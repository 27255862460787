import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { UdtService } from '../../../shared/services/udt.service';
import { PeriforOnChangeMessages, SignalRService } from '../../../shared/services/signal-r.service';
import { Observable, Subscription, catchError, forkJoin, map } from 'rxjs';
import { WellTypeService } from '../../services/well-type-datums.service';
import { FormationTopService } from '../../services/formation-top.service';
import { FormationTop } from 'src/app/perical/models/formation.model';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Store } from '@ngneat/elf';
import { UdtPlotUi } from '../../models/undisturbed-temperature.model';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { withUIEntities } from '@ngneat/elf-entities';
import { XyLinePlotUi } from 'src/app/core/models/xy-line-plot.model';
import { GridItemResizedMessage } from 'src/app/shared/models/mediator-messages.model';
import { MediatorService } from 'src/app/shared/services/mediator.service';

@Component({
    selector: 'app-udt-plot',
    template: `
    <app-tool-loader [isLoading]="isLoading" positionUnset="true">
      <p-toolbar>
        <ng-template #start>
          <div class="mr-2">
            <p-checkbox size="small" [(ngModel)]="udtPlotStore.state.plotFormationTops" (onChange)="toggleFormationTops($event)" binary="true">
            </p-checkbox>
            <label class="ml-2">Show Formation Tops</label>
          </div>
        </ng-template>
      </p-toolbar>

      <app-xy-line-plot
        [plotData]="plot.data"
        [xAxisTitle]="xAxisTitle"
        [yAxisTitle]="yAxisTitle"
        [plotName]="plotName"
        [downloadPlotName]="downloadPlotName"
        [plotTitle]="plotName"
        [xyLinePlotStore]="udtPlotStore"
        [componentHeight]="componentHeight"
      ></app-xy-line-plot>

    </app-tool-loader>
  `,
    standalone: false
})
export class UdtPlotComponent implements OnInit, OnDestroy {

  //State Management
  private _componentId: string;
  @Input() set componentId(value: string) {
    this._componentId = value;
    this.udtPlotStore = this._storeService.createStore(this.componentId, new UdtPlotUi, withUIEntities<XyLinePlotUi>());
  }
  get componentId(): string {
    return this._componentId;
  }
  public udtPlotStore: Store;

  private userUnits: UserUnitsModel;
  public plotFormationTops = false;
  public isLoading = false;
  public mudlineDepth: number;
  private _plotData: any;
  private _formationTops: FormationTop[]
  public plot = {
    data: [],
    layout: {},
    config: {}
  };
  public xAxisTitle = '';
  public yAxisTitle = '';
  public plotName = 'UDT Plot';
  public downloadPlotName = 'udt_plot';
  public componentHeight: number;
  private _subscriptions: Subscription = new Subscription();

  constructor(
    private _udtService: UdtService,
    private _signalRService: SignalRService,
    private _wellTypeService: WellTypeService,
    private _formationTopService: FormationTopService,
    private _storeService: StoreService,
    private _messenger: MediatorService
    ) {
      this._subscriptions.add(this._messenger.of(GridItemResizedMessage).subscribe((e) => {
        if (e.name == "UDT Plot") {
          this.componentHeight = e.itemHeight - 85;
        }
      }));
    }

  async ngOnInit() : Promise<void> {
    this.isLoading = true;
    this.userUnits = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);

    this.xAxisTitle = `Temperature (${this.userUnits.temperature})`;
    this.yAxisTitle = `Depth (${this.userUnits.longLengths})`;

    this.getData();

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));
  }

  private signalRfunc(data: { action: string, designId: string }) {
    if (data.action == PeriforOnChangeMessages.REFRESH_UDT_PLOT) {
      this.getData();
    }
  }

  getData() {
    const sources = [
      this._udtService.getUndisturbedTemperaturePlot() as Observable<any>,
      this._formationTopService.getAllFormationTops() as Observable<any>,
      this._wellTypeService.getWellType() as Observable<any>,
    ]

    forkJoin(sources).pipe(
      map(([udt, formationTops, wellType]) => {
        this.mudlineDepth = wellType.waterDepth > 0 ? +(wellType.drillFloorElevation + wellType.waterDepth).toFixed(2) : null;
        this._plotData = udt;
        this._formationTops = formationTops;
        this.plotUdt(udt);
      }),
      catchError(err => {
        return err;
      })).subscribe();
  }

  plotUdt(plotData) {
    this.plot.data = [];
    const minX = Math.min(...plotData.x) - 20;
    const maxX = Math.max(...plotData.x) + 20;

    if (this.udtPlotStore.state.plotFormationTops) {
      const formationColors: string[] = [
        '#743E07',
        '#9C560F',
        '#D27617',
        '#8E5D2A',
        '#6D4F31',
        '#A36A2F',
        '#EC8316',
        '#A96F34'
      ];

      let i = 0;
      this._formationTops.forEach(formTop => {
        const top = {
          name: formTop.formation['name'] + ' Top',
          y: [formTop.formationTop, formTop.formationTop],
          x: [minX - 0.5, maxX + 0.5],
          mode: 'lines',
          hoverinfo: 'none',
          line: {
            width: 1.5,
            dash: 'dot',
            color: formationColors[i]
          },
        }
        i++;
        if (i >= formationColors.length) { i = 0; }
        this.plot.data.push(top);
      });
    }

    const mudline = {
      name: "Mudline",
      y: [this.mudlineDepth, this.mudlineDepth],
      x: [minX - 0.5, maxX + 0.5],
      line: {
        color: '#783F04'
      },
      mode: 'lines',
      hoverinfo: 'none',
      width: 10
    }

    const msl = {
      name: "MSL",
      y: [0, 0],
      x: [minX - 0.5, maxX + 0.5],
      line: {
        color: '#017BEE'
      },
      mode: 'lines',
      hoverinfo: 'none',
      width: 10
    }

    if(mudline.y[0]) {
      this.plot.data.push(mudline);
      this.plot.data.push(msl);
    }

    const udtData = {
      name: 'UDT',
      x: plotData.x,
      y: plotData.y,
      line: {
        color: '#FF0000'
      },
    }

    this.plot.data.push(udtData);
    this.isLoading = false;
  }

  public toggleFormationTops(e) {
    this.udtPlotStore.update((state) => ({
      ...state,
      plotFormationTops: e.checked
    }));
    this.plotUdt(this._plotData);
  }

  ngOnDestroy() {
    this._subscriptions?.unsubscribe();
    this.signalRfunc = null;
  }
}
