import { Component, Inject, OnInit } from "@angular/core";
import { AuthService } from "@auth0/auth0-angular";
import { MenuItem } from "primeng/api";
import { User, UserRoles } from "../user-admin-page/user-model";
import { firstValueFrom } from "rxjs";
import { DOCUMENT } from '@angular/common';
import { environment } from 'src/environments/environment';
import { StorageKeys, StoreService } from "../../services/store.service";

@Component({
    selector: 'app-user-menu',
    template: `
        <button pButton pRipple type="button" class="p-button-rounded p-button-text p-text-secondary notification-button" icon="pi pi-user" title="User Menu" (click)="menu.toggle($event)"></button>
        <p-menu appendTo="body" #menu [popup]="true" [model]="items"></p-menu>
    `,
    styles: [`
    button {
        background: transparent;
        border-color: transparent;
        }
    `],
    standalone: false
})
export class UserMenuComponent implements OnInit {
    items: MenuItem[] = [];

    constructor(
        @Inject(DOCUMENT)
        private _document: Document,
        private _auth: AuthService,
        private _storeService: StoreService
    ) { }

    async ngOnInit() : Promise<void>{
        try {
            const userRoles = await this._storeService.get<UserRoles>(StorageKeys.ROLES);
            const user = await this._storeService.get<User>(StorageKeys.USER);

            this.initializeMenu(userRoles, user);
        } catch (error) {
            console.error('Error during ngOnInit:', error);
        }
    }

    private initializeMenu(userRoles: UserRoles, user: User) {
        this._auth.user$.subscribe(async (u) => {
            const accessToken = await firstValueFrom(this._auth.getAccessTokenSilently());
            if (u) {
                this.items = [{
                    label: u?.nickname?.toLowerCase() ?? "User Unknown",
                    items: [
                        {
                            label: 'User Profile',
                            icon: 'pi pi-user',
                            routerLink: '/profile'
                        },
                        {
                            label: 'Units',
                            icon: 'pi pi-server',
                            routerLink: '/profile/units'
                        },
                        {
                            label: 'Admin',
                            icon: 'pi pi-users',
                            routerLink: '/users'
                        },
                        {
                            label: 'Hangfire',
                            icon: 'pi pi-cog',
                            url: `${environment.PeriforEndpoint}/hangfire?token=${accessToken}`
                        },
                        {
                            label: 'Logout',
                            icon: 'pi pi-times',
                            command: () => {
                                this.logout();
                            }
                        }
                    ]
                }];
                if (!(user?.profile?.isGlobalAdmin || userRoles?.admin)) {
                    this.items[0]?.items?.splice(2, 1);
                }
            } else {
                this.items = [{
                    label: "Please Login",
                    items: [
                        {
                            label: 'Login',
                            icon: 'pi pi-key',
                            command: () => {
                                this.login();
                            }
                        }
                    ]
                }];
            }
        });
    }

    login() {
        this._auth.loginWithRedirect({
            appState: {
                target: '/designs'
            }
        });
    }

    logout() {
        this._auth.logout({
            logoutParams: {
                returnTo: this._document.location.origin
            }
        });
    }
}
