 <app-tool-loader [isLoading]="isLoading" positionUnset="true">
  <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
    <form [formGroup]="wellTypeForm" class="p-3">
      <div class="flex flex-col space-y-2">

        <p-select class="w-full" formControlName="type" [options]="wellTypes"></p-select>

        <p-fieldset legend="Elevation">
          <div class="flex flex-col space-y-2">
            <div class="flex flex-col">
              <label for="drillFloorElevation">
                Drill Floor Elevation {{ depthRelativeTo }}
              </label>
              <p-inputNumber id="drillFloorElevation" formControlName="drillFloorElevation" [minFractionDigits]="1"
                class="w-full">
              </p-inputNumber>
            </div>

            <div *ngIf="wellTypeForm?.get('type').value !== 'Subsea'" class="flex flex-col">
              <label for="wellheadElevation">
                {{ wellheadType }} {{ wellheadElevationRelativeTo }}
              </label>
              <p-inputNumber id="wellheadElevation" formControlName="wellheadElevation" [minFractionDigits]="1"
                class="w-full">
              </p-inputNumber>
            </div>

            <div class="flex flex-col" *ngIf="wellTypeForm.get('type').value === 'Subsea'">
              <label for="wellheadDepth">
                {{ wellheadType }} {{ wellheadElevationRelativeTo }}
              </label>
              <p-inputNumber id="wellheadDepth" formControlName="wellheadDepth" [minFractionDigits]="1" class="w-full">
              </p-inputNumber>
            </div>
          </div>
        </p-fieldset>

        <p-fieldset *ngIf="waterDepthVisible" legend="Offshore">
          <div class="flex flex-col space-y-2">
            <div class="flex flex-col">
              <label for="waterDepth">
                Water Depth ({{ lengthUnit }})
              </label>
              <p-inputNumber id="waterDepth" formControlName="waterDepth" [minFractionDigits]="1" class="w-full">
              </p-inputNumber>
            </div>
            <label>
              Mudline: {{ mudlineDepth }} ({{ lengthUnit }})
            </label>
          </div>
        </p-fieldset>
      </div>
    </form>
  </p-scrollPanel>
</app-tool-loader>