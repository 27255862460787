import { Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';

export interface vleTuningParametersForm {
  acor: number;
  bcor: number;
  vcor: number;
  dcor: number;
  scor: number;
  kiOption: number;
  c7PlusCorrelations: number;
}

@Component({
    selector: 'app-vle-tuning-parameters',
    templateUrl: './vle-tuning-parameters.component.html',
    styles: [``],
    providers: subformComponentProviders(VleTuningParametersComponent),
    standalone: false
})
export class VleTuningParametersComponent {

  public form = createForm<vleTuningParametersForm>(this, {
    formType: FormType.SUB,
    formControls: {
      acor: new UntypedFormControl(1, [Validators.required]),
      bcor: new UntypedFormControl(1, [Validators.required]),
      vcor: new UntypedFormControl(1, [Validators.required]),
      dcor: new UntypedFormControl(1, [Validators.required]),
      scor: new UntypedFormControl(1, [Validators.required]),
      kiOption: new UntypedFormControl(1, [Validators.required]),
      c7PlusCorrelations: new UntypedFormControl(1, [Validators.required])
    }
  });
}
