import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Fluid, FluidType } from '../../models/fluid.model';
import { Observable, forkJoin, lastValueFrom } from 'rxjs';
import { FluidsService } from '../../services/fluids.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { unitsLib } from 'src/app/core/services/unit-library';
import { Reservoir, ReservoirPerformance, ReservoirPerformanceResult } from '../../models/reservoirPerformance';
import { PeriforOnChangeMessages, SignalRService } from 'src/app/shared/services/signal-r.service';
import { ReservoirService } from '../../services/reservoir.service';
import { AppNotificationService as AppMessageNoticeService } from 'src/app/shared/services/app-notification.service';
import { UdtService } from 'src/app/shared/services/udt.service';
import { WellConfigService } from 'src/app/shared/services/well-config.service';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { GetShortLengthValueFromInches } from 'src/app/perivis/shared/helpers/units.helper';
import { ThermalOperationsService } from '../../services/thermal-operations.service';
import { Perforation } from '../../models/perforation.model';

@Component({
  selector: 'app-reservoir-performance-utility',
  templateUrl: './reservoir-performance-utility.component.html',
  styles: [``],
  standalone:false
})
export class ReservoirPerformanceUtilityComponent implements OnInit, OnDestroy {
  public isLoading: boolean;
  public calculating: boolean;
  public operationTypes: { name: string, value: string }[];
  public gasFlowRateValidation: { min: number, max: number };
  public fluids: Fluid[];
  public reservoirPerformanceForm: UntypedFormGroup;
  public flowRateUnit: string;
  public liquidFlowRateUnit: string;
  public gasFlowRateUnit: string;
  public tempUnit: string;
  public massFlowUnit: string;
  public temperatureValidation: { min: number, max: number };
  public pressureValidation: { min: number, max: number };
  public reservoirPerformanceResult: ReservoirPerformanceResult;
  public reservoirTemperatureLabel: string;
  public surfaceTemperature: number;
  public selectedOperationType: string;
  public userUnits: UserUnitsModel;
  public pressureUnit: string;
  public reservoirs: Reservoir[];
  public pvtCondition: string | null = null;
  public perforations: Perforation[];
  public tempAtPerfs: number;

  @Input()
  private componentId: string;

  constructor(
    private _fluidsService: FluidsService,
    private _formBuilder: UntypedFormBuilder,
    private _storeService: StoreService,
    private _signalRService: SignalRService,
    private _reservoirService: ReservoirService,
    private _appMessageNoticeService: AppMessageNoticeService,
    private _udtService: UdtService,
    private _wellConfigService: WellConfigService,
    private _thermalOperationsService: ThermalOperationsService
  ) {
    this.operationTypes = [
      { name: 'Production', value: 'production' },
      { name: 'Injection', value: 'injection' }
    ];

    this._appMessageNoticeService.getMessages().subscribe(res => {
      res.forEach(r => {
        if (r.summary === 'ReservoirPerformance') {
          this.calculating = false;
        }
      });
    });
  }

  async ngOnInit(): Promise<void> {
    this.userUnits = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);

    this.gasFlowRateUnit = unitsLib[this.userUnits.gasInjectionRate].symbol;
    this.liquidFlowRateUnit = unitsLib[this.userUnits.standardFluidFlow].symbol;
    this.tempUnit = this.userUnits.temperature;
    this.massFlowUnit = unitsLib[this.userUnits.massFlow].symbol;
    this.pressureUnit = unitsLib[this.userUnits.pressure].symbol;

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));

    switch (this.tempUnit) {
      case '°F':
        this.temperatureValidation = { min: 0, max: 1000 };
        break;
      case '°C':
        this.temperatureValidation = { min: -20, max: 540 };
        break;
      case 'K':
        this.temperatureValidation = { min: 255, max: 810 };
        break;
    }

    this.selectedOperationType = 'production';

    this.createReactiveForm();

    
    this.reservoirPerformanceForm.controls.pvtConditions.setValue('bottomHole');
    this.pvtCondition = 'bottomHole';

    await this.getData();

    this.reservoirPerformanceForm.controls.reservoirTemperature.setValidators([Validators.required, Validators.min(this.temperatureValidation.min), Validators.max(this.temperatureValidation.max)]);

    if (this.gasFlowRateUnit == 'MMscf/day') {
      this.gasFlowRateValidation = { min: 0.1, max: 1000 };
    } else {
      this.gasFlowRateValidation = { min: 0.0028, max: 28.3 };
    }
    this.reservoirPerformanceForm.controls.gasFlowRate.setValidators([Validators.required, Validators.min(this.gasFlowRateValidation.min), Validators.max(this.gasFlowRateValidation.max)]);

  }

  private async signalRfunc(data: any): Promise<void> {
    if (data.action === PeriforOnChangeMessages.REFRESH_FLUIDS || data.action === PeriforOnChangeMessages.REFRESH_RESERVOIR ||
        data.action === PeriforOnChangeMessages.REFRESH_PERFORATIONS
    ) {
      this.fluids = [];
      await this.getData();
    }
  }

  private createReactiveForm(): void {
    this.reservoirPerformanceForm = this._formBuilder.group({
      operationType: new UntypedFormControl(null, [Validators.required]),
      fluidId: new UntypedFormControl(null),
      gasFlowRate: new UntypedFormControl(null, [Validators.required]),
      bottomholeTemperature: new UntypedFormControl(null, [Validators.required]),
      reservoirTemperature: new UntypedFormControl(null, [Validators.required]),
      bottomholeDiameter: new UntypedFormControl(null, [Validators.required]),
      pressure: new UntypedFormControl(null,),
      permeability: new UntypedFormControl(null),
      height: new UntypedFormControl(null),
      radialExtent: new UntypedFormControl(null),
      skinFactor: new UntypedFormControl(null),
      isCo2: new UntypedFormControl(false),
      pvtConditions: new UntypedFormControl(null),
      applyCustomPerformanceIndex: new UntypedFormControl(false),
      performanceIndex: new UntypedFormControl(null),
      perf: new UntypedFormControl(null),
      reservoir: new UntypedFormControl(null)
    });
  }

  private async getData(): Promise<void> {
    const sources = [
      this._fluidsService.getFluids() as Observable<any>,
      this._udtService.getUndisturbedTemperaturePoints() as Observable<any>,
      this._wellConfigService.getTubulars() as Observable<any>,
      this._reservoirService.getReservoirs() as Observable<any>,
      this._thermalOperationsService.getPerforations() as Observable<any>
    ];

    const [fluids, udt, wellConfig, reservoirs, perforations] = await lastValueFrom(forkJoin(sources));

    this.fluids = fluids.filter(f => f.state.type === FluidType.CO2FLUID && f.state['co2withImpurities']);
    if (this.fluids.length > 0) {
      this.selectedFluidChange({ value: this.fluids[0] });
    }

    this.reservoirs = reservoirs?.reservoirs;
    const deepestShoe = Math.max(...wellConfig.map(x => x.shoeMd));
    this.perforations = perforations.filter(x => x.measuredDepth <= deepestShoe).sort((a, b) => (a.measuredDepth < b.measuredDepth ? -1 : 1));

    if (this.reservoirPerformanceForm.value.pressure === null && this.reservoirs?.length > 0) {
      this.reservoirPerformanceForm.controls.reservoir.setValue(this.reservoirs[0]);
      this.selectedReservoirChange({ value: this.reservoirs[0] });
    }

    if (!this.tempAtPerfs) {
      this.reservoirPerformanceForm.controls.perf.setValue(this.perforations[0]);
      this.tempAtPerfs = this.perforations[0].temperature;
      this.reservoirPerformanceForm.controls.reservoirTemperature.setValue(this.tempAtPerfs);
    }

    const bottomHoleDiameterDefault = wellConfig.reduce((acc, curr) => {
      if (curr.holeSize < acc && curr.holeSize > 0) {
        return curr.holeSize;
      }
      return acc;
    }, GetShortLengthValueFromInches(12.25, this.userUnits.shortLengths));

    this.surfaceTemperature = udt[0].temperature;
    this.reservoirPerformanceForm.controls.bottomholeDiameter.setValue(bottomHoleDiameterDefault);

    this.isLoading = false;
  }

  public selectedReservoirChange(event: any): void {
    this.reservoirPerformanceForm.controls.pressure.setValue(event.value.pressure);
    this.reservoirPerformanceForm.controls.permeability.setValue(event.value.permeability);
    this.reservoirPerformanceForm.controls.height.setValue(event.value.height);
    this.reservoirPerformanceForm.controls.radialExtent.setValue(event.value.radialExtent);
    this.reservoirPerformanceForm.controls.skinFactor.setValue(event.value.skinFactor);
    this.reservoirPerformanceForm.controls.applyCustomPerformanceIndex.setValue(event.value.applyCustomPerformanceIndex);
    if (event.value.applyCustomPerformanceIndex == true) {
      this.reservoirPerformanceForm.controls.performanceIndex.setValue(event.value.performanceIndex);
    }
  }

  public onPerforationsChange(event: any): void {
    this.reservoirPerformanceForm.controls.perf.setValue(event.value);
    this.tempAtPerfs = event.value.temperature;
    this.reservoirPerformanceForm.controls.reservoirTemperature.setValue(this.tempAtPerfs);
  }

  public pvtConditionsChange(e) {
    this.pvtCondition = e;
    this.reservoirPerformanceForm.controls.pvtConditions.setValue(e);
  }

  public selectedFluidChange(event: any): void {
    this.reservoirPerformanceForm.controls.fluidId.setValue(event.value.id);
    this.reservoirPerformanceForm.controls.isCo2.setValue(event.value.state.type === 'co2Fluid');
    this.flowRateUnit = this.gasFlowRateUnit;
  }

  public selectedOperationChange(event: any): void {
    this.reservoirTemperatureLabel = event.value === 'production' ? 'Reservoir Temperature' : 'Injection Temperature';
    this.selectedOperationType = event.value;
    this.reservoirPerformanceForm.controls.operationType.setValue(event.value);

    if (event.value === 'production') {
      this.reservoirPerformanceForm.controls.reservoirTemperature.setValue(this.tempAtPerfs);
      this.reservoirPerformanceForm.controls.bottomholeTemperature.setValue(0);
    } else {
      this.reservoirPerformanceForm.controls.bottomholeTemperature.setValue(this.surfaceTemperature);
      this.reservoirPerformanceForm.controls.reservoirTemperature.setValue(this.tempAtPerfs);
    }
  }

  public async calculateReservoirPerformance() {
    const phaseEnvelope = await lastValueFrom(this._fluidsService.getPhaseEnvelope(this.reservoirPerformanceForm.value.fluidId));
    if (phaseEnvelope['x'].length <= 0) {
      this.calculating = false;
      return;
    } else {
      this.calculating = true;
      const reservoirPerformanceFormCalc: ReservoirPerformance = new ReservoirPerformance();
      reservoirPerformanceFormCalc.operationType = this.reservoirPerformanceForm.value.operationType;
      reservoirPerformanceFormCalc.fluidId = this.reservoirPerformanceForm.value.fluidId;
      reservoirPerformanceFormCalc.gasFlowRate = this.reservoirPerformanceForm.value.gasFlowRate;
      reservoirPerformanceFormCalc.bottomholeTemperature = this.reservoirPerformanceForm.value.bottomholeTemperature;
      reservoirPerformanceFormCalc.bottomholeDiameter = this.reservoirPerformanceForm.value.bottomholeDiameter;
      reservoirPerformanceFormCalc.reservoirTemperature = this.reservoirPerformanceForm.value.reservoirTemperature;
      reservoirPerformanceFormCalc.pvtConditions = this.reservoirPerformanceForm.value.pvtConditions;
      reservoirPerformanceFormCalc.isCo2 = this.reservoirPerformanceForm.value.isCo2;
      
      reservoirPerformanceFormCalc.reservoir = new Reservoir();
      reservoirPerformanceFormCalc.reservoir.pressure = this.reservoirPerformanceForm.value.pressure;
      reservoirPerformanceFormCalc.reservoir.permeability = this.reservoirPerformanceForm.value.permeability;
      reservoirPerformanceFormCalc.reservoir.height = this.reservoirPerformanceForm.value.height;
      reservoirPerformanceFormCalc.reservoir.radialExtent = this.reservoirPerformanceForm.value.radialExtent;
      reservoirPerformanceFormCalc.reservoir.skinFactor = this.reservoirPerformanceForm.value.skinFactor;
      reservoirPerformanceFormCalc.reservoir.applyCustomPerformanceIndex = this.reservoirPerformanceForm.value.applyCustomPerformanceIndex;
      if (this.reservoirPerformanceForm.value.applyCustomPerformanceIndex == true) {
        reservoirPerformanceFormCalc.reservoir.performanceIndex = this.reservoirPerformanceForm.value.performanceIndex;
      }

      if (this.reservoirPerformanceForm.value.operationType === 'injection' && reservoirPerformanceFormCalc.gasFlowRate > 0) {
        reservoirPerformanceFormCalc.gasFlowRate = reservoirPerformanceFormCalc.gasFlowRate * -1;
      } else if (this.reservoirPerformanceForm.value.operationType === 'production' && reservoirPerformanceFormCalc.gasFlowRate < 0) {
        reservoirPerformanceFormCalc.gasFlowRate = reservoirPerformanceFormCalc.gasFlowRate * -1;
      }
      this.reservoirPerformanceResult = await lastValueFrom(this._reservoirService.calculateReservoirPerformance(reservoirPerformanceFormCalc));
      if (this.reservoirPerformanceResult) {
        this.calculating = false;
      }
    }
  }

  ngOnDestroy(): void {
    this.signalRfunc = null;
  }
}
