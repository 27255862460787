<app-tool-loader [isLoading]="isLoading" positionUnset="true">
  <div class="flex flex-col w-full h-full">
    <!-- Confirmation Dialog -->
    <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

    <!-- Toggle Button -->
    <p-button class="outlined-button" outlined="true" pTooltip="{{ drawerOpen ? 'Collapse List' : 'Expand List' }}"
      icon="{{drawerOpen ? 'pi pi-angle-double-left' : 'pi pi-angle-double-right'}}"
      (onClick)="drawerToggle()">
    </p-button>

    <p-select *ngIf="!drawerOpen && operations" [options]="operations"
      [ngModel]="selectedOperation[0]" dataKey="id" appendTo="body" optionLabel="name"
      (onChange)="onOperationSelect($event, false)">
    </p-select>

    <div class="flex flex-1 overflow-auto">
      <!-- Drawer Section -->
      <div *ngIf="drawerOpen" class="flex-[0.3] min-w-[150px] flex-shrink">
        <p-listbox #operationsListbox [options]="operations" optionLabel="name"
          (onChange)="onOperationSelect($event)"
          cdkDropList (cdkDropListDropped)="drop($event)"
          [(ngModel)]="selectedOperation[0]">
          <ng-template #header>
            <p-button class="outlined-button" outlined="true" severity="success" 
              label="Add Operation" (onClick)="addOperation()">
            </p-button>
          </ng-template>
          <ng-template let-operation #item>
            <div class="flex items-center justify-between w-full" cdkDrag>
              <div class="col-span-10">
                <span class="text-xs">{{ operation.name }}</span>
              </div>
              <div class="flex items-center">
                <i class="pi pi-copy p-1 !text-cyan-400" pTooltip="Clone Operation"
                  (click)="cloneOperation(operation)">
                </i>
                <i class="pi pi-trash !text-red-400" pTooltip="Delete Operation"
                  (click)="deleteOperation(operation)">
                </i>
              </div>
            </div>
          </ng-template>
        </p-listbox>
      </div>
    
      <!-- Main Content Section -->
      <div [ngClass]="{'flex-[0.7]': drawerOpen, 'flex-[1]': !drawerOpen}" class="p-2 overflow-auto">
        <app-operation-data [isReadOnly]="userRoles?.readOnly" [disabled]="operations?.length === 0"
          [operation]="selectedOperationDetail$ | async" (operationUpdate)="updateOrCreateOperation($event)"
          [previousOperations]="previousOperations" [user]="user" [units]="userUnits">
        </app-operation-data>
      </div>
    </div>
    
  </div>
</app-tool-loader>
