import { Injectable, signal } from '@angular/core';
import { MessageService } from 'primeng/api';
import { BehaviorSubject } from 'rxjs';
import localForage from "localforage";

@Injectable({
  providedIn: 'root'
})
export class AppNotificationService {
  private _behaviorSubjectMsgs: BehaviorSubject<any[]>;
  public msgs: any[] = [];
  private store: LocalForage;
  public latestMessage = signal<any>(null);
  
  constructor(private messageService: MessageService) {
    this._behaviorSubjectMsgs = new BehaviorSubject<any[]>(this.msgs);
    this.store = localForage.createInstance({
      name: 'app-db',
      storeName: 'messages',
      driver: localForage.INDEXEDDB
    });
    this.loadMessages();
  }

  showSuccess(message: string): void {
    this.showMessage({ severity: 'success', summary: message });
  }

  showError(message: string): void {
    this.showMessage({ severity: 'error', summary: message });
  }

  showInfo(message: string): void {
    this.showMessage({ severity: 'info', summary: message });
  }

  showWarning(message: string): void {
    this.showMessage({ severity: 'warn', summary: message });
  }

  clearMessages(): void {
    this.msgs = [];
    this._behaviorSubjectMsgs.next(this.msgs);
    this.store.clear();
  }

  getMessages(): BehaviorSubject<any[]> {
    return this._behaviorSubjectMsgs;
  }

  removeMessage(message: any): void {
    this.msgs = this.msgs.filter(m => m.id !== message.id);
    this._behaviorSubjectMsgs.next(this.msgs);
    this.saveMessages();
  }

  markRead(): void {
    this.msgs = this.msgs.map(m => ({ ...m, data: { ...(m as any).data, read: true } }) as any);
    this._behaviorSubjectMsgs.next(this.msgs);
    this.saveMessages();
  }

  showMessage(msg: any): void {
    msg.id = msg.id || this.generateUniqueId();
    msg.data = { time: new Date().toLocaleString(), read: false };
    this.messageService.add(msg);
    this.msgs.unshift(msg);
    if (this.msgs.length > 100) {
      this.msgs.pop();
    }
    this._behaviorSubjectMsgs.next(this.msgs);
    this.latestMessage.set(msg); 
    this.saveMessages();
  }

  private async loadMessages(): Promise<void> {
    const keys = await this.store.keys();
    const messages = await Promise.all(keys.map(key => this.store.getItem<any>(key)));
    this.msgs = messages.filter(Boolean) as any[];
    this._behaviorSubjectMsgs.next(this.msgs);
  }

  private async saveMessages(): Promise<void> {
    await this.store.clear();
    await Promise.all(this.msgs.map(msg => this.store.setItem(msg.id, msg)));
  }

  private generateUniqueId(): string {
    return '_' + Math.random().toString(36).substr(2, 9);
  }

}
