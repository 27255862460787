<div class="flex justify-end">
  <p-button size="small" severity="success" title="Format Axis" (click)="expandFormatAxis()" icon="pi pi-chart-bar">
  </p-button>
</div>

<div *ngIf="showFormatAxis" class="flex flex-wrap">
  <div *ngIf="!legendBelowPlot" class="flex flex-col p-2 w-1/2">
    <p-fieldset legend="X Axis Options">
      <div class="flex flex-row gap-2">
        <div class="flex flex-col">
          <label>Min</label>
          <p-inputnumber [(ngModel)]="plotState?.xAxisRange[0]" (change)="changeAxis($event, 'xAxisRange', 0)" />
        </div>
        <div class="flex flex-col">
          <label>Max</label>
          <p-inputnumber [(ngModel)]="plotState?.xAxisRange[1]" (change)="changeAxis($event, 'xAxisRange', 1)" />
        </div>
        <div class="flex flex-col">
          <label>Tick Spacing</label>
          <p-inputnumber [ngModel]="plotState?.xAxisTick" (change)="changeTicks($event, 'xAxis')" />
        </div>
      </div>
    </p-fieldset>
  </div>

  <div class="flex flex-col p-2 w-1/2">
    <div class="flex flex-row gap-2 items-center">
      <div class="flex flex-col">
        <p-fieldset legend="Y Axis Options">
          <div class="flex flex-row gap-2">
            <div class="flex flex-col">
              <label>Min</label>
              <p-inputnumber *ngIf="plotState?.yAxisRange[0] > plotState?.yAxisRange[1]"
                [ngModel]="plotState?.yAxisRange[1]" (change)="changeAxis($event, 'yAxisRange', 1)" />
              <p-inputnumber *ngIf="plotState?.yAxisRange[0] < plotState?.yAxisRange[1]"
                [ngModel]="plotState?.yAxisRange[0]" (change)="changeAxis($event, 'yAxisRange', 0)" />
            </div>
            <div class="flex flex-col">
              <label>Max</label>
              <p-inputnumber *ngIf="plotState?.yAxisRange[0] > plotState?.yAxisRange[1]"
                [ngModel]="plotState?.yAxisRange[0]" (change)="changeAxis($event, 'yAxisRange', 0)" />
              <p-inputnumber *ngIf="plotState?.yAxisRange[0] < plotState?.yAxisRange[1]"
                [ngModel]="plotState?.yAxisRange[1]" (change)="changeAxis($event, 'yAxisRange', 1)" />
            </div>
            <div class="flex flex-col">
              <label>Tick Spacing</label>
              <p-inputnumber [ngModel]="plotState?.yAxisTick" (change)="changeTicks($event, 'yAxis')" />
            </div>
          </div>
        </p-fieldset>
      </div>
      <div class="flex flex-row gap-2">
        <p-button outlined="true" class="pl-2 outlined-button" size="small" severity="success" label="Reset" (click)="resetFormatAxis()"
          icon="pi pi-refresh">
        </p-button>
    </div>
  </div>
  </div>
</div>

<div (wheel)="onScroll()" [style]="{position: 'relative', height: plotHeight, width: '100%'}" #plotElement class="plot">
  <plotly-plot
    *ngIf="plot?.data?.length > 0"
    (doubleClick)="resetFormatAxis()"
    (afterPlot)="afterPlot()"
    (relayout)="relayout($event)"
    [divId]="plotName"
    [data]="plot.data"
    [layout]="plot.layout"
    [config]="plot.config"
    [updateOnDataChange]="true"
    [useResizeHandler]="true"
    [style]="{ position: 'relative', width: '100%', height: '100%' }"
  ></plotly-plot>
</div>

<p-dialog [modal]="true" class="container" [draggable]="false" [(visible)]="showScrollOverlay" [closable]="false">
  <i class="pi pi-exclamation-circle"></i> Use Shift+Scroll to zoom in <br />
  <br />
</p-dialog>
