import { Component } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

@Component({
    selector: 'app-login-page',
    template: `
      <div class="min-h-screen flex items-center justify-center bg-cover bg-center"
    >
      <div  [style.background-color]="'var(--p-toolbar-background)'"
       class="max-w-lg bg-gray-800 dark:bg-gray-100 text-gray-200 dark:text-gray-900 shadow-lg p-8 bg-[var(--p-surface-900)]">
        <div class="flex flex-col items-center">
          <img class="w-60 h-auto mb-6" src="../../assets/images/Perifor-with-Well-Circle.png" alt="Logo" />
          
          <ng-container *ngIf="!(auth.isAuthenticated$ | async)">
            <p [style.color]="'var(--p-content-color)'" class="text-center mb-6">Welcome! Log in to get started.</p>
            <button class="bg-[#5c6bc0] hover:bg-opacity-[85%] text-white py-3 px-6 transition duration-300"
              (click)="login()">Login</button>
          </ng-container>
        </div>
      </div>
    </div>
  `,
    styles: [],
    standalone: false
})
export class LoginPageComponent {
  constructor(public auth: AuthService) { }

  login() {
    this.auth.loginWithRedirect({
      appState: {
        target: '/designs'
      }
    });
  }
}
