import { Component, OnInit } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { WorkspacesModel } from '../models/dashboard.model';
import { WellExplorerItem } from 'src/app/core/models/well-explorer.model';
import { WellExplorerService } from 'src/app/core/services/well-explorer.service';
import { DashboardApiService } from '../services/dashboard-api.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-clone-workspaces-dialog',
    templateUrl: './clone-workspaces-dialog.component.html',
    styles: [``],
    standalone: false
})
export class CloneWorkspacesDialogComponent implements OnInit {

  readonly companyLevel: string = "companies";
  readonly designLevel: string = "designs";

  public treeItems: TreeNode[];
  public templateWorkspaces: WorkspacesModel;

  public workspaceToClone: string;

  constructor(
    private _wellExplorerService: WellExplorerService,
    private _dashboardService: DashboardApiService,
    public ref: DynamicDialogRef
  ) { }

  ngOnInit(): void {
    this._wellExplorerService.getWellExplorer().subscribe((wellExplorer: WellExplorerItem) => {
      this.treeItems = this.handleTreeItemsSelectable(this.buildTree(wellExplorer));
    });
  }

  onSave(): void {
    this.ref.close(this.workspaceToClone);
  }

  onCancel(): void {
    this.ref.close();
  }

  private buildTree(wellExplorerItem: WellExplorerItem): TreeNode[] {
    return wellExplorerItem?.children?.map((a: WellExplorerItem) => {
      return {
        data: {
          label: a.name,
          icon: this.setIcon(wellExplorerItem?.childLevel?.toLowerCase()),
          id: a.id,
          level: a.level,
          childLevel: a.childLevel
        },
        children: this.buildTree(a),
        expanded: false
      };
    });
  }

  private setIcon(menuLevel: string): string {
    switch (menuLevel) {
      case this.companyLevel:
        return '../../../../assets/icons/domain-24px.svg';
      case 'projects':
        return '../../../../assets/icons/assignment-24px.svg';
      case 'sites':
        return '../../../../assets/icons/place-24px.svg';
      case 'wells':
        return '../../../../assets/icons/oil-platform.svg';
      case 'wellbores':
        return '../../../../assets/icons/noun_drill bit_331639.svg';
      default:
        return '';
    }
  }

  handleTreeItemsSelectable(treeItems): TreeNode[] {
    treeItems.forEach(i => {
      i.key = i.data.id;
      if (i.data.level !== 'designs') {
        i.selectable = false;
        this.handleTreeItemsSelectable(i.children);
      }
    });
    return treeItems;
  }

  onTemplateSelect(designId: string) {
    this._dashboardService.getWorkspaces(designId).subscribe(res => {
      this.templateWorkspaces = res;
      this.workspaceToClone = res.designId;
    });
  }

  onTemplateClear(): void {
    this.templateWorkspaces = null;
    this.workspaceToClone = null;
  }

  showComponentList(uiComponents: any): string {
    let temp = '<span>Components:</span><br>';
    uiComponents?.forEach(component => {
      temp += `<span>${component.name}</span><br>`;
    });
    return temp;
  }
}
