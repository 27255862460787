<form [formGroup]="form.formGroup">
    <div class="flex gap-2 flex-row m-2">
        <p-fieldset legend="Influx Parameters" class="w-1/2">
            <div class="flex flex-col space-y-2">
                <app-uom-input
                    label="Influx Depth"
                    [unitLabel]="userUnits.longLengths"
                    [formControlName]="form.formControlNames.influxDepth"
                    [control]="'influxDepth'"
                    [default]="nextShoeDepth"
                    [validators]="validators.influxDepth"
                    title="Must be at or below shoe: {{ currentString.shoeMd }} {{ userUnits.longLengths }}"
                />

                <app-uom-input
                    label="Pore Pressure"
                    [unitLabel]="densityLabel"
                    [formControlName]="form.formControlNames.porePressure"
                    [control]="'porePressure'"
                    [default]="defaultEmw"
                    [validators]="validators.porePressure"
                />

                <app-uom-input
                    label="Margin of Error"
                    [unitLabel]="densityLabel"
                    [formControlName]="form.formControlNames.marginOfError"
                    [control]="'marginOfError'"
                    [default]="'0'"
                    [validators]="validators.marginOfError"
                />

                <div>
                    <p-checkbox
                        size="small"
                        binary="true"
                        [formControlName]="form.formControlNames.useGasGravity"
                        (onChange)="gasGravityChange()"
                    ></p-checkbox>
                    <label class="ml-2">Use Gas Gravity</label>
                </div>

                <div [style.display]="!gasGravityDisabled ? 'block' : 'none'">
                    <app-uom-input
                        label="Gas Gradient"
                        [unitLabel]="gasGradientLabel"
                        [formControlName]="form.formControlNames.gasGradient"
                        [control]="'gasGradient'"
                        [default]="gasGradientDefault"
                        [validators]="validators.gasGradient"
                        title="Allowed range: {{ gasGradientValidation.min }} - {{ gasGradientValidation.max }} {{ gasGradientLabel }}"
                    />
                </div>

                <div [style.display]="gasGravityDisabled ? 'block' : 'none'">
                    <app-uom-input
                        label="Gas Gravity"
                        [formControlName]="form.formControlNames.gasGravity"
                        [default]="'0.6'"
                        [validators]="validators.gasGravity"
                    />
                </div>
            </div>
        </p-fieldset>

        <p-fieldset legend="Fracture Pressure" class="w-1/2">
            <div class="flex flex-col space-y-2">
                <app-uom-input
                    label="Fracture Depth"
                    [unitLabel]="userUnits.longLengths + ' MD'"
                    [formControlName]="form.formControlNames.fractureDepth"
                    [control]="'fractureDepth'"
                    [default]="shoeDepth"
                    [validators]="validators.fractureDepth"
                    title="Allowed range: {{ currentString.hangerMd }} - {{ currentString.shoeMd }} {{ userUnits.longLengths }}"
                />

                <app-uom-input
                    label="Fracture Gradient"
                    [unitLabel]="densityLabel"
                    [formControlName]="form.formControlNames.fractureGradient"
                    [control]="'fractureGradient'"
                    [default]="defaultFracGradient"
                    [validators]="validators.fractureGradient"
                    title="Allowed range: {{ fractureGradientValidation.min }} - {{ fractureGradientValidation.max }} {{ densityLabel }}"
                />

                <app-uom-input
                    label="Margin of Error"
                    [unitLabel]="densityLabel"
                    [formControlName]="form.formControlNames.fractureMarginOfError"
                    [control]="'fractureMarginOfError'"
                    [default]="'0'"
                    [validators]="validators.fractureMarginOfError"
                    title="Allowed range: {{ marginOfErrorValidation.min }} - {{ marginOfErrorValidation.max }} {{ densityLabel }}"
                />  
            </div>
        </p-fieldset>
    </div>
</form>