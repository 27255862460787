import { Component, OnDestroy, OnInit, computed, effect, signal } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { AppNotificationService } from 'src/app/shared/services/app-notification.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { SignalRService } from 'src/app/shared/services/signal-r.service';
import { isEqual } from 'lodash-es';
import { StorageKeys, StoreService } from '../../services/store.service';
import { Subject, takeUntil } from 'rxjs';

interface PeriforMsg {
  id: string;
  data: any;
  detail: any;
  severity: string;
  summary: string;
}

@Component({
  selector: 'app-notification-hub',
  templateUrl: './notification-hub.component.html',
  styles: [`
    .hub-container {
      height: 375px;
      width: 500px;
      overflow: auto;
    }
  `],
  standalone: false
})
export class NotificationHubComponent implements OnInit, OnDestroy {

  private _destroy$ = new Subject<void>();
  private _designId = signal<string | undefined>(undefined);
  public messages = signal<PeriforMsg[]>([]);
  private _messagesBackup = signal<PeriforMsg[]>([]);
  public live = signal(true);
  public selectedSeverity = signal<any>({ label: 'All', value: 'all' });

  public messageTypes: SelectItem[] = [
    { label: 'All', value: 'all' },
    { label: 'Success', value: 'success' },
    { label: 'Info', value: 'info' },
    { label: 'Error', value: 'error' },
    { label: 'Warning', value: 'warn' }
  ];

  public messagesUnread = computed(() => this.messages().filter(m => m.data?.read === false).length);

  constructor(
    public appMessageNoticeService: AppNotificationService,
    private _clipboard: Clipboard,
    private _signalRService: SignalRService,
    private _storeService: StoreService
  ) {
    // Effect to filter messages when selectedSeverity changes
    effect(() => {
      this.filterSeverity(this.selectedSeverity()?.value);
    });
    // Effect to react to new messages from the service
    effect(() => {
      const newMessage = this.appMessageNoticeService.latestMessage();
      if (newMessage) {
        const processedMessage = this.processMessage(newMessage);
        this.messages.update(currentMessages => [...currentMessages, processedMessage]);
        this._messagesBackup.update(currentBackup => [...currentBackup, processedMessage]);
      }
    });
  }

  ngOnInit(): void {
    const designStore = this._storeService.getStore(StorageKeys.DESIGN);
    if (designStore) {
      designStore.pipe(takeUntil(this._destroy$)).subscribe(d => {
        this._designId.set(d?.id);
      });
    }

    this.loadMessages();
    this.setupSignalR();
  }

  private loadMessages(): void {
    this.appMessageNoticeService.getMessages().pipe(takeUntil(this._destroy$)).subscribe(res => {
      const processedMessages = res.map(m => this.processMessage(m));
      this.messages.set(processedMessages);
      this._messagesBackup.set(processedMessages);
    });
  }

  private processMessage(m: any): PeriforMsg {
    const newMessage = { ...m };
    if (!newMessage.detail && newMessage.summary?.includes('Thermal operation is linked')) {
      newMessage.detail = newMessage.summary.slice(newMessage.summary.indexOf('Thermal operation is linked'));
      newMessage.summary = 'Perical';
    }

    if (!newMessage.detail) {
      newMessage.detail = newMessage.summary;
      newMessage.summary = this.capitalizeFirstLetter(newMessage.severity || 'info');
    }
    return newMessage as PeriforMsg;
  }

  private setupSignalR(): void {
    const signalRHub = this._signalRService.getConnectionToApplicationInfoMessageHub();
    signalRHub.on(SignalRService.ON_APP_INFO, (data: any) => {
      if (this._designId() === data.designId) {
        const msg = { severity: data.level, summary: data.summary, detail: data.message, data: `${data.designId}` };
        this.appMessageNoticeService.showMessage(msg);
      }
    });
  }

  filterSeverity(severity: string): void {
    this.messages.set(
      severity === 'all' ? this._messagesBackup() : this._messagesBackup().filter(x => x.severity === severity)
    );
  }

  clearNotifications(): void {
    this.appMessageNoticeService.clearMessages();
    this.messages.set([]);
    this._messagesBackup.set([]);
  }

  removeNotification(msg: PeriforMsg): void {
    this.appMessageNoticeService.removeMessage(msg);
    this.messages.update(currentMessages => currentMessages.filter(f => !isEqual(f, msg)));
    this._messagesBackup.update(currentBackup => currentBackup.filter(f => !isEqual(f, msg)));
    if (!this.messages().some(x => x.severity === this.selectedSeverity()?.value)) {
      this.selectedSeverity.set({ label: 'All', value: 'all' });
    } else {
      this.filterSeverity(this.selectedSeverity()?.value);
    }
  }

  copyNotification(message: PeriforMsg): void {
    this._clipboard.copy(message.detail || message.summary);
  }

  markRead(): void {
    this.messages.update(currentMessages =>
      currentMessages.map(m => ({ ...m, data: { ...m.data, read: true } }))
    );
    this._messagesBackup.update(currentBackup =>
      currentBackup.map(m => ({ ...m, data: { ...m.data, read: true } }))
    );
    this.appMessageNoticeService.markRead();
    this.selectedSeverity.set({ label: 'All', value: 'all' });
  }

  ngOnDestroy(): void {
    this._destroy$.next();
    this._destroy$.complete();
  }

  private capitalizeFirstLetter(text: string): string {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }

}