import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SelectItem } from 'primeng/api';
import { Subscription, lastValueFrom } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { StressOptionsService } from '../../services/stress-options.service';
import { User, UserRoles } from 'src/app/core/components/user-admin-page/user-model';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';

@Component({
    selector: 'app-stress-options',
    templateUrl: './stress-options.component.html',
    styles: [],
    standalone: false
})
export class StressOptionsComponent implements OnInit, OnDestroy {

  private _subscriptions: Subscription = new Subscription();

  public stressOptionsForm: UntypedFormGroup;
  public helicalFactorTypes: { label: string, value: string }[];
  public apiCollapse: SelectItem[];
  public apiCollapseSelected: string;
  public isLoading: boolean;
  public verticalGridUnit: string;
  public gridSpacingValidation: { min: number, max: number };
  public userRoles : UserRoles;

  @Input()
  private componentId: string;

  constructor(
    private _stressOptionsService: StressOptionsService,
    private _formBuilder: UntypedFormBuilder,
    private _storeService: StoreService
  ) {
    this.helicalFactorTypes = [
      { label: 'Unloading', value: 'Unloading' },
      { label: 'Loading', value: 'Loading' },
      { label: 'Custom', value: 'Custom' }
    ];
  }
  
  async ngOnInit(): Promise<void> {
    this.isLoading = true;

    this.verticalGridUnit = (await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS)).longLengths;
    this.userRoles = (await this._storeService.get<User>(StorageKeys.USER)).roles;

    if (this.verticalGridUnit == 'ft') {
      this.gridSpacingValidation = { min: 1, max: 1000 };
    } else {
      this.gridSpacingValidation = { min: 0.3, max: 304.8 };
    }

    this.createReactiveForm();

    this.apiCollapse = [
      { label: '2018 (Current)', value: 'CollapseApi2018' },
      { label: '2008 (Legacy)', value: 'CollapseApi2008' }
    ];

    const stressOptions = await lastValueFrom(this._stressOptionsService.getStressOptions());

    // Sets all form values based on API response
    this.stressOptionsForm.patchValue(stressOptions, { emitEvent: false });

    // Correctly enables/disables input based on type
    this.onHelicalFactorTypeChange(stressOptions.helicalFactorType);

    this.isLoading = false;
  }

  public resetDefaults(){
    const id = this.stressOptionsForm.value.id;
    this.createReactiveForm();
    this.stressOptionsForm.patchValue({id});// Trigger a save
  }

  private createReactiveForm(): void {
    this.stressOptionsForm = this._formBuilder.group({
      id: new UntypedFormControl(""),
      maximumGridSpacing: new UntypedFormControl(200, [Validators.min(this.gridSpacingValidation.min), Validators.max(this.gridSpacingValidation.max), Validators.required]),
      helicalFactorType: new UntypedFormControl('Unloading'),
      helicalBucklingFactor: new UntypedFormControl(1.414, [Validators.min(1), Validators.max(3), Validators.required]),
      useHeKyllingstad: new UntypedFormControl(false),
      useDynamicFriction: new UntypedFormControl(false),
      frictionFactor: new UntypedFormControl({ value: 0.3, disabled: true }),
      dampingType: new UntypedFormControl({ value: 'FluidDamping', disabled: true }),
      apiCollapseType: new UntypedFormControl({value: 'CollapseApi2018', disabled: this.userRoles.readOnly}),
    });

    this._subscriptions.add(this.stressOptionsForm.valueChanges.pipe(debounceTime(500)).subscribe(async () => {
      if (!this.isLoading && this.stressOptionsForm.valid) {
        await lastValueFrom(this._stressOptionsService.setStressOptions(this.stressOptionsForm.getRawValue())); //getRawValue() to get disabled fields
      }
    }));

    this._subscriptions.add(this.stressOptionsForm.controls.helicalFactorType.valueChanges.subscribe(t => this.onHelicalFactorTypeChange(t)));

  }

  private onHelicalFactorTypeChange(type: string) {
    if (type.toLowerCase() === 'unloading') {
      // Unloading selected
      this.stressOptionsForm.controls.helicalBucklingFactor.disable({ emitEvent: false });
      this.stressOptionsForm.controls.helicalBucklingFactor.setValue(1.414, { emitEvent: false });
    } else if (type.toLowerCase() === 'loading') {
      // Loading selected
      this.stressOptionsForm.controls.helicalBucklingFactor.disable({ emitEvent: false });
      this.stressOptionsForm.controls.helicalBucklingFactor.setValue(2.828, { emitEvent: false });
    } else {
      // Custom selected
      this.stressOptionsForm.controls.helicalBucklingFactor.enable({ emitEvent: false });
    }
  }

  public dampingTypeChange(e) {
    this.stressOptionsForm.get('dampingType').setValue(e.value);
  }

  ngOnDestroy() {
    this._subscriptions?.unsubscribe();
  }
}
