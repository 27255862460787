import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { CementingLanding } from 'src/app/wellbore-inputs/models/cementing-landing.model';
import { ExternalPressureProfileType, GeneralEPP, MudAndWetCementEPP } from '../../models/external-pressure-profile.model';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { unitsLib } from 'src/app/core/services/unit-library';

interface MudAndWetCementEPPForm {
  type: ExternalPressureProfileType.MUDANDWETCEMENT;
  generalEpp: GeneralEPP;
}

@Component({
    selector: 'app-mud-and-wet-cement-epp',
    templateUrl: './mud-and-wet-cement-epp.component.html',
    providers: subformComponentProviders(MudAndWetCementEppComponent),
    standalone: false
})
export class MudAndWetCementEppComponent implements OnInit {

  @Input() currentString: any;
  @Input() cement: CementingLanding;
  @Input() hangerMd: any;
  @Input() userUnits: UserUnitsModel;

  public densityLabel: string;

  public form = createForm<MudAndWetCementEPP, MudAndWetCementEPPForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(ExternalPressureProfileType.MUDANDWETCEMENT),
      generalEpp: new UntypedFormControl(null, Validators.required)
    },
    toFormGroup: (obj: MudAndWetCementEPP): MudAndWetCementEPPForm => {
      return {
        type: ExternalPressureProfileType.MUDANDWETCEMENT,
        generalEpp: obj
      };
    },
    fromFormGroup: (formValue: MudAndWetCementEPPForm): MudAndWetCementEPP => {
      return {
        type: ExternalPressureProfileType.MUDANDWETCEMENT,
        hangerPressure: formValue.generalEpp.hangerPressure,
        fluidDensity: formValue.generalEpp.fluidDensity
      };
    }
  });

  ngOnInit(): void {
    this.densityLabel = unitsLib[this.userUnits.density].symbol;
  }

}
