import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { DrillstringModel } from '../components/drillstring/drillstring.model';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`

@Injectable({
  providedIn: 'root'
})
export class DrillstringService {
  private drillstringCache$: Observable<DrillstringModel> | null = null;

  constructor(private http: HttpClient) { }

  public getDrillstringForTubular(): Observable<DrillstringModel> {
    if (!this.drillstringCache$) {
      this.drillstringCache$ = this.http
        .get<DrillstringModel>(`${PeriforApiEndpoint}/drillstring`)
        .pipe(
          map(data => new DrillstringModel(data)),
          catchError(() => of(null as DrillstringModel)),
          shareReplay(1)
        );
    }
    setTimeout(() => this.drillstringCache$ = null, 1000);
    return this.drillstringCache$;
  }

  updateDrillstringForTubular(model: DrillstringModel): Observable<boolean> {
    return this.http.put<boolean>(`${PeriforApiEndpoint}/drillstring`, model);
  }

  setDrillstring(drillstring: DrillstringModel): Observable<DrillstringModel> {
    return this.http.post<DrillstringModel>(`${PeriforApiEndpoint}/drillstring`, drillstring);
  }
}
