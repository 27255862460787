<app-tool-loader [isLoading]="isLoading" positionUnset="true">

  <p-toolbar>
    <ng-template #start>
      <div class="flex items-center gap-2 toolbar-menu">
        <p-selectButton [options]="modes" [(ngModel)]="displacementResultsStore.state.selectedMode"
          (onChange)="onModeChange($event)" [allowEmpty]="false">
        </p-selectButton>

        <p-selectButton *ngIf="displacementResultsStore.state?.selectedMode === 'multiple'" [options]="resultsDisplay"
          [(ngModel)]="displacementResultsStore.state.selectedResultDisplay"
          (onChange)="onSelectedResultDisplayUpdated($event)" [allowEmpty]="false">
        </p-selectButton>

        <p-selectButton *ngIf="displacementResultsStore.state?.selectedResultDisplay === 'plot'" [options]="depthView"
          [(ngModel)]="displacementResultsStore.state.selectedDepthView" (onChange)="depthViewToggle($event)"
          [allowEmpty]="false">
        </p-selectButton>

        <app-single-load-results [loadCases]="loadCases" (loadCaseSelected)="setLoadCase($event)"
          [stateStore]="displacementResultsStore" [signalRChange]="signalRChange">
        </app-single-load-results>
      </div>
    </ng-template>
  </p-toolbar>

  <app-single-load-plot 
    [hidden]="displacementResultsStore.state?.selectedResultDisplay !== 'plot'"
    [yAxisTitle]="yAxisTitle" [xAxisTitle]="xAxisTitle" [columnDefs]="columnDefs" [data]="results"
    [selectedDepthView]="displacementResultsStore.state.selectedDepthView"
    [loadCaseResultField]="displacementResultsStore.state.selectedLoadCase" [depthUnit]="longLengthUnit"
    [plotName]="plotName" 
    [plotTitle]="'Forces/Displacements'"
    [singleLoadPlotStore]="displacementResultsStore">
  </app-single-load-plot>

  <app-single-load-grid 
    [hidden]="displacementResultsStore.state?.selectedResultDisplay !== 'grid'" 
    [rowData]="results"
    [columnDefinitions]="columnDefinitions" 
    tableName="Displacements"
    gridName="Forces/Displacements">
  </app-single-load-grid>

  <p-dialog [draggable]="false" [(visible)]="errorMsg" [closable]="false">
    <i class="pi pi-exclamation-circle pr-1"></i>
    <label>{{ errorMsg }}</label>
    <br> <br>
  </p-dialog>

</app-tool-loader>