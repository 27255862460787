import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';

export interface RestartOperationForm {
  totalTimeToOpenChoke: number;
  initialTimeToOpenChoke: number;
  terminalChokeOpenPercent: number;
  initialChokeOpenPercent: number;
  allowTransientFlow: boolean;
}

@Component({
  selector: 'app-restart-operation',
  templateUrl: './restart-operation.component.html',
  providers: subformComponentProviders(RestartOperationComponent),
  standalone: false
})
export class RestartOperationComponent implements AfterViewInit, OnChanges {
  @Input()
  public disableRestartOperation: boolean;
  public timeUnits: any[];

  public form = createForm<RestartOperationForm>(this, {
    formType: FormType.SUB,
    formControls: {
      totalTimeToOpenChoke: new UntypedFormControl(10, [Validators.min(0), Validators.max(30)]),
      initialTimeToOpenChoke: new UntypedFormControl(1, [Validators.min(0)]),
      terminalChokeOpenPercent: new UntypedFormControl(10, [Validators.min(0), Validators.max(45)]),
      initialChokeOpenPercent: new UntypedFormControl(0, [Validators.min(0)]),
      allowTransientFlow: new UntypedFormControl(false)
    },
  });

  ngAfterViewInit(): void {
    this.enableDisable();

    this.updateInitialCokeOpenValidation();
    this.updateInitialTimeToOpenValidation();
  }

  ngOnChanges(): void {
    this.enableDisable();
  }

  private enableDisable() {
    const controlNames = [
      'totalTimeToOpenChoke',
      'initialTimeToOpenChoke',
      'terminalChokeOpenPercent',
      'initialChokeOpenPercent',
      'allowTransientFlow'
    ];
    
    controlNames.forEach(controlName => {
      const control = this.form.formGroup.controls[controlName];
      if (this.disableRestartOperation) {
        control.disable({ emitEvent: false });
      } else {
        control.enable({ emitEvent: false });
      }
    });
  }

  public updateInitialCokeOpenValidation() {
    this.form.formGroup.controls.initialChokeOpenPercent.setValidators([Validators.max(this.form.formGroup.controls.terminalChokeOpenPercent.value)]);
    this.form.formGroup.controls.initialChokeOpenPercent.updateValueAndValidity({ emitEvent: false });
  }

  public updateInitialTimeToOpenValidation() {
    this.form.formGroup.controls.initialTimeToOpenChoke.setValidators([Validators.max(this.form.formGroup.controls.totalTimeToOpenChoke.value)]);
    this.form.formGroup.controls.initialTimeToOpenChoke.updateValueAndValidity({ emitEvent: false });
  }
}
