import { Component, Input, OnChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { InternalPressureProfileType, PartialEvacByMdIPP } from '../../models/internal-pressure-profile.model';
import { UomQuantityInput } from '../../uom-quantity-input/uom-quantity-input-component';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { unitsLib } from 'src/app/core/services/unit-library';
import { WellType } from 'src/app/shared/models/wellType.model';

interface PartialEvacByMdIppForm {
  type: InternalPressureProfileType.PARTIALEVAC;
  fluidLevelMd: UomQuantityInput;
  fluidDensity: UomQuantityInput;
}

@Component({
  selector: 'app-partial-evac-by-md-ipp',
  templateUrl: './partial-evac-by-md-ipp.component.html',
  providers: subformComponentProviders(PartialEvacByMdIppComponent),
  standalone: false
})
export class PartialEvacByMdIppComponent implements OnChanges {

  @Input() currentString: any;
  @Input() userUnits: UserUnitsModel;
    @Input() wellType: WellType;

  public validators: any;
  public densityLabel: string;
  public densityValidation: { min: number, max: number };

  public form = createForm<PartialEvacByMdIPP, PartialEvacByMdIppForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(InternalPressureProfileType.PARTIALEVAC),
      fluidLevelMd: new UntypedFormControl(null),
      fluidDensity: new UntypedFormControl(null)
    },
    toFormGroup: (obj: PartialEvacByMdIPP | null): PartialEvacByMdIppForm | null => {
      if (!obj) {
        return null;
      }

      return {
        type: obj.type,
        fluidLevelMd: { control: obj.fluidLevelMd },
        fluidDensity: { control: obj.fluidDensity }
      }
    },
    fromFormGroup: (formValue: PartialEvacByMdIppForm): PartialEvacByMdIPP | null => {
      const { fluidLevelMd, fluidDensity, ...commonValues } = formValue;
      return {
        fluidLevelMd: +fluidLevelMd?.control,
        fluidDensity: +fluidDensity?.control,
        ...commonValues
      }
    }
  })

  ngOnChanges(): void {
    this.densityLabel = unitsLib[this.userUnits.density].symbol;
    switch (this.densityLabel) {
      case 'ppg':
        this.densityValidation = { min: 0.01, max: 25 };
        break;
      case 'kg/m³':
      case 'g/L':
        this.densityValidation = { min: 1.19, max: 2995 };
        break;
      case 'g/cm³':
      case 'kg/l':
      case 'sg':
        this.densityValidation = { min: 0.00119, max: 3 };
        break;
    }

    this.validators = {
      fluidLevelMd: [Validators.min(this.wellType.wellheadDepth), Validators.max(this.currentString.shoeMd)],
      fluidDensity: [Validators.min(this.densityValidation.min), Validators.max(this.densityValidation.max)]
    }
  }
}
