<app-tool-loader [isLoading]="isLoading" positionUnset="true">

  <p-toolbar>
    <ng-template #start>
      <div class="flex gap-2">
        <p-select [(ngModel)]="apbResultsGridStore.state.selectedRadiusType" (onChange)="onRadiusTypeChange($event)"
          optionValue="value" optionLabel="label" [options]="radiusTypes"></p-select>
      </div>
    </ng-template>
  </p-toolbar>

  <app-ng-table-results 
    tableName="APB Results Grid" 
    [tableData]="resultsGrid" 
    [columnDefinitions]="cols"
    [tableHeight]="tableHeight" 
    [selectedOperation]="apbResultsGridStore.state?.selectedRadiusType"
    [virtualScroll]="true"
  />

  <p-dialog [draggable]="false" [(visible)]="needToCalculate" [closable]="false">
    <i class="pi pi-exclamation-circle"></i> Please calculate Perinix to view results <br />
    <br />
  </p-dialog>
  
</app-tool-loader>