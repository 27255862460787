import { UntypedFormArray, UntypedFormControl, ValidatorFn } from "@angular/forms";

export function valuesIncreaseValidator(fieldName:string): ValidatorFn {
    return (control: UntypedFormControl): { error: string }  | null => valuesMustIncrease(control, fieldName);
}

function valuesMustIncrease(control: UntypedFormControl, fieldName: string) :  { error: string } {
    if (control.parent == null || control.parent.parent == null) {
        return;
    }
    const currentArray = control.parent.parent as UntypedFormArray;
    const itemIdx = currentArray.controls.indexOf(control.parent);
    let errorMsg = "";
    if (currentArray.controls.length > 1) {

        const tvds = currentArray.controls.map(c => parseFloat(c.get(fieldName).value));
        let lastTvd = 0;
        let isAscending = true;
        let errorIdx = -99;
        for (let i = 0; i < tvds.length; i++){
            if(lastTvd > tvds[i]){
                isAscending = false;
                errorIdx = i;
                break;
            }
            lastTvd = tvds[i];
        }

        if (!isAscending && errorIdx == itemIdx) {
            errorMsg += `Values must be in increasing order \n`;
        }
        return errorMsg ? { error: errorMsg } : null;
    }
    return null;
}
