import { AfterViewInit, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { unitsLib } from 'src/app/core/services/unit-library';
import { MudPit } from 'src/app/perical/models/thermal-operation.model';
import { GetValueFromFahrenheit, getVolumeValueFromBbl } from 'src/app/perivis/shared/helpers/units.helper';
import { UdtService } from 'src/app/shared/services/udt.service';

export interface MudPitsForm {
  isNewMudTemperature: string;
  newMudTemperature: number;
  airTemperature: number;
  airVelocity: number;
  numberOfTanks: number;
  nominalVolume: number;
  activeVolumeFraction: number;
  exposedSurfaceArea: number;
  agitatorPower: number;
}

@Component({
  selector: 'app-mud-pit-details',
  templateUrl: './mud-pit-details.component.html',
  styles: [``],
  providers: subformComponentProviders(MudPitDetailsComponent),
  standalone: false
})
export class MudPitDetailsComponent implements AfterViewInit, OnChanges {

  constructor(private _udtService: UdtService) { }

  public startingConditions = [
    { name: 'New Mud Temperature', value: 'newMudTemperature' },
    { name: 'Start From Previous Mud Pit', value: 'isStartingFromPreviousPutConditions' }
  ];

  public totalMudVolume: number;
  public startWithNewMudPit: boolean;
  public tempUnit: string;
  public pressureUnit: string;
  public volumeUnit: string;

  @Input()
  public userUnits: UserUnitsModel;

  @Input()
  public previousOperationMudPits: MudPit;

  @Input()
  public disableMudPits = false;

  public form = createForm<MudPit, MudPitsForm>(this, {
    formType: FormType.SUB,
    formControls: {
      isNewMudTemperature: new UntypedFormControl('newMudTemperature', [Validators.required]),
      newMudTemperature: new UntypedFormControl(null),
      airTemperature: new UntypedFormControl(null, [Validators.required]),
      airVelocity: new UntypedFormControl(10, [Validators.required]),
      numberOfTanks: new UntypedFormControl(3, [Validators.min(1), Validators.required]),
      nominalVolume: new UntypedFormControl(null, [Validators.required]),
      activeVolumeFraction: new UntypedFormControl(70, [Validators.required]),
      exposedSurfaceArea: new UntypedFormControl(100, [Validators.required]),
      agitatorPower: new UntypedFormControl(50, [Validators.required])
    },
    toFormGroup: (obj: MudPit | null): MudPitsForm | null => {
      if (!obj) {
        return null;
      }

      return {
        isNewMudTemperature: obj.isNewMudTemperature || 'newMudTemperature',
        newMudTemperature: obj.newMudTemperature,
        airTemperature: obj.airTemperature,
        airVelocity: obj.airVelocity,
        numberOfTanks: obj.numberOfTanks,
        nominalVolume: obj.nominalVolume,
        activeVolumeFraction: obj.activeVolumeFraction,
        exposedSurfaceArea: obj.exposedSurfaceArea,
        agitatorPower: obj.agitatorPower
      }
    },
    fromFormGroup: (formValue: MudPitsForm): MudPit | null => {
      const { ...commonValues } = formValue;

      const rawValueData = this.form.formGroup.getRawValue();

      return {
        ...commonValues,
        isNewMudTemperature: formValue.isNewMudTemperature,
        airTemperature: +rawValueData.airTemperature,
        airVelocity: +rawValueData.airVelocity,
        numberOfTanks: +rawValueData.numberOfTanks,
        nominalVolume: +rawValueData.nominalVolume,
        activeVolumeFraction: +rawValueData.activeVolumeFraction,
        exposedSurfaceArea: +rawValueData.exposedSurfaceArea,
        agitatorPower: +rawValueData.agitatorPower
      }
    }
  });

  public getTotalMudVolume(updatedInput, e) {
    const data: { activeVolumeFraction: number, nominalVolume: number, numberOfTanks: number }[] = [];

    Object.entries(this.form.formGroup.controls).forEach(control => {
      if (control[0] === 'activeVolumeFraction' || control[0] === 'nominalVolume' || control[0] === 'numberOfTanks') {
        if (control[0] !== updatedInput) {
          data[control[0]] = +control[1].value;
        } else {
          data[updatedInput] = e.currentTarget.value;
        }
      }
    });
    this.totalMudVolume = +(data['activeVolumeFraction'] * data['nominalVolume'] * 0.01 * data['numberOfTanks']).toFixed(2);
  }

  ngAfterViewInit(): void {    
    this._udtService.getUndisturbedTemperaturePoints().subscribe(res => {
      this.form.formGroup.controls.airTemperature
        .setValue((this.form.formGroup.value.airTemperature ?? res[0].temperature), { emitEvent: false });
    });

    this.setUnitsAndData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['previousOperationMudPits'] || changes['disableMudPits'].currentValue == false) {
      this.updateFormControlsState();
      this.setUnitsAndData();
    }
  }

  private updateFormControlsState(): void {
    if (this.previousOperationMudPits == null) {
      if (!this.form.formGroup.controls.isNewMudTemperature.value) {
        this.form.formGroup.controls.isNewMudTemperature.setValue('newMudTemperature');
      }
      this.form.formGroup.controls.isNewMudTemperature.disable({ emitEvent: false });
    } else {
      if (!this.form.formGroup.controls.isNewMudTemperature.value) {
        this.form.formGroup.controls.isNewMudTemperature.setValue('newMudTemperature');
      }
      this.form.formGroup.controls.isNewMudTemperature.enable({ emitEvent: false });
    }
    this.disableConfiguration({ value: this.form.formGroup.value.isNewMudTemperature });
  }

  public disableConfiguration(e) {
    this.startWithNewMudPit = e.value === 'isStartingFromPreviousPutConditions';

    if (e.value === 'isStartingFromPreviousPutConditions') {
      const fields = [
        'numberOfTanks',
        'nominalVolume',
        'activeVolumeFraction',
        'exposedSurfaceArea',
        'agitatorPower'
      ];
    
      fields.forEach(field => {
        const value = this.previousOperationMudPits[field as keyof MudPit];
        this.form.formGroup.controls[field].setValue(value, { emitEvent: false });
      });
    }

    const fieldsToToggle = [
      'exposedSurfaceArea',
      'agitatorPower',
      'nominalVolume',
      'numberOfTanks',
      'activeVolumeFraction'
    ];
    
    fieldsToToggle.forEach(field => 
      this.form.formGroup.controls[field][this.startWithNewMudPit ? 'disable' : 'enable']({ emitEvent: false })
    );

    if (this.previousOperationMudPits != null || (this.previousOperationMudPits && this.form.formGroup.controls.numberOfTanks.value != this.previousOperationMudPits.numberOfTanks)) {
      this.form.formGroup.controls.numberOfTanks.setValue(this.previousOperationMudPits.numberOfTanks, { emitEvent: false });
      this.form.formGroup.controls.numberOfTanks.disable({ emitEvent: false });
    }
  }

  private setUnitsAndData() {
    this.tempUnit = this.userUnits.temperature;
    this.pressureUnit = unitsLib[this.userUnits.pressure].symbol;
    this.volumeUnit = unitsLib[this.userUnits.volume].symbol;
  
    if (!this.form.formGroup.controls.nominalVolume.value) {
      this.form.formGroup.controls.nominalVolume.setValue(getVolumeValueFromBbl(500, this.volumeUnit));
    }
  
    if (!this.form.formGroup.controls.airTemperature.value) {
      this.form.formGroup.controls.airTemperature.setValue(GetValueFromFahrenheit(70, this.tempUnit));
    }
  
    if (!this.form.formGroup.controls.newMudTemperature.value) {
      this.form.formGroup.controls.newMudTemperature.setValue(GetValueFromFahrenheit(70, this.tempUnit));
    }
  
    this.getTotalMudVolume(null, null);
  
    this.updateFormControlsState();
  }
}