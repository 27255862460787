import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { validateCustomProfile } from 'src/app/perivis/shared/validators/custom-profile.validator';
import { CustomIPP, InternalPressureProfileType, PressurePoint } from '../../models/internal-pressure-profile.model';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { unitsLib } from 'src/app/core/services/unit-library';

@Component({
    selector: 'app-custom-ipp',
    template: `
      <form [formGroup]="form.formGroup">
        <p
          class="validation-error"
          *ngIf="form.formGroup.controls.pressurePoints.errors?.lastPointAboveShoe"
          >Must be specified from hanger: {{ currentString?.hangerMd.toFixed(2) }} {{ userUnits.longLengths }} and to or
          below shoe: {{ currentString?.shoeMd.toFixed(2) }} {{ userUnits.longLengths }}, but above well TD: {{ wellTd.toFixed(2) }} {{ userUnits.longLengths }}
        </p>
        <app-ng-table-grid
          [scrollHeight]="'300px'"
          tableName="Custom Internal Pressures"
          [tableData]="customPressures"
          [columnDefinitions]="columnDefinitions"
          [newRowFormGroup]="newDataRow"
          [inputFields]="inputFields"
          (dataChange)="onCustomPressuresChange($event)"
          [isDynamicLoaded]="true"
          />
      </form>
    `,
    styles: [`
  `],
    providers: subformComponentProviders(CustomIppComponent),
    standalone: false
})
export class CustomIppComponent implements AfterViewInit, OnChanges {

  @Input() currentString: any;
  @Input() userUnits: UserUnitsModel;
  @Input() wellTd: number;

  public lastPointAboveShoe: boolean;
  public customPressures: PressurePoint[];

  public form = createForm<CustomIPP>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(InternalPressureProfileType.CUSTOMIPP),
      pressurePoints: new UntypedFormControl(null)
    }
  });

  public get columnDefinitions(): { field: string, header: string }[] {
    return [
      { header: 'MD (' + this.userUnits.longLengths + ')', field: 'measuredDepth' },
      { header: 'Pressure (' + unitsLib[this.userUnits.pressure].symbol + ')', field: 'pressure' }
    ];
  }

  public inputFields = [{
    name: 'measuredDepth',
    minFractions: 2,
    maxFractions: 6,
    formatDecimals: 4
  },
  {
    name: 'pressure',
    minFractions: 2,
    maxFractions: 6,
    formatDecimals: 4
  }];

  ngAfterViewInit(): void {
    this.form.formGroup.controls.pressurePoints.addValidators(validateCustomProfile(this.currentString, this.wellTd));

    if (this.form.formGroup.controls.pressurePoints?.value) {
      this.customPressures = this.form.formGroup.controls.pressurePoints.value;
    } else {
      this.form.formGroup.controls.pressurePoints.setValue(this.createInitialCustomPressurePoints());
      this.customPressures = this.form.formGroup.controls.pressurePoints.value;
    }
  }

  ngOnChanges(): void {
    this.form.formGroup.controls.pressurePoints.addValidators(validateCustomProfile(this.currentString, this.wellTd));

    if (this.form.formGroup.controls.pressurePoints?.value) {
      this.customPressures = this.form.formGroup.controls.pressurePoints.value;
    } else {
      this.form.formGroup.controls.pressurePoints.setValue(this.createInitialCustomPressurePoints());
      this.customPressures = this.form.formGroup.controls.pressurePoints.value;
    }
  }

  public newDataRow(): UntypedFormGroup {
    return new UntypedFormGroup({
      measuredDepth: new UntypedFormControl(0, [Validators.required]),
      pressure: new UntypedFormControl(null, [Validators.required])
    });
  }

  public onCustomPressuresChange(v: { dataRows: any[], reload: boolean, triggeredBy: any }) {
    if (v.triggeredBy.type === 'reset') {
      this.customPressures = this.createInitialCustomPressurePoints();
      this.form.formGroup.controls.pressurePoints.setValue(this.customPressures);
    } else {
      this.form.formGroup.controls.pressurePoints.setValue(v.dataRows);
    }
  }

  private createInitialCustomPressurePoints() {
    return [{ measuredDepth: 0, pressure: 0 }];
  }

}
