<div #toolbarDiv>
  <p-toolbar>

    <ng-template #start>
      <div class="flex items-center space-x-2 toolbar-menu">
        <p-select [(ngModel)]="temperatureResultsStore.state.selectedSingleMultiple" [options]="singleMultiple"
          (onChange)="modeToggle($event.value)" appendTo="body">
        </p-select>

        <p-selectButton [options]="resultsDisplay" [(ngModel)]="temperatureResultsStore.state.selectedResultsDisplay"
          (onChange)="onSelectedResultsDisplayUpdated($event)" [allowEmpty]="false">
        </p-selectButton>

        <div *ngIf="temperatureResultsStore.state?.selectedResultsDisplay === 'grid'">
          <p-checkbox size="small" binary="true" [(ngModel)]="temperatureResultsStore.state.showHeatmap"
            (change)="toggleHeatmap($event)">
          </p-checkbox>
          <label class="ml-2">Heatmap</label>
        </div>

        <p-selectButton
          *ngIf="temperatureResultsStore.state?.selectedResultsDisplay === 'plot' || temperatureResultsStore.state.selectedResultsDisplay === 'heatmap'"
          [options]="depthView" [(ngModel)]="temperatureResultsStore.state.selectedDepthView"
          (onChange)="depthViewToggle($event)" [allowEmpty]="false">
        </p-selectButton>
      </div>
    </ng-template>

    <ng-template #center>
      <div class="flex space-x-2 items-center">

        <p-checkbox *ngIf="temperatureResultsStore.state?.selectedSingleMultiple === 'singleOperation'" size="small"
          (onChange)="syncPressAndTemp($event)" [(ngModel)]="temperatureResultsStore.state.syncThermalResults"
          title="Sync P&T results" binary="true">
        </p-checkbox>
        <p-select *ngIf="temperatureResultsStore.state?.selectedSingleMultiple === 'singleOperation'"
          [options]="operations" [(ngModel)]="temperatureResultsStore.state.selectedOperation"
          (onChange)="onOperationSelected($event, true)" optionLabel="name" appendTo="body">
          <ng-template pTemplate="selectedOperation">
            {{ temperatureResultsStore.state?.selectedOperation?.name }}
          </ng-template>
          <ng-template let-selectedOperation pTemplate="selectedOperation">
            {{ selectedOperation?.name }}
          </ng-template>
        </p-select>

        <p-select *ngIf="temperatureResultsStore.state?.selectedSingleMultiple === 'multipleOperation' ||
                temperatureResultsStore.state?.selectedSingleMultiple === 'operationType'" [options]="annulusNames"
          (onChange)="annulusDropdownChange($event)" [(ngModel)]="temperatureResultsStore.state.selectedAnnulus"
          [disabled]="temperatureResultsStore.state?.selectedSingleMultiple === 'operationType'"
          appendTo="body" 
          >
        </p-select>

        <p-button outlined="true" size="small" [disabled]="moveUpDisabled" icon="pi pi-arrow-up"
          (onClick)="onUpDownButtonClick('up', true)">
        </p-button>
        <p-button outlined="true" size="small" [disabled]="moveDownDisabled" icon="pi pi-arrow-down"
          (onClick)="onUpDownButtonClick('down', true)">
        </p-button>

        <p-select 
          class="ml-2"
          [options]="phases" [ngModel]="temperatureResultsStore.state.selectedPhase"
          (onChange)="onPhaseSelected($event, true)" optionLabel="phaseNumber"
          *ngIf="phases?.length > 1 &&
            temperatureResultsStore.state?.selectedSingleMultiple === 'singleOperation'"
          appendTo="body">
          <ng-template pTemplate="selectedItem">
            Phase {{ temperatureResultsStore.state.selectedPhase?.phaseNumber }}
          </ng-template>
          <ng-template let-phase pTemplate="item">
            Phase {{ phase.phaseNumber }}
          </ng-template>
        </p-select>

      </div>
    </ng-template>

    <ng-template #end *ngIf="temperatureResultsStore.state?.selectedSingleMultiple === 'singleOperation'">

      <div class="flex space-x-2">
        <div>
          <p-checkbox size="small" [(ngModel)]="temperatureResultsStore.state.showWellborePoints"
            (onChange)="toggleWellborePoints($event)" binary="true">
          </p-checkbox>
          <label class="ml-2">Wellbore</label>
        </div>

        <div class="mr-2">
          <p-checkbox size="small" [(ngModel)]="temperatureResultsStore.state.showFormationPoints"
            (onChange)="toggleFormationPoints($event)" binary="true">
          </p-checkbox>
          <label class="ml-2">Formation</label>
        </div>
      </div>

    </ng-template>

    <ng-template *ngIf="temperatureResultsStore.state?.selectedSingleMultiple !== 'singleOperation'" #end></ng-template>

  </p-toolbar>
</div>

<app-tool-loader [isLoading]="isLoading">

  <app-ng-table-results
    *ngIf="temperatureResultsStore.state?.selectedResultsDisplay === 'grid'" 
    class="mt-2" 
    tableName="Thermal Results - Temperatures" 
    [tableData]="resultsGrid"
    [columnDefinitions]="
      temperatureResultsStore.state?.selectedSingleMultiple === 'singleOperation'
        ? cols
        : operationNames
    " 
    [tableHeight]="tableHeight" [selectedOperation]="temperatureResultsStore.state.selectedOperation"
    [minTemp]="minTemp" 
    [maxTemp]="maxTemp"
    [showHeatmap]="temperatureResultsStore.state.showHeatmap" 
    [virtualScroll]="true"/>

  <app-thermal-results-plot class="mt-2" *ngIf="temperatureResultsStore.state?.selectedResultsDisplay === 'plot'"
    xAxisTitle="Temperature ({{ temperatureUnit }})" depthUnit="{{ depthUnit }}" [temperatureUnit]="temperatureUnit"
    [diameterUnit]="diameterUnit" [plotTitle]="'Thermal Results - Temperatures'" [plotData]="
      temperatureResultsStore.state?.selectedSingleMultiple ===
      'singleOperation'
        ? results
        : multipleResultsFull
    " [columnDefinitions]="
      temperatureResultsStore.state?.selectedSingleMultiple ===
      'singleOperation'
        ? cols
        : operationNames
    "
    [selectedDepthDisplay]="temperatureResultsStore.state.selectedDepthView"
    [plotName]="plotName"
    [tubulars]="tubulars"
    [selectedAnnulus]="annulusName"
    [componentHeight]="componentHeight"
    [downloadPlotName]="temperatureResultsStore.state.selectedSingleMultiple === 'singleOperation' ? 'single_operation_plot' : 'multiple_operation_plot'"
    [thermalResultsStore]="temperatureResultsStore"
    ></app-thermal-results-plot>

  <app-heatmap class="mt-2" *ngIf="temperatureResultsStore.state?.selectedResultsDisplay === 'heatmap'"
    [depthUnit]="depthUnit" [heatGridHeaders]="
      temperatureResultsStore.state?.selectedSingleMultiple ===
      'singleOperation'
        ? cols
        : operationNames
    " [heatGrid]="
      temperatureResultsStore.state?.selectedSingleMultiple ===
      'singleOperation'
        ? results
        : multipleResultsFull
    " [useTvd]="temperatureResultsStore.state?.selectedDepthView === 'tvd'" [componentHeight]="componentHeight"
    [plotTitle]="'Thermal Results - Temperatures'" [plotName]="plotName"
    [heatmapStore]="temperatureResultsStore"
    >
  </app-heatmap>

  <p-dialog [draggable]="false" [(visible)]="missingOperations" [closable]="false">
    <i class="pi pi-exclamation-circle"></i> No Operations Exist: Please specify
    at least one operation to view results. <br />
    <br />
  </p-dialog>
  <p-dialog [draggable]="false" [visible]="isDialogVisible" [closable]="false">
    <i class="pi pi-exclamation-circle"></i> {{ calculationError }} <br />
    <br />
  </p-dialog>
</app-tool-loader>