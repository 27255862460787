<app-tool-loader [isLoading]="isLoading" positionUnset="true">
    <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
    <form *ngIf="pericalOptionsForm" [formGroup]="pericalOptionsForm" class="flex flex-col space-y-2 p-2">

        <div class="flex flex-col">
            <label for="maximumGridSpacing">Vertical Grid Spacing</label>
            <p-inputgroup>
                <p-inputNumber [minFractionDigits]="1" formControlName="maximumGridSpacing">
                </p-inputNumber>
                <p-inputgroup-addon>{{ verticalGridUnit }}</p-inputgroup-addon>
            </p-inputgroup>
            <p *ngIf="pericalOptionsForm.controls.maximumGridSpacing.value && pericalOptionsForm.controls.maximumGridSpacing.invalid"
                class="validation-error">
                Allowed range: {{ this.gridSpacingValidation.min }} - {{ this.gridSpacingValidation.max }}
            </p>
        </div>

        <div *ngIf="!hideGridRefinement">
            <p-checkbox size="small" binary="true" formControlName="disableInterfaceRefinement">
            </p-checkbox>
            <label class="ml-1">Disable Interface Refinement (Altus only)</label>
        </div>

        <label class="underline">Energy & Enthalpy Effects</label>

        <p class="text-sm">Adjustment factors are built into the thermal numerical solution scheme to promote convergence and
            maintain stability. Alternative values to the internal defaults may be specified here and selectively
            applied to individual operations:
        </p>

        <div class="flex flex-col">
            <label for="successiveRelationFactor">Successive Relaxation Factor (Energy Terms)
                <i title=" The default of value of 0.3 is tuned to provide stability but not slow down convergence."
                    class="pi pi-info-circle"></i>
            </label>
            <p-inputNumber [minFractionDigits]="1" formControlName="successiveRelationFactor"></p-inputNumber>
            <p *ngIf="pericalOptionsForm.controls.successiveRelationFactor.value && pericalOptionsForm.controls.successiveRelationFactor.invalid"
                class="validation-error">
                Allowed range: 0.01 - 2
            </p>
        </div>

        <div class="flex flex-col">
            <label for="latentHeatDampingFactor">Latent Heat Damping Factor (Stability)</label>
            <p-inputNumber [minFractionDigits]="1" formControlName="latentHeatDampingFactor"></p-inputNumber>
            <p *ngIf="pericalOptionsForm.controls.latentHeatDampingFactor.value && pericalOptionsForm.controls.latentHeatDampingFactor.invalid"
                class="validation-error">
                Allowed range: 0.01 - 1000
            </p>
        </div>

        <div>
            <p-checkbox size="small" binary="true" formControlName="includeLatentHeatPhaseChange">
            </p-checkbox>
            <label class="ml-1">
                Include Latent Heat of Phase Change
                <i title="If Latent Heat of Phase Change is included, it may be necessary to reduce the Relaxation Factor, e.g. 0.1."
                    class="pi pi-info-circle">
                </i>
            </label>
        </div>

    </form>
</p-scrollPanel>
</app-tool-loader>