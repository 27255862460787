import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CustomPoint, UndisturbedTemperature, UndisturbedTemperaturePlot } from '../../wellbore-inputs/models/undisturbed-temperature.model';
import { catchError, map, shareReplay } from 'rxjs/operators';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class UdtService {

  constructor(private http: HttpClient) { }
  private temperaturePointsCache$: Observable<CustomPoint[]> = null;
  private undisturbedTemperatureCache: Record<string, Observable<UndisturbedTemperature>> = {};
  private undisturbedTemperaturePlotCache$: Observable<UndisturbedTemperaturePlot> = null;

  public getUndisturbedTemperature(skipUnits = false): Observable<UndisturbedTemperature> {
    const cacheKey = skipUnits.toString();
    if (!this.undisturbedTemperatureCache[cacheKey]) {
      const url = `${PeriforApiEndpoint}/UndisturbedTemperature?skipUnits=${skipUnits}`;
      this.undisturbedTemperatureCache[cacheKey] = this.http.get(url).pipe(
        map(data => new UndisturbedTemperature(data)),
        catchError(() => of(null as UndisturbedTemperature)),
        shareReplay(1)
      );
    }
    setTimeout(() => this.undisturbedTemperatureCache[cacheKey] = null, 1000);
    return this.undisturbedTemperatureCache[cacheKey];
  }

  updateUndisturbedTemperature(undisturbedTemperature: UndisturbedTemperature) {
    return this.http.put(`${PeriforApiEndpoint}/UndisturbedTemperature`, undisturbedTemperature, httpOptions);
  }

  public getUndisturbedTemperaturePlot(): Observable<UndisturbedTemperaturePlot> {
    if (!this.undisturbedTemperaturePlotCache$) {
      this.undisturbedTemperaturePlotCache$ = this.http
        .get(`${PeriforApiEndpoint}/UndisturbedTemperature/plot`)
        .pipe(
          map(data => new UndisturbedTemperaturePlot(data)),
          catchError(() => of(null as UndisturbedTemperaturePlot)),
          shareReplay(1)
        );
    }
    setTimeout(() => this.undisturbedTemperaturePlotCache$ = null, 1000);
    return this.undisturbedTemperaturePlotCache$;
  }

  getUndisturbedTemperaturePoints(): Observable<CustomPoint[]> {
    if (!this.temperaturePointsCache$) {
      this.temperaturePointsCache$ = this.http.get<CustomPoint[]>(`${PeriforApiEndpoint}/UndisturbedTemperature/points`)
        .pipe(
          map(data => data.map(item => new CustomPoint(item))),
          catchError(() => {
            return of([]);
          }),
          shareReplay(1)
        );
    }
    setTimeout(() => this.temperaturePointsCache$ = null, 1000);
    return this.temperaturePointsCache$;
  }
}
