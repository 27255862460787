<form [formGroup]="form.formGroup">
  <div *ngIf="!isAnnulus" class="pb-3 flex flex-col">
    <label for="pressure">Pressure Location</label>
    <p-inputgroup>
      <p-select [disabled]="isReadOnly" [options]="pressureLocations"
        [formControlName]="form.formControlNames.pressureLocation" optionLabel="name" optionValue="value"
        appendTo="body"></p-select>
      <p-inputnumber [minFractionDigits]="1" [formControlName]="form.formControlNames.wellheadPressure" />
      <p-inputgroup-addon>{{ pressureUnit }}</p-inputgroup-addon>
    </p-inputgroup>
    <p *ngIf="
            form.formGroup.controls.wellheadPressure.value &&
            form.formGroup.controls.wellheadPressure.invalid
          " class="text-500-red text-sm">
      Allowed range: {{ pressureValidation.min }} - {{ pressureValidation.max }} {{ pressureUnit }}
    </p>
  </div>
</form>