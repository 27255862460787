import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { unitsLib } from 'src/app/core/services/unit-library';
import { Choke } from 'src/app/perical/models/chokes.model';
import { Reservoir } from 'src/app/perical/models/reservoirPerformance';
import { RestartOperationForm } from './restart-operation/restart-operation.component';

export interface NodalAnalysisForm {
  upstreamPressure: number;
  upstreamTemperature: number;
  pvtConditions: string;
  reservoir: Reservoir;
  choke: Choke;
  chokeFlowCheck: boolean;
  includeRestartOperation: boolean;
  restartOperation: RestartOperationForm;
}

@Component({
  selector: 'app-nodal-analysis',
  templateUrl: './nodal-analysis.component.html',
  styles: [``],
  providers: subformComponentProviders(NodalAnalysisComponent),
  standalone: false
})
export class NodalAnalysisComponent implements AfterViewInit, OnChanges {
  @Input()
  public reservoirs: Reservoir[];

  @Input()
  public chokes: Choke[];

  @Input()
  public userUnits: UserUnitsModel;

  @Input()
  public pressureValidation: { min: number, max: number };

  @Input()
  public temperatureValidation: { min: number, max: number };

  @Input()
  public disableNodal = false;

  @Input()
  public pressureLabel: string;

  @Input()
  public defaultPressure: number;

  @Input()
  public defaultTemperature: number;

  @Input()
  public temperatureLabel: string;
    
  public pvtCondition: string | null = null;
  public pvtConditions: any;
  public tempUnit: string;
  public pressureUnit: string;
  public includeRestartOperation = false;

  ngAfterViewInit(): void {
    if (!this.form.formGroup.get('pvtConditions').value) {
      this.form.formGroup.get('pvtConditions').setValue('bottomHole', { emitEvent: false});
      this.pvtCondition = 'bottomHole';
      this.form.formGroup.controls.reservoir.setValue(this.reservoirs?.length > 0 ? this.reservoirs[0] : null, { emitEvent: false });
    } else if (!this.disableNodal) {
      this.pvtCondition = this.form.formGroup.get('pvtConditions').value;
    }

    this.setValidators();
  }

  private setValidators() {
    const controls = this.form.formGroup.controls;
    const controlNames = [
      'upstreamPressure',
      'upstreamTemperature',
      'reservoir',
      'choke'
    ];
  
    controlNames.forEach(controlName => {
      const control = controls[controlName];
      if (this.disableNodal) {
        control.disable({ emitEvent: false });
      } else {
        control.enable({ emitEvent: false });
      }
    });
  
    controls.reservoir.setValidators(
      this.disableNodal ? null : Validators.required
    );
    controls.reservoir.updateValueAndValidity({ emitEvent: false });
    
    this.tempUnit = this.userUnits.temperature;
    this.pressureUnit = unitsLib[this.userUnits.pressure].symbol;

    this.form.formGroup.controls.upstreamPressure.setValidators([
      Validators.required,
      Validators.min(this.pressureValidation.min),
      Validators.max(this.pressureValidation.max)
    ]);

    this.form.formGroup.controls.upstreamTemperature.setValidators([
      Validators.required,
      Validators.min(this.temperatureValidation.min),
      Validators.max(this.temperatureValidation.max)
    ]);

    this.form.formGroup.controls.reservoir.setValidators(Validators.required);
    this.form.formGroup.controls.reservoir.updateValueAndValidity({ emitEvent: false });

    this.form.formGroup.controls.upstreamPressure.updateValueAndValidity({ emitEvent: false });
    this.form.formGroup.controls.upstreamTemperature.updateValueAndValidity({ emitEvent: false });
  }

  ngOnChanges(): void {
    if (this.disableNodal) {
      return;
    }
    if (!this.form.formGroup.get('upstreamPressure').value) {
      this.form.formGroup.get('upstreamPressure').setValue(this.defaultPressure);
    }
    if (!this.form.formGroup.get('upstreamTemperature').value) {
      this.form.formGroup.get('upstreamTemperature').setValue(this.defaultTemperature);
    }
    if (!this.form.formGroup.get('reservoir').value && this.reservoirs.length > 0) {
      this.form.formGroup.get('reservoir').setValue(this.reservoirs[0]);
    }

    this.setValidators();
  }
  
  public pvtConditionsChange(e) {
    this.pvtCondition = e;
    this.form.formGroup.get('pvtConditions').setValue(e);
  }

  public form = createForm<NodalAnalysisForm>(this, {
    formType: FormType.SUB,
    formControls: {
      upstreamPressure: new UntypedFormControl(null),
      upstreamTemperature: new UntypedFormControl(null),
      pvtConditions: new UntypedFormControl(null),
      reservoir: new UntypedFormControl(null),
      choke: new UntypedFormControl(null),
      chokeFlowCheck: new UntypedFormControl(false),
      includeRestartOperation: new UntypedFormControl(false),
      restartOperation: new UntypedFormControl(null)
    }
  });

  getReservoirPlaceholderText(data: any) {
    if (data == null) {
      return "Select Reservoir (Required)";
    }
    const reservoirExists = this.reservoirs.findIndex(x =>
      x.pressure === data.pressure &&
      x.permeability === data.permeability &&
      x.height === data.height &&
      x.radialExtent === data.radialExtent &&
      x.skinFactor === data.skinFactor &&
      x.applyCustomPerformanceIndex === data.applyCustomPerformanceIndex &&
      x.performanceIndex === data.performanceIndex
  ) >= 0;

    return reservoirExists ? data.name : data.name + ' (local only)';
  }

  getChokePlaceholderText(data: any) {
    if (data == null) {
      return "Select Choke (Optional)";
    }
    const chokeExists = this.chokes.findIndex(x =>
      x.name === data.name &&
      x.criticalLiquidFlowFactor === data.criticalLiquidFlowFactor &&
      x.flowCoefficientSchedule === data.flowCoefficientSchedule &&
      x.flowCoefficientSchedule.length === data.flowCoefficientSchedule.length
    ) >= 0;

    return chokeExists ? data.name : data.name + ' (local only)';
  }
}
