<form [formGroup]="form.formGroup">

  <!-- <div class="pb-5 field" >
    <div class="flex items-center">
      <p-checkbox
        [formControlName]="form.formControlNames.useMudPits"
        id="useMudPits"
        binary="true"
        (onChange)="this.useMudPits = $event.checked;"
      ></p-checkbox>
      <label for="useMudPits" class="ml-2">Circulate through Mud Pits</label>
    </div>
  </div> -->
  
  <p-tabs value="0">
    <p-tablist>
      <p-tab value="0">Operation Details</p-tab>
      <p-tab value="1">Drill String
        <i class="validation-error pi pi-exclamation-circle pl-1"
          *ngIf="drillpipeMissing" pTooltip="Drill string not specified."
        ></i>
      </p-tab>
      <p-tab value="2" *ngIf="showRiser">Riser
        <i class="validation-error pi pi-exclamation-circle pl-1"
          *ngIf="
            form.formGroup.controls?.insideDiameter.invalid ||
            form.formGroup.controls?.outsideDiameter.invalid
          " pTooltip="Operation not saved, some required inputs not specified."
        ></i>
      </p-tab>
      <p-tab *ngIf="useMudPits" value="3">Mud Pits
        <i
          class="validation-error pi pi-exclamation-circle pl-1"
          *ngIf="form.formGroup.controls.mudPits.invalid"
          pTooltip="Operation not saved, double check Mud Pit inputs."
        ></i> 
      </p-tab>
    </p-tablist>

    <p-tabpanels>
      <p-tabpanel value="0">

        <!-- <div [hidden]="true" class="flex flex-col">
          <label for="pressure"> Time since last operation</label>
          <p-inputgroup>
            <p-toggleswitch [formControlName]="form.formControlNames.isRunInHole" (onChange)="rihChange($event, true)"
              binary="true" [disabled]="isReadOnly">
            </p-toggleswitch>
            <p-inputNumber [disabled]="rihDisabled" [formControlName]="form.formControlNames.runInHoleTime">
            </p-inputNumber>
            <p-inputgroup-addon>hr</p-inputgroup-addon>
            <p *ngIf="form.formGroup.controls.runInHoleTime.invalid" class="validation-error">
              Allowed range: 0 - 100000 hrs
            </p>
          </p-inputgroup>
        </div> -->

        <div class="flex flex-wrap gap-4">
          <!-- Left Column -->
          <div class="flex flex-col flex-1 min-w-[200px] space-y-2">

            <app-standard-fluid [formControlName]="form.formControlNames.standardFluidFlow"
              [operationType]="operationType" [userUnits]="userUnits">
            </app-standard-fluid>

            <div class="flex flex-col">
              <label for="startDrillingMd">Start Drilling Depth</label>
              <p-inputgroup>
                <p-inputnumber [minFractionDigits]="1" [formControlName]="form.formControlNames.startDepth"
                  (onBlur)="onDepthRangeChange($event, 'startDepth')" />
                <p-inputgroup-addon>{{ userUnits.longLengths }} MD</p-inputgroup-addon>
              </p-inputgroup>
              <p *ngIf="form.formGroup.controls.startDepth.invalid" class="validation-error">
                Allowed range: {{ startDrillingMd }} - {{ endDrillingMd }} {{ userUnits.longLengths }}
              </p>
            </div>

            <div class="flex flex-col">
              <label for="endDrillingMd">End Drilling Depth</label>
              <p-inputgroup>
                <p-inputnumber [minFractionDigits]="1" [formControlName]="form.formControlNames.endDepth" (onBlur)="endDepthChanged($event)" />
                <p-inputgroup-addon>{{ userUnits.longLengths }} MD</p-inputgroup-addon>
              </p-inputgroup>
              <p *ngIf="form.formGroup.controls.endDepth.invalid" class="validation-error">
                Allowed range: {{ startDrillingMd }} - {{ endDrillingMd }}
                {{ userUnits.longLengths }}
              </p>
            </div>

          </div>
          <!-- Right Column -->
          <div class="flex flex-col space-y-2">

            <div class="flex flex-col">
              <label for="fluids">Fluid</label>
              <p-select [disabled]="isReadOnly" [options]="fluids" [formControlName]="form.formControlNames.fluid"
                dataKey="id" appendTo="body" optionLabel="state.name" tooltipPosition="top">
                <ng-template pTemplate="selectedItem">
                  {{ form.formGroup.value.fluid?.state.name }}
                </ng-template>
                <ng-template let-fluid pTemplate="item">
                  {{ fluid.state.name }}
                </ng-template>
              </p-select>
            </div>

            <div class="flex flex-col">
              <label for="inletTemperature">Inlet Temperature</label>
              <p-inputgroup>
                <p-inputnumber [minFractionDigits]="1" [formControlName]="form.formControlNames.inletTemperature" />
                <p-inputgroup-addon>{{ tempUnit }}</p-inputgroup-addon>
              </p-inputgroup>
              <p *ngIf="form.formGroup.controls.inletTemperature.invalid" class="validation-error">
                Allowed range: {{ temperatureValidation.min }} - {{ temperatureValidation.max }} {{ tempUnit }}
              </p>
            </div>

            <div class="flex flex-col">
              <label for="pressure">Circ on Bottom? / Time</label>
              <p-inputgroup>
                <p-toggleswitch [formControlName]="form.formControlNames.isConditionHole"
                  (onChange)="circOnBottomChange($event, true)" binary="true" [disabled]="isReadOnly"></p-toggleswitch>
                <p-inputNumber [minFractionDigits]="2" [disabled]="conditionHoleDisabled"
                  [formControlName]="form.formControlNames.conditionHoleTime">
                </p-inputNumber>
                <p-inputgroup-addon>hr</p-inputgroup-addon>
                <p *ngIf="form.formGroup.controls.conditionHoleTime.invalid" class="validation-error">
                  Allowed range: 0 - 100000 hrs
                </p>
              </p-inputgroup>
            </div>

            <div class="flex flex-col">
              <label for="pressure">POOH? / Time</label>
              <p-inputgroup>
                <p-toggleswitch [formControlName]="form.formControlNames.isPoolOutOfHole" id="isPoolOutOfHole"
                  (onChange)="poohChange($event, true)" binary="true" [disabled]="isReadOnly">
                </p-toggleswitch>
                <p-inputNumber [minFractionDigits]="2" [disabled]="poohDisabled"
                  [formControlName]="form.formControlNames.poolOutOfHoleTime">
                </p-inputNumber>
                <p-inputgroup-addon checkboxAddon>hr</p-inputgroup-addon>
                <p *ngIf="form.formGroup.controls.poolOutOfHoleTime.invalid" class="validation-error">
                  Allowed range: 0 - 100000 hrs
                </p>
              </p-inputgroup>
            </div>
          </div>
        </div>

      </p-tabpanel>

      <p-tabpanel value="1">

        <p class="{{ drillpipeMissing ? 'validation-error' : 'text-xs mt-2 mb-1' }}">{{ selectedDrillPipe }}</p>
        <p class="text-xs mb-1" *ngIf="selectedHwdp && hasHwdp">{{ selectedHwdp }}</p>
        <p class="text-xs mb-2" *ngIf="selectedCollar && hasCollar">{{ selectedCollar }}</p>

        <div class="flex flex-col">
          <label for="totalFlowArea">Nozzle Flow Area</label>
          <p-inputgroup>
            <p-inputnumber [minFractionDigits]="1" [formControlName]="form.formControlNames.totalFlowArea" />
            <p-inputgroup-addon> {{ areaUnit }} </p-inputgroup-addon>
          </p-inputgroup>
          <p class="validation-error" *ngIf="form.formGroup.controls.totalFlowArea.invalid">
            Allowed range: {{ totalFlowAreaValidation.min }} - {{ totalFlowAreaValidation.max }} {{ areaUnit }}
          </p>
        </div>

      </p-tabpanel>

      <p-tabpanel value="2">

        <p-fieldset legend="Riser" class="cementing">
          <div class="flex flex-col span-y-2">
            <div class="flex flex-col">
              <label for="outsideDiameter">Outside Diameter</label>
              <p-inputgroup>
                <p-inputnumber [minFractionDigits]="1" [formControlName]="form.formControlNames.outsideDiameter" />
                <p-inputgroup-addon>{{ userUnits.shortLengths }}</p-inputgroup-addon>
              </p-inputgroup>
            </div>

            <div class="flex flex-col">
              <label for="outsideDiameter">Inside Diameter</label>
              <p-inputgroup>
                <p-inputnumber [minFractionDigits]="1" [formControlName]="form.formControlNames.insideDiameter" />
                <p-inputgroup-addon>{{ userUnits.shortLengths }}</p-inputgroup-addon>
              </p-inputgroup>
            </div>
          </div>
        </p-fieldset>

      </p-tabpanel>

      <p-tabpanel value="3">
        <app-mud-pit-details
          [disableMudPits]="!useMudPits"
          [previousOperationMudPits]="previousOperationMudPits"
          [userUnits]="userUnits"
          [formControlName]="form.formControlNames.mudPits"
        ></app-mud-pit-details>
      </p-tabpanel>

    </p-tabpanels>
  </p-tabs>

</form>