<div class="h-[calc(100%-24px)] w-full">
  <p-toolbar>
    <ng-template #start>
      <div class="toolbar-menu">
        <p-selectButton
          [options]="plotView"
          [(ngModel)]="trajectoryPlotStore.state.selectedPlotView"
          (onChange)="selectPlotView($event)"
          [allowEmpty]="false"
        ></p-selectButton>
      </div>
    </ng-template>
  </p-toolbar> 

  <app-tool-loader [isLoading]="isLoading" positionUnset="true">
    <div class="h-full w-full p-2">
      <app-three-dee-plot
        [hidden]="trajectoryPlotStore.state.selectedPlotView !== '3d' || isLoading"
        [xyzData]="trajectoryXyzData"
        [trajectoryPlotStore]="trajectoryPlotStore"
        [isDarkMode]="isDarkMode"
        [longLengths]="longLengthUnits"
      ></app-three-dee-plot>

      <app-two-dee-plot
        [hidden]="trajectoryPlotStore.state.selectedPlotView === '3d' || isLoading"
        [selectedPlot]="trajectoryPlotStore.state.selectedPlotView"
        [trajectoryData]="trajectoryData"
        [isDarkMode]="isDarkMode"
        [userUnits]="uu"
        [trajectoryPlotStore]="trajectoryPlotStore"
      ></app-two-dee-plot>
    </div>
  </app-tool-loader>
</div>
