<p-confirmDialog
  header="Confirmation"
  icon="pi pi-exclamation-triangle"
></p-confirmDialog>

<p-toolbar>
  <ng-template #start class="w-full">
    <div class="flex flex-col">
      <label class="mr-2" for="type">Type</label>
      <p-select
        inputId="type"
        (onChange)="getPipes($event.value)"
        [options]="pipeTypes"
        [(ngModel)]="pipesCatalogStore.state.selectedType"
        [ngModelOptions]="{standalone: true}"
      ></p-select>
    </div>
    
    <div class="flex flex-col pl-2">
      <label class="ml-2 mr-2" for="ods">Pipe OD Filter ({{ diameterUnit }})</label>
      <p-select
        inputId="ods"
        (onChange)="filterByOd($event)"
        [options]="pipeOds"
        [(ngModel)]="pipesCatalogStore.state.selectedOd"
        title="Pipe OD ({{ diameterUnit }})"
        [ngModelOptions]="{standalone: true}"
      ></p-select>
    </div>
  </ng-template>
</p-toolbar>

<form [formGroup]="pipesForm" novalidate>
  <ng-container formArrayName="pipes">
    
    <p-table
      styleClass="p-datatable-sm"
      [columns]="columnDefs"
      [resizableColumns]="true"
      [value]="pTableData"
      [scrollable]="true"
      [scrollHeight]="tableHeight"
      [virtualScroll]="true"
      [virtualScrollItemSize]="34"
      showGridlines
      minBufferPx="800"
      maxBufferPx="1800"
      dataKey="value.id"
      stateStorage="local"
      [stateKey]="'pipesCatalogTable@' + componentId"
    >
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th class="text-sm text-center" *ngFor="let col of columns" pResizableColumn>
            {{ col.header }}
          </th>
          <th class="text-center">
            <div style="display: flex;">
              <p-button
                outlined="true"
                size="small"
                severity="success"
                icon="pi pi-plus"
                [disabled]="pipesCatalog.invalid"
                (click)="onAddPipe()"
                title="Add Pipe"
              ></p-button>
              <p-button
                text
                size="small"
                icon="pi pi-file-excel"
                [disabled]="pipesCatalog.invalid"
                (click)="exportExcel()"
                title="Export to Excel"
              ></p-button>
            </div>
          </th>
          
        </tr>
      </ng-template>

      <ng-template pTemplate="body" let-pipeFg let-rIdx="rowIndex">
        <ng-container [formGroupName]="rIdx">
          <tr>
            <td class="text-right">
              <p-inputNumber
                formControlName="outsideDiameter"
                [minFractionDigits]="1"
                [title]="!isControlInvalid(pipeFg, 'outsideDiameter') ? 'Outside Diameter (' + diameterUnit + ')' : getControlErrors(pipeFg, 'outsideDiameter')"
              ></p-inputNumber>
            </td>
            <td class="text-right">
              <p-inputNumber
                formControlName="insideDiameter"
                [minFractionDigits]="1"
                [title]="!isControlInvalid(pipeFg, 'insideDiameter') ? 'Inside Diameter (' + diameterUnit + ')' : getControlErrors(pipeFg, 'insideDiameter')"
              ></p-inputNumber>
            </td>
            <td class="text-right">
              <p-inputNumber
                formControlName="weightPerFoot"
                [minFractionDigits]="1"
                [title]="!isControlInvalid(pipeFg, 'weightPerFoot') ? 'Weight Per Foot (' + weightUnit + ')' : getControlErrors(pipeFg, 'weightPerFoot')"
              ></p-inputNumber>
            </td>
            <td class="text-right">
              <p-inputNumber
                formControlName="driftDiameter"
                [minFractionDigits]="2"
                [title]="!isControlInvalid(pipeFg, 'driftDiameter') ? 'Drift (' + diameterUnit + ')' : getControlErrors(pipeFg, 'driftDiameter')"
              ></p-inputNumber>
            </td>
            <td class="text-right">
              <p-inputNumber
                formControlName="wallThickness"
                [minFractionDigits]="1"
                [title]="!isControlInvalid(pipeFg, 'wallThickness') ? 'Wall Thickness (' + diameterUnit + ')' : getControlErrors(pipeFg, 'wallThickness')"
              ></p-inputNumber>
            </td>
            <td class="text-center">
              <i
                title="Delete Pipe"
                class="pi pi-trash !text-red-400"
                (click)="onDeletePipe(rIdx)"
              ></i>
            </td>
          </tr>
        </ng-container>
      </ng-template>
    </p-table>
  </ng-container>
</form>
