import { UntypedFormGroup, ValidationErrors } from "@angular/forms";

export function isControlInvalid(formGroup: UntypedFormGroup, ctrl: string, skipCheckErrors?: boolean) :boolean {
    const formCtrl = formGroup.controls[ctrl];
    const isValid = formCtrl.touched && formCtrl.invalid; // Skipping dirty check here. This allows errors to show without the form field being dirty.
    return skipCheckErrors ? isValid : isValid && formCtrl.errors != null; // optionally skip checking errors
}

export function getControlErrors(formGroup: UntypedFormGroup, ctrl?: string): string {
    const formCtrlErrors = ctrl ? formGroup.controls[ctrl].errors : formGroup.errors;
    return formCtrlErrors
      ? mapControlErrors(formCtrlErrors)
      : null;
}

export function mapControlErrors(errors: ValidationErrors){
  return Object.keys(errors).map(k => {
    if (k == 'required') {
      return "Value is required";
    }
    if (k == 'min') {
      return `Minimum value is ${errors[k].min}`;
    }
    if (k == 'max') {
      return `Maximum value is ${errors[k].max}`;
    }
    return errors[k];
  }).join("\n\n");
}