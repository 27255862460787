<tr
  class="flex p-2 hover:bg-[var(--p-content-hover-background)]"
  [ngClass]="{ 'name': treeNode?.node.data.level === designLevel }"
  [title]="getWellExplorerLevelName(treeNode?.node.data.level)"
  [ttRow]="treeNode"
  [ttSelectableRowDblClick]="treeNode"
  (contextmenu)="filterContextMenu(treeNode.node.data.level)"
  (mouseover)="onMouseOver(treeNode)"
  (mouseleave)="hover = false; hoverNode = null"
>
  <td
    *ngFor="let col of columns; let i = index"
    [ngClass]="{ 'name': treeNode.node.data.level === designLevel }"
    class="w-full"
  >
    <div style="max-height: 16px;" class="flex items-center">
      <p-treeTableToggler
        *ngIf="i === 0 && treeNode.node.data.level !== designLevel"
        [ngClass]="{ 'name': treeNode.node.data.level === designLevel }"
        [rowNode]="treeNode"
        class="pr-1"
      ></p-treeTableToggler>
      <img 
        *ngIf="treeNode.node.data.level !== designLevel" 
        class="level-icon" 
        [src]="treeNode.node.data.icon" 
      />
      <span 
        class="pl-2 text-xs"
        [ngClass]="{ 'might-overflow-design': treeNode.node.data.level === designLevel, 'might-overflow': treeNode.node.data.level !== designLevel }" 
      >
        {{ rowData[col.field] }}
      </span>
    </div>
  </td>

  <td>
    <div *ngIf="hover && hoverNode === treeNode" class="flex gap-2">
      <span class="flex items-center gap-2">
        <!-- Add Button -->
        <i
          *ngIf="treeNode.node.data.level !== designLevel"
          class="pi pi-plus cursor-pointer !text-green-400"
          pTooltip="{{ 'Create New ' + getChildLevel(treeNode.node.data.level)?.slice(0, -1) | titlecase }}"
          (click)="addChildNode(treeNode.node)"
        ></i>

        <!-- Clone Button -->
        <i
          *ngIf="treeNode.node.data.level === designLevel"
          class="pi pi-copy cursor-pointer !text-blue-400"
          pTooltip="Clone"
          (click)="cloneDesign(treeNode.node)"
        ></i>

        <!-- Delete Button -->
        <i
          *ngIf="isAdmin"
          class="pi pi-trash cursor-pointer !text-red-400"
          pTooltip="Delete"
          (click)="deleteItem(treeNode.node)"
        ></i>

        <!-- Lock Icon -->
        <i
          *ngIf="!isAdmin"
          class="pi pi-lock text-gray-400 cursor-not-allowed"
          pTooltip="Only Admin level users have permissions to delete Well Explorer items"
        ></i>
      </span>
    </div>
  </td>
</tr>
