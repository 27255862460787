import { Component, Input, Output } from "@angular/core";
import { User } from "./user-model";
import { Subject } from "rxjs";
import { ConfirmationService } from "primeng/api";

@Component({
    selector: 'app-users-table',
    template: `
  <p-confirmDialog
      header="Confirmation"
      icon="pi pi-exclamation-triangle"
  ></p-confirmDialog>
  <p-table selectionMode="single" [value]="users" scrollHeight="800">
    <ng-template pTemplate="header">
      <tr>
        <th pSortableColumn="profile.email" class="px-4 py-2">Email</th>
        <th pSortableColumn="profile.firstName" class="px-4 py-2">First Name</th>
        <th pSortableColumn="profile.lastName" class="px-4 py-2">Last Name</th>
        <th pSortableColumn="profile.jobTitle" class="px-4 py-2">Job Title</th>
        <th pSortableColumn="profile.isLicensed" class="px-4 py-2">Licensed</th>
        <th class="px-4 py-2"></th>
      </tr>
    </ng-template>
    <ng-template let-people pTemplate="body" let-user>
      <tr
        class="h-20"
        (dblclick)="editUser(user)"
        [pSelectableRow]="user"
      >
        <td class="px-4 py-2">{{ user.profile.email }}</td>
        <td class="px-4 py-2">{{ user.profile.firstName }}</td>
        <td class="px-4 py-2">{{ user.profile.lastName }}</td>
        <td class="px-4 py-2">{{ user.profile.jobTitle }}</td>
        <td class="px-4 py-2">{{ user.isLicensed }}</td>
        <td class="px-4 py-2">
          <p-splitButton
            class="pr-4"
            (onDropdownClick)="setUser(user)"
            (onClick)="editUser(user)"
            [model]="editOptions"
            icon="pi pi-pencil"
            appendTo="body"
            size="sm"
          >
          </p-splitButton>
        </td>
      </tr>
    </ng-template>
</p-table>

`,
    styles: [``],
    providers: [ConfirmationService],
    standalone: false
})
export class UsersTableComponent {

  showEditUserForm: boolean;

  @Input() users: User[];

  @Output() userEdited = new Subject<User>();
  @Output() userRemoved = new Subject<User>();
  @Output() permissionsEdited = new Subject<User>();
  @Output() licenseToggled = new Subject<User>();
  @Output() inviteSent = new Subject<User>();

  constructor(private _confirmationService: ConfirmationService) { }

  editOptions: any[] = [
    { label: 'Remove', icon: 'pi pi-trash', command: () => this.removeUser() },
    { label: 'Toggle License', icon: 'pi pi-refresh', command: () => this.toggleLicense() },
    { label: 'Permissions', icon: 'pi pi-lock', command: () => this.editPermissions() },
    { label: 'Send Invite Email', icon: 'pi pi-envelope', command: () => this.sendInvite() }
  ];

  selectedUser: User;

  setUser(user: User) {
    this.selectedUser = user;
  }

  editUser(user: User) {
    this.setUser(user);
    this.userEdited.next(user);
  }

  removeUser() {
    this._confirmationService.confirm({
      message: 'Are you sure that you want to remove this user?',
      accept: () => {
        this.userRemoved.next(this.selectedUser);
      }
    });
  }

  editPermissions() {
    this.permissionsEdited.next(this.selectedUser);
  }

  toggleLicense() {
    this.licenseToggled.next(this.selectedUser);
  }

  sendInvite() {
    this.inviteSent.next(this.selectedUser);
  }
  
}
