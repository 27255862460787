export class XyLinePlotUi {
  public constructor(init?: Partial<XyLinePlotUi>) {
    this.xAxisRange = init && init.xAxisRange || null;
    this.xAxisTick = init && init.xAxisTick || null;
    this.yAxisRange = init && init.yAxisRange || null;
    this.yAxisTick = init && init.yAxisTick || null;
  }
  id: string;
  xAxisRange: number[];
  xAxisTick: number;
  yAxisRange: number[];
  yAxisTick: number;
}
