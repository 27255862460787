<form [formGroup]="form.formGroup">
  <p-tabs value="0">

    <p-tablist>
      <p-tab value="0">Operation Details</p-tab>
      <p-tab *ngIf="false && showOptionsAndNodal" value="1">Nodal
        <i
        class="validation-error pi pi-exclamation-circle pl-1"
        *ngIf="form.formGroup.controls.nodalAnalysis.invalid"
        pTooltip="Operation not saved, double check Nodal inputs."
      ></i> 
      </p-tab>
      <p-tab value="2">Options
        <i
        class="validation-error pi pi-exclamation-circle pl-1"
        *ngIf="form.formGroup.controls.customEnthalpyOptions.invalid"
        pTooltip="Operation not saved, double check Options."
      ></i> 
      </p-tab>
    </p-tablist>

    <p-tabpanels>

      <p-tabpanel value="0">

        <div class="flex gap-2">
          <div class="w-1/2 space-y-2">

            <div class="flex flex-col">
              <label for="fluids">Fluid</label>
              <p-select [disabled]="isReadOnly" [options]="fluids" [formControlName]="form.formControlNames.fluid"
                dataKey="id" appendTo="body" tooltipPosition="top" showDelay="100" hideDelay="500"
                (onChange)="fluidChangedEvent($event)"
                >
                <ng-template pTemplate="selectedItem">
                  {{ form.formGroup.value.fluid?.state.name }}
                </ng-template>
                <ng-template let-fluid pTemplate="item">
                  {{ fluid.state.name }}
                </ng-template>
              </p-select>
            </div>

            <div class="flex flex-col">
              <label for="perforation">Perforation</label>
              <p-select [disabled]="isReadOnly" [options]="perforations"
                [formControlName]="form.formControlNames.perforation" optionLabel="name" dataKey="id" appendTo="body"
                (onChange)="perfChange($event)">
              </p-select>
            </div>

            <div class="flex flex-col">
              <label for="tempAtPerfs">Temperature at Perfs</label>
              <p-inputgroup>
                  <p-inputnumber [minFractionDigits]="1" *ngIf="!includeNodalAnalysis" id="pressure" [formControlName]="form.formControlNames.tempAtPerfs"></p-inputnumber>
                <input *ngIf="includeNodalAnalysis" type="number" pInputText placeholder="Nodal Analysis Enabled"
                  [disabled]="true" />
                <p-inputgroup-addon *ngIf="!includeNodalAnalysis">{{ tempUnit }}</p-inputgroup-addon>
              </p-inputgroup>

              <p *ngIf="
                form.formGroup.controls.tempAtPerfs.value &&
                form.formGroup.controls.tempAtPerfs.invalid
              " class="validation-error">
                Allowed range: {{ temperatureValidation.min }} -
                {{ temperatureValidation.max }} {{ tempUnit }}
              </p>

            </div>

            <div class="flex flex-col">
              <label for="pressure">Pressure Location</label>
              <p-inputgroup>
                <p-select *ngIf="!includeNodalAnalysis" [disabled]="isReadOnly" [options]="pressureLocations"
                  [formControlName]="form.formControlNames.pressureLocation" optionLabel="name" optionValue="value"
                  appendTo="body" class="inputGroupDropdown">
                </p-select>
                <p-inputnumber [minFractionDigits]="1" *ngIf="!includeNodalAnalysis" id="pressure" [formControlName]="form.formControlNames.perforationPressure"></p-inputnumber>
                <input *ngIf="includeNodalAnalysis" type="number" pInputText placeholder="Nodal Analysis Enabled"
                  [disabled]="true" />
                <p-inputgroup-addon *ngIf="!includeNodalAnalysis">{{ pressureUnit }}</p-inputgroup-addon>
              </p-inputgroup>
              <p *ngIf="
                form.formGroup.controls.perforationPressure.value &&
                form.formGroup.controls.perforationPressure.invalid
              " class="validation-error">
                Allowed range: {{ pressureValidation.min }} -
                {{ pressureValidation.max }} {{ pressureUnit }}
              </p>
            </div>
          </div>

          <div class="w-1/2 space-y-2">
            <div [ngSwitch]="true">
              <app-standard-fluid *ngSwitchCase="
                form.formGroup.value.fluid?.state.type === FluidType.BRINE ||
                form.formGroup.value.fluid?.state.type ===
                  FluidType.STANDARDMUD ||
                form.formGroup.value.fluid?.state.type === FluidType.ADVANCEDMUD
              " [formControlName]="form.formControlNames.standardFluidFlow" [userUnits]="userUnits">
              </app-standard-fluid>
              <app-hydrocarbon-fluid [userUnits]="userUnits" [isReadOnly]="isReadOnly" *ngSwitchCase="
                form.formGroup.value.fluid?.state.type === FluidType.BLACKOIL ||
                form.formGroup.value.fluid?.state.type ===
                  FluidType.HYDROCARBON ||
                form.formGroup.value.fluid?.state.type === FluidType.VLE ||
                form.formGroup.value.fluid?.state.type === FluidType.CO2FLUID
              " [formControlName]="form.formControlNames.hydrocarbonFlow"
                [selectedFluid]="form.formGroup.value.fluid"></app-hydrocarbon-fluid>
            </div>
          </div>
        </div>
      </p-tabpanel>

      <p-tabpanel value="1">

        <p-fieldset>
          <ng-template pTemplate="header">
            <div class="flex items-center">
              <input
                type="checkbox"
                binary="true"
                [checked]="includeNodalAnalysis"
                (change)="includeNodalAnalysisChange($event)" 
              >
              <label class="pl-2" for="nodal-analysis">Nodal Analysis</label>
            </div>
          </ng-template>

          <app-nodal-analysis [disableNodal]="!includeNodalAnalysis"
            [formControlName]="form.formControlNames.nodalAnalysis" [reservoirs]="reservoirs" [chokes]="chokes"
            [userUnits]="userUnits" [pressureValidation]="pressureValidation"
            [temperatureValidation]="temperatureValidation" [pressureLabel]="'Flow Line Pressure'"
            [temperatureLabel]="'Flow Line Temperature'" [defaultPressure]="form.formGroup.value.perforationPressure"
            [defaultTemperature]="form.formGroup.value.tempAtPerfs"></app-nodal-analysis>

        </p-fieldset>
      </p-tabpanel>

      <p-tabpanel value="2">

        <div class="grid">
          <div class="col">

            <p-fieldset>
              <ng-template pTemplate="header">
                <div class="flex items-center space-x-2">
                  <input type="radio" binary="true"
                    (click)="enthalpyOptionsChange('internalDefaults')"
                    [checked]="form.formGroup.controls.enthalpyOptionsSource.value === 'internalDefaults'">
                  <label>Internal Defaults</label>

                </div>
              </ng-template>

              <label style="padding-left: 10px;">Successive Relaxation Factor: 0.3</label>
              <br>
              <label style="padding-left: 10px;">Latent Heat Damping Factor: 100.0</label>
              <br>
              <label style="padding-left: 10px;">Latent Heat of Phase Change: OFF</label>

            </p-fieldset>

            <br>

            <p-fieldset>

              <ng-template pTemplate="header">
                <div class="flex items-center space-x-2">
                  <input type="radio" binary="true"
                    (click)="enthalpyOptionsChange('newDefaults')"
                    [checked]="form.formGroup.controls.enthalpyOptionsSource.value === 'newDefaults'">
                  <label>New Defaults (Perical Options)</label>

                </div>
              </ng-template>

              <label style="padding-left: 10px;">Successive Relaxation Factor: {{
                pericalOptions.successiveRelationFactor }}</label>
              <br>
              <label style="padding-left: 10px;">Latent Heat Damping Factor: {{ pericalOptions.latentHeatDampingFactor
                }}</label>
              <br>
              <label style="padding-left: 10px;">Latent Heat of Phase Change: {{
                pericalOptions.includeLatentHeatPhaseChange == true ? 'ON' : 'OFF' }}</label>

            </p-fieldset>

            <br>

            <p-fieldset>

              <ng-template pTemplate="header">
                <div class="flex items-center space-x-2">
                  <input type="radio" binary="true"
                    (click)="enthalpyOptionsChange('custom')"
                    [checked]="form.formGroup.controls.enthalpyOptionsSource.value === 'custom'">
                  <label>Use Custom Energy & Enthalpy Options</label>
                </div>
              </ng-template>

              <app-enthalpy-options [formControlName]="form.formControlNames.customEnthalpyOptions"
                [disableCustomOptions]="form.formGroup.controls.enthalpyOptionsSource.value !== 'custom'"></app-enthalpy-options>
            </p-fieldset>

          </div>
        </div>

      </p-tabpanel>

    </p-tabpanels>

  </p-tabs>
</form>