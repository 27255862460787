<app-tool-loader [isLoading]="isLoading" positionUnset="true">

  <p-toolbar>
    <ng-template #start>
      <div class="flex gap-2 toolbar-menu">
        <p-selectButton [options]="resultsDisplay" 
          [(ngModel)]="packerResultsStore.state.selectedResultDisplay"
          (onChange)="toggleGridPlot($event)" [allowEmpty]="false">
        </p-selectButton>
        
        <p-select [options]="packers" appendTo="body" (onChange)="selectPacker($event)"  
          [(ngModel)]="packerResultsStore.state.selectedPacker">
        </p-select>
        
        <p-select *ngIf="packerResultsStore.state?.selectedResultDisplay === 'plot'" [options]="resultTypes" appendTo="body"
          (onChange)="toggleResultsView($event)">
          >
        </p-select>
        
        <p-select *ngIf="isDisplacementSelected == true || packerResultsStore.state?.selectedResultDisplay === 'grid'"
          (onChange)="displacementTypeChange($event)" optionValue="value"
          optionLabel="label" [options]="displacementTypes" appendTo="body" [(ngModel)]="displacementType">
        </p-select>
      </div>
    </ng-template>
  </p-toolbar>

  <p-dialog [draggable]="false" [(visible)]="needToCalculate" [closable]="false" *ngIf="!isLoading">
    <i class="pi pi-exclamation-circle"></i> Please calculate Perivis to see results
    <br />
    <br />
  </p-dialog>

  <p-dialog [draggable]="false" [(visible)]="packersMissing" [closable]="false" *ngIf="!isLoading">
    <i class="pi pi-exclamation-circle"></i> No Packers set on selected string.
    <br />
    <br />
  </p-dialog>

  <app-xy-line-plot
    [plotData]="plot.data"
    [xAxisTitle]="xAxisTitle"
    [yAxisTitle]="yAxisTitle"
    [plotName]="plotName"
    [downloadPlotName]="downloadPlotName"
    [legendBelowPlot]="true"
    [plotTitle]="'Packer Results Table'"
    *ngIf="packerResultsStore.state?.selectedResultDisplay === 'plot'"
    [xyLinePlotStore]="packerResultsStore"
  ></app-xy-line-plot>

  <app-single-load-grid
    [hidden]="packerResultsStore.state?.selectedResultDisplay !== 'grid'"
    [rowData]="results" [columnDefinitions]="cols" [tableName]="'Packer Results Table'"
    [gridName]="'Packer Results Table'">
  </app-single-load-grid>

</app-tool-loader>