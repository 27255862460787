<p-toolbar>
  <ng-template #start>
    <div class="space-x-2">
      <p-select resetFilterOnHide=true placeholder="Search" [options]="allDesigns" filterBy="name" optionLabel="name"
        [filter]="true" appendTo="body" [(ngModel)]="selectedDesign" (onChange)="updateDesign($event)">
      </p-select>
      <p-select placeholder="Recent" [options]="designs" optionLabel="name" appendTo="body" [(ngModel)]="selectedDesign"
        (onChange)="updateDesign($event)">
      </p-select>
    </div>
  </ng-template>

  <ng-template #end>
    <div class="space-x-2">
      <p-button *ngIf="isAdmin" outlined="true" icon="pi pi-plus" size="small" severity="success" pTooltip="Create New Company"
        (click)="addCompany()">
      </p-button>
      <p-button outlined="true" size="small" icon="pi pi-angle-double-left" (onClick)="collapseSidenav()"></p-button>
    </div>
  </ng-template>
</p-toolbar>