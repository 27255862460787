import { AfterViewInit, Component, Input, Output } from '@angular/core';
import { Validators, FormControl, ValidatorFn, AbstractControl, ValidationErrors } from '@angular/forms';
import { PostInstallLoad } from './models/post-install-load.model';
import { Subject } from 'rxjs';
import { createForm, FormType } from 'ngx-sub-form';
import { CementingLanding } from 'src/app/wellbore-inputs/models/cementing-landing.model';
import { PoreFrac } from 'src/app/perivis/models/poreFrac.model';
import { BaseOperation } from 'src/app/perical/models/thermal-operation.model';
import { WellType } from 'src/app/shared/models/wellType.model';
import { getControlErrors, isControlInvalid } from 'src/app/shared/services/validation-helpers';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';

@Component({
    selector: 'app-post-install-load-details',
    templateUrl: './post-install-load-details.component.html',
    styles: [``],
    standalone: false
})
export class PostInstallLoadDetailsComponent implements AfterViewInit {

  private input$ = new Subject<PostInstallLoad | undefined>();
  @Input() set load(load: PostInstallLoad | undefined) {
    this.input$.next(load);
    this._load = load;
    if(this._load?.name === 'Initial Condition'){
      this.form.formGroup.controls.name.disable();
    }
  }

  private disabled$ = new Subject<boolean>();
  @Input() set disabled(value: boolean | undefined) {
    this.disabled$.next(!!value);
  }

  @Output() loadUpdate = new Subject<PostInstallLoad>();
  @Output() cancelEdit$ = new Subject<void>();
  public manualSave$$ = new Subject<void>();

  @Input() shoeDepth: number;
  @Input() shoeDepthTvd: number;
  @Input() currentString: any;
  @Input() cement: CementingLanding;
  @Input() porePressures: PoreFrac[];
  @Input() thermalOperations: Partial<BaseOperation>[];
  @Input() wellType: WellType;
  @Input() userUnits: UserUnitsModel;
  @Input() wellTd: number;
  @Input() designId: string;
  @Input() isReadOnly: boolean;
  
  
  public form = createForm<PostInstallLoad>(this, {
    formType: FormType.ROOT,
    disabled$: this.disabled$,
    input$: this.input$,
    output$: this.loadUpdate,
    manualSave$: this.manualSave$$,
    formControls: {
      index: new FormControl(null),
      name: new FormControl({value: 'New Load', disabled: false}, Validators.required),
      type: new FormControl(null),
      internalPressureProfile: new FormControl(null, Validators.required),
      externalPressureProfile: new FormControl(null, Validators.required),
      temperatureProfile: new FormControl(null, Validators.required),
      overpullForce: new FormControl(null),
      runInHoleSpeed: new FormControl(null),
      pumpPressure: new FormControl(null)
    }
  });
  
  public _load: PostInstallLoad | undefined;
  public customTempAboveInfluxDepth: boolean;
  public loadTypeSelected: string;
  public porePressureLastPoint: number;

  ngAfterViewInit(): void {
    this.form.formGroup.addValidators(
      this.customTempAboveInfluxDepthValidator(this.form.formGroup.controls.internalPressureProfile, this.form.formGroup.controls.temperatureProfile)
    );
  }

  customTempAboveInfluxDepthValidator(internalPressureProfile, temperatureProfile): ValidatorFn {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return (control: AbstractControl): ValidationErrors | null => {
      if ((internalPressureProfile.value?.type === 'GasKick' || internalPressureProfile.value?.type === 'LiquidKick' || internalPressureProfile.value?.type === 'CasingFullOfGas')
        && (temperatureProfile.value?.type === 'CustomTemperatures' || temperatureProfile.value?.type === 'BottomHoleCirculatingTemperature')) {
        let lastTemperaturePoint: number;
        if (temperatureProfile.value?.type == 'CustomTemperatures') {
          const tempLength = temperatureProfile.value.temperaturePoints.length - 1;
          const lastPoint = temperatureProfile.value.temperaturePoints[tempLength];
          lastTemperaturePoint = lastPoint['measuredDepth'];
        } else {
          lastTemperaturePoint = temperatureProfile.value?.bottomMd;
        }

        const influxDepth = internalPressureProfile.value?.influxDepth;
        return lastTemperaturePoint < influxDepth ? { customTempAboveInfluxDepth: true } : null;
      } else { return null }
    }
  }

  // Validation delegates
  public isControlInvalid: Function = isControlInvalid;
  public getControlErrors: Function = getControlErrors;
}
