import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { Subject } from 'rxjs/internal/Subject';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { GreenCementTest, Overpull, PreInstallLoadType, RunInHole, SinglePreInstallLoad } from 'src/app/perivis/models/load-case.model';

export interface SinglePreinstallLoadForm {
  type: PreInstallLoadType,
  overpull: Overpull,
  runInHole: RunInHole
  greenCementTest: GreenCementTest
}

@Component({
    selector: 'app-single-pre-install-load',
    templateUrl: './single-pre-install-load.component.html',
    providers: subformComponentProviders(SinglePreInstallLoadComponent),
    standalone: false
})
export class SinglePreInstallLoadComponent implements OnChanges {

  @Input() tabType: string;
 
  @Output() typeChanged: EventEmitter<any> = new EventEmitter<any>();

  private input$ = new Subject<SinglePreInstallLoad | null | undefined>();
  @Input() set preInstallLoad(preInstallLoad: SinglePreInstallLoad | undefined) {
    this.input$.next(preInstallLoad);
  }

  private disabled$ = new Subject<boolean>();
  @Input() set disabled(value: boolean | undefined) {
    this.disabled$.next(!!value);
  }

  @Input() userUnits: UserUnitsModel;
  @Input() isCemented: boolean;
  @Input() shoeMd: number;
  @Input() hangerMd: number;
  @Input() annularFLuidDensity: number;

  public loadTypes: any[];
  public LoadType: typeof PreInstallLoadType = PreInstallLoadType;

  @Output() singlePreInstallLoadUpdate = new EventEmitter<SinglePreInstallLoad>();

  ngOnChanges(): void {
    this.loadTypes = [
      { name: 'Overpull', value: 'Overpull' },
      { name: 'Run In Hole', value: 'RunInHole' }
    ];

    if (this.isCemented) {
      this.loadTypes.push({ name: 'Green Cement Test', value: 'GreenCementTest' });
    } else {
      this.loadTypes.push({ name: 'Pre-Packer Pressure Test', value: 'GreenCementTest' });
    }
  }

  public form = createForm<SinglePreInstallLoad, SinglePreinstallLoadForm>(this, {
    formType: FormType.SUB,
    disabled$: this.disabled$,
    input$: this.input$,
    output$: this.singlePreInstallLoadUpdate,
    formControls: {
      type: new UntypedFormControl(null, [Validators.required]),
      overpull: new UntypedFormControl(null),
      runInHole: new UntypedFormControl(null),
      greenCementTest: new UntypedFormControl(null)
    },
    toFormGroup: (obj: SinglePreInstallLoad | null): SinglePreinstallLoadForm => {
      if (!obj) {
        return null;
      }

      return {
        overpull: obj.type == PreInstallLoadType.OVERPULL ? obj : null,
        runInHole: obj.type == PreInstallLoadType.RUNINHOLE ? obj : null,
        greenCementTest: obj.type == PreInstallLoadType.GREENCEMENTTEST ? obj : null,
        type: obj.type
      }
    },
    fromFormGroup: (formValue: SinglePreinstallLoadForm | null): SinglePreInstallLoad => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { overpull, runInHole, greenCementTest, type, ...commonValues } = formValue;

      switch (type) {
        case PreInstallLoadType.OVERPULL:
          return overpull ? { type, ...overpull } : null;
        case PreInstallLoadType.RUNINHOLE:
          return runInHole ? { type, ...runInHole } : null;
        case PreInstallLoadType.GREENCEMENTTEST:
          return greenCementTest ? { type, ...greenCementTest } : null;
        case null:
          return null;
        default:
      }
    }
  })
}