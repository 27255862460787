import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Store } from '@ngneat/elf';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { unitsLib } from 'src/app/core/services/unit-library';

@Component({
    selector: 'app-two-dee-plot',
    template: `
    <app-xy-line-plot
      [plotData]="plot.data"
      [xAxisTitle]="xAxisTitle"
      [yAxisTitle]="yAxisTitle"
      [plotName]="plotName"
      [downloadPlotName]="downloadPlotName"
      [plotTitle]="plotName"
      [reverseAutoRange]="reverseAutoRange"
      btmMargin="45"
      [xyLinePlotStore]="trajectoryPlotStore"
    ></app-xy-line-plot>
  `,
    standalone: false
})
export class TwoDeePlotComponent implements OnChanges, OnInit {

  private _isDarkMode :boolean;

  @Input()
  public trajectoryData: any;

  @Input()
  public selectedPlot: string;

  @Input()
  public set isDarkMode(value: boolean) {
    this._isDarkMode = value;
    this.setPlotData(this.trajectoryData);
  }
  public get isDarkMode(): boolean {
    return this._isDarkMode;
  }

  @Input()
  public userUnits: UserUnitsModel;

  @Input()
  public trajectoryPlotStore: Store;

  public xAxisTitle = '';
  public yAxisTitle = '';
  public plotName;
  public downloadPlotName = '';
  public reverseAutoRange = false;

  public plot = {
    data: [],
    layout: {},
    config: {}
  };

  ngOnInit(): void {
    this.plotName = 'Trajectory Plot ' + this.selectedPlot;
  }

  ngOnChanges(): void {
    this.setPlotData(this.trajectoryData);
  }

  private setPlotData(trajectoryData: any[]) {
    if (this.selectedPlot === 'northingTvd') {
      this.plot.data = [{
        x: trajectoryData?.map(x => x.northing),
        y: trajectoryData?.map(x => x.trueVerticalDepth),
      }];
      this.yAxisTitle = `TVD (${this.userUnits?.longLengths})`;
      this.xAxisTitle = `Northing (${this.userUnits?.longLengths})`;
      this.downloadPlotName = 'northing_tvd_plot';
    }

    if (this.selectedPlot === 'eastingTvd') {
      this.plot.data = [{
        x: trajectoryData.map(x => x.easting),
        y: trajectoryData.map(x => x.trueVerticalDepth),
      }];
      this.yAxisTitle = `TVD (${this.userUnits?.longLengths})`;
      this.xAxisTitle = `Easting (${this.userUnits?.longLengths})`;
      this.downloadPlotName = 'easting_tvd_plot';
    }

    if (this.selectedPlot === 'planView') {
      // Easting
      const maxEasting = Math.abs(Math.max(...trajectoryData.map(x => x.easting)));
      const minEasting = Math.abs(Math.min(...trajectoryData.map(x => x.easting)));
      const maxxEasting = Math.max(minEasting, maxEasting);

      // Northing
      const minNorthing = Math.abs(Math.min(...trajectoryData.map(x => x.northing)));
      const maxNorthing = Math.abs(Math.max(...trajectoryData.map(x => x.northing)));
      const maxxNorthing = Math.max(minNorthing, maxNorthing);

      const yAxisValue = Math.max(maxxEasting, maxxNorthing);
      const xAxisValues = maxxEasting == 0 ? maxxNorthing : maxxEasting;

      const lineColor = this.isDarkMode ?'#783F04' : '#FFF';

      const xAxis = {
        x: [-xAxisValues - 100, xAxisValues + 100],
        y: [0, 0],
        showlegend: false,
        name: '',
        mode: 'lines',
        line: {
          color: lineColor
        },
      };

      const yAxis = {
        x: [0, 0],
        y: [-yAxisValue - 100, yAxisValue + 100],
        showlegend: false,
        name: '',
        mode: 'lines',
        line: {
          color: lineColor
        }
      };

      this.plot.data.push(xAxis);
      this.plot.data.push(yAxis);

      this.plot.data.push({
        x: trajectoryData.map(x => x.easting),
        y: trajectoryData.map(x => (x.northing)),
        showlegend: false,
        name: '',
        line: {
          color: '#1f77b4'
        }
      });

      this.yAxisTitle= 'South (-) / North (+)';
      this.xAxisTitle = 'West (-) / East (+)';
      this.reverseAutoRange = true;
      this.downloadPlotName = 'plan_view_plot';
    }

    if (this.selectedPlot === 'doglegMd') {
      const dogleg = {
        x: trajectoryData.map(x => x.doglegSeverity),
        y: trajectoryData.map(x => x.measuredDepth),
      }

      for (let i = dogleg.x.length - 1; i > 0; i--) {
        dogleg.x.splice(i, 0, dogleg.x[i]);
        dogleg.y.splice(i, 0, dogleg.y[i - 1]);
      }
      this.plot.data = [dogleg];
      this.yAxisTitle = `MD (${this.userUnits?.longLengths})`;
      this.xAxisTitle = `Dogleg Severity (${unitsLib[this.userUnits?.doglegSeverity]?.symbol})`;
      this.downloadPlotName = 'dogleg_md_plot';
    }
  }
}
