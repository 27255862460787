<app-tool-loader [isLoading]="isLoading" positionUnset="true">

  <p-toolbar>
    <ng-template #start>
      <div class="flex items-center gap-2 toolbar-menu">
        <p-selectButton [options]="modes" [(ngModel)]="collapseResultsStore.state.selectedMode"
          (onChange)="onModeChange($event)" [allowEmpty]="false">
        </p-selectButton>

        <p-selectButton [options]="resultsDisplay" [(ngModel)]="collapseResultsStore.state.selectedResultDisplay"
          (onChange)="onSelectedResultDisplayUpdated($event)" [allowEmpty]="false">
        </p-selectButton>

        <p-selectButton *ngIf="collapseResultsStore.state?.selectedResultDisplay === 'plot'" [options]="depthView"
          [(ngModel)]="collapseResultsStore.state.selectedDepthView" (onChange)="depthViewToggle($event)"
          [allowEmpty]="false">
        </p-selectButton>

        <app-single-load-results [loadCases]="loadCases" (loadCaseSelected)="setLoadCase($event)"
          [stateStore]="collapseResultsStore" [signalRChange]="signalRChange">
        </app-single-load-results>
      </div>
    </ng-template>
  </p-toolbar>


<app-single-load-plot
  [yAxisTitle]="yAxisTitle"
  [xAxisTitle]="xAxisTitle"
  [columnDefs]="columnDefs"
  [data]="results"
  *ngIf="collapseResultsStore.state?.selectedResultDisplay === 'plot'"
  [selectedDepthView]="collapseResultsStore.state.selectedDepthView"
  [depthUnit]="longLengthUnit"
  [plotName]="plotName"
  [plotTitle]="'API Collapse Results'"
  [singleLoadPlotStore]="collapseResultsStore"
></app-single-load-plot>

  <app-single-load-grid
    [hidden]="collapseResultsStore.state?.selectedResultDisplay !== 'grid'" 
    [rowData]="results"
    [columnDefinitions]="columnDefinitions" 
    tableName="Single Load Collapse"
    gridName="API Collapse Results">
  </app-single-load-grid>

  <p-dialog [(visible)]="errorMsg" [closable]="false">
    <i class="pi pi-exclamation-circle pr-1"></i>
    <label>{{ errorMsg }}</label>
    <br> <br>
  </p-dialog>

</app-tool-loader>