import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { Observable, Subscription, catchError, forkJoin, map } from 'rxjs';
import { toCamelCaseString } from 'src/app/shared/helpers/stringToCamel.helper';
import { PeriforOnChangeMessages, SignalRService } from 'src/app/shared/services/signal-r.service';
import { PreInstallLoad } from '../../models/load-case.model';
import { LoadCaseService } from '../../services/load-case.service';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { WellConfigService } from 'src/app/shared/services/well-config.service';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { GridItemResizedMessage } from 'src/app/shared/models/mediator-messages.model';
import { FluidsService } from 'src/app/perical/services/fluids.service';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';

@Component({
    selector: 'app-pre-install-loads',
    templateUrl: './pre-install-loads.component.html',
    styles: [`
    .pre-install-loads {
      width: 100%;
      height: 100%;
    }
 `],
    providers: [ConfirmationService],
    standalone: false
})
export class PreInstallLoadsComponent implements OnInit, OnDestroy {

  constructor(
    private _loadCaseService: LoadCaseService,
    private _confirmationService: ConfirmationService,
    private _signalRService: SignalRService,
    private _storeService: StoreService,
    private _wellConfigService: WellConfigService,
    private _messenger: MediatorService,
    private _fluidsService: FluidsService
  ) {
    this._subscriptions = new Subscription();
    this._subscriptions.add(this._messenger.of(GridItemResizedMessage).subscribe((e) => {
      if (e.name == "Pre-Install Load Cases") {
        if (this.selectedLoad && this.loadCases.length > 5) {
          const load = this.selectedLoad;
          this.selectedLoad = null;
          this.listbox.listStyle = { 'max-height': `${(e.itemHeight - 105) + 'px'}` };
          this.onLoadSelect({ value: load });
        }
      }
    }));
  }

  @ViewChild("listbox") listbox;

  public loadCases: Partial<PreInstallLoad>[];
  public selectedLoad: Partial<PreInstallLoad>;
  public selectedLoadIndex: number;
  public selectedLoadDetails$: Observable<PreInstallLoad>;
  public emitInitialValueOnInit = false;
  public userUnits: UserUnitsModel;
  public isCemented: boolean;
  public hangerMd: number;
  public shoeDepth: number;
  public annularFLuidDensity: number;

  private isNewLoad = false;
  private isUpdate = false;
  private skipSave = false;
  private _subscriptions: Subscription;

  @Input()
  public componentId: string;

 async ngOnInit(): Promise<void> {
    this.userUnits = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));
    this.getLoadCases();
  }

  signalRfunc (data: any)  {
    if (data.action === PeriforOnChangeMessages.REFRESH_TUBULARS_LIST || data.action == PeriforOnChangeMessages.REFRESH_STRING_INPUTS) {
      this.getLoadCases();
    }
  }

  private getLoadCases() {
    const sources = [
      this._wellConfigService.getTubular() as Observable<any>,
      this._loadCaseService.getLoadCasesSummary(false) as Observable<any>
    ];

    forkJoin(sources).pipe(
      map(([currentString, loads]) => {
        this.loadCases = loads;
        this.selectedLoad = this.loadCases ? this.isNewLoad ? this.loadCases[this.loadCases.length - 1] :
        this.isUpdate ? this.loadCases.find(x => x['index'] === this.selectedLoad['index']) : this.loadCases[0] : null;
        this.skipSave = false;
        this.isNewLoad = false;

        this.hangerMd = currentString.hangerMd;
        this.shoeDepth = currentString.shoeMd - 0.1;
        const annularFluidId = currentString.annularFluidId;

        if (annularFluidId) {
        this._fluidsService.getFluidById(annularFluidId).subscribe(fluid => {
          this.annularFLuidDensity = fluid.state['nominalDensity'];
        });
      }

        this.isCemented = currentString.hasCement;
      if (this.selectedLoad) {
        this._loadCaseService.getPreInstallLoadCase(this.selectedLoad['index']).subscribe(load => {
          this.selectedLoadDetails$ = new Observable(observer => observer.next(this.remapLoad(load, false)));
        });
      } else {
        this.clearLoadDetails();
      }
    }),
    catchError(err => {
      return err;
    })).subscribe();
  }

  private remapLoad(load: any, isUpdate: boolean, index?: number): PreInstallLoad {
    const type = isUpdate ? load.data.type : load['type'];
    let loadType;
    switch (type) {
      case 'Overpull':
        loadType = 'OverpullForce';
        break;
      case 'RunInHole':
        loadType = 'RunInHoleSpeed';
        break;
      case 'GreenCementTest':
        loadType = 'PumpPressure';
        break;
    }

    const loadCase: any = {
      name: load.name,
      index: index,
      data: {
        [loadType]: isUpdate ? load.data[loadType] : load[toCamelCaseString(loadType)],
        type: isUpdate ? load.data.type : load['type'],
        IsFlotation: isUpdate ? load.data.IsFlotation : load['isFlotation'],
        InternalFluidDensity: isUpdate ? load.data.InternalFluidDensity : load['internalFluidDensity'],
        HasBottomCap: isUpdate ? load.data.HasBottomCap : load['hasBottomCap'],
        HasFlotationPlug: isUpdate ? load.data.HasFlotationPlug : load['hasFlotationPlug'],
        PlugDepth: isUpdate ? load.data.PlugDepth : load['plugDepth'],
        FluidDensity: isUpdate ? load.data.FluidDensity : load['fluidDensity']
      }
    }
    return loadCase;
  }

  public updateOrCreateLoad(load: PreInstallLoad): void {
    if (this.skipSave) {
      this.skipSave = false;
      return;
    }

    const loadIndex = this.loadCases.findIndex(x => x['index'] === this.selectedLoad['index'] && (this.selectedLoad.data || this.selectedLoad['type']));
    if (loadIndex < 0) {
      this._loadCaseService.addLoadCaseDefinition(load).subscribe(() => {
        this.isNewLoad = true;
        this.getLoadCases();
      });
    }  else {
      this._loadCaseService.updatePreinstallLoadCase(this.remapLoad(load, true, this.selectedLoad['index'])).subscribe(() => {
        this.isUpdate = true;
        this.skipSave = true;
        this.getLoadCases();
      });
    }
  }

  public onLoadSelect(e) {
    if (!e.value) {
      return;
    }
    this.selectedLoadDetails$ = new Observable(observer => observer.next(null));
    // this.skipSave = true;
    this._loadCaseService.getPreInstallLoadCase(e.value.index).subscribe(load => {
      this.selectedLoad = this.loadCases.find(x => x.name === load.name);
      this.selectedLoadDetails$ = new Observable(observer => observer.next(this.remapLoad(load, false)));
    });
  }

  public addLoad() {
    if (this.loadCases.length > 0 && !this.loadCases[this.loadCases.length - 1]['type']) {
      return;
    }
    this.skipSave = false;
    const newLoad: PreInstallLoad = {
      name: 'New Load',
      data: null
    };
    this.loadCases.push(newLoad);
    this.selectedLoad = this.loadCases[this.loadCases.length - 1];
    this.selectedLoadDetails$ = new Observable(observer => observer.next(newLoad));
  }

  public deleteLoad(load: PreInstallLoad): void {
    const loadIndex = this.loadCases.find(x => x.name === load.name)['index'];
    if (!loadIndex || loadIndex < 0) {
      this.selectedLoadAfterDelete(loadIndex);
      return;
    } else {
      this._confirmationService.confirm({
        message: 'Are you sure that you want to delete load case "' + load.name.bold() + '"?',
        accept: () => {
          this._loadCaseService.deleteLoadCase(loadIndex).subscribe(() => {
            this.selectedLoadAfterDelete(loadIndex);
          });
        }
      });
    }
  }

  private selectedLoadAfterDelete(loadIndex: number) {
    const index = this.loadCases.indexOf(this.loadCases.find(l => l['index'] === loadIndex));
    this.loadCases.splice(index, 1);
    this.loadCases = [...this.loadCases];
    this.selectedLoad = this.loadCases.length !== 0 ? this.loadCases[0] : null;
    if (this.selectedLoad !== null) {
      this._loadCaseService.getPreInstallLoadCase(this.selectedLoad['index']).subscribe(load => {
        this.selectedLoadDetails$ = new Observable(observer => observer.next(this.remapLoad(load, false)));
      });
    } else {
      this.clearLoadDetails();
    }
  }

  public cloneLoad(load: PreInstallLoad): void {
    const index = load['index'];
    const loadCase = { name: load.name + ' - Copy', index: index }
    this._loadCaseService.cloneLoadCaseDefinition(loadCase).subscribe(res => {
      this._loadCaseService.getPreInstallLoadCase(res['index']).subscribe(() => {
        this.getLoadCases();
      });
    });
  }

  clearLoadDetails(): void {
    const clearedLoad: PreInstallLoad = {
      name: null,
      data: null
    };
    this.selectedLoadDetails$ = new Observable(observer => observer.next(clearedLoad));
  }

  ngOnDestroy(): void {
    this._subscriptions?.unsubscribe();
    this.signalRfunc = null;
  }
}
