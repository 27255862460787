import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, shareReplay } from 'rxjs/operators';
import { WellheadModel, WellheadResult } from '../components/wellhead/wellhead.model';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`

@Injectable({
  providedIn: 'root'
})
export class WellheadService {
  private wellheadCache$: Observable<WellheadResult> | null = null;

  constructor(private _http: HttpClient) { }

  public getWellheadForTubular(): Observable<WellheadResult> {
    if (!this.wellheadCache$) {
      this.wellheadCache$ = this._http
        .get<WellheadResult>(`${PeriforApiEndpoint}/wellhead`)
        .pipe(
          catchError(() => of(null as WellheadResult)),
          shareReplay(1)
        );
    }
    setTimeout(() => this.wellheadCache$ = null, 1000);
    return this.wellheadCache$;
  }

  updateWellheadForTubular(model: WellheadModel): Observable<boolean> {
    return this._http.put<boolean>(`${PeriforApiEndpoint}/wellhead`, model);
  }
}
