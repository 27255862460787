import { Component, OnInit } from '@angular/core';
import { AppNotificationService } from 'src/app/shared/services/app-notification.service';
import { lastValueFrom } from 'rxjs';
import { UsersService } from '../../services/users-service';
import { User } from '../user-admin-page/user-model';
import { StorageKeys, StoreService } from '../../services/store.service';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styles: [``],
    providers: [UsersService],
    standalone: false
})
export class ProfileComponent implements OnInit {
  public user: User;

  constructor(
    private _usersService: UsersService,
    private _storeService: StoreService,
    private _toaster: AppNotificationService
  ) {
  }

  async ngOnInit() : Promise<void> {
    this.user = await this._storeService.get<User>(StorageKeys.USER);
  }

  async onSetUserPassword(){
    const setPword = await lastValueFrom(this._usersService.resetPword(this.user.profile.email));
    if (setPword) {
      this._toaster.showSuccess("Password reset email has been sent. Check your email.");
    }
  }

}
