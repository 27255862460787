<div class="grid-container">
  <div class="toolbar" *ngIf="designId && user" >
    <app-toolbar #toolbar [user]="user"></app-toolbar>

    <app-dashboard></app-dashboard>
  </div>

  <div class="sidebar">
    <app-sidebar-menu></app-sidebar-menu>
  </div>
  
  <div class="dashboard">
    <app-no-current-design *ngIf="!designId"></app-no-current-design>
  </div>
  
  <div *ngIf="showWellSchematic" class="schematic">
    <div class="grabber" appResizer (resizeDone)="requestSchematicRedraw()" minWidth="150" maxWidth="650"></div>
    <div class="pad">
      <app-well-schematic #schematic isSidebarComp="true"></app-well-schematic>
    </div>
  </div>

</div>