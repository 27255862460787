import { Component, Input } from '@angular/core';
@Component({
    selector: 'app-formations-catalog',
    template: `
    <app-tool-loader [isLoading]="false" positionUnset="true">
      <app-formations-cement-catalog [catalogType]="'formation'" [componentId]="componentId"></app-formations-cement-catalog>
    </app-tool-loader>
  `,
    standalone: false
})
export class FormationsCatalogComponent {
  // State
  @Input()
  public componentId: string;
}
