import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { PoreFrac } from 'src/app/perivis/models/poreFrac.model';
import { ExternalPressureProfileType, GasCap, GeneralEPP, MudAndPorePressureEPP } from '../../models/external-pressure-profile.model';
import { porePressureMustBeToShoeValidator, porePressureNotSpecifiedValidator } from 'src/app/perivis/shared/validators/pore-pressure.validator';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { unitsLib } from 'src/app/core/services/unit-library';
import { getControlErrors, isControlInvalid } from 'src/app/shared/services/validation-helpers';
import { GetValueFromPpg } from 'src/app/perivis/shared/helpers/units.helper';

interface MudAndPorePressureEPPForm {
  type: ExternalPressureProfileType.MUDANDPOREPRESSURE;
  generalEpp: GeneralEPP;
  gasCap: GasCap;
  cementMixwaterDensity: number;
}

@Component({
    selector: 'app-mud-and-pore-pressure-epp',
    templateUrl: './mud-and-pore-pressure-epp.component.html',
    providers: subformComponentProviders(MudAndPorePressureEppComponent),
    standalone: false
})
export class MudAndPorePressureEppComponent implements OnChanges, AfterViewInit {

  @Input() porePressures: PoreFrac[];
  @Input() shoeDepth: number;
  @Input() currentString: any;
  @Input() userUnits: UserUnitsModel;

  // Validation delegates
  public isControlInvalid: Function = isControlInvalid;
  public getControlErrors: Function = getControlErrors;

  public densityLabel: string;
  public densityValidation: { min: number, max: number };

  public form = createForm<MudAndPorePressureEPP, MudAndPorePressureEPPForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(ExternalPressureProfileType.MUDANDPOREPRESSURE),
      generalEpp: new UntypedFormControl(null, Validators.required),
      cementMixwaterDensity: new UntypedFormControl(null),
      gasCap: new UntypedFormControl(null, Validators.required)
    },
    toFormGroup: (obj: MudAndPorePressureEPP): MudAndPorePressureEPPForm => {
      return {
        type: ExternalPressureProfileType.MUDANDPOREPRESSURE,
        generalEpp: obj,
        cementMixwaterDensity: obj.cementMixwaterDensity,
        gasCap: { gasCapLength: obj.gasCapLength, gasGradient: obj.gasGradient, hasGasCap: obj.hasGasCap }
      };
    },
    fromFormGroup: (formValue: MudAndPorePressureEPPForm): MudAndPorePressureEPP => {
      return {
        type: ExternalPressureProfileType.MUDANDPOREPRESSURE,
        hangerPressure: formValue.generalEpp?.hangerPressure,
        fluidDensity: formValue.generalEpp?.fluidDensity,
        cementMixwaterDensity: formValue.cementMixwaterDensity,
        gasCapLength: formValue.gasCap.gasCapLength,
        gasGradient: formValue.gasCap.gasGradient,
        hasGasCap: formValue.gasCap.hasGasCap
      };
    }
  });

  ngAfterViewInit(): void {
    this.densityLabel = unitsLib[this.userUnits.density].symbol;
    this.form.formGroup.addValidators([porePressureMustBeToShoeValidator(this.porePressures, this.shoeDepth), porePressureNotSpecifiedValidator(this.porePressures)]);
    if (this.form.formGroup.controls.cementMixwaterDensity.value == null) {
      this.form.formGroup.controls.cementMixwaterDensity.setValue(GetValueFromPpg(8.33, this.densityLabel), { emitEvent: false });
    }
  }

  ngOnChanges(): void {
    switch (this.userUnits.density) {
      case 'ppg (U.S.)':
        this.densityValidation = { min: 0.01, max: 25 };
        break;
      case 'kg/m³':
      case 'g/L':
        this.densityValidation = { min: 1.19, max: 2995 };
        break;
      case 'g/cm³':
      case 'kg/l':
      case 'sg':
        this.densityValidation = { min: 0.00119, max: 3 };
        break;
    }
    this.form.formGroup.controls.cementMixwaterDensity.setValidators([Validators.required, Validators.min(this.densityValidation.min), Validators.max(this.densityValidation.max)]);

    this.form.formGroup.updateValueAndValidity();
  }

  onInputFocus($event) {
    $event.target.select();
  }
}
