import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { createForm, FormType, NGX_SUB_FORM_HANDLE_VALUE_CHANGES_RATE_STRATEGIES } from 'ngx-sub-form';
import { Subject } from 'rxjs';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { unitsLib } from 'src/app/core/services/unit-library';
import { Reservoir, Reservoirs } from 'src/app/perical/models/reservoirPerformance';

@Component({
  selector: 'app-reservoir-data',
  templateUrl: './reservoir-data.component.html',
  styles: [``],
  standalone: false
})
export class ReservoirDataComponent implements OnChanges {
  public reservoirData: Reservoirs;
  public pressureUnit: string;
  public lengthUnit: string;
  public pressureValidation: { min: number, max: number };

  private input$ = new Subject<Reservoir | null | undefined>();
  private _reservoir: Reservoir;

  @Input()
  public userUnits: UserUnitsModel | null | undefined;

  @Input() set reservoir(reservoir: Reservoir | undefined) {
    this.input$.next(reservoir);
    this._reservoir = reservoir;
  }

  get reservoir(): Reservoir {
    return this._reservoir;
  }

  private disabled$ = new Subject<boolean>();
  @Input() set disabled(value: boolean | undefined) {
    this.disabled$.next(!!value);
  }

  @Output() reservoirUpdate = new EventEmitter<Reservoir>();

  ngOnChanges(): void {
    this.customPerformanceIndexChange({ checked: this.reservoir?.applyCustomPerformanceIndex });
    
    if (this.userUnits) {
      this.pressureUnit = unitsLib[this.userUnits.pressure].symbol;
      this.lengthUnit = this.userUnits.longLengths;

      switch (this.userUnits.pressure) {
        case 'psi':
          this.pressureValidation = { min: 100, max: 50000 };
          break;
        case 'bar':
        case 'atm':
          this.pressureValidation = { min: 6.8, max: 3447 };
          break;
        case 'KPa':
          this.pressureValidation = { min: 689, max: 344737 };
          break;
      }

      this.form.formGroup.controls.pressure.setValidators([Validators.required, Validators.min(this.pressureValidation.min), Validators.max(this.pressureValidation.max)]);
    }
  }

  public form = createForm<Reservoir>(this, {
    formType: FormType.ROOT,
    disabled$: this.disabled$,
    input$: this.input$,
    output$: this.reservoirUpdate,
    formControls: {
      name: new FormControl('', [Validators.required]),
      pressure: new FormControl(null, [Validators.required]),
      permeability: new FormControl(null, [Validators.required]),
      height: new FormControl(null, [Validators.required]),
      radialExtent: new FormControl(null, [Validators.required]),
      skinFactor: new FormControl(null, [Validators.required]),
      applyCustomPerformanceIndex: new FormControl(null, [Validators.required]),
      performanceIndex: new FormControl(24840, [Validators.required, Validators.min(0)]),
    },
    fromFormGroup: (formValue: Reservoir | null): Reservoir => {
      const { ...commonValues } = formValue;

      this.customPerformanceIndexChange({ checked: commonValues.applyCustomPerformanceIndex });

      return {
        ...commonValues
      }
    },
    handleEmissionRate: NGX_SUB_FORM_HANDLE_VALUE_CHANGES_RATE_STRATEGIES.debounce(1000)
  });

  public customPerformanceIndexChange(e): void {
    if (e?.checked) {
      this.form.formGroup.controls.performanceIndex.enable({ emitEvent: false });
      if (!this.form.formGroup.controls.performanceIndex.value) {
        this.form.formGroup.controls.performanceIndex.setValue(24840);
      }
    } else {
      this.form.formGroup.controls.performanceIndex.disable({ emitEvent: false });
    }
  }
}
