<div class="w-full h-full">
  <app-tool-loader [isLoading]="isLoading" positionUnset="true">

    <p-toolbar>
      <ng-template #start>
        <div class="flex gap-2">
          <p-selectButton [options]="resultsDisplay" [(ngModel)]="apbResultsStore.state.selectedResultsDisplay"
            (onChange)="onSelectedResultsDisplayUpdated($event)" [allowEmpty]="false"></p-selectButton>

          <p-selectButton [options]="apbAfe" [(ngModel)]="apbResultsStore.state.apbAfeSelected"
            *ngIf="apbResultsStore.state?.selectedResultsDisplay === 'plot'" (onChange)="onApbAfeSelected($event)"
            [allowEmpty]="false"></p-selectButton>
        </div>
      </ng-template>
    </p-toolbar>

    <div class="w-full h-full">
      <app-ng-table-results 
        *ngIf="apbResultsStore.state?.selectedResultsDisplay === 'grid'"
        tableName="Annular Pressure Build-Up Results" 
        [tableData]="apbResults"
        [tableHeight]="tableHeight"
        [columnDefinitions]="cols" 
      />

      <app-xy-line-plot *ngIf="apbResultsStore.state?.selectedResultsDisplay === 'plot'" [plotData]="plot.data"
        [xAxisTitle]="xAxisTitle" [yAxisTitle]="yAxisTitle" [plotName]="plotName" [downloadPlotName]="downloadPlotName"
        [reverseAutoRange]="true" [legendBelowPlot]="true" [plotTitle]="'APB Results'">
      </app-xy-line-plot>
    </div>

<app-tool-loader [isLoading]="isLoading" positionUnset="true">
  <br *ngIf="apbResultsStore.state?.selectedResultsDisplay === 'grid'">
  <app-ng-table-results
    tableName="Annular Pressure Build-Up Results"
    [tableData]="apbResults"
    [columnDefinitions]="cols"
    [tableHeight]="tableHeight"
    *ngIf="apbResultsStore.state?.selectedResultsDisplay === 'grid'"
  />
  <app-xy-line-plot
    *ngIf="apbResultsStore.state?.selectedResultsDisplay === 'plot'"
    [plotData]="plot.data"
    [xAxisTitle]="xAxisTitle"
    [yAxisTitle]="yAxisTitle"
    [plotName]="plotName"
    [downloadPlotName]="downloadPlotName"
    [reverseAutoRange]="true"
    [legendBelowPlot]="true"
    [plotTitle]="'APB Results'"
    [xyLinePlotStore]="apbResultsStore"
  ></app-xy-line-plot>

  <p-dialog
  [draggable]="false"
  [(visible)]="needToCalculate"
  [closable]="false"
>
  <i class="pi pi-exclamation-circle"></i> Please calculate Perinix to view results <br />
  <br />
</p-dialog>
</app-tool-loader>
