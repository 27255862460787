import { Component, ElementRef, HostListener, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { saveAs } from 'file-saver-es'
import { jsPDF } from "jspdf";
import autoTable from 'jspdf-autotable';
import ExcelJS from "exceljs";
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-ng-table-results',
  templateUrl: './ng-table-results.component.html',
  styles: [
    `
      .copy-btn{
        display: table-cell;
        padding-bottom: .25rem;
      }
    `
  ],
  standalone: false
})
export class NgTableResultsComponent implements OnInit, OnDestroy {

  private _subscriptions: Subscription;
  private _selection: { cellText: string, cellKeys: { row: number; column: number } }[];

  public menuItems: MenuItem[];
  selectedColumns: string[] = [];

  @Input()
  public minTemp: number;

  @Input()
  public maxTemp: number;

  @Input()
  public showHeatmap: boolean;

  @Input()
  public tableData: any[];

  @Input()
  public designFactors: any;

  @Input()
  public apiCollapseType: string;

  @Input()
  public hangerLiftoffForce: number;

  @Input()
  public tableName: string;

  @Input()
  public tableHeight: string;

  @Input()
  public selectedOperation: string;

  @Input()
  public virtualScroll: string;

  @Input()
  public mode: string;

  @Input()
  public columnDefinitions: { header: string, field: string, valueFormatter(params: any): string, color: string }[];

  @ViewChild('dataTable', { read: Table, static: false })
  public dataTable: Table;

  @HostListener('document:mousedown', ['$event'])
  onClick(event: MouseEvent): void {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.clearColumnData();
    }
  }

  private columnSelected: boolean;
  private columnsForCopy: any = [];
  private columnsForCopyBackup: any = [];
  private isCopied = false;
  private includeHeaders = true;
  private isCopyTable: boolean;

  constructor(private elementRef: ElementRef) {
    this._subscriptions = new Subscription();
    this.menuItems = [
      { label: 'Excel', icon: 'pi pi-file-excel', command: () => { this.exportExcel() } },
      { label: 'PDF', icon: 'pi pi-file-pdf', command: () => { this.exportPdf() } },
      { label: 'CSV', icon: 'pi pi-file', command: () => { this.exportCsv() } }
    ];
  }

  ngOnInit(): void {
    // Overwrite what is being copied to the clipboard.
    this._subscriptions.add(
      addEventListener('copy', (e: ClipboardEvent) => {
        if (this.columnSelected) {
          if (this.includeHeaders) {
            this.copy(e);
          } else {
            if (!this.isCopyTable) {
              this.removeHeaders();
            }
            this.copy(e);
          }
          this.isCopyTable = false;
        } else {
          const csv = this.createTabDelimitedForClipboard();
          e.clipboardData.setData('text', csv);
          e.preventDefault();
          this.isCopied = true;
        }
      }));
  }

  public getColorForTemperature(value: number, field: string): string {
    if (!this.showHeatmap || field.startsWith('md') || field.startsWith('tvd')) {
      return 'white';
    }

    const minTemp = this.minTemp;
    const maxTemp = this.maxTemp;

    const colorCount = 10;
    const temperatureRange = maxTemp - minTemp;
    const xStep = temperatureRange / colorCount;

    const xValues = Array.from({ length: colorCount }, (v, i) => Math.round((minTemp + i * xStep) / 10), 2);

    const averageXValue = xValues.reduce((acc, value) => acc + value, 0) / xValues.length;

    const colors = [
      { index: 0, color: "#0D47A1" },   // Dark Blue,
      { index: 1, color: "#1565C0" },   // Slightly lighter blue
      { index: 2, color: "#1976D2" },   // Another shade of blue
      { index: 3, color: "#1E88E5" },
      { index: 4, color: "#2196F3" },
      { index: 5, color: "#42A5F5" },   // Lighter blue
      { index: 6, color: "#64B5F6" },
      { index: 7, color: "#90CAF9" },
      { index: 8, color: "#BBDEFB" },
      { index: 9, color: "#FFEB3B" },		// Yellow
      { index: 10, color: "#FDD835" },	// Slightly darker yellow
      { index: 11, color: "#FBC02D" },
      { index: 12, color: "#F9A825" },
      { index: 13, color: "#F57F17" },
      { index: 14, color: "#EF6C00" },  // Orange
      { index: 15, color: "#E65100" },  // Slightly darker orange
      { index: 16, color: "#D84315" },
      { index: 17, color: "#BF360C" },
      { index: 18, color: "#A82710" }    // Dark Red
    ];

    const factor = 0.75;
    const colorIndexFactor = 5;

    const colorRanges = [
      { min: -1000, max: minTemp + averageXValue, color: colors[0 + colorIndexFactor].color },
      { min: minTemp - 1, max: minTemp + averageXValue, color: colors[0 + colorIndexFactor].color },
      { min: minTemp + averageXValue, max: minTemp + averageXValue * 1.25 * factor, color: colors[1 + colorIndexFactor].color },
      { min: minTemp + averageXValue * 1.25 * factor, max: minTemp + averageXValue * 1.5 * factor, color: colors[2 + colorIndexFactor].color },
      { min: minTemp + averageXValue * 1.5 * factor, max: minTemp + averageXValue * 1.75 * factor, color: colors[3 + colorIndexFactor].color },
      { min: minTemp + averageXValue * 1.75 * factor, max: minTemp + averageXValue * 2 * factor, color: colors[4 + colorIndexFactor].color },
      { min: minTemp + averageXValue * 2 * factor, max: minTemp + averageXValue * 2.25 * factor, color: colors[5 + colorIndexFactor].color },
      { min: minTemp + averageXValue * 2.25 * factor, max: minTemp + averageXValue * 2.5 * factor, color: colors[6 + colorIndexFactor].color },
      { min: minTemp + averageXValue * 2.5 * factor, max: minTemp + averageXValue * 2.75 * factor, color: colors[7 + colorIndexFactor].color },
      { min: minTemp + averageXValue * 2.75 * factor, max: minTemp + averageXValue * 3 * factor, color: colors[8 + colorIndexFactor].color },
      { min: minTemp + averageXValue * 3 * factor, max: minTemp + averageXValue * 3.25 * factor, color: colors[9 + colorIndexFactor].color },
      { min: minTemp + averageXValue * 3.25 * factor, max: minTemp + averageXValue * 3.5 * factor, color: colors[10 + colorIndexFactor].color },
      { min: minTemp + averageXValue * 3.5 * factor, max: minTemp + averageXValue * 3.75 * factor, color: colors[11 + colorIndexFactor].color },
      { min: minTemp + averageXValue * 3.75 * factor, max: maxTemp + 1, color: colors[12 + colorIndexFactor].color },
      { min: maxTemp + 1, max: 1000, color: colors[13 + colorIndexFactor].color }
    ];

    return colorRanges.find(x => value >= x.min && value <= x.max)?.color || 'white';
  }

  private copy(e: any) {
    e.clipboardData.setData('text', this.columnsForCopy);
    e.preventDefault();
    this.isCopied = true;
  }

  headers(e: Event) {
    this.includeHeaders = e['checked'];
  }

  onRangeChanged(range: { cellText: string, cellKeys: { row: number; column: number } }[]) {
    this.columnSelected = false;
    this._selection = range;
  }

  copyTable(columns) {
    // Foreach item in columns call selectColumn
    columns.forEach(col => {
      this.selectColumn(col, columns);
    });

    this.isCopyTable = true;
    if (!this.includeHeaders) {
      this.removeHeaders();
    }
    document.execCommand('copy');
  }

  removeHeaders() {
    const newCsv: any = [];

    const lines = this.columnsForCopy.split("\n");
    lines.forEach(function (item, i) {
      if (i !== 0) newCsv.push(item);
    })

    this.columnsForCopy = newCsv.join("\n");
  }

  selectColumn(col, columns) {
    this.columnSelected = true;
    if (this.columnsForCopyBackup.findIndex(x => x == col.header) >= 0) {
      return;
    }

    if (this.isCopied) { 
      this.clearColumnData();
    }

    if (this.columnsForCopyBackup.length > 1) {
      const headerExists = this.columnsForCopyBackup[0].findIndex(x => x == col.header);
      if (headerExists >= 0) {
        for (const columnArray of this.columnsForCopyBackup) {
          if (headerExists !== -1) {
            columnArray.splice(headerExists, 1);
          }
        }
        this.columnsForCopy = this.columnsForCopyBackup.map(v => v.map(x => `${x}`).join("\t")).join('\n');
        return;
      }
    }

    const column = this.getColumnData(col);
    if (this.columnsForCopyBackup.length > 0) {
      this.columnsForCopyBackup[0].push(col.header);
      for (let l = 1; l < this.columnsForCopyBackup.length; l++) {
        this.columnsForCopyBackup[l].push(column[l - 1][0]);
      };
    } else {
      this.columnsForCopyBackup = this.getColumnData(col);
      this.columnsForCopyBackup.unshift([col.header]);
    }

    const headers = columns.map(x => x.header);

    if (this.columnsForCopyBackup[0].length > 1) {
      const headerIndex = headers.findIndex(x => x == this.columnsForCopyBackup[0][this.columnsForCopyBackup[0].length - 1]);
      const previousSelectedHeaderIndex = headers.findIndex(x => x == this.columnsForCopyBackup[0][this.columnsForCopyBackup[0].length - 2]);
      if (headerIndex < previousSelectedHeaderIndex) {
        const existingHeaders = headers.filter(ar => this.columnsForCopyBackup[0].find(rm => (rm === ar)));
        for (const columnArray of this.columnsForCopyBackup) {
          if (columnArray.length > 0) {
            const element = columnArray[columnArray.length - 1];
            columnArray.splice(columnArray.length - 1, 1);
        
            const index = existingHeaders.findIndex(x => x === col.header);
            if (index !== -1) {
              columnArray.splice(index, 0, element);
            }
          }
        }
      }
    }

    this.columnsForCopy = this.columnsForCopyBackup.map(v => v.map(x => `${x}`).join("\t")).join('\n');
  }

  private getColumnData(col : any) {
    return this.tableData.map(x => [x[col.field]]);
  }

  toggleColumnHighlight(event: MouseEvent, col: any): void {
    const isCtrlPressed = event.ctrlKey || event.metaKey;
    if (isCtrlPressed) {
      const index = this.selectedColumns.indexOf(col.field);
      if (index === -1) {
        this.selectedColumns.push(col.field);
      } else {
        this.selectedColumns.splice(index, 1);
      }
    } else {
      this.selectedColumns = [];
      this.columnsForCopy = [];
      this.columnsForCopyBackup = [];
      this.selectedColumns = [col.field];
    }

    this.selectColumn(col, this.selectedColumns);
  }

  clearColumnData() {
    this.selectedColumns = [];
    this.columnsForCopy = [];
    this.columnsForCopyBackup = [];
    this.isCopied = false;
  }

  public exportPdf() {
    const doc = new jsPDF("l")
    const headerColumns = [this.columnDefinitions.map(col => col.header)];
    const data = this.tableData.map(d => {
      return Object.entries(d).map((e, i) => this.columnDefinitions[i].valueFormatter(e[1]))
    });
    autoTable(doc, { head: headerColumns, body: data });
    doc.save(`${this.tableName}.pdf`);
  }

  exportCsv = () => {
    const delimiter = ", ";
    const headers = this.columnDefinitions.map(x => `${x.header}`).join(delimiter);
    const outputData = this.tableData.map(x => Object.values(x));
    const csv = outputData.map(v => v.map(x => `${x}`).join(delimiter)).join('\n');;
    saveAs(new Blob([headers + '\n' + csv], { type: "application/csv;charset=utf-8" }), `${this.tableName}.csv`);
  }

  public async exportExcel(): Promise<void> {
    const mappedData = this.tableData.map(td => {
      const objWithHeaderKeys = {};
      this.columnDefinitions.forEach(cd => {
        const objHeaderKey = cd.header;
        objWithHeaderKeys[objHeaderKey.replace(/["']/g, '"')] = td[cd.field];
      });
      return objWithHeaderKeys;
    });

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Connections Catalog");

    worksheet.columns = Object.keys(mappedData[0]).map((key) => ({
      header: key,
      key: key,
      width: 45
    }));

    worksheet.addRows(mappedData);

    const buffer = await workbook.xlsx.writeBuffer();
    this.saveAsExcelFile(buffer, this.tableName);
  }

  public saveAsExcelFile(buffer: any, fileName: string): void {
    const selectedOperationName = this.selectedOperation ? this.selectedOperation['name'] : '';
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + '_export_' + selectedOperationName + EXCEL_EXTENSION);
  }

  private createTabDelimitedForClipboard(): string {
    const selection = new Array(...this._selection);

    const rowMin = Math.min(...this._selection.map(f => f.cellKeys.row));
    const colMin = Math.min(...this._selection.map(f => f.cellKeys.column)); 
    const rowMax = Math.max(...this._selection.map(f => f.cellKeys.row));
    const colMax = Math.max(...this._selection.map(f => f.cellKeys.column));

    const grid = new Array<any[]>();

    for (let i = rowMin; i <= rowMax; i++) {
      const row = []
      for (let j = colMin; j <= colMax; j++) {
        const cell = selection.find(c => c.cellKeys.row === i && c.cellKeys.column === j);
        row.push(cell.cellText)
      }
      grid.push(row);
    }

    const csv = grid.map(v => v.map(x => `${x}`).join("\t")).join('\n');

    return csv
  }

  ngOnDestroy() {
    this._subscriptions?.unsubscribe();
  }

}
