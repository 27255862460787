<form [formGroup]="form.formGroup">

  <img class="w-[25px] h-[25px] mb-2" (click)="applyDefaults()" title="Apply Defaults" src="../../assets/icons/default-icon-white.png" />

  <div class="flex flex-wrap">
    <!-- Left Column -->
    <div class="w-full md:w-1/2 p-4">
      <div class="flex flex-col gap-4">
        <div class="flex flex-col">
          <label for="a0" class="mb-1">a0 x 10<span class="sup">4</span></label>
          <p-inputNumber
            id="a0"
            [formControlName]="form.formControlNames.a0"
            required
            [minFractionDigits]="1"
          ></p-inputNumber>
        </div>
  
        <div class="flex flex-col">
          <label for="a1" class="mb-1">a1 x 10<span class="sup">8</span></label>
          <p-inputNumber
            id="a1"
            [formControlName]="form.formControlNames.a1"
            required
            [minFractionDigits]="1"
          ></p-inputNumber>
        </div>
  
        <div class="flex flex-col">
          <label for="a2" class="mb-1">a2 x 10<span class="sup">13</span></label>
          <p-inputNumber
            id="a2"
            [formControlName]="form.formControlNames.a2"
            required
            [minFractionDigits]="1"
          ></p-inputNumber>
        </div>
      </div>
    </div>
  
    <!-- Right Column -->
    <div class="w-full md:w-1/2 p-4">
      <div class="flex flex-col gap-4">
        <div class="flex flex-col">
          <label for="b0" class="mb-1">b0 x 10</label>
          <p-inputNumber
            id="b0"
            [formControlName]="form.formControlNames.b0"
            required
            [minFractionDigits]="1"
          ></p-inputNumber>
        </div>
  
        <div class="flex flex-col">
          <label for="b1" class="mb-1">b1 x 10<span class="sup">6</span></label>
          <p-inputNumber
            id="b1"
            [formControlName]="form.formControlNames.b1"
            required
            [minFractionDigits]="1"
          ></p-inputNumber>
        </div>
  
        <div class="flex flex-col">
          <label for="b2" class="mb-1">b2 x 10<span class="sup">12</span></label>
          <p-inputNumber
            id="b2"
            [formControlName]="form.formControlNames.b2"
            required
            [minFractionDigits]="1"
          ></p-inputNumber>
        </div>
      </div>
    </div>
  </div>
  
</form>
