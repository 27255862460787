<form [formGroup]="form.formGroup">
  <p-panel>
    <ng-template pTemplate="header">
      <div class="flex items-center m-2">
      <label class="mr-2">External Pressure:</label>
      <p-select
        [disabled]="isReadOnly"
        [options]="epps"
        [formControlName]="form.formControlNames.type"
        appendTo="body"
      ></p-select>
    </div>
    </ng-template>

    <div [ngSwitch]="form.formGroup.value.type" class="bg-[var(--p-content-background)] p-2">
      <app-custom-epp
        *ngSwitchCase="ExternalPressureProfileType.CUSTOMEPP"
        [formControlName]="form.formControlNames.customEpp"
        [currentString]="currentString"
        [userUnits]="userUnits"
        [wellTd]="wellTd"
      ></app-custom-epp>
      <app-fluid-to-shoe-epp
        *ngSwitchCase="ExternalPressureProfileType.FLUIDTOSHOE"
        [formControlName]="form.formControlNames.fluidToShoeEpp"
        [currentString]="currentString"
        [userUnits]="userUnits"
      ></app-fluid-to-shoe-epp>
      <app-interpolation-to-pore-pressure-epp
        *ngSwitchCase="ExternalPressureProfileType.INTERPOLATIONTOPOREPRSSURE"
        [formControlName]="form.formControlNames.interpolationToPorePressureEpp"
        [currentString]="currentString"
        [porePressures]="porePressures"
        [shoeDepth]="shoeDepth"
        [userUnits]="userUnits"
      ></app-interpolation-to-pore-pressure-epp>
      <app-mud-and-cement-mix-water-epp
        *ngSwitchCase="ExternalPressureProfileType.MUDANDCEMENTMIXWATER"
        [formControlName]="form.formControlNames.mudAndCementMixWaterEpp"
        [cement]="cement"
        [currentString]="currentString"
        [userUnits]="userUnits"
      ></app-mud-and-cement-mix-water-epp>
      <app-mud-and-pore-pressure-epp
        *ngSwitchCase="ExternalPressureProfileType.MUDANDPOREPRESSURE"
        [formControlName]="form.formControlNames.mudAndPorePressureEpp"
        [porePressures]="porePressures"
        [shoeDepth]="shoeDepth"
        [currentString]="currentString"
        [userUnits]="userUnits"
      ></app-mud-and-pore-pressure-epp>
      <app-mud-and-wet-cement-epp
        *ngSwitchCase="ExternalPressureProfileType.MUDANDWETCEMENT"
        [formControlName]="form.formControlNames.mudAndWetCementEpp"
        [cement]="cement"
        [currentString]="currentString"
        [userUnits]="userUnits"
      ></app-mud-and-wet-cement-epp>
      <app-perical-link-epp
        [isReadOnly]="isReadOnly"
        *ngSwitchCase="ExternalPressureProfileType.PERICALLINK"
        [formControlName]="form.formControlNames.pericalLinkEpp"
        [thermalOperations]="thermalOperations"
      ></app-perical-link-epp>
      <app-pore-pressure-epp
        *ngSwitchCase="ExternalPressureProfileType.POREPRESSURE"
        [formControlName]="form.formControlNames.porePressureEpp"
        [porePressures]="porePressures"
        [currentString]="currentString"
        [shoeDepth]="shoeDepth"
        [userUnits]="userUnits"
      ></app-pore-pressure-epp>
    </div>

  </p-panel>
</form>
