<app-tool-loader [isLoading]="isLoading" positionUnset="true">

  <p-toolbar>
    <ng-template #start>
      <div class="flex gap-2 toolbar-menu">
        <p-selectButton [options]="resultsView" visible="true" class="resultsViewButton"
          [(ngModel)]="pressTempStore.state.selectedResultsView" (onChange)="resultsViewToggle($event)"
          [allowEmpty]="false">
        </p-selectButton>

        <p-selectButton *ngIf="pressTempStore.state?.selectedResultsView !== 'temperaturePoints'"
          [options]="depthView" [(ngModel)]="pressTempStore.state.selectedDepthView"
          (onChange)="depthViewToggle($event)" [allowEmpty]="false">
        </p-selectButton>
      </div>
    </ng-template>
  </p-toolbar>

  <app-xy-line-plot [plotData]="plot.data" [xAxisTitle]="xAxisTitle" [yAxisTitle]="yAxisTitle" [plotName]="plotName"
    [downloadPlotName]="downloadPlotName" [plotTitle]="'Load P&T Profiles'"
    [xyLinePlotStore]="pressTempStore"
    >
  </app-xy-line-plot>

</app-tool-loader>