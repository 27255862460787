import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { FormationTop } from 'src/app/perical/models/formation.model';
import { environment } from 'src/environments/environment';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`

@Injectable({
  providedIn: 'root'
})
export class FormationTopService {
  private formationTopsCache$: Record<string, Observable<FormationTop[]>> = {};

  constructor(private http: HttpClient) { }

  public getAllFormationTops(skipUnits = false): Observable<FormationTop[]> {
    const cacheKey = skipUnits.toString();
    if (!this.formationTopsCache$[cacheKey]) {
      this.formationTopsCache$[cacheKey] = this.http
        .get<FormationTop[]>(`${PeriforApiEndpoint}/formationTops?skipUnits=${skipUnits}`)
        .pipe(
          map(data => data.map(item => new FormationTop(item))),
          catchError(() => of([])),
          shareReplay(1)
        );
    }
    setTimeout(() => this.formationTopsCache$[cacheKey] = null, 1000);
    return this.formationTopsCache$[cacheKey];
  }

  deleteFormation(formationTopId: FormationTop): Observable<boolean> {
    return this.http.delete<boolean>(`${PeriforApiEndpoint}/formationTops/${formationTopId}`);
  }

  setFormationTop(formationTop: FormationTop): Observable<FormationTop> {
    this.formationTopsCache$ = {};
    return this.http.post<FormationTop>(`${PeriforApiEndpoint}/formationTops`, formationTop);
  }

  editFormationTop(formationTop: FormationTop): Observable<FormationTop> {
    let formationTopId = formationTop.id;
    if (!this.isGuid(formationTopId)) {
      formationTopId = formationTop.id['id'];
      formationTop.id = formationTopId;
    }

    return this.http.put<FormationTop>(`${PeriforApiEndpoint}/formationTops/${formationTopId}`, formationTop);
  }

  private isGuid(value) {
    const regex = /[a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}/i;
    const match = regex.exec(value);
    return match != null;
  }
}
