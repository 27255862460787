import { AfterViewInit, Component, Input } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { Fluid, FluidType } from 'src/app/perical/models/fluid.model';
import { GasLiftOperation, OperationType } from 'src/app/perical/models/thermal-operation.model';

export interface GasLiftOperationForm {
  type: OperationType;
  fluid: Fluid;
  gasLiftValveDepth: number;
  injectionPressure: number;
  inletTemperature: number;
  gasRate: number;
}

@Component({
    selector: 'app-gas-lift-details',
    templateUrl: './gas-lift-details.component.html',
    styles: [``],
    providers: subformComponentProviders(GasLiftDetailsComponent),
    standalone: false
})
export class GasLiftDetailsComponent implements AfterViewInit {

  public fluids: Fluid[];
  @Input('fluids') set _fluids(fluids: Fluid[]) {
    this.fluids = fluids?.filter(f => f.state.type === FluidType.CO2FLUID);
  }
  public FluidType: typeof FluidType = FluidType;
  public isLoading: boolean;

  public form = createForm<GasLiftOperation, GasLiftOperationForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(OperationType.GASLIFT),
      fluid: new UntypedFormControl(null, [Validators.required]),
      gasLiftValveDepth: new UntypedFormControl(null, [Validators.required]),
      injectionPressure: new UntypedFormControl(null, [Validators.required]),
      inletTemperature: new UntypedFormControl(null, [Validators.required]),
      gasRate: new UntypedFormControl(null, [Validators.required])
    },
    toFormGroup: (obj: GasLiftOperation | null): GasLiftOperationForm | null => {
      if (!obj) {
        return null;
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { fluid, ...commonValues } = obj;
    },
    fromFormGroup: (formValue: GasLiftOperationForm): GasLiftOperation | null => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { fluid, type, ...commonValues } = formValue;

      return {
        ...commonValues,
        type: OperationType.GASLIFT,
        fluid: fluid,
        gasLiftValveDepth: formValue.gasLiftValveDepth,
        injectionPressure: formValue.injectionPressure,
        inletTemperature: formValue.inletTemperature,
        gasRate: formValue.gasRate
      };
    },
  });

  ngAfterViewInit() {
    const defaultFluid = this.fluids[0];
    if (!this.form.formGroup.controls.fluid.value && defaultFluid) {
      this.form.formGroup.controls.fluid.setValue(defaultFluid);
    }
  }
}
