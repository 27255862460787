<div class="perforations-form">
  <p-confirmDialog
    header="Confirmation"
    icon="pi pi-exclamation-triangle"
  ></p-confirmDialog>

  <p-dialog [(visible)]="configMissing" [closable]="false">
    <i style="font-size: 10px" class="pi pi-exclamation-circle"></i> Well
    Configuration Missing: Please specify at least one string <br />
    <br />
  </p-dialog>

  <app-tool-loader [isLoading]="isLoading" positionUnset="true">

    <form [formGroup]="perforationsForm" novalidate>
      <ng-container formArrayName="perforations">
        <p-table
          [scrollable]="true"
          [scrollHeight]="tableHeight"
          styleClass="p-datatable-gridlines"
          [columns]="columnDefs"
          [resizableColumns]="true"
          [value]="perforations.controls"
          class="perforations"
          dataKey="value.id"
          stateStorage="local"
          showGridlines
          [stateKey]="componentId"
          [tableStyle]="{'min-width': '2rem'}"
        >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" pResizableColumn>
                {{ col.header }}
              </th>
              <th class="text-center">
                <p-button
                  size="small"
                  severity="success"
                  icon="pi pi-plus"
                  outlined="true"
                  [disabled]="configMissing || perforations.invalid"
                  (click)="onAddPerforation()"
                ></p-button>
              </th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-perforationFg let-rIdx="rowIndex">
            <ng-container [formGroupName]="rIdx">
              <tr>
                <td>
                  <input class="w-[100%-20px]" pInputText formControlName="name" title="Name" />
                  <app-validation-error
                    [isValid]="isControlInvalid(perforationFg, 'name')"
                    [errorMsg]="getControlErrors(perforationFg, 'name')"
                  />
                </td>
                <td>
                  <p-inputNumber
                    formControlName="measuredDepth"
                    [minFractionDigits]="1"
                    title="Depth"
                  ></p-inputNumber>
                  <app-validation-error
                    [isValid]="isControlInvalid(perforationFg, 'measuredDepth')"
                    [errorMsg]="
                      getControlErrors(perforationFg, 'measuredDepth')
                    "
                  />
                </td>
                <td>
                  <p-inputNumber
                    formControlName="temperature"
                    [maxFractionDigits]="2"
                    title="Temperature"
                  ></p-inputNumber>
                  <app-validation-error
                    [isValid]="isControlInvalid(perforationFg, 'temperature')"
                    [errorMsg]="getControlErrors(perforationFg, 'temperature')"
                  />
                </td>

                <td class="text-center">
                  <p-button
                    text
                    size="small"
                    severity="danger"
                    title="Delete Perforation"
                    icon="pi pi-trash"
                    (click)="onDeletePerforation(rIdx)"
                  ></p-button>
                </td>
              </tr>
            </ng-container>
          </ng-template>
        </p-table>
      </ng-container>
    </form>
  </app-tool-loader>
</div>
