<form [formGroup]="form.formGroup">

  <div class="flex flex-col space-y-2">

    <i class="pi pi-info-circle text-sm"
      title=" In the case of cement interval inside the shoe of the previous casing,&#13;this EPP applies the mud/fluid hydrostatic to TOC of the current casing and then &#13;interpolates linearly from TOC to Pore Pressure at the shoe of the previous casing"></i>

    <app-general-epp [formControlName]="form.formControlNames.generalEpp" [currentString]="currentString"
      [userUnits]="userUnits">
    </app-general-epp>

    <p class="validation-error" *ngIf="form.formGroup.hasError('porePressureNotSpecified')">
      Pore Pressure profile not specified.
    </p>
    <p class="validation-error"
      *ngIf="form.formGroup.hasError('porePressureMustBeToShoe') && !form.formGroup.hasError('porePressureNotSpecified')">
      Pore Pressure profile must be extended to the shoe of the string.
    </p>

    <p class="text-sm">
      Mud Weight: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {{ currentString?.annularFluid.state.nominalDensity.toFixed(2) }} {{ densityLabel }}
    </p>
    
  </div>
</form>