<form [formGroup]="form.formGroup" bg-dark>
  <div class="flex flex-col space-y-2">

    <div class="flex flex-col">
      <label>{{ topPressureLabel }}</label>
      <app-validation-error
        [isValid]="isControlInvalid(form.formGroup, 'hangerPressure')"
        [errorMsg]="getControlErrors(form.formGroup, 'hangerPressure')"
      />
      <p-inputgroup>
        <p-inputNumber
          [formControlName]="form.formControlNames.hangerPressure"
          [maxFractionDigits]="2"
          title="Allowed range: {{ pressureValidation.min }} - {{
            pressureValidation.max
          }} {{ pressureLabel }}"
          (onFocus)="onInputFocus($event)"
        ></p-inputNumber>
        <p-inputgroup-addon>{{ userUnits.pressure }}</p-inputgroup-addon>
      </p-inputgroup>
    </div>

    <div class="flex flex-col">
      <label>Fluid Density</label>
      <app-validation-error
        [isValid]="isControlInvalid(form.formGroup, 'fluidDensity')"
        [errorMsg]="getControlErrors(form.formGroup, 'fluidDensity')"
      />
      <p-inputgroup>
        <p-inputNumber
          [formControlName]="form.formControlNames.fluidDensity"
          [maxFractionDigits]="2"
          title="Allowed range: {{ densityValidation.min }} - {{
            densityValidation.max
          }} {{ densityLabel }}"
          (onFocus)="onInputFocus($event)"
        ></p-inputNumber>
        <p-inputgroup-addon>{{ densityLabel }}</p-inputgroup-addon>
      </p-inputgroup>
    </div>
  </div>
</form>
