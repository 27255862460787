import { Component } from '@angular/core';
import { MenuItem } from 'primeng/api';

@Component({
    selector: 'app-help-menu',
    template: `
    <button pButton pRipple type="button" class="p-button-rounded p-button-text p-text-secondary" icon="pi pi-question" title="Help Menu" (click)="menu.toggle($event)"></button>
    <p-menu #menu [popup]="true" [model]="items"></p-menu>
  `,
    styles: [`
    button {
      background: transparent;
      border-color: transparent;
    }
  `],
    standalone: false
})
export class HelpMenuComponent {
  items: MenuItem[]= [
    // {
    //   label: 'Quickstart Guide',
    //   icon: 'pi pi-book',
    //   routerLink: '/quickstart'
    // },
    {
      label: 'Changelog',
      icon: 'pi pi-clock',
      routerLink: '/changelog'
    },
    {
      label: 'About',
      icon: 'pi pi-question',
      routerLink: '/about'
    },
    {
      label: 'Return to Designs',
      icon: 'pi pi-arrow-left',
      routerLink: ['/designs']
    }
  ];
  
}
