import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { GradeCatalog } from '../../models/grade-catalog.model';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api/gradesCatalog/`;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class GradesService {
  private gradesCache$: Observable<GradeCatalog[]> | null = null;

  constructor(private http: HttpClient) { }

  public getGrades(): Observable<GradeCatalog[]> {
    if (!this.gradesCache$) {
      this.gradesCache$ = this.http
        .get<GradeCatalog[]>(`${PeriforApiEndpoint}`)
        .pipe(
          map(data => data.map(item => new GradeCatalog(item))),
          catchError(() => of([])),
          shareReplay(1)
        );
    }
    setTimeout(() => this.gradesCache$ = null, 1000);
    return this.gradesCache$;
  }

  addGrade(model: GradeCatalog): Observable<{id: string}> {
    return this.http.post<{id: string}>(`${PeriforApiEndpoint}`, model);
  }

  updateGrade(model: GradeCatalog): Observable<boolean> {
    return this.http.put<boolean>(`${PeriforApiEndpoint}${model.id}`, model, httpOptions);
  }

  deleteGrade(id: string): Observable<boolean> {
    return this.http.delete<boolean>(`${PeriforApiEndpoint}${id}`);
  }
}
