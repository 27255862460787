import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { getControlErrors, isControlInvalid } from 'src/app/shared/services/validation-helpers';
import { GasCap } from '../../models/external-pressure-profile.model';
import { UntypedFormControl, Validators } from '@angular/forms';

interface GasCapForm {
  hasGasCap: boolean;
  gasCapLength: number;
  gasGradient: number;
}

@Component({
    selector: 'app-gas-cap',
    templateUrl: './gas-cap.component.html',
    providers: subformComponentProviders(GasCapComponent),
    standalone: false
})
export class GasCapComponent implements AfterViewInit, OnChanges {
  @Input() currentString: any;
  @Input() userUnits: UserUnitsModel;

  // Validation delegates
  public isControlInvalid: Function = isControlInvalid;
  public getControlErrors: Function = getControlErrors;

  public lengthUnit: string;
  public gasGradientLabel: string;
  public gasGradientDefault: number;
  public gasGradientValidation: { min: number, max: number };

  ngAfterViewInit(): void {
    const minGasCapLength = this.lengthUnit == 'ft' ? 100 : 30;
    if (this.currentString.topOfCementMd - this.currentString.hangerMd < minGasCapLength) {
      this.form.formGroup.controls.hasGasCap.disable({ emitEvent: false });
    } else {
      this.form.formGroup.controls.hasGasCap.enable({ emitEvent: false });
    }
    this.hasGasCapChange();
  }

  public form = createForm<GasCap, GasCapForm>(this, {
    formType: FormType.SUB,
    formControls: {
      hasGasCap: new UntypedFormControl(false),
      gasCapLength: new UntypedFormControl(null),
      gasGradient: new UntypedFormControl(null)
    },
    toFormGroup: (obj: GasCap): GasCapForm => {
      return obj;
    },
    fromFormGroup: (formValue: GasCapForm): GasCap => {
      return formValue;
    }
  });

  public hasGasCapChange() {
    if (!this.form.formGroup.controls.gasGradient.value) {
      this.form.formGroup.controls.gasGradient.setValue(this.gasGradientDefault);
    }
    if (!this.form.formGroup.controls.gasCapLength.value) {
      const defaultGasCapLength = this.lengthUnit == 'ft' ? 1 : 0.3;
      this.form.formGroup.controls.gasCapLength.setValue(defaultGasCapLength);
    }

    if (this.form.formGroup.controls.hasGasCap.value) {
      this.hasGasCap();
    } else {
      this.doesntHaveGasCap();
    }
  }

  private hasGasCap() {
    this.form.formGroup.controls.hasGasCap.setValue(true);
    this.form.formGroup.controls.gasCapLength.enable();
    this.form.formGroup.controls.gasGradient.enable();
  }

  private doesntHaveGasCap() {
    this.form.formGroup.controls.gasCapLength.setValue(null);
    this.form.formGroup.controls.gasGradient.setValue(null);
    this.form.formGroup.controls.gasCapLength.disable({ emitEvent: false });
    this.form.formGroup.controls.gasGradient.disable({ emitEvent: false });
  }

  onInputFocus($event) {
    $event.target.select();
  }

  ngOnChanges(): void {
    this.lengthUnit = this.userUnits.longLengths;
    this.gasGradientLabel = this.userUnits.gasGradient;

    switch (this.gasGradientLabel) {
      case 'psi/ft':
        this.gasGradientValidation = { min: 0, max: 5.1948 };
        this.gasGradientDefault = 0.1;
        break;
      case 'kPa/m':
        this.gasGradientValidation = { min: 0, max: 117.5 };
        this.gasGradientDefault = 2.26;
        break;
      case 'sg':
        this.gasGradientValidation = { min: 0, max: 11.97 };
        this.gasGradientDefault = 0.23;
        break;
      case 'bar/m':
        this.gasGradientValidation = { min: 0, max: 1.17 };
        this.gasGradientDefault = 0.022;
        break;
    }

    const minGasCapLength = this.lengthUnit == 'ft' ? 1 : 0.3;
    const maxGasCapLength = this.currentString.topOfCementMd - this.currentString.hangerMd;

    this.form.formGroup.controls.gasGradient.setValidators([Validators.min(this.gasGradientValidation.min), Validators.max(this.gasGradientValidation.max)]);
    this.form.formGroup.controls.gasCapLength.setValidators([Validators.min(minGasCapLength), Validators.max(maxGasCapLength)]);
  }
}
