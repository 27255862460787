import { AfterViewInit, Component, Input } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { unitsLib } from 'src/app/core/services/unit-library';
import { Overpull, PreInstallLoadType } from 'src/app/perivis/models/load-case.model';
import { GetValueFromPpg } from 'src/app/perivis/shared/helpers/units.helper';

export interface OverpullForm {
  OverpullForce: number;
  IsFlotation: boolean;
  InternalFluidDensity: number;
  HasBottomCap: boolean;
  HasFlotationPlug: boolean;
  PlugDepth: number;
  FluidDensity: number;
  type: PreInstallLoadType;
}

@Component({
    selector: 'app-overpull-details',
    template: `
    <form [formGroup]="form.formGroup" class="space-y-2">

      <div class="flex flex-col">
        <label>Overpull Force</label>
        <p-inputgroup>
          <p-inputNumber [formControlName]="form.formControlNames.OverpullForce" [minFractionDigits]="1"/>
          <p-inputgroup-addon>{{ forceUnit }}</p-inputgroup-addon>
        </p-inputgroup>
        <p  
          class="validation-error"
          *ngIf="form.formGroup.controls.OverpullForce.value && form.formGroup.controls.OverpullForce.invalid">
          Allowed range: {{ forceValidation.min }} - {{ forceValidation.max }} {{ forceUnit }}
        </p>
      </div>

      <div class="flex flex-col">
        <label>Internal Fluid Density</label>
        <p-inputgroup>
          <p-inputnumber [formControlName]="form.formControlNames.InternalFluidDensity" [minFractionDigits]="1"/>
          <p-inputgroup-addon>{{ densityUnit }}</p-inputgroup-addon>
        </p-inputgroup>
        <p 
          class="validation-error"
          *ngIf="form.formGroup.controls.InternalFluidDensity.value && form.formGroup.controls.InternalFluidDensity.invalid">
          Allowed range: {{ densityValidation.min }} - {{ densityValidation.max }} {{ densityUnit }}
        </p>
      </div>

      <div class="flex mt-2">
        <p-fieldset class="w-full">
          <ng-template pTemplate="header">
            <p-toggleswitch
              binary="true"
              [formControlName]="form.formControlNames.IsFlotation"
              (onChange)="checkFlotation()"
            ></p-toggleswitch>
            <label class="ml-2">Flotation</label>
          </ng-template>

        <div class="flex flex-col space-y-2">
          <div>
            <p-checkbox
              inputId="btm-cap"
              size="small"
              binary="true"
              [formControlName]="form.formControlNames.HasBottomCap"
            >
            </p-checkbox>
            <label for="btm-cap" class="ml-2 pt-1">Bottom end cap or float plug</label>
          </div>

          <div>
            <p-checkbox
              inputId="float-plug"
              size="small"
              binary="true"
              [formControlName]="form.formControlNames.HasFlotationPlug"
              (change)="hasPlugChange()"
            >
            </p-checkbox>
            <label for="float-plug" class="ml-2 pt-1">Has Plug</label>
          </div>

          <div class="flex flex-col">
            <label>Plug Depth</label>
            <p-inputgroup>
              <p-inputnumber [formControlName]="form.formControlNames.PlugDepth" [minFractionDigits]="1"/>
              <p-inputgroup-addon [class]="isFlotation && hasFlotationPlug ? '' : 'disabled'" >{{ depthUnit }} MD</p-inputgroup-addon>
            </p-inputgroup>
            <p 
              class="validation-error"
              *ngIf="form.formGroup.controls.PlugDepth.value && form.formGroup.controls.PlugDepth.invalid">
              Allowed range: {{ this.hangerMd.toFixed(2) }} - {{ this.shoeMd.toFixed(2) }} {{ this.depthUnit }}
            </p>
          </div>

          <div class="flex flex-col">
            <label>Fluid Density (below plug)</label>
            <p-inputgroup>
              <p-inputnumber [formControlName]="form.formControlNames.FluidDensity" [minFractionDigits]="1"></p-inputnumber>
              <p-inputgroup-addon [class]="isFlotation && hasFlotationPlug ? '' : 'disabled'">{{ densityUnit }}</p-inputgroup-addon>
            </p-inputgroup>
            <p 
              class="validation-error"
              *ngIf="form.formGroup.controls.FluidDensity.value && form.formGroup.controls.FluidDensity.invalid">
              Allowed range: {{ densityValidation.min }} - {{ densityValidation.max }} {{ densityUnit }}
            </p>
          </div>
        </div>
      </p-fieldset>
    </div>
    
  </form>
  `,
    providers: subformComponentProviders(OverpullDetailsComponent),
    standalone: false
})
export class OverpullDetailsComponent implements AfterViewInit {
  @Input() userUnits: UserUnitsModel;
  @Input() shoeMd: number;
  @Input() hangerMd: number;
  @Input() annularFLuidDensity: number;

  public forceUnit: string;
  public forceValidation: { min: number, max: number };
  public densityUnit: string;
  public densityValidation: { min: number, max: number };
  public depthUnit: string;
  public isFlotation: boolean;
  public hasFlotationPlug: boolean;

  ngAfterViewInit(): void {
    this.forceUnit = this.userUnits.force;
    this.densityUnit = unitsLib[this.userUnits.density].symbol;
    this.depthUnit = this.userUnits.longLengths;

    switch (this.forceUnit) {
      case 'lbf':
        this.forceValidation = { min: 0, max: 10000000 };
        break;
      case 'tonne':
        this.forceValidation = { min: 0, max: 4535 };
        break;
      case 'kgf':
        this.forceValidation = { min: 0, max: 4535923 };
        break;
    }
    this.densityValidation = { min: GetValueFromPpg(0.01, this.densityUnit), max: GetValueFromPpg(25, this.densityUnit) };

    this.form.formGroup.controls.OverpullForce.setValidators([Validators.min(this.forceValidation.min), Validators.max(this.forceValidation.max), Validators.required]);
    this.form.formGroup.controls.InternalFluidDensity.setValidators([Validators.min(this.densityValidation.min), Validators.max(this.densityValidation.max), Validators.required]);
    this.form.formGroup.controls.FluidDensity.setValidators([Validators.min(this.densityValidation.min), Validators.max(this.densityValidation.max)]);
    this.form.formGroup.controls.PlugDepth.setValidators([Validators.min(this.hangerMd), Validators.max(this.shoeMd)]);

    if (!this.form.formGroup.controls.InternalFluidDensity.value) {
      this.form.formGroup.controls.InternalFluidDensity.setValue(this.annularFLuidDensity, { emitEvent: false });
    }

    if (!this.form.formGroup.controls.FluidDensity.value || !this.form.formGroup.controls.HasFlotationPlug.value) {
      this.form.formGroup.controls.FluidDensity.setValue(this.annularFLuidDensity, { emitEvent: false });
    }

    if (!this.form.formGroup.controls.PlugDepth.value || !this.form.formGroup.controls.HasFlotationPlug.value) {
      this.form.formGroup.controls.PlugDepth.setValue(this.shoeMd, { emitEvent: false });
    }

    if (this.form.formGroup.controls.HasFlotationPlug.value) {
      this.hasPlug();
    } else {
      this.doesntHavePlug();
    }

    this.checkFlotation();
  }

  public form = createForm<OverpullForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(null),
      OverpullForce: new UntypedFormControl(null),
      IsFlotation: new UntypedFormControl(false),
      InternalFluidDensity: new UntypedFormControl(null),
      HasBottomCap: new UntypedFormControl(false),
      HasFlotationPlug: new UntypedFormControl(false),
      PlugDepth: new UntypedFormControl(null),
      FluidDensity: new UntypedFormControl(null)
    },
    toFormGroup: (obj: Overpull | null): OverpullForm => {
      if (!obj) {
        return null;
      }

      return {
        OverpullForce: obj.OverpullForce,
        IsFlotation: obj.IsFlotation,
        InternalFluidDensity: obj.InternalFluidDensity,
        HasBottomCap: obj.HasBottomCap,
        HasFlotationPlug: obj.HasFlotationPlug,
        PlugDepth: obj.PlugDepth,
        FluidDensity: obj.FluidDensity,
        type: obj.type
      }
    },
    fromFormGroup: (formValue: OverpullForm): Overpull => {
      return {
        OverpullForce: formValue.OverpullForce,
        IsFlotation: formValue.IsFlotation,
        InternalFluidDensity: formValue.InternalFluidDensity,
        HasBottomCap: formValue.HasBottomCap,
        HasFlotationPlug: formValue.HasFlotationPlug,
        PlugDepth: formValue.PlugDepth ? formValue.PlugDepth : this.shoeMd,
        FluidDensity: formValue.FluidDensity ? formValue.FluidDensity : this.annularFLuidDensity,
        type: PreInstallLoadType.OVERPULL
      }
    }
  });

  public checkFlotation() {
    if (this.form.formGroup.controls.IsFlotation.value) {
      this.form.formGroup.controls.HasBottomCap.enable({ emitEvent: false });
      this.form.formGroup.controls.HasFlotationPlug.enable({ emitEvent: false });
      this.hasPlug();
      this.isFlotation = true;
    } else {
      this.form.formGroup.controls.HasBottomCap.disable({ emitEvent: false });
      this.form.formGroup.controls.HasFlotationPlug.disable({ emitEvent: false });
      this.doesntHavePlug();
      this.isFlotation = false;
    }
  }

  public hasPlugChange() {
    if (this.form.formGroup.controls.HasFlotationPlug.value) {
      this.hasPlug();
    } else {
      this.doesntHavePlug();
    }
  }

  private hasPlug() {
    if (!this.form.formGroup.controls.PlugDepth.value) {
      this.form.formGroup.controls.PlugDepth.setValue(+this.shoeMd.toFixed(2), { emitEvent: false });
    }
    if (this.form.formGroup.controls.HasFlotationPlug.value || !this.form.formGroup.controls.IsFlotation.value) {
      this.form.formGroup.controls.PlugDepth.enable({ emitEvent: false });
      this.form.formGroup.controls.FluidDensity.enable({ emitEvent: false });
      this.hasFlotationPlug = true;
    }
  }

  private doesntHavePlug() {
    if (!this.form.formGroup.controls.HasFlotationPlug.value || !this.form.formGroup.controls.IsFlotation.value) {
      this.form.formGroup.controls.PlugDepth.disable({ emitEvent: false });
      this.form.formGroup.controls.FluidDensity.disable({ emitEvent: false });
      this.hasFlotationPlug = false;
    }
  }
}