import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ReservoirPerformance, ReservoirPerformanceResult } from '../models/reservoirPerformance';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`
@Injectable({
  providedIn: 'root'
})
export class ReservoirService {
  private reservoirCache$: Observable<any> | null = null;

  constructor(private http: HttpClient) { }

  public calculateReservoirPerformance(reservoirPerformance: ReservoirPerformance): Observable<ReservoirPerformanceResult> {
    return this.http.post<ReservoirPerformanceResult>(`${PeriforApiEndpoint}/thermalOperations/reservoirPerformance`, reservoirPerformance);
  }

  public getReservoirs(): Observable<any> {
    if (!this.reservoirCache$) {
      this.reservoirCache$ = this.http
        .get<any>(`${PeriforApiEndpoint}/thermalOperations/reservoirs`)
        .pipe(
          shareReplay(1)
        );
    }
    setTimeout(() => this.reservoirCache$ = null, 1000);
    return this.reservoirCache$;
  }

  public updateReservoir(reservoir: any): Observable<boolean> {
    return this.http.put<boolean>(`${PeriforApiEndpoint}/thermalOperations/reservoirs`, reservoir);
  }
}
