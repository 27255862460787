<form [formGroup]="form.formGroup">

  <!-- <div class="pb-5 field" >
    <div class="flex items-center">
      <p-checkbox
        [formControlName]="form.formControlNames.useMudPits"
        id="useMudPits"
        binary="true"
        (onChange)="this.useMudPits = $event.checked;"
      ></p-checkbox>
      <label for="useMudPits" class="ml-2">Circulate through Mud Pits</label>
    </div>
  </div> -->
  
  <p-tabs value="0">
    <p-tablist>
      <p-tab value="0">Operation Details</p-tab>
      <p-tab value="1" *ngIf="showDrillPipeData">Landing String
        <i class="validation-error pi pi-exclamation-circle pl-1" *ngIf="drillpipeMissing"
          pTooltip="Landing string not specified."></i>
      </p-tab>
      <p-tab value="2" *ngIf="showRiser">Riser
        <i class="validation-error pi pi-exclamation-circle pl-1"
          *ngIf="
            form.formGroup.controls?.insideDiameter.invalid ||
            form.formGroup.controls?.outsideDiameter.invalid
          " pTooltip="Operation not saved, some required inputs not specified."></i>
      </p-tab>
      <p-tab value="3">Other</p-tab>
      <p-tab *ngIf="useMudPits" value="4">Mud Pits
        <i
        class="validation-error pi pi-exclamation-circle pl-1"
        *ngIf="form.formGroup.controls.mudPits.invalid"
        pTooltip="Operation not saved, double check Mud Pit inputs."
      ></i> 
      </p-tab>
    </p-tablist>
    <p-tabpanels>
      <p-tabpanel value="0">

        <div class="flex flex-wrap gap-4">

          <!-- Left Column -->
          <div class="flex flex-col flex-1 min-w-[200px] space-y-2">

            <div class="flex flex-col">
              <label for="fluids">Lead Slurry</label>
              <p-select [disabled]="isReadOnly" [options]="slurries"
                optionLabel="state.name"
                [formControlName]="form.formControlNames.leadSlurry"
                dataKey="id" appendTo="body" tooltipPosition="top"
                showDelay="100" hideDelay="500">
                <ng-template pTemplate="selectedItem">
                  {{ form.formGroup.value.leadSlurry?.state.name }}
                </ng-template>
                <ng-template let-leadSlurry pTemplate="item">
                  {{ leadSlurry?.state.name }}
                </ng-template>
              </p-select>
            </div>

            <p-fieldset class="cementing">
              <ng-template pTemplate="header">
                <p-checkbox size="small" binary="true" (change)="onTailChange($event)"
                  [value]="form.formGroup.controls.length.value > 0" />
                <label for="length" class="ml-2">Tail Slurry</label>
              </ng-template>
              <div class="flex flex-col">
                <label for="fluids">Slurry</label>
                <p-select [disabled]="hasTailSlurry || isReadOnly" [options]="slurries"
                  optionLabel="state.name"
                  [formControlName]="form.formControlNames.slurry" dataKey="id" appendTo="body" tooltipPosition="top"
                  >
                  <ng-template pTemplate="selectedItem">
                    {{ form.formGroup.value.slurry?.state.name }}
                  </ng-template>
                  <ng-template let-slurry pTemplate="item">
                    {{ slurry?.state.name }}
                  </ng-template>
                </p-select>
              </div>

              <div class="flex flex-col">
                <label for="length">Length</label>
                <p-inputgroup>
                  <p-inputnumber [minFractionDigits]="1" [formControlName]="form.formControlNames.length" />
                  <p-inputgroup-addon>{{ userUnits.longLengths }}</p-inputgroup-addon>
                </p-inputgroup>
                <p *ngIf="form.formGroup.controls.length.invalid" class="validation-error">
                  Allowed range: 0 - {{ cementLength }} {{ userUnits.longLengths }}
                </p>
              </div>
            </p-fieldset>

            <div class="flex flex-col">
              <label for="fluids">Drilling Fluid</label>
              <p-select [disabled]="isReadOnly" [options]="fluids"
                [formControlName]="form.formControlNames.drillingFluid" 
                optionLabel="state.name"
                dataKey="id" appendTo="body"
                tooltipPosition="top">
                <ng-template pTemplate="selectedItem">
                  {{ form.formGroup.value.drillingFluid?.state.name }}
                </ng-template>
                <ng-template let-drillingFluid pTemplate="item">
                  {{ drillingFluid?.state.name }}
                </ng-template>
              </p-select>
            </div>

            <div class="flex flex-col">
              <label for="displacementRate">Displacement Rate</label>
              <p-inputgroup>
                <p-inputnumber [minFractionDigits]="1" [formControlName]="form.formControlNames.displacementRate" />
                <p-inputgroup-addon>{{standardFluidFlowUnit}}</p-inputgroup-addon>
              </p-inputgroup>
              <p *ngIf="
                  form.formGroup.controls.displacementRate.value &&
                  form.formGroup.controls.displacementRate.invalid
                " class="validation-error">
                Allowed range: {{ displacementRateValidation.min }} - {{ displacementRateValidation.max }} {{standardFluidFlowUnit }}
              </p>
            </div>

          </div>

          <!-- Right Column -->
          <div class="flex flex-col space-y-2">
            <div class="flex flex-col">
              <label for="slurryPumpRate">Slurry Pump Rate</label>
              <p-inputgroup>
                <p-inputnumber [minFractionDigits]="1" [formControlName]="form.formControlNames.slurryPumpRate" />
                <p-inputgroup-addon>{{ slurryFlowUnit }}</p-inputgroup-addon>
              </p-inputgroup>
              <p *ngIf="
                  form.formGroup.controls.slurryPumpRate.value &&
                  form.formGroup.controls.slurryPumpRate.invalid
                " class="validation-error">
                Allowed range: {{ slurryFlowRateValidation.min }} - {{ slurryFlowRateValidation.max }} {{ slurryFlowUnit
                }}
              </p>
            </div>

            <div class="flex flex-col">
              <label for="slurryInletTemperature">Slurry Inlet Temperature</label>
              <p-inputgroup>
                <p-inputnumber [minFractionDigits]="1" [formControlName]="form.formControlNames.slurryInletTemperature" />
                <p-inputgroup-addon>{{ userUnits.temperature }}</p-inputgroup-addon>
              </p-inputgroup>
              <p *ngIf="
                  form.formGroup.controls.slurryInletTemperature.value &&
                  form.formGroup.controls.slurryInletTemperature.invalid
                " class="validation-error">
                Allowed range: {{ this.temperatureValidation.min }} - {{ this.temperatureValidation.max }} {{
                userUnits.temperature }}
              </p>
            </div>

            <div class="flex flex-col">
              <label for="cementSetTime">Time to 50psi 'Right Angle' set:</label>
              <label for="cementSetTime">Cement Set Time</label>
              <p-inputgroup>
                <p-inputnumber [minFractionDigits]="1" [formControlName]="form.formControlNames.cementSetTime" />
                <p-inputgroup-addon>hr</p-inputgroup-addon>
              </p-inputgroup>
              <p *ngIf="
                  form.formGroup.controls.cementSetTime.value &&
                  form.formGroup.controls.cementSetTime.invalid
                " class="validation-error">
                Allowed range: 0 - 1000 hr
              </p>
            </div>

            <p-fieldset legend="Displacement Fluid">

              <div class="flex flex-col space-y-2">
                <div class="flex flex-col">
                  <label for="fluids">Fluid</label>
                  <p-select [disabled]="isReadOnly" [options]="fluids"
                    optionLabel="state.name" 
                    [formControlName]="form.formControlNames.fluid"
                    dataKey="id" appendTo="body" tooltipPosition="top" >
                    <ng-template pTemplate="selectedItem">
                      {{ form.formGroup.value.fluid?.state.name }}
                    </ng-template>
                    <ng-template let-fluid pTemplate="item">
                      {{ fluid?.state.name }}
                    </ng-template>
                  </p-select>
                </div>

                <div class="flex flex-col">
                  <label for="inletTemperature">Inlet Temperature</label>
                  <p-inputgroup>
                    <p-inputnumber [minFractionDigits]="1" [formControlName]="form.formControlNames.inletTemperature" />
                    <p-inputgroup-addon>{{ userUnits.temperature }}</p-inputgroup-addon>
                  </p-inputgroup>
                  <p *ngIf="form.formGroup.controls.inletTemperature.value && form.formGroup.controls.inletTemperature.invalid"
                    class="validation-error">
                    Allowed range: {{ this.temperatureValidation.min }} - {{ this.temperatureValidation.max }} {{ userUnits.temperature }}
                  </p>
                </div>
              </div>

            </p-fieldset>

          </div>
        </div>
      </p-tabpanel>

      <p-tabpanel value="1">
        <p class="{{ drillpipeMissing ? 'validation-error' : '' }}">{{ selectedDrillPipe }}</p>
      </p-tabpanel>

      <p-tabpanel value="2">

        <div class="flex flex-col">
          <label for="outsideDiameter">Outside Diameter</label>
          <p-inputgroup>
            <p-inputnumber [minFractionDigits]="1" [formControlName]="form.formControlNames.outsideDiameter" />
            <p-inputgroup-addon>{{ userUnits.shortLengths }}</p-inputgroup-addon>
          </p-inputgroup>
        </div>

        <div class="flex flex-col">
          <label for="outsideDiameter">Inside Diameter</label>
          <p-inputgroup>
            <p-inputnumber [minFractionDigits]="1" [formControlName]="form.formControlNames.insideDiameter" />
            <p-inputgroup-addon>{{ userUnits.shortLengths }}</p-inputgroup-addon>
          </p-inputgroup>
        </div>

      </p-tabpanel>

      <p-tabpanel value="3">
        <div class="flex flex-col">
          <label for="pipeRoughness">Pipe Roughness</label>
          <p-inputgroup>
            <p-inputnumber [minFractionDigits]="1" [formControlName]="form.formControlNames.pipeRoughness" />
            <p-inputgroup-addon>{{ userUnits.shortLengths }}</p-inputgroup-addon>
          </p-inputgroup>
          <p class="validation-error" *ngIf="form.formGroup.controls.pipeRoughness.invalid">
            Allowed range: {{ pipeRoughnessValidation.min }} - {{ pipeRoughnessValidation.max }} {{
            userUnits.shortLengths }}
          </p>
        </div>
      </p-tabpanel>

      <p-tabpanel value="4">
        <app-mud-pit-details
          [disableMudPits]="!useMudPits"
          [previousOperationMudPits]="previousOperationMudPits"
          [userUnits]="userUnits"
          [formControlName]="form.formControlNames.mudPits"
        ></app-mud-pit-details>
      </p-tabpanel>
    </p-tabpanels>

  </p-tabs>

</form>