<app-tool-loader [isLoading]="isLoading">
  <div #toolbarDiv>
    <p-toolbar>
      <ng-template #start>
        <div class="flex item-center space-x-2 toolbar-menu">

          <p-selectButton [options]="singleMultiple" [(ngModel)]="annularResultsStore.state.selectedSingleMultiple"
            (onChange)="modeToggle($event)" [allowEmpty]="false"></p-selectButton>

          <p-select *ngIf="annularResultsStore.state.selectedSingleMultiple === 'singleAnnulus'"
            [options]="resultTypesDropdown" (onChange)="dropdownChange($event)"
            [(ngModel)]="annularResultsStore.state.selectedResultType" optionLabel="header" optionValue="value"
            appendTo="body"></p-select>

          <p-select *ngIf="annularResultsStore.state.selectedSingleMultiple === 'multipleAnnuli'" [options]="annulusNames"
            (onChange)="dropdownChange($event)" [(ngModel)]="annularResultsStore.state.selectedAnnulus"
            optionLabel="header" appendTo="body"></p-select>

          <p-selectButton *ngIf="annularResultsStore.state?.selectedSingleMultiple === 'multipleAnnuli'"
            [options]="fluidGasDisplay" [(ngModel)]="annularResultsStore.state.selectedFluidGas"
            (onChange)="onSelectFluidGas($event)" [allowEmpty]="false">
          </p-selectButton>

          <p-selectButton *ngIf="annularResultsStore.state?.selectedSingleMultiple === 'singleAnnulus'"
            [options]="resultsDisplay" [(ngModel)]="annularResultsStore.state.selectedResultsDisplay"
            (onChange)="onSelectedResultsDisplayUpdated($event)" [allowEmpty]="false">
          </p-selectButton>

          <p-selectButton *ngIf="annularResultsStore.state?.selectedResultsDisplay === 'plot'" [options]="depthView"
            [(ngModel)]="annularResultsStore.state.selectedDepthView" (onChange)="depthViewToggle($event)"
            [allowEmpty]="false">
          </p-selectButton>

        </div>
      </ng-template>

      <ng-template #center>
        <p-select [options]="operations" [(ngModel)]="annularResultsStore.state.selectedOperation"
          (onChange)="onOperationSelected($event)" optionLabel="name" appendTo="body">
        </p-select>

        <p-select [options]="phases" [ngModel]="annularResultsStore.state.selectedPhase"
          (onChange)="onPhaseSelected($event)" optionLabel="phaseNumber" *ngIf="phases?.length > 1" appendTo="body">
          <ng-template pTemplate="selectedItem">
            Phase {{ annularResultsStore.state?.selectedPhase?.phaseNumber }}
          </ng-template>
          <ng-template let-phase pTemplate="item">
            Phase {{ phase.phaseNumber }}
          </ng-template>
        </p-select>
      </ng-template>

      <ng-template #end></ng-template>
    </p-toolbar>
  </div>

  <app-ng-table-results class="mt-2" *ngIf="annularResultsStore.state?.selectedResultsDisplay === 'grid'"
    tableName="Annular Results" [tableData]="resultsGrid" [columnDefinitions]="
      annularResultsStore.state?.selectedSingleMultiple === 'singleAnnulus'
        ? cols
        : multipleResultHeaders
    " [tableHeight]="tableHeight" [selectedOperation]="annularResultsStore.state?.selectedOperation"
    (onChange)="onOperationSelected($event)" [virtualScroll]="true"/>

  <app-thermal-results-plot class="mt-2" [hidden]="annularResultsStore.state?.selectedResultsDisplay !== 'plot'"
    xAxisTitle="{{ xAxisTitle }}" depthUnit="{{ depthUnit }}" plotTitle="Annular Results" [plotData]="results"
    [columnDefinitions]="cols" [selectedDepthDisplay]="annularResultsStore.state?.selectedDepthDisplay"
    [isAnnularResults]="true" [xAxisTickFormatNumber]="numDecimalPoints" [plotName]="plotName"
    [componentHeight]="componentHeight" [selectedDepthDisplay]="annularResultsStore.state.selectedDepthView"
    [thermalResultsStore]="annularResultsStore"
    >
  </app-thermal-results-plot>

  <p-dialog [draggable]="false" [(visible)]="missingOperations" [closable]="false">
    <i class="pi pi-exclamation-circle"></i> No Operations Exist: Please specify
    at least one operation to view results. <br />
    <br />
  </p-dialog>
  <p-dialog [draggable]="false" [visible]="isDialogVisible" [closable]="false">
    <i class="pi pi-exclamation-circle"></i> {{ calculationError }} <br />
    <br />
  </p-dialog>
</app-tool-loader>