export class UndisturbedTemperature {
  public constructor(init?: Partial<UndisturbedTemperature>) {
    Object.assign(this, init);
  }

  constantGradient: {
    surfaceAmbientTemperature: number;
    mudlineTemperature: number;
    temperatureGradient: number;
  }
  customPoints: CustomPoint[];
  isConstantGradient: boolean;
  temperatureAtTd?: number;
}

export class CustomPoint {
  public constructor(init? : Partial<CustomPoint>) {
    Object.assign(this, init);
  }

  public trueVerticalDepth: number;
  public temperature: number;
}

export class UndisturbedTemperaturePlot {
  public constructor(init? : Partial<UndisturbedTemperaturePlot>) {
    Object.assign(this, init);
  }

  public type: string;
  public x: number[];
  public y: number[];
}

export class UdtPlotUi {
  public constructor(init?: Partial<UdtPlotUi>) {
    this.plotFormationTops = init && init.plotFormationTops || false;
  }
  plotFormationTops: boolean;
}
