import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngneat/elf';
import { withUIEntities } from '@ngneat/elf-entities';
import { isEqual } from 'lodash-es';
import { SelectItem } from 'primeng/api';
import { lastValueFrom, Subscription } from 'rxjs';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { XyLinePlotUi } from 'src/app/core/models/xy-line-plot.model';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { SingleLoadResultsUi } from 'src/app/perivis/models/perivis-results-state.model';
import { StressResultsService } from 'src/app/perivis/services/stress-results.service';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { PeriforOnChangeMessages, SignalRService } from 'src/app/shared/services/signal-r.service';

@Component({
  selector: 'app-casing-wear-results',
  templateUrl: './casing-wear-results.component.html',
  styles: [``],
  standalone: false
})
export class CasingWearResultsComponent implements OnInit, OnDestroy {
  private _casingWearResults: any[];
  private _subscriptions: Subscription = new Subscription();

  public isLoading: boolean;
  public yAxisTitle = '';
  public xAxisTitle = '';
  public tableName = 'Max Allowed Wear';
  public userUnits: UserUnitsModel;
  public plotName = 'casingWearPlot';
  public longLengthUnit: string;
  public signalRChange: boolean;
  public tableHeight: string;
  public componentHeight: number;
  public columnDefs: { header: string, field: string }[];
  public columnDefinitions: any[];
  public errorMsg: string;
  public results: any[];

  public loadCases: SelectItem[] = [];
  public resultsDisplay: SelectItem[] = [
    { label: 'Plot', value: 'plot' },
    { label: 'Grid', value: 'grid' }
  ];

  //State Management
  private _componentId: string;
  @Input() set componentId(value: string) {
    this._componentId = value;
    this.casingWearResultsStore = this._storeService.createStore(this.componentId, new SingleLoadResultsUi, withUIEntities<XyLinePlotUi>());
  }
  get componentId(): string {
    return this._componentId;
  }
  public casingWearResultsStore: Store;


  constructor(
    private _stressResultsService: StressResultsService,
    private _signalRService: SignalRService,
    private _messenger: MediatorService,
    private _storeService: StoreService
  ) {
    // this._subscriptions.add(this._messenger.of(GridItemResizedMessage).subscribe((e) => {
    //   if (e.name.includes("Max Allowed Wear")) {
    //     this.tableHeight = (e.itemHeight - divHeight) + 'px';
    //     this.componentHeight = e.itemHeight - divHeight;
    //   }
    // }));
  }

  async ngOnInit(): Promise<void> {
    this.userUnits = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);
    this.longLengthUnit = this.userUnits.longLengths;

    this.refreshResults(true, false, false);

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));

  }

  private formatDecimal(value: any, divisor: number): string {
    return value || value === 0 ? (Math.trunc(value * divisor) / divisor).toLocaleString('en-US') : null;
  }

  signalRfunc(data: any) {
    if (data.action == PeriforOnChangeMessages.REFRESH_RESULTS) {
      this.refreshResults(true, true, false);
    }
  }

  private async refreshResults(isLoading: boolean, calledFromSignalR: boolean, calledFromMode: boolean) {
    this.isLoading = isLoading;

    this.yAxisTitle = `MD (${this.longLengthUnit})`;

    if ((!this._casingWearResults || calledFromSignalR) && !calledFromMode) {
      try {
        this._casingWearResults = await lastValueFrom(this._stressResultsService.getCasingWearResults());
        this.signalRChange = true;
      } catch {
        this.errorMsg = "Well Configuration Missing: Please specify at least one string";
        this.isLoading = false;
        return;
      }
    }

    if (this._casingWearResults && (this._casingWearResults.length === 0 || this._casingWearResults['maxAllowableWear'].length == 0)) {
      this.errorMsg = "Please calculate Perivis to see results";
      this.columnDefs = [];
      this.results = [];
      this.isLoading = false;
      return;
    } else {
      this.errorMsg = null;
    }

    this.handleSingleCasingWear(this._casingWearResults);

    this.isLoading = false;
  }

  private handleSingleCasingWear(casingWearResults: any) {
    this.results = [];

    this.columnDefs = [
      { header: 'Burst Wear', field: 'burstWear' },
      { header: 'Collapse Wear', field: 'collapseWear' },
      { header: 'Combined Wear', field: 'combinedWear' },
    ];

    this.columnDefinitions = [
      {
        header: `MD (${this.longLengthUnit})`,
        field: 'measuredDepth',
        valueFormatter: (params) => this.formatDecimal(params, 100),
      },
      {
        header: `TVD (${this.longLengthUnit})`,
        field: 'trueVerticalDepth',
        valueFormatter: (params) => this.formatDecimal(params, 100),
      },
      {
        header: `Burst Wear`,
        field: 'burstWear',
        valueFormatter: (params) => this.formatDecimal(params, 100),
      },
      {
        header: `Collapse Wear`,
        field: 'collapseWear',
        valueFormatter: (params) => this.formatDecimal(params, 100),
      },
      {
        header: `Combined Wear`,
        field: 'combinedWear',
        valueFormatter: (params) => this.formatDecimal(params, 100),
      },
    ];

    this.results = casingWearResults.maxAllowableWear;

    this.loadCases = casingWearResults.maxAllowableWear.map((wearData, index) => ({
      label: `Load Case ${index + 1}: MD ${wearData.measuredDepth}, TVD ${wearData.trueVerticalDepth}`,
      value: wearData,
    }));

    if (
      !this.casingWearResultsStore.state.selectedLoadCase ||
      !this.loadCases.some((l) =>
        isEqual(l.value, this.casingWearResultsStore.state.selectedLoadCase?.value)
      )
    ) {
      this.casingWearResultsStore.update((state) => ({
        ...state,
        selectedLoadCase: this.loadCases[0] ?? null,
      }));
    }
  }

  onSelectedResultDisplayUpdated(e): void {
    this.casingWearResultsStore.update(state => ({ ...state, selectedResultDisplay: e.value }));
  }

  ngOnDestroy(): void {
    this._subscriptions?.unsubscribe();
  }
}
