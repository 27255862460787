import { Component, OnInit, OnDestroy, ViewChild, Input, Renderer2, Inject } from '@angular/core';
import { debounceTime, lastValueFrom, Subject, Subscription, takeUntil } from 'rxjs';
import { Design } from '../../../shared/models/design.model';
import { PeriforOnChangeMessages, SignalRService } from '../../../shared/services/signal-r.service';
import { WellConfigService } from '../../../shared/services/well-config.service';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { ChangeSelectedTubular, DashboardItemAddedMessage, GridItemResizedMessage, OpenSearchComponentDropdown, ToggleWellSchematicSidebarMessage } from 'src/app/shared/models/mediator-messages.model';
import { CalculationsApiService } from './calculations-api.service';
import { LauncherSharedService } from '../../services/launcher-shared.service';
import { UsersService } from '../../services/users-service';
import { Tubular } from 'src/app/wellbore-inputs/components/well-configuration/models';
import { User } from '../user-admin-page/user-model';
import { StorageKeys, StoreService } from '../../services/store.service';
import { Store } from '@ngneat/elf';
import { Dropdown } from 'primeng/dropdown';
import { DialogService } from 'primeng/dynamicdialog';
import { UnitsConvertComponent } from '../units-convert/units-convert.component';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  providers: [DialogService],
  standalone: false
})
export class ToolbarComponent implements OnInit, OnDestroy {
  private searchTerm = '';
  private _destroy$ = new Subject<void>();
  private _subscriptions: Subscription = new Subscription();
  private _matchedProperty: string;
  private _schematicOpen = false;
  private _selectedTubularId: string;

  public selectedTubular: Tubular;
  public tubulars: Tubular[];
  public calculatingPerivis: boolean;
  public calculatingPerical: boolean;
  public calculatingPerinix: boolean;
  public currentDesign: Design;
  public isLoading: boolean;
  public components: any[];
  public selectedItems: any;
  public calcItem: any;
  public filteredComponents: any[];
  public component: any[];
  public designStore: Store;

  @Input()
  public user: User;

  @ViewChild('search')
  searchElement: any;

  constructor(
    private _launcherSharedService: LauncherSharedService,
    private _calcApiService: CalculationsApiService,
    private _userService: UsersService,
    private _wellConfigService: WellConfigService,
    private _signalRService: SignalRService,
    private _storeService: StoreService,
    private _mediator: MediatorService,
    private _dialogService: DialogService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document
    ) {
      const origOnKeydown = Dropdown.prototype.onKeyDown;
      Dropdown.prototype.onKeyDown = function (event: KeyboardEvent, search: boolean) {
        if (event.which == 9) {
          this.hide();
          return;
        }
        origOnKeydown.apply(this, [event, search]);
      };
    }

  async ngOnInit(): Promise<void> {
    this._selectedTubularId = await lastValueFrom(this._wellConfigService.getSelectedTubularId()).then(data => data?.id);
    this.designStore = this._storeService.getStore(StorageKeys.DESIGN);

    // Ensure cleanup with takeUntil
    this.designStore.pipe(takeUntil(this._destroy$)).subscribe(d => {
      this.calculatingPerinix = false;
      this.calculatingPerivis = false;
      this.calculatingPerical = false;
      this.currentDesign = d;
      this.getStringList();
    });

    this._subscriptions.add(
      this._mediator.of(ChangeSelectedTubular).subscribe((e) => {
        if (e.selectedTubular?.id == this.selectedTubular?.id) {
          return;
        }
        this._selectedTubularId = e.selectedTubular?.id;
        this.setSelectedTubular();

        this._storeService.set(StorageKeys.TUBULAR_ID, e.selectedTubular?.id || "");
        this._userService.updateCurrentAppIds({ tubularId: e.selectedTubular?.id }).subscribe();
      })
    );
    const hub = this._signalRService.getConnectionToNotificationHub();
    hub.on(SignalRService.ON_PFB_CHANGE, async (data: any) => {
      const design = await this._storeService.get<Design>(StorageKeys.DESIGN);
      if (design.id != data.designId) {
        return;
      }
      if (data.action == PeriforOnChangeMessages.REFRESH_TUBULARS_LIST) {
        this.getStringList();
      }
      if (data.action === PeriforOnChangeMessages.APB_CALCULATIONS_FINISHED) {
        this.calculatingPerinix = false;
      }
      if (data.action === PeriforOnChangeMessages.STRESS_CALCULATIONS_FINISHED) {
        this.calculatingPerivis = false;
      }
      if (data.action === PeriforOnChangeMessages.THERMAL_CALCULATIONS_FINISHED) {
        this.calculatingPerical = false;
      }
    });

    this._subscriptions.add(
      this._mediator.of(OpenSearchComponentDropdown)
        .pipe(debounceTime(100))
        .subscribe(() => {
          if (this.searchElement) {
            this.searchElement.show();    
            this.focusSearch();
          }
        })
    );   

    this.getComponents();
  }

  private focusSearch() {
    setTimeout(() => {
      const searchInput = this.searchElement?.el?.nativeElement?.querySelector('.p-multiselect-filter');
      if (searchInput && document.contains(searchInput)) {
        searchInput.focus();
      }
    }, 150);
  }

  private getComponents() {
    this._launcherSharedService.getComponents().subscribe(data => {
      this.components = data?.map(cmp => {
        return { key: cmp.key, name: cmp.name, properties: cmp.properties, displayLabel: `${cmp.name} (${cmp.properties.join(", ")}` };
      });
      this.components.sort((a, b) => a.name.localeCompare(b.name));
      this.populate();

      if (data == null || data.length == 0) {
        this.getComponents();
      }
    })
  }

  public populate() {
    this.filteredComponents = [...this.components];
    this.handleFilter({ filter: '' });

    this.focusSearch();
  }

  handleFilter(event: any): void {
    this.searchTerm = event.filter?.toLowerCase() || '';

    if (this.searchTerm) {
      this.filteredComponents = this.components.filter(component =>
        this.filteredProperty(component) !== null || this.filteredName(component) !== null
      );
    } else {
      this.filteredComponents = [...this.components];
    }
  }

  filteredProperty(component: any): string | null {
    if (!this.searchTerm) {
      return null;
    }
    const searchTerms = this.searchTerm.toLowerCase().split(' ').filter(term => term.trim() !== '');

    const matchedProperty = component.properties?.find((prop: string) => {
      const propWords = prop.toLowerCase().split(' ').filter(word => word.trim() !== '');

      return searchTerms.every(term =>
        propWords.some(word => word.startsWith(term))
      );
    });

    this._matchedProperty = matchedProperty || this._matchedProperty;
    return matchedProperty || null;
  }

  filteredName(component: any): string {
    if (!this.searchTerm) {
      return component.name;
    }
    const matchedName = component.name.toLowerCase().split(' ').find(word => word.startsWith(this.searchTerm));
    return matchedName || null;
  }

  async getStringList() {
    this.isLoading = true;

    this.tubulars = await lastValueFrom(this._wellConfigService.getStringsList());

    this.setSelectedTubular();

    this.isLoading = false;
  }

  public async addUiComponent(uiComponentName: string) {
    this._mediator.publish(new DashboardItemAddedMessage(uiComponentName['value'][0].key));
    this.selectedItems = [];

    setTimeout(() => {
      this.highlightLabel(this._matchedProperty);
    }, 1000);
  }

  highlightLabel(labelText: string) {
    if (labelText == null) {
      return;
    }
    const textElements: NodeListOf<HTMLElement> = this.document.querySelectorAll(
      'label'
    );

    const matchingElements: HTMLElement[] = [];

    textElements.forEach(element => {
      const rect = element.getBoundingClientRect();
      const isVisible = rect.width > 0 && rect.height > 0;

      if (isVisible && element.textContent?.trim().startsWith(labelText)) {
        matchingElements.push(element);
      }
    });

    matchingElements.forEach(element => {
      this.renderer.addClass(element, 'highlight');
      setTimeout(() => {
        this.renderer.removeClass(element, 'highlight');
      }, 3000);
    });
  }

  componentContainsProperty(component: any) {
    return component.properties.find(x => x == this._matchedProperty);
  }

  setSelectedTubular() {
    setTimeout(() => {
      this.selectedTubular = this.tubulars.find(t => t.id === this._selectedTubularId) ?? this.tubulars[0];
      if (!this.selectedTubular?.id && this.tubulars.length !== 0) {
        this._selectedTubularId = this.selectedTubular?.id;
        this.tubularSelectionChanged();
      }
    }, 300);
  }

  async onCalculateStress() {
    this.calculatingPerivis = true;
    await lastValueFrom(this._calcApiService.calculateStress());
  }

  async onCalculateThermal() {
    this.calculatingPerical = true;
    await lastValueFrom(this._calcApiService.calculateThermal());
  }

  async onCalculateApb() {
    this.calculatingPerinix = true;
    await lastValueFrom(this._calcApiService.calculateApb());
  }

  toggleSchematic() {
    this._mediator.publish(new ToggleWellSchematicSidebarMessage());
    this._mediator.publish(new GridItemResizedMessage("none", 0, 0));

    this._schematicOpen = !this._schematicOpen;
    this._launcherSharedService.setData(this._schematicOpen);
  }

  openUnitsConverter() {
    this._dialogService.open(UnitsConvertComponent, {
      header: 'Units Converter',
      width: '325px',
      height: '180px',
      closable: true,
      draggable: true,
      modal: false,
      styleClass: 'units-convert-dialog',
      style: {
        position: 'absolute',
        top: '75px',
        right: '20px'
      }
    });
  }

  tubularSelectionChanged() {
    if (this.selectedTubular) {
      this._storeService.set(StorageKeys.TUBULAR_ID, this.selectedTubular.id || "");
      this._userService.updateCurrentAppIds({ tubularId: this.selectedTubular.id }).subscribe();
    }
  }

  get isPericalDisabled(): boolean {
    return this.calculatingPerical || !this.selectedTubular || this.user.roles.readOnly;
  }

  get isPerinixDisabled(): boolean {
    return this.calculatingPerinix || this.calculatingPerical || !this.selectedTubular || this.user.roles.readOnly;
  }

  get isPerivisDisabled(): boolean {
    return this.calculatingPerivis || !this.selectedTubular || this.user.roles.readOnly;
  }

  ngOnDestroy() {
    this._subscriptions?.unsubscribe();
    this._destroy$.next(); // Emit a value to trigger unsubscription
    this._destroy$.complete(); // Complete the subject to clean up
  }

}
