<form [formGroup]="form.formGroup">

  <div class="flex flex-col space-y-2">

    <div *ngIf="
      operationType !== 'runCasingAndCirculate' &&
      operationType !== 'drilling' &&
      operationType !== 'tripPipeAndCirculate'
    "
      class="flex flex-col">
      <label for="pipeRoughness">Pipe Roughness</label>
      <p-inputgroup>
        <p-inputnumber id="pipeRoughness" [formControlName]="form.formControlNames.pipeRoughness" />
        <p-inputgroup-addon>{{ userUnits.shortLengths }}</p-inputgroup-addon>
      </p-inputgroup>
      <p class="validation-error" *ngIf="form.formGroup.controls.pipeRoughness.invalid">
        Allowed range: {{ pipeRoughnessValidation.min }} - {{ pipeRoughnessValidation.max }} {{ userUnits.shortLengths
        }}
      </p>
    </div>

    <div class="flex flex-col">
      <label for="waterMudFlowRate">
        {{ operationType === 'runCasingAndCirculate' || operationType === 'tripPipeAndCirculate' ? 'Circulation Rate' : 'Flow Rate' }}
      </label>
      <p-inputgroup>
        <p-inputnumber [formControlName]="form.formControlNames.flowRate" />
        <p-inputgroup-addon>{{ flowUnit }}</p-inputgroup-addon>
      </p-inputgroup>
      <p *ngIf="form.formGroup.controls.flowRate.value && form.formGroup.controls.flowRate.invalid"
        class="validation-error">
        Allowed range: {{ flowRateValidation.min }} - {{ flowRateValidation.max }} {{ flowUnit }}
      </p>
    </div>
  </div>

</form>