<form [formGroup]="form.formGroup">

  <div class="field">
    <label>Choke Name</label>
    <p-inputgroup>
      <input type="text" pInputText [formControlName]="form.formControlNames.name" />
    </p-inputgroup>
  </div>
  
  <br>

  <p-tabs value="0">
    <p-tablist>
      <p-tab value="0">Choke</p-tab>
      <p-tab value="1">Cv Schedule</p-tab>
    </p-tablist>

    <p-tabpanels class="w-full h-full">
      <p-tabpanel value="0">
        <div class="flex flex-col space-y-2">
          <div class="flex flex-col">
            <label>Critical Flow Factor (Liquid)</label>
            <p-inputNumber [minFractionDigits]="1" formControlName="criticalLiquidFlowFactor"></p-inputNumber>
            <p *ngIf="form.formGroup.controls.criticalLiquidFlowFactor.invalid && form.formGroup.value.criticalLiquidFlowFactor"
              class="validation-error">Allowed range: {{ 0 }} - {{ 1 }}</p>
          </div>

          <div class="flex flex-col">
            <label>Critical Flow Factor (Vapor)</label>
            <p-inputNumber [minFractionDigits]="1" formControlName="criticalVaporFlowFactor"></p-inputNumber>
            <p *ngIf="form.formGroup.controls.criticalVaporFlowFactor.invalid && form.formGroup.value.criticalVaporFlowFactor"
              class="validation-error">Allowed range: {{ 0 }} - {{ 1 }}</p>
          </div>

          <div class="flex flex-col">
            <label>Piping Geometry Factor</label>
            <p-inputNumber [minFractionDigits]="1" formControlName="pipingGeometryFactor"></p-inputNumber>
          </div>
        </div>
      </p-tabpanel>

      <p-tabpanel value="1">
          <div class="flex w-full gap-2">
            <div class="flex flex-col">
              <label>% Open</label>
              <p-inputNumber class="w-full" (onInput)="interpolateCv($event)" [minFractionDigits]="1"
                [disabled]="flowCoefficientSchedule?.length <= 1"></p-inputNumber>
            </div>

            <div class="flex flex-col">
              <label>Interpolated Cv</label>
              <p-inputNumber class="w-full" disabled="true" placeholder="{{ interpolatedCv }}"></p-inputNumber>
            </div>
          </div>

          <div *ngIf="chokesUiEntity$" class="mt-2">
            <p-toggleswitch (onChange)="tablePlotToggle($event)" [(ngModel)]="chokesUiEntity$.selectedResultsDisplay"
              [ngModelOptions]="{standalone: true}" binary="true">
            </p-toggleswitch>
            <label class="ml-2">Table/Plot</label>
          </div>

          <div class="w-full h-full">

            <app-xy-line-plot 
              [hidden]="!chokesUiEntity$?.selectedResultsDisplay" [plotData]="plot.data"
              [xAxisTitle]="xAxisTitle" [yAxisTitle]="yAxisTitle" [plotName]="choke?.name"
              [downloadPlotName]="downloadPlotName" [reverseAutoRange]="true" 
              plotTitle="Chokes" 
              [btmMargin]="250"
              [xyLinePlotStore]="chokesStore"
              [componentHeight]="componentHeight"
            ></app-xy-line-plot>

            <app-ng-table-grid [scrollHeight]="tableHeight" [hidden]="chokesUiEntity$?.selectedResultsDisplay"
              tableName="Flow Coefficient Schedule" parentComponentName="Chokes" [tableData]="flowCoefficientSchedule"
              [columnDefinitions]="columnDefinitions" [newRowFormGroup]="newDataRow" [inputFields]="inputFields"
              (dataChange)="onFlowScheduleChange($event)" [isDynamicLoaded]="true" [enableVirtualScroll]="false"
            />
          </div>

      </p-tabpanel>
    </p-tabpanels>
  </p-tabs>

</form>