import { Component, Input } from '@angular/core';
@Component({
    selector: 'app-fracture-pressure',
    template: `
    <app-tool-loader [isLoading]="false" positionUnset="true">
      <app-pore-and-frac [pressureType]="'fracPressure'" [componentId]="componentId"></app-pore-and-frac>
    </app-tool-loader>
  `,
    standalone: false
})
export class FracturePressureComponent {
  @Input()
  public componentId: string;
}
