<app-tool-loader [isLoading]="isLoading" positionUnset="true">

  <p-toolbar>
    <ng-template #start>
      <div class="flex gap-2 toolbar-menu">
        <p-selectButton [options]="modes" [(ngModel)]="connectionSafetyFactorResultsStore.state.selectedMode"
          (onChange)="modeToggle($event)" [allowEmpty]="false">
        </p-selectButton>

        <p-selectButton *ngIf="connectionSafetyFactorResultsStore.state?.selectedMode !== 'minimum'"
          [options]="resultsDisplay" [(ngModel)]="connectionSafetyFactorResultsStore.state.selectedResultDisplay"
          (onChange)="onSelectedResultDisplayUpdated($event)" [allowEmpty]="false">
        </p-selectButton>

        <p-checkbox 
          *ngIf="connectionSafetyFactorResultsStore.state?.selectedResultDisplay === 'grid'"
          size="small"
          [(ngModel)]="connectionSafetyFactorResultsStore.state.includeCharacters" label="Show Details"
          (onChange)="toggleCharacterView($event)" binary="true">
        </p-checkbox>

        <p-selectButton
          *ngIf="connectionSafetyFactorResultsStore.state?.selectedResultDisplay === 'plot' && connectionSafetyFactorResultsStore.state?.selectedMode !== 'minimum'"
          [options]="depthView" [(ngModel)]="connectionSafetyFactorResultsStore.state.selectedDepthView"
          (onChange)="depthViewToggle($event)" [allowEmpty]="false">
        </p-selectButton>

        <app-single-load-results
          [loadCases]="loadCases" 
          (loadCaseSelected)="setLoadCase($event)"
          [stateStore]="connectionSafetyFactorResultsStore"
          [signalRChange]="signalRChange">
        </app-single-load-results>
      </div>
    </ng-template>
  </p-toolbar>

  <app-single-load-plot 
    [hidden]="connectionSafetyFactorResultsStore.state?.selectedResultDisplay !== 'plot'"
    [yAxisTitle]="yAxisTitle" [xAxisTitle]="xAxisTitle" [columnDefs]="columnDefs" [data]="results"
    [selectedDepthView]="connectionSafetyFactorResultsStore.state?.selectedDepthView" [depthUnit]="depthUnit"
    [plotName]="plotName" [plotTitle]="'Connection Safety Factor Results'"
    [singleLoadPlotStore]="connectionSafetyFactorResultsStore">
  </app-single-load-plot>

  <app-single-load-grid 
    [hidden]="connectionSafetyFactorResultsStore.state?.selectedResultDisplay !== 'grid'"
    [rowData]="results" [designFactors]="designFactors" 
    [columnDefinitions]="columnDefinitions"
    [selectedLoad]="connectionSafetyFactorResultsStore.state.selectedLoadCase"
    tableName="Connection Safety Factor Results"
    gridName="Connection Safety Factor Results" 
    [mode]="connectionSafetyFactorResultsStore.state?.selectedMode">
  </app-single-load-grid>

  <p-dialog [draggable]="false" [(visible)]="errorMsg" [closable]="false">
    <i class="pi pi-exclamation-circle pr-1"></i>
    <label>{{ errorMsg }}</label>
    <br> <br>
  </p-dialog>

</app-tool-loader>