import { Component, Input, Output } from "@angular/core";
import { FormControl, UntypedFormControl, Validators } from "@angular/forms";
import { FormType, NGX_SUB_FORM_HANDLE_VALUE_CHANGES_RATE_STRATEGIES, createForm } from "ngx-sub-form";
import { Subject } from "rxjs";
import { AddUserAccount, User } from "./user-model";
import { WellExplorerItem } from "../../models/well-explorer.model";

@Component({
    selector: 'app-add-user-form',
    template: `
      <form (mouseover)="populateOrganization()" [formGroup]="form.formGroup">
        <div class="flex flex-col w-full space-y-2">

          <div class="flex flex-col">
            <label>Companies</label>
            <p-multiSelect 
              placeholder="Select Company" 
              [options]="companies" 
              [formControlName]="form.formControlNames.companies" 
              optionLabel="name" 
              display="chip" 
              dataKey="id">
            </p-multiSelect>
            <p class="validation-error" *ngIf="form.formGroup.controls.companies.errors?.['required']">Companies are required.</p>
          </div>

          <app-user-profile-form 
            [showAdminInputs]="showAdminInputs"
            [formControlName]="form.formControlNames.profile"
            (emailChanged)="onEmailChanged($event)"
            [user]="user"
          />

          <div *ngIf="showPassword" class="flex flex-col">
            <label>
              Password
              <i title="User can later change the password if needed." class="pi pi-info-circle infoClass"></i>
            </label>
            <p-password [feedback]=false [formControlName]="form.formControlNames.password" [toggleMask]="true" [minlength]="8"></p-password>
            <p class="validation-error" *ngIf="form.formGroup.controls.password.errors?.required">Password is required.</p>
            <p class="validation-error" *ngIf="form.formGroup.controls.password.value && form.formGroup.controls.password.hasError('strongPassword')">Password is not strong enough.</p>
          </div>

          <p-button class="flex align-start mt-2" (onClick)="save()">Save</p-button>

        </div>

      </form>
    `,
    styles: [``],
    standalone: false
})
export class AddUserFormComponent {

  private _showPassword: boolean;
  private input$ = new Subject<AddUserAccount | undefined>();

  @Input()
  companies: WellExplorerItem[];

  @Input()
  set showPassword(showPassword: boolean) {
    this._showPassword = showPassword;
    if (this._showPassword) {
      this.form.formGroup.controls.password.setValidators([Validators.required, this.strongPasswordValidator]);
    } else {
      this.form.formGroup.controls.password.removeValidators([Validators.required, this.strongPasswordValidator]);
    }
  };

  get showPassword(): boolean {
    return this._showPassword;
  }
  
  @Input()
  user: User;

  @Input() set userProfile(userProfile: AddUserAccount | undefined) {
    this.input$.next(userProfile);
  }

  private disabled$ = new Subject<boolean>();
  @Input() set disabled(value: boolean | undefined) {
    this.disabled$.next(!!value);
  }

  private _showAdminInputs: boolean;
  @Input() set showAdminInputs(value: boolean) {
    this._showAdminInputs = value;
  }
  get showAdminInputs(): boolean {
    return this._showAdminInputs;
  }

  @Output()
  userUpdated = new Subject<AddUserAccount>();

  @Output()
  emailChanged = new Subject<string>();

  manualSave$ = new Subject<void>();

  public onEmailChanged(email: string) {
    this.emailChanged.next(email);
  }

  public form = createForm<AddUserAccount, AddUserAccount>(this, {
    formType: FormType.ROOT,
    disabled$: this.disabled$,
    input$: this.input$,
    output$: this.userUpdated,
    manualSave$: this.manualSave$,
    formControls: {
      companies: new UntypedFormControl([Validators.required]),
      profile: new UntypedFormControl(null),
      password: new FormControl(null)
    },
    handleEmissionRate: NGX_SUB_FORM_HANDLE_VALUE_CHANGES_RATE_STRATEGIES.debounce(1000),
    toFormGroup: (obj: AddUserAccount): AddUserAccount => {
      return { ...obj };
    }
  });

  save() {
    this.manualSave$.next();
  }

  strongPasswordValidator(control) {
    const value = control.value;

    const hasUppercase = /[A-Z]/.test(value);
    const hasLowercase = /[a-z]/.test(value);
    const hasDigit = /\d/.test(value);
    
    // eslint-disable-next-line no-useless-escape
    const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\/-]/.test(value);

    const isValid = hasUppercase && hasLowercase && hasDigit && hasSpecialChar && value.length >= 8;

    if (!isValid) {
      return { strongPassword: true };
    }
    return null;
  }

  populateOrganization() {
    if (this.user.profile.organization != "Altus") {
      // TODO
    }
  }
}
