<form [formGroup]="form.formGroup">
    <div class="grid">
        <div class="col">
            <div class="field infoClass">
                <label style="font-size: 14px;" [title]="successiveFactorInfo" for="successiveRelationFactor">Successive Relaxation Factor (Energy Terms)</label>
                <span class="infoSpan">
                    <i [title]="successiveFactorInfo" class="pi pi-info-circle"></i>
                </span>
                <div class="p-inputgroup">
                    <p-inputNumber
                    [minFractionDigits]="1"
                    formControlName="successiveRelationFactor"
                    [title]="successiveFactorInfo"
                    ></p-inputNumber>
                </div>
                <p *ngIf="form.formGroup.controls.successiveRelationFactor.value && form.formGroup.controls.successiveRelationFactor.invalid" class="validation-error">Allowed range: 0.01 - 2</p>
            </div>

            <div class="field">
                <label [title]="latentHeatInputInfo" style="font-size: 14px;" for="latentHeatDampingFactor">Latent Heat Damping Factor (Stability)</label>

                <span style="padding-left: 7px;" class="infoSpan">
                    <i [title]="latentHeatInputInfo" class="pi pi-info-circle"></i>
                </span>

                <div class="p-inputgroup">
                    <p-inputNumber
                        [minFractionDigits]="1"
                        formControlName="latentHeatDampingFactor"
                        [title]="latentHeatInputInfo"
                    ></p-inputNumber>
                </div>
                <p *ngIf="form.formGroup.controls.latentHeatDampingFactor.value && form.formGroup.controls.latentHeatDampingFactor.invalid" class="validation-error">Allowed range: 0.01 - 1000</p>
            </div>
            <div class="field infoClass">
                <input
                  style="height: 13px; width: 20px;"
                  type="checkbox"
                  binary="true"
                  formControlName="includeLatentHeatPhaseChange"
                  [title]="latentHeatCheckboxInfo"
                >
                <label [title]="latentHeatCheckboxInfo" style="font-size: 14px; padding-left: 5px;">Include Latent Heat of Phase Change</label>
                
                <span class="infoSpan">
                    <i [title]="latentHeatCheckboxInfo" class="pi pi-info-circle"></i>
                </span>
            </div>
        </div>
    </div>
</form>