import { Component, Input, OnChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { unitsLib } from 'src/app/core/services/unit-library';
import { GetValueFromFahrenheit, GetValueFromPpg } from 'src/app/perivis/shared/helpers/units.helper';

export interface StandardMudForm {
  name: string;
  type: string;
  baseFluid: string;
  nominalDensity: number;
  densityTemperature: number;
  plasticViscosity: number;
  rheologyTemperature: number;
  yieldPoint: number;
}

@Component({
    selector: 'app-standard-muds',
    templateUrl: './standard-muds.component.html',
    styles: [``],
    providers: subformComponentProviders(StandardMudsComponent),
    standalone: false
})
export class StandardMudsComponent implements OnChanges {

  public pvDefault = 1;
  public ypDefault = 0;
  public tempUnit: string;
  public temperatureValidation: { min: number, max: number };

  @Input()
  public userUnits: UserUnitsModel;

  @Input()
  public isReadOnly: boolean;
  
  public densityUnits: string;
  public densityValidation: { min: number, max: number };

  public baseFluids = [
    { label: 'Freshwater', value: 'freshwater' },
    { label: 'Seawater', value: 'seawater'},
    { label: 'Diesel', value: 'no2Diesel' }
  ];

  public form = createForm<StandardMudForm>(this, {
    formType: FormType.SUB,
    formControls: {
      name: new UntypedFormControl(null),
      type: new UntypedFormControl(null),
      baseFluid: new UntypedFormControl(null, [Validators.required]),
      nominalDensity: new UntypedFormControl(null),
      densityTemperature: new UntypedFormControl(null),
      plasticViscosity: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(2000)]),
      rheologyTemperature: new UntypedFormControl(null),
      yieldPoint: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(2000)])
    }
  });

  ngOnChanges(): void {
    if (!this.userUnits) {
      return;
    }
    this.tempUnit = this.userUnits.temperature;
    this.densityUnits = unitsLib[this.userUnits.density].symbol;

    switch (this.tempUnit) {
      case '°F':
        this.temperatureValidation = { min: 0, max: 1000 };
        break;
      case '°C':
        this.temperatureValidation = { min: -17.7, max: 537 };
        break;
      case 'K':
        this.temperatureValidation = { min: 255, max: 810 };
        break;
    }

    this.form.formGroup.controls.densityTemperature.setValidators([Validators.required, Validators.min(this.temperatureValidation.min), Validators.max(this.temperatureValidation.max)]);
    this.form.formGroup.controls.rheologyTemperature.setValidators([Validators.required, Validators.min(this.temperatureValidation.min), Validators.max(this.temperatureValidation.max)]);

    switch (this.densityUnits) {
      case 'ppg':
        this.densityValidation = { min: 0.01, max: 25 };
        break;
      case 'kg/m³':
      case 'g/L':
        this.densityValidation = { min: 1.19, max: 2995 };
        break;
      case 'g/cm³':
      case 'kg/l':
      case 'sg':
        this.densityValidation = { min: 0.00119, max: 3 };
        break;
    }

    this.form.formGroup.controls.nominalDensity.setValidators([Validators.required, Validators.min(this.densityValidation.min), Validators.max(this.densityValidation.max)]);
  }

  public setDefaultPvYp(e, isDropdown): void {
    const baseFluid = isDropdown ? e.value : this.form.formGroup.controls.baseFluid.value;
    const density = isDropdown ? this.form.formGroup.controls.nominalDensity.value : e['srcElement']['value'];

    if ((baseFluid === 'freshwater' && density > GetValueFromPpg(8.5, this.densityUnits)) || (baseFluid === 'no2Diesel' && density > GetValueFromPpg(7.5, this.densityUnits))) {
      if (!this.form.formGroup.value.plasticViscosity || this.form.formGroup.value.plasticViscosity === this.pvDefault) {
        const pv = 2 * density - GetValueFromPpg(9.98, this.densityUnits);
        this.form.formGroup.controls.plasticViscosity.setValue(pv);
      }

      if (!this.form.formGroup.value.yieldPoint || this.form.formGroup.value.yieldPoint === this.ypDefault) {
        this.form.formGroup.controls.yieldPoint.setValue(7);
      }
    }
  }

  public applyDefaults(): void {
    const densityDefault = GetValueFromPpg(8.33, this.densityUnits);
    const tempDefault = GetValueFromFahrenheit(70, this.tempUnit);


    this.form.formGroup.controls.nominalDensity.setValue(densityDefault);
    this.form.formGroup.controls.densityTemperature.setValue(tempDefault);
    this.form.formGroup.controls.plasticViscosity.setValue(this.pvDefault);
    this.form.formGroup.controls.rheologyTemperature.setValue(tempDefault);
    this.form.formGroup.controls.yieldPoint.setValue(this.ypDefault);
  }
}
