import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map, shareReplay } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { FormationCementCatalog } from "../../models/formations-cement-catalog.model";

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api/formationCementCatalog/`;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class FormationsService {
  private formationCementCache$: Observable<FormationCementCatalog[]> | null = null;

  constructor(private http: HttpClient) { }

  public getFormationCement(): Observable<FormationCementCatalog[]> {
    if (!this.formationCementCache$) {
      this.formationCementCache$ = this.http
        .get<FormationCementCatalog[]>(`${PeriforApiEndpoint}`)
        .pipe(
          map(data => data.map(item => new FormationCementCatalog(item))),
          catchError(() => of([])),
          shareReplay(1)
        );
    }
    setTimeout(() => this.formationCementCache$ = null, 1000);
    return this.formationCementCache$;
  }

  addItem(model: FormationCementCatalog): Observable<{id: string}> {
    return this.http.post<{id: string}>(`${PeriforApiEndpoint}`, model);
  }

  updateItem(model: FormationCementCatalog): Observable<boolean> {
    return this.http.put<boolean>(`${PeriforApiEndpoint}${model.id}`, model, httpOptions);
  }

  deleteItem(id: string): Observable<boolean> {
    return this.http.delete<boolean>(`${PeriforApiEndpoint}${id}`);
  }
}
