<div class="flex flex-col items-center m-4 h-full overflow-auto">

  <p-card class="flex m-4 p-4 w-max">

    <ng-template pTemplate="title">
      <div class="flex flex-col items-center mt-2 space-y-2">
        <h1>Units</h1>
        <div class="flex space-x-2">
          <p-button severity="danger" outlined="true" icon="pi pi-refresh" label="Reset to API" (onClick)="resetToApi()">
          </p-button>
          <p-button severity="danger" outlined="true"  icon="pi pi-refresh" label="Reset to SI"  (onClick)="resetToSi()">
          </p-button>
          <p-button severity="danger" outlined="true"  icon="pi pi-refresh" label="Reset to Mixed Units" (onClick)="resetToMix()">
          </p-button>
        </div>

      </div>

      <hr class="my-4" />
    </ng-template>

    <div *ngIf="units" class="grid grid-cols-1 gap-4 p-4">

      <div class="grid grid-cols-1 md:grid-cols-3 gap-4">

        <div>
          <div class="flex flex-col space-y-2">

            <div class="flex flex-col">
              <label for="longLengths">Length</label>
              <p-select [(ngModel)]="units.longLengths" [options]="longLengthUnitOptions" appendTo="body"
                optionValue="key"></p-select>
            </div>

            <div class="flex flex-col">
              <label for="shortLengths">Diameter</label>
              <p-select [(ngModel)]="units.shortLengths" [options]="shortLengthUnitOptions" appendTo="body"
                optionValue="key"></p-select>
            </div>
            <div class="flex flex-col">
              <label for="linearDensity">Weight per length</label>
              <p-select [(ngModel)]="units.linearDensity" [options]="linearDensityUnitOptions" appendTo="body"
                optionValue="key"></p-select>
            </div>
            <div class="flex flex-col">
              <label for="temperature">Temperature</label>
              <p-select [(ngModel)]="units.temperature" [options]="temperatureUnitOptions" appendTo="body"
                optionValue="key"></p-select>
            </div>
            <div class="flex flex-col">
              <label for="pressure">Pressure</label>
              <p-select [(ngModel)]="units.pressure" [options]="pressureUnitOptions" appendTo="body"
                optionValue="key"></p-select>
            </div>
            <div class="flex flex-col">
              <label for="stressPressure">Stress Pressure</label>
              <p-select [(ngModel)]="units.stressPressure" [options]="stressPressureUnitOptions" appendTo="body"
                optionValue="key"></p-select>
            </div>
            <div class="flex flex-col">
              <label for="doglegSeverity">Dogleg Severity</label>
              <p-select [(ngModel)]="units.doglegSeverity" [options]="dlsUnitOptions" appendTo="body"
                optionValue="key"></p-select>
            </div>
            <div class="flex flex-col">
              <label for="temperatureGradient">Temperature Gradient</label>
              <p-select [(ngModel)]="units.temperatureGradient" [options]="tempGradientOptions" appendTo="body"
                optionValue="key"></p-select>
            </div>
            <div class="flex flex-col">
              <label for="density">Liquid Density</label>
              <p-select [(ngModel)]="units.density" [options]="densityUnitOptions" appendTo="body"
                optionValue="key"></p-select>
            </div>
          </div>
        </div>

        <div>
          <div  class="flex flex-col space-y-2">
            <div class="flex flex-col">
              <label for="density">Solids Density</label>
              <p-select [(ngModel)]="units.solidsDensity" [options]="solidsDensityUnitOptions" appendTo="body"
                optionValue="key"></p-select>
            </div>
            <div class="flex flex-col">
              <label for="force">Force</label>
              <p-select [(ngModel)]="units.force" [options]="forceUnitOptions" appendTo="body"
                optionValue="key"></p-select>
            </div>
            <div class="flex flex-col">
              <label>Thermal Conductivity</label>
              <p-select [(ngModel)]="units.thermalConductivity" [options]="thermalConductivityUnitOptions"
                appendTo="body" optionValue="key"></p-select>
            </div>
            <div class="flex flex-col">
              <label>Specific Heat Capacity</label>
              <p-select [(ngModel)]="units.specificHeatCapacity" [options]="specificHeatCapacityUnitOptions"
                appendTo="body" optionValue="key"></p-select>
            </div>
            <div class="flex flex-col">
              <label>Thermal Expansion Coefficient</label>
              <p-select [(ngModel)]="units.coefficientOfThermalExpansion" [options]="thermalExpansionCoeffUnitOptions"
                appendTo="body" optionValue="key"></p-select>
            </div>
            <div class="flex flex-col">
              <label>Gas Gradient</label>
              <p-select [(ngModel)]="units.gasGradient" [options]="gasGradientOptions" appendTo="body"
                optionValue="key"></p-select>
            </div>
            <div class="flex flex-col">
              <label>Standard Fluid Flow Rate</label>
              <p-select [(ngModel)]="units.standardFluidFlow" [options]="standardFluidFlowUnitOptions" appendTo="body"
                optionValue="key"></p-select>
            </div>
            <div class="flex flex-col">
              <label>Hydrocarbon Flow Rate</label>
              <p-select [(ngModel)]="units.hydrocarbonFlow" [options]="hydrocarbonFlowUnitOptions" appendTo="body"
                optionValue="key"></p-select>
            </div>
            <div class="flex flex-col">
              <label>Gas Flow Rate</label>
              <p-select [(ngModel)]="units.gasInjectionRate" [options]="gasInjectionRateUnitOptions" appendTo="body"
                optionValue="key"></p-select>
            </div>
          </div>
        </div>

        <div>
          <div  class="flex flex-col space-y-2">
            <div class="flex flex-col">
              <label>Mass Flow Rate</label>
              <p-select [(ngModel)]="units.massFlow" [options]="massFlowOptions" appendTo="body"
                optionValue="key"></p-select>
            </div>
            <div class="flex flex-col">
              <label>Slurry Flow Rate</label>
              <p-select [(ngModel)]="units.slurryFlow" [options]="slurryFlowRateOptions" appendTo="body"
                optionValue="key"></p-select>
            </div>
            <div class="flex flex-col">
              <label>Volume</label>
              <p-select [(ngModel)]="units.volume" [options]="volumeUnitOptions" appendTo="body"
                optionValue="key"></p-select>
            </div>
            <div class="flex flex-col">
              <label>Area</label>
              <p-select [(ngModel)]="units.area" [options]="areaUnitOptions" appendTo="body"
                optionValue="key"></p-select>
            </div>
            <div class="flex flex-col">
              <label for="speed">Speed</label>
              <p-select [(ngModel)]="units.speed" [options]="speedUnitOptions" appendTo="body"
                optionValue="key"></p-select>
            </div>
            <div class="flex flex-col">
              <label for="enthalpy">Enthalpy</label>
              <p-select [(ngModel)]="units.enthalpy" [options]="enthalpyOptions" appendTo="body"
                optionValue="key"></p-select>
            </div>
            <div class="flex flex-col">
              <label for="heat">Heat</label>
              <p-select [(ngModel)]="units.heat" [options]="heatOptions" appendTo="body" optionValue="key"></p-select>
            </div>
            <div class="flex flex-col">
              <label for="torque">Torque</label>
              <p-select [(ngModel)]="units.torque" [options]="torqueOptions" appendTo="body"
                optionValue="key"></p-select>
            </div>
          </div>
        </div>

      </div>

      <div class="flex justify-end mt-4 ">
        <form (ngSubmit)="onCancel()">
          <p-button class="mr-2" severity="danger" outlined="true" type="cancel">Cancel</p-button>
        </form>

        <form (ngSubmit)="onUserMetadataFormSubmit()">
          <p-button severity="success" outlined="true" type="submit">Save</p-button>
        </form>
      </div>

    </div>

  </p-card>

</div>