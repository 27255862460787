<p-toolbar>
  <ng-template #start>
    <label class="pr-3 font-small">
      <strong>Design:</strong> {{ currentDesign?.name }}
    </label>
    
    <b *ngIf="user?.roles?.readOnly" class="ml-2">[read-only]</b>

    <p-select placeholder="&#60;Well Configuration: Empty>" [options]="tubulars" [(ngModel)]="selectedTubular"
      dataKey="id" optionLabel="name" (ngModelChange)="tubularSelectionChanged()" appendTo="body">
      <ng-template pTemplate="selectedItem">
        <div title="Current String" *ngIf="selectedTubular">
          {{ selectedTubular.name }}
        </div>
      </ng-template>
      <ng-template let-tubular pTemplate="item">
        <div *ngIf="tubular">{{ tubular.name }}</div>
      </ng-template>
    </p-select>

    <div class="flex">
      <p-button severity="danger" outlined="true" title="Calculate Perical - Thermal" size="small" class="perical pl-1 calc-btns" (onClick)="onCalculateThermal()"
        [disabled]="isPericalDisabled" [loading]="calculatingPerical">
        <img *ngIf="!calculatingPerical" class="img perical-icon" src="../../assets/icons/icons8-fire-90.png" />
        Perical
      </p-button>
    
      <p-button severity="success" outlined="true" title="Calculate Perinix - APB" size="small" class="perinix pl-1 calc-btns" (onClick)="onCalculateApb()"
        [disabled]="isPerinixDisabled" [loading]="calculatingPerinix">
        <img *ngIf="!calculatingPerinix" class="img perinix-icon" src="../../assets/icons/icons8-pressure-100.png" />
        Perinix
      </p-button>
    
      <p-button severity="info" outlined="true" title="Calculate Perivis - Stress" size="small" class="perivis pl-1 calc-btns" (onClick)="onCalculateStress()"
        [disabled]="isPerivisDisabled" [loading]="calculatingPerivis">
        <img *ngIf="!calculatingPerivis" class="img perivis-icon" src="../../assets/icons/icons8-sigma-96.png" />
        Perivis
      </p-button>
    </div>
  
  </ng-template>
  
  <ng-template #end>
    <button
      pButton
      icon="pi pi-calculator"
      class="p-button-sm p-button-text p-button-secondary"
      (click)="openUnitsConverter()"
      pTooltip="Units Converter"
      tooltipPosition="left"
    ></button>

    <p-multiSelect 
      #search
      [filter]="true" 
      class="multiSelectClassNoCheckbox" 
      resetFilterOnHide="true" 
      [(ngModel)]="selectedItems" 
      placeholder="Search" 
      [showToggleAll]="false" 
      (onChange)="addUiComponent($event)" 
      (onFilter)="handleFilter($event)" 
      optionLabel="displayLabel" 
      (onFocus)="populate()"
      [options]="filteredComponents">
    
      <ng-template let-component pTemplate="item">
        <div>
          <label *ngIf="filteredName(component) && !filteredProperty(component)">{{ component.name }}</label>
          <strong *ngIf="filteredProperty(component)">{{ component.name }}</strong>
          
          <div *ngIf="filteredProperty(component) || filteredName(component)">
            <small *ngIf="componentContainsProperty(component)">
              Property: {{ filteredProperty(component) }}
            </small>
          </div>
        </div>
      </ng-template>
    </p-multiSelect>

    <p-button title="Toggle Well Schematic" size="small" [text]="true" (click)="toggleSchematic()">
      Well Schematic
      <img class="img well-icon" src="../../assets/icons/Schematic.png" />
    </p-button>

  </ng-template>
</p-toolbar>