<div *ngIf="!isLoading" class="p-2 h-full">
    <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

    <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
        <div class="flex flex-1 gap-4 overflow-auto">
            <div class="flex-[0.3] min-w-[150px] flex-shrink">
                <p-listbox
                    [options]="reservoirData?.reservoirs"
                    [(ngModel)]="selectedReservoir"
                    optionLabel="name"
                    (onChange)="onReservoirSelect($event)"
                >
                <ng-template pTemplate="header">
                    <p-button
                        class="outlined-button"
                        size="small"
                        icon="pi pi-plus"
                        severity="success"
                        label="Add Reservoir"
                        outlined="true"
                        (click)="onAddReservoir()"
                    ></p-button>
                </ng-template>
                <ng-template let-reservoir #item>
                    <div class="flex items-center w-full">
                        <div class="flex-1 truncate">
                            <span>{{ reservoir.name }}</span>
                        </div>
                        <div>
                            <i
                                class="pi pi-trash !text-red-400"
                                pTooltip="Delete Reservoir"
                                (click)="onDeleteReservoir(reservoir)"
                            ></i>
                        </div>
                    </div>
                </ng-template>
                </p-listbox>
            </div>
        
            <div class="flex-[0.7]">
                <app-reservoir-data
                    [reservoir]="selectedReservoirDetails$ | async"
                    (reservoirUpdate)="saveReservoir($event)"
                    [disabled]="reservoirData?.reservoirs?.length < 1"
                    [userUnits]="userUnits"
                ></app-reservoir-data>
            </div>
        </div>
    </p-scrollPanel>
</div>