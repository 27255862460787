import { AfterViewInit, Component, Input } from '@angular/core';
import { FormControl, UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { unitsLib } from 'src/app/core/services/unit-library';
import { PreInstallLoadType, RunInHole } from 'src/app/perivis/models/load-case.model';
import { GetValueFromPpg } from 'src/app/perivis/shared/helpers/units.helper';

export interface RunInHoleForm {
  RunInHoleSpeed: number;
  IsFlotation: boolean;
  InternalFluidDensity: number;
  HasBottomCap: boolean;
  HasFlotationPlug: boolean;
  PlugDepth: number;
  FluidDensity: number;
  type: PreInstallLoadType;
}

@Component({
    selector: 'app-run-in-hole-details',
    template: `
    <form [formGroup]="form.formGroup" class="space-y-2">
      
      <div class="flex flex-col">
        <label>Running Speed</label>
        <p-inputgroup>
          <p-inputNumber [formControlName]="form.formControlNames.RunInHoleSpeed" [minFractionDigits]="1"/>
          <p-inputgroup-addon>{{ speedUnit }}</p-inputgroup-addon>
        </p-inputgroup>
        <p 
          class="validation-error"
          *ngIf="form.formGroup.controls.RunInHoleSpeed.value && form.formGroup.controls.RunInHoleSpeed.invalid">
          Allowed range: {{ speedValidation.min }} - {{ speedValidation.max }} {{ speedUnit }}
        </p>
      </div>

      <div class="flex flex-col">
        <label>Internal Fluid Density</label>
        <p-inputgroup>
          <p-inputnumber [formControlName]="form.formControlNames.InternalFluidDensity" [minFractionDigits]="1"/>
          <p-inputgroup-addon>{{ densityUnit }}</p-inputgroup-addon>
        </p-inputgroup>
        <p 
          class="validation-error"
          *ngIf="form.formGroup.controls.InternalFluidDensity.value && form.formGroup.controls.InternalFluidDensity.invalid">
          Allowed range: {{ densityValidation.min }} - {{ densityValidation.max }} {{ densityUnit }}
        </p>
      </div>

      <div class="flex mt-2">
        <p-fieldset class="w-full">

            <ng-template pTemplate="header">
              <div class="flex align-items-center">
                <p-toggleswitch
                  [formControlName]="form.formControlNames.IsFlotation"
                  binary="true"
                  (onChange)="checkFlotation()"
                ></p-toggleswitch>
                <label>Flotation</label>
              </div>
            </ng-template>

          <div class="flex flex-col space-y-2">
            <div>
              <p-checkbox
                size="small"
                binary="true"
                [formControlName]="form.formControlNames.HasBottomCap"
              >
              </p-checkbox>
              <label class="ml-2 pt-1">Bottom end cap or float plug</label>
            </div>

            <div>
              <p-checkbox
                size="small"
                binary="true"
                [formControlName]="form.formControlNames.HasFlotationPlug"
                (onChange)="hasPlugChange()"
              >
              </p-checkbox>
              <label class="ml-2 pt-1">Has Plug</label>
            </div>

            <div class="flex flex-col">
              <label>Plug Depth</label>
              <p-inputgroup>
                <p-inputnumber [formControlName]="form.formControlNames.PlugDepth" [minFractionDigits]="1"/>
                <p-inputgroup-addon>{{ depthUnit }} MD</p-inputgroup-addon>
              </p-inputgroup>
              <p 
                class="validation-error"
                *ngIf="form.formGroup.controls.PlugDepth.value && form.formGroup.controls.PlugDepth.invalid">
                Allowed range: {{ this.hangerMd.toFixed(2) }} - {{ this.shoeMd.toFixed(2) }} {{ this.depthUnit }}
              </p>
            </div>

            <div class="flex flex-col">
              <label>Fluid Density (below plug)</label>
              <p-inputgroup>
                <p-inputnumber [formControlName]="form.formControlNames.FluidDensity" [minFractionDigits]="1"/>
                <p-inputgroup-addon>{{ densityUnit }}</p-inputgroup-addon>
              </p-inputgroup>
              <p 
                class="validation-error"
                *ngIf="form.formGroup.controls.FluidDensity.value && form.formGroup.controls.FluidDensity.invalid" 
                >Allowed range: {{ densityValidation.min }} - {{ densityValidation.max }} {{ densityUnit }}
              </p>
            </div>

          </div>
        </p-fieldset>
      </div>
    </form>
  `,
    providers: subformComponentProviders(RunInHoleDetailsComponent),
    standalone: false
})
export class RunInHoleDetailsComponent implements AfterViewInit {
  @Input() userUnits: UserUnitsModel;
  @Input() shoeMd: number;
  @Input() hangerMd: number;
  @Input() annularFLuidDensity: number;

  public speedUnit: string;
  public speedValidation: { min: number, max: number };
  public densityUnit: string;
  public densityValidation: { min: number, max: number };
  public depthUnit: string;
  public isFlotation: boolean;
  public hasFlotationPlug: boolean;

  ngAfterViewInit(): void {
    this.speedUnit = this.userUnits.speed;
    this.densityUnit = unitsLib[this.userUnits.density].symbol;
    this.depthUnit = this.userUnits.longLengths;

    if (this.userUnits.speed == 'ft/s') {
      this.speedValidation = { min: 0, max: 100 };
    } else {
      this.speedValidation = { min: 0, max: 30 };
    }
    this.densityValidation = { min: GetValueFromPpg(0.01, this.densityUnit), max: GetValueFromPpg(25, this.densityUnit) };

    this.form.formGroup.controls.RunInHoleSpeed.setValidators([Validators.min(this.speedValidation.min), Validators.max(this.speedValidation.max), Validators.required]);
    this.form.formGroup.controls.InternalFluidDensity.setValidators([Validators.min(this.densityValidation.min), Validators.max(this.densityValidation.max), Validators.required]);
    this.form.formGroup.controls.FluidDensity.setValidators([Validators.min(this.densityValidation.min), Validators.max(this.densityValidation.max)]);
    this.form.formGroup.controls.PlugDepth.setValidators([Validators.min(this.hangerMd), Validators.max(this.shoeMd)]);

    if (!this.form.formGroup.controls.InternalFluidDensity.value) {
      this.form.formGroup.controls.InternalFluidDensity.setValue(this.annularFLuidDensity, { emitEvent: false });
    }

    if (!this.form.formGroup.controls.FluidDensity.value || !this.form.formGroup.controls.HasFlotationPlug.value) {
      this.form.formGroup.controls.FluidDensity.setValue(this.annularFLuidDensity, { emitEvent: false });
    }

    if (!this.form.formGroup.controls.PlugDepth.value || !this.form.formGroup.controls.HasFlotationPlug.value) {
      this.form.formGroup.controls.PlugDepth.setValue(this.shoeMd, { emitEvent: false });
    }

    if (this.form.formGroup.controls.HasFlotationPlug.value) {
      this.hasPlug();
    } else {
      this.doesntHavePlug();
    }

    this.checkFlotation();
  }

  public form = createForm<RunInHoleForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(null),
      RunInHoleSpeed: new UntypedFormControl(null),
      IsFlotation: new UntypedFormControl(false),
      InternalFluidDensity: new UntypedFormControl(null),
      HasBottomCap: new UntypedFormControl(false),
      HasFlotationPlug: new UntypedFormControl(false),
      PlugDepth: new FormControl(null),
      FluidDensity: new UntypedFormControl(null)
    },
    toFormGroup: (obj: RunInHole | null): RunInHoleForm => {
      if (!obj) {
        return null;
      }

      return {
        RunInHoleSpeed: obj.RunInHoleSpeed,
        IsFlotation: obj.IsFlotation,
        InternalFluidDensity: obj.InternalFluidDensity,
        HasBottomCap: obj.HasBottomCap,
        HasFlotationPlug: obj.HasFlotationPlug,
        PlugDepth: obj.PlugDepth,
        FluidDensity: obj.FluidDensity,
        type: obj.type
      }
    },
    fromFormGroup: (formValue: RunInHoleForm): RunInHole => {
      return {
        RunInHoleSpeed: formValue.RunInHoleSpeed,
        IsFlotation: formValue.IsFlotation,
        InternalFluidDensity: formValue.InternalFluidDensity,
        HasBottomCap: formValue.HasBottomCap,
        HasFlotationPlug: formValue.HasFlotationPlug,
        PlugDepth: formValue.PlugDepth ? formValue.PlugDepth : this.shoeMd,
        FluidDensity: formValue.FluidDensity ? formValue.FluidDensity : this.annularFLuidDensity,
        type: PreInstallLoadType.RUNINHOLE
      }
    }
  })

  public checkFlotation() {
    if (this.form.formGroup.controls.IsFlotation.value) {
      this.form.formGroup.controls.HasBottomCap.enable({ emitEvent: false });
      this.form.formGroup.controls.HasFlotationPlug.enable({ emitEvent: false });
      this.hasPlug();
      this.isFlotation = true;
    } else {
      this.form.formGroup.controls.HasBottomCap.disable({ emitEvent: false });
      this.form.formGroup.controls.HasFlotationPlug.disable({ emitEvent: false });
      this.doesntHavePlug();
      this.isFlotation = false;
    }
  }

  public hasPlugChange() {
    if (this.form.formGroup.controls.HasFlotationPlug.value) {
      this.hasPlug();
    } else {
      this.doesntHavePlug();
    }
  }

  private hasPlug() {
    if (!this.form.formGroup.controls.PlugDepth.value) {
      // this.form.formGroup.controls.PlugDepth.setValue(+this.shoeMd.toFixed(2), { emitEvent: false });
    }
    if (this.form.formGroup.controls.HasFlotationPlug.value || !this.form.formGroup.controls.IsFlotation.value) {
      this.form.formGroup.controls.PlugDepth.enable({ emitEvent: false });
      this.form.formGroup.controls.FluidDensity.enable({ emitEvent: false });
      this.hasFlotationPlug = true;
    }
  }

  private doesntHavePlug() {
    if (!this.form.formGroup.controls.HasFlotationPlug.value || !this.form.formGroup.controls.IsFlotation.value) {
      this.form.formGroup.controls.PlugDepth.disable({ emitEvent: false });
      this.form.formGroup.controls.FluidDensity.disable({ emitEvent: false });
      this.hasFlotationPlug = false;
    }
  }
}
