export class Packers {
  packers: Packer[];
  id: string;
  designId: string;
  tubularStringId: string;
  mudlineDepth: number;
  topOfCementMd : number;
  hangerMd: number;
  shoeMd: number;
  isFullyCemented: boolean;
}

export class Packer {
  name: string;
  type: string;
  measuredDepth: number;
  initialSetPressure: number;
  plugDepth: number;
  pickupForce: number;
  hasExpansionJoint: boolean;
  expansionJoint: ExpansionJoint;
  isPressureBarrier: boolean;
  packerEnvelope: PackerEnvelope[];
  sort: number;

  constructor(init?: Partial<Packer>) {
    Object.assign(this, init);

    if (this.type == 'Mechanical') {
      this.initialSetPressure = init?.initialSetPressure ?? 0;
      this.plugDepth = init?.plugDepth ?? 0;
    }

    this.packerEnvelope = init?.packerEnvelope || [];
  }
}

export class ExpansionJoint {
  sealBoreContactDiameter: number;
  isSheared: boolean;
  upwardLimit?: number;
  downwardLimit?: number;

  constructor(init: Partial<ExpansionJoint>) {
    Object.assign(this, init);

    this.isSheared = init?.isSheared ?? true;
  }
}

export class PackerEnvelope {
  public force: number;
  public differentialPressure: number;
}

export class PackerEnvelopeUi {
  public constructor(init?: Partial<PackerEnvelopeUi>) {
    this.selectedPacker = init && init.selectedPacker || null;
  }
  selectedPacker: string;
}


export class PackersUi {
  public constructor(init?: Partial<PackersUi>) {
    this.selectedPacker = init && init.selectedPacker || null;
  }
  selectedPacker: Packer;
}

export class PackerResultsUi {
  public constructor(init?: Partial<PackerResultsUi>) {
    this.selectedPacker = init && init.selectedPacker || null;
    this.selectedResultDisplay = init && init.selectedResultDisplay || 'grid';
    this.selectedResultView = init && init.selectedResultView || 'differentialPressure';
    this.displacementType = init && init.displacementType || 'absoluteDisplacements';
  }
  selectedPacker: Packer;
  selectedResultDisplay: string;
  selectedResultView: string;
  displacementType: string;
}
