<div class="w-full h-full p-2">

  <p-dialog [draggable]="false" [visible]="isDialogVisible" [closable]="false">
    <i class="pi pi-exclamation-circle"></i> {{ disabledMessage }} <br />
    <br />
  </p-dialog>

    <p class="text-sm text-center" *ngIf="!isTubing">
      {{ currentStringName }}
    </p>

    <hr *ngIf="!isTubing" />

    <app-tool-loader class="drillstring" [isLoading]="isLoading" positionUnset="true">
    <p-scrollPanel [style]="{ width: '100%', height: '92%' }">
      <form [formGroup]="drillstringForm">

        <div class="flex flex-col mt-2 space-y-2">

          <p-fieldset legend="Drill Pipe">
            <div class="flex items-end gap-2">
              <!-- Select Box -->
              <div class="flex-1">
                <label class="mb-2">Drill Pipe</label>
                <p-select placeholder="Select" formControlName="drillPipe" [options]="drillPipes" appendTo="body"
                  class="w-full"></p-select>
              </div>
              <!-- Button -->
              <p-button class="outlined-button" outlined="true" size="small" severity="danger" pTooltip="Reset Drill Pipe" label="Reset"
                (onClick)="resetDrillPipe()"></p-button>
            </div>
          </p-fieldset>

          <p-fieldset legend="Heavy Weight Drill Pipe">
            <div class="flex items-end gap-2">

              <div class="flex-1">
                <label class="mb-2">HWDP</label>
                <p-select placeholder="Select" formControlName="heavyWeightDrillPipe" [options]="hwdps" appendTo="body"
                  class="w-full">
                </p-select>
              </div>

              <div class="flex-1 max-w-xs">
                <label class="mb-2">Length</label>
                <p-inputgroup>
                  <p-inputnumber [minFractionDigits]="1" formControlName="hwdpLength" />
                  <p-inputgroup-addon>{{ longLengthUnit }}</p-inputgroup-addon>
                </p-inputgroup>
              </div>
              <p-button class="outlined-button" size="small" severity="danger" outlined="true" pTooltip="Reset HWDP Data"
                label="Reset" (onClick)="resetHwdp()">
              </p-button>
            </div>
          </p-fieldset>

          <p-fieldset legend="Collars">
            <div class="flex items-end gap-2">

              <div class="flex-1">
                <label class="mb-2">Collar</label>
                <p-select
                  placeholder="Select"
                  formControlName="collar"
                  [options]="collars"
                  appendTo="body"
                  class="w-full"
                ></p-select>
              </div>
          
              <div class="flex-1 max-w-xs">
                <label class="mb-2">Length</label>
                <p-inputgroup>
                  <p-inputnumber [minFractionDigits]="1" formControlName="collarLength"/>
                  <p-inputgroup-addon>{{ longLengthUnit }}</p-inputgroup-addon>
                </p-inputgroup>
              </div>
          
              <p-button
                class="outlined-button"
                size="small"
                severity="danger"
                outlined="true"
                pTooltip="Reset Collar Data"
                label="Reset"
                (onClick)="resetCollar()"
              ></p-button>
            </div>

          </p-fieldset>

        </div>
      </form>

    </p-scrollPanel>
  </app-tool-loader>
</div>
