<div class="well-explorer-cmp">
  <p-card>

    <ng-template #header>
      <app-well-explorer-header
        [isAdmin]="userRoles?.admin"
        (closeSidenav)="handleCloseSidenav()"
        (companyAdded)="handleAddCompany($event)"
        (designUpdated)="nodeSelect($event)"
        [design]="design"
        [allDesigns]="allDesigns"
      />
    </ng-template>

    <p-treeTable
      #treeTable
      dataKey="id"
      [autoLayout]="false"
      [scrollable]="true"
      [scrollHeight]="scrollHeight"
      [columns]="cols"
      contextMenuSelectionMode="joint"
      indentation=".15"
      selectionMode="single"
      [(selection)]="selectedNode"
      [loading]="loading"
      (onNodeSelect)="nodeSelect($event)"
      [(contextMenuSelection)]="selectedNode"
      [contextMenu]="cm"
      [value]="treeItems"
    >
    <ng-template #body let-rowNode let-rowData="rowData">
      <tr [ngClass]="{ 'selected-node': selectedNode === rowNode.node }">
        
      <app-well-explorer-item
        [treeItems]="treeItems"
        [treeNode]="rowNode"
        [columns]="cols"
        [rowData]="rowData"
        [ttContextMenuRow]="rowNode"
        [selectedNode]="selectedNode"
        [isAdmin]="userRoles?.admin"
        (childNodeAdded)="handleAddChildNode($event)"
        (designCloned)="handleCloneDesign($event)"
        (openDesignInNewTab)="handleOpenDesignInNewTab($event)"
        (nodeDeleted)="handleDeleteNode($event)"
        (itemRenamed)="handleRenameItem($event)"
        (designExported)="handleExportDesign($event)"
        (designMoved)="handleShowMoveDesignDialog($event)"
        (contextMenuUpdated)="handleContextMenuUpdated($event)"
        (importDesignDialogShow)="handleShowImportDesignDialog($event)"
      />
    </ng-template>
  </p-treeTable>
</p-card>

<p-contextMenu #cm [model]="contextMenuItems" appendTo="body"></p-contextMenu>

</div>
