import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { StorageKeys, StoreService } from '../../services/store.service';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { GridItemResizedMessage, ToggleWellSchematicSidebarMessage } from 'src/app/shared/models/mediator-messages.model';
import { WellSchematicComponent } from 'src/app/wellbore-inputs/components/well-schematic/well-schematic.component';
import { UsersService } from '../../services/users-service';
import { lastValueFrom, Subscription } from 'rxjs';
import { User } from '../user-admin-page/user-model';
import { Store } from '@ngneat/elf';

@Component({
    selector: 'app-well-designer-page',
    templateUrl: './well-designer-page.component.html',
    styleUrls: ['./well-designer-page.component.scss'],
    standalone: false
})
export class WellDesignerPageComponent implements OnInit, OnDestroy {

  public designStore: Store<any, any>;
  public designId: string;
  public showWellSchematic: boolean;
  public user: User;
  private _subscriptions = new Subscription();
  
  @ViewChild('schematic')
  public wellSchematic: WellSchematicComponent;

  constructor(
    private _mediator: MediatorService,
    private _storeService: StoreService,
    private _usersService: UsersService
  ) {}

  async ngOnInit(): Promise<void> {
    // Initialize the store
    this.designStore = this._storeService.createStore(StorageKeys.DESIGN, {});

    // Subscribe to store changes
    this._subscriptions.add(
      this.designStore.subscribe(dId => {
        this.designId = dId;
      })
    );

    this.designId = (await this._storeService.get<any>(StorageKeys.DESIGN))?.id;

    // Subscribe to mediator messages
    this._subscriptions.add(
      this._mediator.of(ToggleWellSchematicSidebarMessage).subscribe(() => {
        this.toggleWellSchematic();
      })
    );

    // Fetch user and save user-related data to the store
    this.user = await lastValueFrom(this._usersService.getUser());

    await this._storeService.set(StorageKeys.UNITS, this.user?.units);
    await this._storeService.set(StorageKeys.ROLES, this.user?.roles);
    await this._storeService.set(StorageKeys.USER, this.user);
  }

  public requestSchematicRedraw() {
    this.wellSchematic.drawSchematic();
    // Fire event to resize components.
    this._mediator.publish(new GridItemResizedMessage("", 0, 0));
  }

  private toggleWellSchematic() {
    this.showWellSchematic = !this.showWellSchematic;
  }

  ngOnDestroy(): void {
    this._subscriptions?.unsubscribe();
  }
}
