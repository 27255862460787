import { Injectable } from '@angular/core';
import { Subject} from 'rxjs';
import { ComponentLauncher } from '../components/dashboard/services/component-launcher';

@Injectable({
  providedIn: 'root'
})
export class LauncherSharedService {

  public schematicExpanded = new Subject<boolean>();
  public componentRegistry = new Subject<ComponentLauncher>();
  public sidebarExpanded = new Subject<boolean>();

  public setData(schematicOpen?: boolean, componentRegistry?: ComponentLauncher, sidebarExpanded?: boolean) {
    if (schematicOpen !== undefined && schematicOpen !== null) {
      this.schematicExpanded.next(schematicOpen);
    }
    if (componentRegistry !== undefined && componentRegistry !== null) {
      this.componentRegistry.next(componentRegistry);
    }
    if (sidebarExpanded !== undefined && sidebarExpanded !== null) {
      this.sidebarExpanded.next(sidebarExpanded);
    }
  }

  public getData(): Subject<boolean> {
   return this.schematicExpanded;
  }

  public getComponents(): any {
    return this.componentRegistry;
  }

  public getSidebarExpanded() {
    return this.sidebarExpanded;
  }
}