import { Component, Input, OnChanges, OnDestroy } from '@angular/core';
import { Store } from '@ngneat/elf';
import { TrajectoryXyzModel } from 'src/app/shared/models/trajectory-xyz.model';

@Component({
    selector: 'app-three-dee-plot',
    templateUrl: './3Dplot.component.html',
    styles: [``],
    standalone: false
})
export class ThreeDeePlotComponent implements OnChanges, OnDestroy {

  private _intervalID = 0;
  private _rotationAngle = 0
  private _plotPoints: TrajectoryXyzModel;

  @Input()
  public set xyzData(data: TrajectoryXyzModel) {
    if (data) {
      this._plotPoints = data;
      this.setPlotData(data);
    }
  }

  @Input() public trajectoryPlotStore: Store;

  @Input() public longLengths: string;

  @Input() public isDarkMode: boolean;

  constructor() {
    this.plotlyData = [];
  }

  public btnItems: any[];
  public plotlyData: any[];
  public maxRange: number;
  public minRange: number;

  public config: any = {
    responsive: true,
    displaylogo: false,
    scrollZoom: true,
    toImageButtonOptions: {
      filename: 'trajectory_plot',
      width: 500,
      height: 500,
      format: 'png'
    }
  };

  public layout: any;

  ngOnChanges() {
    this.onRotationCheckChange({ checked: this.trajectoryPlotStore.state.isRotating });
    this.onProjectionsCheckChange({ checked: this.trajectoryPlotStore.state.isShowingProjections });
  }

  public onRotationCheckChange(e) {
    clearInterval(this._intervalID);
    this.trajectoryPlotStore.update((state) => ({
      ...state,
      isRotating: e.checked
    }))
    if (this.trajectoryPlotStore.state.isRotating) {
      this._intervalID = setInterval(() => this.createTurntableRotationNextFrame(), 42) as any;
    } else {
      clearInterval(this._intervalID);
    }
  }

  public onProjectionsCheckChange(e) {
    this.trajectoryPlotStore.update((state) => ({
      ...state,
      isShowingProjections: e.checked
    }))
    this.setPlotData(this._plotPoints);
  }

  private setPlotData(plotData: TrajectoryXyzModel) {
    if (!plotData) {
      return;
    }
    const minRanges = [Math.min(...plotData.x), Math.min(...plotData.y), Math.min(...plotData.z)];
    this.minRange = Math.min(...minRanges);

    const maxRanges = [Math.max(...plotData.x), Math.max(...plotData.y), Math.max(...plotData.z)];
    this.maxRange = Math.max(...maxRanges);

    this.layout = this.create3dLayout();
    this.layout.showlegend = false;

    const norView = { ...plotData, x: [] };
    const estView = { ...plotData, y: [] };
    const planView = { ...plotData, z: [] };

    const xyRange = Math.max(...[Math.abs(this.minRange), Math.abs(this.maxRange)]);
    const zMax = Math.max(...plotData.z);

    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < plotData.x.length; i++) {
      norView.x.push(-xyRange);
      estView.y.push(-xyRange);
      planView.z.push(zMax);
    }

    this.plotlyData = [
      {
        name: '3D Line',
        line: { shape: 'spline', smoothing: 1.3, width: 10 },
        mode: 'lines',
        type: 'scatter3d',
        ...plotData,
      }
    ];

    if (this.trajectoryPlotStore.state.isShowingProjections) {
      const lineColor = this.isDarkMode ? '#FFF' : '#000';
      const line = { shape: 'spline', smoothing: 1.3, width: 1, color: lineColor, dash: 'dash' };
      
      const views = [
        { name: 'Plan', ...planView },
        { name: 'Northing', ...estView },
        { name: 'Easting', ...norView }
      ];
    
      this.plotlyData.push(
        ...views.map(view => ({
          name: view.name,
          line,
          mode: 'lines',
          type: 'scatter3d',
          ...view
        }))
      );
    }
  }

  private create3dLayout(): any {
    const plotBackgroundColor = this.isDarkMode ? '#1C1C1C' : '#fff';
    const fontColor = this.isDarkMode ? 'white' : 'black';
    return {
      autoexpand: 'true',
      autosize: 'true',
      margin: { t: 20, r: 10, l: 10, b: 10 },
      offset: 0,
      type: 'scatter3d',
      mode: 'lines',
      hovermode: 'closest',
      plot_bgcolor: plotBackgroundColor,
      paper_bgcolor: plotBackgroundColor,
      xaxis: this.createLayoutAxis(`Northing (${this.longLengths})`, plotBackgroundColor),
      yaxis: this.createLayoutAxis(`Easting (${this.longLengths})`, plotBackgroundColor),
      zaxis: this.createLayoutAxis(`TVD (${this.longLengths})`, plotBackgroundColor),
      legend: {
        font: {
          color: fontColor
        },
        orientation: 'h'
      },
      scene: {
        aspectmode: 'cube',
        camera: {
          projection: "orthographic", //( "perspective" | "orthographic" )
          center: {
            x: 0, y: 0, z: -0.25
          },
          eye: {
            x: 1.35, y: 1.35, z: 1.25
          },
          up: {
            x: 0, y: 0, z: .5
          }
        },
        zaxis: this.createSceneAxisObj(`TVD (${this.longLengths})`, true, fontColor),
        xaxis: this.createSceneAxisObj(`Northing (${this.longLengths})`, false, fontColor),
        yaxis: this.createSceneAxisObj(`Easting (${this.longLengths})`, false, fontColor)
      }
    };
  }

  private createLayoutAxis(title: string, lineColor: string): any {
    return {
      automargin: true,
      linecolor: lineColor,
      mirror: true,
      title: title
    }
  }

  private createSceneAxisObj(title: string, flipAxis: boolean, fontColor: string): any {
    const xyRange = Math.max(...[Math.abs(this.minRange), Math.abs(this.maxRange)]);
    return {
      range: flipAxis ? [this.maxRange, 0] : [-xyRange, xyRange],
      linewidth: 2,
      gridcolor: '#454545',
      linecolor: 'gray',
      title: title,
      tickfont: {
        color: fontColor
      },
      titlefont: {
        color: fontColor
      },
      hoverformat: '.2f'
    }
  }

  private createTurntableRotationNextFrame() {
    const updatedLayout = this.create3dLayout();
    this._rotationAngle += (Math.PI / 180);
    const newEye = this.rotate(updatedLayout.scene.camera.eye, this._rotationAngle);
    updatedLayout.scene.camera.eye = newEye;
    updatedLayout.showlegend = false;
    this.layout = updatedLayout;
  }

  // https://stackoverflow.com/questions/70060767/how-to-rotate-3d-plotly-in-r-update
  private rotate(currentEye, angle) {
    const rtz = this.xyz2rtz(currentEye);
    rtz.t += angle;
    return this.rtz2xyz(rtz);
  }

  private xyz2rtz(xyz) {
    return {
      r: Math.sqrt(xyz.x * xyz.x + xyz.y * xyz.y),
      t: Math.atan2(xyz.y, xyz.x),
      z: xyz.z
    };
  }

  private rtz2xyz(rtz) {
    return {
      x: rtz.r * Math.cos(rtz.t),
      y: rtz.r * Math.sin(rtz.t),
      z: rtz.z
    };
  }

  ngOnDestroy(): void {
    clearInterval(this._intervalID);
  }

}
