import { UntypedFormControl } from "@angular/forms";

export function driftLargerThanId(control: UntypedFormControl) {
    const innerDiameter = control.value;
    const outerDiameter = control.parent && control.parent.controls['insideDiameter'].value;
    if(outerDiameter <= innerDiameter){
       return {error: "Drift must be smaller than Inside Diameter."};
    } else if (innerDiameter <= 0) {
      return {error: "Value must be greater than 0"};
    }
    return null;
  }
