import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { ExternalPressureProfileType, FluidToShoeEPP, GasCap, GeneralEPP } from '../../models/external-pressure-profile.model';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { unitsLib } from 'src/app/core/services/unit-library';

interface FluidToShoeEPPForm {
  type: ExternalPressureProfileType.FLUIDTOSHOE;
  generalEpp: GeneralEPP;
  gasCap: GasCap;
}

@Component({
    selector: 'app-fluid-to-shoe-epp',
    template: `
  <form [formGroup]="form.formGroup">
    <div class="flex flex-col space-y-2">
      <app-general-epp
        [formControlName]="form.formControlNames.generalEpp"
        [currentString]="currentString"
        [userUnits]="userUnits"
      ></app-general-epp>
      
      <app-gas-cap
        [formControlName]="form.formControlNames.gasCap"
        [userUnits]="userUnits"
        [currentString]="currentString"
      ></app-gas-cap>

      <p class="text-sm">
        Mud Weight: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;  {{ currentString?.annularFluid.state.nominalDensity.toFixed(2) }} {{ densityLabel }}
      </p>

    </div>
  </form>
  `,
    providers: subformComponentProviders(FluidToShoeEppComponent),
    standalone: false
})
export class FluidToShoeEppComponent implements OnInit {

  @Input() currentString: any;
  @Input() userUnits: UserUnitsModel;

  public densityLabel: string;
  public gasGradientDefault: number;
  public gasGradientValidation: { min: number, max: number };
  
  public form = createForm<FluidToShoeEPP, FluidToShoeEPPForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(ExternalPressureProfileType.FLUIDTOSHOE),
      generalEpp: new UntypedFormControl(null, Validators.required),
      gasCap: new UntypedFormControl(null, Validators.required)
    },
    toFormGroup: (obj: FluidToShoeEPP): FluidToShoeEPPForm => {
      return {
        type: ExternalPressureProfileType.FLUIDTOSHOE,
        generalEpp: { fluidDensity: obj.fluidDensity, hangerPressure: obj.hangerPressure },
        gasCap: { gasCapLength: obj.gasCapLength, gasGradient: obj.gasGradient, hasGasCap: obj.hasGasCap }
      };
    },
    fromFormGroup: (formValue: FluidToShoeEPPForm): FluidToShoeEPP => {
      return {
        type: ExternalPressureProfileType.FLUIDTOSHOE,
        hangerPressure: formValue.generalEpp?.hangerPressure,
        fluidDensity: formValue.generalEpp?.fluidDensity,
        gasCapLength: formValue.gasCap.gasCapLength,
        gasGradient: formValue.gasCap.gasGradient,
        hasGasCap: formValue.gasCap.hasGasCap
      };
    }
  });

  ngOnInit(): void {
    this.densityLabel = unitsLib[this.userUnits.density].symbol;
  }
}
