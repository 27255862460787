import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, of, shareReplay } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Fluid } from '../models/fluid.model';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`;

@Injectable({
  providedIn: 'root'
})
export class FluidsService {

  constructor(private http: HttpClient) { }
  private fluidsCache$: Observable<Fluid[]> = null;
  private phaseEnvelopeCache: Record<string, Observable<string>> = {};
  private fluidCache: Record<string, Observable<Fluid>> = {};

  getFluids(): Observable<Fluid[]> {
    if (!this.fluidsCache$) {
      this.fluidsCache$ = this.http.get<Fluid[]>(`${PeriforApiEndpoint}/fluids`)
        .pipe(
          catchError(() => {
            return of([]);
          }),
          shareReplay(1)
        );
    }
    setTimeout(() => this.fluidsCache$ = null, 1000);
    return this.fluidsCache$;
  }

  public getFluidById(fluidId: string): Observable<Fluid> {
    if (!this.fluidCache[fluidId]) {
      this.fluidCache[fluidId] = this.http
        .get<Fluid>(`${PeriforApiEndpoint}/fluids/${fluidId}`)
        .pipe(
          catchError(() => of(null as Fluid)),
          shareReplay(1)
        );
    }
    setTimeout(() => this.fluidCache[fluidId] = null, 1000);
    return this.fluidCache[fluidId];
  }

  createFluid(fluid: Fluid): Observable<string> {
    return this.http.post<string>(`${PeriforApiEndpoint}/fluids`, fluid.state);
  }

  updateFluid(fluid: Fluid): Observable<boolean> {
    return this.http.put<boolean>(`${PeriforApiEndpoint}/fluids/${fluid.id}`, fluid.state);
  }

  deleteFluid(fluidId: string): Observable<boolean> {
    return this.http.delete<boolean>(`${PeriforApiEndpoint}/fluids/${fluidId}`)
  }

  cloneFluid(fluidId: string, fluidName: string): Observable<Partial<Fluid>> {
    return this.http.post<Partial<Fluid>>(`${PeriforApiEndpoint}/fluids/${fluidId}/clone`, {name: fluidName});
  }

  calculateGerg(fluid: Fluid){
    return this.http.post<string>(`${PeriforApiEndpoint}/fluids/${fluid.id}/gerg-calc`, fluid.state);
  }

  public getPhaseEnvelope(fluidId: string): Observable<string> {
    if (!this.phaseEnvelopeCache[fluidId]) {
      this.phaseEnvelopeCache[fluidId] = this.http
        .get<string>(`${PeriforApiEndpoint}/fluids/${fluidId}/phase-envelope`)
        .pipe(
          catchError(() => {
            return of('');
          }),
          shareReplay(1)
        );
    }
    setTimeout(() => this.phaseEnvelopeCache[fluidId] = null, 1000);
    return this.phaseEnvelopeCache[fluidId];
  }
}
