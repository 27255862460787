import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, Validators, UntypedFormBuilder, FormGroup, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs/internal/Subscription';
import { debounceTime } from 'rxjs/operators';
import { PeriforOnChangeMessages, SignalRService } from 'src/app/shared/services/signal-r.service';
import { DesignFactorsService } from '../../services/design-factors.service';
import { WellConfigService } from 'src/app/shared/services/well-config.service';

@Component({
    selector: 'app-design-factors',
    templateUrl: './design-factors.component.html',
    styles: [],
    standalone: false
})
export class DesignFactorsComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription;

  public designFactorsForm: FormGroup;
  public isLoading: boolean;
  public configMissing: boolean;
  public disabledMessage: string;

  public get connection(): FormGroup {
    return this.designFactorsForm.get("connection") as FormGroup;
  }

  @Input()
  private componentId: string;

  constructor(
    private _designFactorsService: DesignFactorsService,
    private _formBuilder: UntypedFormBuilder,
    private _signalRService: SignalRService,
    private _wellConfigService: WellConfigService
  ) {
    this.isLoading = true;
    this._subscriptions = new Subscription();
  }

  async ngOnInit() : Promise<void> {
    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));

    this.createDesignFactorsForm();

    this.getDesignFactors();
  }

  signalRfunc(data: { action: string, designId: string }) {
    if (data.action === PeriforOnChangeMessages.REFRESH_STRING_INPUTS || data.action === PeriforOnChangeMessages.REFRESH_TUBULARS_LIST) {
      this.getDesignFactors();
    }
  }

  private getDesignFactors() {

    this._wellConfigService.getStringsList().subscribe({
      next: (strings) => {
        if (strings.length > 0) {
          this._designFactorsService.getDesignFactors().subscribe(df => {
            this.designFactorsForm.patchValue(df, { emitEvent: false });
            this.isLoading = false;
          });
        } else {
          this.configMissing = true;
          this.disabledMessage = " Well Configuration Missing: Please specify at least one string";
        }
      }
    });

  }

  private createDesignFactorsForm(): void {
    this.designFactorsForm = this._formBuilder.group({
      apiCollapse: new UntypedFormControl(1, [Validators.required, Validators.min(0.1), Validators.max(10)]),
      barlowBurst: new UntypedFormControl(1.1, [Validators.required, Validators.min(0.1), Validators.max(10)]),
      pureCompression: new UntypedFormControl(1.15, [Validators.required, Validators.min(0.1), Validators.max(10)]),
      pureTension: new UntypedFormControl(1.3, [Validators.required, Validators.min(0.1), Validators.max(10)]),
      vonMisesBurst: new UntypedFormControl(1.1, [Validators.required, Validators.min(0.1), Validators.max(10)]),
      vonMisesCollapse: new UntypedFormControl(1, [Validators.required, Validators.min(0.1), Validators.max(10)]),
      connection: new UntypedFormGroup({
        burstLeak: new UntypedFormControl(1.1, [Validators.required, Validators.min(0.1), Validators.max(10)]),
        collapse: new UntypedFormControl(1, [Validators.required, Validators.min(0.1), Validators.max(10)]),
        compression: new UntypedFormControl(1.15, [Validators.required, Validators.min(0.1), Validators.max(10)]),
        tension: new UntypedFormControl(1.3, [Validators.required, Validators.min(0.1), Validators.max(10)])
      })
    });

    this._subscriptions.add(this.designFactorsForm.valueChanges.pipe(debounceTime(300)).subscribe((designFactors) => {
      this.setDefaultValues(designFactors);
      if (!this.isLoading && this.designFactorsForm.valid) {
          this._designFactorsService.updateDesignFactors(designFactors).subscribe();
      }
    }));
  }

  private setDefaultValues(df: any) {
    df.barlowBurst = df.barlowBurst || 1.1;
    df.apiCollapse = df.apiCollapse || 1;
    df.pureTension = df.pureTension || 1.3;
    df.pureCompression = df.pureCompression || 1.15;
    df.vonMisesBurst = df.vonMisesBurst || 1.1;
    df.vonMisesCollapse = df.vonMisesCollapse || 1;

    const con = df.connection;
    con.burstLeak = con.burstLeak || 1.1;
    con.collapse = con.collapse || 1;
    con.compression = con.compression || 1.15;
    con.tension = con.tension || 1.3;
    this.designFactorsForm.patchValue(df, {emitEvent: false});
  }

  ngOnDestroy() {
    this._subscriptions?.unsubscribe();
    this.signalRfunc = null;
  }
}
