<form [formGroup]="form.formGroup">
  <p-fieldset legend="Tuning Parameters for Peng-Robinson EOS">
    <div class="flex space-x-2">
      <div class="flex flex-col">
        <label for="acor">ACOR</label>
        <p-inputNumber id="acor" [formControlName]="form.formControlNames.acor" required></p-inputNumber>
      </div>
      <div class="flex flex-col">
        <label for="bcor">BCOR</label>
        <p-inputNumber id="bcor" [formControlName]="form.formControlNames.bcor" required></p-inputNumber>
      </div>
      <div class="flex flex-col">
        <label for="vcor">VCOR</label>
        <p-inputNumber id="vcor" [formControlName]="form.formControlNames.vcor" required></p-inputNumber>
      </div>
      <div class="flex flex-col">
        <label for="dcor">DCOR</label>
        <p-inputNumber id="dcor" [formControlName]="form.formControlNames.dcor" required></p-inputNumber>
      </div>
      <div class="flex flex-col">
        <label for="scor">SCOR</label>
        <p-inputNumber id="scor" [formControlName]="form.formControlNames.scor" required></p-inputNumber>
      </div>
    </div>
  </p-fieldset>

  <p class="mt-2 mb-2 text-xs">Correlation option to initialize binary Interaction K-Values:</p>

  <div class="flex flex-col">
    <label for="kiOption">K<span class="text-xs align-baseline">i</span>&nbsp;Option</label>
    <p-inputNumber id="kiOption" [formControlName]="form.formControlNames.kiOption" required>
    </p-inputNumber>
  </div>

  <p class="mt-2 mb-2 text-xs">Correlation options for C<span class="text-xs align-baseline">7</span> + component critical properties, acentric
    and shift factors and binary interaction parameters:
  </p>

  <div class="flex flex-col">
    <label for="c7PlusCorrelations">C<span class="text-xs align-baseline">7</span> + Correlations</label>
    <p-inputNumber id="c7PlusCorrelations" [formControlName]="form.formControlNames.c7PlusCorrelations"
      required>
    </p-inputNumber>
  </div>

</form>