import { FormationCementCatalog } from "src/app/catalogs/models/formations-cement-catalog.model";

export class CementingLanding {
  public constructor(init?: Partial<CementingLanding>) {
    Object.assign(this, init);
  }

  leadSlurryDensity: number;
  tailSlurryDensity: number;
  tailSlurryLength: number;
  displacementFluidDensity: number;
  hasTailCement: boolean;
  designId?: string;
  tubularId?: string;
  domainEvents?: any[];
  initialConditionsTemperature: any;
  pickupAfterCementSet: number;
  slackoffForceOnBottom: number;
  cement: FormationCementCatalog;
}
