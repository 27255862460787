import { Component, Input, Output, EventEmitter, AfterViewInit, OnDestroy, OnChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { catchError, forkJoin, map, Observable } from 'rxjs';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Flow } from 'src/app/perical/models/flow.model';
import { Fluid, FluidType } from 'src/app/perical/models/fluid.model';
import { DrillingOperation, MudPit, OperationType } from 'src/app/perical/models/thermal-operation.model';
import { GetShortLengthValueFromInches } from 'src/app/perivis/shared/helpers/units.helper';
import { PeriforOnChangeMessages, SignalRService } from 'src/app/shared/services/signal-r.service';
import { UndisturbedTemperature } from 'src/app/wellbore-inputs/models/undisturbed-temperature.model';
import { DrillstringService } from 'src/app/wellbore-inputs/services/drillstring.service';
import { WellTypeService } from 'src/app/wellbore-inputs/services/well-type-datums.service';

export interface DrillingOperationForm {
  type: OperationType;
  fluid: Fluid;
  standardFluidFlow: Flow;
  outsideDiameter: number;
  insideDiameter: number;
  startDepth: number;
  endDepth: number;
  inletTemperature: number;
  totalFlowArea: number;
  annulusIndex: number;
  isPoolOutOfHole: boolean;
  poolOutOfHoleTime: number;
  isConditionHole: boolean;
  conditionHoleTime: number;
  isRunInHole: boolean;
  runInHoleTime: number;
  useMudPits: boolean;
  mudPits: any;
}

@Component({
  selector: 'app-drilling-operation-details',
  templateUrl: './drilling-operation-details.component.html', 
  styles: [``],
  providers: subformComponentProviders(DrillingOperationDetailsComponent),
  standalone: false
})
export class DrillingOperationDetailsComponent implements OnChanges, OnDestroy, AfterViewInit {

  constructor(
    private _wellTypeService: WellTypeService,
    private _drillstringService: DrillstringService,
    private _signalRService: SignalRService
  ) { }

  public fluids: Fluid[];
  @Input('fluids') set _fluids(fluids: Fluid[]) {
    this.fluids = fluids?.filter(f => f.state.type === FluidType.BRINE ||
      f.state.type === FluidType.STANDARDMUD ||
      f.state.type === FluidType.ADVANCEDMUD)
      .sort((a, b) => a.state.name.localeCompare(b.state.name));
  }

  public annularFluidId: string;
  @Input('annularFluidId') set _annularFluidId(annularFluidId: string) {
    this.annularFluidId = annularFluidId ?? null;
  }

  @Input()
  private wellConfig: any;

  @Input()
  public isReadOnly: boolean;

  @Input()
  private udtProfile: UndisturbedTemperature;

  @Input()
  public currentStringId: string;

  @Input()
  public userUnits: UserUnitsModel;

  @Output()
  drillingRangeChanged = new EventEmitter<{ startDepth: number, endDepth: number }>();

  @Input()
  currentTubularIndex: number;

  @Input()
  public previousOperationMudPits: MudPit;

  public operationType = 'drilling';
  public FluidType: typeof FluidType = FluidType;
  public isLoading: boolean;
  public startDrillingMd: number;
  public endDrillingMd: number;
  public selectedDrillPipe: string;
  public drillPipe: any;
  public hwdp: any;
  public collar: any;
  public drillPipeLength: number;
  public selectedHwdp: string;
  public selectedCollar: string;
  public hasHwdp: boolean;
  public hasCollar: boolean;
  public drillpipeMissing: boolean;
  public showRiser: boolean;
  public conditionHoleDisabled: boolean;
  public poohDisabled: boolean;
  public rihDisabled: boolean;
  public totalFlowAreaValidation: { min: number, max: number };
  public temperatureValidation: { min: number, max: number };
  public tempUnit: string;
  public shortLengthUnit: string;
  public weigthUnit: string;
  public areaUnit: string;
  public useMudPits = false;

  public form = createForm<DrillingOperation, DrillingOperationForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(OperationType.DRILLING),
      fluid: new UntypedFormControl(null, [Validators.required]),
      startDepth: new UntypedFormControl(null, [Validators.required]),
      endDepth: new UntypedFormControl(null, [Validators.required]),
      outsideDiameter: new UntypedFormControl(null, Validators.required),
      insideDiameter: new UntypedFormControl(null, Validators.required),
      standardFluidFlow: new UntypedFormControl(null, [Validators.required]),
      inletTemperature: new UntypedFormControl(null),
      totalFlowArea: new UntypedFormControl(null, Validators.required),
      isPoolOutOfHole: new UntypedFormControl(false),
      poolOutOfHoleTime: new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(100000)]),
      isConditionHole: new UntypedFormControl(false),
      conditionHoleTime: new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(100000)]),
      isRunInHole: new UntypedFormControl(false),
      runInHoleTime: new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(100000)]),      
      useMudPits: new UntypedFormControl(false),
      mudPits: new UntypedFormControl(null),
      annulusIndex: new UntypedFormControl(null)
    },
    toFormGroup: (obj: DrillingOperation | null): DrillingOperationForm | null => {
      if (!obj) {
        return null;
      }

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { fluid, flow, riser, startDepth, endDepth, inletTemperature, totalFlowArea, useMudPits, mudPits,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
        isPoolOutOfHole, poolOutOfHoleTime, isConditionHole, conditionHoleTime, isRunInHole, runInHoleTime, ...commonValues } = obj;

      obj.fluid = this.fluids?.find(f => f.id === obj.fluid.id);
      this.useMudPits = obj.useMudPits;

      this.rihChange(isRunInHole, false, runInHoleTime > 0);
      this.circOnBottomChange(isConditionHole, false, conditionHoleTime > 0);
      this.poohChange(isPoolOutOfHole, false, poolOutOfHoleTime > 0);

      return {
        type: obj.type,
        standardFluidFlow: obj.flow,
        outsideDiameter: riser.outsideDiameter,
        insideDiameter: riser.insideDiameter,
        fluid: obj.fluid,
        startDepth,
        endDepth,
        inletTemperature,
        totalFlowArea,
        isPoolOutOfHole,
        poolOutOfHoleTime,
        isConditionHole,
        conditionHoleTime,
        isRunInHole,
        runInHoleTime,
        useMudPits: useMudPits,
        mudPits: useMudPits ? mudPits : null,
        annulusIndex: obj.annulusIndex
      };
    },
    fromFormGroup: (formValue: DrillingOperationForm): DrillingOperation | null => {
      const { fluid, standardFluidFlow, outsideDiameter, insideDiameter, startDepth, endDepth,
        inletTemperature, totalFlowArea, isPoolOutOfHole, poolOutOfHoleTime, isConditionHole,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        isRunInHole, runInHoleTime, conditionHoleTime, useMudPits, mudPits, annulusIndex, ...commonValues } = formValue;

      return {
        type: OperationType.DRILLING,
        flow: standardFluidFlow,
        riser: {
          outsideDiameter,
          insideDiameter
        },
        fluid,
        startDepth,
        endDepth,
        inletTemperature,
        totalFlowArea,
        isPoolOutOfHole,
        poolOutOfHoleTime,
        isConditionHole,
        conditionHoleTime,
        isRunInHole,
        runInHoleTime,
        useMudPits,
        mudPits,
        annulusIndex
      };
    }
  });

  async ngOnChanges(): Promise<void> {
    this.isLoading = true;

    this.tempUnit = this.userUnits.temperature;
    this.shortLengthUnit = this.userUnits.shortLengths == 'in' ? '"' : this.userUnits.shortLengths;
    this.weigthUnit = this.userUnits.linearDensity == 'lb/ft' ? '#' : this.userUnits.linearDensity;
    this.areaUnit = this.userUnits.area;

    switch (this.tempUnit) {
      case '°F':
        this.temperatureValidation = { min: 0, max: 500 };
        break;
      case '°C':
        this.temperatureValidation = { min: -17.77, max: 260 };
        break;
      case 'K':
        this.temperatureValidation = { min: 255.37, max: 533.15 };
        break;
    }
    this.form.formGroup.controls.inletTemperature.setValidators([Validators.min(this.temperatureValidation.min), Validators.max(this.temperatureValidation.max), Validators.required]);

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));

    if (this.areaUnit == 'in²') {
      this.totalFlowAreaValidation = { min: 0, max: 100 };
    } else {
      this.totalFlowAreaValidation = { min: 0, max: 254 };
    }
    this.form.formGroup.controls.totalFlowArea.setValidators([Validators.min(this.totalFlowAreaValidation.min), Validators.max(this.totalFlowAreaValidation.max), Validators.required]);

    this.getDrillstringData(false);
  }

  signalRfunc(data: any) {
    if ((data.tubularStringId && data.tubularStringId !== this.currentStringId)) {
      return;
    }
    if (data.action === PeriforOnChangeMessages.REFRESH_DRILL_STRING || data.action == PeriforOnChangeMessages.REFRESH_STRING_INPUTS) {
      this.getDrillstringData(true);
    }
  }

  ngAfterViewInit(): void {
    const defaultFluid = this.fluids ? this.fluids.find(x => x.id === this.annularFluidId) : null;
    if (!this.form.formGroup.controls.fluid.value) {
      this.form.formGroup.controls.fluid.setValue(defaultFluid);
    }
  }

  private getDrillstringData(calledFromSignalR: boolean) {
    const sources: Observable<any>[] = [
      this._drillstringService.getDrillstringForTubular() as Observable<any>,
      this._wellTypeService.getWellType() as Observable<any>
    ];

    forkJoin(sources).pipe(
      map(([drillstring, wellType]) => {
        this.drillPipe = drillstring['drillPipe'];
        this.hwdp = drillstring['heavyWeightDrillPipe'];
        this.collar = drillstring['collar'];
        this.drillpipeMissing = !this.drillPipe && (drillstring.hwdpLength ?? 0) == 0 && (drillstring.collarLength ?? 0) == 0;

        const mudlineDepth = +(wellType.drillFloorElevation + wellType.waterDepth).toFixed(5);
        this.startDrillingMd = this.currentTubularIndex == 0 ? wellType.type == 'Land' ? wellType.drillFloorElevation : mudlineDepth : this.wellConfig[this.currentTubularIndex - 1].shoeMd;
        this.endDrillingMd = this.wellConfig[this.currentTubularIndex].shoeMd;

        this.drillPipeLength = +((this.form.formGroup.controls.endDepth.value ?? this.endDrillingMd) - drillstring.collarLength - drillstring.hwdpLength).toFixed(2);
        this.selectedDrillPipe = this.drillPipe ?
          `Drill Pipe: OD: ${this.drillPipe.outsideDiameter}${this.shortLengthUnit}, Weight: ${this.drillPipe.weightPerFoot}${this.weigthUnit}, ID: ${this.drillPipe.insideDiameter}${this.shortLengthUnit}, Length: ${this.drillPipeLength}${this.userUnits.longLengths}` :
          null;

        this.selectedHwdp = this.hwdp ? `HWDP: OD: ${this.hwdp.outsideDiameter}${this.shortLengthUnit}, Weight: ${this.hwdp.weightPerFoot}${this.weigthUnit}, ID: ${this.hwdp.insideDiameter}${this.shortLengthUnit}, Length: ${drillstring.hwdpLength}${this.userUnits.longLengths}` : null;
        this.selectedCollar = this.collar ? `Collar: OD: ${this.collar.outsideDiameter}${this.shortLengthUnit}, Weight: ${this.collar.weightPerFoot}${this.weigthUnit}, ID: ${this.collar.insideDiameter}${this.shortLengthUnit}, Length: ${drillstring.collarLength}${this.userUnits.longLengths}` : null;
        this.hasHwdp = drillstring.hwdpLength > 0;
        this.hasCollar = drillstring.collarLength > 0;

        if (!this.hasCollar && !this.hasHwdp && !this.drillPipe) {
          this.selectedDrillPipe = 'Please specify a drill string';
        }

        if (calledFromSignalR) {
          return;
        }

        this.showRiser = wellType.type == 'Subsea' && this.currentTubularIndex > 0 ? true : null;

        this.form.formGroup.controls.startDepth
          .setValue((this.form.formGroup.value.startDepth ?? this.startDrillingMd), { emitEvent: false });

        this.form.formGroup.controls.endDepth
          .setValue((this.form.formGroup.value.endDepth ?? this.endDrillingMd), { emitEvent: false });

        this.form.formGroup.controls.inletTemperature
          .setValue((this.form.formGroup.value.inletTemperature ?? this.udtProfile[0].temperature), { emitEvent: false });

        this.form.formGroup.controls.totalFlowArea
          .setValue((this.form.formGroup.value.totalFlowArea ? this.form.formGroup.value.totalFlowArea : this.areaUnit == 'in²' ? 1 : 6.45), { emitEvent: false });

        this.form.formGroup.controls.outsideDiameter
          .setValue((this.form.formGroup.value.outsideDiameter ?? GetShortLengthValueFromInches(21, this.userUnits.shortLengths)), { emitEvent: false });

        this.form.formGroup.controls.insideDiameter
          .setValue((this.form.formGroup.value.insideDiameter ?? GetShortLengthValueFromInches(19.75, this.userUnits.shortLengths)), { emitEvent: false });

        this.form.formGroup.controls.startDepth.setValidators([Validators.min(this.startDrillingMd), Validators.max(this.endDrillingMd)]);
        this.form.formGroup.controls.endDepth.setValidators([Validators.min(this.startDrillingMd + 10), Validators.max(this.endDrillingMd)]);

        this.drillingRangeChanged.emit({ startDepth: this.form.formGroup.value.startDepth, endDepth: this.form.formGroup.value.endDepth });

        this.rihChange(this.form.formGroup.controls.isRunInHole, false, this.form.formGroup.controls.runInHoleTime.value > 0);
        this.circOnBottomChange(this.form.formGroup.controls.isConditionHole, false, this.form.formGroup.controls.conditionHoleTime.value > 0);
        this.poohChange(this.form.formGroup.controls.isPoolOutOfHole, false, this.form.formGroup.controls.poolOutOfHoleTime.value > 0);

        this.isLoading = false;
      }),
      catchError(err => {
        this.isLoading = false;
        return err;
      })).subscribe();
  }

  onDepthRangeChange(e, depthLocation) {
    const depth = e['srcElement']['valueAsNumber'];
    const startDepth = depthLocation == 'startDepth' && !isNaN(depth) ? depth : this.form.formGroup.controls.startDepth.value;
    const endDepth = depthLocation == 'endDepth' && !isNaN(depth) ? depth : this.form.formGroup.controls.endDepth.value;

    this.drillingRangeChanged.emit({ startDepth: startDepth, endDepth: endDepth });
  }

  public endDepthChanged(e) {
    this.onDepthRangeChange(e, 'endDepth');
    this.drillPipeLength = e['srcElement']['valueAsNumber'];
    this.selectedDrillPipe = this.drillPipe ?
      `Drill Pipe: OD: ${this.drillPipe.outsideDiameter}${this.shortLengthUnit}, Weight: ${this.drillPipe.weightPerFoot}${this.weigthUnit}, ID: ${this.drillPipe.insideDiameter}${this.shortLengthUnit}, Length: ${this.drillPipeLength} ${this.userUnits.longLengths}` :
      null;
  }

  public rihChange(e, checkboxChange, greaterThanZero?) {
    if (checkboxChange || (!checkboxChange && (e?.checked ?? e?.value ?? false)) || greaterThanZero) {
      this.rihDisabled = false;
    } else {
      this.rihDisabled = true;
    }

    if (checkboxChange && !e?.checked) {
      this.form.formGroup.controls.runInHoleTime.setValue(0);
    } else if (checkboxChange && e?.checked) {
      this.form.formGroup.controls.runInHoleTime.setValue(10);
    }
  }

  public circOnBottomChange(e, checkboxChange, greaterThanZero?) {
    if (checkboxChange || (!checkboxChange && (e?.checked ?? e?.value ?? false)) || greaterThanZero) {
      this.conditionHoleDisabled = false;
    } else {
      this.conditionHoleDisabled = true;
    }

    if (checkboxChange && !e?.checked) {
      this.form.formGroup.controls.conditionHoleTime.setValue(0);
    } else if (checkboxChange && e?.checked) {
      this.form.formGroup.controls.conditionHoleTime.setValue(10);
    }
  }

  public poohChange(e, checkboxChange, greaterThanZero?) {
    if (checkboxChange || (!checkboxChange && (e?.checked ?? e?.value ?? false)) || greaterThanZero) {
      this.poohDisabled = false;
    } else {
      this.poohDisabled = true;
    }

    if (checkboxChange && !e?.checked) {
      this.form.formGroup.controls.poolOutOfHoleTime.setValue(0);
    } else if (checkboxChange && e?.checked) {
      this.form.formGroup.controls.poolOutOfHoleTime.setValue(10);
    }
  }

  ngOnDestroy(): void {
    this.signalRfunc = null;
  }

}
