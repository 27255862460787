import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { TemperatureDerationCatalog } from '../../models/temperature-deration-catalog.model';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api/temperatureDerationCatalog/`;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class TemperatureDerationService {
  private temperatureDerationsCache$: Observable<TemperatureDerationCatalog[]> | null = null;
  private temperatureDerationsByIdCache$: Record<string, Observable<TemperatureDerationCatalog>> = {};

  constructor(private http: HttpClient) { }

  createTemperatureDerations(tempDeration: TemperatureDerationCatalog): Observable<string> {
    return this.http.post<string>(`${PeriforApiEndpoint}`, tempDeration);
  }

  public getTemperatureDerations(): Observable<TemperatureDerationCatalog[]> {
    if (!this.temperatureDerationsCache$) {
      this.temperatureDerationsCache$ = this.http
        .get<TemperatureDerationCatalog[]>(`${PeriforApiEndpoint}`)
        .pipe(
          map(data => data.map(item => new TemperatureDerationCatalog(item))),
          catchError(() => of([])),
          shareReplay(1)
        );
    }
    setTimeout(() => this.temperatureDerationsCache$ = null, 1000);
    return this.temperatureDerationsCache$;
  }

  public getTemperatureDerationsById(profileId: string): Observable<TemperatureDerationCatalog> {
    if (!this.temperatureDerationsByIdCache$[profileId]) {
      this.temperatureDerationsByIdCache$[profileId] = this.http
        .get<TemperatureDerationCatalog>(`${PeriforApiEndpoint}${profileId}`)
        .pipe(
          catchError(() => of(null as TemperatureDerationCatalog)),
          shareReplay(1)
        );
    }
    setTimeout(() => this.temperatureDerationsByIdCache$[profileId] = null, 1000);
    return this.temperatureDerationsByIdCache$[profileId];
  }

  updateTemperatureDeration(model: TemperatureDerationCatalog): Observable<boolean> {
    return this.http.put<boolean>(`${PeriforApiEndpoint}${model.id}`, model, httpOptions);
  }

  deleteTempereatureDeration(id: string): Observable<boolean> {
    return this.http.delete<boolean>(`${PeriforApiEndpoint}${id}`);
  }
}
