<form [formGroup]="form.formGroup" class="flex flex-col space-y-2">

  <app-uom-input
      label="Fluid Density"
      [unitLabel]="densityLabel"
      [formControlName]="form.formControlNames.fluidDensity"
      [control]="'fluidDensity'"
      [default]="currentString.annularFluid?.state.nominalDensity"
      title="Allowed range: {{ densityValidation.min }} - {{ densityValidation.max }} {{ densityLabel }}"
      (focusout)="updateFluidDensityGenFluidProfile()"
      [validators]="validators.fluidDensity"
    />

    <p-select
      id="pressureLocation"
      optionValue="value"
      optionLabel="label"
      class="w-full"
      tooltip="Pressure Locations"
      [options]="topPressureLocations"
      placeholder="Select Pressure Location"
      [formControlName]="form.formControlNames.pressureLocation"
      (onChange)="updateFluidProfileLabel($event)"
    ></p-select>

    <app-uom-input
      label="{{ fluidProfilePressureLocation }}"
      [unitLabel]="pressureLabel"
      [formControlName]="form.formControlNames.topPressure"
      [control]="'topPressure'"
      [default]="hydrostaticHangerPressure"
      title="Allowed range: {{ pressureValidation.min }} - {{ pressureValidation.max }} {{ pressureLabel }}"
      [validators]="validators.topPressure"
    />

</form>
