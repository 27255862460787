import { Component, Input, Output } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import { FormType, NGX_SUB_FORM_HANDLE_VALUE_CHANGES_RATE_STRATEGIES, createForm, subformComponentProviders } from "ngx-sub-form";
import { Subject } from "rxjs";
import { User, UserProfile } from "./user-model";

@Component({
    selector: 'app-user-profile-form',
    styles: [``],
    template: `
      <form [formGroup]="form.formGroup">
        <div class="flex flex-col space-y-2">

          <div class="flex flex-col">
            <label>Email</label>
            <input class="w-full" type="email" pInputText [formControlName]="form.formControlNames.email" (blur)="emailOnBlur()" />
            <p class="validation-error" *ngIf="!form.formGroup.controls.email.value && form.formGroup.controls.email.errors?.['required']">Email is required.</p>
            <p class="validation-error" *ngIf="form.formGroup.controls.email.value && form.formGroup.controls.email.invalid">Email is invalid.</p>
          </div>

          <div class="flex flex-col">
            <label>First Name</label>
            <input class="w-full" type="text" pInputText [formControlName]="form.formControlNames.firstName" />
            <p class="validation-error" *ngIf="form.formGroup.controls.firstName.errors?.['required']">First Name is required.</p>
          </div>

          <div class="flex flex-col">
            <label>Last Name</label>
            <input class="w-full" type="text" pInputText [formControlName]="form.formControlNames.lastName" />
            <p class="validation-error" *ngIf="form.formGroup.controls.lastName.errors?.['required']">Last Name is required.</p>
          </div>

          <div class="flex flex-col">
            <label>Organization</label>
            <p-select class="w-full" [autoDisplayFirst]="false" appendTo="body" [options]="organizations" [formControlName]="form.formControlNames.organization"></p-select>
            <p class="validation-error" *ngIf="form.formGroup.controls.organization.errors?.['required']">Organization is required.</p>
          </div>

          <div class="flex flex-col">
            <label>Job Title</label>
            <input class="w-full" type="text" pInputText [formControlName]="form.formControlNames.jobTitle" />
          </div>

          <p-fieldset legend="Global Admin Only" *ngIf="form.formGroup.controls.organization.value === 'Altus' && showAdminInputs">
            <div class="flex flex-col mb-2">
              <label>Database</label>
              <p-select class="w-full" appendTo="body" [options]="organizations" [formControlName]="form.formControlNames.database"></p-select>
              <p class="validation-error" *ngIf="form.formGroup.controls.database.errors?.['required']">Database is required.</p>
            </div>

            <div class="flex">
              <p-checkbox
                size="small"
                binary="true"
                [formControlName]="form.formControlNames.isGlobalAdmin"
              >
              </p-checkbox>
            <label class="ml-2"> Global Admin</label>
          </div>

          </p-fieldset>
        </div>
      </form>
      <br/>
    `,
    providers: subformComponentProviders(UserProfileFormComponent),
    standalone: false
})
export class UserProfileFormComponent {

  private input$ = new Subject<UserProfile | undefined>();
  private _showAdminInputs: boolean;

  public organizations: string[] = ["Altus", "Bsee", "Baker", "Harbour", "Bp", "Te", "Fervo", "Neptune", "Shell", "Oxy", "Cop", "Omv"];

  @Input() set userProfile(userProfile: UserProfile | undefined) {
    this.input$.next(userProfile);
  }

  @Input()
  user: User;

  @Input() set showAdminInputs(value: boolean) {
    this._showAdminInputs = value;
  }
  get showAdminInputs(): boolean {
    return this._showAdminInputs;
  }

  private disabled$ = new Subject<boolean>();
  @Input() set disabled(value: boolean | undefined) {
    this.disabled$.next(!!value);
  }

  @Output() userProfileUpdated = new Subject<UserProfile>();

  @Output() emailChanged = new Subject<string>();

  public emailOnBlur() {
    if (this.form.formGroup.controls.email.valid) {
      this.emailChanged.next(this.form.formGroup.controls.email.value)
    }
  }

  public form = createForm<UserProfile, UserProfile>(this, {
    formType: FormType.SUB,
    disabled$: this.disabled$,
    input$: this.input$,
    output$: this.userProfileUpdated,
    formControls: {
      email: new UntypedFormControl(null, [Validators.required, Validators.email]),
      firstName: new UntypedFormControl(null, Validators.required),
      lastName: new UntypedFormControl(null, Validators.required),
      organization: new UntypedFormControl(null, Validators.required),
      jobTitle: new UntypedFormControl(null),
      database: new UntypedFormControl(null, Validators.required),
      isGlobalAdmin: new UntypedFormControl(false)
    },
    handleEmissionRate: NGX_SUB_FORM_HANDLE_VALUE_CHANGES_RATE_STRATEGIES.debounce(1000)
  });

  populateOrganization() {
    if (this.user.profile.organization != "Altus" || !this.showAdminInputs) {
      this.form.formGroup.controls.organization.setValue(this.user.profile.organization);
      this.form.formGroup.controls.database.setValue(this.user.profile.database.slice(0, -2)); // Remove 'Db' from name
      this.form.formGroup.controls.organization.disable();
      this.form.formGroup.controls.database.disable();
      this.form.formGroup.controls.isGlobalAdmin.disable();
    }
  }
}
