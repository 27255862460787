import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { StressResultsService } from '../../services/stress-results.service';
import { PeriforOnChangeMessages, SignalRService } from '../../../shared/services/signal-r.service';
import { SelectItem } from 'primeng/api';
import { toCamelCaseString } from 'src/app/shared/helpers/stringToCamel.helper';
import { Subscription, lastValueFrom } from 'rxjs';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Store } from '@ngneat/elf';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { SingleLoadResultsUi } from '../../models/perivis-results-state.model';
import { isEqual } from "lodash-es";
import { unitsLib } from 'src/app/core/services/unit-library';
import { withUIEntities } from '@ngneat/elf-entities';
import { XyLinePlotUi } from 'src/app/core/models/xy-line-plot.model';

@Component({
    selector: 'app-single-load-burst',
    templateUrl: './single-load-burst.component.html',
    styles: [],
    standalone: false
})
export class SingleLoadBurstComponent implements OnInit, OnDestroy {
  private _burstResults: any[];
  private _subscriptions: Subscription;
  private _pressureUnit: string;

  public loadCases: SelectItem[] = [];
  public resultsDisplay: SelectItem[] = [
    { label: 'Plot', value: 'plot' },
    { label: 'Grid', value: 'grid' }
  ];
  public modes: SelectItem[] = [
    { label: 'Single', value: 'single' },
    { label: 'Multiple', value: 'multiple' }
  ];
  public errorMsg: string;
  public results: any[];
  public depthView: SelectItem[] = [
    { label: 'MD', value: 'md' },
    { label: 'TVD', value: 'tvd' }
  ];
  public isLoading: boolean;
  public yAxisTitle = '';
  public xAxisTitle = '';
  public tableName = 'Barlow Burst Results';
  public userUnits: UserUnitsModel;
  public plotName: string;
  public signalRChange: boolean;
  public columnDefs: { header: string, field: string }[];
  public columnDefinitions: any[];
  public longLengthUnit: string;
  
  //State Management
  private _componentId: string;
  @Input() set componentId(value: string) {
    this._componentId = value;
    this.burstResultsStore = this._storeService.createStore(this.componentId, new SingleLoadResultsUi, withUIEntities<XyLinePlotUi>());
  }
  get componentId(): string {
    return this._componentId;
  }
  public burstResultsStore: Store;

  constructor(
    private _stressResultsService: StressResultsService,
    private _signalRService: SignalRService,
    private _storeService: StoreService
  ) {
    this._subscriptions = new Subscription();
  }

  async ngOnInit(): Promise<void> {
    this.userUnits = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);
    this.longLengthUnit = this.userUnits.longLengths;
    this._pressureUnit = unitsLib[this.userUnits.pressure].symbol;
    this.xAxisTitle = `Burst Pressure (${this._pressureUnit})`;
    this.yAxisTitle = `MD (${this.longLengthUnit})`;

    this.refreshResults(true, false, false);

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));

    this.columnDefs = [
      { header: 'Burst Load', field: 'burstLoad' },
      { header: 'Barlow Burst Rating', field: 'barlowBurstRating' },
    ];
    this.columnDefinitions = [
      {
        header: `MD (${this.longLengthUnit})`, field: 'measuredDepth',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      },
      {
        header: `TVD (${this.longLengthUnit})`, field: 'trueVerticalDepth',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      },
      {
        header: `Burst Load (${this._pressureUnit})`, field: 'burstLoad',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      },
      {
        header: `Burst Rating (${this._pressureUnit})`, field: 'barlowBurstRating',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      }
    ];
  }

  signalRfunc(data: any) {
    if (data.action == PeriforOnChangeMessages.REFRESH_RESULTS) {
      this.refreshResults(true, true, false);
    }
  }

  public async refreshResults(isLoading: boolean, calledFromSignalR: boolean, calledFromMode: boolean): Promise<void> {
    this.isLoading = isLoading;

    this.xAxisTitle = `Burst Pressure (${this.userUnits?.pressure})`;

    if ((!this._burstResults || calledFromSignalR) && !calledFromMode) {
      try {
        this._burstResults = await lastValueFrom(this._stressResultsService.getBarlowBurstResults());
        this.signalRChange = true;
      } catch {
        this.errorMsg = "Well Configuration Missing: Please specify at least one string";
        this.isLoading = false;
        return;
      }
    }

    if (this._burstResults && (this._burstResults.length === 0 || this._burstResults[0].loadCaseName === '')) {
      this.errorMsg = "Please calculate Perivis to see results";
      this.columnDefs = [];
      this.results = [];
      this.isLoading = false;
      return;
    } else {
      this.errorMsg = null;
    }

    if (this.burstResultsStore.state.selectedMode == "single") {
      this.handleSingleBurstLoad(this._burstResults);
    } else {
      this.handleMultiBurstLoad(this._burstResults);
    }

    this.isLoading = false;
  }

  onModeChange(e): void {
    this.burstResultsStore.update(state => ({ ...state, selectedMode: e.value }));
    if (this.burstResultsStore.state.selectedMode == "multiple") {
      this.burstResultsStore.update(state => ({ ...state, selectedLoadCase: { label: 'Burst Load', value: 'burstLoad' } }));
    } else {
      // this.xAxisTitle = `Burst Pressure (psi)`;
    }

    this.plotName = 'barlowBurstPlot - ' + this.burstResultsStore.state.selectedMode + ' ' + this.burstResultsStore.state.selectedLoadCase.label;
    this.refreshResults(true, false, true);
  }

  onSelectedResultDisplayUpdated(e): void {
    this.burstResultsStore.update(state => ({ ...state, selectedResultDisplay: e.value }));
  }

  setLoadCase(e): void {
    if (this.burstResultsStore.state.selectedMode === "single") {
      this.burstResultsStore.update(state => ({ ...state, selectedLoadCase: e.value }));
      this.plotName = 'barlowBurstPlot - ' + this.burstResultsStore.state.selectedMode + ' ' + this.burstResultsStore.state.selectedLoadCase.label;
      this.results = e.value.value.burstResults;
    } else {
      this.burstResultsStore.update(state => ({ ...state, selectedLoadCase: e.value }));
      this.plotName = 'barlowBurstPlot - ' + this.burstResultsStore.state.selectedMode + ' ' + this.burstResultsStore.state.selectedLoadCase.label;
      this.refreshResults(true, false, false);
    }
  }

  public depthViewToggle(e) {
    this.burstResultsStore.update(state => ({ ...state, selectedDepthView: e.value }));
  }

  private formatDecimal(value: any, divisor: number): string {
    return value || value === 0 ? (Math.trunc(value * divisor) / divisor).toLocaleString('en-US') : null;
  }

  private handleSingleBurstLoad(burstResults: any[]) {
    this.columnDefs = [
      { header: 'Burst Load', field: 'burstLoad' },
      { header: 'Barlow Burst Rating', field: 'barlowBurstRating' },
    ];
    this.columnDefinitions = [
      {
        header: `MD (${this.longLengthUnit})`, field: 'measuredDepth',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      },
      {
        header: `TVD (${this.longLengthUnit})`, field: 'trueVerticalDepth',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      },
      {
        header: `Burst Load (${this.userUnits?.pressure})`, field: 'burstLoad',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      },
      {
        header: `Burst Rating (${this.userUnits?.pressure})`, field: 'barlowBurstRating',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      }
    ];

    this.results = [];

    this.loadCases = burstResults?.map(lc => {
      return { label: lc.loadCaseName, value: lc };
    }) ?? [];
    if (!this.burstResultsStore.state.selectedLoadCase || !this.loadCases.find(l => isEqual(l, this.burstResultsStore.state.selectedLoadCase))) {
      this.burstResultsStore.update(state => ({ ...state, selectedLoadCase: this.loadCases[0] }));
    }
    this.plotName = 'barlowBurstPlot - ' + this.burstResultsStore.state.selectedMode + ' ' + this.burstResultsStore.state.selectedLoadCase.label;
    this.results = this.burstResultsStore.state.selectedLoadCase?.value.burstResults || [];
  }

  private handleMultiBurstLoad(burstResults: any[]) {
    this.columnDefinitions.splice(2, this.columnDefinitions.length);
    this.columnDefs = [];
    this.results = [];

    this.loadCases = [
      { label: 'Burst Load', value: 'burstLoad' },
      { label: 'Barlow Burst Rating', value: 'barlowBurstRating' }
    ]

    this.xAxisTitle = this.burstResultsStore.state.selectedLoadCase.value === 'burstLoad' ? `Burst Load (${this._pressureUnit})` : `Barlow Burst Rating (${this._pressureUnit})`;

    burstResults.forEach(load => {
      const loadName: string = load.loadCaseName + ` (${this._pressureUnit})`;
      const loadNameCamel = toCamelCaseString(load.loadCaseName);

      this.columnDefs.push({ header: load.loadCaseName, field: loadNameCamel });

      this.columnDefinitions.push({
        header: loadName,
        field: loadNameCamel,
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      });

      const backupArray = [];

      load.burstResults.forEach(loadResult => {
        const result = {
          [loadNameCamel]: loadResult[this.burstResultsStore.state.selectedLoadCase.value],
          measuredDepth: loadResult.measuredDepth,
          trueVerticalDepth: loadResult.trueVerticalDepth
        }

        if (burstResults[0].loadCaseName === load.loadCaseName) {
          this.results.push(result);
        } else {
          backupArray.push(result);
        }
      });

      this.results = this.results.map(x => {
        const otherLoad = backupArray.find(e => e.measuredDepth === x.measuredDepth)
        return { ...x, ...otherLoad }
      });
    });
    this.plotName = 'barlowBurstPlot - ' + this.burstResultsStore.state.selectedMode + ' ' + this.burstResultsStore.state.selectedLoadCase.label;
  }

  ngOnDestroy() {
    this._subscriptions?.unsubscribe();
    this.signalRfunc = null;
  }
}
