import { definePreset } from '@primeng/themes';

/**
 * Adds standard overrides that will apply across themes.
 */
export function addPresetsToTheme(theme: any) {
  return definePreset(theme, {
    semantic: {
      formField: {
        paddingX: '0.1rem',
        paddingY: '0.1rem',
        border:{
          radius: '0px'
        }
      }
    },
    // Use Semantic whenever possible
    components: {
      inputtext:{
        borderRadius: 0,
        paddingX: '.5rem'
      },
      button:{
        sm: {
          paddingX: '0.2rem',
          paddingY: '0.2rem'
        }
      },
      togglebutton: {
        padding: '0.25rem'
        
      },
      toggleswitch: {
        height: '1.1rem',
        width: '2rem',
        handleSize: '1rem',
      },
      datatable: {
        header: {
          cell: {padding: '.1rem .1rem !important' }
        },
        body: {
          cell: {
            padding: '.05rem',
          }
        }
      },
      panel: {
        header: {
          padding: '.25rem'
        },
        content:{
          padding: '.1rem'
        }
      },
      toolbar: {
        gap: '0.1rem',
        padding: '0.1rem',
        border: {
          radius: '0px'
        }
      },
      select: {
        dropdown: {
          width: '1.5rem'
        },
        padding:
        {
          x: '0.1rem',
          y: '0.1rem'
        }
      },
      multiselect: {
        padding:
        {
          x: '0.1rem',
          y: '0.1rem'
        }
      },
      tabs: {
        tab: {
          padding: '0.25rem',
            fontWeight: 450,
            fontSize: 'sm'
        }
      },
      // fieldset: {
      //   padding: '0.2rem',
      //   legend: {
      //     fontSize: 'xs',
      //     fontWeight: 200
      //   }
      // }
      
    }
  });
}
