import { Component, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';

@Component({
    selector: 'app-new-post-install-load-dialog',
    templateUrl: './new-post-install-load-dialog.component.html',
    styles: [`
    label {
      user-select: none;
    }
  `],
    standalone: false
})
export class NewPostInstallLoadDialogComponent {

  public newLoadForm: UntypedFormGroup;

  @Output() saveLoad$ = new Subject<string>();
  @Output() cancelNewLoad$ = new Subject<boolean>();

  constructor(private _formBuilder: UntypedFormBuilder) {
      this.newLoadForm = this._formBuilder.group({
      name: ''
    });
  }

  closeDialog(): void {
    this.cancelNewLoad$.next(true);
  }

  saveLoadCase(): void {
    this.saveLoad$.next(this.newLoadForm.value.name.trim());
  }
}
