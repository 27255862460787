<form [formGroup]="form.formGroup">

  <p-toolbar>
    <ng-template #start>
    </ng-template>
    <ng-template #end>
      <img class="w-[25px] h-[25px] mb-2" (click)="applyDefaults()" title="Apply Defaults"
        src="../../assets/icons/default-icon-white.png" />
    </ng-template>
  </p-toolbar>

  <div class="mt-2">

    <p-fieldset legend="Volumetric Composition">
      <div class="flex flex-col space-y-2">

        <div class="flex flex-col">
          <label for="baseBrine">Base Water</label>
          <p-inputgroup>
            <p-select [disabled]="isReadOnly" [options]="brines" [formControlName]="form.formControlNames.baseBrine"
              optionLabel="state.name" appendTo="body">
            </p-select>
            <p-inputNumber id="waterFraction" [formControlName]="form.formControlNames.waterFraction" required
              [minFractionDigits]="1" (onInput)="validatePercentage('waterFraction', $event)"></p-inputNumber>
            <p-inputgroup-addon>%</p-inputgroup-addon>
          </p-inputgroup>
          <p *ngIf="
          form.formGroup.controls.waterFraction.invalid &&
          form.formGroup.controls.waterFraction.value
        " class="validation-error"> Allowed range: 0 - 100 % </p>
        </div>

        <div class="flex flex-col">
          <label for="baseOil">Base Oil</label>
          <p-inputgroup>
            <p-select [disabled]="isReadOnly" [options]="baseOilTypes" [formControlName]="form.formControlNames.baseOil"
              optionLabel="name" optionValue="value" appendTo="body"></p-select>
            <p-inputNumber placeholder="0" id="oilFraction" [formControlName]="form.formControlNames.oilFraction"
              required [minFractionDigits]="1" (onInput)="validatePercentage('oilFraction', $event)"></p-inputNumber>
            <p-inputgroup-addon>%</p-inputgroup-addon>
          </p-inputgroup>
          <p *ngIf="
          form.formGroup.controls.oilFraction.invalid &&
          form.formGroup.controls.oilFraction.value
        " class="validation-error">
            Allowed range: 0 - 100 %
          </p>
        </div>

        <div class="flex flex-col">
          <label for="highDensitySolids">High Density Solids</label>
          <p-inputgroup>
            <p-select [disabled]="isReadOnly" [options]="highDensityMudSolidsTypes"
              [formControlName]="form.formControlNames.highDensityMudSolidsType" optionLabel="name" optionValue="value"
              appendTo="body"></p-select>
            <p-inputNumber placeholder="0" id="highDensitySolidsFraction" [formControlName]="
            form.formControlNames.highDensitySolidsFraction
          " required [minFractionDigits]="1" (onInput)="
            validatePercentage('highDensitySolidsFraction', $event)
          "></p-inputNumber>
            <p-inputgroup-addon>%</p-inputgroup-addon>
          </p-inputgroup>
          <p *ngIf="
          form.formGroup.controls.highDensitySolidsFraction.invalid &&
          form.formGroup.controls.highDensitySolidsFraction.value
        " class="validation-error">
            Allowed range: 0 - 100 %
          </p>
        </div>

        <div class="flex flex-col">
          <label for="lowDensitySolids">Low Density Solids</label>
          <p-inputgroup>
            <p-select [disabled]="isReadOnly" [options]="lowDensityMudSolidsTypes"
              [formControlName]="form.formControlNames.lowDensityMudSolidsType" optionLabel="name" optionValue="value"
              appendTo="body"></p-select>
            <p-inputNumber placeholder="0" id="lowDensitySolidsFraction"
              [formControlName]="form.formControlNames.lowDensitySolidsFraction" required [minFractionDigits]="1"
              (onInput)="validatePercentage('lowDensitySolidsFraction', $event)"></p-inputNumber>
            <p-inputgroup-addon>%</p-inputgroup-addon>
          </p-inputgroup>
          <p *ngIf="
          form.formGroup.controls.lowDensitySolidsFraction.invalid &&
          form.formGroup.controls.lowDensitySolidsFraction.value
        " class="validation-error">
            Allowed range: 0 - 100 %
          </p>
        </div>

        <div class="mr-2">
          <p>Total: {{ totalPercent }} %</p>
        </div>

        <p *ngIf="totalPercent !== 100" class="validation-error mb-2">
          Total percent must be equal to 100%
        </p>

        <div class="flex flex-col">
          <label for="densityTemperature">Density Temperature</label>
          <p-inputgroup>
            <p-inputNumber id="densityTemperature" [formControlName]="form.formControlNames.densityTemperature"
              [minFractionDigits]="1">
            </p-inputNumber>
            <p-inputgroup-addon>{{ tempUnit }}</p-inputgroup-addon>
          </p-inputgroup>
          <p class="text-sm" *ngIf="form.formGroup.controls.nominalDensity.value">
            Density: {{form.formGroup.controls.nominalDensity.value | number : "1.0-2" }} {{ densityUnit }}
          </p>
          <p *ngIf="form.formGroup.controls.densityTemperature.invalid" class="validation-error">
            Allowed range: {{ temperatureValidation.min }} - {{ temperatureValidation.max }} {{ tempUnit }}
          </p>
        </div>
      </div>

    </p-fieldset>

    <div class="flex align-center mr-2 mt-2 mb-2">
      <label class="mr-2">Show Base Oil Correlations</label>
      <p-toggleswitch (onChange)="showBaseOils()"></p-toggleswitch>
    </div>

    <p-fieldset *ngIf="showBaseOil" legend="Base Oil Density Correlation">
      <div class="flex gap-2">
        <div class="flex flex-col w-1/2 gap-2">
          <div class="flex flex-col">
            <label for="a0">a0 x 10<span class="text-sm align-super">4</span></label>
            <input disabled="true" pInputText value="{{ a0 }}" required />
          </div>
          <div class="flex flex-col">
            <label for="a1">a1 x 10<span class="text-sm align-super">8</span></label>
            <input disabled="true" pInputText value="{{ a1 }}" required />
          </div>
          <div class="flex flex-col">
            <label for="a2">a2 x 10<span class="text-sm align-super">13</span></label>
            <input disabled="true" pInputText value="{{ a2 }}" required />
          </div>
        </div>
        <div class="flex flex-col w-1/2 gap-2">
          <div class="flex flex-col mt-2">
            <label for="b0">b0 x 10</label>
            <input disabled="true" pInputText value="{{ b0 }}" required />
          </div>
          <div class="flex flex-col">
            <label for="b1">b1 x 10<span class="text-sm align-super">6</span></label>
            <input disabled="true" pInputText value="{{ b1 }}" required />
          </div>
          <div class="flex flex-col">
            <label for="b2">b2 x 10<span class="text-sm align-super">12</span></label>
            <input disabled="true" pInputText value="{{ b2 }}" required />
          </div>
        </div>
      </div>
    </p-fieldset>

    <div class="mt-2 mb-2">

      <p-fieldset legend="Rheology">

        <div class="flex flex-col">
          <label for="plasticViscosity">Plastic Viscosity</label>
          <p-inputgroup>
            <p-inputNumber id="plasticViscosity" [formControlName]="form.formControlNames.plasticViscosity"
              [minFractionDigits]="1"></p-inputNumber>
            <p-inputgroup-addon>cP</p-inputgroup-addon>
          </p-inputgroup>
          <p *ngIf="
            form.formGroup.controls.plasticViscosity.invalid &&
            form.formGroup.controls.plasticViscosity.value
          " class="validation-error">
            Allowed range: 0 - 2000 cP
          </p>
        </div>

        <div class="flex flex-col">
          <label for="yieldPoint">Yield Point</label>
          <p-inputgroup>
            <p-inputNumber id="yieldPoint" [formControlName]="form.formControlNames.yieldPoint"
              [minFractionDigits]="1"></p-inputNumber>
            <p-inputgroup-addon>lbf/100ft²</p-inputgroup-addon>
          </p-inputgroup>
          <p *ngIf="
            form.formGroup.controls.yieldPoint.invalid &&
            form.formGroup.controls.yieldPoint.value
          " class="validation-error">
            Allowed range: 0 - 2000 lbf/100ft²
          </p>
        </div>

        <div class="flex flex-col">
          <label for="rheologyTemperature">Temperature</label>
          <p-inputgroup>
            <p-inputNumber id="rheologyTemperature" [formControlName]="form.formControlNames.rheologyTemperature"
              [minFractionDigits]="1"></p-inputNumber>
            <p-inputgroup-addon>{{ tempUnit }}</p-inputgroup-addon>
          </p-inputgroup>
          <p *ngIf="
            form.formGroup.controls.rheologyTemperature.invalid &&
            form.formGroup.controls.rheologyTemperature.value
          " class="validation-error">
            {{ temperatureValidation.min }} -
            {{ temperatureValidation.max }} {{ tempUnit }}
          </p>
        </div>

      </p-fieldset>

    </div>
  </div>

</form>