import { Injectable } from '@angular/core';
import { catchError, Observable, of, shareReplay } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { WellSchematicConsolidated } from '../models/well-schematic.model';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`

@Injectable({
  providedIn: 'root'
})
export class WellSchematicService {

  constructor(private http: HttpClient) { }
  private wellSchematicCache$: Record<string, Observable<WellSchematicConsolidated> | null> = {};

  public getWellSchematic(designId: string): Observable<WellSchematicConsolidated> {
    if (!this.wellSchematicCache$[designId]) {
      this.wellSchematicCache$[designId] = this.http
        .get<WellSchematicConsolidated>(`${PeriforApiEndpoint}/WellSchematic?designId=${designId}`)
        .pipe(catchError(() => of(null)), shareReplay(1));

      }
    setTimeout(() => this.wellSchematicCache$[designId] = null, 1000);
    return this.wellSchematicCache$[designId]!;
  }
}
