import { Component, Input, OnInit } from '@angular/core';
import {  UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { unitsLib } from 'src/app/core/services/unit-library';
import { GreenCementTest, PreInstallLoadType } from 'src/app/perivis/models/load-case.model';

export interface GreenCementTestForm {
  type: PreInstallLoadType;
  PumpPressure: number;
}

@Component({
    selector: 'app-green-cement-test-details',
    template: `
    <form [formGroup]="form.formGroup">
      <div class="flex flex-col">
          <label>Pump Pressure</label>
          <p-inputgroup>
            <p-inputnumber [formControlName]="form.formControlNames.PumpPressure" [minFractionDigits]="1"/>
            <p-inputgroup-addon>{{ pressureUnit }}</p-inputgroup-addon>
          </p-inputgroup>
          <p class="validation-error" 
             *ngIf="form.formGroup.controls.PumpPressure.value && form.formGroup.controls.PumpPressure.invalid" >
             Allowed range: {{ pressureValidation.min }} - {{ pressureValidation.max }} {{ pressureUnit }}
          </p>
        </div>
    </form>
  `,
    providers: subformComponentProviders(GreenCementTestDetailsComponent),
    standalone: false
})
export class GreenCementTestDetailsComponent implements OnInit {

  @Input() userUnits: UserUnitsModel;

  public pressureUnit: string;
  public pressureValidation: { min: number, max: number };

  ngOnInit(): void {
    this.pressureUnit = unitsLib[this.userUnits.pressure].symbol;
    
    switch (this.userUnits.pressure) {
      case 'psi':
        this.pressureValidation = { min: 0, max: 30000 };
        break;
      case 'bar':
        this.pressureValidation = { min: 0, max: 2068 };
        break;
      case 'KPa':
        this.pressureValidation = { min: 0, max: 206800 };
        break;
      case 'atm':
        this.pressureValidation = { min: 0, max: 2041 };
        break;
    }
    this.form.formGroup.controls.PumpPressure.setValidators([Validators.min(this.pressureValidation.min), Validators.max(this.pressureValidation.max), Validators.required]);
  }

  public form = createForm<GreenCementTestForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(null),
      PumpPressure: new UntypedFormControl(null)
    },
    toFormGroup: (obj: GreenCementTest | null): GreenCementTestForm => {
      if (!obj) {
        return null;
      }

      return {
        type: obj.type,
        PumpPressure: obj.PumpPressure
      }
    },
    fromFormGroup: (formValue: GreenCementTestForm): GreenCementTest => {
      return {
        type: PreInstallLoadType.GREENCEMENTTEST,
        PumpPressure: formValue.PumpPressure
      }
    }
  })
}
