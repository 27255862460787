import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BarlowBurst } from '../models/barlow-burst.model';
import { Observable, of } from 'rxjs';
import { catchError, shareReplay } from 'rxjs/operators';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`;

@Injectable({
  providedIn: 'root'
})
export class StressResultsService {

  constructor(private http: HttpClient) { }
  private packerLoadsCache$: Observable<any> | null = null;
  private displacementResultsCache$: Observable<any[]> | null = null;
  private barlowBurstCache$: Observable<BarlowBurst[]> | null = null;
  private collapseResultsCache$: Observable<any[]> | null = null;
  private axialResultsCache$: Observable<any[]> | null = null;
  private safetyFactorResultsCache$: Observable<any[]> | null = null;
  private connectionSafetyFactorCache$: Observable<any[]> | null = null;
  private designLimitsPlotsCache$: Observable<any[]> | null = null;
  private liftoffResultsCache$: Observable<any[]> | null = null;

  public getDisplacementResults(): Observable<any[]> {
    if (!this.displacementResultsCache$) {
      this.displacementResultsCache$ = this.http
        .get<any[]>(`${PeriforApiEndpoint}/stressResults/displacements`)
        .pipe(catchError(() => of([])), shareReplay(1));
    }
    setTimeout(() => this.displacementResultsCache$ = null, 1000);
    return this.displacementResultsCache$;
  }

  public getBarlowBurstResults(): Observable<BarlowBurst[]> {
    if (!this.barlowBurstCache$) {
      this.barlowBurstCache$ = this.http
        .get<BarlowBurst[]>(`${PeriforApiEndpoint}/stressResults/burst`)
        .pipe(catchError(() => of([])), shareReplay(1));
    }
    setTimeout(() => this.barlowBurstCache$ = null, 1000);
    return this.barlowBurstCache$;
  }

  public getCollapseResults(): Observable<any[]> {
    if (!this.collapseResultsCache$) {
      this.collapseResultsCache$ = this.http
        .get<any[]>(`${PeriforApiEndpoint}/stressResults/collapse`)
        .pipe(catchError(() => of([])), shareReplay(1));
    }
    setTimeout(() => this.collapseResultsCache$ = null, 1000);
    return this.collapseResultsCache$;
  }

  public getAxialResults(): Observable<any[]> {
    if (!this.axialResultsCache$) {
      this.axialResultsCache$ = this.http
        .get<any[]>(`${PeriforApiEndpoint}/stressResults/axial`)
        .pipe(catchError(() => of([])), shareReplay(1));
    }
    setTimeout(() => this.axialResultsCache$ = null, 1000);
    return this.axialResultsCache$;
  }

  public getSafetyFactorResults(): Observable<any[]> {
    if (!this.safetyFactorResultsCache$) {
      this.safetyFactorResultsCache$ = this.http
        .get<any[]>(`${PeriforApiEndpoint}/stressResults/safetyFactors`)
        .pipe(catchError(() => of([])), shareReplay(1));
    }
    setTimeout(() => this.safetyFactorResultsCache$ = null, 1000);
    return this.safetyFactorResultsCache$;
  }

  public getConnectionSafetyFactorResults(): Observable<any[]> {
    if (!this.connectionSafetyFactorCache$) {
      this.connectionSafetyFactorCache$ = this.http
        .get<any[]>(`${PeriforApiEndpoint}/stressResults/connectionSafetyFactors`)
        .pipe(catchError(() => of([])), shareReplay(1));
    }
    setTimeout(() => this.connectionSafetyFactorCache$ = null, 1000);
    return this.connectionSafetyFactorCache$;
  }

  public getDesignLimitsPlots(): Observable<any[]> {
    if (!this.designLimitsPlotsCache$) {
      this.designLimitsPlotsCache$ = this.http
        .get<any[]>(`${PeriforApiEndpoint}/stressResults/designLimitsPlot`)
        .pipe(catchError(() => of([])), shareReplay(1));
    }
    setTimeout(() => this.designLimitsPlotsCache$ = null, 1000);
    return this.designLimitsPlotsCache$;
  }

  public getLiftoffResults(): Observable<any[]> {
    if (!this.liftoffResultsCache$) {
      this.liftoffResultsCache$ = this.http
        .get<any[]>(`${PeriforApiEndpoint}/stressResults/liftoff`)
        .pipe(catchError(() => of([])), shareReplay(1));
    }
    setTimeout(() => this.liftoffResultsCache$ = null, 1000);
    return this.liftoffResultsCache$;
  }

  public getPackerLoads(): Observable<any> {
    if (!this.packerLoadsCache$) {
      this.packerLoadsCache$ = this.http
        .get<any>(`${PeriforApiEndpoint}/stressResults/packerLoads`)
        .pipe(catchError(() => of(null)), shareReplay(1)
        );
    }
    setTimeout(() => this.packerLoadsCache$ = null, 1000);
    return this.packerLoadsCache$;
  }

  public getCasingWearResults(): Observable<any> {
    return this.http
      .get<any>(`${PeriforApiEndpoint}/stressResults/maxAllowableWear`)
      .pipe(catchError(() => of(null)), shareReplay(1));
  }
}
