import { Component, Input, Output } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { FormType, NGX_SUB_FORM_HANDLE_VALUE_CHANGES_RATE_STRATEGIES, createForm } from "ngx-sub-form";
import { Subject } from "rxjs";
import { EditUserPermissions } from "./user-model";

@Component({
    selector: 'app-edit-permissions-form',
    template: `
      <form [formGroup]="form.formGroup">
        <div class="flex flex-col space-y-2">

          <user-permissions-form [formControlName]="form.formControlNames.roles"></user-permissions-form>
       
          <p-button (onClick)="save()" label="Save"></p-button>
        </div>

      </form>
    `,
    standalone: false
})
export class EditPermissionsFormComponent {

  private input$ = new Subject<EditUserPermissions | undefined>();

  @Input() set userPermissions(userPermissions: EditUserPermissions | undefined) {
    this.input$.next(userPermissions);
  }

  private disabled$ = new Subject<boolean>();
  @Input() set disabled(value: boolean | undefined) {
    this.disabled$.next(!!value);
  }

  @Output() permissionsUpdated = new Subject<EditUserPermissions>();

  manualSave$ = new Subject<void>();

  public form = createForm<EditUserPermissions, EditUserPermissions>(this, {
    formType: FormType.ROOT,
    disabled$: this.disabled$,
    input$: this.input$,
    output$: this.permissionsUpdated,
    manualSave$: this.manualSave$,
    formControls: {
      id: new UntypedFormControl(),
      roles: new UntypedFormControl()
    },
    handleEmissionRate: NGX_SUB_FORM_HANDLE_VALUE_CHANGES_RATE_STRATEGIES.debounce(1000)
  });

  save() {
    this.manualSave$.next();
  }

}