import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PeriforOnChangeMessages, SignalRService } from 'src/app/shared/services/signal-r.service';
import { StressResultsService } from '../../services/stress-results.service';
import { Observable, Subscription, catchError, forkJoin, map } from 'rxjs';
import { PackerService } from 'src/app/wellbore-inputs/services/packers.service';
import { SelectItem } from 'primeng/api';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { unitsLib } from 'src/app/core/services/unit-library';
import { Store } from '@ngneat/elf';
import { PackerResultsUi, Packers } from 'src/app/wellbore-inputs/models/packers.model';
import { withUIEntities } from '@ngneat/elf-entities';
import { XyLinePlotUi } from 'src/app/core/models/xy-line-plot.model';

@Component({
    selector: 'app-packer-table-results',
    templateUrl: './packer-table-results.component.html',
    styles: [],
    standalone: false
})
export class PackerTableResultsComponent implements OnInit, OnDestroy {

  private _subscriptions: Subscription;
  private _packerCount: number;
  private _forceUnit: string;
  private _longLengthUnit: string;
  private _pressureUnit: string;

  public isDisplacementSelected = false;
  public resultTypes: any[] = [];
  public columnDefs :{ header: string, field: string }[];
  public resultsDisplay: SelectItem[];
  public displacementTypes: { label: string, value: string }[];
  public resultData: any;
  public results: any;
  public needToCalculate: boolean;
  public isLoading: boolean;
  public packersMissing: boolean;
  public packers: any[] = [];
  public cols: any[];
  public xAxisTitle = '';
  public yAxisTitle = '';
  public plotName: string;
  public downloadPlotName = 'packer_results_plot';
  public displacementType: string;

  public plot = {
    data: [],
    layout: { },
    config: {}
  };

    // State Management
    private _componentId: string;
    @Input() set componentId(value: string) {
      this._componentId = value;
      this.packerResultsStore = this._storeService.createStore(this.componentId, new PackerResultsUi, withUIEntities<XyLinePlotUi>());
    }
    get componentId(): string {
      return this._componentId;
    }
    public packerResultsStore: Store;

  constructor(
    private _storeService : StoreService,
    private _signalRService: SignalRService,
    private _stressResultsService: StressResultsService,
    private _packerService: PackerService
  ) {
    this._subscriptions = new Subscription();
  }

  async ngOnInit(): Promise<void> {
    const uu = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);
    this._forceUnit = uu.force;
    this._longLengthUnit = uu.longLengths;
    this._pressureUnit = unitsLib[uu.pressure].symbol;

    this.cols = [
      {
        field: 'loadCaseName',
        header: 'Load',
        valueFormatter: (params: any) => params
      },
      {
        field: 'differentialPressure',
        header: `Differential Pressure (${this._pressureUnit})`,
        valueFormatter: (params: any) => this.formatDecimal(params)
      },
      {
        field: 'tubingToPackerForce',
        header: `Tubing to Packer Force (${this._forceUnit})`,
        valueFormatter: (params: any) => this.formatDecimal(params)
      },
      {
        field: 'latchShearLoad',
        header: `Latch Shear Load (${this._forceUnit})`,
        valueFormatter: (params: any) => this.formatDecimal(params)
      },
      {
        field: 'packerToCasingForce',
        header: `Packer to Casing Force (${this._forceUnit})`,
        valueFormatter: (params: any) => this.formatDecimal(params)
      },
      {
        field: 'total',
        header: `Total Displacement (${this._longLengthUnit})`,
        valueFormatter: (params: any) => this.formatDecimal(params)
      },
      {
        field: 'thermal',
        header: `Thermal Displacement (${this._longLengthUnit})`,
        valueFormatter: (params: any) => this.formatDecimal(params)
      },
      {
        field: 'poisson',
        header: `Poisson Displacement (${this._longLengthUnit})`,
        valueFormatter: (params: any) => this.formatDecimal(params)
      },
      {
        field: 'hooke',
        header: `Hooke Displacement (${this._longLengthUnit})`,
        valueFormatter: (params: any) => this.formatDecimal(params)
      },
      {
        field: 'buckling',
        header: `Buckling Displacement (${this._longLengthUnit})`,
        valueFormatter: (params: any) => this.formatDecimal(params)
      }
    ];

    this.displacementTypes = [
      { label: "Absolute Displacement", value: "absoluteDisplacements" },
      { label: "Relative Displacement", value: "relativeDisplacements" }
    ];

    this.resultsDisplay = [
      { label: 'Grid', value: 'grid' },
      { label: 'Plot', value: 'plot' }
    ];
    
    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));
    
    this.refreshResults();
  }

  signalRfunc(data: any) {
    if (data.action == PeriforOnChangeMessages.REFRESH_RESULTS || data.action == PeriforOnChangeMessages.REFRESH_PACKERS ||
      data.action == PeriforOnChangeMessages.REFRESH_STRING_INPUTS) {
      this.refreshResults();
    }
  }

  public refreshResults() {
    this.isLoading = true;
    this.results = [];
    this.plot.data = [];
    const sources = [
      this._stressResultsService.getPackerLoads() as Observable<any>,
      this._packerService.getPackersForTubular() as Observable<Packers>
    ];

    forkJoin(sources).pipe(
      map(([results, packers]) => {
        this.resultData = results;
        this._packerCount = packers.packers.length;

        this.packers = this.resultData?.map(x => x.packerName ?? 'Packer') ?? [];
        this.packerResultsStore.update(state => ({...state, selectedPacker: this.packers && !this.packers.find(p => p === this.packerResultsStore.state.selectedPacker) || !this.packerResultsStore.state.selectedPacker ? this.packers[0] : null}));
        this.plotName = 'packerResultsPlot - ' + this.packerResultsStore.state.selectedPacker + ' ' + this.packerResultsStore.state.selectedResultView + ' ' + this.packerResultsStore.state.displacementType;
        this.handleErrors();
        this.displacementType = this.displacementTypes[0].value;
        this.results = this.resultData[0].packerLoadResults;
        this.handleDisplacementResults();
        this.showResults();
      }),
      catchError(err => {
        this.isLoading = false;
        return err;
      })).subscribe();
  }

  public toggleGridPlot(e): void {
    this.packerResultsStore.update(state => ({...state, selectedResultDisplay: e.value}));
    this.plotName = 'packerResultsPlot - ' + this.packerResultsStore.state.selectedPacker + ' ' + this.packerResultsStore.state.selectedResultView + ' ' + this.packerResultsStore.state.displacementType;
    this.showResults();
  }

  public displacementTypeChange(event: any) {
    this.packerResultsStore.update(state => ({...state, displacementType: event.value}));
    this.plotName = 'packerResultsPlot - ' + this.packerResultsStore.state.selectedPacker + ' ' + this.packerResultsStore.state.selectedResultView + ' ' + this.packerResultsStore.state.displacementType;
    this.showResults();
  }

  public selectPacker(packer: any) {
    this.packerResultsStore.update(state => ({...state, selectedPacker: packer.originalEvent?.srcElement.innerText ?? packer}));
    let index = this.packers?.findIndex(x => x == this.packerResultsStore.state.selectedPacker);
    if (index == -1) {
      index = 0;
    }

    this.plotName = 'packerResultsPlot - ' + this.packerResultsStore.state.selectedPacker + ' ' + this.packerResultsStore.state.selectedResultView + ' ' + this.packerResultsStore.state.displacementType;

    this.handleErrors();

    this.results = this.resultData[index].packerLoadResults;

    this.showResults();

    this.isLoading = false;
  }

  private showResults(): void {
    if(this.packerResultsStore.state.selectedResultDisplay === 'grid') {
      this.handleDisplacementResults();
    } else {
      this.handleMultipleLoadResults();
    }
  }

  private handleDisplacementResults(): void {
    this.results.forEach(element => {
      element['total'] = element[this.packerResultsStore.state.displacementType].total;
      element['thermal'] = element[this.packerResultsStore.state.displacementType].thermal;
      element['poisson'] = element[this.packerResultsStore.state.displacementType].poisson;
      element['hooke'] = element[this.packerResultsStore.state.displacementType].hooke;
      element['buckling'] = element[this.packerResultsStore.state.displacementType].buckling;
    });
  }

  private handleErrors() {
    if (this._packerCount == 0) {
      this.results = [];
      this.packers = [];
      this.packersMissing = true;
      this.needToCalculate = false;
      this.isLoading = false;
      return;
    } else if ((this.resultData?.length ?? 0) < 1 && this._packerCount > 0) {
      this.results = [];
      this.packersMissing = false;
      this.needToCalculate = true;
      this.isLoading = false;
      return;
    } else {
      this.packersMissing = false;
      this.needToCalculate = false;
      this.isLoading = false;
    }
  }

  public toggleResultsView(e): void {
    this.packerResultsStore.update(state => ({...state, selectedResultView: e.value}));
    this.isDisplacementSelected = this.resultTypes.find(x => x.value == this.packerResultsStore.state.selectedResultView)?.isDisplacement ?? false;
    this.plotName = 'packerResultsPlot - ' + this.packerResultsStore.state.selectedPacker + ' ' + this.packerResultsStore.state.selectedResultView + ' ' + this.packerResultsStore.state.displacementType;
    this.handleMultipleLoadResults();
  }

  private handleMultipleLoadResults() {
    this.columnDefs = [];

    this.resultTypes = [
      { label: `Differential Pressure (${this._pressureUnit})`, value: "differentialPressure", isDisplacement: false },
      { label: `Tubing to Packer Force (${this._forceUnit})`, value: "tubingToPackerForce", isDisplacement: false },
      { label: `Latch Shear Load (${this._forceUnit})`, value: "latchShearLoad", isDisplacement: false },
      { label: `Packer to Casing Force (${this._forceUnit})`, value: "packerToCasingForce", isDisplacement: false },

      { label: `Total Displacement (${this._longLengthUnit})`, value: "total", isDisplacement: true },
      { label: `Thermal Displacement (${this._longLengthUnit})`, value: "thermal", isDisplacement: true },
      { label: `Poisson Displacement (${this._longLengthUnit})`, value: "poisson", isDisplacement: true },
      { label: `Hooke Displacement (${this._longLengthUnit})`, value: "hooke", isDisplacement: true },
      { label: `Buckling Displacement (${this._longLengthUnit})`, value: "buckling", isDisplacement: true }
    ];

    const traceArray = {
      x: [],
      y: [],
      type: "bar",
      width: 0.55,
    };

    this.results.forEach(load => {
      traceArray.y.push(this.isDisplacementSelected ? load[this.packerResultsStore.state.displacementType][this.packerResultsStore.state.selectedResultView] : load[this.packerResultsStore.state.selectedResultView]);
      traceArray.x.push(load.loadCaseName);
    });

    this.yAxisTitle = this.resultTypes.find(x => x.value === this.packerResultsStore.state.selectedResultView).label;

    this.plot.data = [traceArray];
    this.isLoading = false;
  }

  formatDecimal(value: any) {
    return value || value === 0
      ? (Math.trunc(value * 100) / 100).toLocaleString('en-US')
      : null;
  }

  ngOnDestroy() {
    this._subscriptions?.unsubscribe();
    this.signalRfunc = null;
  }
}
