import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, Observable, of, shareReplay } from 'rxjs';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class DesignFactorsService {

  constructor(private http: HttpClient) { }
  private designFactorsCache$: Observable<any> | null = null;

  public getDesignFactors(): Observable<any> {
    if (!this.designFactorsCache$) {
      this.designFactorsCache$ = this.http
        .get(`${PeriforApiEndpoint}/designFactors`)
        .pipe(catchError(() => of(null)), shareReplay(1));
    }
    setTimeout(() => this.designFactorsCache$ = null, 1000);
    return this.designFactorsCache$;
  }

  updateDesignFactors(designFactors: any) {
    this.designFactorsCache$ = null;
    return this.http.put(`${PeriforApiEndpoint}/designFactors`, designFactors, httpOptions);
  }
}
