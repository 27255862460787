<form [formGroup]="form.formGroup">

  <p-tabs value="0">
    <p-tablist>
      <p-tab value="0">Operation Details</p-tab>
      <p-tab *ngIf="showNodal" value="1">Nodal
        <i
        class="validation-error pi pi-exclamation-circle pl-1"
        *ngIf="form.formGroup.controls.nodalAnalysis.invalid"
        pTooltip="Operation not saved, double check Nodal inputs."
      ></i> 
      </p-tab>
      <p-tab value="2">Options
        <i
        class="validation-error pi pi-exclamation-circle pl-1"
        *ngIf="form.formGroup.controls.customEnthalpyOptions.invalid"
        pTooltip="Operation not saved, double check Options."
      ></i> 
      </p-tab>
    </p-tablist>

    <!-- Operation Details Tab -->
    <p-tabpanels>
      <p-tabpanel value="0">
        <div class="flex flex-wrap gap-4">
          <!-- Left Column -->
          <div class="flex flex-col flex-1 min-w-[200px] space-y-2">
            <div class="flex flex-col">
              <label for="fluid">Fluid</label>
              <p-select [options]="fluids" [formControlName]="form.formControlNames.fluid" optionLabel="state.name"
                dataKey="id" appendTo="body" tooltipPosition="top">
                <ng-template pTemplate="selectedItem">
                  {{ form.formGroup.value.fluid?.state.name }}
                </ng-template>
                <ng-template let-fluid pTemplate="item">
                  {{ fluid.state.name }}
                </ng-template>
              </p-select>
            </div>

            <div class="flex flex-col">
              <label for="perforation">Perforation</label>
              <p-select [options]="perforations" [formControlName]="form.formControlNames.perforation"
                optionLabel="name" dataKey="id" appendTo="body">
              </p-select>
            </div>

            <div class="flex flex-col">
              <label for="pressure">Pressure Location</label>
              <p-inputgroup>
                <p-select [options]="pressureLocations" [formControlName]="form.formControlNames.pressureLocation"
                  optionLabel="name" optionValue="value" appendTo="body">
                </p-select>
                <p-inputnumber [minFractionDigits]="1" id="pressure" [formControlName]="form.formControlNames.wellheadPressure"></p-inputnumber>
                <p-inputgroup-addon>{{ pressureUnit }}</p-inputgroup-addon>
              </p-inputgroup>
              <p *ngIf="form.formGroup.controls.wellheadPressure.value && form.formGroup.controls.wellheadPressure.invalid"
                class="validation-error">
                Allowed range: {{ pressureValidation.min }} - {{ pressureValidation.max }} {{ pressureUnit }}
              </p>
            </div>

            <div class="flex flex-col">
              <label for="inletTemperature">Inlet Temperature</label>
              <p-inputgroup>
                <p-inputnumber [minFractionDigits]="1" [formControlName]="form.formControlNames.inletTemperature" />
                <p-inputgroup-addon>{{ tempUnit }}</p-inputgroup-addon>
              </p-inputgroup>
              <p *ngIf="form.formGroup.controls.inletTemperature.value && form.formGroup.controls.inletTemperature.invalid"
                class="validation-error">
                Allowed range: {{ temperatureValidation.min }} - {{ temperatureValidation.max }} {{ tempUnit }}
              </p>
            </div>
          </div>

          <!-- Right Column -->
          <div class="flex flex-col flex-1 min-w-[150px] space-y-2">
            <div [ngSwitch]="true">
              <app-standard-fluid
                *ngSwitchCase="form.formGroup.value.fluid?.state.type === FluidType.BRINE || form.formGroup.value.fluid?.state.type === FluidType.STANDARDMUD || form.formGroup.value.fluid?.state.type === FluidType.ADVANCEDMUD"
                [formControlName]="form.formControlNames.standardFluidFlow" [userUnits]="user.units">
              </app-standard-fluid>
              <app-hydrocarbon-fluid
                *ngSwitchCase="form.formGroup.value.fluid?.state.type === FluidType.VLE || form.formGroup.value.fluid?.state.type === FluidType.BLACKOIL || form.formGroup.value.fluid?.state.type === FluidType.HYDROCARBON || form.formGroup.value.fluid?.state.type === FluidType.CO2FLUID"
                [formControlName]="form.formControlNames.hydrocarbonFlow" [selectedFluid]="form.formGroup.value.fluid"
                [userUnits]="user.units" [isReadOnly]="isReadOnly">
              </app-hydrocarbon-fluid>
            </div>
          </div>
        </div>
      </p-tabpanel>

      <!-- Nodal Tab -->
      <p-tabpanel value="1">

        <p-fieldset class="checkboxFieldset">
          <ng-template pTemplate="header">
            <div class="flex align-items-center">
              <input
                type="checkbox"
                binary="true"
                [checked]="includeNodalAnalysis"
                (change)="includeNodalAnalysisChange($event)" 
              >
              <label class="pl-2">Nodal Analysis</label>
            </div>
          </ng-template>

          <app-nodal-analysis [disableNodal]="!includeNodalAnalysis"
            [formControlName]="form.formControlNames.nodalAnalysis" [reservoirs]="reservoirs" [chokes]="chokes"
            [userUnits]="userUnits" [pressureValidation]="pressureValidation"
            [temperatureValidation]="temperatureValidation" [pressureLabel]="'Pipeline Pressure'"
            [temperatureLabel]="'Pipeline Temperature'" [defaultPressure]="form.formGroup.value.wellheadPressure"
            [defaultTemperature]="form.formGroup.value.inletTemperature">
          </app-nodal-analysis>
        </p-fieldset>
      </p-tabpanel>

      <!-- Options Tab -->
      <p-tabpanel value="2">

        <div class="grid">
          <div class="col">

            <p-fieldset>
              <ng-template pTemplate="header">
                <div class="flex items-center space-x-2">
                  <input type="radio" binary="true"
                    (click)="enthalpyOptionsChange('internalDefaults')"
                    [checked]="form.formGroup.controls.enthalpyOptionsSource.value === 'internalDefaults'">
                  <label>Internal Defaults</label>
                </div>
              </ng-template>

              <label style="padding-left: 10px;">Successive Relaxation Factor: 0.3</label>
              <br>
              <label style="padding-left: 10px;">Latent Heat Damping Factor: 100.0</label>
              <br>
              <label style="padding-left: 10px;">Latent Heat of Phase Change: OFF</label>

            </p-fieldset>

            <br>

            <p-fieldset>

              <ng-template pTemplate="header">
                <div class="flex items-center space-x-2">
                  <input type="radio" binary="true"
                    (click)="enthalpyOptionsChange('newDefaults')"
                    [checked]="form.formGroup.controls.enthalpyOptionsSource.value === 'newDefaults'">
                  <label>New Defaults (Perical Options)</label>

                </div>
              </ng-template>

              <label style="padding-left: 10px;">Successive Relaxation Factor: {{
                pericalOptions.successiveRelationFactor }}</label>
              <br>
              <label style="padding-left: 10px;">Latent Heat Damping Factor: {{ pericalOptions.latentHeatDampingFactor
                }}</label>
              <br>
              <label style="padding-left: 10px;">Latent Heat of Phase Change: {{
                pericalOptions.includeLatentHeatPhaseChange == true ? 'ON' : 'OFF' }}</label>

            </p-fieldset>

            <br>

            <p-fieldset>

              <ng-template pTemplate="header">
                <div class="flex items-center space-x-2">
                  <input type="radio" binary="true"
                    (click)="enthalpyOptionsChange('custom')"
                    [checked]="form.formGroup.controls.enthalpyOptionsSource.value === 'custom'">
                  <label>Use Custom Energy & Enthalpy Options</label>
                </div>
              </ng-template>

              <app-enthalpy-options [formControlName]="form.formControlNames.customEnthalpyOptions"
                [disableCustomOptions]="form.formGroup.controls.enthalpyOptionsSource.value !== 'custom'"></app-enthalpy-options>
            </p-fieldset>

          </div>
        </div>

      </p-tabpanel>

    </p-tabpanels>
  </p-tabs>

</form>