import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, NGX_SUB_FORM_HANDLE_VALUE_CHANGES_RATE_STRATEGIES } from 'ngx-sub-form';
import { Subject } from 'rxjs';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { PreInstallLoad, PreInstallLoadForm } from 'src/app/perivis/models/load-case.model';

@Component({
    selector: 'app-pre-install-data',
    template: `
    <form [formGroup]="form.formGroup" class="flex flex-col space-y-2">
      <div class="flex flex-col">
        <label for="pre-install-name">Name</label>
        <input
          class="w-full"
          id="pre-install-name"
          type="text"
          pInputText
          [formControlName]="form.formControlNames.name"
        />

      </div>
      <app-single-pre-install-load
        [formControlName]="form.formControlNames.data"
        [userUnits]="userUnits"
        [isCemented]="isCemented"
        [shoeMd]="shoeMd"
        [hangerMd]="hangerMd"
        [annularFLuidDensity]="annularFLuidDensity"
      ></app-single-pre-install-load>
    </form>
  `,
    styles: [``],
    standalone: false
})
export class PreInstallDataComponent {

  private input$ = new Subject<PreInstallLoad | null | undefined>();
  @Input() set preInstallLoad(preInstallLoad: PreInstallLoad | undefined) {
    this.input$.next(preInstallLoad);
  }

  private disabled$ = new Subject<boolean>();
  @Input() set disabled(value: boolean | undefined) {
    this.disabled$.next(!!value);
  }
  
  @Input() userUnits: UserUnitsModel;
  @Input() isCemented: boolean;
  @Input() shoeMd: number;
  @Input() hangerMd: number;
  @Input() annularFLuidDensity: number;

  @Output() preInstallLoadUpdated = new EventEmitter<PreInstallLoad>();

  public form = createForm<PreInstallLoad, PreInstallLoadForm>(this, {
    formType: FormType.ROOT,
    disabled$: this.disabled$,
    input$: this.input$,
    output$: this.preInstallLoadUpdated,
    formControls: {
      name: new UntypedFormControl('New Load', [Validators.required]),
      data: new UntypedFormControl(null, [Validators.required])
    },
    toFormGroup: (obj: PreInstallLoad | null): PreInstallLoadForm => {
      if (!obj) {
        return null;
      }

      const { data, ...commonValues } = obj;

      return {
        data: data,
        ...commonValues
      }
    },
    fromFormGroup: (formValue: PreInstallLoadForm): PreInstallLoad => {
      const { data, ...commonValues } = formValue;

      return {
        data: data,
        ...commonValues
      }
    },
    handleEmissionRate: NGX_SUB_FORM_HANDLE_VALUE_CHANGES_RATE_STRATEGIES.debounce(1000)
  })
}
