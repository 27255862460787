import { Component, Input, OnInit } from '@angular/core';
import { ReservoirService } from '../../services/reservoir.service';
import { lastValueFrom, Observable } from 'rxjs';
import { Reservoir } from '../../models/reservoirPerformance';
import { ConfirmationService } from 'primeng/api';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';

@Component({
  selector: 'app-reservoir-catalog',
  templateUrl: './reservoir-catalog.component.html',
  styles: [``],
  standalone: false,
  providers: [ConfirmationService]
})
export class ReservoirCatalogComponent implements OnInit {
  public selectedReservoir: Reservoir[];
  public reservoirData: any;
  public isLoading: boolean;
  public selectedReservoirDetails$: any;
  public emitInitialValueOnInit = false;
  public userUnits: UserUnitsModel;

  private _isAddOrDelete: boolean;
  private _savedReservoir: string;

  @Input()
  private componentId: string;

  constructor(
    private _reservoirService: ReservoirService,
    private _confirmationService: ConfirmationService,
    private _store: StoreService
  ) { }

  async ngOnInit(): Promise<void> {
    this.userUnits = await this._store.get<UserUnitsModel>(StorageKeys.UNITS);
    this.getData();
  }

  private async getData() {
    this.isLoading = true;
    await lastValueFrom(this._reservoirService.getReservoirs()).then((reservoirs) => {
      this.reservoirData = reservoirs;
      this.selectedReservoir = [];

      this.selectedReservoir = this.reservoirData != null && this.reservoirData?.reservoirs.length > 0 ? this.reservoirData.reservoirs[this.reservoirData.reservoirs.length - 1] : this.createReservoir();
      this.selectedReservoirDetails$ = new Observable(observer => observer.next(this.selectedReservoir));
      this.isLoading = false;
    });
  }

  public onAddReservoir(): void {
    if (this.reservoirData.invalid) {
      return;
    }

    this._isAddOrDelete = true;
    const reservoir = this.createReservoir();
    this.reservoirData.reservoirs.push(reservoir);
    this.selectedReservoir = this.reservoirData.reservoirs[this.reservoirData.reservoirs.length - 1];
    this.selectedReservoirDetails$ = new Observable(observer => observer.next(this.selectedReservoir));
  }

  private createReservoir(): Reservoir {
    const heightDefault = this.userUnits.longLengths == 'ft' ? 500 : 152.4;
    const radialExtentDefault = this.userUnits.longLengths == 'ft' ? 5000 : 1524;
    return {
      name: '',
      pressure: 0,
      permeability: 100,
      height: heightDefault,
      radialExtent: radialExtentDefault,
      skinFactor: 0,
      applyCustomPerformanceIndex: false,
      performanceIndex: 24840
    };
  }

  public onReservoirSelect(e: any) {
    const foundReservoir = this.reservoirData.reservoirs.find((reservoir: Reservoir) => reservoir.name === e.value.name);
    this.selectedReservoir = foundReservoir;
    this.selectedReservoirDetails$ = new Observable(observer => observer.next(this.selectedReservoir));
  }

  public onDeleteReservoir(reservoir: Reservoir): void {
    let index = this.reservoirData.reservoirs.findIndex((r: Reservoir) => r.name === reservoir.name);
    if (index === -1) {
      index = 0;
    }

    this._confirmationService.confirm({
      message: 'Are you sure that you want to delete the selected reservoir?',
      accept: () => {
        this.reservoirData.reservoirs.splice(index, 1);
        this._isAddOrDelete = true;
        this.handleSaveReservoir();
        this.selectedReservoir = this.reservoirData.reservoirs.length > 0 ? this.reservoirData.reservoirs[this.reservoirData.reservoirs.length - 1] : this.createReservoir();
        this.selectedReservoirDetails$ = new Observable(observer => observer.next(this.selectedReservoir));
      }
    });
  }

  public saveReservoir(reservoir: Reservoir): void {
    let index = this.reservoirData.reservoirs.findIndex((r: Reservoir) => r.name === reservoir.name);
    if (index === -1) {
      index = this.reservoirData.reservoirs.findIndex(x => x.name == reservoir.name);
    }
    if (index === -1) {
      index = this.reservoirData.reservoirs.length - 1;
    }
    this.reservoirData.reservoirs[index] = reservoir;
    this._savedReservoir = reservoir.name;
    this.handleSaveReservoir();
  }

  public async handleSaveReservoir(): Promise<void> {
    await lastValueFrom(this._reservoirService.updateReservoir(this.reservoirData.reservoirs));

    if (this._isAddOrDelete) {
      this.isLoading = true;
      this.reservoirData = null;
      this.reservoirData = await lastValueFrom(this._reservoirService.getReservoirs());
      this._isAddOrDelete = false;
      this.isLoading = false;
    }

    let index = this.reservoirData.reservoirs.findIndex(x => x.name == this._savedReservoir);
    if (index == -1) {
      index = this.reservoirData.reservoirs.length - 1;
    }
    this.selectedReservoir = this.reservoirData.reservoirs[index];
    this.selectedReservoirDetails$ = new Observable(observer => observer.next(this.selectedReservoir));
  }
}
