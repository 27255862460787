import { Component, Input } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { SelectItem } from 'primeng/api';
import { BaseOperation } from 'src/app/perical/models/thermal-operation.model';
import { WellType } from 'src/app/shared/models/wellType.model';
import { BhctTempProfile, CustomTempProfile, PericalLinkTempProfile, TemperatureProfile, TemperatureProfileType, UndisturbedTempProfile } from '../models/temperature-profile.model';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';

interface TemperatureProfileForm {
  type: TemperatureProfileType;
  bhctTempProfile: BhctTempProfile;
  customTempProfile: CustomTempProfile;
  undisturbedTempProfile: UndisturbedTempProfile;
  pericalLinkTempProfile: PericalLinkTempProfile;
}

@Component({
    selector: 'app-temperature-profiles',
    templateUrl: './temperature-profiles.component.html',
    providers: subformComponentProviders(TemperatureProfilesComponent),
    standalone: false
})
export class TemperatureProfilesComponent {

  @Input() currentString: any;
  @Input() loadName: string;
  @Input() thermalOperations: Partial<BaseOperation>[];
  @Input() wellType: WellType;
  @Input() shoeDepth: number;
  @Input() userUnits: UserUnitsModel;
  @Input() wellTd: number;
  @Input() isReadOnly: boolean;

  public form = createForm<TemperatureProfile, TemperatureProfileForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(null, [Validators.required]),
      bhctTempProfile: new UntypedFormControl(null),
      customTempProfile: new UntypedFormControl(null),
      undisturbedTempProfile: new UntypedFormControl(null),
      pericalLinkTempProfile: new UntypedFormControl(null)
    },
    toFormGroup: (obj: TemperatureProfile | null): TemperatureProfileForm | null => {
      if (!obj) { return null; }

      return {
        type: obj.type,
        bhctTempProfile: obj.type === TemperatureProfileType.BHCT ? obj : null,
        customTempProfile: obj.type === TemperatureProfileType.CUSTOMTEMP ? obj : null,
        undisturbedTempProfile: obj.type === TemperatureProfileType.UNDISTURBED ? obj : null,
        pericalLinkTempProfile: obj.type === TemperatureProfileType.THERMALLINK ? obj : null
      }
    },
    fromFormGroup: (formValue: TemperatureProfileForm): TemperatureProfile | null => {
      const { type, bhctTempProfile, customTempProfile, undisturbedTempProfile, pericalLinkTempProfile, ...commonValues } = formValue;

      switch(type) {
        case TemperatureProfileType.BHCT:
          return bhctTempProfile ? { type, ...bhctTempProfile, ...commonValues } : null;
        case TemperatureProfileType.CUSTOMTEMP:
          return customTempProfile ? { type, ...customTempProfile, ...commonValues } : null;
        case TemperatureProfileType.UNDISTURBED:
          return undisturbedTempProfile ? { type, ...undisturbedTempProfile, ...commonValues } : null;
        case TemperatureProfileType.THERMALLINK:
          return pericalLinkTempProfile ? { type, ...pericalLinkTempProfile, ...commonValues } : null;
      }
    }
  });

  public TemperatureProfileType: typeof TemperatureProfileType = TemperatureProfileType;
  public temps: SelectItem[] = [
    { label: 'Custom', value: 'CustomTemperatures' },
    { label: 'Perical Link', value: 'ThermalLink' },
    { label: 'Undisturbed', value: 'Undisturbed' },
    { label: 'BHCT', value: 'BottomHoleCirculatingTemperature' }
  ];
  public isLoading: boolean;

}
