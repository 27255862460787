<div *ngIf="user" class="flex flex-col items-center m-4">

  <p-card class="flex m-4 p-4 w-max">

    <!-- Card Header -->
    <ng-template pTemplate="title">
      <h1 class="text-center mt-2">
        {{ user?.profile?.firstName }} {{ user?.profile?.lastName }}
      </h1>
      <hr class="my-4" />
    </ng-template>

    <!-- Card Content -->
    <div class="space-y-6 p-4">

      <!-- Update Password Button -->
      <button class="text-blue-400 hover:text-blue-800 font-medium underline cursor-pointer" type="button"
        (click)="onSetUserPassword()">
        Update Password
      </button>

      <!-- User Details -->
      <dl class="grid grid-cols-2 gap-y-2 gap-x-2">
        <dt class="font-medium text-right">Company:</dt>
        <dd>{{ user?.profile?.organization }}</dd>

        <dt class="font-medium text-right">Email:</dt>
        <dd>{{ user?.profile?.email }}</dd>

        <dt class="font-medium text-right">Auth-ID:</dt>
        <dd>{{ user.authId.replace('auth0|', '') }}</dd>

        <dt class="font-medium text-right">Licensed:</dt>
        <dd>{{ user.isLicensed }}</dd>

        <dt class="font-medium text-right">Global Admin:</dt>
        <dd>{{ user?.profile?.isGlobalAdmin ?? false }}</dd>

        <dt class="font-medium text-right">Admin:</dt>
        <dd>{{ user?.roles?.admin ?? false }}</dd>

        <dt class="font-medium text-right">Read-Only:</dt>
        <dd>{{ user.roles.readOnly }}</dd>
      </dl>
    </div>
  </p-card>
</div>