import { Component, Input, Output } from "@angular/core";
import { FormControl, UntypedFormControl } from "@angular/forms";
import { FormType, NGX_SUB_FORM_HANDLE_VALUE_CHANGES_RATE_STRATEGIES, createForm } from "ngx-sub-form";
import { Subject } from "rxjs";
import { EditUserAccount, User } from "./user-model";

@Component({
    selector: 'app-edit-user-form',
    template: `
      <form [formGroup]="form.formGroup">
        <div class="flex flex-col space-y-2">

          <div class="flex flex-col">
            <label>Companies</label>
            <p-multiSelect appendTo="body" [placeholder]="companiesPlaceholder" [options]="companies" [formControlName]="form.formControlNames.companies" optionLabel="name" dataKey="id"></p-multiSelect>
          </div>

          <app-user-profile-form 
            [showAdminInputs]="showAdminInputs"
            [formControlName]="form.formControlNames.profile"
            [user]="user"
          />

          <p-button (onClick)="save()" label="Save"></p-button>
        </div>

      </form>
    `,
    styles: [``],
    standalone: false
})
export class EditUserFormComponent {

  private input$ = new Subject<EditUserAccount | undefined>();
  //This is a fix for the companies dropdown not binding. https://github.com/primefaces/primeng/issues/9673
  public companiesPlaceholder = "";

  constructor() {
    this.companiesPlaceholder = "Select Company";
  }

  @Input() user: User;

  @Input() companies: any[];

  @Input() set userProfile(userProfile: EditUserAccount | undefined) {
    this.input$.next(userProfile);
  }

  private disabled$ = new Subject<boolean>();
  @Input() set disabled(value: boolean | undefined) {
    this.disabled$.next(!!value);
  }

  private _showAdminInputs : boolean;
  @Input() set showAdminInputs(value: boolean) {
    this._showAdminInputs = value;
  }
  get showAdminInputs(): boolean {
    return this._showAdminInputs;
  }

  @Output() userUpdated = new Subject<EditUserAccount>();

  manualSave$ = new Subject<void>();

  public form = createForm<EditUserAccount, EditUserAccount>(this, {
    formType: FormType.ROOT,
    disabled$: this.disabled$,
    input$: this.input$,
    output$: this.userUpdated,
    manualSave$: this.manualSave$,
    formControls: {
      id: new UntypedFormControl(),
      companies: new FormControl<{ id: string, name: string }[] | null>(null),
      profile: new UntypedFormControl(null)
    },
    handleEmissionRate: NGX_SUB_FORM_HANDLE_VALUE_CHANGES_RATE_STRATEGIES.debounce(1000)
  });

  save() {
    this.manualSave$.next();
  }

}
