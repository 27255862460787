import { Component, OnInit, OnDestroy, HostListener, Input, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { GridsterComponentInterface, GridsterConfig, GridsterItem, GridsterItemComponentInterface } from 'angular-gridster2';
import { DashboardWorkspaceModel, UiComponentModel } from '../models/dashboard.model';
import { Subject } from 'rxjs';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { GridItemResizedMessage, OpenSearchComponentDropdown, ToggleWellSchematicSidebarMessage } from 'src/app/shared/models/mediator-messages.model';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { UserRoles } from '../../user-admin-page/user-model';
import { MenuItem } from 'primeng/api';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { DialogService } from 'primeng/dynamicdialog';
import { UnitsConvertComponent } from '../../units-convert/units-convert.component';
/*
  Holds the Grid Workspace that contains UI Components.
*/
@Component({
    selector: 'app-workspace',
    templateUrl: './workspace-component.html',
    styles: [],
    standalone: false
})
export class DashboardWorkspaceComponent implements OnInit, OnChanges, OnDestroy {

  private _destroy$ = new Subject<void>();
  private _gridster: GridsterComponentInterface;
  private _gridsterColOpts = { min: 50, max: 50 };
  private _uiComponentUpdatedDebouncer: Subject<UiComponentModel[]>;
  private _activeGridItemId: string;

  public gridOptions: GridsterConfig;
  public userRoles: UserRoles;
  public contextMenuItems: MenuItem[];

  @HostListener('window:resize', ['$event'])
  onResize($event: Event) {
    if ($event.isTrusted) { // User invoked
      this.computeGridColWidth();
    }
  }

  @Input()
  public workspace: DashboardWorkspaceModel;

  @Input()
  public scrollToItemId: string;

  @Output()
  public uiComponentsRemoved = new EventEmitter<UiComponentModel[]>();

  @Output()
  public uiComponentXyUpdated = new EventEmitter<UiComponentModel[]>();

  constructor(
    private _messenger: MediatorService,
    private _storeService: StoreService,
    private _dialogService: DialogService
  ) {
    this._uiComponentUpdatedDebouncer = new Subject<UiComponentModel[]>();
    this.initializeGridOptions();
  }

  async ngOnInit(): Promise<void> {
    this.userRoles = await this._storeService.get<UserRoles>(StorageKeys.ROLES);

    this._uiComponentUpdatedDebouncer
      .pipe(debounceTime(500), takeUntil(this._destroy$))
      .subscribe((value) => {
        this.uiComponentXyUpdated.emit(value);
      });

    this.contextMenuItems = [
      { label: 'Clear Workspace', icon: 'pi pi-fw pi-times', command: () => this.removeAllComponents() },
      { label: 'Component Search', icon: 'pi pi-fw pi-search', command: () => this._messenger.publish(new OpenSearchComponentDropdown()) },
      { label: 'Toggle Schematic', icon: 'pi pi-arrow-right-arrow-left', command: () => this.toggleSchematic() },
      { label: 'Units Converter', icon: 'pi pi-calculator', command: () => this.openUnitsConverter() }
    ];
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.scrollToItemId?.currentValue) {
      setTimeout(() => {
        const index = this._gridster.grid.findIndex(x => x.item.id == this._activeGridItemId || x.item.id == changes.scrollToItemId.currentValue);
        const position = index == -1 && this._activeGridItemId == null ? this._gridster.el.scrollHeight : this._gridster.grid[index].top;
        this._gridster?.el.scrollTo({ top: position, behavior: 'smooth' });
      }, 20);
    }
  }

  private toggleSchematic(): void {
    this._messenger.publish(new ToggleWellSchematicSidebarMessage());
    this._messenger.publish(new GridItemResizedMessage("none", 0, 0));
  }

  public removeUiComponent(uiComponents: UiComponentModel) {
    this.removeUiComponents([uiComponents]);
  }

  public async removeUiComponents(uiComponents: UiComponentModel[]) {
    for (const uiComponent of uiComponents) {
      await this._storeService.remove(uiComponent.id);
    }
    this.uiComponentsRemoved.emit(uiComponents);
  }

    openUnitsConverter() {
      this._dialogService.open(UnitsConvertComponent, {
        header: 'Units Converter',
        width: '18%',
        height: '17%',
        closable: true,
        draggable: true,
        modal: false,
        styleClass: 'units-convert-dialog',
        style: {
          position: 'absolute',
          top: '75px',
          right: '20px'
        }
      });
    }

  private async removeAllComponents() {
    const uiComponents = [...this.workspace.uiComponents]; // Create a shallow copy to avoid mutating the array while iterating
    await this.removeUiComponents(uiComponents);
    this.workspace.uiComponents = []; // Clear the components array after removal
  }

  private initializeGridOptions(): void {
    this.gridOptions = {
      initCallback: (ic: GridsterComponentInterface) => this.computeGridColWidth(ic),
      gridType: 'scrollVertical',
      enableEmptyCellDrop: true,
      enableOccupiedCellDrop: true,
      pushItems: true,
      swap: true,
      compactType: 'compactLeft&Up',
      pushDirections: { north: true, east: true, south: true, west: true },
      resizable: { enabled: true },
      itemChangeCallback: (e: GridsterItem, ic: GridsterItemComponentInterface) => this.onGridItemChange(e, ic),
      draggable: {
        enabled: true,
        ignoreContent: true,
        dropOverItems: true,
        dragHandleClass: 'drag-handler',
        ignoreContentClass: 'no-drag',
      },
      displayGrid: 'none',
      minCols: 50,
      maxCols: 50,
      margin: 5,
      disableWarnings: true,
      disableAutoPositionOnConflict: false,
      itemResizeCallback: (e: GridsterItem, ic: GridsterItemComponentInterface) => this.gridItemResized(e, ic),
    };
  }

  private onGridItemChange(e: GridsterItem, ic: GridsterItemComponentInterface): void {
    if (e && ic) {
      this.gridItemResized(e, ic);
    }
    // Debounced as item change fires lots of events as the grid items reflow.  
    this._uiComponentUpdatedDebouncer.next(this.workspace.uiComponents);
  }

  private computeGridColWidth(ic: GridsterComponentInterface = null) {
    if (ic){
      this._gridster = ic;
    } 
    const width = this._gridster?.el.clientWidth;
    const columns = Math.floor(width / this._gridsterColOpts.min);

    if (columns !== this._gridsterColOpts.max) {
      this.gridOptions.maxCols = columns;
      this.gridOptions.minCols = columns;
      this.gridOptions.api?.optionsChanged();
    }
  }

  public getActiveComponent(componentId: string): void {
    this._activeGridItemId = componentId;
  }

  public clearActiveComponent(): void {
    this._activeGridItemId = null;
  }

  private gridItemResized(gi: GridsterItem, gic: GridsterItemComponentInterface): void {
    this._messenger.publish(new GridItemResizedMessage(gi.name, gic.height, gic.width));
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }
}
