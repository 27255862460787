import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { toCamelCaseString } from 'src/app/shared/helpers/stringToCamel.helper';
import { ChangeSelectedThermalOperation, GridItemResizedMessage, SyncPressAndTempResults } from 'src/app/shared/models/mediator-messages.model';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { PeriforOnChangeMessages, SignalRService } from 'src/app/shared/services/signal-r.service';
import { BaseOperation } from '../../models/thermal-operation.model';
import { PressureResults, PressureResultPhase, PressureResultsUi } from '../../models/thermal-results.model';
import { ThermalOperationsService } from '../../services/thermal-operations.service';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { Store } from '@ngneat/elf';
import { zipObject } from 'lodash-es';
import { withUIEntities } from '@ngneat/elf-entities';
import { XyLinePlotUi } from 'src/app/core/models/xy-line-plot.model';

@Component({
  selector: 'app-thermal-pressure-results',
  templateUrl: './thermal-pressure-results.component.html',
  styles: [``],
  standalone: false
})
export class ThermalPressureResultsComponent implements OnInit, OnDestroy {

  public isLoading = true;
  public missingOperations = false;
  public needToCalculate: boolean;
  public tableHeight: string;
  public resultsDisplay: SelectItem[] = [
    { label: 'Plot', value: 'plot' },
    { label: 'Grid', value: 'grid' }
  ];
  public pressUnit: string;
  public depthUnit: string;
  public plotName: string;

  public singleMultiple: SelectItem[] = [
    { label: 'Single', value: 'singleOperation' },
    { label: 'Multiple', value: 'multipleOperation' }
  ];

  public multipleResultsFull: any[] = [];
  public annulusNames: { label: string, value: string }[] = [];
  public operationNames: any[] = [];
  public operationNamesPlot: { field: string, header: string }[] = [];
  public selectedResultTypeIndex: number;

  public operations: Partial<BaseOperation>[];
  public allOperationsResults: PressureResults[];
  public results: any[];
  public resultsGrid: any[];
  public cols: any[];
  public phases: PressureResultPhase[];
  public calculationError = "Please calculate Perical to see results.";
  public depthView: SelectItem[];
  public selectedOperationIndex = 0;
  public moveUpDisabled = true;
  public moveDownDisabled = false;
  public componentHeight: number;

  @ViewChild('toolbarDiv', { static: false }) toolbarDiv: ElementRef;

  public yAxisTitle = '';
  public xAxisTitle = '';

  private _subscriptions: Subscription;

  // State Management
  private _componentId: string;
  @Input() set componentId(value: string) {
    this._componentId = value;
    this.pressureResultsStore = this._storeService.createStore(this.componentId, new PressureResultsUi, withUIEntities<XyLinePlotUi>());
  }
  get componentId(): string {
    return this._componentId;
  }
  public pressureResultsStore: Store;

  constructor(
    private _thermalOperationsService: ThermalOperationsService,
    private _messenger: MediatorService,
    private _signalRService: SignalRService,
    private _storeService: StoreService
  ) {
    this._subscriptions = new Subscription();
    this._subscriptions.add(this._messenger.of(GridItemResizedMessage).subscribe((e) => {
      if (e.name.includes("Thermal Results - Pressures")) {
        const divHeight = this.toolbarDiv.nativeElement.offsetHeight + 50;
        this.tableHeight = (e.itemHeight - divHeight) + 'px';
        this.componentHeight = e.itemHeight - divHeight;
      }
    }));

    this._subscriptions.add(this._messenger.of(ChangeSelectedThermalOperation).subscribe((e) => {
      let selectedOperation;
      if (!e.selectedThermalOperation.value?.['phaseNumber']) {
        selectedOperation = e.selectedThermalOperation;
        this.pressureResultsStore.update(state => ({ ...state, selectedOperation: selectedOperation.value }));

        if (!this.pressureResultsStore.state.selectedSingleMultiple) {
          this.pressureResultsStore.update(state => ({ ...state, selectedSingleMultiple: 'singleOperation' }));
        };

        this.onOperationSelected(selectedOperation, false);
      } else {
        this.pressureResultsStore.update(state => ({
          ...state,
          selectedPhase: this.allOperationsResults.find(x => x.operationId === this.pressureResultsStore.state.selectedOperation.id).phases.find(x => x.phaseNumber === e.selectedThermalOperation.value['phaseNumber'])
        }));
        selectedOperation = this.pressureResultsStore.state.selectedOperation;

        if (!this.pressureResultsStore.state.selectedSingleMultiple) {
          this.pressureResultsStore.update(state => ({ ...state, selectedSingleMultiple: 'singleOperation' }));
        };

        this.onOperationSelected(selectedOperation, false, this.pressureResultsStore.state.selectedPhase);
      }
    }));

    this._subscriptions.add(this._messenger.of(SyncPressAndTempResults).subscribe((e) => {
      this.pressureResultsStore.update(state => ({ ...state, syncThermalResults: e.syncPressAndTemp }));
    }));
  }

  async ngOnInit(): Promise<void> {
    const uu = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);
    this.pressUnit = uu.pressure;
    this.depthUnit = uu.longLengths;

    if (!this.pressureResultsStore.state.selectedSingleMultiple) {
      this.pressureResultsStore.update(state => ({ ...state, selectedSingleMultiple: 'singleOperation' }));
    };

    this.getThermalPressureResults();

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));

    this.depthView = [
      { label: 'MD', value: 'md' },
      { label: 'TVD', value: 'tvd' }
    ];

    // this.selectedDepthView = 'md';
  }

  private signalRfunc(data: any): void {
    if (data.action == PeriforOnChangeMessages.REFRESH_THERMAL_RESULTS || data.action === PeriforOnChangeMessages.REFRESH_STRING_INPUTS) {
      this.isLoading = true;
      this.getThermalPressureResults(true);
    }
  }

  get isDialogVisible(): boolean {
    return !this.missingOperations && this.needToCalculate;
  }

  private getThermalPressureResults(calledFromSignalR?: boolean): void {
    const sources = [
      this._thermalOperationsService.getThermalOperations(),
      this._thermalOperationsService.getThermalPressureResults() as Observable<any>
    ];

    forkJoin(sources).subscribe({
      next: ([operations, allResults]) => {
        this.operations = operations;
        if (!this.pressureResultsStore.state.selectedOperation || calledFromSignalR) {
          this.pressureResultsStore.update(state => ({ ...state, selectedOperation: this.operations[0] }));
        }
        
        if (this.operations.length === 0) {
          this.missingOperations = true;
        } {
          this.missingOperations = false;
        }

        if (this.missingOperations) {
          this.results = [];
        }

        if (this.operations.length == 0) {
          this.isLoading = false;
          this.missingOperations = true;
          return;
        }

        this.allOperationsResults = allResults;

        if (this.allOperationsResults.length > 0) {
          if (this.allOperationsResults.length < 2) {
            this.moveUpDisabled = true;
            this.moveDownDisabled = true;
          }

          if (this.pressureResultsStore.state.selectedSingleMultiple == 'singleOperation') {
            const selectedOperation = this.allOperationsResults.find(x => x.operationId === this.pressureResultsStore.state.selectedOperation.id);
            this.pressureResultsStore.update(state => ({
              ...state,
              selectedPhase: selectedOperation?.phases[selectedOperation.phases.length - 1]
            }));
            this.plotName = this.setPlotName();
            this.remapResults();
          } else {
            this.remapMultipleResults(0);
          }
        } else {
          this.clearResults();
        }
        this.isLoading = false;
      },
      error: () => {
        this.missingOperations = true;
        this.isLoading = false;
      }
    });
  }

  setPlotName(): string {
    if (this.pressureResultsStore.state.selectedSingleMultiple === 'singleOperation') {
      return 'thermalTemperatureResults' + this.pressureResultsStore.state.selectedResultsDisplay + ' - ' +
        this.pressureResultsStore.state.selectedOperation.id + ' ' + this.pressureResultsStore.state.selectedPhase?.phaseNumber;
    } else {
      return 'thermalTemperatureResults' + this.pressureResultsStore.state.selectedResultsDisplay + ' - ' +
        this.pressureResultsStore.state.selectedAnnulus;
    }
  }

  public depthViewToggle(e) {
    this.pressureResultsStore.update(state => ({ ...state, selectedDepthView: e.value }));
  }

  public onSelectResultsDisplay(e): void {
    this.pressureResultsStore.update(state => ({
      ...state,
      selectedResultsDisplay: e.value
    }));
    this.plotName = this.setPlotName();
  }

  public syncPressAndTemp(e) {
    this.pressureResultsStore.update(state => ({ ...state, syncThermalResults: e.checked }));
    this._messenger.publish(new SyncPressAndTempResults(this.pressureResultsStore.state.syncThermalResults));
  }

  onOperationSelected(e, isCalledFromHere, selectedPhase?: PressureResultPhase): void {
    if (isCalledFromHere && this.pressureResultsStore.state.syncThermalResults) {
      this._messenger.publish(new ChangeSelectedThermalOperation(e));
    }
    this.moveUpDisabled = false;
    this.moveDownDisabled = false;
    if (this.allOperationsResults[0].operationId === (e.value?.id ?? e.id)) {
      this.moveUpDisabled = true;
    }
    if (this.allOperationsResults[this.allOperationsResults.length - 1].operationId === (e.value?.id ?? e.id)) {
      this.moveDownDisabled = true;
    }
    if (this.allOperationsResults.length > 0) {
      const firstOperation = this.allOperationsResults.find(x => x.operationId === (e.value?.id ?? e.id));
      this.pressureResultsStore.update(state => ({
        ...state,
        selectedOperation: e.value ?? e,
        selectedPhase: !selectedPhase ? firstOperation?.phases[firstOperation.phases.length - 1] : selectedPhase
      }));
      this.plotName = this.setPlotName();
      this.remapResults(selectedPhase);
    } else {
      this.needToCalculate = true;
    }
  }

  onUpDownButtonClick(e, isCalledFromHere) {
    this.moveUpDisabled = false;
    this.moveDownDisabled = false;
    if (this.pressureResultsStore.state.selectedSingleMultiple === 'singleOperation') {
      this.moveSingleOperations(e, isCalledFromHere);
    } else {
      this.moveMultipleOperations(e);
    }
  }

  private moveMultipleOperations(e) {
    this.annulusNames = this.pressureResultsStore.state.selectedPhase.pressureResultsTable.headers.slice(2).map(h => {
      return {
        value: toCamelCaseString(h),
        label: h
      };
    });

    if (e === 'up') {
      const selectedAnnulusIndex = this.annulusNames.findIndex(x => x.value === this.pressureResultsStore.state.selectedAnnulus);
      if (selectedAnnulusIndex === 1) {
        this.moveUpDisabled = true;
        this.pressureResultsStore.update(state => ({ ...state, selectedAnnulus: this.annulusNames[selectedAnnulusIndex - 1].value }));
        this.remapMultipleResults(0);
        return;
      }
      this.pressureResultsStore.update(state => ({ ...state, selectedAnnulus: this.annulusNames[selectedAnnulusIndex - 1].value }));
    } else {
      const selectedAnnulusIndex = this.annulusNames.findIndex(x => x.value === this.pressureResultsStore.state.selectedAnnulus);
      if (selectedAnnulusIndex === this.annulusNames.length - 2) {
        this.moveDownDisabled = true;
        this.pressureResultsStore.update(state => ({ ...state, selectedAnnulus: this.annulusNames[selectedAnnulusIndex + 1].value }));
        this.remapMultipleResults(0);
        return;
      }
      this.pressureResultsStore.update(state => ({ ...state, selectedAnnulus: this.annulusNames[selectedAnnulusIndex + 1].value }));
    }
    this.plotName = this.setPlotName();
    this.remapMultipleResults(0);
  }

  private moveSingleOperations(e, isCalledFromHere) {
    this.selectedOperationIndex = this.operations.findIndex(x => x.id === this.pressureResultsStore.state.selectedOperation.id);
    if (e === 'up') {
      const selectedOperation = this.operations[this.selectedOperationIndex - 1];
      if (!selectedOperation) {
        this.moveUpDisabled = true;
        return;
      }
      if (isCalledFromHere && this.pressureResultsStore.state.syncThermalResults) {
        this._messenger.publish(new ChangeSelectedThermalOperation(selectedOperation));
      }
      this.pressureResultsStore.state.selectedOperation = selectedOperation;
      this.selectedOperationIndex = this.operations.findIndex(x => x.id === this.pressureResultsStore.state.selectedOperation.id);
      this.moveUpDisabled = this.selectedOperationIndex === 0 ? true : false;
      this.moveDownDisabled = this.selectedOperationIndex === this.operations.length - 1 ? true : false;
      const firstOperation = this.allOperationsResults[this.selectedOperationIndex];
      if (!firstOperation) {
        this.clearResults();
      }
      this.pressureResultsStore.update(state => ({ ...state, selectedPhase: firstOperation?.phases[firstOperation.phases.length - 1] }));
      this.remapResults();
    } else {
      const selectedOperation = this.operations[this.selectedOperationIndex + 1];
      if (!selectedOperation) {
        this.moveDownDisabled = true;
        return;
      }
      if (isCalledFromHere && this.pressureResultsStore.state.syncThermalResults) {
        this._messenger.publish(new ChangeSelectedThermalOperation(selectedOperation));
      }
      this.pressureResultsStore.update(state => ({ ...state, selectedOperation: selectedOperation }));
      this.selectedOperationIndex = this.operations.findIndex(x => x.id === this.pressureResultsStore.state.selectedOperation.id);
      this.moveUpDisabled = this.selectedOperationIndex === 0 ? true : false;
      this.moveDownDisabled = this.selectedOperationIndex === this.operations.length - 1 ? true : false;
      const firstOperation = this.allOperationsResults[this.selectedOperationIndex];
      if (!firstOperation) {
        this.clearResults();
      }
      this.pressureResultsStore.update(state => ({ ...state, selectedPhase: firstOperation?.phases[firstOperation.phases.length - 1] }));;
      this.remapResults();
      this.plotName = this.setPlotName();
    }
  }

  private clearResults() {
    this.moveDownDisabled = true;
    this.moveUpDisabled = true;
    this.results = [];
    this.cols = [];
    this.resultsGrid = [];
    this.multipleResultsFull = [];
    this.needToCalculate = true;
    return
  }

  public onPhaseSelected(results: any[], isCalledFromHere: boolean): void {
    if (isCalledFromHere && this.pressureResultsStore.state.syncThermalResults) {
      this._messenger.publish(new ChangeSelectedThermalOperation(results));
    }
    if (this.allOperationsResults.length > 0) {
      this.pressureResultsStore.update(state => ({ ...state, selectedPhase: results['value'] }));
      this.plotName = this.setPlotName();
      this.remapResults(results['value']);
    } else {
      this.needToCalculate = true;
    }
  }

  public modeToggle(e) {
    this.moveUpDisabled = true;
    this.moveDownDisabled = false;
    this.pressureResultsStore.update(state => ({ ...state, selectedSingleMultiple: e }));

    if (e === 'singleOperation') {
      this.pressureResultsStore.update(state => ({ ...state, syncThermalResults: true }));
      this._messenger.publish(new SyncPressAndTempResults(this.pressureResultsStore.state.syncThermalResults));
      this.remapResults(null);
    } else {
      this.pressureResultsStore.update(state => ({ ...state, syncThermalResults: false }));
      this.remapMultipleResults(0);
    }
    this.plotName = this.setPlotName();
  }

  public annulusDropdownChange(e) {
    this.moveUpDisabled = false;
    this.moveDownDisabled = false;
    this.pressureResultsStore.update(state => ({ ...state, selectedAnnulus: e.value }));
    this.remapMultipleResults(0);
  }

  private remapMultipleResults(result: any, selectedPhase?: PressureResultPhase): void {
    let allOperationsResults = this.allOperationsResults.filter(ar => this.operations.find(rm => (rm.name === ar.name)));

    this.pressureResultsStore.update(state => ({ ...state, selectedOperation: allOperationsResults[0] }));
    const selectedOperationResults = allOperationsResults[0];

    this.needToCalculate = !selectedOperationResults;
    this.phases = selectedOperationResults ? selectedOperationResults.phases : null;
    if (!selectedPhase) {
      selectedPhase = this.phases ? this.phases[this.phases.length - 1] : null;
    }

    if (this.operations.length === 0) {
      this.missingOperations = true;
    }

    this.selectedResultTypeIndex = result.value ?? result;

    this.annulusNames = selectedPhase.pressureResultsTable.headers.slice(2).map(h => {
      return {
        value: toCamelCaseString(h),
        label: h
      };
    });

    allOperationsResults = allOperationsResults.sort((a, b) => {
      return this.operations.findIndex(x => a.name == x.name) - this.operations.findIndex(x => b.name == x.name);
    });

    this.operationNames = allOperationsResults.map(x => {
      return {
        field: toCamelCaseString(x.name),
        header: x.name.startsWith('MD') || x.name.startsWith('TVD') ? x.name : x.name + ` (${this.pressUnit})`,
        valueFormatter: (params: any) => this.formatDecimal(params)
      };
    });

    this.multipleResultsFull = [];

    if (this.operations.length === 0) {
      this.missingOperations = true;
    }

    let longestOperationDataIndex = 0;
    let dataLength = 0;
    for (let l = 0; l < allOperationsResults.length; l++) {
      if (allOperationsResults[l].phases.length == 0) {
        continue;
      }
      const length = allOperationsResults[l].phases[0].pressureResultsTable.rows.length;
      if (dataLength < length) {
        longestOperationDataIndex = l;
        dataLength = length;
      }
    }

    const longestOpIndex = allOperationsResults[longestOperationDataIndex];
    const depths = longestOpIndex.phases[0].pressureResultsTable.rows.map(x => x[0]);

    let operationCount = 0;
    let i = 0;
    const longestOpPhaseIndex = longestOpIndex.phases.length - 1;
    for (let l = 0; l < longestOpIndex.phases[0].pressureResultsTable.rows.length; l++) {
      const multipleResults = [];
      // let lastPhase;
      for (i = 0; i < allOperationsResults.length; i++) {
        const allOperationResultsPhaseAtIndex = allOperationsResults[i].phases;
        const lastPhaseNew = allOperationResultsPhaseAtIndex.length - 1;
        if (allOperationResultsPhaseAtIndex.length == 0) {
          continue;
        }
        // lastPhase = allOperationsResults[operationCount].phases.length - 1;
        if (i === 0) {
          multipleResults.push(longestOpIndex.phases[longestOpIndex.phases.length - 1].pressureResultsTable.rows[l][0]);
          multipleResults.push(longestOpIndex.phases[longestOpIndex.phases.length - 1].pressureResultsTable.rows[l][1]);
        }

        let annulusColumnIndex = allOperationResultsPhaseAtIndex[lastPhaseNew].pressureResultsTable.headers.findIndex(x => toCamelCaseString(x) == this.pressureResultsStore.state.selectedAnnulus);
        if (annulusColumnIndex < 0) {
          annulusColumnIndex = 2;
        }

        if (l < longestOpIndex.phases[longestOpPhaseIndex].pressureResultsTable.rows.length) {
          const currentDepth = longestOpIndex.phases[longestOpPhaseIndex].pressureResultsTable.rows[l][0];
          const depthIndex = depths.findIndex(x => x == currentDepth);

          if (depths[depthIndex] == currentDepth) {
            const rowIndex = allOperationResultsPhaseAtIndex[0].pressureResultsTable.rows.findIndex(x => x[0] == currentDepth);
            // lastPhaseNew = allOperationsResults[i].phases.length - 1;
            const rows = allOperationResultsPhaseAtIndex[lastPhaseNew].pressureResultsTable.rows;
            if (l < rows.length && rowIndex >= 0 &&
              currentDepth <= rows[rows.length - 1][0]) {
              multipleResults.push(rows[rowIndex][annulusColumnIndex]);
            } else if (currentDepth <= rows[rows.length - 1][0]) {
              const currentOperationDepths = rows.map(x => x[0]);
              const previousDepthIndex = currentOperationDepths.findIndex(x => x >= currentDepth) - 1;

              if (previousDepthIndex < 0) {
                multipleResults.push(null);
                continue;
              }

              const nextDepthIndex = currentOperationDepths.findIndex(x => x >= currentDepth);

              const previousDepth = currentOperationDepths[previousDepthIndex];
              const nextDepth = currentOperationDepths[nextDepthIndex];

              const previousPress = rows[previousDepthIndex][annulusColumnIndex];
              const nextPress = rows[nextDepthIndex][annulusColumnIndex];

              // let currentOperationPressures = allOperationsResults[i].phases[lastPhase].pressureResultsTable.rows.map(x => x[annulusColumnIndex]);

              // let interpoaltedPressure = LinearInterpSingleVal(currentDepth, currentOperationDepths, currentOperationPressures);

              const interpolatedPressure = previousPress + (nextPress - previousPress) / (nextDepth - previousDepth) * (currentDepth - previousDepth);

              multipleResults.push(interpolatedPressure);
            } else {
              multipleResults.push(null);
            }
          }
        } else {
          multipleResults.push(null);
        }
      }

      if (l === allOperationsResults[operationCount].phases[allOperationsResults[operationCount].phases.length - 1].pressureResultsTable.rows.length - 1) {
        operationCount++;
      }

      this.multipleResultsFull.push(multipleResults);
    }

    if (this.pressureResultsStore.state.selectedSingleMultiple === 'multipleOperation') {
      this.operationNames = this.operationNames.filter(ar => allOperationsResults.find(rm => (ar.header.startsWith(rm.name))));
    }

    this.operationNames.unshift({ field: 'tvdFt', header: `TVD (${this.depthUnit})`, valueFormatter: (params: any) => this.formatDecimal(params) });
    this.operationNames.unshift({ field: 'mdFt', header: `MD (${this.depthUnit})`, valueFormatter: (params: any) => this.formatDecimal(params) });

    this.resultsGrid = this.multipleResultsFull.map(r => zipObject(this.operationNames.map(c => c.field), r));
  }

  private remapResults(selectedPhase?: PressureResultPhase): void {
    let selectedOperationResults = this.allOperationsResults.find(o => o.operationId === this.pressureResultsStore.state.selectedOperation.id);
    if (!selectedOperationResults) {
      selectedOperationResults = this.allOperationsResults.find(o => o.operationId === (this.pressureResultsStore.state.selectedOperation['operationId'] ?? this.pressureResultsStore.state.selectedOperation['value']?.id ?? this.pressureResultsStore.state.selectedOperation.id));
    }
    this.needToCalculate = !selectedOperationResults;
    this.phases = selectedOperationResults ? selectedOperationResults.phases : null;
    if (selectedPhase == undefined || !selectedPhase) {
      selectedPhase = this.phases ? this.phases[this.phases.length - 1] : null;
    }
    if (selectedPhase) {
      this.results = selectedPhase.pressureResultsTable.rows;
      if (isNaN(this.results[0][3])) {
        this.results = [];
        this.cols = [];
        this.resultsGrid = [];
        this.needToCalculate = true;
        // this.calculationError = selectedOperationResults['errors'][1] ?? "Perical engine error while calculating operation.";
        this.calculationError = "Perical engine error while calculating operation.";
        // this.calculationError = "Perical engine error while calculating operation.";
        return;
      }
      this.cols = selectedPhase.pressureResultsTable.headers.map(h => {
        return {
          field: toCamelCaseString((h === `MD (${this.depthUnit})` || h === `TVD (${this.depthUnit})`) ? h.split(' ').shift() : h),
          header: h.startsWith('MD') || h.startsWith('TVD') ? h : h + ` (${this.pressUnit})`,
          valueFormatter: (params: any) => this.formatDecimal(params)
        };
      });
      this.resultsGrid = this.results.map(r => zipObject(this.cols.map(c => c.field), r));
    } else {
      // this.calculationError = selectedOperationResults['errors'][1] ?? (!this.needToCalculate ? "Perical engine error while calculating operation." : "Please calculate Perical to see results.");
      this.calculationError = !this.needToCalculate ? "Perical engine error while calculating operation." : "Please calculate Perical to see results.";
      // this.calculationError = "Perical engine error while calculating operation.";
      this.needToCalculate = true;
      this.results = [];
      this.cols = [];
      this.resultsGrid = [];
    }
  }

  private formatDecimal(value: any) {
    return value || value === 0
      ? (Math.trunc(value * 100) / 100).toLocaleString('en-US')
      : null;
  }

  ngOnDestroy() {
    this._subscriptions?.unsubscribe();
    this.signalRfunc = null;
  }
}
