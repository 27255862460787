import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseOperation, MudPit, OperationForm } from 'src/app/perical/models/thermal-operation.model';
import { createForm, FormType, NGX_SUB_FORM_HANDLE_VALUE_CHANGES_RATE_STRATEGIES } from 'ngx-sub-form';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { ThermalOperationsService } from 'src/app/perical/services/thermal-operations.service';
import { User } from 'src/app/core/components/user-admin-page/user-model';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { FluidsService } from 'src/app/perical/services/fluids.service';

@Component({
  selector: 'app-operation-data',
  templateUrl: './operation-data.component.html',
  styles: [``],
  standalone: false
})
export class OperationDataComponent {

  constructor(
    private _thermalOperationsService: ThermalOperationsService,
    private _fluidsService: FluidsService) {
    this.timeUnits = [
      { name: 'Minutes', value: 'DurationUnit.Minute' },
      { name: 'Hours', value: 'DurationUnit.Hour' },
      { name: 'Days', value: 'DurationUnit.Day' },
      { name: 'Months', value: 'DurationUnit.Month30' },
      { name: 'Years', value: 'DurationUnit.Year365' }
    ];
  }

  private input$ = new Subject<BaseOperation | null | undefined>();
  @Input() set operation(operation: BaseOperation | undefined) {
    this.input$.next(operation);
  }

  private disabled$ = new Subject<boolean>();
  @Input() set disabled(value: boolean | undefined) {
    this.disabled$.next(!!value);
  }

  @Input()
  public user: User;

  @Input()
  public units: UserUnitsModel;

  @Input()
  public isReadOnly: boolean;

  @Output() operationUpdate = new EventEmitter<BaseOperation>();


  @Input()
  previousOperations: BaseOperation[] | undefined;

  public timeUnits: any[];
  public circulationSelected = false;
  public hideTimeInput = true;
  public timeInputLabel: string;
  public insideTabHeader = 'Inside';
  public typeInput: any = {};
  public hasPreviousOperation = false;
  public previousOperationMudPits: MudPit = null;

  private drillingDepthRange: any;
  public defaultRop: number;
  public showRop = false;
  public hideShutIn = false;
  public isValid: boolean;
  public errorMsg = "";
  public volumeOptions: any[];
  public volumeDisabled: boolean;
  public timeDisabled: boolean;
  public showFlowVolume: boolean;

  public form = createForm<BaseOperation, OperationForm>(this, {
    formType: FormType.ROOT,
    disabled$: this.disabled$,
    input$: this.input$,
    output$: this.operationUpdate,
    formControls: {
      id: new UntypedFormControl(null),
      name: new UntypedFormControl('New Operation', [Validators.required]),
      time: new UntypedFormControl(null, [Validators.required]),
      timeUnits: new UntypedFormControl(null),
      inside: new UntypedFormControl(null, [Validators.required]),
      annulus: new UntypedFormControl(null, [Validators.required]),
      previousOperationId: new UntypedFormControl(null),
      volumeSelected: new UntypedFormControl(true),
      volumeSelection: new UntypedFormControl(null),
      volumeValue: new UntypedFormControl({ value: null, disabled: true }),
    },
    toFormGroup: (obj: BaseOperation | null): OperationForm | null => {
      const { time, inside, annulus, previousOperationId, volume, ...commonValues } = obj;

      if (!obj) {
        return null;
      }

      if (inside?.type === 'production' || inside?.type === 'injection' || inside?.type === 'circulation') {
        this.updateVolumeAndTimeState(obj.volume.volumeSelected);
        this.showFlowVolume = true;

        this.getVolumeOptionsFromFlow(inside.flow);
        if (this.showFlowVolume && volume.volumeSelection == null && inside != null) {
          this.getInitialVolume(inside['fluid'].id);
        }
      } else {
        this.showFlowVolume = false;
      }

      this.drillingDepthRange = undefined;

      if (!obj.id || obj?.id.length < 24) {
        this.circulationSelected = false;
        this.insideTabHeader = 'Inside';
        this.form.formGroup.controls.annulus.setValidators(Validators.required);
        this.form.formGroup.controls.annulus.updateValueAndValidity();
      }

      if (obj.inside?.type === 'cementing' || obj.inside?.type === 'runCasingAndCirculate' || obj.inside?.type === 'tripPipeAndCirculate') {
        this.hideTimeInput = false;
      } else {
        this.hideTimeInput = true;
      }

      if (obj.inside?.type === 'drilling') {
        this.timeInputLabel = 'Drilling Time';
        this.showRop = true;
      } else {
        this.timeInputLabel = 'Time';
        this.showRop = false;
      }

      if (obj.inside?.type === 'circulation' || obj.inside?.type === 'drilling' || obj.inside?.type === 'cementing' ||
        obj.inside?.type === 'runCasingAndCirculate' || obj.inside?.type === 'tripPipeAndCirculate') {
        this.circulationSelected = true;
        this.insideTabHeader = 'Inside + Annulus';
        this.form.formGroup.controls.annulus.setValidators(null);
      } else if (obj.inside?.type === 'production' || obj.inside?.type === 'injection' || obj.inside?.type === 'shutIn' || obj.inside?.type === 'gasLift') {
        this.circulationSelected = false;
        this.insideTabHeader = 'Inside';
        this.form.formGroup.controls.annulus.setValidators(Validators.required);
      }

      if ((inside?.type === 'circulation' || inside?.type === 'drilling' || inside?.type === 'cementing') &&
        obj.previousOperationId != null && obj.previousOperationId !== '00000000-0000-0000-0000-000000000000') {
        this._thermalOperationsService.getThermalOperationById(previousOperationId).subscribe((operation) => {
          if (operation.inside['useMudPits'] == true) {
            this.previousOperationMudPits = operation.inside['mudPits'];
          }
        });
      } else if (inside != undefined) {
        this.previousOperationMudPits = null;
      }

      this.form.formGroup.controls.inside.updateValueAndValidity();
      this.form.formGroup.controls.annulus.updateValueAndValidity();

      return {
        time: time.value,
        timeUnits: time.unit,
        inside,
        annulus: annulus ? annulus : null,
        previousOperationId: previousOperationId ? previousOperationId : null,
        volumeSelected: volume ? volume.volumeSelected : false,
        volumeSelection: volume ? volume.volumeSelection : null,
        volumeValue: volume ? volume.volumeValue : 0,
        ...commonValues,
      };
    },
    fromFormGroup: (formValue: OperationForm): BaseOperation | null => {

      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { timeUnits, time, annulus, previousOperationId, volumeSelected, volumeSelection, volumeValue, ...commonValues } = formValue;

      if (formValue.inside.type == 'production' || formValue.inside.type == 'injection' || formValue.inside.type == 'circulation') {
        if (this.form.formGroup.controls.volumeValue.value == 0) {
          this.getInitialVolume(formValue.inside['fluid'].id)
        }

        this.getVolumeOptionsFromFlow(formValue.inside.flow);

        if (this.volumeOptions && !this.volumeOptions.some(option => option.value === volumeSelection)) {
          this.form.formGroup.controls.volumeSelection.setValue(this.volumeOptions[0].value, { emitEvent: false });
          if (volumeSelected) {
            this.volumeChange(null, { value: this.volumeOptions[0].value });
          } else {
            this.timeChange(null);
          }
        }

        if (!this.form.formGroup.controls.inside.pristine) {
          if (volumeSelected) {
            this.volumeChange(null, { value: volumeSelection });
          } else if (this.volumeOptions) {
            this.timeChange(null);
          }
          this.form.formGroup.controls.inside.markAsPristine();
        }
      }

      const rawValueUpdatedOperation = this.form.formGroup.getRawValue();

      return {
        time: { value: +rawValueUpdatedOperation.time, unit: rawValueUpdatedOperation.timeUnits },
        inside: rawValueUpdatedOperation.inside,
        annulus: rawValueUpdatedOperation.annulus,
        previousOperationId: rawValueUpdatedOperation.previousOperationId,
        volume: {
          volumeSelected: rawValueUpdatedOperation.volumeSelected,
          volumeSelection: rawValueUpdatedOperation.volumeSelection,
          volumeValue: rawValueUpdatedOperation.volumeValue
        },
        ...commonValues
      };
    },
    handleEmissionRate: NGX_SUB_FORM_HANDLE_VALUE_CHANGES_RATE_STRATEGIES.debounce(500)
  });

  private getInitialVolume(fluidId: string) {
    this._fluidsService.getFluidById(fluidId).subscribe((fluid) => {
      this.onFluidChange({
        hasGasProperty: fluid.state['gasComposition'] != undefined || fluid.state.type == 'co2Fluid' || fluid.state.type == 'vle',
        hasOilProperty: fluid.state['oilApiGravity'] > 0 || fluid.state.type == 'vle',
        hasWaterMudProperty: fluid.state['nominalDensity'] != undefined || fluid.state.type == 'blackOil' || fluid.state.type == 'vle'
      });

      this.form.formGroup.controls.volumeSelection.setValue(this.volumeOptions[0].value, { emitEvent: false });

      this.timeChange(null);
    });
  }

  private updateVolumeAndTimeState(volumeSelected: boolean) {
    this.volumeDisabled = volumeSelected ? null : true;
    this.timeDisabled = volumeSelected ? true : null;

    this.toggleFormControls();
  }


  getVolumeOptionsFromFlow(flow: any) {
    let oilUnit = 'bbl';
    let gasUnit = 'MMscf';
    let waterUnit = 'bbl';

    if (this.units.hydrocarbonFlow == 'm³/d') {
      oilUnit = 'm³'
      waterUnit = 'm³';
    }

    if (this.units.gasInjectionRate == 'Sm³/h') {
      gasUnit = 'MMm³';
    }

    switch (flow.type) {
      case 'standardFluidFlow':
        this.volumeOptions = [];
        this.volumeOptions.push({ name: `gal`, value: 'bblWater' });
        break;
      case 'hydrocarbonFlow':
        this.volumeOptions = [];
        if (flow.oilFlowRate > 0) {
          this.volumeOptions.push({ name: `${oilUnit} Oil`, value: 'bblOil' });
        }
        if (flow.gasFlowRate > 0) {
          this.volumeOptions.push({ name: `${gasUnit}`, value: 'mmscfGas' });
        }
        if (flow.waterFlowRate > 0) {
          this.volumeOptions.push({ name: `${waterUnit} Water`, value: 'bblWater' });
        }
        break;
      default:
        break;
    }
  }

  toggleFormControls() {
    this.toggleControl(this.form.formGroup.controls.volumeValue, this.volumeDisabled);
    this.toggleControl(this.form.formGroup.controls.volumeSelection, this.volumeDisabled);
    this.toggleControl(this.form.formGroup.controls.time, this.timeDisabled);
    this.toggleControl(this.form.formGroup.controls.timeUnits, this.timeDisabled);
  }

  toggleControl(control: any, isDisabled: boolean) {
    if (isDisabled) {
      control.disable({ emitEvent: false });
    } else {
      control.enable({ emitEvent: false });
    }
  }

  timeChange(inputChange) {
    if (this.showRop) {
      const timeInHours = this.getTimeInHours();
      this.defaultRop = +((this.drillingDepthRange.endDepth - this.drillingDepthRange.startDepth) / timeInHours).toFixed(2);
      return;
    }

    const flow = this.form.formGroup.controls.inside.getRawValue()?.flow;

    const time = inputChange?.srcElement.valueAsNumber || this.form.formGroup.controls.time.value;
    const timeInHours = this.getTimeInHours();

    const daysFromTimeInput = this.getNumberOfDaysPerUnitOfTime();
    const oilFlowRate = flow.oilFlowRate * daysFromTimeInput;
    const gasFlowRate = flow.gasFlowRate * daysFromTimeInput;
    const waterFlowRate = (flow.waterFlowRate ?? flow.flowRate) * daysFromTimeInput;
    const volumeSelected = this.form.formGroup.controls.volumeSelection?.value ?? this.volumeOptions[0]?.value;

    switch (volumeSelected) {
      case 'bblOil': {
        if (isNaN(oilFlowRate)) {
          return;
        }
        const calculatedTimeInHoursOil = time * oilFlowRate;
        this.form.formGroup.controls.volumeValue.setValue(+calculatedTimeInHoursOil.toFixed(3), { emitEvent: false });
        break;
      }
      case 'mmscfGas': {
        if (isNaN(gasFlowRate)) {
          return;
        }
        const calculatedTimeInHoursGas = time * gasFlowRate;
        this.form.formGroup.controls.volumeValue.setValue(+calculatedTimeInHoursGas.toFixed(3), { emitEvent: false });
        break;
      }
      case 'bblWater': {
        if (isNaN(waterFlowRate)) {
          return;
        }
        if (flow.type === "hydrocarbonFlow") {
          const calculatedTimeInHoursWater = time * waterFlowRate;
          this.form.formGroup.controls.volumeValue.setValue(+calculatedTimeInHoursWater.toFixed(3), { emitEvent: false });
        } else {
          const calculatedTimeInHours = timeInHours * (flow.flowRate * 60);
          this.form.formGroup.controls.volumeValue.setValue(+calculatedTimeInHours.toFixed(3), { emitEvent: false });
        }
        break;
      }
      default:
        break;
    }
  }

  private getNumberOfDaysPerUnitOfTime() {
    switch (this.form.formGroup.controls.timeUnits.value) {
      case 'DurationUnit.Minute':
        return 1 / 1440;
      case 'DurationUnit.Hour':
        return 1 / 24;
      case 'DurationUnit.Day':
        return 1;
      case 'DurationUnit.Month30':
        return 30;
      case 'DurationUnit.Year365':
        return 365;
      default:
        return 0;
    }
  }

  onPreviousOperationChanged(e) {
    if (e.value == "00000000-0000-0000-0000-000000000000") {
      this.hideShutIn = false;
      this.hasPreviousOperation = false;
      this.previousOperationMudPits = null;
      return;
    }
    this.hasPreviousOperation = true;
    this._thermalOperationsService.getThermalOperationById(e.value).subscribe((operation) => {
      if (operation.inside.type == 'drilling' || operation.inside.type == 'tripPipeAndCirculate' ||
        operation.inside.type == 'cementing' || operation.inside.type == 'runCasingAndCirculate') {
        this.hideShutIn = true;
      } else {
        this.hideShutIn = false;
      }

      if ((operation.inside.type === 'circulation' || operation.inside.type === 'drilling' || operation.inside.type === 'cementing')
        && operation.inside['useMudPits'] == true) {
        this.previousOperationMudPits = operation.inside['mudPits'];
      } else {
        this.previousOperationMudPits = null;
      }
    });
  }

  drillingRangeChanged(e) {
    let timeChanged = e?.startDepth !== this.drillingDepthRange?.startDepth || e?.endDepth !== this.drillingDepthRange?.endDepth;
    if (!this.drillingDepthRange) {
      timeChanged = false;
    }

    this.drillingDepthRange = e;

    const timeInHours = this.getTimeInHours();

    this.defaultRop = +((e.endDepth - e.startDepth) / timeInHours).toFixed(2);

    const time = +((this.drillingDepthRange.endDepth - this.drillingDepthRange.startDepth) / this.defaultRop).toFixed(2);

    if (timeChanged) {
      this.form.formGroup.controls.timeUnits.setValue('DurationUnit.Hour', { emitEvent: false });
      this.form.formGroup.controls.time.setValue(time, { emitEvent: false });
      this.form.formGroup.controls.time.updateValueAndValidity({ emitEvent: false });
    }
  }

  private getTimeInHours(): number {
    let timeInHours: number;

    switch (this.form.formGroup.controls.timeUnits.value) {
      case 'DurationUnit.Minute':
        timeInHours = this.form.formGroup.controls.time.value / 60;
        break;
      case 'DurationUnit.Hour':
        timeInHours = this.form.formGroup.controls.time.value;
        break;
      case 'DurationUnit.Day':
        timeInHours = this.form.formGroup.controls.time.value * 24;
        break;
      case 'DurationUnit.Month30':
        timeInHours = this.form.formGroup.controls.time.value * 720;
        break;
      case 'DurationUnit.Year365':
        timeInHours = this.form.formGroup.controls.time.value * 12 * 720;
        break;
      default:
        timeInHours = 0;
        break;
    }

    return timeInHours;
  }

  ropChange(e) {
    const time = +((this.drillingDepthRange.endDepth - this.drillingDepthRange.startDepth) / e['srcElement']['valueAsNumber']).toFixed(2);
    this.form.formGroup.controls.timeUnits.setValue('DurationUnit.Hour', { emitEvent: false });
    this.form.formGroup.controls.time.setValue(time);
  }

  volumeChange(inputChange, dropdownChange) {
    const flow = this.form.formGroup.controls.inside.getRawValue()?.flow;

    const oilFlowRate = flow.oilFlowRate / 24;
    const gasFlowRate = flow.gasFlowRate / 24;
    const waterFlowRate = (flow.waterFlowRate ?? flow.flowRate) / 24;

    const volume = inputChange?.srcElement.valueAsNumber || this.form.formGroup.controls.volumeValue.value;
    const volumeSelected = dropdownChange?.value ?? this.form.formGroup.controls.volumeSelection.value;

    switch (volumeSelected) {
      case 'bblOil': { // Added curly braces
        if (isNaN(oilFlowRate)) {
          return;
        }
        const calculatedTimeInHoursOil = volume / oilFlowRate;
        const timeInUnitOil = +this.getTimeInUnitFromHours(calculatedTimeInHoursOil).toFixed(3);
        this.form.formGroup.controls.time.setValue(timeInUnitOil, { emitEvent: false });
        break;
      } // Added curly braces
      case 'mmscfGas': { // Added curly braces
        if (isNaN(gasFlowRate)) {
          return;
        }
        const calculatedTimeInHoursGas = volume / gasFlowRate;
        const timeInUnitGas = +this.getTimeInUnitFromHours(calculatedTimeInHoursGas).toFixed(3);
        this.form.formGroup.controls.time.setValue(timeInUnitGas, { emitEvent: false });
        break;
      } // Added curly braces
      case 'bblWater': { // Added curly braces
        if (isNaN(waterFlowRate)) {
          return;
        }
        if (flow.type == "hydrocarbonFlow") {
          const calculatedTimeInHoursWater = volume / waterFlowRate;
          const timeInUnitWater = +this.getTimeInUnitFromHours(calculatedTimeInHoursWater).toFixed(3);
          this.form.formGroup.controls.time.setValue(timeInUnitWater, { emitEvent: false });
        } else {
          const calculatedTimeInHours = volume / (flow.flowRate * 60);
          const timeInUnit = +this.getTimeInUnitFromHours(calculatedTimeInHours).toFixed(3);
          this.form.formGroup.controls.time.setValue(timeInUnit, { emitEvent: false });
        }
        break;
      } // Added curly braces
      default:
        break;
    }

  }

  public onFluidChange(e) {
    this.volumeOptions = [];

    let oilUnit = 'bbl';
    let gasUnit = 'MMscf';
    let waterUnit = 'bbl';

    if (this.units.hydrocarbonFlow == 'm³/d') {
      oilUnit = 'm³'
      waterUnit = 'm³';
    }

    if (this.units.gasInjectionRate == 'Sm³/h') {
      gasUnit = 'MMm³';
    }

    if (e.hasOilProperty) {
      this.volumeOptions.push({ name: `${oilUnit} Oil`, value: 'bblOil' });
    }

    if (e.hasGasProperty) {
      this.volumeOptions.push({ name: `${gasUnit}`, value: 'mmscfGas' });
    }

    if (e.hasWaterMudProperty) {
      if (this.form.formGroup.controls.inside.getRawValue()?.flow.type == "standardFluidFlow") {
        this.volumeOptions.push({ name: `gal`, value: 'bblWater' });
      } else {
        this.volumeOptions.push({ name: `${waterUnit} Water`, value: 'bblWater' });
      }
    }
  }

  private getTimeInUnitFromHours(timeInHours) {
    let timeValue: number;

    switch (this.form.formGroup.controls.timeUnits.value) {
      case 'DurationUnit.Minute':
        timeValue = timeInHours * 60;
        break;
      case 'DurationUnit.Hour':
        timeValue = timeInHours;
        break;
      case 'DurationUnit.Day':
        timeValue = timeInHours / 24;
        break;
      case 'DurationUnit.Month30':
        timeValue = timeInHours / 720;
        break;
      case 'DurationUnit.Year365':
        timeValue = timeInHours / (12 * 720);
        break;
      default:
        timeValue = 0;
        break;
    }

    return timeValue;
  }

  public volumeTimeChange(volumeSelected) {
    this.updateVolumeAndTimeState(volumeSelected);
    this.form.formGroup.controls.volumeSelected.setValue(volumeSelected);
    this.volumeChange(null, volumeSelected);
  }

  onChildTypeChanged(e) {
    if (e.type.value === 'cementing' || e.type.value === 'runCasingAndCirculate' || e.type.value === 'tripPipeAndCirculate') {
      this.hideTimeInput = false;
    } else {
      this.hideTimeInput = true;
    }

    if (e.type.value === 'drilling') {
      this.timeInputLabel = 'Drilling Time';
      this.showRop = true;
    } else {
      this.timeInputLabel = 'Time';
      this.showRop = false;
    }

    if (e.type.value === 'circulation' || e.type.value === 'drilling' || e.type.value === 'cementing'
      || e.type.value === 'runCasingAndCirculate' || e.type.value === 'tripPipeAndCirculate') {
      this.circulationSelected = true;
      this.insideTabHeader = 'Inside + Annulus';
      this.form.formGroup.controls.annulus.clearValidators();
      this.form.formGroup.controls.annulus.updateValueAndValidity();
      // console.log(this.formGroupErrors);
    } else {
      this.circulationSelected = false;
      this.insideTabHeader = 'Inside';
      this.form.formGroup.controls.annulus.setValidators(Validators.required);
      // this.formGroupControls.annulus.controls.type.setValue(OperationType.SHUTIN);
      this.form.formGroup.controls.annulus.updateValueAndValidity();
    }

    if (e.type.value === 'production' || e.type.value === 'injection' || e.type.value === 'shutIn' || e.type.value === 'gasLift') {
      this.form.formGroup.controls.annulus.clearValidators();
      this.form.formGroup.controls.annulus.updateValueAndValidity();
    }

    if (e.source === 'inside' && e.type.value === 'shutIn') {
      this.form.formGroup.controls.inside.clearValidators();
      this.form.formGroup.controls.inside.updateValueAndValidity();
    }

    this.typeInput = e;
  }
}
