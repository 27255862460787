import { Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { TreeNode } from 'primeng/api';

@Component({
    selector: 'app-move-design-dialog',
    templateUrl: './move-design-dialog.component.html',
    styles: [``],
    standalone: false
})
export class MoveDesignDialogComponent implements OnInit, OnDestroy {

  private _subscriptions: Subscription;
  public moveDesignForm: UntypedFormGroup;
  public treeItems: TreeNode[];

  constructor(
    private formBuilder: UntypedFormBuilder,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    this._subscriptions = new Subscription();
    this.treeItems = this.filterOutDesigns(config.data.treeItems);
  }

  ngOnInit(): void {
    this.moveDesignForm = this.formBuilder.group({
      designId: [this.config.data.id],  
      fromWellboreId: [this.config.data?.fromWellboreId],
      toWellboreId: ['', Validators.required]
    });
  }

  // Recursive method to filter out 'designs'
  private filterOutDesigns(nodes: TreeNode[]): TreeNode[] {
    return nodes
      .filter(node => node.data.level !== 'designs')
      .map(node => {
        if (node.children && node.children.length > 0) {
          node.children = this.filterOutDesigns(node.children);
        }
        return node;
      });
  }

  onWellboreSelect(node: TreeNode) {
    if(node.data.level == "wellbores"){
      const toWellboreId = node.data.id;
      this.moveDesignForm.controls['toWellboreId'].setValue(toWellboreId);
    }else{
      this.moveDesignForm.controls['toWellboreId'].setValue('');
    }
  }

  onWellboreClear() {
    this.moveDesignForm.controls['toWellboreId'].reset();
  }

  onSave() {
    const designData = this.moveDesignForm.value;
    this.ref.close(designData);
  }

  onCancel() {
    this.ref.close();
  }

  onInputFocus($event: Event) {
    ($event.target as HTMLInputElement).select();
  }

  ngOnDestroy() {
    this._subscriptions?.unsubscribe();
  }
  
}
