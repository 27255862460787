import { Component, ElementRef, Input, OnChanges, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PlotlyService } from 'angular-plotly.js';
import { Subscription, debounceTime } from 'rxjs';
import { setPlotImageColorsForExport, setPlotImageColorsForInterface } from 'src/app/shared/helpers/plot-export-colors.helper';
import { GridItemResizedMessage } from 'src/app/shared/models/mediator-messages.model';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { Store } from '@ngneat/elf';
import { PlotData, PlotlyDataLayoutConfig } from 'plotly.js';
import { UIEntitiesRef, addEntities, hasEntity, selectEntity, updateEntities } from '@ngneat/elf-entities';
import { StorageKeys, StoreService } from '../../services/store.service';

@Component({
  selector: 'app-xy-line-plot',
  templateUrl: './xy-line-plot.component.html',
  styles: [],
  standalone: false
})
export class XyLinePlotComponent implements OnInit, OnChanges, OnDestroy {
  public plot: PlotlyDataLayoutConfig = {
    data: [],
    layout: {
    },
    config: {}
  };

  @Input()
  public componentHeight: number;

  @ViewChild('plotElement', { read: ElementRef, static: false })
  public plotElement: ElementRef;

  @Input()
  public plotName: string;

  @Input()
  public plotTitle: string;

  @Input()
  public downloadPlotName: string;

  @Input()
  public plotData: any[];

  @Input()
  public xAxisTitle: string;

  @Input()
  public yAxisTitle: string;

  @Input()
  public reverseAutoRange = false;

  @Input()
  public legendBelowPlot = false;

  @Input()
  public xAxisRange: number[] = null;

  @Input()
  public yAxisRange: number[] = null;

  @Input()
  public annotations: any[] = [];

  @Input()
  public shapes: any[] = [];

  @Input()
  public plotOrientation: "v" | "h" = 'h';

  @Input()
  public legendGroupClick: "toggleitem" | "togglegroup" = 'toggleitem';

  @Input()
  public xyLinePlotStore: Store;

  @Input()
  public btmMargin: number;

  public showFormatAxis = false;
  public plotHeightBackup: number;
  public plotHeight = '90%';
  public showScrollOverlay = false;
  public isDarkMode: boolean;
  public plotState$: any;
  public plotState: any;

  private resizeSubscription: Subscription = new Subscription();
  private enableScroll = false;

  constructor(
    private _storeService: StoreService,
    private plotlyService: PlotlyService,
    private _messenger: MediatorService) {
    addEventListener("keydown", (event) => {
      if (event.code == "Shift" || event.code == "ShiftLeft" || event.code == "ShiftRight") {
        if (!this.enableScroll) {
          this.generateConfig();
        }
        this.enableScroll = true;
      }
    });

    addEventListener("keyup", (event) => {
      if (event.code == "Shift" || event.code == "ShiftLeft" || event.code == "ShiftRight") {
        this.enableScroll = false;

        if (this.xyLinePlotStore.query(hasEntity(this.plotName, {ref: UIEntitiesRef}))) {
          this.xyLinePlotStore.update(updateEntities( this.plotName,
            {
              xAxisRange: this.plot.layout.xaxis.range.map(num => parseFloat(num).toFixed(0)),
              yAxisRange: this.plot.layout.yaxis.range.map(num => parseFloat(num).toFixed(0))
            },
            { ref: UIEntitiesRef }
          ));
        } else {
          this.xyLinePlotStore.update(addEntities(
            {
              id: this.plotName,
              xAxisRange: this.plot.layout.xaxis.range.map(num => parseFloat(num).toFixed(0)),
              yAxisRange: this.plot.layout.yaxis.range.map(num => parseFloat(num).toFixed(0))
            },
            { ref: UIEntitiesRef }
          ));
        }
      }
    });

    this.resizeSubscription.add(this._messenger.of(GridItemResizedMessage).pipe(debounceTime(450)).subscribe(msg => {
      if (msg.name == this.plotTitle) {
        if (this.componentHeight) {
          this.plotHeight = this.showFormatAxis ? this.componentHeight - 155 + 'px' : this.componentHeight + 'px';
        } else {
          this.plotHeight = this.showFormatAxis ? msg.itemHeight - 290 + 'px' : '90%';
        }
      }
      this.generateConfig();
    }));
  }

  public async onScroll() {
    if (!this.enableScroll) {
      this.showScrollOverlay = true;

      setTimeout(() => {
        this.showScrollOverlay = false;
      }, 1300);
    }
  }

  ngOnInit() {
    this._storeService.getStore(StorageKeys.DARK_MODE)
      .subscribe(t => {
        this.isDarkMode = t.isDarkMode;
        this.ngOnChanges();
      });
  }
  

  ngOnChanges() {
    if (this.componentHeight) {
      this.plotHeight = this.componentHeight + 'px';
    }
    if (!this.xyLinePlotStore.query(hasEntity(this.plotName, {ref: UIEntitiesRef}))) {
      this.xyLinePlotStore.update(addEntities(
        {
          id: this.plotName
        },
        { ref: UIEntitiesRef }
      ));
    }
    this.plotState$ = this.xyLinePlotStore.pipe(selectEntity(this.plotName, { ref: UIEntitiesRef }));
    this.plotState$.subscribe(state => {
      this.plotState = state;
    });
    this.generateConfig();
  }

  public async resetFormatAxis() {
    const Plotly = await this.plotlyService.getPlotly();
    Plotly.relayout(this.plotlyService.getInstanceByDivId(this.plotName), {
      'xaxis.autorange': true,
      'yaxis.autorange': true
    });
    this.xyLinePlotStore.update(updateEntities( this.plotName,
      {
        xAxisTick: null,
        yAxisTick: null
      },
      { ref: UIEntitiesRef }
    ));
    this.generateConfig();
  }

  public expandFormatAxis() {
    this.showFormatAxis = !this.showFormatAxis;
    if (this.componentHeight) {
      this.plotHeight = this.showFormatAxis ? this.componentHeight - 120 + 'px' : this.componentHeight + 'px';
    } else {
      this.plotHeight = this.showFormatAxis ? this.plotElement.nativeElement.offsetHeight - 120 + 'px' : '90%';
    }

    this.generateConfig();
  }

  afterPlot(): void {
    if (this.plotData.length > 0) {
      this.populatePlaceholders();
    }
  }

  private populatePlaceholders() {
    if (this.xyLinePlotStore.query(hasEntity(this.plotName, {ref: UIEntitiesRef}))) {
      if (this.plotName?.includes('Heatmap')) {
        this.xyLinePlotStore.update(updateEntities( this.plotName,
          {
            xAxisRange: this.plot.layout.xaxis.range.map(num => parseFloat(num)),
            yAxisRange: this.plot.layout.yaxis.range.map(num => parseFloat(num))
          },
          { ref: UIEntitiesRef }
        ));
      } else {
        this.xyLinePlotStore.update(updateEntities( this.plotName,
          {
            xAxisRange: this.plot.layout.xaxis.range.map(num => parseFloat(num).toFixed(0)),
            yAxisRange: this.plot.layout.yaxis.range.map(num => parseFloat(num).toFixed(0))
          },
          { ref: UIEntitiesRef }
        ));
      }

    } else {
      this.xyLinePlotStore.update(addEntities(
        {
          id: this.plotName,
          xAxisRange: this.plot.layout.xaxis.range.map(num => parseFloat(num).toFixed(0)),
          yAxisRange: this.plot.layout.yaxis.range.map(num => parseFloat(num).toFixed(0))
        },
        { ref: UIEntitiesRef }
      ));
    }
  }

  public changeAxis(e, axis, range) {
    const value = e.srcElement.value;
    if (value == '') {
      return;
    }

    if (this.xyLinePlotStore.query(hasEntity(this.plotName, {ref: UIEntitiesRef}))) {
      this.xyLinePlotStore.update(updateEntities(this.plotName,
        (entity) => (
          {
            [`${axis}Range`]: entity[`${axis}Range`].map((item, index) =>
              index === range ? value : item
            )
          }
        ), { ref: UIEntitiesRef }));
    } else {
      this.xyLinePlotStore.update(addEntities(
        (entity) => (
          {
            id: this.plotName,
            [`${axis}Range`]: entity[`${axis}Range`].map((item, index) =>
              index === range ? value : item
            )
          }
        ), { ref: UIEntitiesRef }));
    }

    this.generateConfig();
  }

  public relayout(e: any) {
    if (e['xaxis.autorange'] && e['yaxis.autorange']) {
      this.populatePlaceholders();
    }
  }

  public changeTicks(e, axis) {
    const value = e.srcElement.value;
    if (value == '') {
      return;
    }

    if(this.xyLinePlotStore.query(hasEntity(this.plotName, {ref: UIEntitiesRef}))) {
      this.xyLinePlotStore.update(
        updateEntities(this.plotName,
          { [`${axis}Tick`]: value },
          { ref: UIEntitiesRef }
        )
      );
    } else {
      this.xyLinePlotStore.update(
        addEntities(
          { id: this.plotName, [`${axis}Tick`]: value },
          { ref: UIEntitiesRef }
        )
      );
    }

    this.generateConfig();
  }

  private async generateConfig() {
    const plotBackgroundColor = this.isDarkMode ? '#1C1C1C' : '#fff';
    const fontColor = this.isDarkMode ? 'white' : 'black';
    const Plotly = await this.plotlyService.getPlotly();
    if (this.plotData.length > 0) {
      this.plot.data = this.plotData;
      this.plot.layout = {
        autosize: true,
        margin: { t: (this.legendBelowPlot ? 50 : 90), r: 10, l: 80, b: 5 },
        hovermode: 'closest',
        plot_bgcolor: plotBackgroundColor,
        paper_bgcolor: plotBackgroundColor,
        hoverlabel: { namelength: -1 },
        legend: {
          font: {
            color: fontColor,
            size: 11
          },
          orientation: this.plotOrientation,
          groupclick: this.legendGroupClick,
        },
        annotations: this.annotations,
        shapes: this.shapes,
        xaxis: {
          range: this.plotState.xAxisRange,
          side: this.legendBelowPlot ? null : 'top',
          linecolor: 'grey',
          mirror: true,
          dtick: this.plotState.xAxisTick,
          tickfont: {
            color: fontColor
          },
          tickcolor: 'grey',
          gridcolor: '#454545',
          zerolinecolor: '#5C5c5c',
          zerolinewidth: 2,
          tickformat: "f",
          automargin: this.legendBelowPlot ? true : false,
          title: {
            text: this.xAxisTitle,
            font: {
              color: fontColor
            }
          },
          autorange: this.plotState.xAxisRange ? this.plotState.xAxisRange : true,
          hoverformat: '.2f'
        },
        yaxis: {
          range: this.plotState.yAxisRange,
          linecolor: 'grey',
          linewidth: 1,
          mirror: true,
          dtick: this.plotState.yAxisTick,
          tickfont: {
            color: fontColor
          },
          tickcolor: 'grey',
          gridcolor: '#454545',
          zerolinecolor: '#5c5c5c',
          zerolinewidth: 2,
          tickformat: "f",
          title: {
            text: this.yAxisTitle,
            font: {
              color: fontColor
            }
          },
          autorange: this.plotState.yAxisRange ? this.plotState.yAxisRange : this.reverseAutoRange ? '' : 'reversed',
          hoverformat: '.2f'
        }
      };
      this.plot.config = {
        responsive: true,
        scrollZoom: this.enableScroll,
        displaylogo: false,
        modeBarButtons: [
          [{
              title: 'Format Axis',
              name: 'Format Axis',
              icon: Plotly.Icons.plotlylogo,
              click: this.expandFormatAxis.bind(this)
            }],
            [{
              title: 'Download plot as png with white background',
              name: 'Download plot as png with white background',
              icon: Plotly.Icons.camera,
              click: this.downloadPlot.bind(this)
            }], 
            ['toImage'], ['zoom2d'], ['pan2d'], ['zoomIn2d'], ['zoomOut2d'], ['autoScale2d'], ['hoverClosestCartesian'], ['hoverCompareCartesian']
        ]
      };

      setTimeout(() => {
        this.attachLegendHoverEvent();
      }, 100);
    }

  }

  private async attachLegendHoverEvent() {
    const graphDiv = this.plotlyService.getInstanceByDivId(this.plotName);
    const legendItems = graphDiv?.querySelectorAll('.legend .traces .legendtoggle');

    legendItems.forEach((legendItem, index) => {
      legendItem.addEventListener('mouseover', () => this.highlightTraceOnLegenditemHover(index));
      legendItem.addEventListener('mouseout', () => this.resetHighlightOnLegenditemHover(index));
    });
  }

  public async highlightTraceOnLegenditemHover(traceIndex: number) {
    const graphDiv = this.plotlyService.getInstanceByDivId(this.plotName);
    const Plotly = await this.plotlyService.getPlotly();

    if (this.plotName.startsWith('thermalTemperatureResultsPlot') || this.plotName.startsWith('operationEnvelopePlot')) {
      const filteredData = graphDiv['data'].filter((trace) => {
        return !(trace.name.startsWith('Formation below') || trace.name.startsWith('Wellhead') || trace.name.startsWith('Perforation'));
      });

      if (this.plotName.startsWith('operationEnvelopePlot')) {
        if (traceIndex == 1) {
          return;
        } else if (traceIndex >= 2) {
          traceIndex -= 2;
        }
      }

      const traceName = filteredData[traceIndex].name;
      traceIndex = graphDiv['data'].findIndex((trace) => {
        return trace.name == traceName;
      });

      if (this.plotName.startsWith('thermalTemperatureResultsPlot')) {
        Plotly.restyle(graphDiv, {
          // 'line.color': 'white',  // Change color or style as needed
          'line.width': 4
        }, [traceIndex + 1]);
      }
    }

    let dlpEnvelopeArray = [];

    if (this.plotName.startsWith('dlpPlot')) {
      const dlpArrayIndex = graphDiv['data'].findIndex(x => x.name == 'Initial Condition');
      dlpEnvelopeArray = [...Array(dlpArrayIndex).keys()];
    }

    if (this.plotName.startsWith('packerOperatingEnvelope')) {
      traceIndex+=1;
    }

    if (this.plotName.startsWith('operationEnvelopePlot'), this.plotName.startsWith('packerOperatingEnvelope')) {
      Plotly.restyle(graphDiv, {
        // 'line.color': 'white',  // Change color or style as needed
        'line.width': 3
      }, [0, traceIndex]);
    } else {
      Plotly.restyle(graphDiv, {
        // 'line.color': 'white',  // Change color or style as needed
        'line.width': 3
      }, [traceIndex]);
    }

    Plotly.restyle(graphDiv, {
      'opacity': 0.25
    }, [...Array(graphDiv['data'].length).keys()].filter(i => i !== traceIndex && (this.plotName.startsWith('operationEnvelopePlot') || this.plotName.startsWith('packerOperatingEnvelope') ? i !== 0 : true) && !dlpEnvelopeArray.includes(i)));
  }

  public async resetHighlightOnLegenditemHover(traceIndex: number) {
    const graphDiv = this.plotlyService.getInstanceByDivId(this.plotName);
    const Plotly = await this.plotlyService.getPlotly();

    if (this.plotName.startsWith('thermalTemperatureResultsPlot') || this.plotName.startsWith('operationEnvelopePlot')) {
      const filteredData = graphDiv['data'].filter((trace) => {
        return !(trace.name.startsWith('Formation below') || trace.name.startsWith('Wellhead') || trace.name.startsWith('Perforation'));
      });

      if (this.plotName.startsWith('operationEnvelopePlot') && traceIndex >= 2) {
        traceIndex -= 2;
      }

      const traceName = filteredData[traceIndex].name;
      traceIndex = graphDiv['data'].findIndex((trace) => {
        return trace.name == traceName;
      });

      if (this.plotName.startsWith('thermalTemperatureResultsPlot')) {
        Plotly.restyle(graphDiv, {
          // 'line.color': null,  // Reset to the original color
          'line.width': null   // Reset to the original width
        }, [traceIndex + 1]);
      }
    }

    Plotly.restyle(graphDiv, {
      // 'line.color': null,  // Reset to the original color
      'line.width': null,   // Reset to the original width
    }, [traceIndex]);

    Plotly.restyle(graphDiv, {
      'opacity': 1
    });
  }

  async downloadPlot() {
    const graphDiv = this.plotlyService.getInstanceByDivId(this.plotName);
    const Plotly = await this.plotlyService.getPlotly();
    const currentData = this.plot.data;

    // Filter out traces that are not visible
    const filteredData = currentData.filter((trace: PlotData) => {
      return trace.visible !== false && trace.visible !== 'legendonly';
    });

    const filteredLayout = { ...this.plot.layout };
    const filteredConfig = { ...this.plot.config };

    await Plotly.newPlot(graphDiv, filteredData, filteredLayout, filteredConfig);

    // Download the plot with only the selected (visible) traces
    await Plotly.downloadImage(graphDiv, {
      filename: this.downloadPlotName,
      width: this.plotElement.nativeElement.offsetWidth,
      height: this.plotElement.nativeElement.offsetHeight,
      format: 'png',
      setImageColors: setPlotImageColorsForExport(graphDiv),
    }).then(() => {
      setPlotImageColorsForInterface(graphDiv);
    });

    await Plotly.newPlot(graphDiv, this.plot.data, this.plot.layout, this.plot.config);
  }

  ngOnDestroy() {
    this.resizeSubscription?.unsubscribe();
  }
}
