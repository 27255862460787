import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, Observable, of, shareReplay } from 'rxjs';
import { TrajectoryXyzModel } from '../models/trajectory-xyz.model';
import { TotalDepthResult, TrajectoryModel } from '../models/trajectory.model';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`;

@Injectable({
  providedIn: 'root'
})
export class TrajectoryService {
  private trajectoryPointsCache$: Record<string, Observable<TrajectoryModel[]>> = {};
  private tvdsFromMdsCache$: Record<string, Observable<number[]>> = {};
  private trajectoryXyzCache$: Record<string, Observable<TrajectoryXyzModel>> = {};

  constructor(private http: HttpClient) { }

  public getTrajectoryPoints(skipUnits = false): Observable<TrajectoryModel[]> {
    const cacheKey = skipUnits.toString();
    if (!this.trajectoryPointsCache$[cacheKey]) {
      this.trajectoryPointsCache$[cacheKey] = this.http
        .get<TrajectoryModel[]>(`${PeriforApiEndpoint}/trajectory/points?skipUnits=${skipUnits}`)
        .pipe(
          catchError(() => of([])),
          shareReplay(1)
        );
    }
    setTimeout(() => this.trajectoryPointsCache$[cacheKey] = null, 1000);
    return this.trajectoryPointsCache$[cacheKey];
  }

  public getTrajectoryXyz(skipUnits = false): Observable<TrajectoryXyzModel> {
    const cacheKey = skipUnits.toString();
    if (!this.trajectoryXyzCache$[cacheKey]) {
      this.trajectoryXyzCache$[cacheKey] = this.http
        .get<TrajectoryXyzModel>(`${PeriforApiEndpoint}/trajectory/points-xyz?skipUnits=${skipUnits}`)
        .pipe(
          catchError(() => of(null as TrajectoryXyzModel)),
          shareReplay(1)
        );
    }
    setTimeout(() => this.trajectoryXyzCache$[cacheKey] = null, 1000);
    return this.trajectoryXyzCache$[cacheKey];
  }

  public getTvdsFromMds(mds: number[], calledFromPPFG = false): Observable<number[]> {
    const cacheKey = JSON.stringify({ mds, calledFromPPFG });
    
    if (!this.tvdsFromMdsCache$[cacheKey]) {
      this.tvdsFromMdsCache$[cacheKey] = this.http
        .post<number[]>(`${PeriforApiEndpoint}/trajectory/mds-to-tvds`, {
          measuredDepths: mds,
          calledFromPPFG: calledFromPPFG
        })
        .pipe(
          catchError(() => of([])),
          shareReplay(1)
        );
    }
    setTimeout(() => this.tvdsFromMdsCache$[cacheKey] = null, 1000);
    return this.tvdsFromMdsCache$[cacheKey];
  }
  
  updateTrajectory(trajectory: TrajectoryModel[]): Observable<TrajectoryModel[]> {
    this.trajectoryPointsCache$ = {};
    return this.http.put<TrajectoryModel[]>(`${PeriforApiEndpoint}/trajectory`, trajectory);
  }

  getTotalDepth(skipUnits = false): Observable<TotalDepthResult> {
    return this.http.get<TotalDepthResult>(`${PeriforApiEndpoint}/trajectory/total-depth?skipUnits=${skipUnits}`);
  }
}
