
export class ThermalOpOrderModel {
  public constructor(init?: Partial<ThermalOpOrderModel>) {
    Object.assign(this, init);
  }

  sortOrder : OpOrderModel[];
  
}

export class OpOrderModel {
  public constructor(init?: Partial<OpOrderModel>) {
    Object.assign(this, init);
  }

  opId: string;
  sort: number
}
