<form [formGroup]="form.formGroup">
  <p-tabs value="0">
    <p-tablist>
      <p-tab value="0">Kick Parameters </p-tab>
      <p-tab value="1">Drill String</p-tab>
    </p-tablist>

    <p-tabpanels class="p-0">
      <p-tabpanel value="0">
        <div class="flex gap-2 flex-row m-2">
          <p-fieldset legend="Kick Parameters" class="w-1/2">
            <div class="flex flex-col space-y-2">
              <app-uom-input label="Influx Depth" [unitLabel]="userUnits.longLengths + ' MD'"
                [formControlName]="form.formControlNames.influxDepth" [control]="'influxDepth'"
                [default]="influxDepthDefault" [validators]="validators.influxDepth"
                title="Must be at or below shoe: {{ currentString.shoeMd }} {{ userUnits.longLengths }}" />
              <app-uom-input label="Liquid Density" [unitLabel]="densityLabel"
                [formControlName]="form.formControlNames.liquidDensity" [control]="'liquidDensity'"
                [default]="liquidDensityDefault" [validators]="validators.liquidDensity"
                title="Allowed range: {{ liquidDensityValidation.min }} - {{ liquidDensityValidation.max }} {{ volumeLabel }}" />
              <app-uom-input label="Kick Volume" [unitLabel]="volumeLabel"
                [formControlName]="form.formControlNames.kickVolume" [control]="'kickVolume'"
                [default]="kickVolumeDefault" [validators]="validators.kickVolume"
                title="Allowed range: {{ kickVolumeValidation.min }} - {{ kickVolumeValidation.max }} {{ volumeLabel }}" />
              <app-uom-input label="Kick Intensity" [unitLabel]="densityLabel"
                [formControlName]="form.formControlNames.kickIntensity" [control]="'kickIntensity'"
                [default]="kickIntensityDefault" [validators]="validators.kickIntensity"
                title="Allowed range: {{ kickIntensityValidation.min }} - {{ kickIntensityValidation.max }} {{ densityLabel }}" />
              <app-uom-input label="Max Mud Weight" [unitLabel]="densityLabel"
                [formControlName]="form.formControlNames.maxMudWeight" [control]="'maxMudWeight'"
                [default]="nextStringMudWeight" [validators]="validators.maxMudWeight"
                title="Allowed range: {{ maxMudWeightValidation.min }} - {{ maxMudWeightValidation.max }} {{ densityLabel }}" />
            </div>
          </p-fieldset>

          <p-fieldset legend="Fracture Pressure" class="w-1/2">
            <div class="flex flex-col space-y-2">
              <app-uom-input label="Fracture Depth" [unitLabel]="userUnits.longLengths + ' MD'"
                [formControlName]="form.formControlNames.fractureDepth" [control]="'fractureDepth'"
                [default]="shoeDepth + 1" [validators]="validators.fractureDepth"
                title="Allowed range: {{ shoeDepth }} - {{ nextShoeDepth }} {{ userUnits.longLengths }}"
                (focusout)="getEmwDefaultFrac($event.target.value)" />

              <app-uom-input label="Fracture Gradient" [unitLabel]="densityLabel"
                [formControlName]="form.formControlNames.fractureGradient" [control]="'fractureGradient'"
                [default]="defaultFracGradient" [validators]="validators.fractureGradient"
                title="Allowed range: {{ fractureGradientValidation.min }} - {{ fractureGradientValidation.max }} {{ densityLabel }}" />

              <app-uom-input label="Margin Of Error" [unitLabel]="densityLabel"
                [formControlName]="form.formControlNames.fractureMarginOfError" [control]="'fractureMarginOfError'"
                [default]="'0'" [validators]="validators.fractureMarginOfError"
                title="Allowed range: {{ marginOfErrorValidation.min }} - {{ marginOfErrorValidation.max }} {{ densityLabel }}" />
            </div>
          </p-fieldset>
        </div>
      </p-tabpanel>

      <p-tabpanel value="1">
        <div class="m-2">
          <app-uom-input label="Drill Pipe OD" [unitLabel]="userUnits.shortLengths"
            [formControlName]="form.formControlNames.drillPipeOd" [control]="'drillPipeOd'"
            [default]="defaultDrillPipeOd" title="Allowed range: 0 - {{ smallestPipeId }}" />
        </div>
      </p-tabpanel>

    </p-tabpanels>
  </p-tabs>
</form>