import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, of, shareReplay } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Fluid } from '../models/fluid.model';
import { ThermalOpOrderModel } from '../models/op-order.model';
import { Perforation } from '../models/perforation.model';
import { BaseOperation } from '../models/thermal-operation.model';
import { PressureResults, TemperatureResults } from '../models/thermal-results.model';
import { PericalOptions } from '../models/pericalOptions';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`;

@Injectable({
  providedIn: 'root'
})
export class ThermalOperationsService {
    private thermalOperations$: Observable<Partial<BaseOperation>[]> = null;
    private perforationsCache: Record<string, Observable<Perforation[]>> = {};
    private thermalOperationCache: Record<string, Observable<BaseOperation>> = {};
    private previousOperationsCache: Record<string, Observable<Partial<BaseOperation>[]>> = {};
    private temperatureResultsCache: Record<string, Observable<TemperatureResults[]>> = {};
    private fluidsCache$: Observable<Fluid[]> | null = null;
    private annularResultsCache$: Observable<any[]> | null = null;
    private thermalPressureResultsCache$: Record<string, Observable<PressureResults[]>> = {};
  
  constructor(private http: HttpClient) { }

  private clearCache() {
    this.thermalOperations$ = null;
    this.thermalOperationCache = {};
    this.previousOperationsCache = {};
    this.temperatureResultsCache = {};
    this.perforationsCache = {};
  }

  getThermalOperations(): Observable<Partial<BaseOperation>[]> {
    if (!this.thermalOperations$) {
      this.thermalOperations$ = this.http.get<Partial<BaseOperation>[]>(`${PeriforApiEndpoint}/thermalOperations/`)
        .pipe(
          catchError(() => {
            return of([]);
          }),
          shareReplay(1)
        );
    }
    setTimeout(() => this.thermalOperations$ = null, 1000);
    return this.thermalOperations$;
  }

  getPreviousThermalOperations(currentOperationId: string): Observable<Partial<BaseOperation>[]> {
    if (!this.previousOperationsCache[currentOperationId]) {
      this.previousOperationsCache[currentOperationId] = this.http
        .get<BaseOperation[]>(`${PeriforApiEndpoint}/thermalOperations/previous-ops?currentOperationId=${currentOperationId}`)
        .pipe(
          catchError(() => {
            return of([]);
          }),
          shareReplay(1)
        );
    }
    setTimeout(() => this.previousOperationsCache[currentOperationId] = null, 1000);
    return this.previousOperationsCache[currentOperationId];
  }

  getApbFinalConditionOperations(): Observable<Partial<BaseOperation>[]> {
    return this.http.get<BaseOperation[]>(`${PeriforApiEndpoint}/apb/thermalOperations`);
  }

  getThermalOperationById(operationId: string): Observable<BaseOperation> {
    if (!this.thermalOperationCache[operationId]) {
      this.thermalOperationCache[operationId] = this.http.get<BaseOperation>(`${PeriforApiEndpoint}/thermalOperations/${operationId}`)
        .pipe(
          catchError(() => {
            return of(null);
          }),
          shareReplay(1)
        );
    }
    setTimeout(() => this.thermalOperationCache[operationId] = null, 1000);
    return this.thermalOperationCache[operationId];
  }

  updateThermalOperation(operation: BaseOperation): Observable<boolean> {
    return this.http.put<boolean>(`${PeriforApiEndpoint}/thermalOperations/${operation.id}`, operation);
  }

  createThermalOperation(operation: BaseOperation): Observable<string> {
    return this.http.post<string>(`${PeriforApiEndpoint}/thermalOperations/`, operation);
  }

  deleteThermalOperation(operationId: string): Observable<boolean> {
    return this.http.delete<boolean>(`${PeriforApiEndpoint}/thermalOperations/${operationId}`);
  }

  cloneThermalOperation(operationId: string, operationName: string): Observable<Partial<BaseOperation>> {
    return this.http.post<Partial<BaseOperation>>(`${PeriforApiEndpoint}/thermalOperations/${operationId}/clone`, {name: operationName});
  }

  updateThermalOperationOrder(order: ThermalOpOrderModel) {
    return this.http.put<boolean>(`${PeriforApiEndpoint}/thermalOperations/order`, order);
  }

  getThermalTemperatureResults(takeFirstThreeCols = false): Observable<TemperatureResults[]> {
    const cacheKey = takeFirstThreeCols.toString(); // Use the parameter value as the cache key

    if (!this.temperatureResultsCache[cacheKey]) {
      this.temperatureResultsCache[cacheKey] = this.http
        .get<TemperatureResults[]>(`${PeriforApiEndpoint}/thermalResults/temperatures?takeFirstThreeCols=${takeFirstThreeCols}`)
        .pipe(
          catchError(() => {
            return of([]);
          }),
          shareReplay(1)
        );
    }
    setTimeout(() => this.temperatureResultsCache[cacheKey] = null, 1000);
    return this.temperatureResultsCache[cacheKey];
  }

  public getDetailedAnnularResults(): Observable<any[]> {
    if (!this.annularResultsCache$) {
      this.annularResultsCache$ = this.http
        .get<TemperatureResults[]>(`${PeriforApiEndpoint}/thermalResults/annuli`)
        .pipe(
          catchError(() => of([])),
          shareReplay(1)
        );
    }
    setTimeout(() => this.annularResultsCache$ = null, 1000);
    return this.annularResultsCache$;
  }

  public getThermalPressureResults(takeFirstThreeCols = false): Observable<PressureResults[]> {
    const cacheKey = takeFirstThreeCols.toString();
    if (!this.thermalPressureResultsCache$[cacheKey]) {
      this.thermalPressureResultsCache$[cacheKey] = this.http
        .get<PressureResults[]>(`${PeriforApiEndpoint}/thermalResults/pressures?takeFirstThreeCols=${takeFirstThreeCols}`)
        .pipe(
          catchError(() => of([])),
          shareReplay(1)
        );
    }
    setTimeout(() => this.thermalPressureResultsCache$[cacheKey] = null, 1000);
    return this.thermalPressureResultsCache$[cacheKey];
  }

  public getFluids(): Observable<Fluid[]> {
    if (!this.fluidsCache$) {
      this.fluidsCache$ = this.http.get<Fluid[]>(`${PeriforApiEndpoint}/thermalOperations/fluids`)
        .pipe(
          catchError(() => {
            return of([]);
          }),
          shareReplay(1)
        );
    }
    setTimeout(() => this.fluidsCache$ = null, 1000);
    return this.fluidsCache$;
  }

  getPerforations(skipUnits = false): Observable<Perforation[]> {
    const cacheKey = `skipUnits=${skipUnits}`;

    if (!this.perforationsCache[cacheKey]) {
      this.perforationsCache[cacheKey] = this.http.get<Perforation[]>(`${PeriforApiEndpoint}/thermalOperations/perforations?skipUnits=${skipUnits}`)
        .pipe(
          catchError(() => {
            return of([]);
          }),
          shareReplay(1)
        );
    }
    setTimeout(() => this.perforationsCache[cacheKey] = null, 1000);
    return this.perforationsCache[cacheKey];
  }

  setPerforations(perforation: Perforation): Observable<Perforation> {
    return this.http.post<Perforation>(`${PeriforApiEndpoint}/thermalOperations/perforations`, perforation);
  }

  updatePerforation(perforation: Perforation): Observable<boolean> {
    return this.http.put<boolean>(`${PeriforApiEndpoint}/thermalOperations/perforations/`, perforation);
  }

  deletePerforation(perforationId: string): Observable<boolean> {
    return this.http.delete<boolean>(`${PeriforApiEndpoint}/thermalOperations/perforations/${perforationId}`);
  }

  getPericalOptions(): Observable<PericalOptions> {
    return this.http.get<PericalOptions>(`${PeriforApiEndpoint}/thermalOperations/pericalOptions`)
      .pipe(map(r=>  new PericalOptions({...r})));
  }

  setPericalOptions(pericalOptions: PericalOptions) {
    return this.http.put(`${PeriforApiEndpoint}/thermalOperations/pericalOptions`, pericalOptions);
  }
}
