<div class="w-full h-full p-2">
  <app-tool-loader [isLoading]="isLoading" positionUnset="true">
    <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
    <p-dialog [draggable]="false" [(visible)]="configMissing" [closable]="false">
      <i class="pi pi-exclamation-circle"></i> {{ disabledMessage }} <br />
      <br />
    </p-dialog>

    <form *ngIf="designFactorsForm" [formGroup]="designFactorsForm" class="w-full">
      <div class="flex flex-wrap gap-2 m-2">

        <!-- Pipe Section -->
        <div class="flex-1">
        <p-fieldset legend="Pipe" class="flex flex-col flex-grow-0 flex-shrink-0 basis-[150px]">

          <div class="flex flex-col mb-2">
            <label for="barlowBurst" class="mb-1">Barlow Burst</label>
            <p-inputNumber formControlName="barlowBurst" [minFractionDigits]="1" autocomplete="off"
              class="w-full"></p-inputNumber>
            <app-allowed-range [invalid]="designFactorsForm.controls.barlowBurst.invalid" from="0.1" to="10" />
          </div>

          <div class="flex flex-col mb-2">
            <label for="apiCollapse">API Collapse</label>
            <p-inputNumber formControlName="apiCollapse" [minFractionDigits]="1" class="w-full"></p-inputNumber>
            <app-allowed-range [invalid]="designFactorsForm.controls.apiCollapse.invalid" from="0.1" to="10" />
          </div>

          <div class="flex flex-col mb-2">
            <label for="pureTension">Pure Tension</label>
            <p-inputNumber formControlName="pureTension" [minFractionDigits]="1" class="w-full"></p-inputNumber>
            <app-allowed-range [invalid]="designFactorsForm.controls.pureTension.invalid" from="0.1" to="10"/>
          </div>

          <div class="flex flex-col mb-2">
            <label for="pureCompression">Pure Compression</label>
            <p-inputNumber formControlName="pureCompression" [minFractionDigits]="1" class="w-full"></p-inputNumber>
            <app-allowed-range [invalid]="designFactorsForm.controls.pureCompression.invalid" from="0.1" to="10" />
          </div>

          <div class="flex flex-col mb-2">
            <label for="vonMisesBurst">Von Mises Burst</label>
            <p-inputNumber formControlName="vonMisesBurst" [minFractionDigits]="1" class="w-full"></p-inputNumber>
            <app-allowed-range [invalid]="designFactorsForm.controls.vonMisesBurst.invalid" from="0.1" to="10" />
          </div>

          <div class="flex flex-col mb-2">
            <label for="vonMisesCollapse">Von Mises Collapse</label>
            <p-inputNumber formControlName="vonMisesCollapse" [minFractionDigits]="1" class="w-full"></p-inputNumber>
            <app-allowed-range [invalid]="designFactorsForm.controls.vonMisesCollapse.invalid" from="0.1" to="10"/>
          </div>
        </p-fieldset>
      </div>

        <!-- Connection Section -->
        <div class="flex-1">
        <p-fieldset legend="Connection" class="flex flex-col flex-grow-0 flex-shrink-0 basis-[150px]">
          <div [formGroup]="designFactorsForm.get('connection')">

            <div class="flex flex-col mb-2">
              <label for="burst">Burst/Leak</label>
              <p-inputNumber formControlName="burstLeak" [minFractionDigits]="1" class="w-full"
                id="conBurstLeak"></p-inputNumber>
              <app-allowed-range [invalid]="connection.controls.burstLeak.invalid" from="0.1" to="10"/>
            </div>

            <div class="flex flex-col mb-2">
              <label for="collapse">Collapse/Leak</label>
              <p-inputNumber formControlName="collapse" [minFractionDigits]="1" class="w-full"
                id="conCollapse"></p-inputNumber>
              <app-allowed-range [invalid]="connection.controls.collapse.invalid" from="0.1" to="10"/>
            </div>

            <div class="flex flex-col mb-2">
              <label for="tension">Tension</label>
              <p-inputNumber formControlName="tension" [minFractionDigits]="1" class="w-full"
                id="conTension"></p-inputNumber>
              <app-allowed-range [invalid]="connection.controls.tension.invalid" from="0.1" to="10"/>
            </div>

            <div class="flex flex-col mb-2">
              <label for="compression">Compression</label>
              <p-inputNumber formControlName="compression" [minFractionDigits]="1" class="w-full"></p-inputNumber>
              <app-allowed-range [invalid]="connection.controls.compression.invalid" from="0.1" to="10"/>
            </div>
          </div>
        </p-fieldset>
      </div>
      </div>
    </form>
  </p-scrollPanel>
  </app-tool-loader>
</div>