import { UntypedFormArray, UntypedFormGroup } from "@angular/forms";

export function measuredDepthMustBeGreaterThanPrevious(control: UntypedFormArray) {
   const currentArray = control.controls as UntypedFormGroup[];
   let errorMsg = "";
   if (currentArray.length > 1) {
      const isAscending = a => a.slice(1)
                        .map((e,i) => e > a[i])
                        .every(x => x);

      const btMds = currentArray.map(c=> c.controls['bottomMeasuredDepth'].value);
      const mdIsAscending =  isAscending(btMds);
      if (!mdIsAscending) {
         errorMsg += `Bottom depths must be in increasing order \n`;
      }
      return errorMsg ? { error: errorMsg } : null;
   }
   return null;
}
