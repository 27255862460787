<p-button *ngIf="messagesUnread() > 0" class="notification-button" (click)="notificationHub.toggle($event)"
  icon="pi pi-bell" [badge]="messagesUnread()" badgeSeverity="danger" severity="secondary" size="small" rounded text
  title="Notification Hub">
</p-button>

<p-button *ngIf="messagesUnread() === 0" class="notification-button" (click)="notificationHub.toggle($event)" size="small"
  rounded severity="secondary" text icon="pi pi-bell" title="Notification Hub">
</p-button>

<p-popover #notificationHub (onShow)="markRead()">

  <p-toolbar>
    <ng-template pTemplate="start">
      <p-select [ngModel]="selectedSeverity()" [options]="messageTypes" (onChange)="filterSeverity($event.value)"
        placeholder="All">
      </p-select>
    </ng-template>
    <ng-template pTemplate="center">
      <b>Notifications</b>
    </ng-template>
    <ng-template pTemplate="end">
      <p-button size="small" outlined severity="warning" (click)="clearNotifications()"
        [disabled]="messages().length === 0">
        Clear All
      </p-button>
    </ng-template>
  </p-toolbar>

  <div class="hub-container">

    <div class="flex flex-col">
      @for (msg of messages(); track msg.id;) {
      <p-message [severity]="msg.severity" closable (onClose)="removeNotification(msg)" class="mb-2">
        <div class="w-full">
          <span>{{msg.summary}}</span>
          <p-button class="mr-auto" rounded text size="small" severity="warning" title="Copy Message Text"
            (click)="copyNotification(msg)" icon="pi pi-clone">
          </p-button>
          <p class="text-sm">{{ msg.detail }}</p>
          <p class="text-xs">{{ msg.data.time }}</p>
        </div>
      </p-message>
      }
    </div>

    <div *ngIf="messages().length === 0" class="flex justify-center no-notification">
      <b> No notifications to display</b>
    </div>

  </div>
</p-popover>