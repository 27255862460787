<form [formGroup]="form.formGroup">

  <div class="flex space-x-2">

    <div class="w-1/2 space-y-2">

      <div class="flex flex-col mb-2">
        <label>Reservoir</label>
        <p-select [options]="reservoirs" optionLabel="name" appendTo="body" formControlName="reservoir"
          [placeholder]="getReservoirPlaceholderText(form.formGroup.controls.reservoir.value)"></p-select>
      </div>

      <p-fieldset legend="Reservoir Fluid PVT Conditions">
        <div class="flex">
          <input [disabled]="disableNodal" type="radio" binary="true" (click)="pvtConditionsChange('bottomHole')"
            [checked]="pvtCondition === 'bottomHole'">
          <label class="ml-2">Flowing Bottom-Hole</label>
        </div>

        <div class="flex">
          <input [disabled]="disableNodal" type="radio" binary="true" (click)="pvtConditionsChange('farField')"
            [checked]="pvtCondition === 'farField'">
          <label class="ml-2">Far-field Reservoir</label>
        </div>

        <div class="flex">
          <input [disabled]="disableNodal" type="radio" binary="true" (click)="pvtConditionsChange('average')"
            [checked]="pvtCondition === 'average'">
          <label class="ml-2">Average Reservoir Conditions</label>
        </div>
      </p-fieldset>

    </div>

    <div class="border-r border-gray"></div>

    <div class="w-1/2 space-y-2">
 
      <div class="flex flex-col">
        <label>Choke</label>
        <p-select [options]="chokes" formControlName="choke" optionLabel="name" appendTo="body"
          [autoDisplayFirst]="false" [showClear]="true"
          [placeholder]="getChokePlaceholderText(form.formGroup.controls.choke.value)"></p-select>

        <div *ngIf="form.formGroup.controls.choke.value" class="field mt-2">
          <p-toggleswitch formControlName="chokeFlowCheck" binary="true"></p-toggleswitch>
          <label class="ml-2"> Check for Choked Flow</label>
        </div>

      </div>

      <div class="flex flex-col">
        <label>{{ pressureLabel }}</label>
        <p-inputgroup>
          <p-inputNumber [minFractionDigits]="1" [disabled]="true" formControlName="upstreamPressure"></p-inputNumber>
          <p-inputgroup-addon>{{ pressureUnit }}</p-inputgroup-addon>
        </p-inputgroup>
      </div>
      <p *ngIf="
          form.formGroup?.controls.upstreamPressure.value &&
          form.formGroup.controls.upstreamPressure.invalid
        " class="validation-error">
        Allowed range: {{ pressureValidation.min }} -
        {{ pressureValidation.max }}
      </p>

      <div class="flex flex-col">
        <label>{{ temperatureLabel }}</label>
        <p-inputgroup>
          <p-inputNumber [minFractionDigits]="1" [disabled]="true"
            formControlName="upstreamTemperature"></p-inputNumber>
          <p-inputgroup-addon>{{ tempUnit }}</p-inputgroup-addon>
        </p-inputgroup>
        <p *ngIf="
          form.formGroup?.controls.upstreamTemperature.value &&
          form.formGroup.controls.upstreamTemperature.invalid
        " class="validation-error">
          Allowed range: {{ temperatureValidation.min }} -
          {{ temperatureValidation.max }}
        </p>
      </div>
    </div>
  </div>

  <div *ngIf="!disableNodal" class="pt-4">
    <p-fieldset>
      <ng-template pTemplate="header">
        <div class="flex items-center">
          <input
            type="checkbox"
            binary="true"
            formControlName="includeRestartOperation"
          >
          <label class="pl-2" for="nodal-analysis">Restart Operation</label>
        </div>
      </ng-template>

      <app-restart-operation
        [disableRestartOperation]="!form.formGroup.value.includeRestartOperation"
        [formControlName]="form.formControlNames.restartOperation"
      ></app-restart-operation>

    </p-fieldset>
  </div>

</form>