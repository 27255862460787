<app-tool-loader positionUnset="true">
    <div class="flex flex-col space-y-2">

        <div class="flex flex-col">
            <label for="type">Unit Type</label>
            <p-select 
                [options]="unitTypes" 
                optionLabel="name" 
                appendTo="body" 
                optionValue="value"
                [autoDisplayFirst]="false" 
                placeholder="Select Unit Type" 
                (onChange)="selectedUnitTypeChange($event)"
                [(ngModel)]="selectedUnitType">
            </p-select>
        </div>

        <div class="flex space-x-2">
            <div class="flex flex-col">
                <label>From</label>
                <p-input-group>
                    <p-inputNumber 
                        [minFractionDigits]="1" 
                        [(ngModel)]="fromValue"
                        (onBlur)="convertUnits()">
                    </p-inputNumber>
                    <p-select 
                        [options]="unitOptions" 
                        optionLabel="name" 
                        optionValue="value" 
                        appendTo="body"
                        [(ngModel)]="fromUnit" 
                        (onChange)="convertUnits()">
                    </p-select>
                </p-input-group>
            </div>

            <div class="flex flex-col">
                <label>To</label>
                <p-input-group>
                    <p-inputNumber [minFractionDigits]="1" [(ngModel)]="convertedUnit"
                        [disabled]="true"></p-inputNumber>
                    <p-select [options]="unitOptions" optionLabel="name" optionValue="value" appendTo="body"
                        [(ngModel)]="toUnit" (onChange)="convertUnits()"></p-select>
                </p-input-group>
            </div>
        </div>
    </div>
</app-tool-loader>