<app-tool-loader [isLoading]="isLoading" positionUnset="true">
  <p-toolbar>
    <ng-template #start>
      <div class="flex gap-2">
        <p-selectButton [options]="tablePlot" [(ngModel)]="poreFracStore.state.tablePlotSelected"
          (onChange)="tablePlotToggle($event)" [allowEmpty]="false">
        </p-selectButton>

        <p-selectButton *ngIf="poreFracStore.state?.tablePlotSelected === 'plot'" [options]="pressureEmw"
          [(ngModel)]="poreFracStore.state.pressureEmwSelected" (onChange)="pressureEmwToggle($event)"
          [allowEmpty]="false">
        </p-selectButton>
      </div>
    </ng-template>
  </p-toolbar>

  <app-ng-table-grid
    [hidden]="poreFracStore.state?.tablePlotSelected !== 'table'"
    #poreFracPressureTable
    [tableName]="pressureType === 'porePressure' ? 'Pore Pressure' : 'Fracture Gradient'" [tableData]="pressures"
    [columnDefinitions]="columnDefinitions" [newRowFormGroup]="newDataRow" [inputFields]="inputFields"
    [calculatedFields]="calculatedFields" (dataChange)="onPorePressuresChanged($event)" [isDynamicLoaded]="true"
    [virtualScroll]="true" btmMargin="75"/>
  
  <app-xy-line-plot
  [hidden]="poreFracStore.state?.tablePlotSelected !== 'plot'" [plotData]="plot.data"
  [xAxisTitle]="xAxisTitle" [yAxisTitle]="yAxisTitle" [plotName]="plotName" [downloadPlotName]="downloadPlotName"
  [plotTitle]="plotTitle"
  [xyLinePlotStore]="poreFracStore"
  ></app-xy-line-plot>
</app-tool-loader>