<form [formGroup]="createTreeLevelForm">

  <div class="flex flex-col mb-2">
    <label for="levelName">{{ levelName | titlecase }} Name</label>
    <input pInputText formControlName="name" id="levelName" (keyup)="handleKeyUp($event)" />
    <p *ngIf="createTreeLevelForm.controls.name.value.length > 0 && createTreeLevelForm.controls.name.value.length < 3"
      class="validation-error">Must be longer than three characters</p>
  </div>

  <div class="float-right">
    <button pButton type="button" icon="pi pi-check" class="p-button-text p-button-success"
      [disabled]="createTreeLevelForm.invalid" label="Save" (click)=onSave()></button>
    <button pButton type="button" icon="pi pi-times" class="p-button-text p-button-danger" label="Cancel"
      (click)=onCancel()></button>
  </div>
</form>