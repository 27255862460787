import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngneat/elf';

@Component({
    selector: 'app-heatmap',
    //TODO: Make the template here work with plotly.
    // Plotly setup looks like maybe it changed? https://github.com/plotly/angular-plotly.js
    // template: `<plotly-plot [data]="graph.data" [layout]="graph.layout"></plotly-plot>`,
    template: `
        <app-xy-line-plot
            [plotData]="data"
            [xAxisTitle]="''"
            [yAxisTitle]="yAxisTitle"
            [plotName]="plotName"
            [downloadPlotName]="downloadPlotName"
            [plotTitle]="plotTitle"
            [componentHeight]="componentHeight"
            [legendBelowPlot]="true"
            [xyLinePlotStore]="heatmapStore"
        ></app-xy-line-plot>
    `,
    standalone: false
})
export class HeatmapComponent implements OnChanges {
    public yAxisTitle = '';
    public downloadPlotName = 'heatmap_plot';

    @Input()
    public componentHeight: number

    @Input()
    public plotTitle: string;

    @Input()
    public plotName: string;

    @Input()
    public heatGrid: number[][];

    @Input()
    public heatGridHeaders: { field: string, header: string }[];

    @Input()
    public useTvd = false;

    @Input()
    public depthUnit: string;

    @Input()
    public heatmapStore: Store;

    public config = {
      displaylogo: false
    }

    public data = [{
            x: [], // Grid Position
            y: [], // Depths
            z: [[]], // Temperature Grid
            type: 'heatmap',
            colorscale: 'Portland',
            hoverongaps: false,
            colorbar: {
                tickfont: {
                  color: 'white'
                }
              }
        }
    ]

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.heatGrid || changes.heatGridHeaders || changes.useTvd) {
            this.updateHeatmapData();
        }
    }

    private updateHeatmapData(): void {
        this.data[0].x = this.heatGridHeaders?.slice(2).map(l => l.header);
        this.data[0].y = this.getDepths(this.useTvd);
        this.data[0].z = this.processedHeatGrid();
        this.yAxisTitle = this.useTvd ? `TVD (${this.depthUnit})` : `MD (${this.depthUnit})`;
    }

    private processedHeatGrid(): number[][] {
        return this.heatGrid?.map(row => row.slice(2));
    }

    private getDepths(tvd = false): number[] {
        const columnIndex = tvd ? 1 : 0;
        return this.heatGrid?.map(row => row[columnIndex]);
    }
}
