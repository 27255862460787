import * as paper from 'paper';
import { WellType } from "src/app/shared/models/wellType.model";
import { Tubular, WellComponent } from "../../well-configuration/models";
import { getWellboreOutsideString } from "../../well-configuration/validation/tubularFitsInWellConfiguration";
import { Casing, HorizontalLine, IPipeSection, Liner, Tubing, Perforation, WellSchematicItemBase } from "../models/well-schematic-models";
import { PerforationModel } from "src/app/wellbore-inputs/models/perforations.model";
import { ElementRef, Injectable } from "@angular/core";
import { WellSchematicConsolidated, WellSchematicPacker } from "src/app/wellbore-inputs/models/well-schematic.model";
import { UserUnitsModel } from "src/app/core/components/user-units/user-units.model";
import { StorageKeys, StoreService } from "src/app/core/services/store.service";

@Injectable()
export class SchematicDrawingEngine {

    private _units: { long: string, short: string };
    private _currentTubularId: string;
    private _drawOriginX = 0;
    private _topOfFluid = 0;

    public scope: paper.PaperScope;
    public project: paper.Project;
    public heightScaleFactor = .95;
    public widthScaleFactor = 5;
    public drawLabels: boolean;
    public drawWater: boolean;

    public constructor(
        _store: StoreService
    ) {
        (async () => {
            const currentUserUnits = await _store.get<UserUnitsModel>(StorageKeys.UNITS);
            this._units = {
                long: currentUserUnits.longLengths,
                short: currentUserUnits.shortLengths
            };
        })();
    }

    public get LabelGroups() {
        return this.project.getItems({ name: "TextGroup" });
    }

    // This method gets over-ridden by things using this service, console.log avoids linting errors
    public onPaperClick = (e: Event) => {console.log(e)};

    // This method gets over-ridden by things using this service, console.log avoids linting errors
    public onPathSelect = (e: Event, tsId: string, odPath: paper.Path, idPath: paper.Path) => { console.log(e, tsId, odPath, idPath) };

    public drawSchematic(canvas: ElementRef, wellSchematicPaperObjs: WellSchematicItemBase[]) {

        this.scope = new paper.PaperScope();
        this.project = new paper.Project(canvas.nativeElement);

        // This is a little odd, doing this to keep the view from zooming out in on redraws when the widget is in the sidebar.
        // https://github.com/paperjs/paper.js/issues/1729 (Note: Calling set view size with the canvas size triggered this scaling hittest issue, or something like it.)
        this.project.view.viewSize = new paper.Size(this.project.view.size.width + 1, this.project.view.size.height + 1);

        this.project.view.onClick = (e) => {
            this.project.deselectAll();
            this.onPaperClick(e);
        }

        this.drawAllWellSchematicComponents(wellSchematicPaperObjs);

        const widthConversionFactors = {
            'in': 1,
            'cm': 2.54,
            'mm': 25.4
        };
        this.widthScaleFactor = 5 / widthConversionFactors[this._units.short]

        this.project.activeLayer.scale(this.widthScaleFactor, this.heightScaleFactor); // This will scale the width & height to fit the component

        // Label Stuff
        this.labelAll(wellSchematicPaperObjs);

        this.project.activeLayer.fitBounds(this.project.view.bounds.expand(-1, -45), false); // this fits the item in the center
    }

    public convertWellConfigurationToSchematicObjs(
        wellSchematicData: WellSchematicConsolidated,
        tubularsToDraw: WellComponent[],
    ): WellSchematicItemBase[] {

        this._currentTubularId = wellSchematicData.currentTubular;

        const { packers, perforations, wellType, tubularStrings } = wellSchematicData;
        const wellSchematicDrawingObjs = [];

        const largestTopCasingDiameter = Math.max(...tubularsToDraw.map(x => x.holeSize));
        this.addLandWaterDepthLines(largestTopCasingDiameter, wellType, wellSchematicDrawingObjs);

        for (let i = 0; i < tubularsToDraw.length; i++) {
            const tubular = tubularsToDraw[i];
            // Need deep object copies so that getWellBoreOutside string works correctly....
            const wellBoreOutside = getWellboreOutsideString(JSON.parse(JSON.stringify(tubular)), JSON.parse(JSON.stringify([...tubularStrings])));

            const labelToc = ![ // Don't draw TOC labels at the mudline, etc.
                wellType.waterDepth,
                wellType.wellheadDepth,
                wellType.wellheadElevation,
                wellType.wellheadDepthFromDrillFloor,
                wellType.waterDepth + wellType.wellheadDepth,
                tubular.shoeMd
            ].includes(tubular.topOfCementMd);

            // Don't draw annular fluid on the outer casing above the mudline.
            if (i == 0 && ["platform", "subsea"].includes(wellType.type.toLocaleLowerCase())) {
                this._topOfFluid = wellType.drillFloorElevation + wellType.waterDepth;
            }

            wellSchematicDrawingObjs.push(
                ...this.generateDrawPaths(labelToc, tubular, packers, wellBoreOutside)
            );
        }

        wellSchematicDrawingObjs.push(
            ...this.drawPerforations(perforations, tubularsToDraw)
        );

        return wellSchematicDrawingObjs;
    }

    private labelAll(wellSchematicPaperObjs: WellSchematicItemBase[]) {
        // Track Children that have already been labeled
        const childObjLabelNames: string[] = [];

        for (const wellSchematicPaperObj of wellSchematicPaperObjs) {
            wellSchematicPaperObj.generateLabel(this.scope);
            wellSchematicPaperObj.children
                .filter(c => !childObjLabelNames.includes(c.name))
                .forEach(c => c.generateLabel(this.scope));

            childObjLabelNames.push(...wellSchematicPaperObj.children.map(c => c.name));
        }

        this.LabelGroups.forEach(lg => lg.visible = this.drawLabels);
    }

    private drawAllWellSchematicComponents(wellSections: WellSchematicItemBase[]) {
        let leftX = 0;
        for (let i = 0; i < wellSections.length; i++) {
            leftX = this.calculateHorizontalOffset(wellSections[0].outerDiameter, wellSections[i].outerDiameter, this._drawOriginX);
            wellSections[i].labelSide = i % 2 ? 'left' : 'right';
            wellSections[i].draw(this.scope, leftX, this.onPathSelect);
        }
    }

    private addLandWaterDepthLines(largestTopCasingDiameter: number, wellType: WellType, wellSchematicDrawingObjs: WellSchematicItemBase[]) {
        // Set our width for horizont lines to just a little wider than the top casing
        const lineWidth = largestTopCasingDiameter * .70;
        if (wellType.type.toLowerCase() != "land") {
            const mudline = wellType.drillFloorElevation + wellType.waterDepth;

            // Waterline can make the schematic very small. Drawing waterline is off by default.
            if (this.drawWater) {
                wellSchematicDrawingObjs.push(new HorizontalLine({
                    units: this._units,
                    verticalPosition: 0,
                    width: lineWidth,
                    color: "blue",
                    name: `Water Depth\r ${wellType.waterDepth.toFixed(0)}${this._units.long}`
                }));
            }

            if (mudline != wellType.wellheadDepthFromDrillFloor) {
                wellSchematicDrawingObjs.push(new HorizontalLine({
                    units: this._units,
                    verticalPosition: wellType.wellheadDepthFromDrillFloor,
                    width: lineWidth,
                    color: "black",
                    name: `Wellhead\r ${wellType.wellheadDepthFromDrillFloor.toLocaleString("en-US")}${this._units.long}`
                }));
            }

            wellSchematicDrawingObjs.push(new HorizontalLine({
                units: this._units,
                verticalPosition: mudline,
                width: lineWidth,
                color: "brown",
                name: `Mudline\r ${mudline.toLocaleString("en-US")}${this._units.long}`
            }));

        } else { // Land wells
            wellSchematicDrawingObjs.push(new HorizontalLine(
                {
                    units: this._units,
                    verticalPosition: wellType.wellheadDepthFromDrillFloor,
                    width: lineWidth,
                    color: "black",
                    name: `Wellhead\r ${wellType.wellheadDepthFromDrillFloor.toLocaleString("en-US")}${this._units.long}`
                }
            ));
        }
    }

    private getPackersForSection(tubingId: string, packers: WellSchematicPacker[]) {
        const packersForTubular = packers.filter(p => p.tubularStringId == tubingId);
        return packersForTubular.map(p => {
            return { depth: p.measuredDepth, name: p.name }
        });
    }

    private calculateHorizontalOffset(outsideSectionOd: number, insideSectionOd: number, xCoordinate: number) {
        return xCoordinate + (outsideSectionOd - insideSectionOd) / 2;
    }

    private generateDrawPaths(
        labelToc: boolean,
        wc: WellComponent,
        packers: WellSchematicPacker[],
        outsideProfile: { priorShoeMd: number, wellboreSections: IPipeSection[] } = null): WellSchematicItemBase[] {

        let top = wc.hangerMd;
        const drawObjects = [];
        const packerInfo = this.getPackersForSection(wc.id.toString(), packers);

        for (let i = 0; i < wc.stringSections.length; i++) {
            const s = wc.stringSections[i];
            const stringSectionPackers = packerInfo?.filter(p =>
                (s.bottomMeasuredDepth >= p.depth) && (p.depth > (wc.stringSections[i - 1]?.bottomMeasuredDepth || wc.hangerMd)));

            const drawingParams = {
                tubularStringId: wc.id.toString(),
                outerPipeProfile: outsideProfile,
                packers: stringSectionPackers,
                openHole: wc.holeSize,
                outerDiameter: s.pipe.outsideDiameter,
                innerDiameter: s.pipe.insideDiameter,
                top: top,
                btm: s.bottomMeasuredDepth,
                hasFluid: Boolean(wc.annularFluidId),
                topOfFluid: this._topOfFluid,
                hasCement: wc.hasCement && (s.bottomMeasuredDepth >= wc.topOfCementMd),
                topOfCement: wc.topOfCementMd,
                labelToc: labelToc && (top <= wc.topOfCementMd && s.bottomMeasuredDepth >= wc.topOfCementMd),
                hasShoe: (wc.type.toLowerCase() != "tieback") && (wc.stringSections.length - 1 == i),
                name: this.labelSection(s, i == wc.stringSections.length - 1),
                units: this._units
            };

            // Reset top of fluid after it's been used. (Don't draw annular fluid on the outer casing above the mudline).
            if (this._topOfFluid !== 0) {
                this._topOfFluid = 0;
            }

            let drawItem: WellSchematicItemBase = null;
            const isCrossOver = wc.stringSections.length > 1 && i != wc.stringSections.length - 1;

            if (wc.type.toLowerCase() == "casing" || wc.type.toLowerCase() == "tieback") {
                drawItem = new Casing(drawingParams);
                drawItem.name = this.labelSection(s, isCrossOver);
            }

            if (wc.type.toLowerCase() == "liner") {
                drawItem = new Liner({ ...drawingParams, hasHanger: i == 0 });
                drawItem.name = this.labelSection(s, isCrossOver);
            }

            if (wc.type.toLowerCase() == "tubing") {
                drawItem = new Tubing(drawingParams);
                drawItem.name = this.labelSection(s, isCrossOver, drawingParams.hasShoe);
            }

            if (drawItem) {
                if (this._currentTubularId.toLowerCase() == wc.id.toString().toLowerCase()) {
                    drawItem.color = "red";
                    drawItem.labelColor = "red";
                }

                drawObjects.push(drawItem);
            }

            top = s.bottomMeasuredDepth;
        }

        return drawObjects;
    }

    private labelSection(s: Tubular, isCrossOver: boolean, hasShoe = true) {
        const type = hasShoe && !isCrossOver ? "Shoe" : isCrossOver ? "X/O" : "@";
        return `OD ${s.pipe.outsideDiameter}${this._units.short}\r${type} ${s.bottomMeasuredDepth.toLocaleString("en-US")}${this._units.long}`;
    }

    /* Perforations are drawn at the outside of the hole where each perforation is located*/
    private drawPerforations(
        perforations: PerforationModel[],
        tubulars: WellComponent[]
    ): WellSchematicItemBase[] {

        //Get boundary depths for the currently drawn tubulars
        const minDepth = Math.min(...tubulars.map(t => t.hangerMd));
        const maxDepth = Math.max(...tubulars.map(t => t.shoeMd));
        const perfsInRange = perforations.filter(p => p.measuredDepth >= minDepth && p.measuredDepth <= maxDepth);

        return perfsInRange.map(p => {
            const tubularsInRange = tubulars.filter(t => t.hangerMd <= p.measuredDepth && t.shoeMd >= p.measuredDepth);
            const largestOd = Math.max(...tubularsInRange.map(t => t.holeSize));
            const perfName = `${p.name} ${p.measuredDepth.toLocaleString("en-US")}${this._units.long}`;
            return new Perforation({ od: largestOd, top: p.measuredDepth, name: perfName, units: this._units });
        });
    }
}