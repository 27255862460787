import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { createForm, FormType, NGX_SUB_FORM_HANDLE_VALUE_CHANGES_RATE_STRATEGIES, subformComponentProviders } from 'ngx-sub-form';
import { Subject } from 'rxjs';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { ExpansionJoint } from 'src/app/wellbore-inputs/models/packers.model';

export interface ExpansionJointForm {
  sealBoreContactDiameter: number;
  isSheared: boolean;
  upwardLimit: number;
  downwardLimit: number;
}
@Component({
    selector: 'app-expansion-joint',
    templateUrl: './expansion-joint.component.html',
    providers: subformComponentProviders(ExpansionJointComponent),
    standalone: false
})

export class ExpansionJointComponent implements OnChanges {

  private hasExpansionJoint$ = new Subject<boolean>();
  @Input() set hasExpansionJoint(value: boolean | undefined) {
    this.hasExpansionJoint$.next(!!value);
  }

 
  @Input()
  packerDepth: boolean | undefined;

  @Input()
  public userUnits: UserUnitsModel | null | undefined;

  public grayoutUpwardLimit: boolean;
  public grayoutDownwardLimit: boolean;
  public upwardLimitChecked = true;
  public downwardLimitChecked = true;
  public disableNogoCheckboxes: boolean;

  private input$ = new Subject<ExpansionJoint | null | undefined>();
  @Input() set expansionJoint(expansionJoint: ExpansionJoint | undefined) {
    this.input$.next(expansionJoint);
  }

  @Output() expansionJointUpdate = new EventEmitter<ExpansionJoint>();

  public form = createForm<ExpansionJoint, ExpansionJointForm>(this, {
    formType: FormType.SUB,
    input$: this.input$,
    output$: this.expansionJointUpdate,
    formControls: {
      sealBoreContactDiameter: new FormControl(null, { updateOn: 'blur', validators: Validators.required }),
      isSheared: new FormControl(false, Validators.required),
      upwardLimit: new FormControl(null,  { updateOn: 'blur' }),
      downwardLimit: new FormControl(null, { updateOn: 'blur' })
    },
    toFormGroup: (obj: ExpansionJoint| null): ExpansionJointForm => {
      if (!obj) {
        return null;
      }

      if (!obj.upwardLimit || obj.upwardLimit === null || obj.upwardLimit?.toString().length === 0) {
        this.upwardLimitChecked = false;
      } else {
        this.upwardLimitChecked = true;
      }

      if (!obj.downwardLimit || obj.downwardLimit === null || obj.downwardLimit?.toString().length === 0) {
        this.downwardLimitChecked = false;
      } else {
        this.downwardLimitChecked = true;
      }

      return {
        sealBoreContactDiameter: obj.sealBoreContactDiameter,
        isSheared: obj.isSheared,
        upwardLimit: obj.upwardLimit,
        downwardLimit: obj.downwardLimit
      }
    },
    fromFormGroup: (formValue: ExpansionJointForm | null): ExpansionJoint => {
      return {
        sealBoreContactDiameter: formValue.sealBoreContactDiameter,
        isSheared: formValue.isSheared,
        upwardLimit: formValue.upwardLimit,
        downwardLimit: formValue.downwardLimit
      }
    }, handleEmissionRate: NGX_SUB_FORM_HANDLE_VALUE_CHANGES_RATE_STRATEGIES.debounce(1000)
  })

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.hasExpansionJoint) {
      if (!this.form.formGroup.controls['hasExpansionJoint']?.value) {
        this.hideExpansionJoint();
      }
      return;
    }
    if (!changes.hasExpansionJoint?.currentValue) {
        this.hideExpansionJoint();
    } else {
      this.form.formGroup.controls.sealBoreContactDiameter.enable({emitEvent: false});
      this.form.formGroup.controls.isSheared.enable({emitEvent: false});
      this.form.formGroup.controls.downwardLimit.enable({emitEvent: false});
      this.form.formGroup.controls.upwardLimit.enable({emitEvent: false});
      this.grayoutDownwardLimit = true;
      this.grayoutUpwardLimit = true;

      this.disableNogoCheckboxes = false;
    }

    if (!this.form.formGroup.controls.downwardLimit?.value || !changes.hasExpansionJoint?.currentValue) {
      this.form.formGroup.controls.downwardLimit.disable({emitEvent: false});
    } else {
      this.form.formGroup.controls.downwardLimit.enable({emitEvent: false});
    }

    if (!this.form.formGroup.controls.upwardLimit.value || !changes.hasExpansionJoint?.currentValue) {
      this.form.formGroup.controls.upwardLimit.disable({emitEvent: false});
    } else {
      this.form.formGroup.controls.upwardLimit.enable({emitEvent: false});
    }
  }

  private hideExpansionJoint() {
    this.form.formGroup.controls.sealBoreContactDiameter.disable({emitEvent: false});
        this.form.formGroup.controls.isSheared.disable({emitEvent: false});
        this.form.formGroup.controls.downwardLimit.disable({emitEvent: false});
        this.form.formGroup.controls.upwardLimit.disable({emitEvent: false});
        this.grayoutUpwardLimit = false;
        this.grayoutDownwardLimit = false;

        this.disableNogoCheckboxes = true;
  }

  public toggleDownwardLimit(event: any): void {
    if(event.checked){ 
      this.form.formGroup.controls.downwardLimit.enable({emitEvent: false});
      this.form.formGroup.controls.downwardLimit.setValue(this.form.formGroup.controls.downwardLimit.value ?? 30.0);
    } else {
      this.form.formGroup.controls.downwardLimit.disable({emitEvent: false});
      this.form.formGroup.controls.downwardLimit.setValue(null);   
    }
  }

  public toggleUpwardLimit(event: any): void {
    if(event.checked){    
      this.form.formGroup.controls.upwardLimit.enable({emitEvent: false});
      this.form.formGroup.controls.upwardLimit.setValue(this.form.formGroup.controls.upwardLimit.value ?? 30.0);
    } else {
      this.form.formGroup.controls.upwardLimit.disable({emitEvent: false});
      this.form.formGroup.controls.upwardLimit.setValue(null);
    }
  }
}
