import { Component, Input, OnDestroy, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { GradeCatalog } from '../models/grade-catalog.model';
import { GradesService } from '../shared/services/grades-catalog.service';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmationService } from 'primeng/api';
import { getControlErrors, isControlInvalid } from 'src/app/shared/services/validation-helpers';
import { catchError, debounce, map } from 'rxjs/operators';
import { forkJoin, Observable, Subscription, timer } from 'rxjs';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { GridItemResizedMessage } from 'src/app/shared/models/mediator-messages.model';
import { TemperatureDerationService } from '../shared/services/temperature-deration.service';
import { TemperatureDerationCatalog } from '../models/temperature-deration-catalog.model';
import { lastValueFrom } from 'rxjs';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { unitsLib } from 'src/app/core/services/unit-library';
import { saveAs } from 'file-saver-es';
import ExcelJS from "exceljs";
import { UserRoles } from 'src/app/core/components/user-admin-page/user-model';
import { Utilities } from 'src/app/core/services/utilities';
import { PeriforOnChangeMessages, SignalRService } from 'src/app/shared/services/signal-r.service';
import { GetSolidsDensityFromLbmFt, GetValueFromPsi } from 'src/app/perivis/shared/helpers/units.helper';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { AppNotificationService } from 'src/app/shared/services/app-notification.service';

@Component({
  selector: 'app-grades-catalog',
  templateUrl: './grades-catalog.component.html',
  styles: ``,
  providers: [DialogService, ConfirmationService],
  standalone: false
})
export class GradesCatalogComponent implements OnInit, OnDestroy, AfterViewInit {

  private _grades: GradeCatalog[];
  private _temperatureDerations: TemperatureDerationCatalog[];
  private _subscriptions: Subscription;
  private _defaultTempDeration: TemperatureDerationCatalog;

  public strengthCalculationOptions: { label: string, value: string }[];
  public columnDefs: any[];
  public isLoading: boolean;
  public gradesForm: FormGroup;
  public tableHeight: string;
  public pressureUnit: string;
  public thermalCondUnit: string;
  public solidsDensityUnit: string;
  public thermalExpCoeffUnit: string;
  public userRoles: UserRoles;
  public specificHeatCapacityUnit: string;
  public temperatureDerations: { label: string, value: TemperatureDerationCatalog }[] = [];
  @ViewChild("catalogTable") catalogTable;

  get gradesCatalog(): FormArray {
    return this.gradesForm.get("grades") as FormArray;
  }

  // Validation delegates
  public isControlInvalid: Function = isControlInvalid;
  public getControlErrors: Function = getControlErrors;

  // State
  @Input()
  public componentId: string;

  constructor(
    private _messenger: MediatorService,
    private _confirmationService: ConfirmationService,
    private _gradesCatalogService: GradesService,
    private _formBuilder: FormBuilder,
    private _temperatureDerationService: TemperatureDerationService,
    private _storeService: StoreService,
    private _signalRService: SignalRService,
    private _toaster: AppNotificationService
  ) {
    this.isLoading = true;
    this._subscriptions = new Subscription();

    this.strengthCalculationOptions = [
      { label: 'API', value: 'Api' },
      { label: 'Non-API', value: 'NonApi' }
    ];

    this.gradesForm = this._formBuilder.group({
      grades: this._formBuilder.array([]),
    });
  }

  async ngOnInit(): Promise<void> {
    this.userRoles = await this._storeService.get<UserRoles>(StorageKeys.ROLES);
    const uu = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);
    this.pressureUnit = uu.stressPressure;
    this.thermalCondUnit = uu.thermalConductivity;
    this.solidsDensityUnit = uu.solidsDensity;
    this.thermalExpCoeffUnit = unitsLib[uu.coefficientOfThermalExpansion].symbol;
    this.specificHeatCapacityUnit = unitsLib[uu.specificHeatCapacity].symbol;

    this.columnDefs = [
      { field: 'name', header: 'Name' },
      { field: 'minimumApiYieldStrength', header: `API Min Yield (${this.pressureUnit})` },
      { field: 'youngsModulus', header: `Young's Modulus (${this.pressureUnit})` },
      { field: 'poissonsRatio', header: 'Poisson\'s Ratio' },
      { field: 'thermalExpansionCoefficient', header: `Thermal Exp Coeff (${this.thermalExpCoeffUnit})` },
      { field: 'ultimateTensileStrength', header: `UTS (${this.pressureUnit})` },
      { field: 'density', header: `Density (${this.solidsDensityUnit})` },
      { field: 'specificHeatCapacity', header: `Specific Heat Capacity (${this.specificHeatCapacityUnit})` },
      { field: 'thermalConductivity', header: `Thermal Conductivity (${this.thermalCondUnit})` },
      { field: 'temperatureDeration', header: 'Temperature Deration' },
      { field: 'strengthCalculationOption', header: 'Strength Calculation' }
    ];

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));

    this.getGradesData(false);

    addEventListener("keydown", (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
      }
    });
  }

  private signalRfunc(data: any) {
    if (data.action == PeriforOnChangeMessages.REFRESH_TEMP_DERATION_CATALOG) {
      this._grades = [];
      this.getGradesData(true);
    }
  }

  ngAfterViewInit() {
    this._subscriptions.add(this._messenger.of(GridItemResizedMessage).subscribe((e) => {
      if (e.name == "Grades Catalog") {
        this.tableHeight = (e.itemHeight - 65) + 'px';
      }
    }));
  }

  private getGradesData(isCalledFromSignalR: boolean) {
    const sources: Observable<any>[] = [
      this._gradesCatalogService.getGrades() as Observable<GradeCatalog[]>,
      this._temperatureDerationService.getTemperatureDerations() as Observable<TemperatureDerationCatalog[]>
    ];

    forkJoin(sources).pipe(
      map(([grades, temperatureDerations]) => {
        this._grades = grades;
        this._temperatureDerations = temperatureDerations;
        this._defaultTempDeration = this._temperatureDerations.find(x => x.name == 'Default') ?? this._temperatureDerations[0];
        this.temperatureDerations = temperatureDerations.map(g => ({ label: g.name, value: g }));

        if (!isCalledFromSignalR) {
          this.populateFormData(this._grades);
        }

        this.isLoading = false;
      }),
      catchError(err => {
        this.isLoading = false;
        return err;
      })).subscribe();
  }

  public onAddGrade(idx?: number): void {
    if (this.gradesCatalog.invalid) {
      return;
    }
    this.gradesCatalog.insert(idx || 0, this.newGrade());
    this.catalogTable?.scrollTo({ top: 0, behavior: 'smooth' });
  }

  public onDeleteGrade(idx: number): void {
    const gradeId = this.gradesCatalog.controls[idx].get("id").value;
    const gradeName = this.gradesCatalog.controls[idx].get("name").value;
    if (gradeId.length < 36) {
      this.gradesCatalog.removeAt(idx);
    } else {
      this._confirmationService.confirm({
        message: `Are you sure that you want to delete the selected grade ${gradeName}?`,
        accept: async () => {
          if (gradeId.length > 24) { // it's been saved to the db, so delete through API
            await lastValueFrom(this._gradesCatalogService.deleteGrade(gradeId));
          }
          this.gradesCatalog.removeAt(idx);
        }
      });
    }
  }

  public showJson(data: any) {
    return JSON.stringify(data, null, '\t');
  }

  public getPlaceholderText(data: any) {
    if (data == null) {
      this.gradesCatalog.clear();
      this.getGradesData(false);
    }

    const tempDerationExists = this._temperatureDerations.findIndex(x =>
      x.name == data?.name &&
      JSON.stringify(data.temperatureDerationProfile) == JSON.stringify(x.temperatureDerationProfile)
    ) >= 0;

    if (data == null) {
      return 'Select';
    }

    return tempDerationExists ? data.name : data.name + ' (local only)';
  }

  private async handleSaveGradeRow(v: any, gradeRecord: FormGroup): Promise<void> {
    if (gradeRecord.valid && !this.isLoading) {
      const gradeName = v.name;
      const existingGrade = this._grades.find(x => x.name == gradeName && x.id != v.id);
      if (existingGrade) {
        this._toaster.showError(`Grade with name "${gradeName}" already exists.`);
        return;
      }
      if (v.id.length == 24) { // it's only on the client so save to db as a new record
        const newEntityId = await lastValueFrom(this._gradesCatalogService.addGrade(new GradeCatalog(v)));
        gradeRecord.get("id").patchValue(newEntityId, { emitEvent: false });
      } else {
        await lastValueFrom(this._gradesCatalogService.updateGrade(new GradeCatalog(v)));
      }
    }
  }

  private newGrade(): FormGroup {
    const gradeFg = new FormGroup({
      id: new FormControl(Utilities.generateFakeGuid()), // Setting a unique id here so that prime-ng p-table has a data key for the expand and collapse functionality.
      name: new FormControl("", { updateOn: 'blur', validators: [Validators.required] }),
      minimumApiYieldStrength: new FormControl("", { updateOn: 'blur', validators: [Validators.required] }),
      youngsModulus: new FormControl(GetValueFromPsi(30000000, this.pressureUnit), { updateOn: 'blur', validators: [Validators.required] }),
      poissonsRatio: new FormControl(0.3, { updateOn: 'blur', validators: [Validators.required] }),
      thermalExpansionCoefficient: new FormControl(this.thermalExpCoeffUnit == '1E-6/°F' ? 6.9 : 12.42, { updateOn: 'blur', validators: [Validators.required] }),
      ultimateTensileStrength: new FormControl("", { updateOn: 'blur', validators: [Validators.required] }),
      density: new FormControl(GetSolidsDensityFromLbmFt(490, this.solidsDensityUnit), { updateOn: 'blur', validators: [Validators.required] }),
      specificHeatCapacity: new FormControl(this.specificHeatCapacityUnit == 'BTU/lbm-°F' ? 0.11 : 460.54, { updateOn: 'blur', validators: [Validators.required] }),
      thermalConductivity: new FormControl(this.thermalCondUnit == 'BTU/h·ft·°F' ? 26.2 : 45.35, { updateOn: 'blur', validators: [Validators.required] }),
      temperatureDeration: new FormControl(this._defaultTempDeration, [Validators.required]),
      strengthCalculationOption: new FormControl("Api")
    });

    const debouncedValueChanges = gradeFg.valueChanges.pipe(debounce(() => timer(1000)));
    this._subscriptions.add(debouncedValueChanges.subscribe((v) => this.handleSaveGradeRow(v, gradeFg)));

    gradeFg.markAllAsTouched();

    return gradeFg;
  }

  private populateFormData(grades?: GradeCatalog[]): void {
    grades.forEach((data, idx) => {
      const gradeFg = this.newGrade();
      this.gradesCatalog.push(gradeFg);
      const expandedData = { ...data };
      this.gradesCatalog.controls[idx].setValue(expandedData, { emitEvent: false });
    });
  }

  public async exportExcel() : Promise<void> {
    const mappedData = this.gradesCatalog.controls.map(td => {
      const objWithHeaderKeys = {};
      this.columnDefs.forEach(cd => {
        const objHeaderKey = cd.header;
        const name = cd.field != 'temperatureDeration' ? td.value[cd.field] : td.value[cd.field].name;
        objWithHeaderKeys[objHeaderKey.replace(/["']/g, '"')] = name;
      });
      return objWithHeaderKeys;
    });

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Connections Catalog");

    worksheet.columns = Object.keys(mappedData[0]).map((key) => ({
      header: key,
      key: key,
      width: 45
    }));

    worksheet.addRows(mappedData);

    const buffer = await workbook.xlsx.writeBuffer();
    this.saveAsExcelFile(buffer, "Grades Catalog");
  }

  public saveAsExcelFile(buffer: any, fileName: string): void {
    const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    saveAs(data, fileName + '_export_' + EXCEL_EXTENSION);
  }

  public filterGrades(event: any) {
    const filterName = event['srcElement']['value'];
    let filtered: GradeCatalog[] = this._grades;
    if (filterName) {
      filtered = this._grades.filter((grade) => {
        return grade.name.toLowerCase().includes(filterName.toLowerCase());
      });
    }
    this.gradesCatalog.clear();
    this.populateFormData(filtered);
  }

  ngOnDestroy() {
    this._subscriptions?.unsubscribe();
    this.signalRfunc = null;
  }
}
