import { Component, Input, OnChanges } from '@angular/core';
import { Store } from '@ngneat/elf';

@Component({
    selector: 'app-single-load-plot',
    template: `
    <app-xy-line-plot
      [plotData]="plot.data"
      [xAxisTitle]="xAxisTitle"
      [yAxisTitle]="yAxisTitle"
      [plotName]="plotName"
      [downloadPlotName]="downloadPlotName"
      [plotTitle]="plotTitle"
      [componentHeight]="componentHeight"
      [xyLinePlotStore]="singleLoadPlotStore"
    ></app-xy-line-plot>
  `,
    standalone: false
})
export class SingleLoadPlotComponent implements OnChanges {
  @Input() yAxisTitle = '';
  @Input() xAxisTitle = '';
  @Input() columnDefs: any[];
  @Input() loadCaseResultField = '';
  @Input() data: any[];
  @Input() selectedDepthView = 'md';
  @Input() depthUnit = 'ft';
  @Input() plotName = '';
  @Input() plotTitle = '';
  @Input() toolbarHeight = 0;
  @Input() componentHeight: number;
  @Input() singleLoadPlotStore: Store;

  configMissing: boolean;

  public downloadPlotName = 'load_results_plot';
  public plot = {
    data: [],
    layout: {
      xaxis: {
        autorange: true
      },
      yaxis: {
        autorange: 'reversed'
      }
    },
    config: {
      scrollZoom: false
    }
  };

  ngOnChanges(): void {
    this.parseResults(this.data);
    this.resetAxes();
  }

  private resetAxes(): void {
    this.plot.layout.xaxis.autorange = true;
    this.plot.layout.yaxis.autorange = 'reversed';
  }

  private parseResults(results: any[]) {
    if (results === undefined) { return; }
    this.plot.config.scrollZoom = this.plot ? true : false;
    const traceArray = [];

    this.columnDefs?.forEach((element => {
      const isDesignFactor = element.header.slice(-2) === 'DF' || element.header.slice(-6) === 'Rating';
      traceArray.push(
        {
          name: element.header,
          x: results.map(r => this.getValue(r[element.field])),
          y: this.selectedDepthView === 'md' ? results.map(r => r.measuredDepth) : results.map(r => r.trueVerticalDepth),
          mode: 'lines',
          line: {
            dash: isDesignFactor ? 'dot' : 'solid',
            width: isDesignFactor ? 3 : 1.5,
            color: isDesignFactor ? 'red' : element.color
          }
        });
    }));

    this.plot.data = traceArray;
    this.yAxisTitle = this.selectedDepthView === 'md' ? `MD (${this.depthUnit})` : `TVD (${this.depthUnit})`;
  }

  private getValue(e: string) {
    if (e?.toString().slice(- 1) == '*') {
      return +e.substring(0, e.length - 2);
    }

    return e;
  }
}
