<div class="w-full h-full p-2">
  <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
  <app-tool-loader [isLoading]="isLoading" positionUnset="true">
    <form *ngIf="stressOptionsForm" [formGroup]="stressOptionsForm">

      <div class="w-full">
        <label for="maximumGridSpacing">Vertical Grid Spacing</label>
        <p-inputgroup class="mb-2">
          <p-inputnumber [minFractionDigits]="1" id="maximumGridSpacing" formControlName="maximumGridSpacing" onfocus="this.select()" />
          <p-inputgroup-addon>{{ verticalGridUnit }}</p-inputgroup-addon>
        </p-inputgroup>
      </div>

      <p *ngIf="stressOptionsForm.controls.maximumGridSpacing.invalid" class="validation-error mt-1">
        Allowed range: {{ this.gridSpacingValidation.min }} - {{ this.gridSpacingValidation.max }}
      </p>

      <label for="maximumGridSpacing">API TR 5C3 Collapse Formula</label>
      <p-select id="apiCollapseType" formControlName="apiCollapseType" [options]="apiCollapse" appendTo="body"
        class="w-full mb-2">
      </p-select>

      <div class="mb-2">
        <p-fieldset legend="Buckling Parameters">
          <label for="helicalFactorType">Critical Ratio for Transition to Helical Buckling:</label>
          <p-inputgroup class="mb-2">
            <p-select id="helicalFactorType" formControlName="helicalFactorType" [options]="helicalFactorTypes"
              appendTo="body"></p-select>
            <p-inputnumber [minFractionDigits]="1" id="helicalBucklingFactor" onfocus="this.select()" [attr.disabled]="true"
              formControlName="helicalBucklingFactor" />
          </p-inputgroup>
          <p *ngIf="stressOptionsForm.controls.helicalBucklingFactor.invalid" class="validation-error mt-1">
            Allowed range: 1 - 3
          </p>
          <p-checkbox binary="true" id="useHeKyllingstad" formControlName="useHeKyllingstad"></p-checkbox>
          <label class="ml-2"> He-Kyllingstad Buckling Dogleg Correlation</label>
        </p-fieldset>
      </div>

      <p-fieldset legend="Dynamic Friction Model">
        <!-- Friction Factor Section -->
        <div class="flex flex-col mb-2">
          <label for="frictionFactor">Friction Factor</label>
          <p-inputnumber [minFractionDigits]="1" id="frictionFactor" formControlName="frictionFactor" onfocus="this.select()" class="w-full">
          </p-inputnumber>
        </div>

        <!-- Damping Type Section -->
        <p-fieldset legend="Damping Type">
          <div class="flex">
            <input type="radio" binary="true" formControlName="dampingType"
              [checked]="stressOptionsForm.controls.dampingType.value === 'artificialDamping'"
              (click)="dampingTypeChange('artificialDamping')">
            <label class="ml-2">Artificial Damping</label>
          </div>
  
          <div class="flex">
            <input type="radio" binary="true" formControlName="dampingType"
            [checked]="stressOptionsForm.controls.dampingType.value === 'fluidDamping'"
            (click)="dampingTypeChange('fluidDamping')">
            <label class="ml-2">Fluid Damping</label>
          </div>
        </p-fieldset>
      </p-fieldset>
    </form>
  </app-tool-loader>
</p-scrollPanel>
</div>