import { Injectable } from '@angular/core';
import { catchError, Observable, of, shareReplay } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Packers } from '../models/packers.model';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`
@Injectable({
  providedIn: 'root'
})
export class PackerService {
  private packersCache$: Observable<Packers> | null = null;

  constructor(private http: HttpClient) { }

  public getPackersForTubular(): Observable<Packers> {
    if (!this.packersCache$) {
      this.packersCache$ = this.http
        .get<Packers>(`${PeriforApiEndpoint}/packers`)
        .pipe(
          catchError(() => of(null as Packers)),
          shareReplay(1)
        );
    }
    setTimeout(() => this.packersCache$ = null, 1000);
    return this.packersCache$;
  }

  updatePackers(packer: any): Observable<boolean> {
    return this.http.put<boolean>(`${PeriforApiEndpoint}/packers`, packer);
  }

}
