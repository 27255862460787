import { Injectable } from "@angular/core";
import { createStore, withProps, Store } from "@ngneat/elf";
import localForage from "localforage";
import { persistState } from '@ngneat/elf-persist-state';

@Injectable({
  providedIn: 'root'
})
export class StoreService {

  private stores: Record<string, Store<any>> = {};
  private preloadedState: Record<string, any> = {};

  constructor() {
    localForage.config({
      driver: localForage.INDEXEDDB,
      name: 'app-db',
      version: 1.0,
      storeName: 'appState'
    });
    // Preload all necessary data from IndexedDB
    this.preloadState();
  }

  private async preloadState(): Promise<void> {
    const keys = await localForage.keys();
    for (const key of keys) {
      this.preloadedState[key] = await localForage.getItem(key);
    }
  }

  createStore<T>(storeKey: string, initialState: T, ...features: any[]): Store<any> {
    if (!this.stores[storeKey]) {
      const persistedState = this.preloadedState[storeKey];
      const state = persistedState ? { ...initialState, ...persistedState } : initialState;
      const store = createStore(
        { name: storeKey },
        withProps<T>(state),
        ...features
      );
      this.persistItem(storeKey, store);
      this.stores[storeKey] = store;
    }
    return this.stores[storeKey];
  }

  // Get store by key
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  getStore<T>(storeKey: string): Store<any> {
    return this.stores[storeKey] as Store<any>;
  }

  // Update store by key
  updateStore<T>(storeKey: string, newState: Partial<T>) {
    const store = this.getStore<T>(storeKey);
    store.update(state => ({
      ...state,
      ...newState
    }));
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private persistItem<T>(storeKey: string, store: Store<any>): void {
    persistState(store, {
      key: storeKey,
      storage: localForage
    });
  }

  async get<T>(key: string): Promise<T | null> {
    try {
      return await localForage.getItem<T>(key);
    } catch (error) {
      console.error(`Error getting key "${key}" from localForage (IndexedDB):`, error);
      return null;
    }
  }

  async set<T>(key: string, value: T): Promise<boolean> {
    try {
      await localForage.setItem(key, value);
      return true;
    } catch (error) {
      console.error(`Error setting key "${key}" in localForage (IndexedDB):`, error);
      return false;
    }
  }

  async remove(key: string): Promise<boolean> {
    try {
      await localForage.removeItem(key);
      return true;
    } catch (error) {
      console.error(`Error removing key "${key}" from localForage (IndexedDB):`, error);
      return false;
    }
  }

}

export class StorageKeys {
  static DARK_MODE = "dark_mode";
  static DESIGN = "design";
  static TUBULAR_ID = "tubularId";
  static ROLES = "roles";
  static UNITS = "units";
  static USER = "user";
}
