import { Component, OnInit, Input, ViewChild, ElementRef, Renderer2, OnChanges } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { DashboardWorkspaceIdxModel } from 'src/app/core/components/dashboard/models/dashboard.model';
import { Output, EventEmitter } from '@angular/core';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { CloneWorkspacesDialogComponent } from '../clone-workspaces-dialog/clone-workspaces-dialog.component';
import { DashboardApiService } from '../services/dashboard-api.service';

@Component({
    selector: 'app-workspace-tabs',
    templateUrl: './workspace-tabs.component.html',
    providers: [DialogService],
    standalone: false
})
export class DashboardWorkspaceTabsComponent implements OnInit, OnChanges {

  private _workspaces: DashboardWorkspaceIdxModel[];
  private _selectedTabIndex: number;

  public activeWorkspaceTab:DashboardWorkspaceIdxModel;
  public selectedEditTab: DashboardWorkspaceIdxModel;
  public workspaceOptions: MenuItem[];
  public workspaceContextMenu: MenuItem[];
  public workspaceTabs: MenuItem[];
  public editFromContextMenu: boolean;
  
  ref: DynamicDialogRef | undefined;
  
  handleInput(event: KeyboardEvent): void{
    event.stopPropagation();
  } 
  
  @ViewChild('editNameBox') editNameBox: ElementRef;
  
  @Input()
  public set selectedTabIndex(value: number) {
    if (value) {
      this._selectedTabIndex = value;
    }
  }
  
  public get selectedTabIndex(): number {
    return this._selectedTabIndex;
  }
  
  @Input()
  public set workspaces(value: DashboardWorkspaceIdxModel[]) {
    if (value) {
      this._workspaces = value;
    }
  }
  
  public get workspaces(): DashboardWorkspaceIdxModel[] {
    return this._workspaces;
  }

  @Input()
  public isLoading: boolean;
  
  @Output()
  public workspaceInserted = new EventEmitter<{ idx: number }>();
  
  @Output()
  public workspaceDeleted = new EventEmitter<number>();

  @Output()
  public workspaceRenamed  = new EventEmitter<{ idx: number, name: string }>();

  @Output()
  public workspaceSelected = new EventEmitter<number>();

  @Output()
  public workspaceIdx = new EventEmitter<{ fromIdx: number, toIdx: number }>();

  @Output()
  public workspacesCloned = new EventEmitter<any>();

  constructor(
    private renderer: Renderer2,
    public _dialogService: DialogService,
    private _dashboardService: DashboardApiService
  ) {
    this.renderer.listen('window', 'mouseDown', (e: Event) => {
      if (e.target !== this.editNameBox?.nativeElement && !this.editFromContextMenu) {
        this.selectedEditTab = null;
      }
    });
  }

  ngOnInit() {
    this.workspaceOptions = [
      {
        id: "add",
        icon: 'pi pi-plus',
        command: () => {
          this.insertWorkspace(this.workspaces.length + 1);
        },
        tooltip: "Add New Workspace"
      },
      {
        id: "clone",
        icon: 'pi pi-clone',
        command: () => {
          this.ref = this._dialogService.open(CloneWorkspacesDialogComponent, {
            header: 'Clone Workspace',
            width: '400px'
          });
          this.ref.onClose.subscribe((dashboardId) => {
            if (dashboardId) {
              this._dashboardService.cloneDashboard(dashboardId).subscribe(() => {
                this.workspacesCloned.emit();
              });
            }
          });
        },
        tooltip: "Copy tabs and components from another design"
      }
    ];
  }

  ngOnChanges() {
    this.activeWorkspaceTab = this.workspaces?.find(wt => wt.index == this._selectedTabIndex);
  }

  public insertWorkspace(idx: number): void {
    this.workspaceInserted.emit({ idx });
    this.selectWorkspace(idx);
  }

  public deleteWorkspace(workspaceIndex: number): void {
    this.workspaceDeleted.emit(workspaceIndex);
    this.selectWorkspace(0);
  }

  public selectWorkspace(workspaceIndex: number): void {
    if (workspaceIndex == 0 || this._selectedTabIndex === workspaceIndex) {
      return;
    }
    this.activeWorkspaceTab = this.workspaces?.find(wt => wt.index == workspaceIndex);
    this.workspaceSelected.emit(workspaceIndex);
  }

  public renameWorkspace(workspaceIndex: string, dashboardName: string) {
    this.workspaceRenamed.emit({ idx: parseInt(workspaceIndex), name: dashboardName });
    this.workspaces.find(ws => ws.index === parseInt(workspaceIndex)).name = dashboardName;
    this.selectedEditTab = null;
  }

  public moveWorkspaceIdx(fromIdx: number, toIdx: number) {
    this.workspaceIdx.emit({ fromIdx: fromIdx, toIdx: toIdx });
  }

  public copyWorkspace(){

      this.ref = this._dialogService.open(CloneWorkspacesDialogComponent, {
        header: 'Clone Workspace',
        width: '400px'
      });

      this.ref.onClose.subscribe((dashboardId) => {
        if (dashboardId) {
          this._dashboardService.cloneDashboard(dashboardId).subscribe(() => {
            this.workspacesCloned.emit();
          });
        }
      });
    
  }

  public onContextMenu(e: any, workspace: DashboardWorkspaceIdxModel) {
    this.workspaceContextMenu = [
      {
        label: 'Delete Workspace', icon: 'pi pi-fw pi-trash', disabled: this.workspaces?.length === 1,
        command: () => { this.deleteWorkspace(workspace.index); }
      },
      {
        label: 'Rename Workspace', icon: 'pi pi-fw pi-pencil',
        command: () => { this.editWorkspace(workspace, true); }
      },
      {
        label: 'Insert Workspace', icon: 'pi pi-fw pi-arrow-up',
        command: () => {
          this.insertWorkspace(workspace.index);
        }
      },
      {
        label: 'Move Left', icon: 'pi pi-fw pi-arrow-left',
        command: () => {
          this.moveWorkspaceIdx(workspace.index, workspace.index - 1);
        }
      },
      {
        label: 'Move Right', icon: 'pi pi-fw pi-arrow-right',
        command: () => {
          this.moveWorkspaceIdx(workspace.index, workspace.index + 1);
        }
      }
    ] as MenuItem[];
  }

  public editWorkspace(ws: DashboardWorkspaceIdxModel, calledFromContextMenu: boolean) {
    this.editFromContextMenu = calledFromContextMenu;
    this.selectedEditTab = ws;
  }

}
