<div class="w-full h-full overflow-auto">
  <app-tool-loader positionUnset="true">    
    <div class="flex flex-col w-full h-full">
      <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
      <div class="flex flex-1 overflow-auto p-2 gap-2">
        <div class="flex-[0.35] min-w-[150px] flex-shrink">
          <p-listbox 
            [options]="loadCases" 
            [(ngModel)]="selectedLoad" 
            optionLabel="name" 
            (onChange)="onLoadSelect($event)"
            scrollHeight="400px">

            <ng-template pTemplate="header">
              <p-button class="outlined-button" size="small" severity="success" label="Add Load" outlined="true" (onClick)="addLoad()"></p-button>
            </ng-template>
            <ng-template let-load pTemplate="item">
              <div class="flex items-center justify-between w-full">
                <div class="text-left">
                  {{ load.name }}
                </div>
            
                <div class="flex items-center">
                  <i
                    class="pi pi-copy p-1 !text-cyan-400"
                    size="sm"
                    pTooltip="Clone Load"
                    (click)="cloneLoad(load)">
                  </i>
                  
                  <i
                    class="pi pi-trash !text-red-400" 
                    size="sm" 
                    (click)="deleteLoad(load)">
                  </i>
                </div>

              </div>
            </ng-template>
          </p-listbox>
        </div>

        <div class="flex-[0.65] overflow-auto">
          <app-pre-install-data 
            [disabled]="loadCases?.length === 0" 
            [preInstallLoad]="selectedLoadDetails$ | async"
            (preInstallLoadUpdated)="updateOrCreateLoad($event)" [userUnits]="userUnits" [isCemented]="isCemented"
            [shoeMd]="shoeDepth" [hangerMd]="hangerMd" [annularFLuidDensity]="annularFLuidDensity">
          </app-pre-install-data>
        </div>
      </div>
    </div>
  </app-tool-loader>
</div>