<div class="w-full h-full p-2">
  <ng-container *ngIf="udtForm.controls.isConstantGradient.value; else noScrollPanel">
    <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </p-scrollPanel>
  </ng-container>

  <ng-template #noScrollPanel>
    <div class="w-full h-full">
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </div>
  </ng-template>

  <ng-template #content>
    <label class="text-sm mb-4">Total Depth: {{ totalDepthLabel }}</label>

    <div class="flex flex-col mt-2">
      <label>Specify UDT as</label>
      <p-selectButton [options]="udtTypeOptions" [formControl]="udtForm.controls.isConstantGradient"
        (onChange)="changeUdtType()" [allowEmpty]="false">
      </p-selectButton>
    </div>

    <form [formGroup]="udtForm">
      <div [style.display]="!isResetting && udtForm.controls.isConstantGradient.value ? 'block' : 'none'">
        <div formGroupName="constantGradient">
          <div class="flex flex-col space-y-2">
            <div class="flex flex-col mt-2">
              <label for="surfaceAmbientTemp">Surface Temperature</label>
              <p-inputgroup>
                <p-inputNumber [minFractionDigits]="1" id="surfaceAmbientTemp"
                  formControlName="surfaceAmbientTemperature" onfocus="this.select()">
                </p-inputNumber>
                <p-inputgroup-addon>{{ tempUnit }}</p-inputgroup-addon>
              </p-inputgroup>
              <p *ngIf="constGrad.controls.surfaceAmbientTemperature.invalid && !isLoading" class="validation-error">
                Allowed range: {{ surfaceAndMudlineValidation.min }} - {{ surfaceAndMudlineValidation.max }} {{ tempUnit
                }}
              </p>
            </div>

            <p-fieldset *ngIf="isOffshore" legend="Offshore">
              <div class="flex flex-col">
                <label for="mudlineTemperature">Mudline Temperature</label>
                <p-inputgroup>
                  <p-inputNumber [minFractionDigits]="1" id="mudlineTemperature" formControlName="mudlineTemperature"
                    onfocus="this.select()" />
                  <p-inputgroup-addon>{{ tempUnit }}</p-inputgroup-addon>
                </p-inputgroup>
                <p *ngIf="constGrad.controls.mudlineTemperature.invalid && !isLoading" class="validation-error">
                  Allowed range: {{ surfaceAndMudlineValidation.min }} - {{ surfaceAndMudlineValidation.max }} {{
                  tempUnit }}
                </p>
              </div>
              <label class="pt-2">Mudline Depth: {{ mudlineDepth }} {{ depthUnit }}</label>
            </p-fieldset>

            <div class="flex flex-col">
              <label for="temperatureGradient">Gradient</label>
              <p-inputgroup>
                <p-inputNumber [minFractionDigits]="1" id="temperatureGradient" formControlName="temperatureGradient"
                  onfocus="this.select()" />
                <p-inputgroup-addon>{{ gradientUnit }}</p-inputgroup-addon>
              </p-inputgroup>
              <p *ngIf="constGrad.controls.temperatureGradient.invalid && !isLoading" class="validation-error">
                Allowed range: 0 - 6.33 {{ gradientUnit }}
              </p>
            </div>
          </div>
        </div>

        <div class="flex flex-col mt-2">
          <label for="temperatureGradient">Temperature at TD</label>
          <p-inputgroup>
            <p-inputNumber [minFractionDigits]="1" id="temperatureAtTd" formControlName="temperatureAtTd"
              onfocus="this.select()" />
            <p-inputgroup-addon>{{ tempUnit }}</p-inputgroup-addon>
          </p-inputgroup>
          <p *ngIf="udtForm.controls.temperatureAtTd.invalid && !isLoading" class="validation-error">
            Allowed range: {{ tempAtTdValidaton.min }} - {{ tempAtTdValidaton.max }} {{ tempUnit }}
          </p>
        </div>

        <hr class="mt-2" style="height: 2px" />

        <form [formGroup]="interpolatedTempForm" bg-dark>
          <label>Temperature Interpolation:</label>
          <div class="flex flex-col">
            <label for="interpolationDepth">Depth</label>
            <p-inputgroup>
              <p-inputNumber class="w-1/2" [minFractionDigits]="1" placeholder="{{ interpDepth.toFixed(2) ?? 0 }}"
                formControlName="interpolationDepth" onfocus="this.select()" />
              <p-inputgroup-addon>
                <p-select id="depthTypeSelected" [options]="depthTypes" optionLabel="name" optionValue="value"
                  (onChange)="updateInterpDepth($event)" appendTo="body" formControlName="interpolationDepthType">
                </p-select>
                <p-inputNumber [minFractionDigits]="1" [ngModel]="interpolatedTemp" [suffix]="tempUnit" disabled="true"
                  [ngModelOptions]="{ standalone: true }"></p-inputNumber>
                <p-button size="small" outlined="true" severity="success" icon="pi pi-copy" title="Copy"
                  (onClick)="copyTemperature()"></p-button>
              </p-inputgroup-addon>
            </p-inputgroup>
          </div>
        </form>
      </div>

      <div class="mb-2 pt-2"
        [style.display]="isResetting || !udtForm.controls.isConstantGradient.value ? 'block' : 'none'">
        <app-ng-table-grid tableName="UDT Profile" parentComponentName="UDT" [btmMargin]="115"
          [tableData]="customPoints" [columnDefinitions]="columnDefinitions" [newRowFormGroup]="newDataRow"
          [inputFields]="inputFields" [isDynamicLoaded]="true" (dataChange)="customPointsChange($event)" />

      </div>
    </form>
  </ng-template>
</div>