<form [formGroup]="form.formGroup">
  <p-panel>

    <ng-template pTemplate="header">
      <div class="flex items-center m-2">
        <label class="mr-2">Temperature:</label>
        <p-select
        [disabled]="isReadOnly"
        [options]="temps"
        [formControlName]="form.formControlNames.type"
        ></p-select>
      </div>
    </ng-template>

    <div [ngSwitch]="form.formGroup.value.type" class="bg-[var(--p-content-background)] p-2">
      <app-bhct-temp-profile
        *ngSwitchCase="TemperatureProfileType.BHCT"
        [formControlName]="form.formControlNames.bhctTempProfile"
        [currentString]="currentString"
        [loadName]="loadName"
        [userUnits]="userUnits"
      ></app-bhct-temp-profile>
      <app-perical-link-temp-profile
        [isReadOnly]="isReadOnly"
        *ngSwitchCase="TemperatureProfileType.THERMALLINK"
        [formControlName]="form.formControlNames.pericalLinkTempProfile"
        [thermalOperations]="thermalOperations"
      ></app-perical-link-temp-profile>
      <app-undisturbed-temp-profile
        *ngSwitchCase="TemperatureProfileType.UNDISTURBED"
        [formControlName]="form.formControlNames.undisturbedTempProfile"
      ></app-undisturbed-temp-profile>
      <app-custom-temp-profile
        *ngSwitchCase="TemperatureProfileType.CUSTOMTEMP"
        [formControlName]="form.formControlNames.customTempProfile"
        [wellType]="wellType"
        [currentString]="currentString"
        [shoeDepth]="shoeDepth"
        [userUnits]="userUnits"
        [wellTd]="wellTd"
      ></app-custom-temp-profile>
    </div>

  </p-panel>
</form>
