import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { PipesCatalog } from 'src/app/catalogs/models/pipes-catalog.model';

const PeriforApiEndpoint_PipesCatalog = `${environment.PeriforEndpoint}/api/pipesCatalog`;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

export class PipesUpdatedMessage {
  constructor(public pipesCatalog: PipesCatalog) {}
}

@Injectable({
  providedIn: 'root'
})
export class PipesService {
  private pipeOdsCache$: Observable<any> | null = null;
  private pipesCache$: Record<string, Observable<PipesCatalog[]>> = {};

  constructor(private http: HttpClient) { }

  public getPipeOds(): Observable<any> {
    if (!this.pipeOdsCache$) {
      this.pipeOdsCache$ = this.http
        .get<any>(`${PeriforApiEndpoint_PipesCatalog}/ods`)
        .pipe(
          catchError(() => of(null)),
          shareReplay(1)
        );
    }
    setTimeout(() => this.pipeOdsCache$ = null, 1000);
    return this.pipeOdsCache$;
  }

  public getPipes(od = ""): Observable<PipesCatalog[]> {
    const cacheKey = od || 'default';
    if (!this.pipesCache$[cacheKey]) {
      this.pipesCache$[cacheKey] = this.http
        .get<PipesCatalog[]>(`${PeriforApiEndpoint_PipesCatalog}?${od}`)
        .pipe(
          map(data => data.map(item => new PipesCatalog(item))),
          catchError(() => of([])),
          shareReplay(1)
        );
    }
    setTimeout(() => this.pipesCache$[cacheKey] = null, 1000);
    return this.pipesCache$[cacheKey];
  }

  addPipe(model: PipesCatalog): Observable<{id: string}> {
    return this.http.post<{id: string}>(`${PeriforApiEndpoint_PipesCatalog}`, model);
  }

  updatePipe(model: PipesCatalog): Observable<boolean> {
    return this.http.put<boolean>(`${PeriforApiEndpoint_PipesCatalog}/${model.id}`, model, httpOptions);
  }

  deletePipe(id: string): Observable<boolean> {
    return this.http.delete<boolean>(`${PeriforApiEndpoint_PipesCatalog}/${id}`);
  }

}
