import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api/selectitem';
import { PeriforOnChangeMessages, SignalRService } from 'src/app/shared/services/signal-r.service';
import { ApbResult, ApbResultsUi } from '../../models/apb.model';
import { ApbService } from '../../services/apb.service';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { GridItemResizedMessage } from 'src/app/shared/models/mediator-messages.model';
import { Subscription } from 'rxjs';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { Store } from '@ngneat/elf';
import { withUIEntities } from '@ngneat/elf-entities';
import { XyLinePlotUi } from 'src/app/core/models/xy-line-plot.model';

@Component({
    selector: 'app-apb-results',
    templateUrl: './apb-results.component.html',
    styles: [``],
    standalone: false
})
export class ApbResultsComponent implements OnInit, OnDestroy {

  constructor(
    private _apbService: ApbService,
    private _signalRService: SignalRService,
    private _messenger: MediatorService,
    private _storeService: StoreService
  ) {
    this._subscriptions = new Subscription();
    this._subscriptions.add(this._messenger.of(GridItemResizedMessage).subscribe((e) => {
      if (e.name.includes("APB Results")) {
        this.tableHeight = (e.itemHeight - 90) + 'px';
      }
    }));
  }

  public resultsDisplay: SelectItem[] = [
    { label: 'Grid', value: 'grid' },
    { label: 'Plot', value: 'plot' }
  ];
  public apbAfe: SelectItem[] = [
    { label: 'APB', value: 'apb' },
    { label: 'AFE', value: 'afe' }
  ];
  public apbResults: ApbResult[];
  public cols: any[] = [];
  public isLoading = true;
  public tableHeight: string;
  public needToCalculate: boolean;

  private userUnits: UserUnitsModel;
  private _subscriptions: Subscription;

  public xAxisTitle = '';
  public yAxisTitle = '';
  public plotName: string;
  public downloadPlotName = 'apb_results_plot';
  public plot = {
    data: [],
    layout: {},
    config: {}
  };

    //State Management
    private _componentId: string;
    @Input() set componentId(value: string) {
      this._componentId = value;
      this.apbResultsStore = this._storeService.createStore(this.componentId, new ApbResultsUi, withUIEntities<XyLinePlotUi>());
    }
    get componentId(): string {
      return this._componentId;
    }
    public apbResultsStore: Store;

  async ngOnInit(): Promise<void> {
    this.isLoading = true;

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));

    this.userUnits = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);

    this.cols = [
      {
        field: 'annulusName',
        header: 'Annulus Name',
        valueFormatter: (params: any) => params
      },
      {
        field: 'pressureBuildUp',
        header: `Annular Pressure Build-Up (${this.userUnits.pressure})`,
        valueFormatter: (params: any) => this.formatDecimal(params)
      },
      {
        field: 'fluidExpansionVolume',
        header: `Fluid Expansion Volume (${this.userUnits.volume})`,
        valueFormatter: (params: any) => this.formatDecimal(params)
      }
    ];

    this.plotName = this.apbResultsStore.state.apbAfeSelected + 'ResultsPlot';

    this.getApbResults();
  }

  private signalRfunc(data: any) {
    if (data.action === PeriforOnChangeMessages.REFRESH_APB_RESULTS) {
      this.getApbResults();
    }
  }

  public getApbResults() {
    this.isLoading = true;
    this.needToCalculate = false;
    this._apbService.getApbResults().subscribe({
      next: (results) => {
      this.apbResults = results;

      if (!results || results.length === 0) {
        this.isLoading = false;
        this.needToCalculate = true;
        return;
      }

      const traceArray = {
        x: [],
        y: [],
        type: "bar",
        width: 0.55,
      };

      results.forEach((result) => {
        traceArray.x.push(result.annulusName);
        traceArray.y.push(this.apbResultsStore.state.apbAfeSelected == 'apb' ? result.pressureBuildUp : result.fluidExpansionVolume);
      });

      this.yAxisTitle = this.apbResultsStore.state.apbAfeSelected == 'apb' ? `Annular Pressure Build-Up (${this.userUnits.pressure})` : `Fluid Expansion Volume (${this.userUnits.volume})`;

      this.plot.data = [traceArray];

      this.isLoading = false;
    },
    error: () => {
      this.isLoading = false;
      return;
    }});
  }

  onSelectedResultsDisplayUpdated(e): void {
    this.apbResultsStore.update(state => ({...state, selectedResultsDisplay: e.value}));
  }

  onApbAfeSelected(e): void {
    this.apbResultsStore.update(state => ({...state, apbAfeSelected: e.value}));
    this.plotName = this.apbResultsStore.state.apbAfeSelected + 'ResultsPlot';
    this.getApbResults();
  }

  formatDecimal(value: any) {
    return value || value === 0
      ? (Math.trunc(value * 100) / 100).toLocaleString('en-US')
      : null;
  }

  ngOnDestroy() {
    this._subscriptions?.unsubscribe();
    this.signalRfunc = null;
  }
}
