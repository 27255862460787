import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngneat/elf';
import { FormType, createForm, NGX_SUB_FORM_HANDLE_VALUE_CHANGES_RATE_STRATEGIES } from 'ngx-sub-form';
import { Subject, lastValueFrom } from 'rxjs';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { unitsLib } from 'src/app/core/services/unit-library';
import { PeriforOnChangeMessages, SignalRService } from 'src/app/shared/services/signal-r.service';
import { Packer, PackerEnvelope, Packers } from 'src/app/wellbore-inputs/models/packers.model';
import { PackerService } from 'src/app/wellbore-inputs/services/packers.service';

@Component({
  selector: 'app-packer-data',
  templateUrl: './packer-data.component.html',
  standalone: false
})
export class PackerDataComponent implements OnInit, OnDestroy, OnChanges {

  @Input()
  public userUnits: UserUnitsModel | null | undefined;

  @Input()
  public packersStore: Store;

  @Input()
  public componentHeight: number;

  public packerTypes: { label: string, value: string }[];
  public packerData: Packers;
  public packerMinDepth: number;
  public packerDefaultDepth: number;
  public isMechanicalPacker: boolean;
  public tableHeight: string;
  public packerEnvelope: PackerEnvelope[];
  public forceUnit: string;
  public pressureUnit: string;
  public selectedResultDisplay: boolean;
  public plot = {
    data: []
  };
  public xAxisTitle = '';
  public yAxisTitle = '';
  public plotName = 'packerOperatingEnvelope';
  public downloadPlotName = 'packer_operating_envelope';

  public get columnDefinitions(): { field: string, header: string }[] {
    return [
      { field: 'force', header: `Force (${ this.userUnits.force })` },
      { field: 'differentialPressure', header: `Differential Pressure (${unitsLib[this.userUnits.pressure].symbol})` }
    ];
  }

  public inputFields = [
    { name: 'force', minFractions: 2, maxFractions: 6, formatDecimals: 3 },
    { name: 'differentialPressure', minFractions: 2, maxFractions: 6, formatDecimals: 3 }
  ];

  public newDataRow() : UntypedFormGroup {
    return new UntypedFormGroup({
      force: new UntypedFormControl(0, [Validators.required]),
      differentialPressure: new UntypedFormControl(null, [Validators.required])
    });
  }

  constructor(
    private _packerService: PackerService,
    private _signalRService: SignalRService
  ) {
    this.packerTypes = [
      { label: "Mechanical", value: "Mechanical" },
      { label: "Hydraulic", value: "Hydraulic" },
      { label: "Hydrostatic", value: "Hydrostatic" }
    ];
  }

  private input$ = new Subject<Packer | null | undefined>();
  @Input() set packer(packer: Packer | undefined) {
    this.input$.next(packer);
  }

  private disabled$ = new Subject<boolean>();
  @Input() set disabled(value: boolean | undefined) {
    this.disabled$.next(!!value);
  }

  @Output()
  packerUpdate = new EventEmitter<Packer>();

  ngOnChanges(e: any): void {
    if (e.packer?.currentValue?.packerEnvelope) {
      this.packerEnvelope = e.packer?.currentValue?.packerEnvelope || [this.newDataRow() as any];
    }
    this.plotEnvelope();
    this.tableHeight = this.componentHeight + 'px';
  }

  private plotEnvelope() {
    if (Array.isArray(this.packerEnvelope) && this.packerEnvelope.length) {
      this.plot.data = [{
        x: this.packerEnvelope.map(x => x.differentialPressure),
        y: this.packerEnvelope.map(x => x.force),
      }];
    }
  }

  public form = createForm<Packer>(this, {
    formType: FormType.ROOT,
    disabled$: this.disabled$,
    input$: this.input$,
    output$: this.packerUpdate,
    formControls: {
      name: new FormControl('', { updateOn: 'blur', validators: [Validators.required] }),
      type: new FormControl(null, [Validators.required]),
      measuredDepth: new FormControl(null, { updateOn: 'blur', validators: [Validators.required] }),
      initialSetPressure: new FormControl(null, { updateOn: 'blur', validators: [Validators.required] }),
      plugDepth: new FormControl(null, { updateOn: 'blur', validators: [Validators.required] }),
      pickupForce: new FormControl(null, { updateOn: 'blur', validators: [Validators.required] }),
      hasExpansionJoint: new FormControl(null, [Validators.required]),
      expansionJoint: new FormControl(null, [Validators.required]),
      isPressureBarrier: new FormControl(null, [Validators.required]),
      packerEnvelope: new FormControl(null, [Validators.required]),
      sort: new FormControl('', [Validators.required])
    },
    fromFormGroup: (formValue: Packer | null): Packer => {
      const { ...commonValues } = formValue;

      if (formValue.type == 'Hydraulic') {
        this.isMechanicalPacker = false;
      }

      return {
        ...commonValues
      }
    },
    handleEmissionRate: NGX_SUB_FORM_HANDLE_VALUE_CHANGES_RATE_STRATEGIES.debounce(1000)
  })

  ngOnInit(): void {
    this.forceUnit = this.userUnits.force;
    this.pressureUnit = unitsLib[this.userUnits.pressure].symbol;

    this.yAxisTitle = `Axial Force +Up [Tension] / -Down [Compression] (${this.forceUnit})`;
    this.xAxisTitle = `Differential Pressure +Up/-Down (${this.pressureUnit})`;

    this.validatePackers();

    if (!this.packerData) {
      this.isMechanicalPacker = true;
    }

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));
  }

  signalRfunc(data: { action: string, designId: string }) {
    if (data.action == PeriforOnChangeMessages.REFRESH_STRING_INPUTS || data.action === PeriforOnChangeMessages.REFRESH_TUBULARS_LIST) {
      this.validatePackers();
    }
  }

  public tablePlotToggle(event: any) {
    this.selectedResultDisplay = event.checked;
  }

  private async validatePackers() {
    this.packerData = await lastValueFrom(this._packerService.getPackersForTubular());
    this.packerMinDepth = +Math.max(this.packerData.hangerMd + 0.1, this.packerData.mudlineDepth).toFixed(2);
    this.packerDefaultDepth = Math.min(this.packerData.shoeMd, this.packerData.topOfCementMd);

    this.form.formGroup.controls.measuredDepth.setValidators([Validators.min(this.packerMinDepth), Validators.max(this.packerDefaultDepth)]);
    this.form.formGroup.controls.plugDepth.setValidators([Validators.max(this.packerDefaultDepth)]);
  }

  public onPackerEnvelopeChange(v: { dataRows: PackerEnvelope[], reload: boolean }) {
    const valueToSave: PackerEnvelope[] = v.dataRows.length == 0 ? [{ force: 0, differentialPressure: 0 }] : v.dataRows;
    this.form.formGroup.value['packerEnvelope'] = valueToSave;
    this.packerUpdate.emit(this.form.formGroup.value);
  }

  public typeChange() {
    if (this.form.formGroup.controls.plugDepth.value == 0) {
      this.form.formGroup.controls.plugDepth.setValue(this.form.formGroup.controls.measuredDepth.value);
    }
  }

  ngOnDestroy() {
    this.signalRfunc = null;
  }

}
