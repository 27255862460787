export class PipesCatalog {
  public constructor(init?: Partial<PipesCatalog>) {
    Object.assign(this, init);
  }

  id: string;
  driftDiameter: number;
  insideDiameter: number;
  outsideDiameter: number;
  wallThickness: number;
  weightPerFoot: number;
  discriminator: string;
}

export class PipesCatalogUi {
  public constructor(init?: Partial<PipesCatalogUi>) {
    this.selectedOd = init && init.selectedOd || null;
    this.selectedType = init && init.selectedType || 'tubularPipe';
  }
  selectedOd: string;
  selectedType: string;
}
