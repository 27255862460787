import { Component } from '@angular/core';

@Component({
    selector: 'app-no-current-design',
    template: `
    <div class="min-h-screen flex items-center justify-center bg-cover bg-center">
        <div [style.background-color]="'var(--p-toolbar-background)'"
            class="max-w-lg bg-gray-800 dark:bg-gray-100 text-gray-500 dark:text-gray-900 shadow-lg p-8 bg-[var(--p-surface-900)]">
            <div class="flex flex-col items-center">
            <img class="w-60 h-auto mb-6" src="../../assets/images/Perifor-with-Well-Circle.png" alt="Logo" />
            <span class="text-2xl mb-3">
                <i class="pi pi-arrow-left"></i>
            </span> 
            <h1 class="text-2xl text-center font-semibold">
                Open a design from the <strong>WELL EXPLORER</strong> to get started
            </h1>
            </div>
        </div>
        </div>`,
    standalone: false
})
export class NoCurrentDesignComponent {}
