<div #workspaceSelect class="w-full">
  <!-- class="flex items-center w-full" -->
  <p-toolbar>
    <ng-template #start>
      <p-tabs [value]="selectedTabIndex">
        <p-tablist>
          @for(ws of workspaces; track ws.index){
          <p-tab [value]="ws.index" (click)="selectWorkspace(ws.index)">
            <div class="text-xs p-1" (contextmenu)="!isLoading && onContextMenu($event, ws)"
              *ngIf="ws?.index !== selectedEditTab?.index" (dblclick)="!isLoading && editWorkspace(ws, false)">
              {{ ws.name }}
            </div>

            <input #editNameBox (keydown)="handleInput($event)" type="text" pInputText [(ngModel)]="ws.name"
              *ngIf="ws?.index === selectedEditTab?.index" (blur)="renameWorkspace(ws?.index, ws?.name)"
              (keyup.enter)="renameWorkspace(ws?.index, ws?.name)"
              [ngClass]="{ menuItem: ws?.index === selectedEditTab.index }" [disabled]="isLoading" />
          </p-tab>
          }
        </p-tablist>
      </p-tabs>

      <div class="ml-1 flex w-full space-x-1">
        <p-button outlined="true" size="small" icon="pi pi-plus" pTooltip="Add New Workspace"
          (onClick)="insertWorkspace(workspaces.length + 1)">
        </p-button>
        <p-button outlined="true" size="small" icon="pi pi-clone" pTooltip="Copy tabs and components from another design"
          (onClick)="copyWorkspace()">
        </p-button>
      </div>
    </ng-template>
  </p-toolbar>
</div>

<p-contextMenu [target]="workspaceSelect" [model]="workspaceContextMenu" appendTo="body"></p-contextMenu>