import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { Store } from '@ngneat/elf';
import { SelectItem } from 'primeng/api';

@Component({
    selector: 'app-single-load-results',
    template: `
    <p-select
      [options]="loadCases"
      [(ngModel)]="stateStore.state.selectedLoadCase"
      optionLabel="label"
      (onChange)="onLoadCaseSelected($event)"
      appendTo="body"
    ></p-select>
   &nbsp;
    <span *ngIf="isAxialForce" style="">
      <i title="Forces with bending. Results for forces without bending are accessible in Forces/Displacements results" class="pi pi-info-circle"></i>
    </span>
  `,
    standalone: false
})
export class SingleLoadResultsComponent implements OnChanges {

  @Input() isAxialForce: boolean;
  @Input() loadCases: SelectItem[] = [];
  @Input() signalRChange: boolean;
  @Input() stateStore: Store;

  @Output() loadCaseSelected = new EventEmitter<Event>();

  ngOnChanges(): void {
    if (!this.signalRChange && !this.stateStore.state?.selectedLoadCase) {
      this.stateStore.update(state => ({...state, selectedLoadCase: this.loadCases[0]}));
    }
  }

  onLoadCaseSelected(event: Event): void {
    this.loadCaseSelected.emit(event);
  }
}
