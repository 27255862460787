import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TreeNode } from 'primeng/api';
import { DashboardItemAddedMessage, GridItemResizedMessage } from 'src/app/shared/models/mediator-messages.model';
import { MediatorService } from 'src/app/shared/services/mediator.service';

@Component({
    selector: 'app-sidebar-menu-group',
    template: `
    <div class="sidebar-menu-group">
      <p-card>
        <p-toolbar>
          <ng-template #start>
            <h4 class="pl-2">
              <span *ngIf="!solidColorLabel" class="peri-txt">PERI</span> 
              <span [style.color]="labelColor">{{ suffixLabel }}</span>
              <span [style.color]="labelColor">{{ solidColorLabel }}</span>
            </h4>
          </ng-template>
          <ng-template #end>
            <p-button class="pr-1" size="small" outlined="true" icon="pi pi-angle-double-left" (onClick)="collapseSubMenu()"></p-button>
          </ng-template>
        </p-toolbar>

        <p-tree [scrollHeight]="scrollHeight" [value]="menuItems">
          <ng-template pTemplate="group" let-node>
            <div class="ml-2 text-xs font-semibold">
              <b><u>{{ node.label }}</u></b>
            </div>
          </ng-template>
          <ng-template pTemplate="default" let-node>
            <div class="flex items-center gap-x-2 p-2">
              <img class="w-4 h-4" [src]="node.data.icon" alt="icon" />
              <span 
                class="text-xs font-medium hover:text-[#5c6bc0] hover:cursor-pointer" 
                (click)="onClick(node.data.identifier)">
                {{ node.data.label }}
              </span>
            </div>
          </ng-template>
        </p-tree>
      </p-card>
    </div>
  `,
    styles: [`
    .sidebar-menu-group {
      width: 260px;
    }
    .peri-txt {
      color: #5C6BC0;
    }
  `],
    standalone: false
})
export class SidebarMenuGroupComponent {
  constructor(private _mediator: MediatorService) {
    this.closed = new EventEmitter();
    this._mediator.of(GridItemResizedMessage).subscribe(() => {
      this.scrollHeight = (window.innerHeight - 85) + "px";
    });
  }

  public scrollHeight: string;

  @Input() solidColorLabel: string;
  @Input() suffixLabel: string;
  @Input() labelColor: string;
  @Input() menuItems: TreeNode[];

  @Output() closed: EventEmitter<string>;

  collapseSubMenu(): void {
    this.closed.emit('close');
  }

  onClick(uiComponentName: string): void {
    this._mediator.publish(new DashboardItemAddedMessage(uiComponentName));
  }
}
