import { UserUnitsModel } from "../user-units/user-units.model";

  export class User {
    constructor(init?: Partial<User>){
      Object.assign(this, init);
    }
    id: string;
    authId: string;
    companies: CompanyLookup[];
    isLicensed: boolean;
    currentAppIds: CurrentAppIds;
    profile: UserProfile;
    units: UserUnitsModel;
    roles: UserRoles;
  }
  
  export class CurrentAppIds  {
    designId: string;
    tubularId: string | null;
    dashboardId: string;
    dashboardWorkspaceIndex: number;
  }
  
  export class UserProfile {
    constructor(init?: Partial<UserProfile>){
      this.firstName = init?.firstName || "";
      this.lastName = init?.lastName || "";
      this.email = init?.email || "";
      this.database = init?.database || "";
      this.isGlobalAdmin = init?.isGlobalAdmin || false;
    }
    firstName: string;
    lastName: string;
    email: string;
    organization: string;
    jobTitle: string;
    database: string;
    isGlobalAdmin: boolean;
  }
  
  export class UserRoles  {
    admin: boolean;
    readOnly: boolean;
  }

  export class AddUserAccount {
    constructor(init?: Partial<AddUserAccount>){
      Object.assign(this, init);
    }

    companies: CompanyLookup[];
    password: string;
    profile: UserProfile;
  }

  export class EditUserAccount {
    constructor(init: Partial<EditUserAccount>){
      Object.assign(this, init);
    }
    id: string;
    companies: CompanyLookup[];
    profile: UserProfile;
  }

  export class EditUserPermissions {
    constructor(init: Partial<EditUserPermissions>){
      Object.assign(this, init);
    }
    id: string;
    roles: UserRoles;
  }

  export class CompanyLookup {
    id: string;
    name: string;
  }

  export class Auth0Profile {
    given_name: string;
    family_name: string;
    email: string;
    app_metadata: any;
  }