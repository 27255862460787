<div class="w-full">
  <label for="designTemplate" class="text-sm">Apply workspace from another design</label>
  <br />
  <label for="designTemplate" class="text-xs">(All tabs and components)</label>
  <p-treeSelect
    containerStyleClass="w-full"
    [options]="treeItems"
    appendTo="body"
    id="designTemplate"
    placeholder="Select a design"
    filter="true"
    filterBy="data.label"
    showClear="true"
    (onNodeSelect)="onTemplateSelect($event.node?.data.id)"
    (onClear)="onTemplateClear()"
    class="w-full"
  >
    <ng-template pTemplate="default" let-node>
      <span class="flex items-center gap-2 text-xs">
        <img
          *ngIf="node?.data?.level !== 'designs'"
          class="level-icon"
          [src]="node?.data?.icon"
          alt="icon"
        />
        {{ node?.data?.label }}
      </span>
    </ng-template>

    <ng-template pTemplate="value" let-node>
      <div *ngIf="!node" class="text-xs flex justify-center items-center">
        Select a design
      </div>
      <div class="flex justify-center items-center">
        {{ node?.data?.label }}
      </div>
    </ng-template>
    
  </p-treeSelect>
  <div *ngIf="templateWorkspaces">
    <br />
    <p-fieldset legend="Tabs in Selected Workspace:" class="text-xs">
      <div *ngFor="let tab of templateWorkspaces.workspaces" class="inline-flex space-x-1">
        <p-chip
          [pTooltip]="showComponentList(tab.uiComponents)"
          [escape]="false"
        >{{ tab.name }}</p-chip>
      </div>
    </p-fieldset>
    <p class="validation-error pt-2">WARNING: All tabs and components in the current design will be overwritten! Design data will not be affected.</p>
  </div>

  <br />
  <br />
  <div class="float-right">
    <button
      pButton
      type="button"
      icon="pi pi-times"
      class="p-button-text p-button-danger text-xs"
      label="Cancel"
      (click)="onCancel()"
    ></button>
    <button
      pButton
      type="button"
      icon="pi pi-check"
      class="p-button-text p-button-success text-xs"
      label="Save"
      (click)="onSave()"
      [disabled]="!workspaceToClone"
    ></button>
  </div>
</div>
