<form [formGroup]="form.formGroup">

  <div class="flex flex-col space-y-2">
    <div class="flex flex-col">
      <label for="name">Name</label>
      <input id="name" type="text" pInputText [formControlName]="form.formControlNames.name" />
    </div>

    <div class="flex flex-col">
      <label for="thermalOperation">Previous Operation</label>
      <p-select id="thermalOperation" [disabled]="isReadOnly" [options]="previousOperations"
        [formControlName]="form.formControlNames.previousOperationId" optionLabel="name" optionValue="id"
        (onChange)="onPreviousOperationChanged($event)" appendTo="body">
      </p-select>
    </div>
  </div>

  <div class="mt-3">
    <p-tabs value="0">
      <p-tablist>
        <p-tab value="0">{{ insideTabHeader }}</p-tab>
        <p-tab value="1" *ngIf="!circulationSelected">Annulus</p-tab>
      </p-tablist>

      <p-tabpanels>
        <p-tabpanel value="0">
          <app-single-operation [previousOperationMudPits]="previousOperationMudPits" [isReadOnly]="isReadOnly" [formControlName]="form.formControlNames.inside"
            [typeInput]="typeInput" [hideShutIn]="hideShutIn" (typeChanged)="onChildTypeChanged($event)"
            tabType="inside"
            (drillingDepthRangeUpdate)="drillingRangeChanged($event)" [user]="user" [units]="units"
            (fluidChanged)="onFluidChange($event)"></app-single-operation>
        </p-tabpanel>

        <p-tabpanel value="1">
          <app-single-operation [isReadOnly]="isReadOnly" [formControlName]="form.formControlNames.annulus"
            [typeInput]="typeInput" (typeChanged)="onChildTypeChanged($event)" tabType="annulus"
            [user]="user"></app-single-operation>
        </p-tabpanel>

          <div class="grid grid-nogutter">

            <p-fieldset legend="Duration">
              <div class="flex gap-2">
              <div style="align-content: center;">
                <div *ngIf="showFlowVolume" class="pb-2">
                  <input
                    class="radio-input"
                    value="volume"
                    type="radio"
                    binary="true"
                    (click)="volumeTimeChange(true)"
                    [checked]="form.formGroup.controls.volumeSelected.value"
                  />
                </div>

                <div *ngIf="showFlowVolume" class="pt-6">
                  <input
                  value="volume"
                  type="radio"
                  binary="true"
                  (click)="volumeTimeChange(false)"
                  [checked]="!form.formGroup.controls.volumeSelected.value"
                  >
                </div>
              </div>

              <div class="flex gap-2">
                <div class="space-y-2">
                  <div class="form-field">
                    <div class="input-group">

                      <div class="form-field" *ngIf="!showRop && showFlowVolume">
                        <label for="volume">Volume</label>
                        <p-inputgroup>
                          <p-inputNumber
                            [minFractionDigits]="1"
                            [formControlName]="form.formControlNames.volumeValue"
                            (focusout)="volumeChange($event, null)"
                          ></p-inputNumber>
                          <p-select
                            [disabled]="isReadOnly"
                            [options]="volumeOptions"
                            optionLabel="name"
                            optionValue="value"
                            appendTo="body"
                            (onChange)="volumeChange(null, $event)"
                            [formControlName]="form.formControlNames.volumeSelection"
                          ></p-select>
                        </p-inputgroup>
                      </div>
                    </div>
                  </div>

                  <div class="field">
                    <div class="p-input-group">

                      <div class="field" *ngIf="showRop">
                        <label for="time">ROP ({{ user.units.longLengths }}/hr)</label>
                        <p-inputgroup>
                          <input id="rop" type="number" pInputText value="{{ defaultRop }}" (change)="ropChange($event)" />
                        </p-inputgroup>
                      </div>
                      <div class="field">
                        <label htmlFor="time">{{ timeInputLabel }}</label>
                        <p-inputgroup>
                          <p-inputNumber
                            [minFractionDigits]="1"
                            [formControlName]="form.formControlNames.time"
                            (focusout)="timeChange($event)"
                          ></p-inputNumber>
                          <p-select [disabled]="isReadOnly" [options]="timeUnits"
                            [formControlName]="form.formControlNames.timeUnits" optionLabel="name" optionValue="value"
                            appendTo="body" (onChange)="timeChange(null)"></p-select>
                        </p-inputgroup>
                    </div>
                  </div>
                </div>

              </div>
              </div>
            </div>
          </p-fieldset>
        </div>
      </p-tabpanels>
    </p-tabs>
  </div>
</form>

<!-- <pre>{{ form.formGroupErrors | json }}</pre> -->