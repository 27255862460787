import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { catchError, debounce, forkJoin, lastValueFrom, map, Observable, Subscription, timer } from 'rxjs';
import { FluidsService } from 'src/app/perical/services/fluids.service';
import { WellConfigService } from 'src/app/shared/services/well-config.service';
import { UserRoles } from 'src/app/core/components/user-admin-page/user-model';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { PeriforOnChangeMessages, SignalRService } from 'src/app/shared/services/signal-r.service';
import { WellTypeService } from '../../services/well-type-datums.service';
import { getControlErrors, isControlInvalid } from 'src/app/shared/services/validation-helpers';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { FluidType } from 'src/app/perical/models/fluid.model';
import { DialogService } from 'primeng/dynamicdialog';
import { TrajectoryService } from 'src/app/shared/services/trajectory.service';
import { GetMudHydrostaticPressure } from 'src/app/perivis/shared/helpers/mud-hydrostatic.helper';
import { unitsLib } from 'src/app/core/services/unit-library';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { GridItemResizedMessage } from 'src/app/shared/models/mediator-messages.model';
import { _isNumberValue } from '@angular/cdk/coercion';

@Component({
  selector: 'app-annulus-contents',
  templateUrl: './annulus-contents.component.html',
  styles: [],
  providers: [DialogService, ConfirmationService],
  standalone: false
})
export class AnnulusContentsComponent implements OnInit, AfterViewInit, OnDestroy {
  public annulusContentsForm: UntypedFormGroup;
  public columnDefs: any[];
  public tableHeight: string;
  public isLoading: boolean;
  public _annulusContents: any[];
  public tocDepth: number;
  public fluids: { label: string; value: string, disabled?: boolean }[];
  public userRoles: UserRoles;
  public uu: UserUnitsModel;
  public tocLabel: string;
  public configMissing: boolean;
  public stringFullyCemented: boolean;
  public contextMenuItems: MenuItem[];
  public selectedRowIdx: number;
  public pressureUnit: string;
  public pressureValidation: { min: number, max: number };
  public currentStringName: string;
  public disabledMessage: string;
  public tubularCemented: boolean;

  private _defaultAnnulusContent: any;
  private _subscriptions: Subscription = new Subscription();
  private _mudlineDepth: number;
  private _tubular: any;
  private _defaultFluids: { label: string; value: string }[];

  get annulusContents(): UntypedFormArray {
    return this.annulusContentsForm.get('annulusContents') as UntypedFormArray;
  }

  get isDialogVisible(): boolean {
    return this.configMissing || this.stringFullyCemented;
  }

  @Input()
  private componentId: string;

  public isControlInvalid: Function = isControlInvalid;
  public getControlErrors: Function = getControlErrors;

  constructor(
    private _fluidsService: FluidsService,
    private _wellConfigService: WellConfigService,
    private _storeService: StoreService,
    private _signalRService: SignalRService,
    private _wellTypeService: WellTypeService,
    private _confirmationService: ConfirmationService,
    private _formBuilder: UntypedFormBuilder,
    private _trajectoryService: TrajectoryService,
    private _messenger: MediatorService
  ) {
    this.isLoading = true;

    this.annulusContentsForm = this._formBuilder.group({
      annulusContents: this._formBuilder.array([]),
      hangerPressure: new UntypedFormControl(null, { updateOn: 'blur', validators: [Validators.required, Validators.min(0)] }),
      naturalConvection: new UntypedFormControl(true),
      radiation: new UntypedFormControl(true)
    });

    this._defaultFluids = [
      { label: 'Oxygen', value: 'Oxygen-82fa-4186-a3c3-a09a1ec8b453' },
      { label: 'Methane', value: 'Methane-0f34-4c84-9736-50a6b1a59b1a' },
      { label: 'Carbon Dioxide', value: 'CO2-e84e-4d73-82ca-00eedc18795d' },
      { label: 'Nitrogen', value: 'Nitrogen-139e-4dbb-a350-8733be710f6c' },
      { label: 'Air', value: 'Air-d88d-47d6-81aa-d16e1c55e9fe' },
    ];
  }

  async ngOnInit(): Promise<void> {
    this.uu = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);
    this.userRoles = await this._storeService.get<UserRoles>(StorageKeys.ROLES);

    this.pressureUnit = unitsLib[this.uu.pressure].symbol;

    this.columnDefs = [
      { field: 'annulusSectionBottom', header: `Bottom (${this.uu.longLengths}) MD` },
      { field: 'annulusSectionContent', header: 'Annular Fluid' },
      { field: 'naturalConvection', header: 'Natural Convection' },
      { field: 'radiation', header: 'Radiation' }
    ];

    this.contextMenuItems = [
      { label: 'Insert section above', icon: 'pi pi-arrow-up', command: () => this.onAddAnnulusContent(this.selectedRowIdx) }
    ];

    this.annulusContentsForm.get('hangerPressure')?.valueChanges.subscribe((value) => {
      if (!this.annulusContentsForm.controls.hangerPressure.pristine) {
        this.handleSave({ hangerPressure: value });
      }
    });

    this.getData();

    switch (this.uu.pressure) {
      case 'psi':
        this.pressureValidation = { min: 0, max: 100000 };
        break;
      case 'bar':
        this.pressureValidation = { min: 0, max: 6894 };
        break;
      case 'KPa':
        this.pressureValidation = { min: 0, max: 689475 };
        break;
      case 'atm':
        this.pressureValidation = { min: 0, max: 6804 };
        break;
    }

    this.annulusContentsForm.controls.hangerPressure.setValidators([
      Validators.min(this.pressureValidation.min),
      Validators.max(this.pressureValidation.max),
      Validators.required
    ]);

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, (d) => this.signalRfunc(d));
  }

  ngAfterViewInit() {
    this._subscriptions.add(this._messenger.of(GridItemResizedMessage).subscribe((e) => {
      if (e.name == "Annulus Contents") {
        this.tableHeight = (e.itemHeight - 170) + 'px';
      }
    }));
  }

  signalRfunc(data: { action: string }) {
    if (data.action === PeriforOnChangeMessages.REFRESH_FLUIDS) {
      this.getData(true);
    } else if (data.action === PeriforOnChangeMessages.REFRESH_STRING_INPUTS ||
      data.action === PeriforOnChangeMessages.REFRESH_WELL_CONFIG) {
      this.getData();
    }
  }

  public onAddAnnulusContent(index?: number): void {
    if (this.annulusContents.invalid) {
      return;
    }
    const idx = index ?? 0;
    this.annulusContents.insert(idx, this.newAnnulusContent(true, idx));
  }

  private getData(isFluidSignalR?: boolean): void {
    this._wellConfigService.getTubular().subscribe({
      next: (tubular) => {
        this._tubular = tubular;
        if (!tubular) {
          this.configMissing = true;
          this.disabledMessage = "Well Configuration Missing: Please specify at least one string";
          this.annulusContentsForm.reset();
          this.annulusContentsForm.disable();
          this.isLoading = false;
          return;
        }

        const diameterUnit = this.uu.shortLengths === 'in' ? '"' : this.uu.shortLengths;
        this.currentStringName = `Current string: ${this._tubular.stringSections[0].pipe.outsideDiameter}${diameterUnit} ${tubular.type}`;
        this.stringFullyCemented = tubular.topOfCementMd === tubular.hangerMd;
        this.tubularCemented = tubular.hasCement && tubular.topOfCementMd < tubular.shoeMd;
        if (this.stringFullyCemented) {
          this.disabledMessage = 'String is fully cemented';
          this.annulusContentsForm.reset();
          this.annulusContentsForm.disable();
          this.tocLabel = '';
          return;
        }

        if (!isFluidSignalR) {
          this.annulusContentsForm.reset();
          this.annulusContentsForm.controls.hangerPressure.enable();
          this.disabledMessage = '';
          this.configMissing = false;
        }

        const sources: Observable<any>[] = [
          this._wellConfigService.getAnnulusContents() as Observable<any>,
          this._fluidsService.getFluids() as Observable<any>,
          this._wellTypeService.getWellType() as Observable<any>
        ];

    forkJoin(sources)
      .pipe(
        map(([annulusContents, fluids, wellType]) => {
          this.tocDepth = tubular.hasCement ? tubular.topOfCementMd : tubular.shoeMd;
          this.tocLabel = `(TOC = ${this.tocDepth.toFixed(2)} ${this.uu.longLengths}MD)`;
          this.fluids = fluids
            .filter(
              (f) =>
                f.state.type === FluidType.BRINE ||
                f.state.type === FluidType.STANDARDMUD ||
                f.state.type === FluidType.ADVANCEDMUD
            )
            .sort((a, b) => a.state.name.localeCompare(b.state.name))
            .map((f) => ({ label: f.state.name, value: f.id }));

          this.fluids.unshift({ label: '----Custom Fluids----', value: '', disabled: true });

          this._defaultFluids.forEach((fluid) => {
            this.fluids.unshift({ label: fluid.label, value: fluid.value });
          });

          if (isFluidSignalR) {
            return;
          }

          const waterDepth = wellType.waterDepth ? wellType.waterDepth : 0;
          this._mudlineDepth = +(wellType.drillFloorElevation + waterDepth).toFixed(2);
          this._defaultAnnulusContent = this.fluids.find((f) => f.value === this._tubular.annularFluidId)?.value ?? fluids[0].value;
          this._annulusContents = annulusContents.annulusContents ?? [];

          const hangerPressure = annulusContents.hangerPressure ?? 0;
          if (!this.annulusContentsForm.get('hangerPressure').value)
          {
            this.annulusContentsForm.patchValue({ hangerPressure });
          }

          this.populateFormData();

          this.isLoading = false;
        }),
        catchError((err) => {
          this.configMissing = true;
          this.isLoading = false;
          return err;
        })
      )
      .subscribe();
      },
      error: () => {
        this.isLoading = false;
      }
    });
  }

  private async populateFormData() {
    this.annulusContents.clear();
    if (!this.annulusContentsForm.controls.hangerPressure || this._annulusContents?.length === 0) {
      const defaultAnnulusContent = {
        annulusSectionContent: this._defaultAnnulusContent,
        annulusSectionBottom: this.tocDepth,
        naturalConvection: true,
        radiation: true
      };

      const hangerTvd = await lastValueFrom(this._trajectoryService.getTvdsFromMds([this._tubular.hangerMd], true));
      const defaultPressure = GetMudHydrostaticPressure(hangerTvd[0], this._tubular.annularFluid.state.nominalDensity, this.uu, true);
      
      this._annulusContents = [defaultAnnulusContent];
      
      const data = {
        annulusContents: this._annulusContents,
        hangerPressure: defaultPressure
      };

      this._wellConfigService.updateAnnulusContents(data).subscribe(() => {
        this.annulusContentsForm.patchValue({
          hangerPressure: defaultPressure.toFixed(2),
          annulusContents: this._annulusContents
        });

        this.annulusContentsForm.reset();
        this.annulusContents.clear();
        this.getData();
        return;
      });
    }

    this._annulusContents.forEach((data, idx) => {
      const annulusSectionFg = this.newAnnulusContent(false, idx);
      this.annulusContents.push(annulusSectionFg);
      const expandedData = { ...data };
      const annulusSection = [
        {
          annulusSectionBottom: expandedData.annulusSectionBottom,
          annulusSectionContent: expandedData.annulusSectionContent,
          naturalConvection: expandedData.naturalConvection,
          radiation: expandedData.radiation
        }
      ];
      this.annulusContents.controls[idx].setValue(annulusSection[0], { emitEvent: false });
    });
  }

  public onDeleteSection(idx: number): void {
    this._confirmationService.confirm({
      message: 'Are you sure that you want to delete this annulus section?',
      accept: () => {
        this.annulusContents.removeAt(idx);
        this.handleSave(this.annulusContents.value, this.annulusContents.getRawValue(), true);
      }
    });
  }

  public getRowIndex(row: any): void {
    this.selectedRowIdx = row;
  }

  public onFluidChange(annulusSectionFg: UntypedFormGroup, fluid: any): void {
    annulusSectionFg.controls.annulusSectionContent.patchValue(fluid);
    this.handleSave(annulusSectionFg.value, annulusSectionFg);
  }

  private newAnnulusContent(isNewAnnulusContent: boolean, idx: number): UntypedFormGroup {
    const defaultDepth =
      this.annulusContents.getRawValue()[idx]?.annulusSectionBottom - 100 || this.tocDepth;
    const annulusSectionFg = new UntypedFormGroup({
      annulusSectionBottom: new UntypedFormControl(
        {
          value: defaultDepth,
          disabled: this.userRoles.readOnly || (!isNewAnnulusContent && idx === this._annulusContents.length - 1),
        },
        {
          validators: [Validators.min(this._mudlineDepth), Validators.required],
          updateOn: 'blur'
        }
      ),
      annulusSectionContent: new UntypedFormControl(
        {
          value: isNewAnnulusContent ? null : this._defaultAnnulusContent,
          disabled: this.userRoles.readOnly
        },
        Validators.required
      ),
      naturalConvection: new UntypedFormControl(
        {
          value: true,
          disabled: this.userRoles.readOnly
        },
        Validators.required
      ),
      radiation: new UntypedFormControl(
        {
          value: true,
          disabled: this.userRoles.readOnly
        },
        Validators.required
      )
    });

    const debouncedValueChanges = annulusSectionFg.valueChanges.pipe(debounce(() => timer(1000)));
    this._subscriptions.add(debouncedValueChanges.subscribe((v) => this.handleSave(v, annulusSectionFg)));

    if (isNewAnnulusContent && this.annulusContents.value.length < 1) {
      annulusSectionFg.controls.annulusSectionBottom.patchValue(this.tocDepth, { emitEvent: false });
    }

    annulusSectionFg.markAllAsTouched();

    return annulusSectionFg;
  }

  private async handleSave(v: any, annulusContentsRecord?: any, isDelete?: boolean): Promise<void> {
    if (!isDelete && !_isNumberValue(v.hangerPressure) && (v.annulusSectionBottom < this._mudlineDepth || v.annulusSectionBottom > this.tocDepth || !annulusContentsRecord.value?.annulusSectionContent)) {
      return;
    }
    if (annulusContentsRecord?.valid || this.annulusContentsForm.get('hangerPressure')?.valid) {
      const data = {
        annulusContents: [...this.annulusContents.getRawValue()],
        hangerPressure: this.annulusContentsForm.get('hangerPressure')?.value
      };
      await lastValueFrom(this._wellConfigService.updateAnnulusContents(data));
    }
  }

  ngOnDestroy(): void {
    this._subscriptions?.unsubscribe();
    this.signalRfunc = null;
  }
}
