<app-tool-loader [isLoading]="isLoading" positionUnset="true">

  <p-toolbar>
    <ng-template #start>
      <div class="toolbar-menu">
        <p-selectButton [options]="tablePlot" [(ngModel)]="dlsOverridesStore.state.tablePlotSelected"
          (onChange)="tablePlotToggle($event)" [allowEmpty]="false" appendTo="body">
        </p-selectButton>
      </div>
    </ng-template>
  </p-toolbar>
 
  <div class="w-full h-[calc(100%-1.5rem)]">
    <app-ng-table-grid
      *ngIf="dlsOverridesStore.state?.tablePlotSelected === 'table'" 
      tableName="Additional Doglegs"
      [tableData]="dlsOverrides" [columnDefinitions]="columnDefinitions"
      [newRowFormGroup]="newDataRow" [inputFields]="inputFields"
      (dataChange)="onDlsOverrideChanged($event)"
      btmMargin="85"/>

    <app-xy-line-plot [hidden]="dlsOverridesStore.state?.tablePlotSelected !== 'plot'" [plotData]="plot.data"
      [xAxisTitle]="xAxisTitle" [yAxisTitle]="yAxisTitle" [plotName]="'Additional Doglegs'"
      [downloadPlotName]="downloadPlotName" [plotTitle]="'Additional Doglegs'"
      [xyLinePlotStore]="dlsOverridesStore">
    </app-xy-line-plot>
  </div>

</app-tool-loader>