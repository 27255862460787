import * as paper from 'paper'; 

export class SimplePanAndZoom {

  changeZoom(oldZoom: number, delta: number): number {
    const factor = 1.05;
    if (delta < 0) {
      return oldZoom * factor;
    }
    if (delta > 0) {
      return oldZoom / factor;
    }
    return oldZoom;
  }

  changeCenter(oldCenter: paper.Point, deltaX: number, deltaY: number, factor: number): paper.Point {
    let offset = new paper.Point(deltaX, -deltaY); 
    offset = offset.multiply(factor);
    return oldCenter.add(offset);
  }

}

export class StableZoom extends SimplePanAndZoom {

  changeZoomStable(oldZoom: number, delta: number, c: paper.Point, p: paper.Point) : any[] {
    const newZoom = super.changeZoom(oldZoom, delta);
    const beta = oldZoom / newZoom;
    const pc = p.subtract(c);
    const a = p.subtract(pc.multiply(beta)).subtract(c);
    return [newZoom, a];
  }

}