<form [formGroup]="form.formGroup">

  <div class="flex flex-col space-y-2">

    <p-toolbar>
      <ng-template #start>
      </ng-template>
      <ng-template #end>
        <img 
          class="w-[25px] h-[25px]" 
          (click)="applyDefaults()" 
          title="Apply Defaults" 
          src="../../assets/icons/default-icon-white.png" 
        />
      </ng-template>
    </p-toolbar>
    

    <div class="flex flex-col">
      <label for="brineType">Brine Type</label>
      <p-select [disabled]="isReadOnly" [options]="brineTypes" [formControlName]="form.formControlNames.brineType"
        id="brineType" optionLabel="label" optionValue="value" appendTo="body" class="w-full">
      </p-select>
    </div>

    <div class="flex flex-col">
      <label for="nominalDensity">Nominal Density</label>
      <p-inputgroup>
        <p-inputNumber id="nominalDensity" [formControlName]="form.formControlNames.nominalDensity"
          mode="decimal" [minFractionDigits]="1">
        </p-inputNumber>
        <p-inputgroup-addon>{{ densityUnits }}</p-inputgroup-addon>
      </p-inputgroup>
      <p *ngIf="form.formGroup.controls.nominalDensity.invalid" class="validation-error">
        Allowed range: {{ densityValidation.min }} - {{ densityValidation.max }} {{ densityUnits }}
      </p>
    </div>

  </div>

</form>