<form [formGroup]="form.formGroup" class="flex flex-col space-y-2 overflow-hidden">

  <div class="flex flex-col">
    <label>Depth</label>
    <p-inputgroup>
      <p-inputNumber [disabled]="true" placeholder="{{ packerDepth }}"></p-inputNumber>
      <p-inputgroup-addon>{{ userUnits.longLengths }}</p-inputgroup-addon>
    </p-inputgroup>
  </div>

  <div class="flex flex-col">
    <label>Seal Contact Diameter</label>
    <p-inputgroup>
      <p-inputnumber [formControlName]="form.formControlNames.sealBoreContactDiameter" />
      <p-inputgroup-addon>{{ userUnits.shortLengths }}</p-inputgroup-addon>
    </p-inputgroup>
  </div>

  <div class="flex items-center space-x-2">
    <p-checkbox size="small" [formControlName]="form.formControlNames.isSheared" label="Sheared" id="isSheared"
      binary="true" value="true" />
    <label class="pt-1">Is Sheared</label>
  </div>

  <div class="flex flex-col">

    <label>
      Upward Limit
      <i title="NOTE: when space-out is specified via No-Go limits for hydraulic-set packers, the space-out shall include length-change effects during the packer setting process.  This will be accurate if space-out is set in the yard prior to deployment to the wellsite."
        class="pi pi-info-circle">
      </i>
    </label>

    <p-inputgroup>
      <!-- <p-checkbox [formControlName] class="mr-2" size="small" binary="true" [value]="upwardLimitChecked"
        (onChange)="toggleUpwardLimit($event)" [disabled]="disableNogoCheckboxes" /> -->
        <input
          class="mr-2"
          [disabled]="disableNogoCheckboxes"
          type="checkbox"
          binary="true"
          [checked]="upwardLimitChecked"
          (change)="toggleUpwardLimit($event)" 
        >
      <p-inputnumber [hidden]="!upwardLimitChecked" [formControlName]="form.formControlNames.upwardLimit" />
      <p-inputgroup-addon>{{ userUnits.longLengths }}</p-inputgroup-addon>
    </p-inputgroup>
  </div>

  <div class="flex flex-col">
    <label>
      Downward Limit
      <i title="NOTE: when space-out is specified via No-Go limits for hydraulic-set packers, the space-out shall include length-change effects during the packer setting process.  This will be accurate if space-out is set in the yard prior to deployment to the wellsite."
        class="pi pi-info-circle">
      </i>
    </label>
    <p-inputgroup>
      <!-- <p-checkbox class="mr-2" size="small" binary="true" [value]="downwardLimitChecked"
        (onChange)="toggleDownwardLimit($event)" [disabled]="disableNogoCheckboxes" /> -->
        <input
          class="mr-2"
          [disabled]="disableNogoCheckboxes"
          type="checkbox"
          binary="true"
          [checked]="downwardLimitChecked"
          (change)="toggleDownwardLimit($event)" 
        >
      <p-inputnumber [hidden]="!downwardLimitChecked" [formControlName]="form.formControlNames.downwardLimit" />
      <p-inputgroup-addon>{{ userUnits.longLengths }}</p-inputgroup-addon>
    </p-inputgroup>
  </div>

</form>