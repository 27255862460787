<div #div class="h-[calc(100%-168px)] overflow-none">

  <gridster [options]="gridOptions" class="w-full h-full">
    <gridster-item [item]="item" *ngFor="let item of workspace?.uiComponents" (mouseleave)="clearActiveComponent()"
      (mouseover)="getActiveComponent(item?.id)">

      <p-panel class="h-full w-full border-none background-[var(--p-content-background)]">
        <p-header class="w-full background-[var(--p-content-border-color)]">
          <div class="flex justify-between items-center workspace-cmp-header">
            <div class="drag-handler flex-1">
              {{ item.name }}
            </div>
            <div class="flex-shrink-0">
              <p-button size="small" severity="danger" icon="pi pi-times"
                class="p-0 m-1 h-4 w-5 flex items-center justify-center" (click)="removeUiComponent(item)">
              </p-button>
            </div>
          </div>
        </p-header>

        <!-- Wrap the tool components to handle read-only mode -->
        <fieldset class="border-none m-0 h-full"  [disabled]="userRoles?.readOnly">
          <ndc-dynamic
            [ndcDynamicComponent]="item.componentInstance"
            [ndcDynamicInputs]="{ componentId: item.id }">
          </ndc-dynamic>
        </fieldset>
      </p-panel>

    </gridster-item>
  </gridster>

  <p-contextMenu [target]="div" [model]="contextMenuItems" appendTo="body"></p-contextMenu>
</div>