import { Tubular } from "./tubular";

export class WellComponent {

    constructor(init?: Partial<WellComponent>) {
      this.id = init && init.id || "";
      this.type = init && init.type || "Casing";
      this.holeSize = init && init.holeSize || 0;
      this.hangerMd = init && init.hangerMd || 0;
      this.shoeMd = init && init.shoeMd || 0;
      this.topOfCementMd = init && init.topOfCementMd || 0;
      this.hasCement = init && init.hasCement || false;
      this.annularFluidId = init && init.annularFluidId ||  "";
      this.stringSections = init && init.stringSections ? init.stringSections.map(s=> new Tubular(s)) : [];
      this.sequenceNumber = init?.sequenceNumber || 0;
    }
    
    id: string | number;
    type: string;
    holeSize: number;
    hangerMd: number;
    shoeMd: number;
    topOfCementMd: number;
    hasCement: boolean;
    annularFluidId: string;
    stringSections: Tubular[];
    sequenceNumber: number;
  }
  