
<div class="h-full">
  <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

    <div class="flex flex-1">
    <!-- Left Column -->
    <div class="flex-[0.3] min-w-[150px] flex-shrink">
      <p-listbox
        #tempDerationListbox
        [options]="tempDerations"
        [(ngModel)]="selectedTempDeration"
        optionLabel="name"
        (onChange)="onSelect($event)">
        <ng-template #header>
          <p-button
            class="outlined-button"
            size="small"
            severity="success"
            label="Add Profile"
            title="Add Profile"
            [outlined]="true"
            (click)="addItem()">
          </p-button>
        </ng-template>
        <ng-template let-item #item>
          <div class="flex justify-between items-center w-full">
            <span class="text-sm truncate">{{ item.name }}</span>
            <div class="flex">
              <i
                size="small"
                class="pi pi-trash !text-red-400"
                title="Delete Profile"
                (click)="deleteProfile(item)">
              </i>
            </div>
          </div>
        </ng-template>
      </p-listbox>
    </div>

    <!-- Right Column -->
    <div class="flex-[0.7] p-2">
      <!-- Flex container for Name label and input -->
      <div class="flex items-center gap-2 mb-4">
        <label for="tempDerationName" class="text-sm font-medium">Name:</label>
        <input
          id="tempDerationName"
          class="flex-grow p-1"
          type="text"
          pInputText
          value="{{selectedTempDeration?.name}}"
          (change)="onNameChange($event)"
          autocomplete="off"
          #nameField>
      </div>
      <app-ng-table-grid
        tableName="Temperature Deration"
        class="table-auto min-w-full"
        btmMargin="95"
        [tableData]="selectedTempDeration?.temperatureDerationProfile"
        [columnDefinitions]="columnDefinitions"
        [newRowFormGroup]="newDataRow"
        [inputFields]="inputFields"
        (dataChange)="onTemperatureDerationChange($event)"
        [isDynamicLoaded]="true"
        [componentId]="componentId"/>
    </div>
  </div>
</div>