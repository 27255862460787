<div class="h-full">

  <div class="h-[calc(100%-30px)]">

    <p-toolbar *ngIf="wellSchematicStore?.state && data">

      <ng-template #start>
        <p-button id="exportPng" text size="small" icon="pi pi-download" title="Download .png"
          (click)="onExportPng()"></p-button>

        <label for="stageSwitch">Current Stage &nbsp;</label>
        <p-toggleSwitch inputId="stageSwitch" [(ngModel)]="wellSchematicStore.state.currentStageOnly"
          (onChange)="onCurrentStageOnly($event)"></p-toggleSwitch>
        &nbsp;
        <label for="labelSwitch">No Labels &nbsp;</label>
        <p-toggleSwitch inputId="labelSwitch" [(ngModel)]="wellSchematicStore.state.drawNoLabels"
          (onChange)="onLabelToggle($event)"></p-toggleSwitch>
      </ng-template>

      <ng-template #end *ngIf="data?.wellType?.type !== 'Land'">
        <label for="mslSwitch">MSL &nbsp;</label>
        <p-toggleSwitch inputId="mslSwitch" [(ngModel)]="wellSchematicStore.state.drawWater"
          (onChange)="onWaterToggle($event)"></p-toggleSwitch>
      </ng-template>

    </p-toolbar>

    <div class="infoBoxWrapper">
      <app-schematic-info [tubular]="selectedTubularString"></app-schematic-info>
    </div>

    <canvas #wellSchematic id="wellSchematic" pTooltip="Scroll to Zoom" (dblclick)="drawSchematic()"
      (mousewheel)="onMouseWheel($event)" (mousedown)="onMouseDown()" (mouseup)="onMouseUp()"
      (mousemove)="onMouseMove($event)" (mouseover)="onMouseOver($event)" resize>
    </canvas>

  </div>

</div>