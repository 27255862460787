<div class="grades-catalog">
  <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

  <app-tool-loader [isLoading]="isLoading" positionUnset="true">
    
    <p-toolbar>
      <ng-template #start>
        <input
          class="w-full"
          pInputText
          (input)="filterGrades($event)"
          placeholder="Search"
      />
      </ng-template>
    </p-toolbar>

    <form [formGroup]="gradesForm" novalidate autocomplete="off">
    
      <ng-container formArrayName="grades">
        <p-table
          #catalogTable
          [scrollable]="true"
          [scrollHeight]="tableHeight"
          [columns]="columnDefs" 
          [resizableColumns]="true"
          [value]="gradesCatalog.controls" 
          showGridlines
          dataKey="value.id" 
          stateStorage="local"
          [stateKey]="componentId">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" pResizableColumn class="text-sm text-center">
                {{ col.header }}
              </th>
              <th class="text-center">
                <div style="display: flex;">
                  <p-button outlined="true" size="small" severity="success" [disabled]="gradesCatalog.invalid" icon="pi pi-plus"
                    (click)="onAddGrade()" title="Add Grade"></p-button>
                  <p-button text size="small" severity="success" [disabled]="gradesCatalog.invalid" icon="pi pi-file-excel"
                  (click)="exportExcel()" title="Export to Excel"></p-button>
                </div>
              </th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-gradeFg let-rIdx="rowIndex">
            <ng-container [formGroupName]="rIdx">
              <tr>
                <td>
                    <input type="text" pInputText formControlName="name" autocomplete="off" title="Name"
                      class="w-full" />
                    <app-validation-error [isValid]="isControlInvalid(gradeFg, 'name')"
                      [errorMsg]="getControlErrors(gradeFg, 'name')"/>
                </td>
                <td class="text-right">
                  <p-inputNumber formControlName="minimumApiYieldStrength" [minFractionDigits]="1"
                    title="API Minimum Yield ({{ pressureUnit }})">
                  </p-inputNumber>
                  <app-validation-error [isValid]="isControlInvalid(gradeFg, 'minimumApiYieldStrength')"
                    [errorMsg]="getControlErrors(gradeFg, 'minimumApiYieldStrength')"/>
                </td>
                <td class="text-right">
                  <p-inputNumber formControlName="youngsModulus"
                    title="Young's Modulus ({{ pressureUnit }})">
                  </p-inputNumber>
                  <app-validation-error [isValid]="isControlInvalid(gradeFg, 'youngsModulus')"
                    [errorMsg]="getControlErrors(gradeFg, 'youngsModulus')"/>
                </td>
                <td class="text-right">
                  <p-inputNumber formControlName="poissonsRatio" [minFractionDigits]="1"
                    title="Poisson's Ratio">
                  </p-inputNumber>
                  <app-validation-error [isValid]="isControlInvalid(gradeFg, 'poissonsRatio')"
                    [errorMsg]="getControlErrors(gradeFg, 'poissonsRatio')"/>
                </td>
                <td class="text-right">
                  <p-inputNumber formControlName="thermalExpansionCoefficient" [minFractionDigits]="2"
                    title="Thermal Expansion Coeff ({{ thermalExpCoeffUnit }})">
                  </p-inputNumber>
                  <app-validation-error [isValid]="isControlInvalid(gradeFg, 'thermalExpansionCoefficient')"
                    [errorMsg]="getControlErrors(gradeFg, 'thermalExpansionCoefficient')"/>
                </td>
                <td class="text-right">
                  <p-inputNumber formControlName="ultimateTensileStrength" [minFractionDigits]="0"
                    title="Ultimate Tensile Strength ({{ pressureUnit }})">
                  </p-inputNumber>
                  <app-validation-error [isValid]="isControlInvalid(gradeFg, 'ultimateTensileStrength')"
                    [errorMsg]="getControlErrors(gradeFg, 'ultimateTensileStrength')"/>
                </td>
                <td class="text-right">
                  <p-inputNumber formControlName="density" [minFractionDigits]="1"
                    title="Density ({{ solidsDensityUnit }})">
                  </p-inputNumber>
                  <app-validation-error [isValid]="isControlInvalid(gradeFg, 'density')"
                    [errorMsg]="getControlErrors(gradeFg, 'density')"/>
                </td>
                <td class="text-right">
                  <p-inputNumber formControlName="specificHeatCapacity" [minFractionDigits]="1"
                    title="Specific Heat Capacity ({{ specificHeatCapacityUnit }})">
                  </p-inputNumber>
                  <app-validation-error [isValid]="isControlInvalid(gradeFg, 'specificHeatCapacity')"
                    [errorMsg]="getControlErrors(gradeFg, 'specificHeatCapacity')"/>
                </td>
                <td class="text-right">
                  <p-inputNumber formControlName="thermalConductivity" [minFractionDigits]="2"
                    title="Thermal Conductivity ({{ thermalCondUnit }})">
                  </p-inputNumber>
                  <app-validation-error [isValid]="isControlInvalid(gradeFg, 'thermalConductivity')"
                    [errorMsg]="getControlErrors(gradeFg, 'thermalConductivity')"/>
                </td>
                <td class="text-center">
                  <p-select class="w-full" [disabled]="userRoles.readOnly" [title]="showJson(gradeFg.get('temperatureDeration').value)"
                    [placeholder]="getPlaceholderText(gradeFg.get('temperatureDeration').value)"
                    formControlName="temperatureDeration" [options]="temperatureDerations" appendTo="body">
                  </p-select>
                  <app-validation-error [isValid]="isControlInvalid(gradeFg, 'temperatureDeration')"
                    [errorMsg]="getControlErrors(gradeFg, 'temperatureDeration')"/>
                </td>
                <td class="text-center">
                  <p-select
                    class="w-full"
                    [disabled]="userRoles.readOnly"
                    title="Strength Calculation Option"
                    [placeholder]="getPlaceholderText(gradeFg.get('strengthCalculationOption').value)"
                    formControlName="strengthCalculationOption"
                    [options]="strengthCalculationOptions"
                    appendTo="body"
                  >
                  </p-select>
                  <app-validation-error
                    [isValid]="isControlInvalid(gradeFg, 'strengthCalculationOption')"
                    [errorMsg]="getControlErrors(gradeFg, 'strengthCalculationOption')"
                  />
                </td>
                <td class="text-center">
                  <i title="Delete Grade" class="pi pi-trash !text-red-400" (click)="onDeleteGrade(rIdx)"></i>
                </td>
              </tr>
            </ng-container>
          </ng-template>
        </p-table>
      </ng-container>
    </form>
  </app-tool-loader>
</div>