<form [formGroup]="form.formGroup" class="h-full w-full">

  <p-toolbar>
    <ng-template #start>
      <div class="flex items-center">
        <label class="mr-2">Show Plot</label>
        <p-toggleswitch (onChange)="tablePlotToggle()"></p-toggleswitch>
      </div>
    </ng-template>
    <ng-template #end>
      <img class="w-[25px] h-[25px]" (click)="applyDefaults()" title="Apply Defaults"
        src="../../assets/icons/default-icon-white.png" />
    </ng-template>
  </p-toolbar>

  <div class="w-full">
    <div *ngIf="!showPlot" class="flex flex-col mt-2 mb-2 space-y-2">

      <div class="flex flex-col">
        <label for="nominalDensity">Density</label>
        <p-inputgroup>
          <p-inputNumber id="nominalDensity" [formControlName]="form.formControlNames.density" required
            [minFractionDigits]="1">
          </p-inputNumber>
          <p-inputgroup-addon>{{ densityUnits }}</p-inputgroup-addon>
        </p-inputgroup>
        <p *ngIf="form.formGroup.controls.density.invalid" class="validation-error">
          Allowed range: {{ densityValidation.min }} - {{ densityValidation.max }} {{ densityUnits }}
        </p>
      </div>

      <div class="flex flex-col">
        <label for="mixWaterDensity">Mix-Water Density</label>
        <p-inputgroup>
          <p-inputNumber id="mixWaterDensity" [formControlName]="form.formControlNames.mixWaterDensity" required
            [minFractionDigits]="1"></p-inputNumber>
          <p-inputgroup-addon>{{ densityUnits }}</p-inputgroup-addon>
        </p-inputgroup>
        <p *ngIf="form.formGroup.controls.mixWaterDensity.invalid" class="validation-error">
          Allowed range: {{ mixWaterDensityValidation.min }} - {{ mixWaterDensityValidation.max }} {{ densityUnits }}
        </p>
      </div>

      <div class="flex flex-col">
        <label for="densityTemperature">Density Temperature</label>
        <p-inputgroup>
          <p-inputNumber id="densityTemperature" [formControlName]="form.formControlNames.densityTemperature" required
            [minFractionDigits]="1"></p-inputNumber>
          <p-inputgroup-addon>{{ tempUnit }}</p-inputgroup-addon>
        </p-inputgroup>
        <p *ngIf="form.formGroup.controls.densityTemperature.invalid" class="validation-error">
          Allowed range: {{ temperatureValidation.min }} - {{ temperatureValidation.max }} {{ tempUnit }}
        </p>
      </div>
    </div>

    <div [hidden]="!showPlot" class="h-[500px] w-[100%] p-2">
      <app-xy-line-plot [plotData]="plot.data" [xAxisTitle]="xAxisTitle" [yAxisTitle]="yAxisTitle" [plotName]="plotName"
        [downloadPlotName]="downloadPlotName" [reverseAutoRange]="true" [plotTitle]="'Fluids'"
        [xyLinePlotStore]="fluidsStore">
      </app-xy-line-plot>
    </div>

    <p-fieldset legend="Rheology">
      <div class="flex gap-2 ">
        <div class="flex flex-col w-1/3 space-y-2">

          <div class="flex flex-col">
            <label for="r600">R600</label>
            <p-inputNumber id="r600" [formControlName]="form.formControlNames.fann600" [minFractionDigits]="1"
              (ngModelChange)="calculateRheologyProperties()">
            </p-inputNumber>
            <p *ngIf="
              form.formGroup.controls.fann600.invalid && form.formGroup.controls.fann600.value
            " class="validation-error">
              Allowed range: 0.1 - 500
            </p>
          </div>

          <div class="flex flex-col">
            <label for="r100">R100</label>
            <p-inputNumber id="r100" [formControlName]="form.formControlNames.fann100" [minFractionDigits]="1"
              (ngModelChange)="calculateRheologyProperties()">
            </p-inputNumber>
            <p *ngIf="
              form.formGroup.controls.fann100.invalid &&
              form.formGroup.controls.fann100.value
            " class="validation-error">
              Allowed range: 0.1 - 500
            </p>
          </div>
        </div>

        <div class="flex flex-col w-1/3 space-y-2">
          <div class="flex flex-col">
            <label for="r300">R300</label>
            <p-inputNumber id="r300" [formControlName]="form.formControlNames.fann300" [minFractionDigits]="1"
              (ngModelChange)="calculateRheologyProperties()">
            </p-inputNumber>
            <p *ngIf="
              form.formGroup.controls.fann300.invalid && form.formGroup.controls.fann300.value
            " class="validation-error">
              Allowed range: 0.1 - 500
            </p>
          </div>

          <div class="flex flex-col">
            <label for="r6">R6</label>
            <p-inputNumber id="r6" [formControlName]="form.formControlNames.fann6" [minFractionDigits]="1"
              (ngModelChange)="calculateRheologyProperties()">
            </p-inputNumber>
            <p *ngIf="
              form.formGroup.controls.fann6.invalid && form.formGroup.controls.fann6.value
            " class="validation-error">
              Allowed range: 0.1 - 500
            </p>
          </div>
        </div>

        <div class="flex flex-col w-1/3 space-y-2">
          <div class="flex flex-col">
            <label for="r200">R200</label>
            <p-inputNumber id="r200" [formControlName]="form.formControlNames.fann200" [minFractionDigits]="1"
              (ngModelChange)="calculateRheologyProperties()">
            </p-inputNumber>
            <p *ngIf="
              form.formGroup.controls.fann200.invalid && form.formGroup.controls.fann200.value
            " class="validation-error">
              Allowed range: 0.1 - 500
            </p>
          </div>

          <div class="flex flex-col">
            <label for="r3">R3</label>
            <p-inputNumber id="r3" [formControlName]="form.formControlNames.fann3" [minFractionDigits]="1"
              (ngModelChange)="calculateRheologyProperties()">
            </p-inputNumber>
            <p *ngIf="
              form.formGroup.controls.fann3.invalid && form.formGroup.controls.fann3.value
            " class="validation-error">
              Allowed range: 0.1 - 500
            </p>
          </div>
        </div>
      </div>

      <div class="mt-2">
        <label for="rheologyTemperature">Rheology Temperature</label>
        <p-inputgroup>
          <p-inputNumber id="rheologyTemperature" [formControlName]="form.formControlNames.rheologyTemperature"
            [minFractionDigits]="1" required></p-inputNumber>
          <p-inputgroup-addon>{{ tempUnit }}</p-inputgroup-addon>
        </p-inputgroup>
        <p *ngIf="form.formGroup.controls.rheologyTemperature.invalid" class="validation-error">
          Allowed range: {{ temperatureValidation.min }} - {{ temperatureValidation.max }} {{ tempUnit }}
        </p>
      </div>

      <div class="flex mt-2">
        <div class="flex flex-col w-1/2">
          <div>
            <label>PV:&nbsp; {{ plasticViscosity }} &nbsp;cP</label>
          </div>
          <div>
            <label>YP:&nbsp; {{ yieldPoint }} &nbsp;lbf/100ft²</label>
          </div>
        </div>

        <div class="flex flex-col w-1/2">
          <div>
            <label>n':&nbsp; {{ nPrime | number }}</label>
          </div>
          <div>
            <label>K':&nbsp; {{ kPrime | number }} &nbsp;lb*s <span class="text-sm align-sub">&nbsp; n'/ft²</span></label>
          </div>
        </div>
      </div>

    </p-fieldset>
  </div>

</form>