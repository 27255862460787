<div #toolbarDiv>
  <p-toolbar>

    <ng-template #start>
      <div class="flex space-x-2 toolbar-menu">
        <p-select [options]="singleMultiple" (onChange)="modeToggle($event.value)"
          [(ngModel)]="pressureResultsStore.state.selectedSingleMultiple" appendTo="body">
        </p-select>

        <p-selectButton [options]="resultsDisplay" [(ngModel)]="pressureResultsStore.state.selectedResultsDisplay"
          (onChange)="onSelectResultsDisplay($event)" [allowEmpty]="false">
        </p-selectButton>

        <p-selectButton *ngIf="pressureResultsStore.state?.selectedResultsDisplay === 'plot'" [options]="depthView"
          [(ngModel)]="pressureResultsStore.state.selectedDepthView" (onChange)="depthViewToggle($event)"
          [allowEmpty]="false">
        </p-selectButton>
      </div>
    </ng-template>

    <ng-template #center>
      <div class="flex items-center space-x-2">

        <p-checkbox *ngIf="pressureResultsStore.state?.selectedSingleMultiple === 'singleOperation'" size="small"
          (onChange)="syncPressAndTemp($event)" [(ngModel)]="pressureResultsStore.state.syncThermalResults"
          title="Sync P&T results" binary="true">
        </p-checkbox>

        <p-select *ngIf="pressureResultsStore.state?.selectedSingleMultiple === 'singleOperation'"
          [(ngModel)]="pressureResultsStore.state.selectedOperation" [options]="operations"
          (onChange)="onOperationSelected($event, true)" optionLabel="name" appendTo="body">
        </p-select>

        <p-select *ngIf="pressureResultsStore.state?.selectedSingleMultiple === 'multipleOperation'"
          [(ngModel)]="pressureResultsStore.state.selectedAnnulus" [options]="annulusNames"
          (onChange)="annulusDropdownChange($event)" appendTo="body">
        </p-select>

        <p-button outlined="true" size="small" [disabled]="moveUpDisabled" (onClick)="onUpDownButtonClick('up', true)" icon="pi pi-arrow-up">
        </p-button>

        <p-button outlined="true" size="small" [disabled]="moveDownDisabled" (onClick)="onUpDownButtonClick('down', true)" icon="pi pi-arrow-down">
        </p-button>

        <p-select 
          class="ml-2"
          [options]="phases" [ngModel]="pressureResultsStore.state.selectedPhase"
          (onChange)="onPhaseSelected($event, true)" optionLabel="phaseNumber" 
          *ngIf="phases?.length > 1 &&
            pressureResultsStore.state?.selectedSingleMultiple === 'singleOperation'"
            appendTo="body">
          <ng-template pTemplate="selectedItem">
            Phase {{ pressureResultsStore.state?.selectedPhase?.phaseNumber }}
          </ng-template>
          <ng-template let-phase pTemplate="item">
            Phase {{ phase.phaseNumber }}
          </ng-template>
        </p-select>

      </div>

    </ng-template>

    <ng-template #end></ng-template>

  </p-toolbar>
</div>

<app-tool-loader [isLoading]="isLoading">

  <app-ng-table-results
    *ngIf="pressureResultsStore.state?.selectedResultsDisplay === 'grid'"
    class="mt-2"
    tableName="Thermal Results - Pressures"
    [tableData]="resultsGrid"
    [columnDefinitions]="pressureResultsStore.state?.selectedSingleMultiple === 'singleOperation' ? cols : operationNames"
    [tableHeight]="tableHeight" 
    [selectedOperation]="pressureResultsStore.state.selectedOperation"
    [virtualScroll]="true" />

  <app-thermal-results-plot 
    class="mt-2"
    *ngIf="pressureResultsStore.state?.selectedResultsDisplay === 'plot'"
    xAxisTitle="Pressure ({{ pressUnit }})" depthUnit="{{ depthUnit }}" plotTitle="Thermal Results - Pressures"
    [plotData]="
      pressureResultsStore.state?.selectedSingleMultiple === 'singleOperation'
        ? results
        : multipleResultsFull
    " [columnDefinitions]="
      pressureResultsStore.state?.selectedSingleMultiple === 'singleOperation'
        ? cols
        : operationNames
    " [selectedDepthDisplay]="pressureResultsStore.state.selectedDepthView" [plotName]="plotName"
    [componentHeight]="componentHeight"
    [downloadPlotName]="pressureResultsStore.state.selectedSingleMultiple === 'singleOperation' ? 'single_operation_plot' : 'multiple_operation_plot'"
    [thermalResultsStore]="pressureResultsStore"
  ></app-thermal-results-plot>

  <p-dialog [draggable]="false" [(visible)]="missingOperations" [closable]="false">
    <i class="pi pi-exclamation-circle"></i> No Operations Exist: Please specify at least one operation to view results.
    <br />
    <br />
  </p-dialog>

  <p-dialog [draggable]="false" [visible]="isDialogVisible" [closable]="false">
    <i class="pi pi-exclamation-circle"></i> {{ calculationError }}
    <br />
    <br />
  </p-dialog>

</app-tool-loader>