<div class="flex flex-col items-center m-4">

    <!-- Dialog Boxes -->
    <div class="flex justify-center">
        <p-dialog modal="true" header="Add User" [(visible)]="showAddUserForm"  [style]="{ width: '400px' }">
            <app-add-user-form [showAdminInputs]="user?.profile?.isGlobalAdmin" [companies]="companies"
                [(userProfile)]="addUserAccount" (userUpdated)="onUserAdd($event)"
                (emailChanged)="onUserEmailChanged($event)" [showPassword]="userNotInAuth0"
            />
        </p-dialog>

        <p-dialog modal="true" header="Edit User" [(visible)]="showEditUserForm"  [style]="{ width: '400px' }">
            <app-edit-user-form [showAdminInputs]="user?.profile?.isGlobalAdmin" [companies]="companies"
                [userProfile]="editUserAccount" (userUpdated)="onUserEdit($event)"
                [user]="user"
            />
        </p-dialog>

        <p-dialog modal="true" header="Edit Permissions" [(visible)]="showEditPermissionsForm"  [style]="{ width: '400px' }">
            <app-edit-permissions-form [userPermissions]="userPermissions"
                (permissionsUpdated)="onUserPermissionsUpdated($event)"
            />
        </p-dialog>
    </div>

    <p-card class="flex m-4 p-4 w-max">

        <!-- Card Header -->
        <ng-template pTemplate="title">
            <h1 class="text-center mt-2">
                Users
            </h1>
            <p-button class="ml-2" (click)="showAddUser()" icon="pi pi-user" label="Add User" outlined="true"></p-button>
            <hr class="my-4" />
        </ng-template>

        <div class="flex flex-col p-4">
            <app-tool-loader [isLoading]="isLoading">
                <app-users-table 
                    [users]="users" (userEdited)="showEditUser($event)" (userRemoved)="removeUser($event)"
                    (permissionsEdited)="showEditPermissions($event)" (licenseToggled)="toggleLicense($event)"
                    (inviteSent)="sendInvite($event)"
                />
            </app-tool-loader>
        </div>

    </p-card>

</div>