<form [formGroup]="form.formGroup">
  <label>
    {{ label }}
    <app-validation-error
      [isValid]="isControlInvalid()"
      [errorMsg]="getControlErrors()"
    />
  </label>
  <p-inputgroup>
    <p-inputNumber
      #pnInput
      [formControlName]="form.formControlNames.control"
      [min]="min"
      [max]="max"
      [minFractionDigits]="minDecimals"
      [maxFractionDigits]="maxDecimals"
      [title]="title"
      (onFocus)="onInputFocus($event)"
    >
    </p-inputNumber>
    <p-inputgroup-addon *ngIf="unitLabel">
      {{ unitLabel }}
    </p-inputgroup-addon>
  </p-inputgroup>
</form>
