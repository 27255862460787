
export class UiComponentModel {
  public constructor(init?: Partial<UiComponentModel>) {
    Object.assign(this, init);
  }
  id: string;
  cols: number;
  rows: number;
  y?: number;
  x?: number;
  componentInstance?: any;
  name: string;
}

export class DashboardModel {
  public constructor(init?: Partial<DashboardModel>) {
    Object.assign(this, init);
  }

  id: string;
  designId: string;
  workspaces: DashboardWorkspaceIdxModel[];
  activeWorkspace: DashboardWorkspaceModel;
}

export class WorkspacesModel {
  public constructor(init?: Partial<DashboardModel>) {
    Object.assign(this, init);
  }

  id: string;
  designId: string;
  workspaces: DashboardWorkspaceModel[];
  activeWorkspace: DashboardWorkspaceModel;
}

export class DashboardWorkspaceModel {
  public constructor(init: Partial<DashboardWorkspaceModel>){
    Object.assign(this, init);
  }
  name: string
  index: number;
  uiComponents: UiComponentModel[];
}

export class DashboardWorkspaceIdxModel {
  name: string
  index: number;
}
