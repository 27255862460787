import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TrajectoryService } from '../../../shared/services/trajectory.service';
import { PeriforOnChangeMessages, SignalRService } from 'src/app/shared/services/signal-r.service';
import { SelectItem } from 'primeng/api';
import { TrajectoryXyzModel } from 'src/app/shared/models/trajectory-xyz.model';
import { TrajectoryModel, TrajectoryPlotUi } from 'src/app/shared/models/trajectory.model';
import { Subscription, lastValueFrom } from 'rxjs';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { Store } from '@ngneat/elf';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { withUIEntities } from '@ngneat/elf-entities';
import { XyLinePlotUi } from 'src/app/core/models/xy-line-plot.model';

@Component({
    selector: 'app-trajectory-plot',
    templateUrl: './trajectory-plot-container.component.html',
    styles: [``],
    standalone: false
})
export class TrajectoryPlotContainerComponent implements OnInit, OnDestroy {
  private _subscriptions: Subscription;

  public plotly: any;

  public uu: UserUnitsModel;
  public isLoading: boolean;
  public isDarkMode : boolean;

  public plotView: SelectItem[] = [
    { label: '3D', value: '3d' },
    { label: 'Northing/TVD', value: 'northingTvd' },
    { label: 'Easting/TVD', value: 'eastingTvd' },
    { label: 'Dogleg/MD', value: 'doglegMd' },
    { label: 'Plan View', value: 'planView' }
  ];
  
  public trajectoryData: TrajectoryModel[];
  public trajectoryXyzData: TrajectoryXyzModel;
  public longLengthUnits: string;
  public componentHeight: number;

  //Used for component state
  private _componentId: string;
  @Input() set componentId(value: string) {
    this._componentId = value;
    this.trajectoryPlotStore = this._storeService.createStore(this.componentId, new TrajectoryPlotUi, withUIEntities<XyLinePlotUi>());
  }
  get componentId(): string {
    return this._componentId;
  }
  public trajectoryPlotStore: Store;

  constructor(
    private _trajectoryService: TrajectoryService,
    private _signalRService: SignalRService,
    private _messenger: MediatorService,
    private _storeService: StoreService,
  ) {
    this._subscriptions = new Subscription();
  }

  async ngOnInit(): Promise<void> {
    const themeStore =this._storeService.getStore(StorageKeys.DARK_MODE).subscribe((d) => {
      this.isDarkMode =  d.isDarkMode;
    });
    this._subscriptions.add(themeStore);

    this.uu = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);
    this.longLengthUnits = this.uu?.longLengths;

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));

    await this.getPlotData(false);
  }

  signalRfunc(data: any) {
    if (data.action == PeriforOnChangeMessages.REFRESH_TRAJECTORY_PLOT) {
      this.getPlotData(true);
    }
  }

  public async getPlotData(load: boolean) {
    this.isLoading = load;

    if (this.trajectoryPlotStore.state.selectedPlotView === '3d') {
      this.trajectoryXyzData = await lastValueFrom(this._trajectoryService.getTrajectoryXyz());
    } else {
      this.trajectoryData = await lastValueFrom(this._trajectoryService.getTrajectoryPoints());
    }

    this.isLoading = false;
  }

  public selectPlotView(e): void {
    this.trajectoryPlotStore.update((state) => ({
      ...state,
      selectedPlotView: e.value
    }));
    this.getPlotData(true);
  }

  ngOnDestroy() {
    this._subscriptions?.unsubscribe();
    this.signalRfunc = null;
  }
}
