import { Component, Input, OnInit } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { TotalDepthResult } from 'src/app/shared/models/trajectory.model';
import { TrajectoryService } from 'src/app/shared/services/trajectory.service';

@Component({
    selector: 'app-interpolation-tool',
    template: `
    <div *ngIf="!isLoading" class="flex flex-col p-2">
        <label class="mb-4">Total Depth: {{ totalDepthLabel }}</label>

        <p-inputgroup>
          <label class="mr-2 pt-1">MD:</label>
          <p-inputNumber
            min="0"
            [max]="totalDepth?.measuredDepth"
            placeholder="0"
            [minFractionDigits]="0"
            [maxFractionDigits]="4"
            [(ngModel)]="interpDepth"
            (onBlur)="convertToTvd()"
            (keydown.enter)="$event.target.blur()"
            (onFocus)="$event.target.select()">
          </p-inputNumber>
          <p-inputgroup-addon>
          <label class="ml-2"> => &nbsp; TVD: &nbsp; {{depthTvd}} &nbsp;</label>
          <p-button size="small" outlined="true" severity="success" icon="pi pi-copy" title="Copy TVD" (click)="copyTvd()">
          </p-button></p-inputgroup-addon>
        </p-inputgroup>
    </div>
  `,
    styles: [],
    standalone: false
})
export class InterpolationToolComponent implements OnInit {
  public depthTvd: number;
  public totalDepth: TotalDepthResult;
  public interpDepth: number;
  public depthUnit: string;
  public isLoading = true;

  public get totalDepthLabel(): string {
    return `${(Math.trunc(this.totalDepth.measuredDepth * 100) / 100).toLocaleString('en-US')} ft MD`;
  }

  //Used for state management
  @Input()
  private componentId: string;

  constructor(
    private _trajectoryService: TrajectoryService,
    private _storeService: StoreService
  ) {
    this.interpDepth = 0;
    this.depthTvd = 0;
  }

  async ngOnInit(): Promise<void> {
    this.totalDepth = await lastValueFrom(this._trajectoryService.getTotalDepth());
    if (this.totalDepth) {
      this.isLoading = false;
    }
    this.depthUnit = (await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS)).longLengths;
  }

  public async convertToTvd(): Promise<void> {
    const tvds = await lastValueFrom(this._trajectoryService.getTvdsFromMds([this.interpDepth], true));
    this.depthTvd = (Math.trunc(tvds[0] * 100) / 100);
  }

  public copyTvd(): void {
    navigator.clipboard.writeText(this.depthTvd.toFixed(3))
  }

}
