import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { MenuItem } from 'primeng/api';
import { StorageKeys, StoreService } from '../../services/store.service';
import { Store, StoreDef } from '@ngneat/elf';

@Component({
    selector: 'app-header',
    template: `
      <p-toolbar>
        <!--Main Level Navigation-->
        <!--Navigation for the app modules-->
        <ng-template #start>
          <p-tabMenu
            [model]="items"
            [activeItem]="items[0]"
            *ngIf="!document.location.href.includes('designs') && auth.isAuthenticated$ | async">
          </p-tabMenu>
        </ng-template>

        <ng-template #center>
          <div class="flex justify-center items-center" *ngIf="auth.isAuthenticated$ | async">
          <span class="text-[#5C6BC0] font-medium">PERI</span><span class="text-red-500 font-medium">FOR</span>
          </div>
        </ng-template>

        <ng-template #end>
          <div class="flex items-center  space-x-2">
            <p-button [rounded]="true" icon="pi pi-sun" [text]="true" [title]="isDarkMode ? 'Switch to Light Mode' : 'Switch to Dark Mode'" (onClick)="toggleDarkMode()"/>
            <app-notification-hub *ngIf="auth.isAuthenticated$ | async"></app-notification-hub>
            <app-help-menu *ngIf="auth.isAuthenticated$ | async"/>
            <app-user-menu *ngIf="auth.isAuthenticated$ | async"/>
          </div>
        </ng-template>
      </p-toolbar>
    `,
    styles: [``],
    standalone: false
})
export class HeaderComponent implements OnInit {
  public items: MenuItem[] = [
    {
      label: 'Designs',
      routerLink: ['/designs']
    }
    // Add other module levels here, ie: Reports, Etc
  ];

  public isDarkMode;

  constructor(
    public auth: AuthService,
    @Inject(DOCUMENT) public document,
    private _storeService: StoreService
  ) { }

  public themeStore: Store<StoreDef<{ isDarkMode: boolean }>>;

  ngOnInit() {
    this.themeStore = this._storeService.createStore(StorageKeys.DARK_MODE, {});

    this.themeStore.subscribe((v) => {
      this.isDarkMode = v?.isDarkMode ?? true;
      if (this.isDarkMode) {
        document.querySelector('html')?.classList.add('perifor-app-dark');
      } else {
        document.querySelector('html')?.classList.remove('perifor-app-dark');
      }
    });
  }

  toggleDarkMode(): void {
    // Get current state and toggle it
    const currentState = this.themeStore.getValue();
    this.isDarkMode = !currentState.isDarkMode;

    // Update the store with the new value
    this._storeService.updateStore(StorageKeys.DARK_MODE, { isDarkMode: this.isDarkMode });

    // Toggle the dark mode class on the HTML element
    document.querySelector('html')?.classList.toggle('perifor-app-dark', this.isDarkMode);
  }
}
