import { UntypedFormGroup } from "@angular/forms";
import { WellComponent } from "../models";

// Hole size must be smaller than the smallest ID of the previous string. Except for the outermost one.
export function holeSizeSmallerThanPrevsId(control: UntypedFormGroup) {
    const wellConfiguration = control?.parent?.parent?.getRawValue() as WellComponent[]; //Using RawValue so we can get the disabled inputs.
    const holeSize :number = control?.value;
    if(!wellConfiguration || !holeSize || control.pristine){
        return null;
    }
    const tubularString = control?.parent.getRawValue() as WellComponent;
    const idx = wellConfiguration.findIndex(wc => wc.id == tubularString.id);
    if(idx == 0){
        return null;
    }
    const previousTubular = wellConfiguration[idx - 1];
    // let previousDiameters = previousTubular.stringSections.map(ss=> ss.pipe.insideDiameter);
    // let minDiameter = Math.min(...previousDiameters);
    const previousDiameter = previousTubular.stringSections[previousTubular.stringSections.length - 1].pipe.outsideDiameter;
    if (holeSize > previousDiameter - 0.01) {
       return { error: `Hole size must be smaller than ${previousDiameter}" \n` };
    }
    if (holeSize < 0) {
        return { error: `Hole size must be greater than 0`};
    }
    const nextTubular = wellConfiguration[idx + 1] ?? null;
    if (nextTubular) {
        const nextHoleSize = nextTubular.holeSize;
        if (holeSize < nextHoleSize) {
            return { error: `Hole size must be greater than ${nextHoleSize}" \n` };
        }
    }
    return null;
 }