<div class="w-full h-full">
  <app-tool-loader [isLoading]="isLoading" positionUnset="true">

    <p-toolbar *ngIf="safetyFactorResultsStore" positionUnset="true">
      <ng-template #start>
        <div class="flex items-center gap-2 toolbar-menu">
          <p-selectButton [options]="modes" [(ngModel)]="safetyFactorResultsStore.state.selectedMode"
            (onChange)="onModeChange($event)" [allowEmpty]="false">
          </p-selectButton>

          <p-selectButton *ngIf="safetyFactorResultsStore.state?.selectedMode !== 'minimum'" [options]="resultsDisplay"
            [(ngModel)]="safetyFactorResultsStore.state.selectedResultDisplay"
            (onChange)="onSelectedResultsDisplayChange($event)" [allowEmpty]="false">
          </p-selectButton>

          <div *ngIf="safetyFactorResultsStore.state?.selectedResultDisplay === 'grid'">
            <p-checkbox size="small"
              [(ngModel)]="safetyFactorResultsStore.state.includeCharacters" label="Show Details"
              (onChange)="toggleCharacterView($event)" binary="true">
            </p-checkbox>
            <label class="pl-1">Show Details</label>
          </div>

          <p-selectButton *ngIf="
            safetyFactorResultsStore.state?.selectedResultDisplay === 'plot' &&
            safetyFactorResultsStore.state?.selectedMode !== 'minimum'
            " [options]="depthView" [(ngModel)]="safetyFactorResultsStore.state.selectedDepthView"
            (onChange)="depthViewToggle($event)" [allowEmpty]="false">
          </p-selectButton>

          <app-single-load-results [loadCases]="loadCases" (loadCaseSelected)="setLoadCase($event)"
            [stateStore]="safetyFactorResultsStore" [signalRChange]="signalRChange">
          </app-single-load-results>
        </div>
      </ng-template>
    </p-toolbar>

    <app-single-load-plot *ngIf="safetyFactorResultsStore.state?.selectedResultDisplay === 'plot'"
      [yAxisTitle]="yAxisTitle" [xAxisTitle]="xAxisTitle" [columnDefs]="columnDefs" [data]="results"
      [selectedDepthView]="safetyFactorResultsStore.state?.selectedDepthView" [depthUnit]="depthUnit"
      [plotName]="plotName" [plotTitle]="'Safety Factor Results'"
      [singleLoadPlotStore]="safetyFactorResultsStore">
    </app-single-load-plot>

    <app-single-load-grid [hidden]="safetyFactorResultsStore.state?.selectedResultDisplay !== 'grid'"
      [apiCollapseType]="apiCollapseType" [designFactors]="designFactors"
      [selectedLoad]="safetyFactorResultsStore.state?.selectedLoadCase" [rowData]="results"
      [columnDefinitions]="columnDefinitions" tableName="Safety Factor Results" gridName="Safety Factor Results"
      [mode]="safetyFactorResultsStore.state?.selectedMode">
    </app-single-load-grid>

    <p-dialog [draggable]="false" [(visible)]="errorMsg" [closable]="false">
      <i class="pi pi-exclamation-circle pr-1"></i>
      <label>{{ errorMsg }}</label>
      <br />
      <br />
    </p-dialog>

  </app-tool-loader>
</div>