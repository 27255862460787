import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { unitsLib } from 'src/app/core/services/unit-library';
import { FlowType } from 'src/app/perical/models/flow.model';

export interface StandardFluidFlowForm {
  flowRate: number;
  type: FlowType;
  pipeRoughness: number;
}

@Component({
    selector: 'app-standard-fluid',
    templateUrl: './standard-fluid.component.html',
    styles: [``],
    providers: subformComponentProviders(StandardFluidComponent),
    standalone: false
})
export class StandardFluidComponent implements OnInit, AfterViewInit {

  @Input() public operationType: any;

  @Input()
  public userUnits: UserUnitsModel;
  
  public flowUnit: string;
  public flowRateValidation: { min: number, max: number };
  public pipeRoughnessValidation: { min: number, max: number };

  private _defaultPipeRoughness: number;

  ngOnInit(): void {
    this._defaultPipeRoughness = 0.001968;
    if (this.userUnits.shortLengths == 'in') {
      this.pipeRoughnessValidation = { min: 0, max: 0.25 };
    } else if (this.userUnits.shortLengths == 'cm') {
      this._defaultPipeRoughness = 0.00499;
      this.pipeRoughnessValidation = { min: 0, max: 0.6 };
    } else {
      this._defaultPipeRoughness = 0.0499;
      this.pipeRoughnessValidation = { min: 0, max: 6.3 };
    }
    this.form.formGroup.controls.pipeRoughness.setValidators([Validators.min(this.pipeRoughnessValidation.min), Validators.max(this.pipeRoughnessValidation.max), Validators.required]);

    this.flowUnit = unitsLib[this.userUnits.standardFluidFlow].symbol;
    if (this.flowUnit == 'gpm') {
      this.flowRateValidation = { min: 0.1, max: 40000 };
    } else {
      this.flowRateValidation = { min: 0, max: 150 };
    }
    this.form.formGroup.controls.flowRate.setValidators([Validators.min(this.flowRateValidation.min), Validators.max(this.flowRateValidation.max), Validators.required]);
  }
  
  ngAfterViewInit(): void {    
    if (this.form.formGroup.controls.pipeRoughness.value == null) {
      this.form.formGroup.controls.pipeRoughness.patchValue(this._defaultPipeRoughness, { emitEvent: false });
    }
  }

  public form = createForm<StandardFluidFlowForm>(this, {
    formType: FormType.SUB,
    formControls: {
      flowRate: new UntypedFormControl(null),
      type: new UntypedFormControl(FlowType.STANDARD),
      pipeRoughness: new UntypedFormControl(null, [Validators.required])
    }
  });
}
