<app-tool-loader [isLoading]="isLoading" positionUnset="true">

  <p-toolbar>
    <ng-template #start>
      <div class="flex gap-2">
        <p-selectButton [options]="resultsView" visible="true"
          [(ngModel)]="pressureResultsStore.state.selectedResultsView" (onChange)="resultsViewToggle($event)"
          [allowEmpty]="false">
        </p-selectButton>

        <p-selectButton [options]="resultsDisplay" [(ngModel)]="pressureResultsStore.state.selectedResultDisplay"
          (onChange)="onSelectedResultDisplayUpdated($event)" [allowEmpty]="false">
        </p-selectButton>

        <p-selectButton *ngIf="pressureResultsStore.state?.selectedResultDisplay === 'plot'" [options]="depthView"
          [(ngModel)]="pressureResultsStore.state.selectedDepthView" (onChange)="onSelectedDepthViewUpdated($event)"
          [allowEmpty]="false">
        </p-selectButton>
      </div>
    </ng-template>
  </p-toolbar>

  <app-single-load-plot [hidden]="pressureResultsStore.state?.selectedResultDisplay !== 'plot'"
    [yAxisTitle]="yAxisTitle" [xAxisTitle]="xAxisTitle" [columnDefs]="columnDefs" [data]="results"
    [selectedDepthView]="pressureResultsStore.state?.selectedDepthView" [loadCaseResultField]="selectedLoadCase"
    [depthUnit]="depthUnit" [plotName]="plotName" [plotTitle]="'Pressures'"
    [singleLoadPlotStore]="pressureResultsStore">
  </app-single-load-plot>

  <app-single-load-grid
    [hidden]="pressureResultsStore.state?.selectedResultDisplay !== 'grid'"
    [rowData]="results" [columnDefinitions]="columnDefinitions" tableName="Pressures"
    [tableHeight]="tableHeight" gridName="Pressures"
    >
  </app-single-load-grid>

  <p-dialog [draggable]="false" [(visible)]="errorMsg" [closable]="false">
    <i class="pi pi-exclamation-circle"></i>
    <label>{{ errorMsg }}</label>
    <br />
    <br />
  </p-dialog>
</app-tool-loader>