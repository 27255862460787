<div class="h-full overflow-auto">
  <app-tool-loader [isLoading]="isLoading" positionUnset="true">
    <div class="flex flex-col w-full h-full">

      <!-- Confirmation Dialog -->
      <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle">
      </p-confirmDialog>

      <!-- Phase Envelope Dialog -->
      <p-dialog modal="true" [(visible)]="phaseEnvelopeCalculating" [draggable]="false" [closable]="false">
        <i class="pi pi-exclamation-circle"></i>
        Please wait while Phase Envelope is being calculated
        <br /><br />
      </p-dialog>

      <!-- Collapse/Expand Button -->
      <p-button size="small" outlined="true" pTooltip="{{ fluidsStore.state?.drawerOpen ? 'Collapse List' : 'Expand List' }}"
        class="outlined-button" icon="{{ fluidsStore.state?.drawerOpen ? 'pi pi-angle-double-left' : 'pi pi-angle-double-right' }}"
        (onClick)="drawerToggle()">
      </p-button>

      <!-- Fluid Selector -->
      <p-select *ngIf="!fluidsStore.state?.drawerOpen" [options]="fluids" [(ngModel)]="fluidsStore.state.selectedFluid"
        optionLabel="state.name" dataKey="id" appendTo="body" (onChange)="onFluidSelect($event)" class="mt-2">
        <ng-template pTemplate="selectedItem">
          {{ fluidsStore.state?.selectedFluid.state.name }}
        </ng-template>
        <ng-template let-fluid pTemplate="item">
          {{ fluid.state.name }}
        </ng-template>
      </p-select>

      <div class="flex flex-1 overflow-auto">
        <!-- Drawer Section -->
        <div *ngIf="fluidsStore.state?.drawerOpen" class="flex-[0.3] min-w-[150px] flex-shrink">
          <p-listbox 
            #fluidsListbox 
            [options]="selectedFluids"
            [ngModel]="fluidsStore.state.selectedFluid" 
            [group]="true" 
            optionGroupChildren="fluids"
            (onChange)="onFluidSelect($event)">
      
            <!-- Group Template -->
            <ng-template let-group #group>
              <div class="flex items-center justify-between" [style.background-color]="'var(--p-toolbar-background)'">
                <label class="text-sm">{{ findDisplayName(group.type) }}</label>
                <div class="flex space-x-2">
                  <p-button text size="small" icon="pi pi-plus" pTooltip="Add {{ findDisplayName(group.type) }}"
                    (onClick)="addFluid(group.type)">
                  </p-button>
                  <p-button text size="small"
                    [icon]="group.fluids.length > 0 ? 'pi pi-angle-double-down' : 'pi pi-angle-double-left'"
                    pTooltip="{{ group.fluids.length > 0 ? 'Collapse' : 'Expand' }} {{ findDisplayName(group.type) }}"
                    (onClick)="expandGroup(group.type)">
                  </p-button>
                </div>
              </div>
            </ng-template>
      
            <!-- Item Template -->
            <ng-template let-fluid #item>
              <div class="flex items-center justify-between w-full">
                <span class="w-full text-sm truncate" title="{{ fluid?.state?.name }}">
                  {{ fluid?.state?.name }}
                </span>
                
                <div class="flex items-center">
                  <i *ngIf="fluid.state.co2withImpurities && !hideGergCalc"
                    class="pi pi-play !text-green-400" pTooltip="Calculate Phase Envelope" (click)="calcGerg(fluid)">
                  </i>
                  <i class="pi pi-copy p-1 !text-cyan-400" pTooltip="Clone Fluid"
                    (click)="cloneFluid(fluid)">
                  </i>
                  <i *ngIf="!fluid?.isInUse && !checkDefaultFluid(fluid?.state?.name)" class="pi pi-trash !text-red-400"
                    pTooltip="Delete Fluid" (click)="deleteFluid(fluid)">
                  </i>
                    <i *ngIf="fluid?.isInUse || checkDefaultFluid(fluid?.state?.name)" class="pi pi-lock !text-red-400" [title]="checkDefaultFluid(fluid?.state?.name) ? 'Fluid cannot be deleted' : 'Fluid is used in the design'"></i>
                </div>
              </div>
            </ng-template>
      
          </p-listbox>
        </div>
      
        <!-- Main Content Section (Expands When Drawer is Hidden) -->
        <div [ngClass]="{'flex-[0.7]': fluidsStore.state?.drawerOpen, 'flex-[1]': !fluidsStore.state?.drawerOpen}" 
          class="p-2 overflow-auto">
          <app-fluid-data
            *ngIf="user"
            [isReadOnly]="user?.roles?.readOnly"
            [fluid]="fluidsStore.state?.selectedFluid"
            (fluidUpdate)="updateOrCreateFluid($event)"
            [disabled]="fluidsStore.state?.selectedFluid?.isInUse || (isDefaultFluid && fluidsStore.state?.selectedFluid?.state.name !== 'Seawater')"
            [fluids]="fluids"
            [phaseEnvelopeCalculated]="phaseEnvelopeCalculated"
            [userUnits]="units"
            [fluidsComponentHeight]="componentHeight"
            [isAltus]="user?.profile.organization === 'Altus'"
            [fluidsStore]="fluidsStore">
          </app-fluid-data>
        </div>
      </div>
      

    </div>
  </app-tool-loader>
</div>