import { Component } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';

export interface VleHeavyWeightHydrocarbonComponent {
  name: string | null;
  fraction: number | null;
  molecularWeight: number | null;
  specificGravity: number | null;
}

export interface HeaviesForm {
  vleHeavyWeightHydrocarbonComponents: VleHeavyWeightHydrocarbonComponent[];
}

@Component({
  selector: 'app-heavies',
  templateUrl: './heavies.component.html',
  providers: subformComponentProviders(HeaviesComponent),
  standalone: false,
})
export class HeaviesComponent {
  public form = createForm<VleHeavyWeightHydrocarbonComponent[], HeaviesForm>(this, {
    formType: FormType.SUB,
    formControls: {
      vleHeavyWeightHydrocarbonComponents: new UntypedFormArray([]),
    },
    createFormArrayControl: (key, value) => {
      switch (key) {
        case 'vleHeavyWeightHydrocarbonComponents':
          return new UntypedFormGroup({
            name: new UntypedFormControl("", Validators.required),
            fraction: new UntypedFormControl("", Validators.required),
            molecularWeight: new UntypedFormControl("", Validators.required),
            specificGravity: new UntypedFormControl("", Validators.required),
          }) as any;
        default:
          return new UntypedFormControl(value);
      }
    },
    toFormGroup: (
      obj: VleHeavyWeightHydrocarbonComponent[] | null
    ): HeaviesForm | null => {
      return {
        vleHeavyWeightHydrocarbonComponents: !obj ? [] : obj,
      };
    },
    fromFormGroup: (formValue: HeaviesForm): VleHeavyWeightHydrocarbonComponent[] | null => {
      return formValue.vleHeavyWeightHydrocarbonComponents;
    },
  });

  public removeHeavy(index: number): void {
    this.form.formGroup.controls.vleHeavyWeightHydrocarbonComponents.removeAt(index);
  }

  public addHeavy(): void {
    this.form.formGroup.controls.vleHeavyWeightHydrocarbonComponents.push(
      new UntypedFormGroup({
        name: new UntypedFormControl(null, Validators.required),
        fraction: new UntypedFormControl(null, Validators.required),
        molecularWeight: new UntypedFormControl(null, Validators.required),
        specificGravity: new UntypedFormControl(null, Validators.required),
      })
    );
  }
}
