<p-dialog [draggable]="false" [visible]="isDialogVisible" [closable]="false">
  <div *ngIf="wellheadDisabled"><i class="pi pi-exclamation-circle"></i> Not applicable for the outermost string or
    liners</div>
  <div *ngIf="configMissing"><i class="pi pi-exclamation-circle text-sm"></i> Well Configuration
    Missing: Please specify at least one string</div>
  <br> <br>
</p-dialog>

<app-tool-loader [isLoading]="isLoading" positionUnset="true">
  <div class="flex p-2">

    <div class="w-full">
      <p class="text-sm text-center">{{ currentStringName }}</p>
      <hr>

      <form [formGroup]="wellheadForm" class="m-2 space-y-2">
        <div class="flex flex-col">
          <label>Wellhead Inner Diameter</label>
          <p-inputgroup>
            <p-inputnumber [minFractionDigits]="1" formControlName="wellheadInnerDiameter" required />
            <p-inputgroup-addon>{{ innerDiameterUnit }}</p-inputgroup-addon>
          </p-inputgroup>
        </div>
  
  
        <div class="flex flex-col">
          <label>Hanger Lockdown Rating</label>
          <p-inputgroup>
            <p-inputnumber [minFractionDigits]="1" formControlName="hangerLockdownRating" required />
            <p-inputgroup-addon>{{ forceUnit }}</p-inputgroup-addon>
          </p-inputgroup>
        </div>
      </form>
    </div>
    
  </div>

</app-tool-loader>