<div class="h-full w-full">
  <app-tool-loader [isLoading]="!_load">
    <form [formGroup]="form.formGroup" class="m-2">

      <!-- Name Row -->
      <div class="flex justify-between items-center gap-2 mb-2">
        <div class="flex flex-col">
          <label for="load-name">Load Name</label>
          <input 
            class="w-full"
            id="load-name" 
            type="text" pInputText 
            [formControlName]="form.formControlNames.name"
           />
        </div>

        <div class="flex space-x-2">
          <app-validation-error [isValid]="isControlInvalid(form.formGroup, 'internalPressureProfile')"
            [errorMsg]="getControlErrors(form.formGroup, 'internalPressureProfile')"/>

          <p-button class="outlined-button" label="Save" size="small" severity="success" outlined
            [disabled]="form.formGroup.invalid || form.formGroup.disabled || form.formGroupErrors" icon="pi pi-check"
            (click)="manualSave$$.next()">
          </p-button>

          <p-button class="outlined-button" label="Cancel" icon="pi pi-times" size="small" severity="danger" outlined (click)="cancelEdit$.next()">
          </p-button>
        </div>
      </div>

      <!-- Load Case Partials-->
      <div class="grid grid-cols-3 gap-4">
        <div class="col-span-1">
          <app-internal-pressure-profiles [isReadOnly]="isReadOnly" #ipp
            [formControlName]="form.formControlNames.internalPressureProfile" [currentString]="currentString"
            [cement]="cement" [thermalOperations]="thermalOperations" [wellType]="wellType" [shoeDepth]="shoeDepth"
            [userUnits]="userUnits" [wellTd]="wellTd">
          </app-internal-pressure-profiles>
        </div>
        <div class="col-span-1">
          <app-external-pressure-profiles [isReadOnly]="isReadOnly" #epp
            [formControlName]="form.formControlNames.externalPressureProfile" [shoeDepth]="shoeDepthTvd"
            [currentString]="currentString" [cement]="cement" [porePressures]="porePressures"
            [thermalOperations]="thermalOperations" [userUnits]="userUnits"
            [wellTd]="wellTd">
          </app-external-pressure-profiles>
        </div>
        <div class="col-span-1">
          <p class="validation-error" *ngIf="form.formGroup.errors?.customTempAboveInfluxDepth" >
            Temperature Profile must be specified to Influx Depth
          </p>
          
          <app-temperature-profiles [isReadOnly]="isReadOnly" #tp
            [formControlName]="form.formControlNames.temperatureProfile" [currentString]="currentString"
            [loadName]="_load?.name" [thermalOperations]="thermalOperations" [wellType]="wellType"
            [shoeDepth]="shoeDepth" [userUnits]="userUnits" [wellTd]="wellTd">
          </app-temperature-profiles>
        </div>
      </div>

    </form>
  </app-tool-loader>

</div>