<div class="flex h-full w-full overflow-auto p-4">
  <p-card class="mx-auto w-2/3 p-4">
    <div class="p-4 m-2">
      <h1 class="text-[#90ee90] text-2xl font-bold mb-6 text-center">
        End-User License Agreement (EULA) for Perifor
      </h1>

      <p class="mb-4">
        This End-User License Agreement ("EULA") is a legal agreement between
        you and <span class="font-semibold">Altus Well Experts, Inc.</span>.
      </p>

      <p class="mb-4">
        This EULA agreement governs your acquisition and use of our
        <span class="app_name">Perifor</span> software ("Software") directly
        from <span class="font-semibold">Altus Well Experts, Inc.</span> or
        indirectly through a
        <span class="font-semibold">Altus Well Experts, Inc.</span> authorized
        reseller or distributor (a "Reseller").
      </p>

      <p class="mb-4">
        Please read this EULA agreement carefully before using the
        <span class="font-semibold">Perifor</span> software. It provides a license
        to use the <span class="font-semibold">Perifor</span> software and contains
        warranty information and liability disclaimers.
      </p>

      <p class="mb-4">
        If you are entering into this EULA agreement on behalf of a company or
        other legal entity, you represent that you have the authority to bind
        such entity and its affiliates to these terms and conditions. If you
        do not have such authority or if you do not agree with the terms and
        conditions of this EULA agreement, do not install or use the Software,
        and you must not accept this EULA agreement.
      </p>

      <h2 class="text-xl font-bold mt-6 mb-4">License Grant</h2>

      <p class="mb-4">
        <span class="font-semibold">Altus Well Experts, Inc.</span> hereby
        grants you a personal, non-transferable, non-exclusive licence to use
        the <span class="font-semibold">Perifor</span> software on your devices in
        accordance with the terms of this EULA agreement.
      </p>

      <p class="mb-4">
        You are permitted to load the
        <span class="font-semibold">Perifor</span> software (for example a PC,
        laptop, mobile or tablet) under your control. You are responsible for
        ensuring your device meets the minimum requirements of the
        <span class="font-semibold">Perifor</span> software.
      </p>

      <p class="mb-2">You are not permitted to:</p>

      <ul class="list-disc pl-6 mb-4">
        <li class="mb-2">
          Edit, alter, modify, adapt, translate or otherwise change the whole
          or any part of the Software nor permit the whole or any part of the
          Software to be combined with or become incorporated in any other
          software, nor decompile, disassemble or reverse engineer the
          Software or attempt to do any such things
        </li>
        <li class="mb-2">
          Reproduce, copy, distribute, or resell the Software for any
          commercial purpose
        </li>
        <li class="mb-2">
          Allow any third party to use the Software on behalf of or for the
          benefit of any third party
        </li>
        <li class="mb-2">
          Use the Software in any way which breaches any applicable local,
          national or international law
        </li>
        <li>
          Use the Software for any purpose that
          <span class="font-semibold">Altus Well Experts, Inc.</span> considers
          is a breach of this EULA agreement
        </li>
      </ul>

      <h2 class="text-xl font-bold mt-6 mb-4">Intellectual Property and Ownership</h2>

      <p class="mb-4">
        <span class="font-semibold">Altus Well Experts, Inc.</span> shall at
        all times retain ownership of the Software as originally accessed by
        you and all subsequent uses of the Software by you. The Software (and
        the copyright, and other intellectual property rights of whatever
        nature in the Software, including any modifications made thereto) are
        and shall remain the property of
        <span class="font-semibold">Altus Well Experts, Inc.</span>.
      </p>

      <p class="mb-4">
        <span class="font-semibold">Altus Well Experts, Inc.</span> reserves
        the right to grant licences to use the Software to third parties.
      </p>

      <h2 class="text-xl font-bold mt-6 mb-4">Termination</h2>

      <p class="mb-4">
        This EULA agreement is effective from the date you first use the
        Software and shall continue until terminated. You may terminate it at
        any time upon written notice to
        <span class="font-semibold">Altus Well Experts, Inc.</span>.
      </p>

      <p class="mb-4">
        It will also terminate immediately if you fail to comply with any term
        of this EULA agreement. Upon such termination, the licenses granted by
        this EULA agreement will immediately terminate and you agree to stop
        all access and use of the Software. The provisions that by their
        nature continue and survive will survive any termination of this EULA
        agreement.
      </p>

      <h2 class="text-xl font-bold mt-6 mb-4">Governing Law</h2>

      <p class="mb-4">
        This EULA agreement, and any dispute arising out of or in connection
        with this EULA agreement, shall be governed by and construed in
        accordance with the laws of the <span class="font-semibold">US</span>.
      </p>
    </div>

  </p-card>
</div>
