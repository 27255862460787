<div class="catalog">
  <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

  <app-tool-loader [isLoading]="isLoading" positionUnset="true">
    
    <p-toolbar>
      <ng-template #start>
        <input
          class="w-full"
          pInputText
          (input)="filterGrades($event)"
          placeholder="Search"
      />
      </ng-template>
    </p-toolbar>

    <form [formGroup]="catalogForm" novalidate>

      <ng-container formArrayName="items">
        <p-table
          #catalogTable
          styleClass="p-datatable-sm"
          [scrollable]="true"
          [scrollHeight]="catalogType === 'formation' ? tableHeightFormation : tableHeightCement"
          [columns]="columnDefs"
          [resizableColumns]="true"
          showGridlines
          [value]="itemsCatalog.controls"
          dataKey="value.id"
          stateStorage="local"
          [stateKey]="componentId">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" pResizableColumn class="text-sm text-center">
                {{ col.header }}
              </th>
              <th class="text-center">
                <div style="display: flex;">
                  <p-button size="small" outlined="true" severity="success" [disabled]="itemsCatalog.invalid" icon="pi pi-plus"
                    (click)="onAddItem()" title="Add"></p-button>
                  <p-button size="small" severity="success" text [disabled]="itemsCatalog.invalid" icon="pi pi-file-excel"
                    (click)="exportExcel()" title="Export to Excel"></p-button>
                </div>
              </th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-itemFg let-rIdx="rowIndex">
            <ng-container [formGroupName]="rIdx">
              <tr>
                <td>
                  <input type="text" pInputText formControlName="name" 
                    [title]="!isControlInvalid(itemFg, 'name') ? 'Name' : getControlErrors(itemFg, 'name')" 
                    autocomplete="off" />
                </td>
                <td class="text-right">
                  <p-inputNumber formControlName="density" [minFractionDigits]="1"
                    [title]="!isControlInvalid(itemFg, 'density') ? 'Density (' + solidsDensityUnit + ')' : getControlErrors(itemFg, 'density')"></p-inputNumber>
                </td>
                <td class="text-right">
                  <p-inputNumber formControlName="specificHeatCapacity" [minFractionDigits]="1" 
                    [title]="!isControlInvalid(itemFg, 'specificHeatCapacity') ? 'Specific Heat Capacity (' + specificHeatCapacityUnit + ')' : getControlErrors(itemFg, 'specificHeatCapacity')"></p-inputNumber>
                </td>
                <td class="text-right">
                  <p-inputNumber formControlName="thermalConductivity" [minFractionDigits]="2"
                    [title]="!isControlInvalid(itemFg, 'thermalConductivity') ? 'Thermal Conductivity (' + thermalCondUnit + ')' : getControlErrors(itemFg, 'thermalConductivity')"></p-inputNumber>
                </td>
                <td class="text-right">
                  <p-inputNumber formControlName="youngsModulus" [minFractionDigits]="0"
                    [title]="!isControlInvalid(itemFg, 'youngsModulus') ? 'Young\'s Modulus (' + youngsModulusUnit + ')' : getControlErrors(itemFg, 'youngsModulus')"></p-inputNumber>
                </td>
                <td class="text-right">
                  <p-inputNumber formControlName="poissonsRatio" [minFractionDigits]="1"
                    [title]="!isControlInvalid(itemFg, 'poissonsRatio') ? 'Poisson\'s Ratio' : getControlErrors(itemFg, 'poissonsRatio')"></p-inputNumber>
                </td>
                <td class="text-right">
                  <p-inputNumber formControlName="thermalExpansionCoefficient" [minFractionDigits]="1"
                    [title]="!isControlInvalid(itemFg, 'thermalExpansionCoefficient') ? 'Thermal Expansion Coeff (' + thermalExpCoeffUnit + ')' : getControlErrors(itemFg, 'thermalExpansionCoefficient')"></p-inputNumber>
                </td>
                <td class="text-center">
                  <i
                    title="{{ itemFg.value.name !== 'Default' ? 'Delete' : 'Cannot delete default item' }}"
                    class="{{ itemFg.value.name !== 'Default' ? 'pi pi-trash !text-red-400' : 'pi pi-lock !text-red-400' }}"
                    (click)="itemFg.value.name !== 'Default' ? onDeleteItem(rIdx) : null"
                  ></i>
                </td>
              </tr>
            </ng-container>
          </ng-template>
        </p-table>
      </ng-container>
    </form>
  </app-tool-loader>
</div>