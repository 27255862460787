<form [formGroup]="form.formGroup">

  <div class="flex flex-col space-y-2">

    <div class="flex flex-row">
        <p-checkbox
          size="small"
          binary="true"
          [formControlName]="form.formControlNames.hasGasCap"
          (onChange)="hasGasCapChange()"
        >
        </p-checkbox> 
      <label class="ml-2">Has Gas Cap</label>
    </div>

    <div class="flex flex-col">
      <label>Gas Cap Length</label>
      <app-validation-error
        [isValid]="isControlInvalid(form.formGroup, 'gasCapLength')"
        [errorMsg]="getControlErrors(form.formGroup, 'gasCapLength')"
      />
      <p-inputgroup>
        <p-inputNumber
          [formControlName]="form.formControlNames.gasCapLength"
          [maxFractionDigits]="2"
          (onFocus)="onInputFocus($event)"
        ></p-inputNumber>
        <p-inputgroup-addon>{{ lengthUnit }}</p-inputgroup-addon>
      </p-inputgroup>
    </div>

    <div class="flex flex-col">
      <label>Gas Gradient</label>
      <app-validation-error
        [isValid]="isControlInvalid(form.formGroup, 'gasGradient')"
        [errorMsg]="getControlErrors(form.formGroup, 'gasGradient')"
      />
      <p-inputgroup>
        <p-inputNumber
          [formControlName]="form.formControlNames.gasGradient"
          [maxFractionDigits]="4"
          (onFocus)="onInputFocus($event)"
        ></p-inputNumber>
        <p-inputgroup-addon>{{ gasGradientLabel }}</p-inputgroup-addon>
      </p-inputgroup>
    </div>
    
  </div>
</form>