import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { CementingLanding } from 'src/app/wellbore-inputs/models/cementing-landing.model';
import { ExternalPressureProfileType, GeneralEPP, MudAndWetCementMixWaterEPP } from '../../models/external-pressure-profile.model';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { unitsLib } from 'src/app/core/services/unit-library';
import { GetValueFromPpg } from 'src/app/perivis/shared/helpers/units.helper';
import { getControlErrors, isControlInvalid } from 'src/app/shared/services/validation-helpers';

interface MudAndWetCementMixWaterEPPForm {
  type: ExternalPressureProfileType.MUDANDCEMENTMIXWATER;
  generalEpp: GeneralEPP;
  cementMixwaterDensity: number;
}

@Component({
    selector: 'app-mud-and-cement-mix-water-epp',
    templateUrl: './mud-and-cement-mix-water-epp.component.html',
    providers: subformComponentProviders(MudAndCementMixWaterEppComponent),
    standalone: false
})
export class MudAndCementMixWaterEppComponent implements AfterViewInit, OnChanges {

  @Input() currentString: any;
  @Input() cement: CementingLanding;
  @Input() userUnits: UserUnitsModel;

  // Validation delegates
  public isControlInvalid: Function = isControlInvalid;
  public getControlErrors: Function = getControlErrors;

  public densityLabel: string;
  public mixWaterDensity: number;
  public densityValidation: { min: number, max: number };

  public form = createForm<MudAndWetCementMixWaterEPP, MudAndWetCementMixWaterEPPForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(ExternalPressureProfileType.MUDANDCEMENTMIXWATER),
      generalEpp: new UntypedFormControl(null, Validators.required),
      cementMixwaterDensity: new UntypedFormControl(null)
    },
    toFormGroup: (obj: MudAndWetCementMixWaterEPP): MudAndWetCementMixWaterEPPForm => {
      return {
        type: ExternalPressureProfileType.MUDANDCEMENTMIXWATER,
        generalEpp: obj,
        cementMixwaterDensity: obj.cementMixwaterDensity
      };
    },
    fromFormGroup: (formValue: MudAndWetCementMixWaterEPPForm): MudAndWetCementMixWaterEPP => {
      return {
        type: ExternalPressureProfileType.MUDANDCEMENTMIXWATER,
        hangerPressure: formValue.generalEpp.hangerPressure,
        fluidDensity: formValue.generalEpp.fluidDensity,
        cementMixwaterDensity: formValue.cementMixwaterDensity
      };
    }
  });

  ngAfterViewInit(): void {
    this.densityLabel = unitsLib[this.userUnits.density].symbol;
    if (this.form.formGroup.controls.cementMixwaterDensity.value == null) {
      this.form.formGroup.controls.cementMixwaterDensity.setValue(GetValueFromPpg(8.33, this.densityLabel), { emitEvent: false });
    }
  }

  ngOnChanges(): void {
    switch (this.userUnits.density) {
      case 'ppg (U.S.)':
        this.densityValidation = { min: 0.01, max: 25 };
        break;
      case 'kg/m³':
      case 'g/L':
        this.densityValidation = { min: 1.19, max: 2995 };
        break;
      case 'g/cm³':
      case 'kg/l':
      case 'sg':
        this.densityValidation = { min: 0.00119, max: 3 };
        break;
    }
    this.form.formGroup.controls.cementMixwaterDensity.setValidators([Validators.required, Validators.min(this.densityValidation.min), Validators.max(this.densityValidation.max)]);
  }

  onInputFocus($event) {
    $event.target.select();
  }
}
