<form [formGroup]="form.formGroup">
  <div class="flex space-x-4">
    <div class="w-1/2 space-y-2">

      <div class="flex flex-col">
        <label>Total Time to Open Choke</label>
        <p-inputgroup>
          <p-inputNumber
            formControlName="totalTimeToOpenChoke"
            [minFractionDigits]="1"
            (onBlur)="updateInitialTimeToOpenValidation()"
          ></p-inputNumber>
          <p-inputgroup-addon>min</p-inputgroup-addon>
        </p-inputgroup>
        <p
        *ngIf="
          form.formGroup.controls.totalTimeToOpenChoke.value &&
          form.formGroup.controls.totalTimeToOpenChoke.invalid
          " class="validation-error"
        >Allowed range: {{ 0 }} - {{ 10 }} min</p>
      </div>

      <div class="flex flex-col">
        <label>Initial Time to Open Choke</label>
        <p-inputgroup>
          <p-inputNumber
            formControlName="initialTimeToOpenChoke"
            [minFractionDigits]="1"
          ></p-inputNumber>
          <p-inputgroup-addon>min</p-inputgroup-addon>
        </p-inputgroup>
        <p
        *ngIf="
          form.formGroup.controls.initialTimeToOpenChoke.value &&
          form.formGroup.controls.initialTimeToOpenChoke.invalid
          " class="validation-error"
        >Allowed range: {{ 0 }} - {{ form.formGroup.controls.totalTimeToOpenChoke.value }} min</p>
      </div>

    </div>

    <div class="w-1/2 space-y-2">

      <div class="flex flex-col">
        <label>Terminal Choke Open/Travel</label>
        <p-inputgroup>
          <p-inputNumber
            (onBlur)="updateInitialCokeOpenValidation()"
            [minFractionDigits]="1"
            formControlName="terminalChokeOpenPercent">
          </p-inputNumber>
          <p-inputgroup-addon>%</p-inputgroup-addon>
        </p-inputgroup>
        <p
        *ngIf="
          form.formGroup.controls.terminalChokeOpenPercent.value &&
          form.formGroup.controls.terminalChokeOpenPercent.invalid
          " class="validation-error"
        >Allowed range: {{ 0 }} - {{ 45 }} %</p>
      </div>

      <div class="flex flex-col">
        <label>Initial Choke Open/Travel</label>
        <p-inputgroup>
          <p-inputNumber [minFractionDigits]="1" formControlName="initialChokeOpenPercent"></p-inputNumber>
          <p-inputgroup-addon>%</p-inputgroup-addon>
        </p-inputgroup>
        <p
        *ngIf="
          form.formGroup.controls.initialChokeOpenPercent.value &&
          form.formGroup.controls.initialChokeOpenPercent.invalid
          " class="validation-error"
        >Allowed range: {{ 0 }} - {{ form.formGroup.controls.terminalChokeOpenPercent.value }} %</p>
      </div>

    </div>

  </div>

  <div class="flex items-center pt-2">
    <input
    type="checkbox"
    binary="true"
    formControlName="allowTransientFlow"
    >
    <label class="pl-2">Allow Transient Unsteady Flow / Mass Accumulation</label>
  </div>
</form>