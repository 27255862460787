import { Component, Input, OnChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { InternalPressureProfileType, WCDIPP } from '../../models/internal-pressure-profile.model';
import { UomQuantityInput } from '../../uom-quantity-input/uom-quantity-input-component';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { unitsLib } from 'src/app/core/services/unit-library';

interface WcdIppForm {
  type: InternalPressureProfileType.WCDIPP;
  gasOilGradient: UomQuantityInput;
  seawaterGradient: UomQuantityInput;
}

@Component({
    selector: 'app-wcd-ipp',
    template: `
    <form [formGroup]="form.formGroup">
      <div class="flex flex-col space-y-2">
        <app-uom-input
          label="Gas Oil Gradient"
          [unitLabel]="gasGradientLabel"
          [formControlName]="form.formControlNames.gasOilGradient"
          [control]="'gasOilGradient'"
          [default]="gasGradientDefault"
          [validators]="validators.gasOilGradient"
          title="Allowed range: {{ gasGradientValidation.min }} - {{ gasGradientValidation.max }} {{ gasGradientLabel }}"
        />
      
        <app-uom-input
          label="Seawater Gradient"
          [unitLabel]="densityLabel"
          [formControlName]="form.formControlNames.seawaterGradient"
          [control]="'seawaterGradient'"
          [default]="seawaterGradientDefault"
          [validators]="validators.seawaterGradient"
          [inputDisabled]="true"
        />
      </div>
    </form>
`,
    providers: subformComponentProviders(WcdIppComponent),
    standalone: false
})
export class WcdIppComponent implements OnChanges {

  @Input() userUnits: UserUnitsModel;

  public validators: any;
  public densityLabel: string;
  public gasGradientLabel: string;
  public seawaterGradientDefault: number;
  public gasGradientDefault: number;
  public gasGradientValidation: { min: number, max: number };

  public form = createForm<WCDIPP, WcdIppForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(InternalPressureProfileType.WCDIPP),
      gasOilGradient: new UntypedFormControl(null),
      seawaterGradient: new UntypedFormControl(null)
    },
    toFormGroup: (obj: WCDIPP | null): WcdIppForm | null => {
      if (!obj) {
        return null;
      }

      return {
        type: obj.type,
        gasOilGradient: { control: obj.gasOilGradient },
        seawaterGradient: { control: obj.seawaterGradient }
      }
    },
    fromFormGroup: (formValue: WcdIppForm): WCDIPP | null => {
      const { gasOilGradient, seawaterGradient, ...commonValues } = formValue;
      return {
        gasOilGradient: +gasOilGradient?.control,
        seawaterGradient: +seawaterGradient?.control,
        ...commonValues
      }
    }
  });

  ngOnChanges(): void {
    this.densityLabel = unitsLib[this.userUnits.density].symbol;
    this.gasGradientLabel = this.userUnits.gasGradient;
    switch (this.densityLabel) {
      case 'ppg':
        this.seawaterGradientDefault = 8.6;
        break;
      case 'kg/m³':
      case 'g/L':
        this.seawaterGradientDefault = 1030.5;
        break;
      case 'g/cm³':
      case 'kg/l':
      case 'sg':
        this.seawaterGradientDefault = 1.03;
        break;
    }

    switch (this.gasGradientLabel) {
      case 'psi/ft':
        this.gasGradientValidation = { min: 0, max: 5.1948 };
        this.gasGradientDefault = 0.1;
        break;
      case 'kPa/m':
        this.gasGradientValidation = { min: 0, max: 117.5 };
        this.gasGradientDefault = 2.26;
        break;
      case 'sg':
        this.gasGradientValidation = { min: 0, max: 11.97 };
        this.gasGradientDefault = 0.23;
        break;
      case 'bar/m':
        this.gasGradientValidation = { min: 0, max: 1.17 };
        this.gasGradientDefault = 0.022;
        break;
    }

    this.validators = {
      gasOilGradient: [Validators.min(this.gasGradientValidation.min), Validators.max(this.gasGradientValidation.max)],
      seawaterGradient: [Validators.min(0), Validators.max(100)]
    };
  }
}