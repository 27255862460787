import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, Subscription, lastValueFrom, takeUntil } from 'rxjs';
import { DashboardModel, UiComponentModel } from './models/dashboard.model';
import { ComponentLauncher } from './services/component-launcher';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { DashboardApiService } from './services/dashboard-api.service';
import { DashboardItemAddedMessage } from 'src/app/shared/models/mediator-messages.model';
import { UsersService } from '../../services/users-service';
import { ConfirmationService } from 'primeng/api';
import { DialogService } from 'primeng/dynamicdialog';
import { StorageKeys, StoreService } from '../../services/store.service';
import { AppNotificationService } from 'src/app/shared/services/app-notification.service';

@Component({
    selector: 'app-dashboard',
    template: `
      <p-confirmDialog
        header="Confirmation"
        icon="pi pi-exclamation-triangle"
      ></p-confirmDialog>

    <div class="dashboard">
      <app-workspace
        [workspace]="dashboard?.activeWorkspace"
        (uiComponentsRemoved)="handleUiComponentsRemoved($event)"
        (uiComponentXyUpdated)="handleUiComponentXyUpdated()"
        [scrollToItemId]="scrollToItemId"
      />
      <app-workspace-tabs
        [isLoading]="isLoading"
        [workspaces]="dashboard?.workspaces"
        [selectedTabIndex]="dashboard?.activeWorkspace?.index ?? 1"
        (workspaceInserted)="handleWorkspaceInsert($event)"
        (workspaceIdx)="handleWorkspaceIndex($event)"
        (workspaceDeleted)="handleWorkspaceDelete($event)"
        (workspaceRenamed)="handleWorkspaceRename($event)"
        (workspaceSelected)="handleWorkspaceSelect($event)"
        (workspacesCloned)="handleWorkspacesCloned()"
      />
    </div>
  `,
    styles: [`
    :host {
      width: 100%;
    }
    .dashboard {
      height: calc(100vh + 65px);
      width: 100%;
    }
  `],
    providers: [ComponentLauncher, DialogService, ConfirmationService],
    standalone: false
})
export class DashboardContainerComponent implements OnInit, OnDestroy {

  private _designId : string;
  private _destroy$ = new Subject<void>();
  private _subscriptions = new Subscription();
  private _previousComponent: string;
  public isLoading = false;
  public dashboard: DashboardModel;
  public scrollToItemId: string;
  public newComponentId: string;

  constructor(
    private _componentLauncher: ComponentLauncher,
    private _dashboardApi: DashboardApiService,
    private _usersService: UsersService,
    private _mediator: MediatorService,
    private _storeService: StoreService,
    private _confirmationService: ConfirmationService,
    private _toaster: AppNotificationService
  ) { }

  async ngOnInit() {
    this._subscriptions.add(
      this._storeService
        .getStore(StorageKeys.DESIGN)
        .pipe(takeUntil(this._destroy$))
        .subscribe(async (d) => {
          if(this._designId != d.id){
            this._designId = d?.id;
            await this.fetchDashboardInitComponents();
          }
        })
    );

    this._subscriptions.add(
      this._mediator.of(DashboardItemAddedMessage)
        .pipe(takeUntil(this._destroy$))
        .subscribe(message => {
          this.handleUiComponentAdded(message.widgetIdentifier);
        })
    );

  }

  async handleWorkspacesCloned() {
    await this.fetchDashboardInitComponents();
  }

  async handleWorkspaceInsert(e: { idx: number }) {
    const inserted = await lastValueFrom(this._dashboardApi.insertWorkspace({ workspaceIndex: e.idx }));
    if (inserted) {
      await this.fetchDashboardInitComponents();
    }
  }

  async handleWorkspaceDelete(idx: number) {
    const deleted = await lastValueFrom(this._dashboardApi.deleteWorkspace(idx));
    if (deleted) {
      await this.fetchDashboardInitComponents();
    }
  }

  async handleWorkspaceRename(e: { idx: number, name: string }) {
    await lastValueFrom(this._dashboardApi.renameWorkspace(e.idx, e.name));
  }

  async handleWorkspaceSelect(idx: number) {
    const setWs = await lastValueFrom(this._usersService.updateCurrentAppIds({ dashboardId: this.dashboard.id, dashboardWorkspaceIndex: idx }));
    if (setWs) {
      await this.fetchDashboardInitComponents();
    }
  }

  async handleUiComponentsRemoved(uiComponents: UiComponentModel[]) {
    this.dashboard.activeWorkspace.uiComponents = this._componentLauncher.removeComponents(uiComponents, this.dashboard.activeWorkspace.uiComponents);
    await lastValueFrom(this._dashboardApi.updateUiComponents(this.dashboard.activeWorkspace.uiComponents));
  }

  async handleUiComponentAdded(uiComponentName: string) {
    if (this._previousComponent === uiComponentName) {
      return;
    }

    this.isLoading = true;
    this._previousComponent = uiComponentName;
    setTimeout(() => this._previousComponent = null, 1000);

    const activeComponents = this.dashboard.activeWorkspace.uiComponents;
    const allComponents = this._componentLauncher.getComponentRegistry();
    const uiComponentNameKey = allComponents.find(c => c.key === uiComponentName)?.name;
    const componentIndex = activeComponents.findIndex(c => c.name === uiComponentNameKey);

    const sumOfAllActiveComponentRows = activeComponents.reduce((acc, c) => acc + c.rows, 0);
    if (sumOfAllActiveComponentRows > 85) {
      this._toaster.showInfo('You have reached the maximum workspace size. Please create a new workspace to add more components.');
      return;
    }

    this.scrollToItemId = null;
    if (componentIndex > -1) {
      this._confirmationService.confirm({
        message: `Component ${activeComponents[componentIndex].name} already exists in the workspace. Do you want to add another?`,
        header: 'Information',
        accept: async () => {
          await this.addComponent(uiComponentName);
        },
        reject: () => {
          this.scrollToItemId = activeComponents[componentIndex].id;
        }
      });
    } else {
      await this.addComponent(uiComponentName);
      this.scrollToItemId = activeComponents.find(c => c.name === uiComponentNameKey).id;
    }

    this.isLoading = false;
  }

  async handleUiComponentXyUpdated() {
    if (!this.isLoading) {
      await lastValueFrom(this._dashboardApi.updateUiComponents(this.dashboard.activeWorkspace.uiComponents));
    }
  }

  async handleWorkspaceIndex(e: { fromIdx: number, toIdx: number }) {
    const indexed = await lastValueFrom(this._dashboardApi.reIndexWorkspace(e.fromIdx, e.toIdx));
    if (indexed) {
      await lastValueFrom(this._usersService.updateCurrentAppIds({ dashboardId: this.dashboard.id, dashboardWorkspaceIndex: e.toIdx }));
      await this.fetchDashboardInitComponents();
    }
  }

  private async addComponent(uiComponentName: string): Promise<void> {
    const uiComponents = this.dashboard.activeWorkspace.uiComponents;
    const uiComponent = this._componentLauncher.generateComponent(uiComponentName);
    const uiComponentId = await lastValueFrom(this._dashboardApi.updateUiComponents([...uiComponents, uiComponent]));

    uiComponent.id = uiComponentId;
    this._componentLauncher.addComponentToUi(uiComponent, uiComponents);
    this.scrollToItemId = uiComponentId;
  }

  private async fetchDashboardInitComponents() {
    this.isLoading = true;
    this.dashboard = await lastValueFrom(this._dashboardApi.getDashboard());
    this._componentLauncher.loadComponentInstances(this.dashboard?.activeWorkspace?.uiComponents);
    this.isLoading = false;
  }

  ngOnDestroy() {
    this._subscriptions.unsubscribe();
    this._destroy$.next();
    this._destroy$.complete();
  }
}
