import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { ChokesService } from '../../services/chokes.service';
import { Choke, ChokesUi } from '../../models/chokes.model';
import { Observable, Subscription, lastValueFrom } from 'rxjs';
import { User } from 'src/app/core/components/user-admin-page/user-model';
import { Store } from '@ngneat/elf';
import { withUIEntities } from '@ngneat/elf-entities';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { GridItemResizedMessage } from 'src/app/shared/models/mediator-messages.model';

@Component({
    selector: 'app-chokes',
    templateUrl: './chokes.component.html',
    styles: [``],
    providers: [ConfirmationService],
    standalone: false
})
export class ChokesComponent implements OnInit, OnDestroy {
  public selectedChoke: Choke[];
  public chokeData: any;
  public isLoading: boolean;
  public selectedChokeDetails$: Observable<any>;
  public emitInitialValueOnInit = false;
  public hideGerg = false;
  public user: User;
  public componentHeight: number;

  private _isAddOrDelete: boolean;
  private _savedChoke: string;
  private _subscriptions: Subscription;

  // State
  private _componentId: string;
  @Input() set componentId(value: string) {
    this._componentId = value;
    this.chokesStore = this._storeService.createStore(this.componentId, null ,withUIEntities<ChokesUi>());
  }
  get componentId(): string {
    return this._componentId;
  }
  public chokesStore: Store;

  constructor(
    private _confirmationService: ConfirmationService,
    private _chokesService: ChokesService,
    private _store: StoreService,
    private _storeService: StoreService,
    private _messenger: MediatorService
  ) {
    this._subscriptions = new Subscription();
    this._subscriptions.add(this._messenger.of(GridItemResizedMessage).subscribe((e) => {
      if (e.name == "Chokes") {
        this.componentHeight = e.itemHeight - 285;
      }
    }));
  }

  async ngOnInit(): Promise<void> {
    this.user = await this._store.get<User>(StorageKeys.USER);
    if (this.user) {
      const company = this.user.profile?.organization?.toLowerCase();
      if (company != "altus" && company != "harbour" && company != "baker" && company != "bp" && company != "fervo" && company != "neptune" && company != "shell" && company != "oxy" && company != "cop" && company != "omv") {
        this.hideGerg = true;
      }
    }

    this.getData();
  }

  private async getData() {
    this.isLoading = true;
    this.chokeData = await lastValueFrom(this._chokesService.getChokes());

    this.selectedChoke = [];

    this.selectedChoke = this.chokeData?.chokes.length > 0 ? this.chokeData.chokes[this.chokeData.chokes.length - 1] : this.createChoke();
    this.selectedChokeDetails$ = new Observable(observer => observer.next(this.selectedChoke));

    this.isLoading = false;
  }

  public onAddChoke(): void {
    if (this.hideGerg || this._isAddOrDelete) {
      return;
    }
    this._isAddOrDelete = true;
    const choke = this.createChoke();
    this.chokeData.chokes.push(choke);
    this.selectedChoke = this.chokeData.chokes[this.chokeData.chokes.length - 1];
    this.selectedChokeDetails$ = new Observable(observer => observer.next(this.selectedChoke));
  }

  public createChoke(): Choke {
    const newChoke: Choke = {
      name: '',
      criticalLiquidFlowFactor: 0.75,
      criticalVaporFlowFactor: 0.75,
      pipingGeometryFactor: 1,
      flowCoefficientSchedule: [
        {
          percentOpen: 0.0,
          flowCoefficient: 0.0
        }
      ]
    };

    return newChoke;
  }

  public onChokeSelect(e: any) {
    const foundChoke = this.chokeData.chokes.find(x => x.name === e.value.name);
    this.selectedChoke = foundChoke;
    this.selectedChokeDetails$ = new Observable(observer => observer.next(this.selectedChoke));
  }

  public onDeleteChoke(choke: Choke): void {
    let index = this.chokeData.chokes.findIndex(x => x.name == choke.name);
    if (index == -1) {
      index = 0;
    }
    this._confirmationService.confirm({
      message: 'Are you sure that you want to delete the selected choke?',
      accept: () => {
        this.chokeData.chokes.splice(index, 1);
        this._isAddOrDelete = true;
        this.handleSaveChokes();
        this.selectedChoke = this.chokeData.chokes.length > 0 ? this.chokeData.chokes[this.chokeData.chokes.length - 1] : this.createChoke();
        this.selectedChokeDetails$ = new Observable(observer => observer.next(this.selectedChoke));
      }
    });
  }

  public async handleSaveChokes(): Promise<void> {
    await lastValueFrom(this._chokesService.updateChokes(this.chokeData.chokes));

    if (this._isAddOrDelete) {
      this.isLoading = true;
      this.chokeData = null;
      this.chokeData = await lastValueFrom(this._chokesService.getChokes());
      this._isAddOrDelete = false;
      this.isLoading = false;
    }

    let index = this.chokeData.chokes.findIndex(x => x.name == this._savedChoke);
    if (index == -1) {
      index = this.chokeData.chokes.length - 1;
    }
    this.selectedChoke = this.chokeData.chokes[index];
    this.selectedChokeDetails$ = new Observable(observer => observer.next(this.selectedChoke));
  }

  public saveChoke(e: any) {
    let index = this.chokeData.chokes.findIndex(x => x.name == e.name && x.criticalLiquidFlowFactor == e.criticalLiquidFlowFactor);
    if (index == -1) {
      index = this.chokeData.chokes.findIndex(x => x.name == e.name);
    }
    if (index == -1) {
      index = this.chokeData.chokes.length - 1;
    }
    this.chokeData.chokes[index] = e;
    this._savedChoke = e.name;

    this.handleSaveChokes();
  }

  ngOnDestroy() {
    this._subscriptions?.unsubscribe();
  }
}

