import { Component, Input } from '@angular/core';
import { unitsLib } from '../../services/unit-library';
import { UsersService } from '../../services/users-service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-units-convert',
  templateUrl: './units-convert.component.html',
  styles: [``],
  standalone: false
})
export class UnitsConvertComponent {
  public unitTypes = [
    { name: "Area", value: "area" },
    { name: "Density", value: "density" },
    { name: "Dogleg Severity", value: "doglegSeverity" },
    { name: "Enthalpy", value: "enthalpy" },
    { name: "Force", value: "force" },
    { name: "Gas Gradient", value: "gasGradient" },
    { name: "Gas Injection Rate", value: "gasInjectionRate" },
    { name: "Heat", value: "heat" },
    { name: "Hydrocarbon Flow", value: "hydrocarbonFlow" },
    { name: "Linear Density", value: "linearDensity" },
    { name: "Long Lengths", value: "longLengths" },
    { name: "Mass Flow", value: "massFlow" },
    { name: "Pressure", value: "pressure" },
    { name: "Short Lengths", value: "shortLengths" },
    { name: "Slurry Flow Rate", value: "slurryFlow" },
    { name: "Solids Density", value: "solidsDensity" },
    { name: "Specific Heat Capacity", value: "specificHeatCapacity" },
    { name: "Speed", value: "speed" },
    { name: "Standard Fluid Flow", value: "standardFluidFlow" },
    { name: "Stress Pressure", value: "stressPressure" },
    { name: "Temperature", value: "temperature" },
    { name: "Temperature Gradient", value: "temperatureGradient" },
    { name: "Thermal Conductivity", value: "thermalConductivity" },
    { name: "Thermal Expansion Coefficient", value: "coefficientOfThermalExpansion" },
    { name: "Torque", value: "torque" },
    { name: "Volume", value: "volume" }
];

  //Used for state management
  @Input()
  private componentId: string;

  public selectedUnitType: string;
  public fromValue: number;
  public convertedUnit: number;
  public toValue: number;
  public toUnit: string;
  public fromUnit: string;
  public unitOptions = [];

  constructor(private _usersService: UsersService) {}

  public selectedUnitTypeChange(event: any) {
    this.unitOptions = unitsLib.getOptions(event.value);
    this.fromUnit = this.unitOptions[0].value;
    this.toUnit = this.unitOptions[1].value;
    this.fromValue = null;
    this.convertedUnit = null;
  }

  public async convertUnits() {
    const unitType = "Unit" + this.unitTypes.find(x => x.value == this.selectedUnitType).name.replace(/\s/g, '');
    const toUnitSymbol = this.unitOptions.find(x => x.value == this.toUnit).value;
    const fromUnitSymbol = this.unitOptions.find(x => x.value == this.fromUnit).value;
    
    if (!this.fromValue || !this.toUnit || !this.fromUnit) {
      return;
    }
    this.convertedUnit = await lastValueFrom(this._usersService.convertUnits(this.fromValue, unitType, toUnitSymbol, fromUnitSymbol));
  }
}
