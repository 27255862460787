<form [formGroup]="form.formGroup">
  <div class="flex flex-col space-y-2">

    <app-general-epp
      [formControlName]="form.formControlNames.generalEpp"
      [currentString]="currentString"
      [userUnits]="userUnits"
    ></app-general-epp>

    <p class="text-sm">
      Mud Weight: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      {{ currentString?.annularFluid.state.nominalDensity.toFixed(2) }} {{ densityLabel }}
    </p>

    <p class="text-sm">
      Top Of Cement: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      {{ currentString?.topOfCementMd.toFixed(2) }} {{ userUnits.longLengths }}
    </p>

    <p class="text-sm">
      Lead Slurry Density: &nbsp;&nbsp;
      {{ cement?.leadSlurryDensity.toFixed(2) }} {{ densityLabel }}
    </p>

    <p class="text-sm" *ngIf="cement?.tailSlurryLength > 0">
      Tail Slurry Density: &nbsp; &nbsp; &nbsp;
      {{ cement?.tailSlurryDensity.toFixed(2) }} {{ densityLabel}}
    </p>

    <p class="text-sm" *ngIf="cement?.tailSlurryLength > 0">
      Tail Slurry Length: &nbsp; &nbsp; &nbsp;
      {{ cement?.tailSlurryLength.toFixed(2) }} {{ userUnits.longLengths }} 
    </p>
    
  </div>
</form>
