import { UserUnitsModel } from "src/app/core/components/user-units/user-units.model";
import { GetPpgFromValue, GetValueFromPsi } from "./units.helper";
import { unitsLib } from "src/app/core/services/unit-library";

export function GetMudHydrostaticPressure(tvd: number, density: number, units: UserUnitsModel, skipTopPressure?: boolean) {
    let depthInFeet = 0;
    switch(units.longLengths) {
        case 'ft':
            depthInFeet = tvd;
            break;
        case 'm':
            depthInFeet = tvd * 3.28084;
            break;
    }

    const densityInPpg = GetPpgFromValue(density, unitsLib[units.density].symbol);

    const topPressure = skipTopPressure ? 0 : 14.69595;
    const pressureInPsi = depthInFeet * densityInPpg * 7.48051948051947 / 144 + topPressure;

    return GetValueFromPsi(pressureInPsi, units.pressure);
}