export class UserUnitsModel {
    public longLengths = 'ft';
    public shortLengths = 'in';
    public doglegSeverity = 'd-ft';
    public temperatureGradient = '∆°F/ft';
    public linearDensity = 'lb/ft';
    public temperature = '°F';
    public pressure = 'psi';
    public stressPressure = 'psi';
    public force = 'lbf';
    public speed = 'ft/s';
    public density = 'ppg (U.S.)';
    public solidsDensity = 'lbm/ft³';
    public standardFluidFlow = 'gpm';
    public hydrocarbonFlow = 'bbl/d';
    public volume = 'bbl';
    public area = 'in²';
    public thermalConductivity = 'BTU/h·ft·°F';
    public coefficientOfThermalExpansion = '1/°C';
    public gasInjectionRate = 'scfh';
    public massFlow = 'lb/h';
    public specificHeatCapacity = 'BTU/ft³·°F';
    public slurryFlow = `bbl/min`;
    public gasGradient = 'psi/ft';
    public enthalpy = 'BTU/lbm';
    public heat = 'BTU';
    public torque = 'lbf·ft';
  }