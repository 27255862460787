import { Component } from '@angular/core';

@Component({
    selector: 'app-about',
    templateUrl: './about.component.html',
    styles: [''],
    standalone: false
})
export class AboutComponent {

}
