import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { catchError, Observable, of, shareReplay } from 'rxjs';
import { CementingLanding } from 'src/app/wellbore-inputs/models/cementing-landing.model';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class CementingLandingService {
  private cementingLandingCache$: Record<string, Observable<CementingLanding>> = {};

  constructor(private http: HttpClient) {
  }

  public getCementingLanding(tubularId: string | null): Observable<CementingLanding> {
    const cacheKey = tubularId || 'no_tubular_id';
    if (!this.cementingLandingCache$[cacheKey]) {
      let params = new HttpParams();
      if (tubularId) {
        params = params.set('tubularId', tubularId);
      }

      this.cementingLandingCache$[cacheKey] = this.http
        .get<CementingLanding>(`${PeriforApiEndpoint}/cementinglanding`, { params })
        .pipe(
          catchError(() => of(null as CementingLanding)),
          shareReplay(1)
        );
    }
    setTimeout(() => this.cementingLandingCache$[cacheKey] = null, 1000);
    return this.cementingLandingCache$[cacheKey];
  }

  setCementingLanding(cementingLanding: CementingLanding, tubularId: string | null) {
    let params = new HttpParams();
    
    if (tubularId) {
      params = params.set('tubularId', tubularId);
    }
  
    return this.http.put(
      `${PeriforApiEndpoint}/cementinglanding`,
      cementingLanding,
      {
        ...httpOptions,
        params: params
      }
    );
  }
}
