<app-tool-loader [isLoading]="isLoading" positionUnset="true">

  <p-toolbar>
    <ng-template #start>
      <div class="toolbar-menu">
        <p-selectButton [options]="resultsDisplay" [(ngModel)]="liftoffResultsStore.state.selectedResultDisplay"
          (onChange)="onSelectedResultDisplayUpdated($event)" [allowEmpty]="false">
        </p-selectButton>
      </div>
    </ng-template>

    <ng-template #end>
      <span class="validation-error" *ngIf="!wellheadDisabled && liftoffResultsStore.state?.selectedResultDisplay === 'grid'">
        Wellhead Hanger Lockdown Rating: {{ lockdownRating }} {{ forceUnit }}
      </span>
    </ng-template>
  </p-toolbar>

  <app-xy-line-plot [hidden]="liftoffResultsStore.state?.selectedResultDisplay !== 'plot'" [plotData]="plot.data"
    [xAxisTitle]="xAxisTitle" [yAxisTitle]="yAxisTitle" [plotName]="plotName" [downloadPlotName]="downloadPlotName"
    [legendBelowPlot]="true" [annotations]="annotations" [shapes]="shapes" [plotTitle]="'Lift-Off Results'"
    [xyLinePlotStore]="liftoffResultsStore">
  </app-xy-line-plot>

  <app-single-load-grid 
    [hidden]="liftoffResultsStore.state?.selectedResultDisplay !== 'grid'" 
    [rowData]="results"
    [columnDefinitions]="columnDefinitions"
    tableName="Lift-Off Results" 
    [hangerLiftoffForce]="lockdownRating"
    gridName="Lift-Off Results" 
    ></app-single-load-grid>

  <p-dialog [draggable]="false" [(visible)]="errorMsg" [closable]="false">
    <i class="pi pi-exclamation-circle pr-1"></i>
    <label>{{ errorMsg }}</label>
    <br> <br>
  </p-dialog>

</app-tool-loader>