<app-tool-loader [isLoading]="isLoading" positionUnset="true">
  <p-toolbar>
    <ng-template #start>
      <div class="toolbar-menu">
        <p-selectButton [options]="resultsDisplay" [(ngModel)]="casingWearResultsStore.state.selectedResultDisplay"
          (onChange)="onSelectedResultDisplayUpdated($event)" [allowEmpty]="false"></p-selectButton>
      </div>
    </ng-template>

  </p-toolbar>

  <app-single-load-plot 
    [yAxisTitle]="yAxisTitle" [xAxisTitle]="xAxisTitle" [columnDefs]="columnDefs" [data]="results"
    *ngIf="casingWearResultsStore.state?.selectedResultDisplay === 'plot'"
    [selectedDepthView]="casingWearResultsStore.state.selectedDepthView" [depthUnit]="longLengthUnit"
    [plotName]="plotName" [plotTitle]="'Max Allowed Wear'" [componentHeight]="componentHeight"
    [singleLoadPlotStore]="casingWearResultsStore">
  </app-single-load-plot>

  <app-single-load-grid 
    [hidden]="casingWearResultsStore.state?.selectedResultDisplay !== 'grid'" [rowData]="results"
    [columnDefinitions]="columnDefinitions" 
    tableName="Max Allowed Wear"
    gridName="Max Allowed Wear"
    [tableHeight]="tableHeight"
  ></app-single-load-grid>

  <p-dialog [draggable]="false" [(visible)]="errorMsg" [closable]="false">
    <i class="pi pi-exclamation-circle pr-1"></i>
    <label>{{ errorMsg }}</label>
    <br />
    <br />
  </p-dialog>
</app-tool-loader>
