import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { PeriforOnChangeMessages, SignalRService } from 'src/app/shared/services/signal-r.service';
import { BaseFluid, Fluid, FluidType, FluidsUi } from '../../models/fluid.model';
import { EMPTY_GUID } from '../../models/thermal-operation.model';
import { FluidsService } from '../../services/fluids.service';
import { Subscription, debounceTime, lastValueFrom } from 'rxjs';
import { AppNotificationService } from 'src/app/shared/services/app-notification.service';
import { User } from 'src/app/core/components/user-admin-page/user-model';
import { GridItemResizedMessage } from 'src/app/shared/models/mediator-messages.model';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { Store } from '@ngneat/elf';
import { sortBy, cloneDeep } from "lodash-es";
import { GetValueFromFahrenheit } from 'src/app/perivis/shared/helpers/units.helper';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { withUIEntities } from '@ngneat/elf-entities';
import { XyLinePlotUi } from 'src/app/core/models/xy-line-plot.model';

@Component({
    selector: 'app-fluids',
    templateUrl: './fluids.component.html',
    styles: [``],
    providers: [ConfirmationService],
    standalone: false
})
export class FluidsComponent implements OnInit, OnDestroy {

  public fluids: Fluid[];
  public groupedFluids: any = [];
  public selectedFluidType: any;
  public selectedFluids: any = [];
  public isLoading: boolean;
  public phaseEnvelopeCalculating: boolean;
  public phaseEnvelopeCalculated: boolean;
  public hideGergCalc = false;
  public user: User;
  public componentHeight: number;
  public isDefaultFluid: boolean;

  @ViewChild("fluidsListbox") fluidsListbox;

  public fluidTypes = [
    { label: 'Brines', value: 'brine', expanded: true },
    { label: 'Standard Muds', value: 'standardMud', expanded: true },
    { label: 'Black Oil & Gas', value: 'blackOil', expanded: true },
    { label: 'GERG Fluids', value: 'co2Fluid', expanded: true },
    { label: 'Cement Slurries', value: 'cementSlurry', expanded: true },
    { label: 'VLE Reservoir Fluids', value: 'vle', expanded: true },
    // { label: 'Base Oils', value: 'baseOil', expanded: true },
    { label: 'Advanced Muds', value: 'advancedMud', expanded: true }
  ];

  private _isSaving: boolean;
  private _subscriptions: Subscription;
  private _selectedFluidsBackup: any = [];

  //State Management
  private _componentId: string;
  @Input() set componentId(value: string) {
    this._componentId = value;
    this.fluidsStore = this._storeService.createStore(this.componentId, new FluidsUi, withUIEntities<XyLinePlotUi>());
  }
  get componentId(): string {
    return this._componentId;
  }
  public fluidsStore: Store;
  public units: UserUnitsModel;

  constructor(
    private _fluidsService: FluidsService,
    private _confirmationService: ConfirmationService,
    private _signalRService: SignalRService,
    private _toaster: AppNotificationService,
    private _messenger: MediatorService,
    private _storeService: StoreService
  ) {
    this._subscriptions = new Subscription();
    this._subscriptions.add(this._messenger.of(GridItemResizedMessage).subscribe((e) => {
      if (e.name == "Fluids") {
        this.componentHeight = e.itemHeight - 250;

        this.fluidsListbox.listStyle = { 'max-height': `${(e.itemHeight - 70) + 'px'}` };
        this.onFluidSelect({ value: this.fluidsStore.state.selectedFluid });
      }
    }));
  }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;

    this.user = await this._storeService.get<User>(StorageKeys.USER);
    this.units = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);

    if (this.user) {
      const company = this.user.profile?.organization?.toLowerCase();
      const companies = ["altus", "harbour", "baker", "bp", "fervo", "neptune", "shell", "oxy", "cop", "omv"];
      if (!companies.includes(company)) {
        this.hideGergCalc = true;
      }
    }

    if (this.fluidsStore.state.drawerOpen == undefined || this.fluidsStore.state.drawerOpen == null) {
      this.fluidsStore.update(state => ({ ...state, drawerOpen: true }));
    }

    this.getFluids();

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));

  }

  signalRfunc(data: any) {
    if (data.action === PeriforOnChangeMessages.REFRESH_FLUIDS) {
      this.isLoading = this._isSaving ? false : true;
      this.getFluids();
    }
    if (data.action === PeriforOnChangeMessages.REFRESH_PHASE_ENVELOPE) {
      this.phaseEnvelopeCalculating = false;
      this.phaseEnvelopeCalculated = true;
      this._toaster.showSuccess("Phase envelope calculated");
    }
  }

  getFluids(): void {
    this._fluidsService.getFluids().subscribe({
      next: (fluids: Fluid[]) => {
        this.fluids = fluids;
        this.groupedFluids = [];
        this.fluidTypes.forEach(f => {
          this.groupedFluids.push({ type: f.value, fluids: [] });
          const expandedFluids = this.fluidsStore.state.expandedFluids;
          f.expanded = expandedFluids.length > 0 ? expandedFluids.includes(f.value) : true;
        });
        this.fluids.forEach(f => {
          this.groupedFluids.find(g => g.type === f.state.type).fluids.push(f);
        });
        this.groupedFluids.forEach(g => g.fluids = sortBy(g.fluids, (f: Fluid) => f.state.name.toLowerCase()));

        if (!this.selectedFluidType) {
          this.selectedFluidType = ['brine', 'standardMud', 'blackOil', 'vle', 'advancedMud', 'cementSlurry', 'co2Fluid'];
        }
        this.selectedFluids = this.groupedFluids.filter(f => this.selectedFluidType.includes(f.type));
        this._selectedFluidsBackup = cloneDeep(this.selectedFluids);
        this.selectedFluids = this.groupedFluids.filter(f => this.selectedFluidType.includes(f.type)).map(f => ({
          type: f.type,
          fluids: f.fluids.filter(() =>
            this.fluidTypes.find(ft => ft.value === f.type)?.expanded
          )
        }));
        if (!this.fluidsStore.state.selectedFluid) { this.fluidsStore.state.selectedFluid = this.selectedFluids.find(x => x.fluids[0]).fluids[0]; };
        this.isDefaultFluid = this.checkDefaultFluid(this.fluidsStore.state.selectedFluid.state.name);
        this.isLoading = false;
      },
      error: () => {
        this.isLoading = false;
      }
    });
  }

  public expandGroup(group: any): void {
    this.fluidTypes.forEach(f => {
      f.expanded = f.value === group ? !f.expanded : f.expanded;
    });

    this.selectedFluids = this._selectedFluidsBackup.map(f => {
      return {
        type: f.type,
        fluids: f.fluids.filter(() => {
          return this.fluidTypes.find(ft => ft.value === f.type).expanded;
        })
      }
    });
    this.fluidsStore.update(state => ({ ...state, expandedFluids: this.fluidTypes.filter(f => f.expanded).map(f => f.value) }));
  }

  drawerToggle() {
    this.fluidsStore.update(state => ({ ...state, drawerOpen: !state.drawerOpen }));
  }

  updateOrCreateFluid(fluid: Fluid): void {
    const nameExists = this.fluids.find(f => f.id !== fluid.id && f.state.name.toLowerCase() === fluid.state.name.toLowerCase());
    if (nameExists) {
      this._toaster.showError("Fluid name must be unique");
      return;
    }
    if (fluid.state.type === 'blackOil' && !fluid.state.oilApiGravity) {
      fluid.state.oilApiGravity = 0;
    }
    if (fluid.id === EMPTY_GUID) {
      this._fluidsService.createFluid(fluid).subscribe(res => {
        this._fluidsService.getFluidById(res['id']).subscribe(createdFluid => {
          this.fluidsStore.update(state => ({ ...state, selectedFluid: createdFluid }));

          this.groupedFluids.find(g => g.type === createdFluid.state.type).fluids = this.groupedFluids.find(g => g.type === createdFluid.state.type).fluids.map(f => {
            if (f.id === EMPTY_GUID) {
              return createdFluid;
            } else {
              return f;
            }
          });
          this.selectedFluids = this.groupedFluids.filter(f => this.selectedFluidType.includes(f.type));
        });
      });
    } else {
      this._fluidsService.updateFluid(fluid).subscribe(() => {
        this._isSaving = true;
        const updatedFluid: Partial<Fluid> = this.groupedFluids.find(g => g.type === fluid.state.type).fluids.find(f => f.id === fluid.id);
        if (updatedFluid) {
          if (fluid.state.type == FluidType.ADVANCEDMUD) {
            this.fluidsStore.update(state => ({ ...state, selectedFluid: null }));
            this._fluidsService.getFluidById(updatedFluid.id).pipe(debounceTime(1000)).subscribe(fluidRes => {
              updatedFluid.state = fluidRes.state;
              this.fluidsStore.update(state => ({ ...state, selectedFluid: updatedFluid }));
            })
          } else {
            updatedFluid.state = fluid.state;
            this.fluidsStore.update(state => ({ ...state, selectedFluid: updatedFluid }));
          }
        }
      });
    }
  }

  onFluidSelect(e) {
    if (e.value && e.value.id !== EMPTY_GUID) {
      this._fluidsService.getFluidById(e.value.id).subscribe(fluid => {
        this.fluidsStore.update(state => ({ ...state, selectedFluid: fluid }));
        console.log('Updated selectedFluid:', fluid);
      });
      this.isDefaultFluid = this.checkDefaultFluid(e.value.state.name);
    }
  }

  public checkDefaultFluid(fluidName: string): boolean {
    return fluidName === 'Seawater' || fluidName === 'Freshwater' || fluidName === 'Diesel' || fluidName === 'Pure CO2';
  }

  deleteFluid(fluid: Fluid): void {
    if (fluid.id === EMPTY_GUID) {
      this.selectFluidAfterDelete(fluid);
      return;
    }
    this._confirmationService.confirm({
      message: 'Are you sure that you want to delete ' + fluid.state.name.bold() + '?',
      accept: () => {
        this.isLoading = true;
        this._fluidsService.deleteFluid(fluid.id).subscribe(() => {
          this.selectFluidAfterDelete(fluid);
          this.isLoading = false;
        });
      }
    });
  }

  private selectFluidAfterDelete(fluid) {
    const fluidGroup = this.groupedFluids.find(g => g.type === fluid.state.type);
    const index = fluidGroup.fluids.findIndex(x => x.id === fluid.id);
    fluidGroup.fluids.splice(index, 1);
    this.groupedFluids = [...this.groupedFluids];
    this.selectedFluids = this.groupedFluids.filter(f => this.selectedFluidType.includes(f.type));
    this.fluidsStore.update(state => ({ ...state, selectedFluid: this.selectedFluids.find(x => x.fluids[0]).fluids[0] }));
  }

  cloneFluid(fluid: Fluid): void {
    this.isLoading = true;
    const fluidName = fluid.state.name + ' - Copy';
    this._fluidsService.cloneFluid(fluid.id, fluidName).subscribe(res => {
      this._fluidsService.getFluidById(res.id).subscribe(res => {
        this.groupedFluids.find(g => g.type === res.state.type).fluids.push(res);
        this.selectedFluids = this.groupedFluids.filter(f => this.selectedFluidType.includes(f.type));
        this.fluidsStore.update(state => ({ ...state, selectedFluid: res }));
        this.isDefaultFluid = false;
        this.isLoading = false;
      });
    });
  }

  findDisplayName(fluidType) {
    return this.fluidTypes.find(f => f.value === fluidType).label;
  }

  addFluid(fluidType: string): void {
    const newFluid: Fluid = {
      id: EMPTY_GUID,
      isInUse: false,
      state: this.createDefaultFluid(fluidType)
    };
    this.fluidsStore.update(state => ({ ...state, selectedFluid: newFluid }));
    const typeGroup = this.groupedFluids.find(g => g.type === fluidType);
    if (typeGroup) {
      typeGroup.fluids = [...typeGroup.fluids, newFluid];
    }
    this.groupedFluids = [...this.groupedFluids];
    this.selectedFluids = this.groupedFluids.filter(f => this.selectedFluidType.includes(f.type));
    this.isDefaultFluid = false;
  }

  createDefaultFluid(fluidType): BaseFluid {
    const tempUnit = this.user.units.temperature;

    let defaultDensitySw = 8.6;
    let defaultDensityFw = 8.33;
    let defaultDensitySlurry = 15.8;
    switch (this.user.units.density) {
      case 'kg/m³':
      case 'g/L':
        {
          defaultDensitySw = 1030.51;
          defaultDensityFw = 998.1;
          defaultDensitySlurry = 1893;
          break;
        }
      case 'g/cm³':
      case 'kg/l':
      case 'sg':
        {
          defaultDensitySw = 1.03;
          defaultDensityFw = 1;
          defaultDensitySlurry = 1.89;
          break;
        }
      default: {
        break;
      }
    }

    switch (fluidType) {
      case 'brine':
        return {
          name: 'New Fluid',
          type: FluidType.BRINE,
          brineType: 'seawater',
          nominalDensity: defaultDensitySw,
          plasticViscosity: 1,
          viscosityTemperature: GetValueFromFahrenheit(70, tempUnit),
        };
      case 'standardMud':
        return {
          name: 'New Fluid',
          type: FluidType.STANDARDMUD,
          baseFluid: 'freshwater',
          nominalDensity: defaultDensityFw,
          densityTemperature: GetValueFromFahrenheit(70, tempUnit),
          plasticViscosity: 1,
          rheologyTemperature: GetValueFromFahrenheit(70, tempUnit),
          yieldPoint: 0
        };
      case 'blackOil':
        return {
          name: 'New Fluid',
          type: FluidType.BLACKOIL,
          oilApiGravity: 32,
          gasComposition: null
        };
      case 'baseOil':
        return {
          name: 'New Fluid',
          type: FluidType.BASEOIL,
          a0: -3.6058,
          a1: 0.464,
          a2: -1.6843,
          b0: 8.7071,
          b1: 3.6031,
          b2: -72.465
        };
      case 'cementSlurry':
        return {
          name: 'New Fluid',
          type: FluidType.CEMENTSLURRY,
          density: defaultDensitySlurry,
          mixWaterDensity: defaultDensityFw,
          densityTemperature: GetValueFromFahrenheit(120, tempUnit),
          fann600: 120,
          fann300: 85,
          fann200: 74,
          fann100: 58,
          fann6: 22,
          fann3: 17,
          rheologyTemperature: GetValueFromFahrenheit(120, tempUnit)
        }
      case 'vle':
        return {
          name: 'New Fluid',
          type: FluidType.VLE,
          c1: 100,
          c2: 0,
          c3: 0,
          nC4: 0,
          iC4: 0,
          nC5: 0,
          iC5: 0,
          nC6: 0,
          n2: 0,
          cO2: 0,
          h2S: 0,
          h2O: 0,
          vleHeavyWeightHydrocarbonComponents: null,
          vleTuningParameters: null
        }
      case 'co2Fluid':
        return {
          name: 'New Fluid',
          type: FluidType.CO2FLUID,
          co2withImpurities: false,
          h2O: 0,
          cO: 0,
          h2s: 0,
          sO2: 0,
          h2SO4: 0,
          n2: 0,
          nO2: 0,
          o2: 0,
          h2: 0,
          ar: 0,
          cH4: 0,
          c2: 0,
          c3: 0,
          nC4: 0,
          iC4: 0,
          nC5: 0,
          iC5: 0,
          nC6: 0,
          c7: 0,
          c8: 0
        }
      case 'advancedMud':
        return {
          name: 'New Fluid',
          type: FluidType.ADVANCEDMUD,
          waterFraction: 100,
          oilFraction: 0,
          baseOil: 'dieselOil',
          baseBrine: this.fluids.filter(f => f.state.type === 'brine')[0] || null,
          highDensitySolidsFraction: 0,
          highDensityMudSolidsType: 'barite',
          lowDensitySolidsFraction: 0,
          lowDensityMudSolidsType: 'bentonite',
          plasticViscosity: 1,
          yieldPoint: 0,
          rheologyTemperature: GetValueFromFahrenheit(70, tempUnit),
          densityTemperature: GetValueFromFahrenheit(70, tempUnit),
          // density: 8.44
        }
    }
  }

  public async calcGerg(fluid: Fluid) {
    this._toaster.showInfo("Started calculating GERG-2008 Phase Envelope");
    this.phaseEnvelopeCalculating = true;
    await lastValueFrom(this._fluidsService.calculateGerg(fluid));
  }

  ngOnDestroy(): void {
    if (this.fluidsStore.state.selectedFluid.id === EMPTY_GUID) {
      this.fluidsStore.update(state => ({ ...state, selectedFluid: this.fluids[0] }));
    }
    this._subscriptions?.unsubscribe();
    this.signalRfunc = null;
  }
}
