import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { PeriforOnChangeMessages, SignalRService } from 'src/app/shared/services/signal-r.service';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { StressResultsService } from '../../services/stress-results.service';
import { Subscription, lastValueFrom } from 'rxjs';
import { toCamelCaseString } from 'src/app/shared/helpers/stringToCamel.helper';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { Store } from '@ngneat/elf';
import { LoadPressureResultsUi } from '../../models/perivis-results-state.model';
import { unitsLib } from 'src/app/core/services/unit-library';
import { withUIEntities } from '@ngneat/elf-entities';
import { XyLinePlotUi } from 'src/app/core/models/xy-line-plot.model';

@Component({
    selector: 'app-pressure-results',
    templateUrl: './pressure-results.component.html',
    styles: [],
    standalone: false
})
export class PressureResultsComponent implements OnInit, OnDestroy {
  private _displacementResults: any[];
  private pressureUnit: string;
  private _subscriptions: Subscription;

  public yAxisTitle = '';
  public xAxisTitle = '';
  public plotName: string;
  public isLoading: boolean;
  public errorMsg: string;
  public results: any[];
  public columnDefs: { header: string, field: string }[];
  public columnDefinitions: any[];
  public selectedLoadCase: any;
  public resultsDisplay: SelectItem[] = [
    { label: 'Plot', value: 'plot' },
    { label: 'Grid', value: 'grid' }
  ];
  public resultsView: SelectItem[] = [
    { label: 'IPP', value: 'internalPressure' },
    { label: 'EPP', value: 'externalPressure' },
    { label: 'DIFF', value: 'differentialPressure' }
  ];
  public depthView = [
    { label: 'TVD', value: 'tvd' },
    { label: 'MD', value: 'md' }
  ];
  public depthUnit: string;
  public tableHeight: string;

  //State Management
  private _componentId: string;
  @Input() set componentId(value: string) {
    this._componentId = value;
    this.pressureResultsStore = this._storeService.createStore(this.componentId, new LoadPressureResultsUi, withUIEntities<XyLinePlotUi>());
  }
  get componentId(): string {
    return this._componentId;
  }
  public pressureResultsStore: Store;

  constructor(
    private _stressResultsService: StressResultsService,
    private _signalRService: SignalRService,
    private _storeService: StoreService
  ) {
    this._subscriptions = new Subscription();
  }

  async ngOnInit(): Promise<void> {
    this.isLoading = true;
    const units = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);
    this.depthUnit = units.longLengths;
    this.pressureUnit = unitsLib[units.pressure].symbol;

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));

    this.plotName = 'pressureResultsPlot - ' + this.pressureResultsStore.state.selectedResultsView;

    this.refreshResults(false);
  }

  signalRfunc(data: any) {
    if (data.action == PeriforOnChangeMessages.REFRESH_RESULTS) {
      this.refreshResults(true);
    }
  }

  resultsViewToggle(e) {
    this.pressureResultsStore.update(state => ({ ...state, selectedResultsView: e.value }));
    this.plotName = 'pressureResultsPlot - ' + this.pressureResultsStore.state.selectedResultsView;
    this.refreshResults(false);
  }

  onSelectedResultDisplayUpdated(e) {
    this.pressureResultsStore.update(state => ({ ...state, selectedResultDisplay: e.value }));
  }

  onSelectedDepthViewUpdated(e) {
    this.pressureResultsStore.update(state => ({ ...state, selectedDepthView: e.value }));
  }

  public async refreshResults(calledFromSignalR: boolean): Promise<void> {
    this.isLoading = true;
    this.results = [];

    if (!this._displacementResults || calledFromSignalR) {
      try {
        this._displacementResults = await lastValueFrom(this._stressResultsService.getDisplacementResults());
      } catch {
        this.errorMsg = "Well Configuration Missing: Please specify at least one string";
        this.isLoading = false;
        return;
      }
    }

    if (this._displacementResults.length === 0 || this._displacementResults[0].loadCaseName === '') {
      this.errorMsg = "Please calculate Perivis to see results";
      this.columnDefs = [];
      this.results = [];
      this.isLoading = false;
      return;
    } else {
      this.errorMsg = null;
    }

    this.handlePressureResults(this._displacementResults);

    this.isLoading = false;
  }

  private handlePressureResults(displacementResults: any[]) {
    this.columnDefinitions = [
      {
        header: `MD (${this.depthUnit})`, field: 'measuredDepth',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      },
      {
        header: `TVD (${this.depthUnit})`, field: 'trueVerticalDepth',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      }
    ];
    this.columnDefs = [];
    this.results = [];

    this.yAxisTitle = `MD (${this.depthUnit})`;

    switch (this.pressureResultsStore.state.selectedResultsView) {
      case 'internalPressure':
        this.xAxisTitle = 'Internal Pressure (' + this.pressureUnit + ')';
        break;
      case 'externalPressure':
        this.xAxisTitle = 'External Pressure (' + this.pressureUnit + ')';
        break;
      case 'differentialPressure':
        this.xAxisTitle = 'Differential Pressure (' + this.pressureUnit + ')';
        break;
    }

    displacementResults.forEach(load => {
      const loadName: string = load.loadCaseName + ' (' + this.pressureUnit + ')';
      const loadNameCamel = toCamelCaseString(load.loadCaseName);

      this.columnDefs.push({ header: load.loadCaseName, field: loadNameCamel });

      this.columnDefinitions.push({
        header: loadName,
        field: loadNameCamel,
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      });

      const backupArray = [];

      load.displacementResults.forEach(loadResult => {
        loadResult.differentialPressure = loadResult.internalPressure - loadResult.externalPressure;

        const result = {
          [loadNameCamel]: loadResult[this.pressureResultsStore.state.selectedResultsView],
          measuredDepth: loadResult.measuredDepth,
          trueVerticalDepth: loadResult.trueVerticalDepth
        }

        if (displacementResults[0].loadCaseName === load.loadCaseName) {
          this.results.push(result);
        } else {
          backupArray.push(result);
        }
      });

      this.results = this.results.map(x => {
        const otherLoad = backupArray.find(e => e.measuredDepth === x.measuredDepth)
        return { ...x, ...otherLoad }
      });
    });
  }

  private formatDecimal(value: any, divisor: number): string {
    return value || value === 0 ? (Math.trunc(value * divisor) / divisor).toLocaleString('en-US') : null;
  }

  ngOnDestroy(): void {
    this._subscriptions?.unsubscribe();
    this.signalRfunc = null;
  }
}
