import { AfterContentInit, Component, Input } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { FullEvacIPP, InternalPressureProfileType } from '../../models/internal-pressure-profile.model';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';

interface FullEvacIppForm {
  type: InternalPressureProfileType.FULLEVAC
}

@Component({
    selector: 'app-full-evac-ipp',
    template: `
    <div class="p-field p-grid">
    <div class="field col-12">
        <app-uom-input
          label="Fluid Level"
          [inputDisabled]="true"
          [default]="currentString.shoeMd.toFixed(3)"
          [unitLabel]="userUnits.longLengths"
        />
      </div>
    </div>
  `,
    providers: subformComponentProviders(FullEvacIppComponent),
    standalone: false
})
export class FullEvacIppComponent implements AfterContentInit {
  @Input() currentString: any;
  @Input() userUnits: UserUnitsModel;

  public form = createForm<FullEvacIPP, FullEvacIppForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(InternalPressureProfileType.FULLEVAC)
    },
    toFormGroup: (obj: FullEvacIPP | null): FullEvacIppForm | null => {
      if (!obj) {
        return null;
      }

      return {
        type: InternalPressureProfileType.FULLEVAC
      }
    },
    fromFormGroup: (formValue: FullEvacIppForm): FullEvacIPP | null => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { type, ...commonValues} = formValue;

      return { type: InternalPressureProfileType.FULLEVAC, ...commonValues }
    }
  });

  ngAfterContentInit(): void {
    this.form.formGroup.controls.type.setValue(InternalPressureProfileType.FULLEVAC);
  }
}
