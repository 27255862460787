<app-tool-loader [isLoading]="isLoading" positionUnset="true">
    <div #toolbarDiv>
        <p-toolbar>
            <ng-template #start>
                <div class="flex items-center space-x-2 toolbar-menu">
                    <p-select [options]="singleMultiple" (onChange)="modeToggle($event.value)"
                        [(ngModel)]="operationEnvelopeStore.state.selectedSingleMultiple" appendTo="body">
                    </p-select>

                    <p-select [options]="phaseEnvelopes" (onChange)="onPhaseEnvelopeSelected($event)"
                        [(ngModel)]="operationEnvelopeStore.state.selectedPhaseEnvelope" optionLabel="fluid.state.name"
                        appendTo="body">
                    </p-select>

                    <p-selectButton *ngIf="operationEnvelopeStore.state?.selectedSingleMultiple === 'singleOperation'"
                        [options]="resultsDisplay" [(ngModel)]="operationEnvelopeStore.state.selectedResultsDisplay"
                        (onChange)="onSelectedResultsDisplayUpdated($event)" [allowEmpty]="false">
                    </p-selectButton>

                    <p-selectButton *ngIf="operationEnvelopeStore.state?.selectedResultsDisplay === 'grid'"
                        [options]="envelopeOperation" [(ngModel)]="operationEnvelopeStore.state.selectedEnvelopeOperation"
                        (onChange)="selectedTableChange($event)" [allowEmpty]="false">
                    </p-selectButton>
                </div>
            </ng-template>
            
            <ng-template #end>
                <div class="flex items-center space-x-2">
                    <p-select *ngIf="operationEnvelopeStore.state?.selectedSingleMultiple === 'singleOperation'"
                        [options]="operations" (onChange)="onOperationSelected($event)"
                        [(ngModel)]="operationEnvelopeStore.state.selectedOperation" optionLabel="name" appendTo="body">
                    </p-select>
                    <p-button outlined="true" *ngIf="operationEnvelopeStore.state?.selectedSingleMultiple === 'singleOperation'"
                        [disabled]="moveUpDisabled" (onClick)="onUpDownButtonClick('up')" icon="pi pi-arrow-up"
                        class="upDownButtons">
                    </p-button>
                    <p-button outlined="true" *ngIf="operationEnvelopeStore.state?.selectedSingleMultiple === 'singleOperation'"
                        [disabled]="moveDownDisabled" (onClick)="onUpDownButtonClick('down')" icon="pi pi-arrow-down"
                        class="upDownButtons">
                    </p-button>
                </div>
            </ng-template>
        </p-toolbar>
    </div>

    <app-ng-table-results 
        *ngIf="operationEnvelopeStore.state?.selectedSingleMultiple === 'singleOperation' 
               && operationEnvelopeStore.state?.selectedResultsDisplay !== 'plot'" 
        class="mt-2" 
        tableName="{{ tableName }}"
        [tableData]="resultsGrid" 
        [columnDefinitions]="cols" 
        [tableHeight]="tableHeight" 
    />

    <div class="plot" *ngIf="operationEnvelopeStore.state?.selectedResultsDisplay === 'plot'">
        <app-xy-line-plot
            [plotData]="plot.data"
            [xAxisTitle]="xAxisTitle"
            [yAxisTitle]="yAxisTitle"
            [plotName]="plotName"
            [downloadPlotName]="downloadPlotName"
            [reverseAutoRange]="true"
            [plotTitle]="tableName"
            [legendGroupClick]="'toggle'"
            [plotOrientation]="''"
            [componentHeight]="componentHeight"
            [xyLinePlotStore]="operationEnvelopeStore"
        ></app-xy-line-plot>
    </div>
    <app-xy-line-plot [hidden]="operationEnvelopeStore.state?.selectedResultsDisplay !== 'plot'" class="mt-2"
        [plotData]="plot.data" [xAxisTitle]="xAxisTitle" [yAxisTitle]="yAxisTitle" [plotName]="plotName"
        [downloadPlotName]="downloadPlotName" [reverseAutoRange]="true" [plotTitle]="tableName"
        [legendGroupClick]="'toggle'" [plotOrientation]="''" [componentHeight]="componentHeight">
    </app-xy-line-plot>

    <p-dialog [draggable]="false" [(visible)]="needToCalculate" [closable]="false">
        <i class="pi pi-exclamation-circle"></i> {{ calculationError }}
        <br> <br>
    </p-dialog>

</app-tool-loader>