<form [formGroup]="form.formGroup">
  <table>
    <thead>
      <tr>
        <th>Name</th>
        <th>Fraction %</th>
        <th>Molecular Weight</th>
        <th>Specific Gravity</th>
        <th class="text-center">
          <p-button
            size="small"
            outlined="true"
            severity="success"
            icon="pi pi-plus"
            (click)="addHeavy()"
            pTooltip="Add Heavy"
            [disabled]="form.formGroup.controls.vleHeavyWeightHydrocarbonComponents.controls.length >= 3"
        ></p-button>
        </th>
      </tr>
    </thead>
    <tbody formArrayName="vleHeavyWeightHydrocarbonComponents">
      <tr *ngFor="let vleItem of form.formGroup.controls.vleHeavyWeightHydrocarbonComponents.controls; let i = index" [formGroupName]="i">
        <td>
          <input formControlName="name" required pInputText />
        </td>
        <td>
          <p-input-number formControlName="fraction" required  />
        </td>
        <td>
          <p-input-number formControlName="molecularWeight" required />
        </td>
        <td>
          <p-input-number formControlName="specificGravity" required />
        </td>
        <td class="text-center">
          <p-button
            text
            size="small"
            severity="danger"
            icon="pi pi-trash"
            (click)="removeHeavy(i)"
            [disabled]="form.formGroup.disabled"
            pTooltip="Delete Heavy"
          ></p-button>
        </td>
      </tr>
    </tbody>
  </table>

</form>
