<form [formGroup]="form.formGroup">
  <div class="flex flex-col space-y-2">
    
    <app-general-epp
      [formControlName]="form.formControlNames.generalEpp"
      [currentString]="currentString"
      [userUnits]="userUnits"
    ></app-general-epp>

    <div class="flex flex-col">
      <label>Cement Mix-Water Density</label>
      <app-validation-error
        [isValid]="isControlInvalid(form.formGroup, 'cementMixwaterDensity')"
        [errorMsg]="getControlErrors(form.formGroup, 'cementMixwaterDensity')"
      />
      <p-inputgroup>
        <p-inputNumber
          [formControlName]="form.formControlNames.cementMixwaterDensity"
          [maxFractionDigits]="2"
          title="Allowed range: {{ densityValidation.min }} - {{
            densityValidation.max
          }} {{ densityLabel }}"
          (onFocus)="onInputFocus($event)"
        ></p-inputNumber>
        <p-inputgroup-addon>{{ densityLabel }}</p-inputgroup-addon>
      </p-inputgroup>
    </div>

    <p class="text-sm">
      Mud Weight: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      {{ currentString?.annularFluid.state.nominalDensity.toFixed(2) }}
      {{ densityLabel }}
    </p>

    <p class="text-sm">
      Top Of Cement: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      {{ currentString?.topOfCementMd.toFixed(2) }} {{ userUnits.longLengths }}
    </p>

    <p class="text-sm">
      Lead Slurry Density: &nbsp;&nbsp;
      {{ cement?.leadSlurryDensity }} {{ densityLabel }}
    </p>
    
    <p class="text-sm" *ngIf="cement?.tailSlurryLength > 0">
      Tail Slurry Density: &nbsp; &nbsp; &nbsp;{{ cement?.tailSlurryDensity.toFixed(2) }}
      {{ densityLabel }}
    </p>

    <p class="text-sm" *ngIf="cement?.tailSlurryLength > 0">
      Tail Slurry Length: &nbsp; &nbsp; &nbsp;{{ cement?.tailSlurryLength.toFixed(2) }}
      {{ userUnits.longLengths }}
    </p>

  </div>
</form>
