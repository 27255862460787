<div *ngIf="tubular" class="w-full bg-[var(--p-toolbar-background)] opacity-95 rounded-lg p-2 relative">
    
    <button pButton type="button" text icon="pi pi-times" class="absolute top-0 right-0 p-0 text-red-400 p-1" (click)="handleClose()"></button>
    
    <dl class="max-w-[200px] min-w-[100px] flex flex-wrap text-xs">
        <dt class="text-right font-medium pr-1 w-[60%]">Type:</dt> 
        <dd class="pl-1 ml-auto w-[40%]">{{tubular?.type}}</dd>
        
        <ng-container *ngIf="tubular?.holeSize > 0">
            <dt class="text-right font-medium pr-1 w-[60%]">Hole Size ({{ units.short }}):</dt> 
            <dd class="pl-1 ml-auto w-[40%]">{{tubular?.holeSize.toFixed(2) | number:'1.0-4'}}</dd>
        </ng-container>
        
        <dt class="text-right font-medium pr-1 w-[60%]">Hanger Depth ({{ units.long }}):</dt> 
        <dd class="pl-1 ml-auto w-[40%]">{{tubular?.hangerMd.toFixed(2) | number:'1.0-4'}}</dd>
        
        <dt class="text-right font-medium pr-1 w-[60%]">Shoe Depth ({{ units.long }}):</dt> 
        <dd class="pl-1 ml-auto w-[40%]">{{tubular?.shoeMd.toFixed(2) | number:'1.0-4'}}</dd>
        
        <dt class="text-right font-medium pr-1 w-[60%]">TOC ({{ units.long }}):</dt> 
        <dd class="pl-1 ml-auto w-[40%]">{{tubular?.topOfCementMd.toFixed(2)| number:'1.0-4'}}</dd>
        
        <dt class="text-right font-medium pr-1 w-[60%]">Sections:</dt>
        <dd class="pl-1 ml-auto w-[40%]">{{tubular?.stringSections.length}}</dd>
    </dl>
    
    <table class="table-auto w-full border-collapse border border-gray-300 text-xs">
        <thead>
            <tr class="border border-gray-300">
                <th class="border border-gray-300 px-2 py-1">Btm</th>
                <th class="border border-gray-300 px-2 py-1">OD</th>
                <th class="border border-gray-300 px-2 py-1">ID</th>
                <th class="border border-gray-300 px-2 py-1">Grade</th>
                <th *ngIf="hasConnections" class="border border-gray-300 px-2 py-1">Connection</th>
            </tr>
        </thead>
        <tbody>
            <tr *ngFor="let t of tubular.stringSections" class="border border-gray-300">
                <td class="border border-gray-300 px-2 py-1">{{ t.bottomMeasuredDepth | number:'1.0-4' }}</td>
                <td class="border border-gray-300 px-2 py-1">{{ t.pipe.outsideDiameter | number:'1.0-4' }}</td>
                <td class="border border-gray-300 px-2 py-1">{{ t.pipe.insideDiameter | number:'1.0-4' }}</td>
                <td class="border border-gray-300 px-2 py-1">{{ t.grade.name }}</td>
                <td *ngIf="hasConnections" class="border border-gray-300 px-2 py-1">{{ t.connection?.name }}</td>
            </tr>
        </tbody>
    </table>
    
</div>