import { Choke } from './chokes.model';
import { Flow } from './flow.model';
import { Fluid } from './fluid.model';
import { Perforation } from './perforation.model';
import { Reservoir } from './reservoirPerformance';

export interface BaseOperation {
  id: string;
  name: string;
  time: { value: number, unit: string };
  inside: SingleOperation;
  annulus: Partial<SingleOperation>;
  previousOperationId: string;
  volume: {
    volumeSelected: boolean;
    volumeSelection: string;
    volumeValue: number
  }
}

export type SingleOperation = ProductionOperation | InjectionOperation | ShutInOperation |
 CirculationOperation | GasLiftOperation | DrillingOperation | RunCasingAndCirculate |
  CementingOperation | TripPipeAndCirculate | null;

export enum OperationType {
  PRODUCTION = 'production',
  INJECTION = 'injection',
  SHUTIN = 'shutIn',
  CIRCULATION = 'circulation',
  GASLIFT = 'gasLift',
  DRILLING = 'drilling',
  CEMENTING = 'cementing',
  RUNCASINGANDCIRCULATE = 'runCasingAndCirculate',
  TRIPPIPEANDCIRCULATE = 'tripPipeAndCirculate'
}

export interface InjectionOperation {
  type: OperationType.INJECTION;
  wellheadPressure: number;
  inletTemperature: number;
  fluid: Fluid;
  perforation: Perforation;
  flow: Flow;
  pressureLocation: string;
  enthalpyOptionsSource: any;
  nodalAnalysis: {
    upstreamPressure: number;
    upstreamTemperature: number;
    pvtConditions: string;
    reservoir: Reservoir;
    choke: Choke;
    // chokeFlowCheck: boolean;
    includeRestartOperation: boolean;
    restartOperation: {
      totalTimeToOpenChoke: { value: number, unit: string };
      initialTimeToOpenChoke: { value: number, unit: string };
      terminalChokeOpenPercent: number;
      initialChokeOpenPercent: number;
      allowTransientFlow: boolean;
    }
  };
  customEnthalpyOptions: {
    successiveRelationFactor: number;
    latentHeatDampingFactor: number;
    includeLatentHeatPhaseChange: boolean;
  }
  shutIn: {
    time: number;
    pressure: {
      pressure: number;
      location: string;
    }
  }
  annulusIndex: number;
}

export interface ProductionOperation {

  readonly type : OperationType.PRODUCTION;

  perforationPressure: number;
  fluid: Fluid;
  perforation: Perforation;
  flow: Flow;
  pressureLocation: string;
  enthalpyOptionsSource: any;
  nodalAnalysis: {
    upstreamPressure: number;
    upstreamTemperature: number;
    pvtConditions: string;
    reservoir: Reservoir;
    choke: Choke;
    // chokeFlowCheck: boolean;
    includeRestartOperation: boolean;
    restartOperation: {
      totalTimeToOpenChoke: { value: number, unit: string };
      initialTimeToOpenChoke: { value: number, unit: string };
      terminalChokeOpenPercent: number;
      initialChokeOpenPercent: number;
      allowTransientFlow: boolean;
    }
  };
  customEnthalpyOptions: {
    successiveRelationFactor: number;
    latentHeatDampingFactor: number;
    includeLatentHeatPhaseChange: boolean;
  }
  shutIn: {
    time: number;
    pressure: {
      pressure: number;
      location: string;
    }
  },
  annulusIndex: number;
}

export interface ShutInOperation {
  readonly type: OperationType.SHUTIN;
  wellheadPressure: number;
  pressureLocation: string;
  annulusIndex: number;
}

export interface CirculationOperation {
  readonly type: OperationType.CIRCULATION;
  fluid: Fluid;
  flow: Flow;
  circulationParameters: {
    circulationMd: number;
    circulationDirection: string;
    inletTemperature: number;
    pressure: number;
    pressureLocation: string;
  };
  useMudPits: boolean;
  mudPits: MudPit;
  annulusIndex: number;
}

export interface GasLiftOperation {
  readonly type: OperationType.GASLIFT;
  fluid: Fluid;
  gasLiftValveDepth: number;
  injectionPressure: number;
  inletTemperature: number;
  gasRate: number;
}

export interface DrillingOperation {
  readonly type: OperationType.DRILLING;
  flow: Flow;
  fluid: Fluid;
  riser: {
    outsideDiameter;
    insideDiameter;
  };
  startDepth: number;
  endDepth: number;
  inletTemperature: number;
  totalFlowArea: number;
  isPoolOutOfHole: boolean;
  poolOutOfHoleTime: number;
  isConditionHole: boolean;
  conditionHoleTime: number;
  isRunInHole: boolean;
  runInHoleTime: number;
  useMudPits: boolean;
  mudPits: MudPit;
  annulusIndex: number;
}

export interface CementingOperation {
  readonly type: OperationType.CEMENTING
  drillingFluid: Fluid;
  slurryPumpRate: number;
  slurryInletTemperature: number;
  leadSlurry: Fluid;
  tailCement: {
    slurry: Fluid;
    length: number;
  };
  cementDisplacement: {
    fluid: Fluid;
    inletTemperature: number;
  };
  displacementRate: number;
  cementSetTime: number;
  pipeRoughness: number;
  riser: {
    outsideDiameter;
    insideDiameter;
  };
  useMudPits: boolean;
  mudPits: MudPit;
  annulusIndex: number;
}

export interface RunCasingAndCirculate {
  readonly type: OperationType.RUNCASINGANDCIRCULATE;
  fluid: Fluid;
  flow: Flow;
  circulationTime: number;
  tripIntoHole: number;
  inletTemperature: number;
  staticPeriod: number;
  pipeRoughness: number;
  riser: {
    outsideDiameter;
    insideDiameter;
  };
  annulusIndex: number;
}

export interface TripPipeAndCirculate {
  readonly type: OperationType.TRIPPIPEANDCIRCULATE;
  fluid: Fluid;
  flow: Flow;
  circulationTime: number;
  tripIntoHole: number;
  inletTemperature: number;
  riser: {
    outsideDiameter;
    insideDiameter;
  };
  circulationDepth: number;
  isPullOutOfHole: boolean;
  pullOutOfHoleTime: number;
  pipeRoughness: number;
  totalFlowArea: number;
  isNextStringInPlace: boolean;
  annulusIndex: number;
}

export interface MudPit {
  isNewMudTemperature: string;
  newMudTemperature: number;
  airTemperature: number;
  airVelocity: number;
  numberOfTanks: number;
  nominalVolume: number;
  activeVolumeFraction: number;
  exposedSurfaceArea: number;
  agitatorPower: number;
}

export interface OperationForm {
  id: string;
  name: string;
  time: number;
  timeUnits: string;
  inside: SingleOperation;
  annulus: Partial<SingleOperation>;
  previousOperationId: string;
  volumeSelected: boolean;
  volumeSelection: string;
  volumeValue: number;
}

export const EMPTY_GUID = '0000-0000-0000-0000';
