import { UntypedFormControl, Validators } from '@angular/forms';
import { AfterViewInit, Component } from '@angular/core';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';

export interface GasCompositionForm {
  c1: number;
  c2: number;
  c3: number;
  nC4: number;
  iC4: number;
  nC5: number;
  iC5: number;
  nC6: number;
  averageMolWeightC7ToC10: number;
  c7C10: number;
  co2: number;
  h2S: number;
  n2: number;
}

@Component({
    selector: 'app-gas-composition',
    templateUrl: './gas-composition.component.html',
    styles: [``],
    providers: subformComponentProviders(GasCompositionComponent),
    standalone: false
})
export class GasCompositionComponent implements AfterViewInit {

  public totalMolWeight: number;

  private molWeights = {
    air: 28.967,
    c1: 16.04,
    c2: 30.07,
    c3: 44.1,
    c4: 58.12,
    c5: 72.15,
    c6: 86.18,
    co2: 44.01,
    h2S: 34.08,
    n2: 28.0134
  };



  public form = createForm<GasCompositionForm>(this, {
    formType: FormType.SUB,
    formControls: {
      c1: new UntypedFormControl(100, [Validators.required, Validators.min(0), Validators.max(100)]),
      c2: new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(100)]),
      c3: new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(100)]),
      nC4: new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(100)]),
      iC4: new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(100)]),
      nC5: new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(100)]),
      iC5: new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(100)]),
      nC6: new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(100)]),
      averageMolWeightC7ToC10: new UntypedFormControl(120, [Validators.required]),
      c7C10: new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(100)]),
      co2: new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(100)]),
      h2S: new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(100)]),
      n2: new UntypedFormControl(0, [Validators.required, Validators.min(0), Validators.max(100)])
    }
  });

  public validatePercentage(updatedInput, e) {
    let sum = 0;

    Object.entries(this.form.formGroup.controls).forEach(res => {
      if (res[0] !== 'averageMolWeightC7ToC10' && res[0] !== 'c7C10' && res[0] !== updatedInput) {
        sum += res[1].value;
      }
    });
    sum += e.value;

    sum = +sum.toFixed(5);

    this.form.formGroup.controls.c7C10.setValue(100 - sum);
    this.calculateGasGravity(updatedInput, e);
  }

  public calculateGasGravity(updatedInput, e) {
    const val = this.form.formGroup.value;
    Object.entries(val).forEach(el => {
      if (el[0] === updatedInput) {
        val[el[0]] = e.value;
      }
    });
    const totalMolWeight = (val.co2 * this.molWeights.co2 + val.h2S * this.molWeights.h2S + val.n2 * this.molWeights.n2
      + val.c1 * this.molWeights.c1 + val.c2 * this.molWeights.c2 + val.c3 * this.molWeights.c3
      + (val.iC4 + val.nC4) * this.molWeights.c4 + (val.iC5 + val.nC5) * this.molWeights.c5 + val.nC6 * this.molWeights.c6
      + val.c7C10 * val.averageMolWeightC7ToC10) / 100;
    this.totalMolWeight = +(totalMolWeight / this.molWeights.air).toFixed(3);
  }

  ngAfterViewInit(): void {
    this.calculateGasGravity(null, null);
  }

  public applyDefaults(): void {
    this.form.formGroup.controls.c1.setValue(100);
    this.form.formGroup.controls.c2.setValue(0);
    this.form.formGroup.controls.c3.setValue(0);
    this.form.formGroup.controls.nC4.setValue(0);
    this.form.formGroup.controls.iC4.setValue(0);
    this.form.formGroup.controls.nC5.setValue(0);
    this.form.formGroup.controls.iC5.setValue(0);
    this.form.formGroup.controls.nC6.setValue(0);
    this.form.formGroup.controls.averageMolWeightC7ToC10.setValue(120);
    this.form.formGroup.controls.co2.setValue(0);
    this.form.formGroup.controls.h2S.setValue(0);
    this.form.formGroup.controls.n2.setValue(0);
    this.form.formGroup.controls.c7C10.setValue(0);

    this.calculateGasGravity(null, null);
  }
}
