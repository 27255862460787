<div class="p-1 h-full">
  <app-tool-loader
    [isLoading]="isLoading"
    positionUnset="true"
  >
    <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
      <div class="p-4">
        <p-dialog
          modal="true"
          [(visible)]="chokePerformanceCalculating"
          [draggable]="false"
        >
          <i class="pi pi-exclamation-circle"></i> Please wait while Choke Performance is being calculated 
          <br />
          <br />
        </p-dialog>

        <div class="flex gap-4">
          <div class="flex-1">
            <form *ngIf="chokePerformanceForm" [formGroup]="chokePerformanceForm">
              <p-fieldset legend="Input Configuration">
                <div [hidden]="false" class="flex flex-col space-y-2">
                  <div class="flex flex-col">
                    <label for="hardCement">Choke</label>
                    <p-select
                      [options]="chokes"
                      formControlName="choke"
                      optionLabel="name"
                      dataKey="id"
                      appendTo="body"
                    ></p-select>
                  </div>

                  <div class="flex flex-col">
                    <label>Opening / Travel</label>
                    <p-inputgroup>
                      <p-inputNumber
                        [minFractionDigits]="1"
                        formControlName="openingTravel"
                      ></p-inputNumber>
                      <p-inputgroup-addon>%</p-inputgroup-addon>
                    </p-inputgroup>
                    <p
                      *ngIf="
                        chokePerformanceForm?.controls.openingTravel.value &&
                        chokePerformanceForm?.controls.openingTravel.invalid
                      "
                      class="validation-error"
                    >
                      Allowed range: 0% - 100%
                    </p>
                  </div>

                  <div class="flex flex-col">
                    <label for="pressure">Fluid</label>
                    <p-select
                      [options]="fluids"
                      optionLabel="state.name"
                      appendTo="body"
                      (onChange)="selectedFluidChange($event)"
                    >
                    </p-select>
                  </div>

                  <div class="flex flex-col">
                    <label>Gas Flow Rate</label>
                    <p-inputgroup>
                      <p-inputNumber
                        [minFractionDigits]="1"
                        formControlName="gasFlowRate"
                      ></p-inputNumber>
                      <p-inputgroup-addon>{{ flowRateUnit }}</p-inputgroup-addon>
                    </p-inputgroup>
                    <p
                      *ngIf="
                        chokePerformanceForm?.controls.gasFlowRate.value &&
                        chokePerformanceForm?.controls.gasFlowRate.invalid
                      "
                      class="validation-error"
                    >
                      Allowed range: {{ gasFlowRateValidation.min }} -
                      {{ gasFlowRateValidation.max }}
                    </p>
                  </div>

                  <div class="field">
                    <label>Upstream Pressure</label>
                    <p-inputgroup>
                      <p-inputNumber
                        [minFractionDigits]="1"
                        formControlName="upstreamPressure"
                      ></p-inputNumber>
                      <p-inputgroup-addon>{{ pressureUnit }}</p-inputgroup-addon>
                    </p-inputgroup>
                    <p
                      *ngIf="
                        chokePerformanceForm?.controls.upstreamPressure.value &&
                        chokePerformanceForm?.controls.upstreamPressure.invalid
                      "
                      class="validation-error"
                    >
                      Allowed range: {{ pressureValidation.min }} - {{ pressureValidation.max }}
                    </p>
                  </div>

                  <div class="flex flex-col">
                    <label>Upstream Temperature</label>
                    <p-inputgroup>
                      <p-inputNumber
                        [minFractionDigits]="1"
                        formControlName="upstreamTemperature"
                      ></p-inputNumber>
                      <p-inputgroup-addon>{{ tempUnit }}</p-inputgroup-addon>
                    </p-inputgroup>
                    <p
                      *ngIf="
                        chokePerformanceForm?.controls.upstreamTemperature.value &&
                        chokePerformanceForm?.controls.upstreamTemperature.invalid
                      "
                      class="validation-error"
                    >
                      Allowed range: {{ temperatureValidation.min }} - {{ temperatureValidation.max }}
                    </p>
                  </div>
                  <div class="flex justify-center mt-5">
                    <p-button
                      class="outlined-button"
                      size="small"
                      severity="success"
                      [disabled]="chokePerformanceForm?.invalid"
                      label="Calculate"
                      (onClick)="calculateChokePerformance()"
                      outlined="true"
                    ></p-button>
                  </div>
                </div>
              </p-fieldset>
            </form>
          </div>

          <div class="flex-1">
            <p-fieldset legend="Performance Output">
              <div [hidden]="false" class="flex flex-col space-y-2">
                <div class="flex flex-col">
                  <label>Downstream Pressure</label>
                  <p-inputgroup disabled="true" >
                    <p-inputNumber
                      [minFractionDigits]="1"
                      [disabled]="true"
                      placeholder="{{ chokePerformanceResult?.downstreamPressure }}"
                    ></p-inputNumber>
                    <p-inputgroup-addon>{{ pressureUnit }}</p-inputgroup-addon>
                  </p-inputgroup>
                </div>

                <div class="flex flex-col">
                  <label>Downstream Temperature</label>
                  <p-inputgroup>
                    <p-inputNumber
                      [minFractionDigits]="1"
                      [disabled]="true"
                      placeholder="{{ chokePerformanceResult?.downstreamTemperature }}"
                    ></p-inputNumber>
                    <p-inputgroup-addon>{{ tempUnit }}</p-inputgroup-addon>
                  </p-inputgroup>
                </div>

                <div class="flex flex-col">
                  <label>Effective Cv</label>
                  <p-inputgroup>
                    <p-inputNumber
                      [minFractionDigits]="1"
                      [disabled]="true"
                      placeholder="{{ chokePerformanceResult?.effectiveCv }}"
                    ></p-inputNumber>
                  </p-inputgroup>
                </div>

                <div class="flex flex-col">
                  <label>Effective Flow Rate</label>
                  <p-inputgroup>
                    <p-inputNumber
                      [minFractionDigits]="1"
                      [disabled]="true"
                      placeholder="{{ chokePerformanceResult?.effectiveFlowRate }}"
                    ></p-inputNumber>
                    <p-inputgroup-addon>{{ flowRateUnit }}</p-inputgroup-addon>
                  </p-inputgroup>
                </div>

                <div class="flex flex-col">
                  <label>Effective Mass Flow Rate</label>
                  <p-inputgroup>
                    <p-inputNumber
                      [minFractionDigits]="1"
                      [disabled]="true"
                      placeholder="{{ chokePerformanceResult?.effectiveMassFlowRate }}"
                    ></p-inputNumber>
                    <p-inputgroup-addon>{{ massFlowUnit }}</p-inputgroup-addon>
                  </p-inputgroup>
                </div>

                <div class="flex flex-col">
                  <label>Upstream Phase</label>
                  <p-inputNumber
                    [minFractionDigits]="1"
                    [disabled]="true"
                    placeholder="{{ chokePerformanceResult?.upstreamPhase }}"
                  ></p-inputNumber>
                </div>

                <div class="flex flex-col">
                  <label>Downstream Phase</label>
                  <p-inputNumber
                    [minFractionDigits]="1"
                    [disabled]="true"
                    placeholder="{{ chokePerformanceResult?.downstreamPhase }}"
                  ></p-inputNumber>
                </div>

                <div
                  *ngIf="
                    chokePerformanceResult?.isChoked &&
                    chokePerformanceResult?.downstreamPressure
                  "
                  class="flex flex-col space-y-2"
                >
                  <p class="validation-error"
                    >Warning: flow is choked and/or subject to flashing. Nominal
                    downstream performance without choking:
                  </p>

                  <div class="flex flex-col">
                    <label>Downstream Pressure (Non-Choked)</label>
                    <p-inputgroup>
                      <p-inputNumber
                        [minFractionDigits]="1"
                        [disabled]="true"
                        placeholder="{{ chokePerformanceResult?.downstreamPressureNonChoked  }}"
                      ></p-inputNumber>
                      <p-inputgroup-addon>{{ pressureUnit }}</p-inputgroup-addon>
                    </p-inputgroup>
                  </div>

                  <div class="flex flex-col">
                    <label>Downstream Temperature (Non-Choked)</label>
                    <p-inputgroup>
                      <p-inputNumber
                        [minFractionDigits]="1"
                        [disabled]="true"
                        placeholder="{{ chokePerformanceResult?.downstreamTemperatureNonChoked }}"
                      ></p-inputNumber>
                      <p-inputgroup-addon>{{ tempUnit }}</p-inputgroup-addon>
                    </p-inputgroup>
                  </div>
                </div>
              </div>
            </p-fieldset>
          </div>
        </div>
      </div>
    </p-scrollPanel>
  </app-tool-loader>
</div>
