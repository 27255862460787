import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { WellType } from 'src/app/shared/models/wellType.model';
import { WellTypeService } from '../../services/well-type-datums.service';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { catchError } from 'rxjs/operators';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { throwError } from 'rxjs';

@Component({
    selector: 'app-well-type-datums',
    templateUrl: './well-type-datums.component.html',
    styles:[],
    standalone: false
})
export class WellTypeDatumsComponent implements OnInit {

  public wellTypeForm: UntypedFormGroup;
  public wellTypes: { label: string, value: string }[];
  public wellheadType: string;
  public depthRelativeTo: string;
  public wellheadElevationRelativeTo: string;
  public waterDepthVisible: boolean;
  public mudlineDepth: number;
  public isLoading: boolean;
  public lengthUnit: string;

  @Input()
  private componentId: string;

  constructor(
    private _wellTypeService: WellTypeService,
    private _formBuilder: UntypedFormBuilder,
    private _store: StoreService
  ) {
    this.isLoading = true;
    this.wellTypes = [
      { label: 'Land', value: 'Land' },
      { label: 'Platform', value: 'Platform' },
      { label: 'Subsea', value: 'Subsea' }
    ];
    this.createReactiveForm();
  }

  async ngOnInit(): Promise<void> {
    this.lengthUnit = (await this._store.get<UserUnitsModel>(StorageKeys.UNITS)).longLengths;

    this._wellTypeService.getWellType()
    .pipe(
      catchError(err => {
        this.isLoading = false;
        return throwError(() => err); // Ensure it returns an observable error
      })
    )
    .subscribe(data => {
      this.isLoading = true;
      const wellType = new WellType(data);
      delete wellType.wellheadDepthFromDrillFloor;

      this.onTypeChange(wellType.type);

      this.wellTypeForm.setValue(wellType, { emitEvent: false });

      const wellheadDepth = data.wellheadElevation >= 0 ? data.wellheadElevation : data.wellheadElevation;
      this.wellTypeForm.controls.wellheadElevation.setValue(wellheadDepth, { emitEvent: false });

      this.mudlineDepth = +(data.drillFloorElevation + data.waterDepth).toFixed(2);
      this.isLoading = false;
    });
  
  }

  private createReactiveForm(): void {
    this.wellTypeForm = this._formBuilder.group({
      type: new UntypedFormControl({ value: 'Land', disabled: true }),
      drillFloorElevation: new UntypedFormControl({ value: 0, disabled: true }, [Validators.min(0), Validators.max(Number.MAX_VALUE), Validators.required]),
      wellheadElevation: new UntypedFormControl({ value: 0, disabled: true }, [Validators.min(0), Validators.max(Number.MAX_VALUE), Validators.required]),
      wellheadDepth: new UntypedFormControl({ value: 0, disabled: true }, [Validators.min(0), Validators.max(Number.MAX_VALUE), Validators.required]),
      waterDepth: new UntypedFormControl({ value: 100, disabled: true }, [Validators.min(1), Validators.max(Number.MAX_VALUE), Validators.required]),
    });
  }

  private onTypeChange(value: string) {
    if (value === 'Land') {
      this.onOnshoreSelect();
    } else if (value === 'Platform') {
      this.onPlatformSelect();
    } else {
      this.onSubseaSelect();
    }
  }

  private onOnshoreSelect() {
    this.waterDepthVisible = false;
    this.wellheadType = 'Wellhead Elevation';
    this.depthRelativeTo = ` (${this.lengthUnit} above ground level)`;
    this.wellheadElevationRelativeTo = ` (${this.lengthUnit} above ground level)`;
  }

  private onPlatformSelect() {
    this.waterDepthVisible = true;
    this.wellheadType = 'Wellhead Elevation';
    this.depthRelativeTo = `(${this.lengthUnit} above MSL)`;
    this.wellheadElevationRelativeTo = ` (${this.lengthUnit} above MSL)`;

    if (!this.wellTypeForm.controls.waterDepth.value) {
      this.wellTypeForm.controls.waterDepth.setValue(100);
    }
  }

  private onSubseaSelect() {
    this.waterDepthVisible = true;
    this.wellheadType = 'Wellhead Depth';
    this.depthRelativeTo = ` (${this.lengthUnit} above MSL)`;
    this.wellheadElevationRelativeTo = ` (${this.lengthUnit} below MSL)`;

    if (!this.wellTypeForm.controls.waterDepth.value) {
      this.wellTypeForm.controls.waterDepth.setValue(100);
    }
  }
}
