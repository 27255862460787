<form [formGroup]="form.formGroup">
  <div>

    <div *ngIf="isAltus" class="flex flex-col mb-2">
      <label for="name">Name: &nbsp;
        <p-button
          text
          icon="pi pi-copy"
          size="small"
          severity="success"
          pTooltip="Copy Fluid ID (Altus only)"
          (click)="copyFluidId()"
        ></p-button>
      </label>
      <input
        id="fluid-name"
        type="text"
        pInputText
        [formControlName]="form.formControlNames.name"
      />
    </div>

  </div>

  <div [ngSwitch]="form.formGroup.value.type">
      <app-standard-muds
        *ngSwitchCase="FluidType.STANDARDMUD"
        [isReadOnly]="isReadOnly"
        [formControlName]="form.formControlNames.standardMud"
        [userUnits]="userUnits"
      ></app-standard-muds>
      <app-brines
        *ngSwitchCase="FluidType.BRINE"
        [isReadOnly]="isReadOnly"
        [formControlName]="form.formControlNames.brine"
        [userUnits]="userUnits"
        [selectedBrineType]="form.formGroup.controls.brine.value.brineType"
        [isInUse]="isFluidInUse"
      ></app-brines>
      <app-black-oil
        *ngSwitchCase="FluidType.BLACKOIL"
        [isReadOnly]="isReadOnly"
        [formControlName]="form.formControlNames.blackOil"
      ></app-black-oil>
      <app-base-oils
        *ngSwitchCase="FluidType.BASEOIL"
        [formControlName]="form.formControlNames.baseOil"
      ></app-base-oils>
      <app-cement-slurries
        *ngSwitchCase="FluidType.CEMENTSLURRY"
        [formControlName]="form.formControlNames.cementSlurry"
        [userUnits]="userUnits"
        [fluidsStore]="fluidsStore"
      ></app-cement-slurries>
      <app-vle
        *ngSwitchCase="FluidType.VLE"
        [formControlName]="form.formControlNames.vle"
      ></app-vle>
      <app-advanced-muds
        *ngSwitchCase="FluidType.ADVANCEDMUD"
        [isReadOnly]="isReadOnly"
        [formControlName]="form.formControlNames.advancedMud"
        [fluids]="fluids"
        [userUnits]="userUnits"
      ></app-advanced-muds>
      <app-co2-fluid
        [isReadOnly]="isReadOnly"
        *ngSwitchCase="FluidType.CO2FLUID"
        [formControlName]="form.formControlNames.co2Fluid"
        [isPureCo2]="isPureCo2"
        [co2ChangeTrigger]="co2ChangeTrigger"
        [phaseEnvelopeCalculated]="phaseEnvelopeCalculated"
        [userUnits]="userUnits"
        [fluidsComponentHeight]="fluidsComponentHeight"
        [fluidsStore]="fluidsStore"
      ></app-co2-fluid>
  </div>
</form>