import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { wellheadDepthAboveMudline } from '../validation/wellheadDepthAboveMudline';
import { wellheadElevationAboveDfe } from '../validation/wellheadElevationAboveDfe';
import { UserUnitsModel } from '../../user-units/user-units.model';
import { DashboardApiService } from '../../dashboard/services/dashboard-api.service';
import { WorkspacesModel } from '../../dashboard/models/dashboard.model';
import { SelectItem } from 'primeng/api';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';

@Component({
    selector: 'app-create-design-dialog',
    templateUrl: './create-design-dialog.component.html',
    styles: [``],
    standalone: false
})
export class CreateDesignDialogComponent implements OnInit, OnDestroy {

  private _subscriptions: Subscription;

  public designForm: UntypedFormGroup;
  public levelName: string;
  public wellTypes: { label: string, value: string }[];
  public wellheadType: string;
  public depthRelativeTo: string;
  public wellheadElevationRelativeTo: string;
  public waterDepthVisible: boolean;
  public mudlineDepth: number;
  public wellheadError: string;
  public lengthUnit: string;
  public templateWorkspaces: WorkspacesModel;
  public autoGenerateLoads = false;
  public designTemplates: SelectItem[];

  public get wtc(): FormGroup {
    const wtc = (this.designForm.controls.wellType as FormGroup)
    return wtc;
  }

  constructor(
    private formBuilder: UntypedFormBuilder,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig,
    private _store: StoreService,
    private _dashboardService: DashboardApiService
  ) {
    this._subscriptions = new Subscription();
    this.wellTypes = [
      { label: 'Land', value: 'Land', },
      { label: 'Platform', value: 'Platform' },
      { label: 'Subsea', value: 'Subsea' }
    ];
  }

  async ngOnInit(): Promise<void> {
    this.lengthUnit = (await this._store.get<UserUnitsModel>(StorageKeys.UNITS)).longLengths;

    this.designForm = this.formBuilder.group({
      name: ['', [Validators.required, Validators.minLength(3)]],
      wellType: this.formBuilder.group({
        type: ['Land', [Validators.required]],
        wellheadElevation: [0, [Validators.min(0), Validators.max(Number.MAX_VALUE), Validators.required]],
        drillFloorElevation: [0, [Validators.min(0), Validators.max(Number.MAX_VALUE), Validators.required]],
        wellheadDepth: [0, [Validators.required, Validators.min(0)]],
        waterDepth: [this.lengthUnit == 'ft' ? 100 : 30, [Validators.required, Validators.min(1)]]
      }),
      designTemplate: [null]
    });

    this._subscriptions.add(this.designForm.valueChanges.pipe(debounceTime(300)).subscribe(async (formData) => {
      const wtc = (this.wtc as FormGroup).controls;

      if (formData.wellType.type) {
        this.mudlineDepth = (wtc.drillFloorElevation.value + wtc.waterDepth.value).toFixed(2);
      }

      // Angular forms is stupid.
      // https://stackoverflow.com/questions/58832992/angular-6-this-formgroup-updatevalueandvalidity-not-working-properly
      wtc.wellheadDepth.updateValueAndValidity({ emitEvent: false });
      wtc.waterDepth.updateValueAndValidity({ emitEvent: false });
      wtc.drillFloorElevation.updateValueAndValidity({ emitEvent: false });
      wtc.wellheadElevation.updateValueAndValidity({ emitEvent: false });
    }));

    this._subscriptions.add(this.wtc.controls.type.valueChanges.subscribe(v => this.onTypeChange(v)));

    const level = this.config.data.level;
    this.levelName = level.slice(0, -1);
    this.onOnshoreSelect();

    this.handleTreeItemsSelectable(this.config.data.treeItems);

    this.designTemplates = [
      { label: 'Template 1', value: 'temp1' },
      { label: 'Template 2', value: 'temp2' }
    ];
  }

  handleTreeItemsSelectable(treeItems): void {
    treeItems.forEach(i => {
      i.key = i.data.id;
      if (i.data.level !== 'designs') {
        i.selectable = false;
        this.handleTreeItemsSelectable(i.children);
      }
    });
  }

  public loadCasesCheckboxChange(e: Event): void {
    this.autoGenerateLoads = (e.target as HTMLInputElement).checked;
  }

  onTemplateSelect(designId: string) {
    // this.designForm.controls['designTemplate'].setValue(designId);
    this._dashboardService.getWorkspaces(designId).subscribe(res => {
      this.templateWorkspaces = res;
      this.designForm.controls['designTemplate'].setValue(res.id);
    });
  }

  onTemplateClear(): void {
    this.designForm.controls['designTemplate'].reset();
    this.templateWorkspaces = null;
  }

  showComponentList(uiComponents: any): string {
    let temp = '<span>Components:</span><br>';
    uiComponents?.forEach(component => {
      temp+=`<span>${component.name}</span><br>`;
    });
    return temp;
  }

  onSave() {
    const designData = this.designForm.value;
    this.ref.close(designData);
  }

  onCancel() {
    this.ref.close();
  }

  private onTypeChange(value: string) {
    const wtc = (this.wtc as FormGroup).controls;

    this.designForm.clearValidators();
    this.wtc.clearValidators();

    if (value === 'Subsea') {
      this.wellheadError = 'Wellhead must be below or at DFE';
      wtc.wellheadDepth.setValidators(wellheadDepthAboveMudline);
      wtc.waterDepth.setValidators(wellheadDepthAboveMudline);
    } else {
      this.wellheadError = 'Wellhead must be below or at DFE';
      wtc.wellheadElevation.setValidators(wellheadElevationAboveDfe);
      wtc.drillFloorElevation.setValidators(wellheadElevationAboveDfe);
    }

    if (value === 'Land') {
      this.onOnshoreSelect();
    } else if (value === 'Platform') {
      this.onPlatformSelect();
    } else {
      this.onSubseaSelect();
    }
  }

  private onOnshoreSelect() {
    this.waterDepthVisible = false;
    this.wellheadType = 'Wellhead Elevation';
    this.depthRelativeTo = ` (${this.lengthUnit} above ground level)`;
    this.wellheadElevationRelativeTo = ` (${this.lengthUnit} above ground level)`;
  }

  private onPlatformSelect() {
    this.waterDepthVisible = true;
    this.wellheadType = 'Wellhead Elevation';
    this.depthRelativeTo = `(${this.lengthUnit} above MSL)`;
    this.wellheadElevationRelativeTo = ` (${this.lengthUnit} above MSL)`;

    if (!this.wtc.get('waterDepth').value) {
      this.wtc.get('waterDepth').setValue(100);
    }
  }

  private onSubseaSelect() {
    this.waterDepthVisible = true;
    this.wellheadType = 'Wellhead Depth';
    this.depthRelativeTo = ` (${this.lengthUnit} above MSL)`;
    this.wellheadElevationRelativeTo = ` (${this.lengthUnit} below MSL)`;

    if (!this.wtc.get('waterDepth').value) {
      this.wtc.get('waterDepth').setValue(100);
    }
  }

  onInputFocus($event) {
    $event.target.select();
  }

  ngOnDestroy() {
    this._subscriptions?.unsubscribe();
  }
  
}
