import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SelectItem } from 'primeng/api';
import { PeriforOnChangeMessages, SignalRService } from '../../../shared/services/signal-r.service';
import { StressResultsService } from '../../services/stress-results.service';
import { toCamelCaseString } from 'src/app/shared/helpers/stringToCamel.helper';
import { Observable, Subscription, catchError, forkJoin, map } from 'rxjs';
import { DesignFactorsService } from '../../services/design-factors.service';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { SafetyFactorResultsUi } from '../../models/perivis-results-state.model';
import { Store } from '@ngneat/elf';
import { isEqual } from "lodash-es";
import { withUIEntities } from '@ngneat/elf-entities';
import { XyLinePlotUi } from 'src/app/core/models/xy-line-plot.model';

@Component({
    selector: 'app-single-load-safety-factors',
    templateUrl: './single-load-safety-factors.component.html',
    styles: [],
    standalone: false
})
export class SingleLoadSafetyFactorsComponent implements OnInit, OnDestroy {

  private _safetyFactorResults: any[];
  private _subscriptions: Subscription;

  public loadCases: SelectItem[] = [];
  public resultsDisplay: SelectItem[] = [
    { label: 'Plot', value: 'plot' },
    { label: 'Grid', value: 'grid' }
  ];
  public modes: SelectItem[] = [
    { label: 'Single', value: 'single' },
    { label: 'Multiple', value: 'multiple' },
    { label: 'Minimum', value: 'minimum' }
  ];
  public errorMsg: string;
  public results: any[];
  public axialResults: any[];
  public resultsBackup: any[];
  public designFactors: any;
  public depthView: SelectItem[] = [
    { label: 'MD', value: 'md' },
    { label: 'TVD', value: 'tvd' }
  ];
  public isLoading: boolean;
  public yAxisTitle = '';
  public xAxisTitle = 'Safety Factor';
  public tableName: string;
  public apiCollapseType: string;
  public userUnits: UserUnitsModel;
  public depthUnit: string;
  public plotName: string
  public signalRChange: boolean;
  public tableHeight: string;

  public minimumResultType = 'summary';
  private gridDesignFactorName: string;
  private previousView: string;

  public types = [
    { label: 'Burst', value: 'barlowBurst' },
    { label: 'Collapse', value: 'apiCollapse' },
    { label: 'Axial', value: 'pureAxial' },
    { label: 'VME (Triaxial)', value: 'vonMisesEquivalent' }
  ]

  public columnDefs: { header: string, field: string }[];
  public columnDefinitions = this.setDepths();

  //State Management
  private _componentId: string;
  @Input() set componentId(value: string) {
    this._componentId = value;
    this.safetyFactorResultsStore = this._storeService.createStore(this.componentId, new SafetyFactorResultsUi, withUIEntities<XyLinePlotUi>());
  }
  get componentId(): string {
    return this._componentId;
  }
  public safetyFactorResultsStore: Store;

  constructor(
    private _stressResultsService: StressResultsService,
    private _signalRService: SignalRService,
    private _designFactorsService: DesignFactorsService,
    private _storeService: StoreService
  ) {
    this._subscriptions = new Subscription();
  }

  async ngOnInit(): Promise<void> {
    this.userUnits = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);
    this.depthUnit = this.userUnits.longLengths;
    this.yAxisTitle = `MD (${this.userUnits.longLengths})`;

    this.refreshResults(true, false);

    if (!this.previousView && this.safetyFactorResultsStore.state.selectedMode) {
      this.previousView = this.safetyFactorResultsStore.state?.selectedMode;
    } else {
      this.previousView = 'single'
    }

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));
  }

  signalRfunc(data: any) {
    if (data.action == PeriforOnChangeMessages.REFRESH_RESULTS) {
      this.safetyFactorResultsStore.update(state => ({ ...state, selectedLoadCase: null }));
      //TODO Does this need to be reset to single?
      // this.mode = 'single';
      this.refreshResults(true, true);
    }
  }

  private setPlotName(): string {
    if (this.safetyFactorResultsStore.state.selectedMode === 'single') {
      return 'safetyFactorsPlot - ' + this.safetyFactorResultsStore.state.selectedMode + ' ' +
      (this.safetyFactorResultsStore.state.selectedLoadCase.loadCaseName || this.safetyFactorResultsStore.state.selectedLoadCase.label);
    } else {
      return 'safetyFactorsPlot - ' + this.safetyFactorResultsStore.state.selectedMode + ' ' + this.safetyFactorResultsStore.state.selectedLoadCase.label;
    }
  }

  public async refreshResults(isLoading: boolean, calledFromSignalR: boolean, calledFromMode?: boolean): Promise<void> {
    this.errorMsg = null;
    this.isLoading = isLoading;


    if ((!this._safetyFactorResults || calledFromSignalR) && !calledFromMode) {
      const sources = [
        this._stressResultsService.getAxialResults() as Observable<any>,
        this._stressResultsService.getSafetyFactorResults(),
        this._designFactorsService.getDesignFactors()
      ];

      forkJoin(sources).pipe(
        map(([axialResults, safetyFactorResults, designFactors]) => {
          this.resultsBackup = safetyFactorResults;
          this.designFactors = designFactors;
          this.axialResults = axialResults;
          if (safetyFactorResults.length === 0 || safetyFactorResults[0].loadCaseName === '') {
            this.errorMsg = "Please calculate Perivis to see results";
            this.columnDefs = [];
            this.results = [];
            this.isLoading = false;
            return;
          }
          this._safetyFactorResults = safetyFactorResults;
          this.designFactors = designFactors;

          this.apiCollapseType = this._safetyFactorResults[0].apiCollapseType === 'CollapseApi2018' ? 'apiCollapse2015' : 'apiCollapse';
          this.remapData();
        }),
        catchError(err => {
          this.errorMsg = "Well Configuration Missing: Please specify at least one string";
          this.isLoading = false;
          return err;
        })).subscribe();
    } else {
      this.remapData();
    }
  }

  private remapData() {
    if (!this._safetyFactorResults) {
      return;
    }
    this.minimumResultType = this.safetyFactorResultsStore.state.selectedLoadCase?.value || this.safetyFactorResultsStore.state.selectedLoadCase;
    if (this.safetyFactorResultsStore.state.selectedMode == "single") {
      this.handleSingleSafetyFactorLoad(this._safetyFactorResults, true);
    } else if (this.safetyFactorResultsStore.state.selectedMode == 'multiple') {
      this.handleMultiSafetyFactorLoad(this._safetyFactorResults, this.designFactors)
    } else if (this.safetyFactorResultsStore.state.selectedMode == 'minimum' && this.minimumResultType !== 'barlowBurst' && !this.minimumResultType.startsWith('apiCollapse') &&
      this.minimumResultType !== 'pureAxial' && this.minimumResultType !== 'vonMisesEquivalent') {
      this.handleMinimumSummary(this._safetyFactorResults, this.designFactors);
    } else {
      this.handleMinimum(this._safetyFactorResults, this.designFactors, this.minimumResultType);
    }
    this.signalRChange = true;
    this.plotName = this.setPlotName();
    this.isLoading = false;
  }

  setLoadCase(e): void {
    this.minimumResultType = e.value;
    if (this.safetyFactorResultsStore.state.selectedMode === "single") {
      this.safetyFactorResultsStore.update(state => ({ ...state, selectedLoadCase: e.value }));
      this.plotName = this.setPlotName();
      this.results = e.value.safetyFactorResults;
    } else {
      this.safetyFactorResultsStore.update(state => ({ ...state, selectedLoadCase: e.value }));
      this.plotName = this.setPlotName();
      this.refreshResults(e.value, false);
    }

    if (this.safetyFactorResultsStore.state.selectedMode === 'minimum' && e.value != 'summary') {
      this.handleMinimum(this.resultsBackup, this.designFactors, e.value);
    }

    if (this.safetyFactorResultsStore.state.selectedMode === 'minimum' && e.value == 'summary') {
      this.handleMinimumSummary(this.resultsBackup, this.designFactors);
    }
  }

  public onSelectedResultsDisplayChange(e): void {
    this.safetyFactorResultsStore.update(state => ({ ...state, selectedResultDisplay: e.value }));
  }

  public depthViewToggle(e) {
    this.safetyFactorResultsStore.update(state => ({ ...state, selectedDepthView: e.value }));
  }

  public onModeChange(e) {
    if (e.value !== "minimum" && this.previousView == 'minimum' || this.previousView == 'summary') {
      this.safetyFactorResultsStore.update(state => ({ ...state, selectedResultDisplay: 'plot' }));
    }

    this.safetyFactorResultsStore.update(state => ({ ...state, selectedMode: e.value }));
    if (e.value === 'minimum' && this.previousView !== 'multiple') {
      this.handleMinimumSummary(this.resultsBackup, this.designFactors);
    }

    if (e.value == 'minimum') {
      this.safetyFactorResultsStore.update(state => ({ ...state, selectedLoadCase: { label: 'Summary', value: 'summary' } }));
    }

    if (e.value == "multiple") {
      // this.safetyFactorResultsStore.state.selectedLoadCase = "burstLoad";
    } else {
      this.xAxisTitle = 'Safety Factor';
    }

    const type = this.types.findIndex(x => x.value == this.safetyFactorResultsStore.state.selectedLoadCase) > 0;
    this.plotName = this.setPlotName();
    this.refreshResults(type ? this.safetyFactorResultsStore.state.selectedLoadCase : { label: 'Barlow Burst', value: 'barlowBurst' }, false, true);
  }

  private formatDecimal(value: any, divisor: number): string {
    return value || value === 0 ? (Math.trunc(value * divisor) / divisor).toLocaleString('en-US') : null;
  }

  private handleSingleSafetyFactorLoad(safetyFactorResults: any[], calledFromSignalR: boolean) {
    if (calledFromSignalR) {
      //TODO Might need to fix this
      // this.safetyFactorResultsStore.update(state => ({ ...state, selectedLoadCase: this.loadCases[0] }));
    }
    this.minimumResultType = 'summary';
    this.columnDefs = [
      { header: 'Burst', field: 'barlowBurst' },
      { header: 'Collapse', field: safetyFactorResults[0].apiCollapseType === 'CollapseApi2018' ? 'apiCollapse2015' : 'apiCollapse' },
      { header: 'Axial', field: 'pureAxial' },
      { header: 'VME (Triaxial)', field: 'vonMisesEquivalent' },
    ];

    this.columnDefinitions = this.setDepths();
    this.columnDefinitions.push(
      {
        header: 'Barlow Burst', field: 'barlowBurst',
        valueFormatter: (params: any) => params
      },
      {
        header: 'API Collapse', field: safetyFactorResults[0].apiCollapseType === 'CollapseApi2018' ? 'apiCollapse2015' : 'apiCollapse',
        valueFormatter: (params: any) => params
      },
      {
        header: 'Axial', field: 'pureAxial',
        valueFormatter: (params: any) => params
      },
      {
        header: 'VME (Triaxial)', field: 'vonMisesEquivalent',
        valueFormatter: (params: any) => params
      }
    );

    this.results = [];

    this.loadCases = safetyFactorResults?.map(lc => {
      lc.safetyFactorResults.forEach(element => {
        // Axial
        const designFactorAxialCheck = this.safetyFactorResultsStore.state.includeCharacters && element['pureAxial'] < this.getDesignFactor(this.designFactors, 'pureAxial', safetyFactorResults[0].apiCollapseType) - 0.000001 ? ' *' : '';
        if (element['pureAxial'].toString().includes('*')) {
          const pureAxial = element['pureAxial'].toString().substring(0, element['pureAxial'].toString().indexOf(' '));
          element['pureAxial'] = this.roundValue(pureAxial, 3) + designFactorAxialCheck;
        } else {
          element['pureAxial'] = this.roundValue(element['pureAxial'], 3);
        }

        // Collapse
        const collapseType = safetyFactorResults[0].apiCollapseType === 'CollapseApi2018' ? 'apiCollapse2015' : 'apiCollapse'
        const designFactorCollapseCheck = this.safetyFactorResultsStore.state.includeCharacters && element[collapseType] < this.getDesignFactor(this.designFactors, 'apiCollapse2015', safetyFactorResults[0].apiCollapseType) - 0.000001 ? ' *' : '';
        if (element[collapseType].toString().includes('*')) {
          const collapse = element[collapseType].toString().substring(0, element[collapseType].toString().indexOf(' '));
          element[collapseType] = this.roundValue(collapse, 3) + designFactorCollapseCheck;
        } else {
          element[collapseType] = this.roundValue(element[collapseType], 3);
        }

        // Burst
        const designFactorBurstCheck = this.safetyFactorResultsStore.state.includeCharacters && element['barlowBurst'] < this.getDesignFactor(this.designFactors, 'barlowBurst', safetyFactorResults[0].apiCollapseType) - 0.000001 ? ' *' : '';
        if (element['barlowBurst'].toString().includes('*')) {
          const burst = element['barlowBurst'].toString().substring(0, element['barlowBurst'].toString().indexOf(' '));
          element['barlowBurst'] = this.roundValue(burst, 3) + designFactorBurstCheck;
        } else {
          element['barlowBurst'] = this.roundValue(element['barlowBurst'], 3);
        }

        // VME
        const burstValueAtDepth = element.barlowBurst;
        const collapseValueAtDepth = element[collapseType];
        const designFactorVmeCheck = this.safetyFactorResultsStore.state.includeCharacters && element['vonMisesEquivalent'] < this.getDesignFactor(this.designFactors, 'vonMisesEquivalent', safetyFactorResults[0].apiCollapseType, burstValueAtDepth, collapseValueAtDepth) - 0.000001 ? ' *' : '';
        if (element['vonMisesEquivalent'].toString().includes('*')) {
          const vme = element['vonMisesEquivalent'].toString().substring(0, element['vonMisesEquivalent'].toString().indexOf(' '));
          element['vonMisesEquivalent'] = this.roundValue(vme, 3) + designFactorVmeCheck;
        } else {
          element['vonMisesEquivalent'] = this.roundValue(element['vonMisesEquivalent'], 3);
        }
      });

      return { label: lc.loadCaseName, value: lc };
    }) ?? [];
    if (this.previousView != 'single' || !this.safetyFactorResultsStore.state.selectedLoadCase || !this.safetyFactorResultsStore.state.selectedLoadCase.loadCaseName) {
      this.safetyFactorResultsStore.update(state => ({ ...state, selectedLoadCase: this.loadCases[0] }));
      this.plotName = this.setPlotName();
    }
    this.previousView = 'single'
    this.results = this.safetyFactorResultsStore.state.selectedLoadCase?.value?.safetyFactorResults || this.safetyFactorResultsStore.state.selectedLoadCase?.safetyFactorResults || [];
  }

  private handleMultiSafetyFactorLoad(safetyFactorResults: any[], designFactors) {
    if (this.previousView !== 'multiple') {
      this.safetyFactorResultsStore.update(state => ({ ...state, selectedLoadCase: { label: 'Barlow Burst', value: 'barlowBurst' } }));
      this.plotName = this.setPlotName();
    }
    this.previousView = 'multiple';
    this.minimumResultType = 'summary';
    this.loadCases = [];
    this.columnDefinitions = this.setDepths();
    this.columnDefs = [];
    this.results = [];
    let i = 0;
    this.loadCases = this.types;

    safetyFactorResults.forEach(load => {
      const loadName: string = load.loadCaseName;
      const loadNameCamel = toCamelCaseString(loadName);
      let selectedType = this.safetyFactorResultsStore.state.selectedLoadCase.value ?? this.safetyFactorResultsStore.state.selectedLoadCase;

      this.setAxisNames(selectedType);

      this.columnDefs.push({ header: load.loadCaseName, field: loadNameCamel });

      this.columnDefinitions.push({
        header: loadName, field: loadNameCamel,
        valueFormatter: (params: any) => params
      }
      );

      if (selectedType.startsWith('apiCollapse')) {
        selectedType = safetyFactorResults[0].apiCollapseType === 'CollapseApi2018' ? 'apiCollapse2015' : 'apiCollapse'
      }

      const backupArray = [];
      load.safetyFactorResults.forEach(loadResult => {
        const burstValueAtDepth = loadResult.barlowBurst;
        const collapseValueAtDepth = loadResult[selectedType];
        const result = {
          [loadNameCamel]: this.safetyFactorResultsStore.state.includeCharacters ? this.roundValue(loadResult[selectedType], 3) + (loadResult[selectedType] < (this.getDesignFactor(designFactors, selectedType, safetyFactorResults[0].apiCollapseType, burstValueAtDepth, collapseValueAtDepth) - 0.000001) ? ' *' : '') : this.roundValue(loadResult[selectedType], 3),
          measuredDepth: loadResult.measuredDepth,
          trueVerticalDepth: loadResult.trueVerticalDepth
        }

        if (i === 0) {
          this.results.push(result);
        } else {
          backupArray.push(result);
        }
      });

      this.results = this.results.map(x => {
        const otherLoad = backupArray.find(e => e.measuredDepth === x.measuredDepth)
        return { ...x, ...otherLoad }
      });

      if (i === safetyFactorResults.length - 1) {
        const burstValueAtDepth = load.safetyFactorResults.find(x => Math.abs(x.measuredDepth - this.results[this.results.length - 1].measuredDepth) < 0.00001).barlowBurst;
        const collapseValueAtDepth = load.safetyFactorResults.find(x => Math.abs(x.measuredDepth - this.results[this.results.length - 1].measuredDepth) < 0.00001)[selectedType];
        this.addDesignFactor(selectedType, this.gridDesignFactorName, designFactors, burstValueAtDepth, collapseValueAtDepth);
      }
      i++;
    });
    this.isLoading = false;
  }

  private handleMinimum(safetyFactorResults: any[], designFactors, type) {
    this.previousView = 'minimum';
    this.columnDefinitions = this.setDepths();
    this.columnDefs = [];
    this.results = [];
    this.loadCases = this.types;
    this.loadCases = this.loadCases.filter(x => x.label !== 'Summary');
    this.loadCases.unshift({ label: 'Summary', value: 'summary' });

    let selectedType = type.value ?? type;
    if (selectedType.startsWith('apiCollapse')) {
      selectedType = safetyFactorResults[0].apiCollapseType === 'CollapseApi2018' ? 'apiCollapse2015' : 'apiCollapse'
    }
    this.setAxisNames(selectedType);

    this.safetyFactorResultsStore.update(state => ({ ...state, selectedResultDisplay: 'grid' }));

    safetyFactorResults.forEach(load => {
      const loadName: string = load.loadCaseName;
      const loadNameCamel = toCamelCaseString(loadName);

      this.columnDefinitions.push({
        header: loadName, field: loadNameCamel,
        valueFormatter: (params: any) => params
      }
      );

      let smallestValue = 10000;
      let depthMd = 0;
      let depthTvd = 0;
      load.safetyFactorResults.forEach(element => {
        if (element[selectedType] < smallestValue) {
          smallestValue = element[selectedType];
          depthMd = element.measuredDepth;
          depthTvd = element.trueVerticalDepth;
        }
      });
      const burstValueAtDepth = load.safetyFactorResults.find(x => Math.abs(x.measuredDepth - depthMd) < 0.00001).barlowBurst;
      const collapseValueAtDepth = load.safetyFactorResults.find(x => Math.abs(x.measuredDepth - depthMd) < 0.00001)[selectedType];

      const designFactorCheck = this.safetyFactorResultsStore.state.includeCharacters && smallestValue < this.getDesignFactor(designFactors, selectedType, safetyFactorResults[0].apiCollapseType, burstValueAtDepth, collapseValueAtDepth) - 0.000001 ? ' *' : '';

      if (this.results.findIndex(x => x.measuredDepth == depthMd) < 0) {
        this.results.push({
          loadName: loadNameCamel,
          measuredDepth: depthMd,
          trueVerticalDepth: depthTvd,
          [loadNameCamel]: this.roundValue(smallestValue, 3) + designFactorCheck
        });
      } else {
        this.results[this.results.findIndex(x => x.measuredDepth == depthMd)][loadNameCamel] = this.roundValue(smallestValue, 3) + designFactorCheck;
      }

    });
    this.results.sort((a, b) => (a.measuredDepth < b.measuredDepth ? -1 : 1));
    if (selectedType.startsWith('apiCollapse')) {
      selectedType = safetyFactorResults[0].apiCollapseType === 'CollapseApi2018' ? 'apiCollapse2015' : 'apiCollapse'
    }
    this.addDesignFactor(selectedType, this.gridDesignFactorName, designFactors);

    this.isLoading = false;
  }

  private handleMinimumSummary(safetyFactorResults: any[], designFactors) {
   
    this.previousView = 'summary';
    this.columnDefinitions = [];
    this.columnDefs = [];
    this.results = [];
    this.loadCases = this.types;
    this.loadCases = this.loadCases.filter(x => x.label !== 'Summary');
    this.loadCases.unshift({ label: 'Summary', value: 'summary' });

    this.safetyFactorResultsStore.update(state => ({ ...state, selectedResultDisplay: 'grid' }));

    this.columnDefinitions = [
      {
        header: 'Load Name', field: 'loadName',
        valueFormatter: (params: any) => params
      },
      {
        header: 'VME (Triaxial)', field: 'vonMisesEquivalent',
        valueFormatter: (params: any) => params
      },
      {
        header: 'Barlow Burst', field: 'barlowBurst',
        valueFormatter: (params: any) => params
      },
      {
        header: 'API Collapse', field: safetyFactorResults[0].apiCollapseType === 'CollapseApi2018' ? 'apiCollapse2015' : 'apiCollapse',
        valueFormatter: (params: any) => params
      },
      {
        header: 'Axial', field: 'pureAxial',
        valueFormatter: (params: any) => params
      },
    ];

    safetyFactorResults.forEach(load => {
      let smallestBurst = 10000;
      let smallestAxial = 10000;
      let smallestVme = 10000;
      let smallestCollapse = 10000;
      const firstDepth = load.safetyFactorResults[0].measuredDepth;
      let depthBurst = firstDepth;
      let depthAxial = firstDepth;
      let depthCollapse = firstDepth;
      let depthVme = firstDepth;
      let collapse;
      load.safetyFactorResults.forEach(element => {
        if (element['barlowBurst'] < smallestBurst) {
          smallestBurst = element['barlowBurst'];
          depthBurst = element.measuredDepth.toFixed(3);
        }

        if (element['pureAxial'] < smallestAxial) {
          smallestAxial = element['pureAxial'];
          depthAxial = element.measuredDepth.toFixed(3);
        }

        if (element['vonMisesEquivalent'] < smallestVme) {
          smallestVme = element['vonMisesEquivalent'];
          depthVme = element.measuredDepth.toFixed(3);
        }

        collapse = safetyFactorResults[0].apiCollapseType === 'CollapseApi2018' ? 'apiCollapse2015' : 'apiCollapse'
        if (element[collapse] < smallestCollapse) {
          smallestCollapse = element[collapse];
          depthCollapse = element.measuredDepth.toFixed(3);
        }
      });

      if (this.results.length < 1) {
        this.results.push({
          loadName: 'Design Factor',
          barlowBurst: designFactors['barlowBurst'],
          [collapse]: designFactors['apiCollapse'],
          pureAxial: Math.min(designFactors['pureTension'], designFactors['pureCompression']),
          vonMisesEquivalent: `${designFactors['vonMisesBurst'] + ' (B) / ' + designFactors['vonMisesCollapse'] + ' (C)'}`
        });
      }

      const loadIndex = this.axialResults.findIndex(x => x.loadCaseName == load.loadCaseName);
      const depthIndex = this.axialResults[loadIndex].axialResults.findIndex(x => Math.abs(x.measuredDepth - depthAxial) < 0.00001);
      const axialForceValue = this.axialResults[loadIndex].axialResults[depthIndex].axialLoad;
      const tensionCompression = axialForceValue < 0 ? ' - Compression' : '';
      const depthUnit = this.userUnits.longLengths + "MD";
      const burstValueAtDepth = load.safetyFactorResults.find(x => Math.abs(x.measuredDepth - depthBurst) < 0.00001).barlowBurst;
      const collapseValueAtDepth = load.safetyFactorResults.find(x => Math.abs(x.measuredDepth - depthCollapse) < 0.00001)[collapse];

      this.results.push({
        loadName: load.loadCaseName,
        barlowBurst: this.safetyFactorResultsStore.state.includeCharacters ? this.roundValue(smallestBurst, 3) + (smallestBurst < this.getDesignFactor(designFactors, 'barlowBurst', safetyFactorResults[0].apiCollapseType) - 0.000001 ? ' *' : '') + ' - @ - ' + depthBurst + ` ${depthUnit}` : this.roundValue(smallestBurst, 3),
        [collapse]: this.safetyFactorResultsStore.state.includeCharacters ? this.roundValue(smallestCollapse, 3) + (smallestCollapse < this.getDesignFactor(designFactors, 'apiCollapse', safetyFactorResults[0].apiCollapseType) - 0.000001 ? ' *' : '') + ' - @ - ' + depthCollapse + ` ${depthUnit}` : this.roundValue(smallestCollapse, 3),
        pureAxial: this.safetyFactorResultsStore.state.includeCharacters ? this.roundValue(smallestAxial, 3) + (smallestAxial < this.getDesignFactor(designFactors, 'pureAxial', safetyFactorResults[0].apiCollapseType) - 0.000001 ? ' *' : '') + ' - @ - ' + depthAxial + ` ${depthUnit}` + tensionCompression : this.roundValue(smallestAxial, 3),
        vonMisesEquivalent: this.safetyFactorResultsStore.state.includeCharacters ? this.roundValue(smallestVme, 3) + (smallestVme < this.getDesignFactor(designFactors, 'vonMisesEquivalent', safetyFactorResults[0].apiCollapseType, burstValueAtDepth, collapseValueAtDepth) - 0.000001 ? ' *' : '') + ' - @ - ' + depthVme + ` ${depthUnit}` : this.roundValue(smallestVme, 3)
      });
    });

    if (!this.safetyFactorResultsStore.state.selectedLoadCase || !this.loadCases.find(l => isEqual(l, this.safetyFactorResultsStore.state.selectedLoadCase))) {
      this.safetyFactorResultsStore.update(state => ({ ...state, selectedLoadCase: this.loadCases[0] }));
      this.plotName = this.setPlotName();
    }
    this.signalRChange = !this.signalRChange;
    this.isLoading = false;
  }

  private getDesignFactor(designFactors: any, selectedType, apiCollapseType, burstValueAtDepth?: number, collapseValueAtDepth?: number) {
    if (selectedType.startsWith('apiCollapse')) {
      selectedType = apiCollapseType === 'CollapseApi2018' ? 'apiCollapse2015' : 'apiCollapse'
    }
    let df = selectedType;
    if (df === 'pureAxial') {
      df = Math.min(designFactors['pureTension'], designFactors['pureCompression']);
    } else if (df === 'vonMisesEquivalent') {
      df = burstValueAtDepth < 100 ?  designFactors['vonMisesBurst'] : collapseValueAtDepth < 100 ? designFactors['vonMisesCollapse'] : designFactors['vonMisesBurst'];
    } else if (df === 'apiCollapse' || df === 'apiCollapse2015') {
      df = designFactors['apiCollapse'];
    } else if (df === 'barlowBurst') {
      df = designFactors['barlowBurst'];
    }

    return df;
  }

  private addDesignFactor(selectedType, gridDesignFactorName, designFactors, burstValueAtDepth?: number, collapseValueAtDepth?: number) {
    let df = selectedType;
    if (df === 'pureAxial') {
      df = designFactors['pureTension'] < designFactors['pureCompression'] ? 'pureTension' : 'pureCompression';
    } else if (df === 'vonMisesEquivalent') {
      df = 'vonMisesBurst';
    } else if (df.startsWith('apiCollapse')) {
      df = 'apiCollapse';
    }

    this.columnDefs.push({ header: gridDesignFactorName, field: selectedType });
    this.columnDefinitions.push(
      {
        header: gridDesignFactorName, field: selectedType,
        valueFormatter: (params: any) => params
      }
    );
    this.results = this.results.map(x => {
      const designFactor = {
        [selectedType]: selectedType == 'vonMisesEquivalent' ? burstValueAtDepth < 100 ?  designFactors['vonMisesBurst'] : collapseValueAtDepth < 100 ? designFactors['vonMisesCollapse'] : designFactors['vonMisesBurst'] : designFactors[df],
        measuredDepth: x.measuredDepth,
        trueVerticalDepth: x.trueVerticalDepth
      }
      return { ...x, ...designFactor }
    });
  }

  private setAxisNames(selectedType: any) {
    switch (selectedType) {
      case 'barlowBurst': {
        this.gridDesignFactorName = 'Burst DF';
        this.xAxisTitle = 'Burst Safety Factor';
        break;
      }
      case 'apiCollapse': {
        this.gridDesignFactorName = 'Collapse DF';
        this.xAxisTitle = 'Collapse Safety Factor';
        break;
      }
      case 'pureAxial': {
        this.gridDesignFactorName = 'Axial DF';
        this.xAxisTitle = 'Axial Safety Factor';
        break;
      }
      case 'vonMisesEquivalent': {
        this.gridDesignFactorName = 'VME (Triaxial) DF';
        this.xAxisTitle = 'VME (Triaxial) Safety Factor';
        break;
      }
      default: {
        break;
      }
    }
  }

  private roundValue(value, decimalPoints) {
    let fixed = decimalPoints || 0;
    fixed = Math.pow(10, fixed);
    return Math.floor(value * fixed) / fixed;
  }

  private setDepths() {
    return [
      {
        header: `MD (${this.depthUnit})`, field: 'measuredDepth',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      },
      {
        header: `TVD (${this.depthUnit})`, field: 'trueVerticalDepth',
        valueFormatter: (params: any) => this.formatDecimal(params, 100)
      },
    ]
  }

  public toggleCharacterView(e) {
    this.safetyFactorResultsStore.update(state => ({ ...state, includeCharacters: e.checked }));

    if (this.safetyFactorResultsStore.state.selectedMode == "single") {
      this.handleSingleSafetyFactorLoad(this.resultsBackup, false);
    } else if (this.safetyFactorResultsStore.state.selectedMode == 'multiple') {
      this.handleMultiSafetyFactorLoad(this.resultsBackup, this.designFactors)
    } else if (this.safetyFactorResultsStore.state.selectedMode == 'minimum' && this.minimumResultType !== 'barlowBurst' && !this.minimumResultType.startsWith('apiCollapse') &&
      this.minimumResultType !== 'pureAxial' && this.minimumResultType !== 'vonMisesEquivalent') {
      this.handleMinimumSummary(this.resultsBackup, this.designFactors);
    }

    if (this.minimumResultType == 'barlowBurst' || this.minimumResultType.startsWith('apiCollapse') ||
      this.minimumResultType == 'pureAxial' || this.minimumResultType == 'vonMisesEquivalent') {
      this.handleMinimum(this.resultsBackup, this.designFactors, this.safetyFactorResultsStore.state.selectedLoadCase);
    }
  }

  ngOnDestroy() {
    this._subscriptions?.unsubscribe();
    this.signalRfunc = null;
  }
}
