<div class="w-full h-full p-2">
  <app-tool-loader [isLoading]="isLoading" positionUnset="true">
    <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
    <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

    <p-dialog [draggable]="false" [(visible)]="isWellConfigMissing" [closable]="false">
      <i style="font-size: 10px" class="pi pi-exclamation-circle"></i> Well Configuration Missing: Please specify at
      least one string
      <br />
      <br />
    </p-dialog>

    <p-dialog [draggable]="false" [visible]="packerData && packerData.isFullyCemented" [closable]="false">
      <i style="font-size: 10px" class="pi pi-exclamation-circle"></i> String is fully cemented <br />
      <br />
    </p-dialog>

    <div class="flex flex-1 gap-4">
      <!-- Orderlist Section -->
      <div class="flex-[0.35] min-w-[150px] flex-shrink">
        <p-listbox 
          scrollHeight="350px"
          [options]="packerData?.packers"
          optionLabel="type" 
          (onChange)="onPackerSelect($event)"
          cdkDropList 
          (cdkDropListDropped)="drop($event)"
          [(ngModel)]="selectedPacker[0]">
    
          <ng-template #header>
            <div class="w-full">
              <div class="flex justify-start items-center space-x-2">
                <p-button outlined size="small" severity="primary" [disabled]="packerData?.packers.length < 2"
                  title="Sort packers Bottom Up" icon="pi pi-sort-amount-up-alt"
                  (onClick)="onSortUp()">
                </p-button>
    
                <p-button outlined size="small" severity="primary" [disabled]="packerData?.packers.length < 2"
                  title="Sort packers Top Down" icon="pi pi-sort-amount-down-alt" (onClick)="onSortDown()">
                </p-button>
    
                <p-button class="outlined-button" size="small" severity="success" outlined="true" label="Add" 
                  title="Add Packer"
                  (onClick)="onAddPacker()">
                </p-button>
    
                <span class="tooltip">
                  <i title="Packers are set in the order listed below" class="pi pi-info-circle"></i>
                </span>
              </div>
              <p class="text-sm" *ngIf="packerOrderLabel">
                {{ packerOrderLabel }}
              </p>
            </div>
          </ng-template>
    
          <ng-template let-packer #item>
            <div class="flex w-full items-center" 
              cdkDrag
              [cdkDragDisabled]="selectedPacker?.['measuredDepth'] === null"
            >
              <div class="flex-1">{{ packer.name }}</div>
              <div>
                <i
                  class="pi pi-trash !text-red-400" 
                  pTooltip="Delete Packer" 
                  (click)="onDeletePacker(packer)">
                </i>
              </div>
            </div>
          </ng-template>
        </p-listbox>
      </div>
    
      <!-- Main Content Section -->
      <div class="flex-[0.65] overflow-auto">
        <app-packer-data *ngIf="userUnits" 
          [packer]="selectedPackerDetails$ | async" 
          (packerUpdate)="savePacker($event)"
          [userUnits]="userUnits" 
          [disabled]="packerData?.packers.length < 1"
          [packersStore]="packersStore" 
          [componentHeight]="componentHeight">
        </app-packer-data>
      </div>
    </div>
    
  </p-scrollPanel>
  </app-tool-loader>
</div>