<div>
  <p-table
    #dataTable
    responsiveLayout="stack"
    [scrollable]="true"
    [scrollHeight]="tableHeight"
    [columns]="columnDefinitions"
    [reorderableColumns]="true"
    [exportFilename]="tableName"
    selectionMode="multiple"
    stripedRows
    [value]="tableData"
    appRangeSelection
    (rangeChanged)="onRangeChanged($event)"
    [virtualScroll]="virtualScroll"
    [virtualScrollItemSize]="25"
    class="table-fixed w-full"
  >
  <ng-template pTemplate="header" let-columns>
    <tr class="w-full">
      <td *ngIf="tableData?.length > 0" class="w-[50px] text-center">
        <div class="flex flex-col items-center justify-center">
          <p-splitButton
            class="copy-btn"
            pTooltip="Copy Table"
            icon="pi pi-copy"
            (onClick)="copyTable(columns)"
            [model]="menuItems"
            size="sm"
            appendTo="body"
          ></p-splitButton>
          <p-toggleswitch
            [ngModel]="true"
            pTooltip="Include headers when copying"
            (onChange)="headers($event)"
          ></p-toggleswitch>
        </div>
      </td>
  
      <th
        *ngFor="let col of columns"
        (click)="toggleColumnHighlight($event, col)"
        pReorderableColumn
        class="w-[150px] text-center align-middle"
        [ngClass]="{ 'highlight-column-header': selectedColumns.includes(col.field) }"
      >
        {{ col.header }}
      </th>
    </tr>
  </ng-template>
  
    <!--TODO Rethink this table (it morphed into having branches for each each type below, that's not great)-->
    <ng-template
      pTemplate="body"
      let-rowData
      let-rIdx="rowIndex"
      let-columns="columns"
    >
      <tr (click)="clearColumnData()">
        <td class="row-index">{{ rIdx + 1 }}</td>
        <td *ngFor="let col of columnDefinitions" class="text-left pl-2" [ngClass]="{ 'highlight-column': selectedColumns.includes(col.field) }">
          <!-- Connection -->
          <div *ngIf="tableName === 'Connection Safety Factor Results'">
            <div
              [ngClass]="{
                belowDesignFactor:
                    ((col.field === 'burstLeak' || selectedOperation['value'] === 'burstLeak') && col.field !== 'measuredDepth' &&
                    col.field !== 'trueVerticalDepth' &&
                      (rowData[col.field] && rowData[col.field][1]
                        ? rowData[col.field].split(' ')[0]
                        : rowData[col.field]) <
                        designFactors['burstLeak'] - 0.000001) ||

                    ((col.field === 'collapse' || selectedOperation === 'collapse') && col.field !== 'measuredDepth' &&
                    col.field !== 'trueVerticalDepth' &&
                      (rowData[col.field] && rowData[col.field][1]
                        ? rowData[col.field].split(' ')[0]
                        : rowData[col.field]) <
                        designFactors['collapse'] - 0.000001) ||

                    ((col.field === 'axial' || selectedOperation['value'] === 'axial') && col.field !== 'measuredDepth' &&
                    col.field !== 'trueVerticalDepth' &&
                      (rowData[col.field] && rowData[col.field][1]
                        ? rowData[col.field].split(' ')[0]
                        : rowData[col.field]) <
                        (designFactors['tension'] < designFactors['compression']
                          ? designFactors['tension']
                          : designFactors['compression']) -
                          0.000001)
                        }"
            >
              {{ col.valueFormatter(rowData[col.field]) }}
            </div>
          </div>

          <!-- Pipe -->
          <div
            *ngIf="
              tableName !== 'Thermal Results - Temperatures' &&
              tableName !== 'Thermal Results - Pressures' &&
              tableName !== 'Connection Safety Factor Results' &&
              tableName !== 'Annular Results'
            ">
            <div
              [ngClass]="{
                belowDesignFactor:
                  (!rowData[col.field]?.toString().includes('B') && col.field === 'barlowBurst' &&
                    (rowData[col.field][1]
                      ? rowData[col.field].split(' ')[0]
                      : rowData[col.field]) <
                      designFactors['barlowBurst'] - 0.000001) ||

                  (!rowData[col.field]?.toString().includes('B') && col.field === 'pureAxial' &&
                    (rowData[col.field][1]
                      ? rowData[col.field].split(' ')[0]
                      : rowData[col.field]) <
                      (designFactors['pureTension'] <
                      designFactors['pureCompression']
                        ? designFactors['pureTension']
                        : designFactors['pureCompression']) -
                        0.000001) ||

                  (!rowData[col.field]?.toString().includes('B') && col.field == apiCollapseType &&
                    (rowData[col.field][1]
                      ? rowData[col.field].split(' ')[0]
                      : rowData[col.field]) <
                      designFactors['apiCollapse'] - 0.000001) ||

                  (!rowData[col.field]?.toString().includes('B') && col.field === 'vonMisesEquivalent' &&
                    (rowData[col.field][1]
                      ? rowData[col.field].split(' ')[0]
                      : rowData[col.field]) <
                      designFactors['vonMisesCollapse'] - 0.000001) ||

                    (!rowData[col.field]?.toString().includes('B') && col.field !== 'measuredDepth' &&
                      col.field !== 'trueVerticalDepth' &&
                      selectedOperation === 'barlowBurst' &&
                      (rowData[col.field] && rowData[col.field][1]
                        ? rowData[col.field].split(' ')[0]
                        : rowData[col.field]) <
                        designFactors['barlowBurst'] - 0.000001) ||

                    (!rowData[col.field]?.toString().includes('B') && col.field !== 'measuredDepth' &&
                      col.field !== 'trueVerticalDepth' &&
                      selectedOperation === 'apiCollapse' &&
                      (rowData[col.field] && rowData[col.field][1]
                        ? rowData[col.field].split(' ')[0]
                        : rowData[col.field]) <
                        designFactors['apiCollapse'] - 0.000001) ||
                        
                    (!rowData[col.field]?.toString().includes('B') && col.field !== 'measuredDepth' &&
                      col.field !== 'trueVerticalDepth' &&
                      selectedOperation === 'pureAxial' &&
                      (rowData[col.field] && rowData[col.field][1]
                        ? rowData[col.field].split(' ')[0]
                        : rowData[col.field]) <
                        (designFactors['pureTension'] <
                        designFactors['pureCompression']
                          ? designFactors['pureTension']
                          : designFactors['pureCompression']) -
                          0.000001) ||

                    (!rowData[col.field]?.toString().includes('B') && col.field !== 'measuredDepth' &&
                      col.field !== 'trueVerticalDepth' &&
                      selectedOperation === 'vonMisesEquivalent' &&
                      (rowData[col.field] && rowData[col.field][1]
                        ? rowData[col.field].split(' ')[0]
                        : rowData[col.field]) <
                        designFactors['vonMisesCollapse'] - 0.000001) ||

                  (col.field === 'hangerLiftoffForce' &&
                    rowData[col.field] * -1 > hangerLiftoffForce)
              }"
              *ngIf="col.valueFormatter"
            >
              {{ col.valueFormatter(rowData[col.field]) }}
            </div>
          </div>

          <!-- Ann results -->
          <div 
            *ngIf="
            tableName === 'Annular Results' ||
            tableName === 'Thermal Results - Pressures'">
            <div>
              <div *ngIf="col.valueFormatter">
                {{ col.valueFormatter(rowData[col.field]) }}
              </div>
              <div *ngIf="!col.valueFormatter">
                {{ col.valueFormatter(rowData[col.field]) }}
              </div>
            </div>
          </div>

          <!-- Thermal -->
          <div *ngIf="tableName === 'Thermal Results - Temperatures'">
            <div
              [ngStyle]="{
                color: getColorForTemperature(rowData[col.field], col.field)
              }"
            >
              <div *ngIf="col.valueFormatter">
                {{ col.valueFormatter(rowData[col.field]) }}
              </div>
              <div *ngIf="!col.valueFormatter">
                {{ col.valueFormatter(rowData[col.field]) }}
              </div>
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
