import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { unitsLib } from 'src/app/core/services/unit-library';
import { GetValueFromPpg } from 'src/app/perivis/shared/helpers/units.helper';

export interface BrineForm {
  name: string;
  type: string;
  brineType?: string;
  nominalDensity?: number;
  plasticViscosity?: number;
  viscosityTemperature?: number;
}

@Component({
    selector: 'app-brines',
    templateUrl: './brines.component.html',
    styles: [``],
    providers: subformComponentProviders(BrinesComponent),
    standalone: false
})
export class BrinesComponent implements AfterViewInit, OnChanges {

  @Input()
  public userUnits: UserUnitsModel;

  @Input()
  public isReadOnly: boolean;

  @Input()
  public selectedBrineType: string;

  @Input()
  public isInUse: boolean;
  
  public densityUnits: string;
  public densityValidation: { min: number, max: number };

  private _densityDefault: number;
  private _minDensityPpg: number;
  private _maxDensityPpg: number;

  public brineTypes = [
    { label: 'Seawater', value: 'seawater' },
    { label: 'NaCl', value: 'nacl' },
    { label: 'KCl', value: 'kcl' },
    { label: 'CaCl2', value: 'cacl2' },
    { label: 'CaBr2', value: 'cabr2' },
    { label: 'ZnBr2', value: 'znbr2' },
    { label: 'KFo', value: 'kfo' },
    { label: 'CsFo', value: 'csfo' },
    { label: 'CsKFo', value: 'cskfo' },
    { label: 'CaCl2-NaCl', value: 'cacl2nacl' }
  ];

  ngOnChanges(e: any): void {
    if (!this.userUnits) {
      return;
    }
    this.densityUnits = unitsLib[this.userUnits.density].symbol;
    this.getDefaultDensities();

    const minDensity = GetValueFromPpg(this._minDensityPpg, this.densityUnits);
    const maxDensity = GetValueFromPpg(this._maxDensityPpg, this.densityUnits);

    this.form.formGroup.controls.nominalDensity.clearValidators();
    this._densityDefault = GetValueFromPpg(this._minDensityPpg, this.densityUnits);

    if (e.selectedBrineType) {
      this.checkSeaWater();
    }

    this.densityValidation = { min: minDensity, max: maxDensity};
    this.form.formGroup.controls.nominalDensity.setValidators([Validators.required, Validators.min(this.densityValidation.min), Validators.max(this.densityValidation.max)]);
  }

  ngAfterViewInit(): void {
    if (this._densityDefault) {
      this.checkSeaWater();
    }
  }

  private checkSeaWater(): void {
    if (this.selectedBrineType === 'seawater') {
      this.form.formGroup.controls.nominalDensity.patchValue(this._densityDefault, { emitEvent: false });
      this.form.formGroup.controls.nominalDensity.disable();
    } else if (!this.isInUse) {
      this.form.formGroup.controls.nominalDensity.patchValue(this._densityDefault, { emitEvent: false });
      this.form.formGroup.controls.nominalDensity.enable();
    }
  }

  private getDefaultDensities(): void {
    switch(this.selectedBrineType) {
      case 'seawater': {
        this._minDensityPpg = 8.6;
        this._maxDensityPpg = 8.6;
        break;
      }
      case 'nacl': {
        this._minDensityPpg = 8.35;
        this._maxDensityPpg = 10;
        break;
      }
      case 'kcl': {
        this._minDensityPpg = 8.35;
        this._maxDensityPpg = 10;
        break;
      }
      case 'cacl2': {
        this._minDensityPpg = 8.35;
        this._maxDensityPpg = 12;
        break;
      }
      case 'cabr2': {
        this._minDensityPpg = 11;
        this._maxDensityPpg = 15.5;
        break;
      }
      case 'znbr2': {
        this._minDensityPpg = 14.5;
        this._maxDensityPpg = 19.2;
        break;
      }
      case 'kfo': {
        this._minDensityPpg = 8.35;
        this._maxDensityPpg = 14.2;
        break;
      }
      case 'csfo': {
        this._minDensityPpg = 8.35;
        this._maxDensityPpg = 20.8;
        break;
      }
      case 'cskfo': {
        this._minDensityPpg = 13.1;
        this._maxDensityPpg = 18.4;
        break;
      }
      case 'cacl2nacl': {
        this._minDensityPpg = 8.35;
        this._maxDensityPpg = 11.2;
        break;
      }
    }
  }

  public form = createForm<BrineForm>(this, {
    formType: FormType.SUB,
    formControls: {
      name: new UntypedFormControl(null),
      type: new UntypedFormControl(null),
      brineType: new UntypedFormControl(null, [Validators.required]),
      nominalDensity: new UntypedFormControl(null),
      plasticViscosity: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(2000)]),
      viscosityTemperature: new UntypedFormControl(null)
    }
  });

  public applyDefaults(): void {
    this.form.formGroup.controls.nominalDensity.setValue(this._densityDefault);
    // this.formGroupControls.plasticViscosity.setValue(1);
    // this.formGroupControls.viscosityTemperature.setValue(70);
  }
}
