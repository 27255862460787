import { UntypedFormControl } from "@angular/forms";

export function connectionOdSmallerThanPipeOd(control: UntypedFormControl) {
    const outerDiameter = control.value;
    const pipeOd = control.parent && control.parent.controls['pipeOd'].value;
    if(outerDiameter < pipeOd){
       return {error: "Outside Diameter must be greater than or equal to the Pipe OD."};
    } else if (outerDiameter <= 0) {
      return {error: "Value must be greater than 0"};
    }
    return null;
  }
