import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { CreateTreeLevelDialogComponent } from '../create-tree-level-dialog/create-tree-level-dialog.component';
import { Design } from 'src/app/shared/models/design.model';
import { TreeNode } from 'primeng/api';

@Component({
    selector: 'app-well-explorer-header',
    templateUrl: './well-explorer-header.component.html',
    styles: [],
    providers: [DialogService],
    standalone: false
})
export class WellExplorerHeaderComponent implements OnChanges {

  @Input()
  public isAdmin : boolean;

  @Output()
  public closeSidenav: EventEmitter<string>;

  @Output()
  public designUpdated: EventEmitter<TreeNode>;

  @Output()
  public companyAdded: EventEmitter<{levelData: {id:string, name: string}, level:string}>;


  @Input()
  public design: Design;

  public designs: Design[] = [];

  @Input()
  public allDesigns: Design[] = [];

  public selectedDesign: any;

  constructor(
    public _dialogService: DialogService,) {
    this.companyAdded = new EventEmitter();
    this.closeSidenav = new EventEmitter();
    this.designUpdated = new EventEmitter();
  }

  ngOnChanges() {
    if (this.design && !this.designs.find(x => x.id == this.design.id)) {
      this.designs.push(this.design);
    }
  }

  public updateDesign(design: Design) {
    const selectedDesign: TreeNode = {
      data: {
        id: design['value'].id,
        name: design['value'].name,
        level: 'designs'
      }
    }
    this.designUpdated.emit(selectedDesign);
    this.selectedDesign = [];
  }

  public collapseSidenav() {
    this.closeSidenav.emit("close");
  }

  public async addCompany() {
    const dialogReference = this._dialogService.open(CreateTreeLevelDialogComponent, {
      data: {
        level: 'companies'
      },
      header: 'Create a New Company',
      width: '400px'
    });

    dialogReference.onClose.subscribe((levelData : {name: string}) => {
      if (levelData) {
        this.companyAdded.emit({levelData: {id: null, name: levelData.name}, level: 'companies'});
      }
    });
  }
}
