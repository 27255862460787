  <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

  <app-tool-loader [isLoading]="isLoading" positionUnset="true">

    <p-toolbar>
      <ng-template #start>
        <div class="flex flex-col toolbar-menu">
          <label class="mr-2" for="ods">Pipe OD Filter ({{ diameterUnit }}) </label>
          <p-select inputId="ods" placeholder="View All" (onChange)="filterByOd($event)" [options]="filterPipeOds" appendTo="body" title="Pipe OD ({{ diameterUnit }})">
          </p-select>
        </div>
      </ng-template>
      <ng-template #end>
        <span class="text-sm">* NOTE: External pressure rating is based on leakage from outside</span> 
      </ng-template>
    </p-toolbar>
    
    <form [formGroup]="connectionsForm" novalidate>
      <ng-container formArrayName="connections">
        <p-table
          #catalogTable
          [scrollable]="true"
          [scrollHeight]="tableHeight"
          [columns]="columnDefs"
          [resizableColumns]="true"
          [value]="connectionsCatalog.controls" 
          dataKey="value.id" 
          stateStorage="local" 
          showGridlines
          [stateKey]="componentId">
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th *ngFor="let col of columns" pResizableColumn class="text-sm text-center">
                {{ col.header }}
              </th>
              <th class="text-center">
                <div style="display: flex;">
                  <p-button outlined="true" size="small" icon="pi pi-plus" [disabled]="connectionsCatalog.invalid"
                    (click)="onAddConnection()" title="Add Connection"></p-button>
                  <p-button text size="small" icon="pi pi-file-excel" [disabled]="connectionsCatalog.invalid"
                    (click)="exportExcel()" title="Export to Excel"></p-button>
                </div>
              </th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-connFg let-rIdx="rowIndex">
            <ng-container [formGroupName]="rIdx">
              <tr>
                <td>
                  <input type="text" pInputText formControlName="name" title="Name" autocomplete="off" />
                  <app-validation-error [isValid]="isControlInvalid(connFg, 'name')"
                    [errorMsg]="getControlErrors(connFg, 'name')"/>
                </td>
                <td>
                  <p-select class="w-full" [disabled]="userRoles.readOnly" formControlName="pipeOd" [options]="pipeOds" appendTo="body"
                    title="Pipe OD ({{ diameterUnit }})"
                    [placeholder]="
                    getPlaceholderText(
                      connFg.get('tubularPipe').value,
                      'outsideDiameter',
                      true
                    )
                  "
                    ></p-select>
                </td>
                <td class="text-center">
                  <p-select class="w-full" [disabled]="userRoles.readOnly" [title]="showJson(connFg.get('tubularPipe').value)"
                    [placeholder]="
                      getPlaceholderText(
                        connFg.get('tubularPipe').value,
                        'outsideDiameter'
                      )
                    " formControlName="tubularPipe" [options]="getSortedPipeOptions(connFg, 'weightPerFoot')"
                    appendTo="body">
                  </p-select>
                </td>
                <td class="text-right">
                  <p-inputNumber formControlName="outerDiameter" [minFractionDigits]="3"
                    [title]="!isControlInvalid(connFg, 'outerDiameter') ? 'Connection OD (' + diameterUnit + ')' : getControlErrors(connFg, 'outerDiameter')"></p-inputNumber>
                </td>
                <td class="text-right">
                  <p-inputNumber formControlName="innerDiameter" [minFractionDigits]="3"
                    [title]="!isControlInvalid(connFg, 'innerDiameter') ? 'Connection ID (' + diameterUnit + ')' : getControlErrors(connFg, 'innerDiameter')"></p-inputNumber>
                </td>
                <td class="text-center">
                  <p-select class="w-full" [disabled]="userRoles.readOnly" 
                    [title]="isControlInvalid(connFg, 'grade') ? getControlErrors(connFg, 'grade') : showJson(connFg.get('grade').value)" 
                    [placeholder]="getPlaceholderText(connFg.get('grade').value, 'name')"
                    formControlName="grade" [options]="grades" appendTo="body"></p-select>
                </td>
                <td class="text-right">
                  <p-inputNumber [minFractionDigits]="1" formControlName="burstRating"
                    [title]="!isControlInvalid(connFg, 'burstRating') ? 'Burst Rating (' + pressureUnit + ')' : getControlErrors(connFg, 'burstRating')"></p-inputNumber>
                </td>
                <td class="text-right">
                  <p-inputNumber [minFractionDigits]="1" formControlName="collapseRating"
                    [title]="!isControlInvalid(connFg, 'collapseRating') ? 'Collapse Rating (' + pressureUnit + ')' : getControlErrors(connFg, 'collapseRating')"></p-inputNumber>
                </td>
                <td class="text-right">
                  <p-inputNumber [minFractionDigits]="1" formControlName="tensionRating"
                    [title]="!isControlInvalid(connFg, 'tensionRating') ? 'Tension Rating (' + forceUnit + ')' : getControlErrors(connFg, 'tensionRating')"></p-inputNumber>
                </td>
                <td class="text-right">
                  <p-inputNumber [minFractionDigits]="1" formControlName="compressionRating"
                    [title]="!isControlInvalid(connFg, 'compressionRating') ? 'Compression Rating (' + forceUnit + ')' : getControlErrors(connFg, 'compressionRating')"></p-inputNumber>
                </td>
                <td class="text-center">
                  <i title="Delete Connection" class="pi pi-trash !text-red-400" (click)="onDeleteConnection(rIdx)"></i>
                </td>
              </tr>
            </ng-container>
          </ng-template>
        </p-table>
      </ng-container>
    </form>
  </app-tool-loader>