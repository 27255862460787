<div class="w-full h-full p-2">
  <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
  <div class="grid grid-cols-12 gap-2">
    <!-- Listbox Section -->
    <div class="col-span-5">
      <p-listbox 
        scrollHeight="250px"
        [options]="apbData?.apbAnnuli"
        [(ngModel)]="apbInputsStore.state.selectedAnnulus"
        (onChange)="onAnnulusSelected($event)"
        optionLabel="name">
      </p-listbox>
    </div>

    <!-- Switch and Fieldset Section -->
    <div class="col-span-7 space-y-4">
      <div class="flex flex-col">
        <label *ngIf="apbInputsStore.state.selectedAnnulus" for="isVented">
          Vented Annulus
        </label>
        <p-toggleSwitch *ngIf="apbInputsStore.state.selectedAnnulus" id="isVented"
          [(ngModel)]="apbInputsStore.state.selectedAnnulus.isVented"
          (onChange)="updateAnnulus(apbInputsStore.state.selectedAnnulus)"
          [disabled]="!apbInputsStore.state?.selectedAnnulus" class="mt-2">
        </p-toggleSwitch>
      </div>

      <p-fieldset legend="Initial Conditions">

        <div>
          <label for="initialTemp">
            Initial Temperature
          </label>
          <p-select 
            id="initialTemp" 
            dataKey="id" 
            optionLabel="name" 
            [disabled]="true" 
            placeholder="UDT"
            appendTo="body"
            class="mt-2 w-full">
          </p-select>
        </div>
      </p-fieldset>
    </div>

    <!-- Final Conditions Section -->
    <div class="col-span-12">
      <p-fieldset legend="Final Conditions">
            <div class="flex flex-col space-y-2">
              <label for="thermalOperation">
                Thermal Operation
              </label>
              <p-select 
                id="thermalOperation" 
                dataKey="id" 
                [options]="thermalOperations"
                [ngModel]="apbData?.finalOperation" 
                (onChange)="updateFinalConditions($event.value)" 
                optionLabel="name"
                appendTo="body"
                class="w-full"
                ></p-select>
            </div>
      </p-fieldset>
    </div>

  </div>
</p-scrollPanel>
</div>
