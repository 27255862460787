import { Component, Input } from "@angular/core";

@Component({
    selector: 'app-validation-error',
    template: `
    <span
     [ngClass]="{ 'show': isValid }"
     *ngIf="isValid"
     class="validation-error" pTooltip="{{errorMsg}}" tooltipPosition="top" tooltipStyleClass="toolTip" tooltipZIndex=150000>
        <i class="p-error pi pi-exclamation-circle"></i>
    </span> 
    `,
    standalone: false
})
  export class ValidationErrorComponent {

    @Input()
    public isValid  = true;

    @Input()
    public errorMsg  = "";
    
  }
