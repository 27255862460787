<app-tool-loader [isLoading]="isLoading" positionUnset="true">

  <p-toolbar>
    <ng-template pTemplate="start">
      <div class="flex items-center gap-2 toolbar-menu">
        <p-selectButton [options]="modes" [(ngModel)]="burstResultsStore.state.selectedMode"
          (onChange)="onModeChange($event)" [allowEmpty]="false">
        </p-selectButton>

        <p-selectButton [options]="resultsDisplay" [(ngModel)]="burstResultsStore.state.selectedResultDisplay"
          (onChange)="onSelectedResultDisplayUpdated($event)" [allowEmpty]="false">
        </p-selectButton>

        <p-selectButton *ngIf="burstResultsStore.state.selectedResultDisplay === 'plot'" [options]="depthView"
          [(ngModel)]="burstResultsStore.state.selectedDepthView" (onChange)="depthViewToggle($event)"
          [allowEmpty]="false">
        </p-selectButton>

        <app-single-load-results [loadCases]="loadCases" (loadCaseSelected)="setLoadCase($event)"
          [stateStore]="burstResultsStore" [signalRChange]="signalRChange">
        </app-single-load-results>
      </div>
    </ng-template>
  </p-toolbar>

  <app-single-load-plot 
    *ngIf="burstResultsStore.state?.selectedResultDisplay === 'plot'" 
    [yAxisTitle]="yAxisTitle"
    [xAxisTitle]="xAxisTitle" [columnDefs]="columnDefs" [data]="results"
    [selectedDepthView]="burstResultsStore.state.selectedDepthView" [depthUnit]="longLengthUnit" [plotName]="plotName"
    [plotTitle]="'Barlow Burst Results'"
    [singleLoadPlotStore]="burstResultsStore">
  </app-single-load-plot>

  <app-single-load-grid  
    [hidden]="burstResultsStore.state?.selectedResultDisplay !== 'grid'"
    [rowData]="results"
    [columnDefinitions]="columnDefinitions" 
    tableName="Single Load Burst" 
    gridName="Barlow Burst Results"
    >
  </app-single-load-grid>

  <p-dialog [draggable]="false" [(visible)]="errorMsg" [closable]="false">
    <i class="pi pi-exclamation-circle pr-1"></i>
    <label>{{ errorMsg }}</label>
    <br />
    <br />
  </p-dialog>
</app-tool-loader>