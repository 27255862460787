<form *ngIf="designForm" [formGroup]="designForm">
  <div class="flex flex-col space-y-2">

    <div class="flex flex-col">
      <label>Name</label>
      <input class="w-full" id="name" type="string" required pInputText formControlName="name" />
      <p *ngIf="
          designForm.controls.name.value.length > 0 &&
          designForm.controls.name.value.length < 3
        " class="validation-error">
        Must be longer than three characters
      </p>
    </div>

    <p-fieldset legend="Well Type and Datums">
      <div formGroupName="wellType">
        <div class="flex flex-col mb-2">
          <label>Type</label>
          <p-select formControlName="type" [options]="wellTypes"></p-select>
        </div>

        <p-fieldset legend="Elevation">
          <div class="flex flex-col mb-2">
            <label for="drillFloorElevation">Drill Floor Elevation {{ depthRelativeTo }}</label>
            <p-inputgroup>
              <p-inputNumber (onFocus)="onInputFocus($event)" id="drillFloorElevation"
                formControlName="drillFloorElevation" [minFractionDigits]="1"></p-inputNumber>
            </p-inputgroup>
            <p *ngIf="wtc.controls.drillFloorElevation.invalid" class="validation-error mb-2">
              Wellhead must be below or at DFE
            </p>
          </div>

          <div *ngIf="wtc.controls.type.value !== 'Subsea'" class="flex flex-col mb-2">
            <label for="wellheadElevation">{{ wellheadType }} {{ wellheadElevationRelativeTo }}</label>
            <p-inputNumber (onFocus)="onInputFocus($event)" id="wellheadElevation" formControlName="wellheadElevation"
              [minFractionDigits]="1">
            </p-inputNumber>
            <p *ngIf="wtc.controls.wellheadElevation.invalid" class="validation-error">
              {{ wellheadError }}
            </p>
          </div>

          <div *ngIf="wtc.controls.type.value === 'Subsea'" class="flex flex-col">
            <label for="wellheadDepth">{{ wellheadType }} {{ wellheadElevationRelativeTo }}</label>
            <p-inputNumber (onFocus)="onInputFocus($event)" id="wellheadDepth" formControlName="wellheadDepth"
              [minFractionDigits]="1"></p-inputNumber>
            <p *ngIf="wtc.controls.wellheadDepth.invalid" class="validation-error">Wellhead must be above the
              seafloor
            </p>
          </div>

        </p-fieldset>

        <p-fieldset *ngIf="waterDepthVisible" legend="Offshore">
          <div class="flex flex-col">
            <label for="waterDepth">Water Depth ({{ lengthUnit }})</label>
            <p-inputNumber (onFocus)="onInputFocus($event)" id="waterDepth" formControlName="waterDepth"
              [minFractionDigits]="1"></p-inputNumber>
            <p *ngIf="wtc.controls.waterDepth.invalid" class="validation-error">Wellhead must be above the seafloor
            </p>
          </div>
          <p class="text-sm">Mudline Depth: {{ mudlineDepth }} ({{ lengthUnit }})</p>
        </p-fieldset>

      </div>

    </p-fieldset>

    <p-fieldset legend="Dashboard Template" class="w-full">
      <div class="flex flex-col">

        <label for="designTemplate">Apply a set of tabs and components based on an existing design (optional):</label>

        <p-treeSelect 
          containerStyleClass="w-full"
          [options]="config.data.treeItems" 
          appendTo="body" 
          formControlName="designTemplate" 
          placeholder="Select a design" 
          filter="true" 
          filterBy="data.label" 
          showClear="true" 
          (onNodeSelect)="onTemplateSelect($event.node?.data.id)" 
          (onClear)="onTemplateClear()" 
          class="w-full"
        >
          <!-- Custom template for dropdown items -->
          <ng-template pTemplate="default" let-node>
            <span class="flex items-center gap-2">
              <img
                *ngIf="node?.data?.level !== 'designs'"
                class="level-icon"
                [src]="node?.data?.icon"
                alt="icon"
              />
              {{ node?.data?.label }}
            </span>
          </ng-template>
          
          <!-- Custom template for the selected value -->
          <ng-template pTemplate="value" let-node>
            <div *ngIf="!node">Select a design</div>
            <div class="w-full">{{ node?.data?.label }}</div>
          </ng-template>
        </p-treeSelect>

        <div *ngIf="templateWorkspaces">
          <p-fieldset legend="Tabs in Selected Dashboard Template:">
            <div *ngFor="let tab of templateWorkspaces.workspaces" class="inline-flex">
              <p-chip [pTooltip]="showComponentList(tab.uiComponents)" [escape]="false">{{ tab.name }}</p-chip>
            </div>
          </p-fieldset>
        </div>
      </div>
    </p-fieldset>

  </div>
  
  <div class="float-right">
    <button pButton type="button" icon="pi pi-times" class="p-button-text p-button-danger" label="Cancel"
      (click)="onCancel()"></button>
    <button pButton type="button" icon="pi pi-check" class="p-button-text p-button-success"
      [disabled]="designForm.invalid" label="Save" (click)="onSave()"></button>
  </div>

</form>