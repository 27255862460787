import { AfterContentInit, Component, Input, OnChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { BaseOperation } from 'src/app/perical/models/thermal-operation.model';
import { PericalLinkTempProfile, TemperatureProfileType } from '../../models/temperature-profile.model';

interface PericalLinkTempProfileForm {
  type: TemperatureProfileType.THERMALLINK;
  thermalOperation: Partial<BaseOperation>
}

@Component({
    selector: 'app-perical-link-temp-profile',
    template: `
    <form [formGroup]="form.formGroup">
        <label for="thermalOperation">Thermal Operation</label>
        <p-select
          class="w-full"
          id="thermalOperation"
          dataKey="id"
          [disabled]="isReadOnly"
          [options]="thermalOperations"
          [formControlName]="form.formControlNames.thermalOperation"
          optionLabel="name"
          appendTo="body"
        ></p-select>
    </form>
    `,
    styles: [``],
    providers: subformComponentProviders(PericalLinkTempProfileComponent),
    standalone: false
})
export class PericalLinkTempProfileComponent implements AfterContentInit, OnChanges {

  @Input() thermalOperations: Partial<BaseOperation>[];
  @Input() isReadOnly: boolean;

  public form = createForm<PericalLinkTempProfile, PericalLinkTempProfileForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(TemperatureProfileType.THERMALLINK),
      thermalOperation: new UntypedFormControl(null, Validators.required)
    },
    toFormGroup: (obj: PericalLinkTempProfile | null): PericalLinkTempProfileForm | null => {
      if (!obj) { return null; };
      return {
        type: TemperatureProfileType.THERMALLINK,
        thermalOperation: !obj.thermalOperationId ? (!this.thermalOperations ? null : this.thermalOperations[0]) : this.thermalOperations.find(op => op.id === obj.thermalOperationId)
      };
    },
    fromFormGroup: (formValue: PericalLinkTempProfileForm): PericalLinkTempProfile | null => {
      const { thermalOperation, ...commonValues } = formValue;
      return { type: TemperatureProfileType.THERMALLINK, thermalOperationId: thermalOperation.id, ...commonValues }
    }
  });

  ngAfterContentInit(): void {
    setTimeout(() => {
      if (!this.form.formGroup.controls.thermalOperation.value  && this.thermalOperations) {
        this.form.formGroup.controls.thermalOperation.setValue(this.thermalOperations[0]);
      }
    }, 100);
  }

  ngOnChanges(): void {
    setTimeout(() => {
      if (!this.form.formGroup.controls.thermalOperation.value  && this.thermalOperations) {
        this.form.formGroup.controls.thermalOperation.setValue(this.thermalOperations[0]);
      }
    }, 100);
  }
}
