<form [formGroup]="form.formGroup">

  <p-tabs value="0">

    <p-tablist>
      <p-tab value="0">Operation Details</p-tab>
      <p-tab value="1">
        Landing String
        <i class="validation-error pi pi-exclamation-circle pl-1" 
          *ngIf="landingStringMissing"
          pTooltip="Landing string not specified."></i>
      </p-tab>
      <p-tab value="2" *ngIf="showRiser">
        Riser
        <i class="validation-error pi pi-exclamation-circle pl-1" 
          *ngIf="form.formGroup.controls?.insideDiameter.invalid || form.formGroup.controls?.outsideDiameter.invalid" 
          pTooltip="Operation not saved, some required inputs not specified."></i>
      </p-tab>
      <p-tab value="3">
        Other
      </p-tab>
    </p-tablist>

    <p-tabpanels>
      <p-tabpanel value="0">
        <div class="flex flex-wrap gap-4">
          <!-- Left Column -->
          <div class="flex flex-col flex-1 min-w-[200px] space-y-2">

            <div class="flex flex-col">
              <label for="tripIntoHole">Trip Into Hole</label>
              <p-inputgroup>
                <p-inputnumber [minFractionDigits]="1" [formControlName]="form.formControlNames.tripIntoHole" />
                <p-inputgroup-addon>hr</p-inputgroup-addon>
              </p-inputgroup>
              <p *ngIf="form.formGroup.controls.tripIntoHole.value && form.formGroup.controls.tripIntoHole.invalid"
                class="validation-error">Allowed range: 0 - 100000 hrs
              </p>
            </div>

            <app-standard-fluid [formControlName]="form.formControlNames.standardFluidFlow"
              [operationType]="operationType" [userUnits]="userUnits">
            </app-standard-fluid>

            <div class="flex flex-col">
              <label for="startDrillingMd">Circulation Time</label>
              <p-inputgroup>
                <p-inputnumber [minFractionDigits]="1" [formControlName]="form.formControlNames.circulationTime" />
                <p-inputgroup-addon>hr</p-inputgroup-addon>
              </p-inputgroup>
              <p *ngIf="form.formGroup.controls.circulationTime.value && form.formGroup.controls.circulationTime.invalid
                " class="validation-error">Allowed range: 0 - 100000 hrs
              </p>
            </div>

            <div class="flex flex-col">
              <label for="endDrillingMd">Static Period</label>
              <p-inputgroup>
                <p-inputnumber [minFractionDigits]="1" [formControlName]="form.formControlNames.staticPeriod" />
                <p-inputgroup-addon>hr</p-inputgroup-addon>
              </p-inputgroup>
              <p *ngIf="form.formGroup.controls.staticPeriod.value && form.formGroup.controls.staticPeriod.invalid"
                class="validation-error">Allowed range: 0 - 300 hrs
              </p>
            </div>
          </div>

          <div class="flex flex-col space-y-2">
            <div class="flex flex-col">
              <label for="fluids">Fluid</label>
              <p-select [disabled]="isReadOnly" [options]="fluids" [formControlName]="form.formControlNames.fluid"
                dataKey="id" appendTo="body" optionLabel="state.name" tooltipPosition="top" 
                >
                <ng-template pTemplate="selectedItem">
                  {{ form.formGroup.value.fluid?.state.name }}
                </ng-template>
                <ng-template let-fluid pTemplate="item">
                  {{ fluid.state.name }}
                </ng-template>
              </p-select>
            </div>

            <div class="flex flex-col">
              <label for="inletTemperature">Inlet Temperature</label>
              <p-inputgroup>
                <p-inputnumber [minFractionDigits]="1" [formControlName]="form.formControlNames.inletTemperature" />
                <p-inputgroup-addon>{{ tempUnit }}</p-inputgroup-addon>
              </p-inputgroup>
              <p *ngIf="form.formGroup.controls.inletTemperature.invalid" class="validation-error">
                Allowed range: {{temperatureValidation.min }} - {{ temperatureValidation.max }} {{ tempUnit }}
              </p>
            </div>

          </div>
        </div>
      </p-tabpanel>

      <p-tabpanel value="1">
        <label class="{{ landingStringMissing ? 'validation-error' : '' }}">{{selectedLandingString}}</label>
      </p-tabpanel>

      <p-tabpanel value="2">
        <p-fieldset legend="Riser">
          <div class="flex flex-col">
            <label for="outsideDiameter">Outside Diameter</label>
            <p-inputgroup>
              <p-inputnumber [minFractionDigits]="1" [formControlName]="form.formControlNames.outsideDiameter" />
              <p-inputgroup-addon>{{ pipeRoughnessUnit }}</p-inputgroup-addon>
            </p-inputgroup>
          </div>

          <div class="flex flex-col">
            <label for="outsideDiameter">Inside Diameter</label>
            <p-inputgroup>
              <p-inputnumber [minFractionDigits]="1" [formControlName]="form.formControlNames.insideDiameter" />
              <p-inputgroup-addon>{{ pipeRoughnessUnit }}</p-inputgroup-addon>
            </p-inputgroup>
          </div>
        </p-fieldset>

      </p-tabpanel>

      <p-tabpanel value="3">
        <div class="flex flex-col">
          <label for="pipeRoughness">Pipe Roughness</label>
          <p-inputgroup>
            <p-inputnumber [minFractionDigits]="1" [formControlName]="form.formControlNames.pipeRoughness" />
            <p-inputgroup-addon>{{ pipeRoughnessUnit }}</p-inputgroup-addon>
          </p-inputgroup>
          <p class="validation-error" *ngIf="form.formGroup.controls.pipeRoughness.invalid">
            Allowed range: {{ pipeRoughnessValidation.min }} - {{ pipeRoughnessValidation.max }} {{ pipeRoughnessUnit }}
          </p>
        </div>
      </p-tabpanel>
    </p-tabpanels>
  </p-tabs>

</form>