import { AfterContentInit, Component, Input, OnChanges, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { InternalPressureProfileType, CasingFullOfGasIPP } from '../../models/internal-pressure-profile.model';
import { UomQuantityInput } from '../../uom-quantity-input/uom-quantity-input-component'
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { LinearInterpSingle } from 'src/app/perivis/shared/helpers/linear-interp-single.helper';
import { PoreFrac } from 'src/app/perivis/models/poreFrac.model';
import { TrajectoryService } from 'src/app/shared/services/trajectory.service';
import { SimpleChanges } from '@angular/core';
import { unitsLib } from 'src/app/core/services/unit-library';
import { lastValueFrom } from 'rxjs';

interface CasingFullOfGasForm {
  type: InternalPressureProfileType.CFOG;
  influxDepth: UomQuantityInput;
  porePressure: UomQuantityInput;
  marginOfError: UomQuantityInput;
  gasGradient: UomQuantityInput;
  fractureDepth: UomQuantityInput;
  fractureGradient: UomQuantityInput;
  fractureMarginOfError: UomQuantityInput;
  useGasGravity: boolean;
  gasGravity: UomQuantityInput;
}

@Component({
    selector: 'app-casing-full-of-gas-ipp',
    templateUrl: './casing-full-of-gas-ipp.component.html',
    styles: [`
    .leftFieldset {
      width: 50%;
    }
  `],
    providers: subformComponentProviders(CasingFullOfGasIppComponent),
    standalone: false
})
export class CasingFullOfGasIppComponent implements OnInit, OnChanges, AfterContentInit {
  @Input() userUnits: UserUnitsModel;
  @Input() currentString: any;
  @Input() shoeDepth: number;
  @Input() nextShoeDepth: number;

  private _defaultEmw: number;

  public gasGravityDisabled: boolean;
  public defaultEmw: number;
  public validators: any;
  public densityLabel: string;
  public gasGradientLabel: string;
  public defaultFracGradient: number;
  public gasGradientDefault: number;
  public densityValidation: { min: number, max: number };
  public fractureGradientValidation: { min: number, max: number };
  public marginOfErrorValidation: { min: number, max: number };
  public gasGradientValidation: { min: number, max: number };
  @Input() fracPressures: PoreFrac[];
  @Input() porePressures: PoreFrac[];

  public form = createForm<CasingFullOfGasIPP, CasingFullOfGasForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(InternalPressureProfileType.CFOG),
      influxDepth: new UntypedFormControl(null),
      porePressure: new UntypedFormControl(null),
      marginOfError: new UntypedFormControl(null),
      gasGradient: new UntypedFormControl(null),
      fractureDepth: new UntypedFormControl(null),
      fractureGradient: new UntypedFormControl(null),
      fractureMarginOfError: new UntypedFormControl(null),
      useGasGravity: new UntypedFormControl(false),
      gasGravity: new UntypedFormControl(null)
    },
    toFormGroup: (obj: CasingFullOfGasIPP | null): CasingFullOfGasForm | null => {
      if (!obj) {
        return null;
      }

      return {
        type: obj.type,
        influxDepth: { control: obj.influxDepth },
        porePressure: { control: obj.porePressure },
        marginOfError: { control: obj.marginOfError },
        gasGradient: { control: obj.gasGradient },
        fractureDepth: { control: obj.fractureDepth },
        fractureGradient: { control: obj.fractureGradient },
        fractureMarginOfError: { control: obj.fractureMarginOfError },
        useGasGravity: obj.useGasGravity,
        gasGravity: { control: obj.gasGravity }
      };
    },
    fromFormGroup: (formValue: CasingFullOfGasForm): CasingFullOfGasIPP | null => {
      const { influxDepth, porePressure, marginOfError, gasGradient, fractureDepth, fractureGradient, fractureMarginOfError, gasGravity, ...commonValues } = formValue;

      return {
        influxDepth: +influxDepth?.control,
        porePressure: +porePressure?.control,
        marginOfError: +marginOfError?.control,
        gasGradient: +gasGradient?.control,
        fractureDepth: +fractureDepth?.control,
        fractureGradient: +fractureGradient?.control,
        fractureMarginOfError: +fractureMarginOfError?.control,
        gasGravity: +gasGravity?.control,
        ...commonValues
      };
    }
  });

  constructor(private _trajectoryService: TrajectoryService) { }

  ngOnInit(): void {
    this.gasGravityChange();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.densityLabel = unitsLib[this.userUnits.density].symbol;
    this.gasGradientLabel = this.userUnits.gasGradient;

    switch (this.densityLabel) {
      case 'ppg':
        this.densityValidation = { min: 1.04, max: 25 };
        this.fractureGradientValidation = { min: 7, max: 25 };
        this.marginOfErrorValidation = { min: 0, max: 10 };
        this._defaultEmw = 10.5;
        break;
      case 'kg/m³':
      case 'g/L':
        this.densityValidation = { min: 124, max: 2995 };
        this.fractureGradientValidation = { min: 0.84, max: 2995 };
        this.marginOfErrorValidation = { min: 0, max: 1198 };
        this._defaultEmw = 1680;
        break;
      case 'g/cm³':
      case 'kg/l':
      case 'sg':
        this.densityValidation = { min: 0.12, max: 3 };
        this.fractureGradientValidation = { min: 0.084, max: 3 };
        this.marginOfErrorValidation = { min: 0, max: 1.2 }
        this._defaultEmw = 1.68;
        break;
    }

    switch (this.gasGradientLabel) {
      case 'psi/ft':
        this.gasGradientValidation = { min: 0, max: 5.1948 };
        this.gasGradientDefault = 0.1;
        break;
      case 'kPa/m':
        this.gasGradientValidation = { min: 0, max: 117.5 };
        this.gasGradientDefault = 2.26;
        break;
      case 'sg':
        this.gasGradientValidation = { min: 0, max: 11.97 };
        this.gasGradientDefault = 0.23;
        break;
      case 'bar/m':
        this.gasGradientValidation = { min: 0, max: 1.17 };
        this.gasGradientDefault = 0.022;
        break;
    }

    this.validators = {
      influxDepth: [Validators.min(this.currentString.hangerMd), Validators.max(Number.MAX_VALUE), Validators.required],
      porePressure: [Validators.min(this.densityValidation.min), Validators.max(this.densityValidation.max), Validators.required],
      marginOfError: [Validators.min(this.marginOfErrorValidation.min), Validators.max(this.marginOfErrorValidation.max), Validators.required],
      gasGradient: [Validators.min(this.gasGradientValidation.min), Validators.max(this.gasGradientValidation.max)],
      fractureDepth: [Validators.min(this.currentString.shoeMd), Validators.max(this.nextShoeDepth), Validators.required],
      fractureGradient: [Validators.min(this.fractureGradientValidation.min), Validators.max(this.fractureGradientValidation.max), Validators.required],
      fractureMarginOfError: [Validators.min(this.marginOfErrorValidation.min), Validators.max(this.marginOfErrorValidation.max), Validators.required],
      gasGravity: [Validators.required]
    };

    if (changes.currentString || changes.fracPressures || changes.porePressures) {
      this.getEmwDefaultFrac(this.nextShoeDepth);
    }
  }

  ngAfterContentInit(): void {
    this.gasGravityChange();
  }

  async getEmwDefaultFrac(depth): Promise<void> {
    const tvds = await lastValueFrom(this._trajectoryService.getTvdsFromMds([depth], true));

    if (this.fracPressures?.length > 1) {
      if (depth < this.currentString?.hangerMd) {
        return;
      }
      this.defaultEmw = +LinearInterpSingle(tvds[0], this.porePressures, this.userUnits).toFixed(2);
      this.defaultFracGradient = +LinearInterpSingle(tvds[0], this.fracPressures, this.userUnits).toFixed(2);
    } else {
      this.defaultFracGradient = this.currentString.annularFluid.state.nominalDensity + 2;
      this.defaultEmw = this._defaultEmw;
    }
    if (!this.form.formGroup.controls.fractureGradient.value?.control) {
      this.form.formGroup.controls.fractureGradient.setValue({ control: this.defaultFracGradient });
    }

    if (!this.form.formGroup.controls.porePressure.value?.control) {
      this.form.formGroup.controls.porePressure.setValue({ control: this.defaultEmw });
    }
  }

  public gasGravityChange() {
    if (this.form.formGroup.controls.useGasGravity.value) {
      this.useGasGravity();
    } else {
      this.useGasGradient();
    }
  }

  private useGasGravity() {
    this.form.formGroup.controls.useGasGravity.setValue(true);
    this.gasGravityDisabled = true;
  }

  private useGasGradient() {
    this.form.formGroup.controls.useGasGravity.setValue(false);
    this.gasGravityDisabled = false;
  }
}
