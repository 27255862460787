<div (keydown.enter)="saveLoadCase()">

  <form [formGroup]="newLoadForm">
      <label for="load-case-name">Load Case Name</label>
      <input class="w-full" id="load-case-name"  pInputText formControlName="name" autocomplete="off" />

      <div class="flex mt-2 space-x-2">
        <p-button
          outlined="true"
          class="outlined-button"
          size="small"
          severity="danger"
          label="Cancel"
          icon="pi pi-times"
          (onClick)="closeDialog()"
        ></p-button>
        <p-button
          outlined="true"
          class="outlined-button"
          size="small"
          severity="success"
          label="Save"
          [disabled]="newLoadForm.invalid"
          icon="pi pi-check"
          (onClick)="saveLoadCase()"
        ></p-button>
      </div>

  </form>
  
</div>
