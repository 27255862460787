<form [formGroup]="form.formGroup">
    <div class="field">
        <label>Reservoir Name</label>
        <div class="p-inputgroup">
          <input
            type="text"
            pInputText
            [formControlName]="form.formControlNames.name"
            id="name"
          />
        </div>
      </div>

      <div class="field">
        <label>Pressure</label>
        <div class="p-inputgroup">
          <p-inputNumber
            [minFractionDigits]="1"
            formControlName="pressure"
          ></p-inputNumber>
          <p-inputgroup-addon>{{ pressureUnit }}</p-inputgroup-addon>
        </div>
        <p
          *ngIf="
            form.formGroup?.controls.pressure.value &&
            form.formGroup?.controls.pressure.invalid
          "
          class="validation-error"
        >
          Allowed range: {{ pressureValidation.min }} -
          {{ pressureValidation.max }}
        </p>
      </div>

      <div class="field">
        <label>Permeability</label>
        <div class="p-inputgroup">
          <p-inputNumber
            [minFractionDigits]="1"
            formControlName="permeability"
          ></p-inputNumber>
          <p-inputgroup-addon>mD</p-inputgroup-addon>
        </div>
        <!-- <p
          *ngIf="
            form.formGroup?.controls.permeability.value &&
            form.formGroup?.controls.permeability.invalid
          "
          class="validation-error"
        >
          Allowed range: {{ permeabilityValidation.min }} -
          {{ permeabilityValidation.max }}
        </p> -->
      </div>

      <div class="field">
        <label>Height</label>
        <div class="p-inputgroup">
          <p-inputNumber
            [minFractionDigits]="1"
            formControlName="height"
          ></p-inputNumber>
          <p-inputgroup-addon>{{ lengthUnit }}</p-inputgroup-addon>
        </div>
        <!-- <p
          *ngIf="
            form.formGroup?.controls.height.value &&
            form.formGroup?.controls.height.invalid
          "
          class="validation-error"
        >
          Allowed range: {{ heightValidation.min }} -
          {{ heightValidation.max }}
        </p> -->
      </div>

      <div class="field">
        <label>Radial Extent</label>
        <div class="p-inputgroup">
          <p-inputNumber
            [minFractionDigits]="1"
            formControlName="radialExtent"
          ></p-inputNumber>
          <p-inputgroup-addon>{{ lengthUnit }}</p-inputgroup-addon>
        </div>
        <!-- <p
          *ngIf="
            form.formGroup?.controls.radialExtent.value &&
            form.formGroup?.controls.radialExtent.invalid
          "
          class="validation-error"
        >
          Allowed range: {{ radialExtentValidation.min }} -
          {{ radialExtentValidation.max }}
        </p> -->
      </div>

      <div class="field">
        <label>Skin Factor</label>
        <div class="p-inputgroup">
          <p-inputNumber
            [minFractionDigits]="1"
            formControlName="skinFactor"
          ></p-inputNumber>
        </div>
        <!-- <p
          *ngIf="
            form.formGroup?.controls.skinFactor.value &&
            form.formGroup?.controls.skinFactor.invalid
          "
          class="validation-error"
        >
          Allowed range: {{ skinFactorValidation.min }} -
          {{ skinFactorValidation.max }}
        </p> -->
      </div>

      <br>

      <div class="field">
        <p-toggleswitch
          formControlName="applyCustomPerformanceIndex"
          (onChange)="customPerformanceIndexChange($event)"
          binary="true"
        ></p-toggleswitch>
        <label style="margin-left: 5px;"> Custom Performance Index (J)</label>
      </div>

      <div class="field">
        <label>Performance Index (J)</label>
        <span class="pl-2">
          <i [title]="'J is specified with volume rate at Standard Conditions'" class="pi pi-info-circle"></i>
        </span>
        <p-inputgroup>
          <p-inputnumber [formControlName]="form.formControlNames.performanceIndex"/>
          <p-inputgroup-addon>ft³/hr/psi</p-inputgroup-addon>
        </p-inputgroup>
        <p
          *ngIf="
            form.formGroup?.controls.performanceIndex.value &&
            form.formGroup?.controls.performanceIndex.invalid
          "
          class="validation-error">
          Minimum value is 0
        </p>
      </div>
</form>