<form [formGroup]="form.formGroup">

  <p-toolbar>
    <ng-template #start>
      <div class="flex space-x-2">
        <div class="flex">
          <p-toggleswitch [formControlName]="form.formControlNames.co2withImpurities" (onChange)="typeChange(); getPercentages()"></p-toggleswitch>
          <label class="ml-2">CO₂ With Impurities</label>
        </div>

        <div class="flex">
          <p-toggleswitch (onChange)="tablePlotToggle()"> </p-toggleswitch>
          <label class="ml-2">Phase Envelope</label>
        </div>

        <div class="flex" *ngIf="showPlot">
          <p-toggleswitch [disabled]="isReadOnly" (onChange)="plotTableResultChange()"></p-toggleswitch>
          <label class="ml-2">Show Grid</label>
        </div>
      </div>

    </ng-template>

    <ng-template #end>
      <img class="w-[25px] h-[25px]" (click)="applyDefaults()" title="Apply Defaults"
        src="../../assets/icons/default-icon-white.png" />
    </ng-template>

  </p-toolbar>

  <app-tool-loader [isLoading]="isLoading" positionUnset="true">
  
    <app-ng-table-results
      *ngIf="showGridResult"
      tableName="Phase Envelope" 
      [tableData]="resultsGrid" 
      [columnDefinitions]="cols"
      [tableHeight]="tableHeight" 
      />
  
    <app-xy-line-plot 
      [hidden]="!showPlot || showGridResult"
      [plotData]="plot.data" [xAxisTitle]="xAxisTitle" [yAxisTitle]="yAxisTitle" [plotName]="plotName"
      [downloadPlotName]="downloadPlotName" [reverseAutoRange]="true" [plotTitle]="'Fluids'"
      [componentHeight]="fluidsComponentHeight"
      [xyLinePlotStore]="fluidsStore"
      >
    </app-xy-line-plot>
    
  </app-tool-loader>

  <div class="mt-2" [hidden]="showPlot">
    <br>
    <div *ngIf="!form.formGroup.controls.co2withImpurities.value" class="text-sm">
      <span>Pure CO₂</span> will be modelled
    </div>

    <div *ngIf="form.formGroup.controls.co2withImpurities.value" class="text-sm">
      <span> CO₂</span> will be modelled with the following impurities:
    </div>

    <br>
    <p-fieldset legend="Gas Composition %"
      [ngClass]="{ 'opacity-50 pointer-events-none': !form.formGroup.controls.co2withImpurities.value }">

      <div class="flex flex-wrap">

        <!-- Column 1 -->
        <div class="flex flex-col w-1/3 p-2 space-y-2">

          <div class="flex flex-col">
            <label>
              A<span class="text-sm align-sub">r</span>
            </label>
            <p-inputnumber 
              class="w-full"
              id="ar" 
              required
              [formControlName]="form.formControlNames.ar" 
              (onInput)="validatePercentage('ar', $event)"
              [placeholder]="form.formGroup.controls.co2withImpurities.value ? 0.002 : 0" [maxFractionDigits]="6" />
            <p *ngIf="form.formGroup.controls.ar.invalid" class="validation-error">Allowed range: 0 - 100 %</p>
          </div>

          <div class="flex flex-col">
            <label>
              C1 =&nbsp;
              <span>CH</span><span class="text-sm align-sub">4</span>
            </label>
            <p-inputNumber [formControlName]="form.formControlNames.cH4" id="cH4" required
              (onInput)="validatePercentage('cH4', $event)"
              [placeholder]="this.form.formGroup.controls.co2withImpurities.value ? 0.49 : 0" [maxFractionDigits]="6" />
            <p *ngIf="form.formGroup.controls.cH4.invalid" class="validation-error">Allowed range: 0 - 100 %</p>
          </div>

          <div class="flex flex-col">
            <label for="cO">CO</label>
            <p-inputNumber id="cO" [formControlName]="form.formControlNames.cO" required
              (onInput)="validatePercentage('cO', $event)" [placeholder]="0" [maxFractionDigits]="6" />
            <p *ngIf="form.formGroup.controls.cO.invalid" class="validation-error">Allowed range: 0 - 100 %</p>
          </div>

          <div class="flex flex-col">
            <label>
              H<span class="text-sm align-sub">2</span>
            </label>
            <p-inputNumber [formControlName]="form.formControlNames.h2" id="h2" required
              (onInput)="validatePercentage('h2', $event)"
              [placeholder]="form.formGroup.controls.co2withImpurities.value ? 2 : 0" [maxFractionDigits]="6" />
            <p *ngIf="form.formGroup.controls.h2.invalid" class="validation-error">Allowed range: 0 - 100 %</p>
          </div>

          <div class="flex flex-col">
            <label>
              NC<span class="text-sm align-sub">4</span>
            </label>
            <p-inputNumber [formControlName]="form.formControlNames.nC4" id="nC4" required
              (onInput)="validatePercentage('nC4', $event)" [placeholder]="0" [maxFractionDigits]="6" />
            <p *ngIf="form.formGroup.controls.nC4.invalid" class="validation-error">Allowed range: 0 - 100 %</p>
          </div>

          <div class="flex flex-col">
            <label>
              IC<span class="text-sm align-sub">5</span>
            </label>
            <p-inputNumber [formControlName]="form.formControlNames.iC5" id="iC5" required
              (onInput)="validatePercentage('iC5', $event)" [placeholder]="0" [maxFractionDigits]="6" />
            <p *ngIf="form.formGroup.controls.iC5.invalid" class="validation-error">Allowed range: 0 - 100 %</p>
          </div>

          <div class="flex flex-col">
            <label for="c8">
              C<span class="text-sm align-sub">8</span>
            </label>
            <p-inputNumber [formControlName]="form.formControlNames.c8" id="c8" required
              (onInput)="validatePercentage('c8', $event)" [placeholder]="0" [maxFractionDigits]="6" />
            <p *ngIf="form.formGroup.controls.c8.invalid" class="validation-error">Allowed range: 0 - 100 %</p>
          </div>

        </div>

        <!-- Column 2 -->
        <div class="w-full md:w-1/3 p-2 space-y-2">

          <div class="flex flex-col">
            <label for="h2S">
              H<span class="text-sm align-sub">2</span>
            </label>
            <p-inputNumber [formControlName]="form.formControlNames.h2s" id="h2s" required
              (onInput)="validatePercentage('h2s', $event)" [placeholder]="0" [maxFractionDigits]="6" />
            <p *ngIf="form.formGroup.controls.h2s.invalid" class="validation-error">Allowed range: 0 - 100 %</p>
          </div>

          <div class="flex flex-col">
            <label for="c2">
              C<span class="text-sm align-sub">2</span>
            </label>
            <p-inputNumber id="c2" [formControlName]="form.formControlNames.c2" required
              (onInput)="validatePercentage('c2', $event)" [maxFractionDigits]="6" />
            <p *ngIf="form.formGroup.controls.c2.invalid" class="validation-error">Allowed range: 0 - 100 %</p>
          </div>

          <div class="flex flex-col">
            <label for="nO2">N2O</label>
            <p-inputNumber [formControlName]="form.formControlNames.nO2" id="nO2" required
              (onInput)="validatePercentage('nO2', $event)" [placeholder]="" [maxFractionDigits]="6" />
            <p *ngIf="form.formGroup.controls.nO2.invalid" class="validation-error">Allowed range: 0 - 100 %</p>
          </div>

          <div class="flex flex-col">
            <label for="o2">
              O<span class="text-sm align-sub">2</span>
            </label>
            <p-inputNumber [formControlName]="form.formControlNames.o2" id="o2" required
              (onInput)="validatePercentage('o2', $event)"
              [placeholder]="form.formGroup.controls.co2withImpurities.value ? 0.001 : 0" [maxFractionDigits]="6" />
            <p *ngIf="form.formGroup.controls.o2.invalid" class="validation-error">Allowed range: 0 - 100 %</p>
          </div>

          <div class="flex flex-col">
            <label for="iC4">
              i-C<span class="text-sm align-sub">4</span>
            </label>
            <p-inputNumber [formControlName]="form.formControlNames.iC4" id="iC4" required
              (onInput)="validatePercentage('iC4', $event)" [placeholder]="0" [maxFractionDigits]="6" />
            <p *ngIf="form.formGroup.controls.iC4.invalid" class="validation-error">Allowed range: 0 - 100 %</p>
          </div>

          <div class="flex flex-col">
            <label for="nC6">
              NC<span class="text-sm align-sub">6</span>
            </label>
            <p-inputNumber [formControlName]="form.formControlNames.nC6" id="nC6" required
              (onInput)="validatePercentage('nC6', $event)" [placeholder]="0" [maxFractionDigits]="6" />
            <p *ngIf="form.formGroup.controls.nC6.invalid" class="validation-error">Allowed range: 0 - 100 %</p>
          </div>

        </div>

        <!-- Column 3 -->
        <div class="w-full md:w-1/3 p-2 space-y-2">

          <div class="flex flex-col">
            <label for="n2">
              N<span class="text-sm align-sub">2</span>
            </label>
            <p-inputNumber [formControlName]="form.formControlNames.n2" id="n2" required
              (onInput)="validatePercentage('n2', $event)"
              [placeholder]="form.formGroup.controls.co2withImpurities.value ? 1.5 : 0" [maxFractionDigits]="6" />
            <p *ngIf="form.formGroup.controls.n2.invalid" class="validation-error">Allowed range: 0 - 100 %</p>
          </div>

          <div class="flex flex-col">
            <label for="c3">
              C<span class="text-sm align-sub">3</span>
            </label>
            <p-inputNumber id="c3" [formControlName]="form.formControlNames.c3" required
              (onInput)="validatePercentage('c3', $event)" [maxFractionDigits]="6" />
            <p *ngIf="form.formGroup.controls.c3.invalid" class="validation-error">Allowed range: 0 - 100 %</p>
          </div>

          <div class="flex flex-col">
            <label for="c3">
              H<span class="text-sm align-sub">2</span><span>O</span>
            </label>
            <p-inputNumber [formControlName]="form.formControlNames.h2O" id="h2O" required
              (onInput)="validatePercentage('h2O', $event)"
              [placeholder]="form.formGroup.controls.co2withImpurities.value ? 0.005 : 0" [maxFractionDigits]="6" />
            <p *ngIf="form.formGroup.controls.h2O.invalid" class="validation-error">Allowed range: 0 - 100 %</p>
          </div>

          <div class="flex flex-col">
            <label>
              S<span>O</span><span class="text-sm align-sub">2</span>
            </label>
            <p-inputNumber [formControlName]="form.formControlNames.sO2" id="sO2" required
              (onInput)="validatePercentage('sO2', $event)" [placeholder]="0" [maxFractionDigits]="6" />
            <p *ngIf="form.formGroup.controls.sO2.invalid" class="validation-error">Allowed range: 0 - 100 %</p>
          </div>

          <div class="flex flex-col">
            <label for="nC5">
              NC<span>O</span><span class="text-sm align-sub">5</span>
            </label>
            <p-inputNumber [formControlName]="form.formControlNames.nC5" id="nC5" required
              (onInput)="validatePercentage('nC5', $event)" [placeholder]="0" [maxFractionDigits]="6" />
            <p *ngIf="form.formGroup.controls.nC5.invalid" class="validation-error">Allowed range: 0 - 100 %</p>
          </div>

          <div class="flex flex-col">
            <label for="c7">
              C<span class="text-sm align-sub">7</span>
            </label>
            <p-inputNumber [formControlName]="form.formControlNames.c7" id="c7" required
              (onInput)="validatePercentage('c7', $event)" [placeholder]="0" [maxFractionDigits]="6" />
            <p *ngIf="form.formGroup.controls.c7.invalid" class="validation-error">Allowed range: 0 - 100 %</p>
          </div>

          <div class="flex flex-col">
            <label class="{{ totalError ? 'validation-error' : '' }}">Total Impurities: {{ totalPercentage }} %</label>
          </div>

        </div>

      </div>

    </p-fieldset>

    <div class="flex flex-col mt-2">
      <label>Total CO₂: {{ co2Percentage }} %</label> 
      <br *ngIf="gergLabel">
      <label class="validation-error" *ngIf="gergLabel">{{ gergLabel }}</label>
    </div>

  </div>
</form>