<form [formGroup]="form.formGroup">
  <div class="flex flex-col space-y-2">
    <i
      class="pi pi-info-circle text-sm"
      title="This EPP applies the mud/fluid hydrostatic to TOC of the current casing and applies Pore Pressure for cement sections in the open hole.&#13;In the case of cement interval inside the shoe of the previous casing, the cement mix-water gradient is applied from TOC down to the previous shoe depth."
    ></i>

    <app-general-epp
      [formControlName]="form.formControlNames.generalEpp"
      [currentString]="currentString"
      [userUnits]="userUnits"
    ></app-general-epp>

    <div class="flex flex-col">
      <label>Cement Mix-Water Density</label>
      <app-validation-error
        [isValid]="isControlInvalid(form.formGroup, 'cementMixwaterDensity')"
        [errorMsg]="getControlErrors(form.formGroup, 'cementMixwaterDensity')"
      />
      <p-inputgroup>
        <p-inputNumber
          [formControlName]="form.formControlNames.cementMixwaterDensity"
          [maxFractionDigits]="2"
          title="Allowed range: {{ densityValidation.min }} - {{densityValidation.max}} {{ densityLabel }}"
          (onFocus)="onInputFocus($event)"
        ></p-inputNumber>
        <p-inputgroup-addon>{{ densityLabel }}</p-inputgroup-addon>
      </p-inputgroup>
    </div>
    
    <app-gas-cap
      hidden="true"
      [formControlName]="form.formControlNames.gasCap"
      [userUnits]="userUnits"
      [currentString]="currentString"
    ></app-gas-cap>
    
    <p class="validation-error"
      *ngIf="form.formGroup.hasError('porePressureNotSpecified')">
      Pore Pressure profile not specified.
    </p>

    <p class="validation-error"
      *ngIf="
        form.formGroup.hasError('porePressureMustBeToShoe') &&
        !form.formGroup.hasError('porePressureNotSpecified')
      ">
      Pore Pressure profile must be extended to the shoe of the string.
    </p>
    
    <p class="text-sm">
      Mud Weight: &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
      {{ currentString?.annularFluid.state.nominalDensity.toFixed(2) }} {{ densityLabel }}
    </p>

  </div>
</form>
