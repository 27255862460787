import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { lastValueFrom, Observable, Subscription } from 'rxjs';
import { WellConfigService } from 'src/app/shared/services/well-config.service';
import { PeriforOnChangeMessages, SignalRService } from '../../../shared/services/signal-r.service';
import { Packer, Packers, PackersUi } from '../../models/packers.model';
import { PackerService } from '../../services/packers.service';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { MediatorService } from 'src/app/shared/services/mediator.service';
import { GridItemResizedMessage } from 'src/app/shared/models/mediator-messages.model';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { Store } from '@ngneat/elf';
import { withUIEntities } from '@ngneat/elf-entities';
import { XyLinePlotUi } from 'src/app/core/models/xy-line-plot.model';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-packers',
  templateUrl: './packers.component.html',
  styles: [``],
  providers: [ConfirmationService],
  standalone: false
})
export class PackersComponent implements OnInit, OnDestroy {

  private _savedPackerDepth: number;
  private _selectedString: any;
  private _stringFullyCemented: boolean;
  private _isAddOrDelete: boolean;
  private _subscriptions: Subscription;

  public selectedPacker: Packer[] = [];
  public packerData: Packers;
  public packerDefaultDepth: number;
  public isWellConfigMissing: boolean;
  public isLoading: boolean;
  public selectedPackerDetails$: Observable<Packer>;
  public emitInitialValueOnInit = false;
  public onInit: boolean;
  public packerOrderLabel: string;
  public userUnits: UserUnitsModel;
  public componentHeight: number;

  // State Management
  private _componentId: string;
  @Input() set componentId(value: string) {
    this._componentId = value;
    this.packersStore = this._storeService.createStore(this.componentId, new PackersUi, withUIEntities<XyLinePlotUi>());
  }
  get componentId(): string {
    return this._componentId;
  }
  public packersStore: Store;

  constructor(
    private _packerService: PackerService,
    private _signalRService: SignalRService,
    private _confirmationService: ConfirmationService,
    private _wellConfigService: WellConfigService,
    private _storeService: StoreService,
    private _messenger: MediatorService
  ) {
    this._subscriptions = new Subscription();
    this._subscriptions.add(this._messenger.of(GridItemResizedMessage).subscribe((e) => {
      if (e.name == "Packers") {
        this.componentHeight = e.itemHeight - 220;
      }
    }));
  }

  async ngOnInit(): Promise<void> {
    this.userUnits = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);

    this.getData();

    const hub = this._signalRService.getConnectionToNotificationHub();
    this._signalRService.subscribeToEventFilteredByDesignId(hub, SignalRService.ON_PFB_CHANGE, d => this.signalRfunc(d));
  }

  signalRfunc(data: { action: string, designId: string }) {
    if (data.action == PeriforOnChangeMessages.REFRESH_STRING_INPUTS || data.action === PeriforOnChangeMessages.REFRESH_TUBULARS_LIST) {
      this.isWellConfigMissing = false;
      this.getData();
    }
  }

  public onPackerSelect(e: any) {
    const foundPacker = this.packerData.packers.find(x => x.name === e.value.name);
    this.selectedPacker[0] = foundPacker;
    this.selectedPackerDetails$ = new Observable(observer => observer.next(this.selectedPacker[0]));
  }

  public onAddPacker(): void {
    if ((this.packerData.packers.length > 0 && this.packerData.packers[this.packerData.packers.length - 1].measuredDepth == null || this._stringFullyCemented)) {
      return;
    }
    this._isAddOrDelete = true;
    const packer = this.createPacker();
    this.packerData.packers.push(packer);
    this.selectedPacker[0] = this.packerData.packers[this.packerData.packers.length - 1];
    this.selectedPackerDetails$ = new Observable(observer => observer.next(new Packer(this.selectedPacker[0])));
  }

  public onDeletePacker(packer: Packer): void {
    let index = this.packerData.packers.findIndex(x => x.measuredDepth == packer.measuredDepth);
    if (index == -1) {
      index = 0;
    }
    this._confirmationService.confirm({
      message: 'Are you sure that you want to delete the selected packer?',
      accept: () => {
        this.packerData.packers.splice(index, 1);
        this._isAddOrDelete = true;
        this.handleSavePackers();
        this.selectedPacker[0] = this.packerData.packers.length > 0 ? this.packerData.packers[this.packerData.packers.length - 1] : this.createPacker();
        this.selectedPackerDetails$ = new Observable(observer => observer.next(new Packer(this.selectedPacker[0])));
      }
    });
  }

  public onSortUp() {
    this.packerData.packers.sort((a,b) => b.measuredDepth - a.measuredDepth);
    this.onReorder();
  }

  public onSortDown() {
    this.packerData.packers.sort((a,b) => a.measuredDepth - b.measuredDepth);
    this.onReorder();
  }

  private async getData() {
    this.isLoading = true;
    this.packerData = await lastValueFrom(this._packerService.getPackersForTubular());
    this._selectedString = await lastValueFrom(this._wellConfigService.getTubular());

    if (this.packerData && this._selectedString) {
      this._stringFullyCemented = this.packerData.isFullyCemented;
      this.selectedPacker = [];

      for (let i = 0; i < this.packerData.packers?.length; i++) {
        if (this.packerData.packers[i].type == 'Mechanical') {
          this.packerData.packers[i].initialSetPressure = 0;
          this.packerData.packers[i].plugDepth = this.packerData.packers[i].measuredDepth;
        }

        if (!this.packerData.packers[i].packerEnvelope) {
          this.packerData.packers[i].packerEnvelope = [{
            force: 0,
            differentialPressure: 0,
          }];
        }
      }

      this.packerData.packers.sort((a,b) => a.sort - b.sort);

      this.checkPackerOrder();

      this.selectedPacker.push(this.packerData?.packers[0]);
      this.selectedPackerDetails$ = new Observable(observer => observer.next(new Packer(this.selectedPacker[0])));

      this.isLoading = false;
    } else {
      this.isWellConfigMissing = true;
    }


  }

  checkPackerOrder(): void {
    const packerDepths = this.packerData.packers.map(x => x.measuredDepth);

    const label = 'Setting Order: ';
    const aa = packerDepths.slice(1);
    if (!aa.length) {
        this.packerOrderLabel = null;
        return;
    }
    if (aa.every((a, i) => packerDepths[i] > a)) {
        this.packerOrderLabel = label + "Bottom-Up";
        return;
    }
    if (aa.every((a, i) => packerDepths[i] < a)) {
        this.packerOrderLabel = label + "Top-Down";
        return;
    }
    this.packerOrderLabel = label + "Custom (default)";
  }

  public async handleSavePackers(): Promise<void> {
    const reOrdered = this.packerData.packers.map((p,i) => {
      p.sort = i
      return p;
    });
    await lastValueFrom(this._packerService.updatePackers(reOrdered));

    if (this._isAddOrDelete) {
      this.isLoading = true;
      this.packerData = null;
      this.packerData = await lastValueFrom(this._packerService.getPackersForTubular());
      this._isAddOrDelete = false;
      this.isLoading = false;
    }
    this.packerData.packers.sort((a,b) => a.sort - b.sort);

    let index = this.packerData.packers.findIndex(x => x.measuredDepth == this._savedPackerDepth);
    if (index == -1) {
      index = this.packerData.packers.length - 1;
    }

    this.checkPackerOrder();

    this.selectedPacker[0] = this.packerData.packers[index];
    this.selectedPackerDetails$ = new Observable(observer => observer.next(new Packer(this.selectedPacker[0])));
  }

  async onReorder() {
    const reOrdered = this.packerData.packers.map((p,i) => {
      p.sort = i
      return p;
    });
    this.checkPackerOrder();
    await lastValueFrom(this._packerService.updatePackers(reOrdered));
  }

  public createPacker(): Packer {
    const randomPackerDepth = Math.floor(Math.random() * (this.packerData.topOfCementMd - this.packerData.hangerMd + 1) + this.packerData.hangerMd);
    const stringSectionIndex = this._selectedString.stringSections.findIndex(x => x.bottomMeasuredDepth >= randomPackerDepth);
    const packerSortNumber = this.packerData.packers[this.packerData.packers.length - 1]?.sort;
    const limitDefault = this.userUnits?.longLengths === 'ft' ? 30 : 10;

    const newPacker: Packer = {
      name: '',
      sort: packerSortNumber ? packerSortNumber + 1 : 0,
      type: 'Mechanical',
      measuredDepth: null,
      initialSetPressure: 0,
      plugDepth: this.packerData.topOfCementMd ?? this.packerData.shoeMd,
      pickupForce: 0,
      hasExpansionJoint: false,
      expansionJoint: {
        sealBoreContactDiameter: this._selectedString.stringSections[stringSectionIndex].pipe.outsideDiameter,
        isSheared: true,
        upwardLimit: limitDefault,
        downwardLimit: limitDefault
      },
      isPressureBarrier: true,
      packerEnvelope: [{
        force: 0,
        differentialPressure: 0,
      }]
    };

    return newPacker;
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.packerData.packers, event.previousIndex, event.currentIndex);
    this.onReorder();
  }

  public savePacker(e: any) {
    if (!(this.packerData.packers.find(x => x.name == e.name) ?? null)) {
      const pkrIndex = this.packerData.packers.findIndex(x => x.measuredDepth == e.measuredDepth);
      this.packerData.packers[pkrIndex].name = e.name;
    }

    let index = this.packerData.packers.findIndex(x => x.measuredDepth == e.measuredDepth);
    if (index == -1) {
      index = this.packerData.packers.findIndex(x => x.name == e.name);
    }
    if (index == -1) {
      index = this.packerData.packers.length - 1;
    }
    this.packerData.packers[index] = e;
    this._savedPackerDepth = e.measuredDepth;
    if (!this._savedPackerDepth) {
      return;
    }
    this.handleSavePackers();
  }

  ngOnDestroy() {
    this._subscriptions?.unsubscribe();
    this.signalRfunc = null;
  }
}
