import { AfterViewInit, Component } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { VleHeavyWeightHydrocarbonComponent, VleTuningParameters } from 'src/app/perical/models/fluid.model';

export interface VleForm {
  name: string;
  type: string;
  c1: number;
  c2: number;
  c3: number;
  nC4: number;
  iC4: number;
  nC5: number;
  iC5: number;
  nC6: number;
  n2: number;
  cO2: number;
  h2S: number;
  h2O: number;
  vleHeavyWeightHydrocarbonComponents: VleHeavyWeightHydrocarbonComponent[];
  vleTuningParameters: VleTuningParameters;
}

@Component({
    selector: 'app-vle',
    templateUrl: './vle.component.html',
    styles: [''],
    providers: subformComponentProviders(VleComponent),
    standalone: false
})
export class VleComponent implements AfterViewInit {

  public totalPercent: number;
  public totalError: boolean;
  public difference: number;
  public percentLabel: string;

  public form = createForm<VleForm>(this, {
    formType: FormType.SUB,
    formControls: {
      name: new UntypedFormControl(null),
      type: new UntypedFormControl(null, [Validators.required]),
      c1: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      c2: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      c3: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      nC4: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      iC4: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      nC5: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      iC5: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      nC6: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      n2: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      cO2: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      h2S: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      h2O: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      vleHeavyWeightHydrocarbonComponents: new UntypedFormControl(null),
      vleTuningParameters: new UntypedFormControl(null)
    }
  });

  public applyDefaults(): void {
    this.form.formGroup.controls.c1.setValue(100);
    this.form.formGroup.controls.c2.setValue(0);
    this.form.formGroup.controls.c3.setValue(0);
    this.form.formGroup.controls.nC4.setValue(0);
    this.form.formGroup.controls.iC4.setValue(0);
    this.form.formGroup.controls.nC5.setValue(0);
    this.form.formGroup.controls.iC5.setValue(0);
    this.form.formGroup.controls.nC6.setValue(0);
    this.form.formGroup.controls.n2.setValue(0);
    this.form.formGroup.controls.cO2.setValue(0);
    this.form.formGroup.controls.h2S.setValue(0);
    this.form.formGroup.controls.h2O.setValue(0);
  }

  ngAfterViewInit(): void {
    this.calculateTotalPercent(null, null);
  }

  calculateTotalPercent(updatedInput, e) {
    let sum = 0;

    Object.entries(this.form.formGroup.controls).forEach(control => {
      if (control[0] !== 'name' && control[0] !== 'type' && control[0] !== 'vleTuningParameters' && control[0] !== updatedInput) {
        if (control[0] !== 'vleHeavyWeightHydrocarbonComponents') {
          sum += +control[1].value;
        } else {
          Object.entries(control[1].value).forEach(value => {
            sum += value[1].fraction;
          });
        }
      }
    });
    sum += e?.value ?? 0;

    sum = +sum.toFixed(5);
    this.totalPercent = sum;

    if (sum !== 100) {
      this.totalError = true;
      const diff = +(100 - sum).toFixed(5);
      this.difference = Math.abs(diff);
      this.percentLabel = diff < 0 ? 'Over' : "Missing";
      this.form.formGroup.controls.vleHeavyWeightHydrocarbonComponents.setErrors({'incorrect': true});
    } else {
      this.totalError = false;
      this.form.formGroup.controls.vleHeavyWeightHydrocarbonComponents.setErrors({'incorrect': false});
      this.form.formGroup.controls.vleHeavyWeightHydrocarbonComponents.updateValueAndValidity({emitEvent: false});
    }
  }
}
