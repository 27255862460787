<app-tool-loader [isLoading]="isLoading" [positionUnset]="true">
  <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

  <div>
    <label>
      Total Well Depth: {{ totalDepth?.measuredDepth | number: '1.0-0' }} {{ longLengthUnit }} MD
    </label>
    <span>
      <i title="Note: Pipe, Grade and Connections will be copied into the well configuration from catalogs.&#13;If a Pipe, Grade or Connection is removed from the catalog, it will remain in the design.&#13;The design is valid and can be used without issue. It will be shown as (local only) in the Well Configuration." class="pi pi-info-circle"></i>
    </span>
  </div>

  <div class="overflow-auto">

      <form [formGroup]="wellConfigForm" novalidate>
        <p-contextMenu #cm [model]="contextMenuItems" appendTo="body"></p-contextMenu>
        <ng-container formArrayName="wellComponents">
          <p-table
          [scrollHeight]="tableHeight"
          [columns]="cols"
          [resizableColumns]="true"
          [value]="wellComponents?.controls"
          dataKey="value.id"
          rowExpandMode="single"
          [contextMenu]="cm"
          stateStorage="local"
          stripedRows
          [stateKey]="componentId"
          >
            <ng-template #header let-columns>
              <tr>
                <th>
                  Strings
                </th>
                <th *ngFor="let col of columns" pResizableColumn>
                  {{ col.header }}
                </th>
                <th class="place-items-center">
                  <p-button class="outlined-button whitespace-nowrap" size="small" [outlined]="true" severity="success" 
                    [disabled]="this.wellComponents.invalid" label="Add String" (click)="onAddWellComponent()">
                  </p-button>
                </th>
                
              </tr>
            </ng-template>

            <ng-template #body let-wellCmpFg let-wIdx="rowIndex" let-expanded="expanded">
              <ng-container [formGroupName]="wIdx">
                <tr [pContextMenuRow] (mouseover)="getRowIndex(wIdx, wellCmpFg)" (dblclick)="stringSectionsExpand(expanded, wIdx)" [ngStyle]="{'background-color': wIdx === selectedStringId ? 'var(--p-datatable-row-selected-background)' : 'var(--p-datatable-row-background)'}">
                  <td [title]="getStringSectionsTextHintHover(wellCmpFg)">
                    <p-button 
                        *ngIf="!userRoles.readOnly" 
                        text
                        size="small"
                        [icon]="expanded ? 'pi pi-chevron-circle-down' : 'pi pi-chevron-right'"
                        [disabled]="isControlInvalid(wellCmpFg, 'holeSize')"
                        type="button" 
                        [pRowToggler]="wellCmpFg"
                        (click)="stringSectionsExpand(expanded, wIdx)"
                      >
                    </p-button>
                    <i
                      *ngIf="userRoles.readOnly"
                      [pRowToggler]="wellCmpFg"
                      (click)="stringSectionsExpand(expanded, wIdx)"
                      tabindex="0"
                      [class]="expanded ? 'pi pi-chevron-circle-down' : 'pi pi-chevron-right'"
                    ></i>
                    <app-validation-error [isValid]="wellCmpFg.invalid" [errorMsg]="getControlErrors(wellCmpFg)" title="Expand for Errors"/>
                    <label *ngIf="!wellCmpFg.invalid">{{ getStringSectionsTextHint(wellCmpFg) }}</label>
                  </td>
                  <td>
                    <p-select class="w-[90%] min-w-[70px]" [disabled]="userRoles.readOnly" [options]="stringTypes" formControlName="type" appendTo="body" title="Type"></p-select>
                  </td>
                  <td>
                    <div class="w-[95%] flex items-center space-x-2">
                      <p-inputNumber formControlName="hangerMd" [minFractionDigits]="1" title="Hanger MD"></p-inputNumber>
                      <app-validation-error [isValid]="isControlInvalid(wellCmpFg, 'hangerMd')" [errorMsg]="getControlErrors(wellCmpFg,'hangerMd')"/>
                    </div>
                  </td>
                  <td>
                    <div class="w-[95%] flex items-center space-x-2">
                      <p-inputNumber formControlName="shoeMd" [minFractionDigits]="1" title="Shoe MD"></p-inputNumber>
                      <app-validation-error [isValid]="isControlInvalid(wellCmpFg, 'shoeMd')" [errorMsg]="getControlErrors(wellCmpFg,'shoeMd')"/>
                    </div>
                  </td>
                  <td>
                    <div class="w-[95%] flex items-center space-x-2">
                      <p-inputNumber formControlName="holeSize" [minFractionDigits]="2" title="Hole Size"></p-inputNumber>
                      <app-validation-error [isValid]="isControlInvalid(wellCmpFg, 'holeSize')" [errorMsg]="getControlErrors(wellCmpFg,'holeSize')"/>
                    </div>
                  </td>
                  <td>
                    <div class="w-[95%] flex items-center space-x-2">
                      <input
                        title="Has Cement"
                        type="checkbox"
                        binary="true"
                        formControlName="hasCement"
                      >
                      <p-inputNumber class="form-control" [minFractionDigits]="1" formControlName="topOfCementMd" title="Top of Cement MD"></p-inputNumber>
                      <app-validation-error [isValid]="isControlInvalid(wellCmpFg, 'topOfCementMd')" [errorMsg]="getControlErrors(wellCmpFg,'topOfCementMd')"/>
                    </div>
                  </td>
                  <td>
                    <p-select
                      class="w-full"
                      [disabled]="userRoles.readOnly"
                      title="Annular Fluid"
                      placeholder="Select"
                      [options]="fluids"
                      formControlName="annularFluidId"
                      appendTo="body"
                      (onChange)="onAnnularFluidChange(wellCmpFg)">
                    </p-select>
                    <app-validation-error [isValid]="isControlInvalid(wellCmpFg, 'annularFluidId')" [errorMsg]="getControlErrors(wellCmpFg,'annularFluidId')"/>
                  </td>
                  <td class="text-center">
                    <p-button text size="small" severity="danger" title="Remove String" icon="pi pi-trash" (click)="onDeleteWellComponent(wIdx, wellCmpFg)"></p-button>
                  </td>                  
                </tr>
              </ng-container>
            </ng-template>

            <ng-template #expandedrow let-wellCmpFg>
              <tr>
                <td colspan="9">
                <div class="ui-g ui-fluid pl-5">
                  <p-table
                  showGridlines
                  [value]="getWellComponentTubulars(wellCmpFg).controls"
                  [resizableColumns]="true"
                  [columns]="tubularCols"
                  dataKey="id"
                  >
                    <ng-container [formGroup]="wellCmpFg">
                      <ng-container formArrayName="stringSections">
                      <ng-template #header let-columns>
                        <tr>
                          <th>
                            Sections
                            <app-validation-error style="padding-left: 5px;" *ngIf="isControlInvalid(wellCmpFg, 'stringSections')" [isValid]="isControlInvalid(wellCmpFg, 'stringSections')" [errorMsg]="getControlErrors(wellCmpFg,'stringSections')"/>
                        </th>
                          <th *ngFor="let col of columns" pResizableColumn>
                            {{ col.header }}
                          </th>
                          <th pResizableColumn [ngStyle]="{'width':'50px'}">
                          </th>
                        </tr>
                      </ng-template>
                      <ng-template #body let-tIdx="rowIndex" let-rowData>

                        <tr [style.backgroundColor]="'var(--p-datatable-row-selected-background)'" [formGroupName]="tIdx">
                          <td class="flex items-center justify-center space-x-3">
                            <p-button text size="small" severity="secondary" title="Insert section above" icon="pi pi-angle-up" (click)="onAddTubular(wellCmpFg, tIdx, false)"></p-button>
                            <p-button text size="small" severity="secondary" title="Insert section below" icon="pi pi-angle-down" (click)="onAddTubular(wellCmpFg, tIdx, true)"></p-button>
                          </td>
                          <td>
                            <p-inputNumber [minFractionDigits]="1" formControlName="bottomMeasuredDepth"></p-inputNumber>
                            <app-validation-error [isValid]="isControlInvalid(rowData, 'bottomMeasuredDepth')" [errorMsg]="getControlErrors(rowData,'bottomMeasuredDepth')"/>
                          </td>
                            <ng-container formGroupName="pipe">
                              <td>
                                <p-select 
                                  class="w-full"
                                  [disabled]="userRoles.readOnly"
                                  [placeholder]="getPlaceholderText(rowData.get('pipe').value, 'outsideDiameter', rowData.get('bottomMeasuredDepth'), 'pipe')"
                                  [options]="rowData.get('odOptions').value"
                                  formControlName="outsideDiameter"
                                  appendTo="body">
                                </p-select>
                                <app-validation-error [isValid]="isControlInvalid(rowData.get('pipe'), 'outsideDiameter')" [errorMsg]="getControlErrors(rowData.get('pipe'), 'outsideDiameter')"/>
                              </td>

                              <td>
                                <p-select
                                  class="w-full"
                                  [disabled]="userRoles.readOnly"
                                  [placeholder]="getPlaceholderText(rowData.get('pipe').value, 'weightPerFoot', rowData.get('bottomMeasuredDepth'), 'pipe')"
                                  [options]="getSortedPipeOptions(rowData, 'weightPerFoot')"
                                  optionLabel="weightPerFoot"
                                  optionValue="id"
                                  formControlName="id"
                                  appendTo="body">
                                </p-select>
                                <app-validation-error [isValid]="isControlInvalid(rowData.get('pipe'), 'weightPerFoot')" [errorMsg]="getControlErrors(rowData.get('pipe'), 'weightPerFoot')"/>
                              </td>
                              <td>
                              <p-select
                                class="w-full"
                                [disabled]="userRoles.readOnly"
                                [placeholder]="getPlaceholderText(rowData.get('pipe').value, 'insideDiameter', rowData.get('bottomMeasuredDepth'), 'pipe')"
                                [options]="getSortedPipeOptions(rowData, 'insideDiameter')"
                                optionLabel="insideDiameter"
                                optionValue="id"
                                formControlName="id"
                                appendTo="body">
                              </p-select>
                              <app-validation-error [isValid]="isControlInvalid(rowData.get('pipe'), 'insideDiameter')" [errorMsg]="getControlErrors(rowData.get('pipe'), 'insideDiameter')"/>
                            </td>
                            <td>
                              <p-select
                                class="w-full"
                                [disabled]="userRoles.readOnly"
                                [placeholder]="getPlaceholderText(rowData.get('pipe').value, 'wallThickness', rowData.get('bottomMeasuredDepth'), 'pipe')"
                                [options]="getSortedPipeOptions(rowData, 'wallThickness')"
                                optionLabel="wallThickness"
                                optionValue="id"
                                formControlName="id"
                                appendTo="body">
                              </p-select>
                              <app-validation-error [isValid]="isControlInvalid(rowData.get('pipe'), 'wallThickness')" [errorMsg]="getControlErrors(rowData.get('pipe'), 'wallThickness')"/>
                            </td>
                          </ng-container>
                            <td>
                              <p-select
                                class="w-full"
                                [disabled]="userRoles.readOnly"
                                [title]="showJson(rowData.get('grade').value)"
                                [placeholder]="getPlaceholderText(rowData.get('grade').value, 'name', rowData.get('bottomMeasuredDepth'), 'grade')"
                                [options]="grades"
                                formControlName="grade"
                                appendTo="body">
                              </p-select>
                              <app-validation-error [isValid]="isControlInvalid(rowData, 'grade')" [errorMsg]="getControlErrors(rowData, 'grade')"/>
                            </td>
                            <td>
                              <div *ngIf="checkStrengthCalculation(rowData.get('grade'))">
                              </div>

                              <div class="flex items-center justify-center">
                                <p-button 
                                  *ngIf="!userRoles.readOnly"
                                  text
                                  size="small"
                                  severity="warning"
                                  [icon]="checkIfNonApiPipeDataExpanded(wellCmpFg, tIdx) ? 'pi pi-chevron-circle-down' : 'pi pi-chevron-right'" 
                                  (click)="expandNonApiPipeProperties(wellCmpFg, tIdx)" 
                                  label="{{ highCollapsePipeLabel }}">
                                </p-button>
                                <i
                                  *ngIf="userRoles.readOnly"
                                  (click)="expandNonApiPipeProperties(wellCmpFg, tIdx)"
                                  [class]="checkIfNonApiPipeDataExpanded(wellCmpFg, tIdx) ? 'pi pi-chevron-circle-down' : 'pi pi-chevron-right'"
                                >
                                  {{ highCollapsePipeLabel }}
                                </i>
                              </div>
                            </td>
                            <td class="place-items-center">
                              <div class="w-95%">
                              <p-select
                                [disabled]="userRoles.readOnly"
                                [title]="showJson(rowData.get('connection').value)"
                                [placeholder]="getPlaceholderText(rowData.get('connection').value, 'name', rowData.get('bottomMeasuredDepth'), 'connection')"
                                [options]="rowData.get('connectionOptions').value"
                                formControlName="connection"
                                appendTo="body">
                              </p-select>

                              <p-button 
                                text 
                                size="small" 
                                severity="danger" 
                                icon="pi pi-refresh" 
                                title="Remove Connection"
                                (click)="resetConnection(rowData.get('bottomMeasuredDepth'))" >
                              </p-button>
                            </div>
                          </td>
                          <td style="padding-right: 0px; text-align: center;">
                              <p-button text size="small" severity="danger" icon="pi pi-trash" title="Remove String Section" (click)="onDeleteTubular(wellCmpFg, tIdx)" [disabled]='rowData.parent.length == 1'></p-button>
                          </td>
                        </tr>

                        <tr *ngIf="checkIfNonApiPipeDataExpanded(wellCmpFg, tIdx)" [formGroupName]="tIdx">
                          <td colspan="10">
                              <ng-container formGroupName="nonApiPipe">
                                <tr>
                                  <th *ngFor="let col of nonApiPipeInputs" pResizableColumn>
                                    <label>{{ col.header }}</label>
                                  </th>
                                </tr>
                                <tr *ngIf="checkStrengthCalculation(rowData.get('grade'))">
                                  <td>
                                    <div class="flex flex-col items-center justify-center">
                                      <label>Edit</label>
                                      <p-toggleswitch formControlName="tensionEnabled" binary="true"></p-toggleswitch>
                                    </div>
                                  </td>

                                  <td>
                                    <div class="flex flex-col items-center justify-center">
                                      <label>Edit</label>
                                      <p-toggleswitch formControlName="compressionEnabled" binary="true"></p-toggleswitch>
                                    </div>
                                  </td>

                                  <td>
                                    <div class="flex flex-col items-center justify-center">
                                      <label>Edit</label>
                                      <p-toggleswitch formControlName="burstEnabled" binary="true"></p-toggleswitch>
                                    </div>
                                  </td>

                                  <td>
                                    <div class="flex flex-col items-center justify-center">
                                      <label>Edit</label>
                                      <p-toggleswitch formControlName="collapseEnabled" binary="true"></p-toggleswitch>
                                    </div>
                                  </td>

                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                </tr>
                                <tr>
                                  <td>
                                    <p-inputNumber [disabled]="enableHighCollapsePipeRatings(rowData, 'tensionEnabled', rowData.get('grade'), 'tension')" [minFractionDigits]="1" formControlName="tension"></p-inputNumber>
                                  </td>
                                  <td>
                                    <p-inputNumber [disabled]="enableHighCollapsePipeRatings(rowData, 'compressionEnabled', rowData.get('grade'), 'compression')" required [minFractionDigits]="1" formControlName="compression"></p-inputNumber>
                                  </td>
                                  <td>
                                    <p-inputNumber [disabled]="enableHighCollapsePipeRatings(rowData, 'burstEnabled', rowData.get('grade'), 'burst')" required [minFractionDigits]="1" formControlName="burst"></p-inputNumber>
                                  </td>
                                  <td>
                                    <p-inputNumber [disabled]="enableHighCollapsePipeRatings(rowData, 'collapseEnabled', rowData.get('grade'), 'collapse')" required [minFractionDigits]="1" formControlName="collapse"></p-inputNumber>
                                  </td>
                                  <td>
                                    <p-inputNumber required [minFractionDigits]="1" formControlName="wallToleranceHoop"></p-inputNumber>
                                  </td>
                                  <td>
                                    <p-inputNumber required [minFractionDigits]="1" formControlName="wallToleranceTriaxialAxial"></p-inputNumber>
                                  </td>
                                  <td>
                                    <p-inputNumber required [minFractionDigits]="1" formControlName="wallToleranceAxial"></p-inputNumber>
                                  </td>
                                  <td>
                                    <p-inputNumber required [minFractionDigits]="1" formControlName="wallToleranceBurst"></p-inputNumber>
                                  </td>
                                  <td>
                                    <p-inputNumber required [minFractionDigits]="1" formControlName="wallToleranceCollapse"></p-inputNumber>
                                  </td>
                                </tr>
                              </ng-container>
                          </td>
                        </tr>
                      </ng-template>
                      </ng-container>
                    </ng-container>
                  </p-table>
                </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </ng-container>
      </form>
    </div>
  <br>
</app-tool-loader>
