import { Injectable } from '@angular/core';
import { catchError, Observable, of, shareReplay } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Choke, ChokePerformance, ChokePerformanceResult, Chokes } from '../models/chokes.model';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`
@Injectable({
  providedIn: 'root'
})
export class ChokesService {
  private chokesCache$: Observable<Chokes> | null = null;

  constructor(private http: HttpClient) { }

  public getChokes(): Observable<Chokes> {
    if (!this.chokesCache$) {
      this.chokesCache$ = this.http
        .get<Chokes>(`${PeriforApiEndpoint}/thermalOperations/chokes`)
        .pipe(
          catchError(() => of(null as Chokes)),
          shareReplay(1)
        );
    }
    setTimeout(() => this.chokesCache$ = null, 1000);
    return this.chokesCache$;
  }

  updateChokes(choke: Choke): Observable<boolean> {
    return this.http.put<boolean>(`${PeriforApiEndpoint}/thermalOperations/chokes`, choke);
  }

  calculateChokePerformance(chokePerformance: ChokePerformance): Observable<ChokePerformanceResult> {
    return this.http.post<ChokePerformanceResult>(`${PeriforApiEndpoint}/thermalOperations/chokePerformance`, chokePerformance);
  }
}
