import { Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
@Component({
    selector: 'app-rename-tree-level-dialog',
    template: `
    <form [formGroup]="renameTreeLevelForm">
      <div class="field">
        <span class="p-float-label">
          <label for="levelName">{{ this.config.data.singularLevel  | titlecase }} Name</label>
          <input pInputText formControlName="LevelName" id="levelName"/>
        </span>
      </div>
      <br>
      <div style="float:right">
        <button pButton type="button" icon="pi pi-check" class="p-button-text p-button-success" [disabled]="renameTreeLevelForm.invalid" label="Save" (click)=onSave()></button>
        <button pButton type="button" icon="pi pi-times" class="p-button-text p-button-danger" label="Cancel" (click)=onCancel()></button>
      </div>
    </form>
  `,
    styles: [``],
    standalone: false
})

export class RenameTreeLevelDialogComponent {
  renameTreeLevelForm: UntypedFormGroup;
  
  constructor(
    private formBuilder: UntypedFormBuilder,
    public ref: DynamicDialogRef,
    public config: DynamicDialogConfig
  ) {
    this.renameTreeLevelForm = this.formBuilder.group({
      LevelName: ['', [Validators.required, Validators.minLength(3)]]
    });

    this.renameTreeLevelForm.controls.LevelName.setValue(this.config.data.currentLevelName);
   }

  onSave() {
    this.ref.close(this.renameTreeLevelForm.controls.LevelName.value);
  }

  onCancel() {
    this.ref.close();
  }
}
