import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';

export interface EnthalpyOptionsForm {
  successiveRelationFactor: number;
  latentHeatDampingFactor: number;
  includeLatentHeatPhaseChange: boolean;
}

@Component({
  selector: 'app-enthalpy-options',
  templateUrl: './enthalpy-options.component.html',
  styleUrls: ['./enthalpy-options.component.scss'],
  providers: subformComponentProviders(EnthalpyOptionsComponent),
  standalone: false
})
export class EnthalpyOptionsComponent implements AfterViewInit, OnChanges {
  @Input()
  public useEnergyAndEnthaplyFactorDefaults!: boolean;

  @Input()
  public disableCustomOptions = false;

  public latentHeatCheckboxInfo = " Phase change and associated latent heat is not present in many operations. However, in some cases it can be significant and should be included. If Latent Heat of Phase Change is included. If Latent Heat of Phase Change is included, it may be necessary to reduce the Relaxation Factor, e.g. 0.1.";
  public latentHeatInputInfo = " The default value of 100 is set to limit numerical instability.  However, it may be reduced to increase the impact of Latent Heat of Phase Change. ";
  public successiveFactorInfo = " The default value of 0.3 is tuned to provide stability but not slow down convergence";

  public form = createForm<EnthalpyOptionsForm>(this, {
    formType: FormType.SUB,
    formControls: {
      successiveRelationFactor: new UntypedFormControl(0.3),
      latentHeatDampingFactor: new UntypedFormControl(100),
      includeLatentHeatPhaseChange: new UntypedFormControl(true)
    }
  });

  ngAfterViewInit(): void {
    this.enableDisable();
  }

  ngOnChanges(): void {
    this.enableDisable();
  }

  private enableDisable() {
    if (this.disableCustomOptions) {
      this.form.formGroup.controls.successiveRelationFactor.disable({ emitEvent: false });
      this.form.formGroup.controls.latentHeatDampingFactor.disable({ emitEvent: false });
      this.form.formGroup.controls.includeLatentHeatPhaseChange.disable({ emitEvent: false });

      this.form.formGroup.controls.successiveRelationFactor.setValidators(null);
      this.form.formGroup.controls.successiveRelationFactor.updateValueAndValidity({ emitEvent: false });
      this.form.formGroup.controls.latentHeatDampingFactor.setValidators(null);
      this.form.formGroup.controls.latentHeatDampingFactor.updateValueAndValidity({ emitEvent: false });
    } else {
      this.form.formGroup.controls.successiveRelationFactor.enable({ emitEvent: false });
      this.form.formGroup.controls.latentHeatDampingFactor.enable({ emitEvent: false });
      this.form.formGroup.controls.includeLatentHeatPhaseChange.enable({ emitEvent: false });

      this.form.formGroup.controls.successiveRelationFactor.setValidators(Validators.required);
      this.form.formGroup.controls.successiveRelationFactor.updateValueAndValidity();
      this.form.formGroup.controls.latentHeatDampingFactor.setValidators(Validators.required);
      this.form.formGroup.controls.latentHeatDampingFactor.updateValueAndValidity();
    }
  }
}
