import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { LoadCaseDefinitionModel, LoadCaseSummaryResult, PreInstallLoad } from '../models/load-case.model';
import { BaseOperation } from 'src/app/perical/models/thermal-operation.model';
import { PostInstallLoad } from '../components/post-install-loads/post-install-load-details/models/post-install-load.model';
import { WellheadSlump } from '../models/wellhead-slump.model';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`;

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
    providedIn: 'root'
})
export class LoadCaseService {

    constructor(private http: HttpClient) {
    }

    getThermalOperations(): Observable<Partial<BaseOperation>[]> {
      return this.http.get<BaseOperation[]>(`${PeriforApiEndpoint}/loadcases/thermalOperations/`);
    }

    getLoadCasesSummary(isPostInstall: boolean): Observable<LoadCaseSummaryResult[]> {
      return this.http.get<LoadCaseSummaryResult[]>(`${PeriforApiEndpoint}/loadcases/summary?isPostInstall=${isPostInstall}`);
    }

    getLoadCase(index: number): Observable<PostInstallLoad> {
      return this.http.get<PostInstallLoad>(`${PeriforApiEndpoint}/loadcases/${index}`);
    }

    getPreInstallLoadCase(index: number): Observable<PreInstallLoad> {
      return this.http.get<PreInstallLoad>(`${PeriforApiEndpoint}/loadcases/${index}`);
    }

    getPressuresAndTemperatures(index: number): Observable<any> {
      return this.http.get<any[]>(`${PeriforApiEndpoint}/loadcases/pressures-temperatures/${index}`);
    }

    getAllPressuresAndTemperatures(): Observable<any> {
      return this.http.get<any[]>(`${PeriforApiEndpoint}/loadcases/all-pressures-temperatures/`);
    }

    addLoadCaseDefinition(loadCase: {name: string}): Observable<number> {
      return this.http.post<number>(`${PeriforApiEndpoint}/loadcases`, loadCase, httpOptions);
    }

    cloneLoadCaseDefinition(loadCase: {name: string, index: number}): Observable<number> {
      return this.http.post<number>(`${PeriforApiEndpoint}/loadCases/clone`, loadCase, httpOptions);
    }

    updateLoadCaseDefinitionOrder(model: {fromIndex:number, toIndex: number}): Observable<boolean> {
      return this.http.put<boolean>(`${PeriforApiEndpoint}/loadcases/order`, model, httpOptions);
    }

    updateLoadCase(loadCase: LoadCaseDefinitionModel): Observable<boolean> {
      return this.http.put<boolean>(`${PeriforApiEndpoint}/loadcases`, loadCase, httpOptions);
    }

    deleteLoadCase(index: number): Observable<boolean> {
      return this.http.delete<boolean>(`${PeriforApiEndpoint}/loadcases/${index}`);
    }

    deleteMultipleLoadCases(selectedLoadCaseIndexes: number[]): Observable<boolean> {
      return this.http.delete<boolean>(`${PeriforApiEndpoint}/loadcases/delete-multiple`, { body: selectedLoadCaseIndexes });
    }

    getPreInstallLoads(): Observable<any> {
      return this.http.get<any[]>(`${PeriforApiEndpoint}/preinstallloadcases/`);
    }

    updatePreinstallLoadCase(loadCase): Observable<boolean> {
      return this.http.put<boolean>(`${PeriforApiEndpoint}/loadcases`, loadCase, httpOptions);
    }

    updateWellheadSlump(wellheadSlump: WellheadSlump) {
      return this.http.put(`${PeriforApiEndpoint}/loadcases/wellheadSlump`, wellheadSlump, httpOptions);
    }

    getWellheadSlump(): Observable<WellheadSlump> {
      return this.http.get<WellheadSlump>(`${PeriforApiEndpoint}/loadcases/wellheadSlump`);
    }
}
