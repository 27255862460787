<h4>Are you sure that you want to delete <span *ngIf="this.config.data.level !== 'companies'">{{ parentName }} /
  </span>{{ levelName }}?</h4>

<div>
  <p-checkbox size="small" (keydown.enter)="onConfirm()" [(ngModel)]="accept" [binary]="true"
    inputId="accept"></p-checkbox>
  <label for="accept"> I understand that <b> {{ levelName }} </b> and any item(s) contained within will be deleted and
    that this action is <b>PERMANENT</b> and <b>CANNOT BE UNDONE!</b></label>
</div>

<br>
<br>
<div class="float-right">
  <button pButton type="button" [disabled]="!accept" label="Confirm" icon="pi pi-check" (click)="onConfirm()"
    class="p-button-text p-button-success"></button>
  <button pButton type="button" class="p-button-text p-button-danger" label="Cancel" (click)="onCancel()"
    icon="pi pi-times"></button>
</div>