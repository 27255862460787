import { Tubular } from "src/app/wellbore-inputs/components/well-configuration/models";

export class DashboardItemAddedMessage {
    constructor(public widgetIdentifier: string) { }
}

export class GridItemResizedMessage {
    constructor(public name: string, public itemHeight: number, public itemWidth:number) {
      window.dispatchEvent(new Event('resize'));
    }
}

export class ChangeSelectedTubular {
  constructor(public selectedTubular: Tubular) {}
}

export class ChangeSelectedThermalOperation {
    constructor(public selectedThermalOperation: any) {}
}

export class SyncPressAndTempResults {
    constructor(public syncPressAndTemp: boolean) {}
}

export class ToggleWellSchematicSidebarMessage {
}

export class OpenSearchComponentDropdown {
}
