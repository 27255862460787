import { Component, Input, AfterViewInit, OnChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { BaseOperation } from 'src/app/perical/models/thermal-operation.model';
import { ExternalPressureProfileType, PericalLinkEPP } from '../../models/external-pressure-profile.model';

interface PericalLinkEPPForm {
  type: ExternalPressureProfileType.PERICALLINK;
  thermalOperation: Partial<BaseOperation>;
}

@Component({
    selector: 'app-perical-link-epp',
    template: `
    <form [formGroup]="form.formGroup" >
      <label for="thermalOperation">Thermal Operation</label>
      <p-select
        class="w-full"
        id="thermalOperation"
        dataKey="id"
        [disabled]="isReadOnly"
        [options]="thermalOperations"
        [formControlName]="form.formControlNames.thermalOperation"
        optionLabel="name"
        appendTo="body"
      ></p-select>
    </form>
    `,
    providers: subformComponentProviders(PericalLinkEppComponent),
    standalone: false
})
export class PericalLinkEppComponent implements OnChanges, AfterViewInit {
  @Input() thermalOperations: Partial<BaseOperation>[];
  @Input() isReadOnly: boolean;

  public form = createForm<PericalLinkEPP, PericalLinkEPPForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(ExternalPressureProfileType.PERICALLINK),
      thermalOperation: new UntypedFormControl(null, Validators.required)
    },
    toFormGroup: (obj: PericalLinkEPP): PericalLinkEPPForm => {
      return {
        type: ExternalPressureProfileType.PERICALLINK,
        thermalOperation: !obj.thermalOperationId ? (!this.thermalOperations ? null : this.thermalOperations[0]) : this.thermalOperations.find(op => op.id === obj.thermalOperationId)
      };
    },
    fromFormGroup: (formValue: PericalLinkEPPForm): PericalLinkEPP => {
      return {
        type: ExternalPressureProfileType.PERICALLINK,
        thermalOperationId: formValue.thermalOperation.id
      };
    }
  });

  ngAfterViewInit(): void {
    setTimeout(() => {
      if (!this.form.formGroup.controls.thermalOperation.value  && this.thermalOperations) {
        this.form.formGroup.controls.thermalOperation.setValue(this.thermalOperations[0]);
      }
    }, 100);
  }

  ngOnChanges(): void {
    setTimeout(() => {
      if (!this.form.formGroup.controls.thermalOperation.value  && this.thermalOperations) {
        this.form.formGroup.controls.thermalOperation.setValue(this.thermalOperations[0]);
      }
    }, 100);
  }
}
