import { Component } from '@angular/core';
@Component({
  selector: 'app-changelog',
  template: `
  <div class="flex h-full w-full overflow-auto p-4">
    <p-card class="mx-auto w-2/3 p-4">
      <div class="p-4 m-2">
        <h1 class="text-[#90ee90] text-2xl font-bold mb-6 text-center">
          Changelog
        </h1>
        <div class="prose dark:prose-invert">
          <markdown
            ngPreserveWhitespaces
            [src]="'../../../../assets/CHANGELOG_165.md'"
          ></markdown>
        </div>
      </div>
    </p-card>
  </div>
  `,
  styles: [``],
  standalone: false
})

export class ChangelogComponent { }
