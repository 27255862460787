import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { lastValueFrom } from 'rxjs';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { CO2Fluid } from 'src/app/perical/models/fluid.model';
import { FluidsService } from 'src/app/perical/services/fluids.service';
import {zipObject} from 'lodash-es';
import { Store } from '@ngneat/elf';

@Component({
  selector: 'app-co2-fluid',
  templateUrl: './co2-fluid.component.html',
  styles: [],
  standalone: false,
  providers: subformComponentProviders(Co2FluidComponent),
})
export class Co2FluidComponent implements AfterViewInit, OnChanges {

  constructor(private _fluidService: FluidsService) { }

 
  @Input()
  public isPureCo2: any | null | undefined;

  @Input()
  public isReadOnly: boolean;

  @Input()
  public fluidsComponentHeight: number;

  @Input()
  public userUnits: UserUnitsModel;

 
  @Input()
  public co2ChangeTrigger: any | null | undefined;

  @Input()
  public set phaseEnvelopeCalculated(data: boolean) {
    if (data) {
      if (this.showPlot && data) {
        const fluidId = this?.form?.formGroup.value.id;
        this.plotData(fluidId);
      }
    }
  }

  @Input()
  public fluidsStore: Store;

  public plot = {
    data: [],
    layout: {},
    config: {
      scrollZoom: false
    }
  };

  public yAxisTitle = '0';
  public xAxisTitle = '0';
  public plotName: string;;
  public downloadPlotName = 'co2_phase_envelope';

  public gergLabel: string;
  public showComposition: boolean;
  public totalPercentage: number;
  public co2Percentage: number;
  public totalError: boolean;
  public showPlot: boolean;
  public isLoading: boolean;
  public tableHeight: string;
  public cols: any[] = [];
  public resultsGrid: any[] = [];
  public showGridResult = false;

  public form = createForm<CO2Fluid>(this, {
    formType: FormType.SUB,
    formControls: {
      id: new UntypedFormControl(null),
      name: new UntypedFormControl(null),
      type: new UntypedFormControl(null),
      co2withImpurities: new UntypedFormControl(null, [Validators.required]),
      h2O: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      cO: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      h2s: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      sO2: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      h2SO4: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      n2: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      nO2: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      o2: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      h2: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      ar: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      cH4: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      c2: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      c3: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      nC4: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      iC4: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      nC5: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      iC5: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      nC6: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      c7: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      c8: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100)])
    }
  });

  ngOnChanges(): void {
    if (!this.form.formGroup.controls.co2withImpurities.value) {
      this.gergLabel = null;
      // this.showPlot = false;
      this.plotName = 'co2PhaseEnvelopePlot - ' + this?.form?.formGroup.value.id;
      this.plotData(this?.form?.formGroup.value.id);
    } else {
      this.gergLabel = "NOTE: GERG-2008 EOS does not include reliable binary coefficients for SO2 and N2O. Consider lumping those molar %'s with CO2 or normalizing the composition without them.";
      this.plotName = 'co2PhaseEnvelopePlot' + this?.form?.formGroup.value.id;
      this.plotData(this?.form?.formGroup.value.id);
    }
    this.tableHeight = this.fluidsComponentHeight + 50 + 'px';

    this.getPercentages();
  }

  public validatePercentage(updatedInput, e) {
    let percentImpurities = this.getPercentImpuritiesFromControls(updatedInput);

    if (e) {
      percentImpurities += e.value;
    }

    if (percentImpurities > 90) {
      this.totalError = true;
      this.form.formGroup.controls.co2withImpurities.setErrors({ 'incorrect': true });
    } else {
      this.totalError = false;
      this.form.formGroup.controls.co2withImpurities.setErrors({ 'incorrect': false });
      this.form.formGroup.controls.co2withImpurities.updateValueAndValidity({ emitEvent: false });
    }

    percentImpurities = +percentImpurities.toFixed(5);
    this.co2Percentage = +(100 - percentImpurities).toFixed(5);
    this.totalPercentage = +percentImpurities.toFixed(3);
  }

  public getPercentages() {
    const percentImpurities = this.getPercentImpuritiesFromControls();
    this.co2Percentage = +(100 - percentImpurities).toFixed(6);
    this.totalPercentage = +percentImpurities.toFixed(3);
  }

  public tablePlotToggle() {
    this.isLoading = true;
    this.yAxisTitle = `Pressure (${this.userUnits.pressure})`
    this.xAxisTitle = `Temperature (${this.userUnits.temperature})`

    this.cols = [
      { field: 'pressure', header: `Pressure (${this.userUnits.pressure})`, valueFormatter: (params: any) => this.formatDecimal(params) },
      { field: 'temperature', header: `Temperature (${this.userUnits.temperature})`, valueFormatter: (params: any) => this.formatDecimal(params) }
    ];

    this.showPlot = !this.showPlot;
    this.showGridResult = false;
    if (this.showPlot) {
      const fluidId = this?.form?.formGroup.value.id;
      this.plotData(fluidId);
    }
  }

  public plotTableResultChange() {
    this.showGridResult = !this.showGridResult;
  }

  private getPercentImpuritiesFromControls(updatedInput ="") : number {
    let sum = 0;
    Object.entries(this.form.formGroup.controls).forEach(control => {
      if (!["name", "co2withImpurities", "type", "id", updatedInput].includes(control[0])) {
        sum += +control[1].value;
      }
    });
    return sum;
  }

  private async plotData(fluidId: string) {
    if (!this.showPlot) {
      this.isLoading = false;
      return;
    }

    const defaultEnvelope = {
      x: [-70, -65, -60, -55, -50, -45, -40, -35, -30, -25, -20, -15, -10, -5, 0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60, 65, 70, 75, 80, 85, 87.8],
      y:  [74.851189, 84.29903685, 94.62512064, 105.8790509, 118.1110952, 131.3721561, 145.7137646, 161.1880918, 177.8479785, 195.7469833, 214.9394527, 235.480611, 257.4266742, 280.8349879, 305.7641921, 332.2744152, 360.4275016, 390.2872765, 421.9198558, 455.3940105, 490.7815977, 528.1580776, 567.6031453, 609.2015188, 653.0439521, 699.2285857, 747.8628336, 799.0661885, 852.9747501, 909.7494477, 969.5939808, 1032.81054, 1069.930683]
    };

    switch (this.userUnits.temperature) {
      case '°F':
        // F is default
        break;
      case '°C':
        defaultEnvelope.x = defaultEnvelope.x.map(x => (x - 32) * 5 / 9);
        break;
      case 'K':
        defaultEnvelope.x = defaultEnvelope.x.map(x => (x - 32) * 5 / 9 + 273.15);
        break;
    }

    switch (this.userUnits.pressure) {
      case 'psi':
        // PSI is default
        break;
      case 'bar':
        case 'atm':
        defaultEnvelope.y = defaultEnvelope.y.map(x => x * 0.0689476);
        break;
      case 'kPa':
        defaultEnvelope.y = defaultEnvelope.y.map(x => x * 6.89476);
        break;
    }

    const plotData = this.form.formGroup.controls.co2withImpurities.value ? await lastValueFrom(this._fluidService.getPhaseEnvelope(fluidId)) as any : defaultEnvelope;
    if (plotData) {
      const results = this.createPoints(plotData.y, plotData.x);
      this.resultsGrid = results.map(r => zipObject(this.cols.map(c => c.field), r));

      this.isLoading = false;
    }

    this.plot.config.scrollZoom = plotData ? true : false;
    this.plot.data = [plotData];
  }

  createPoints(x: number[], y: number[]): [number, number][] {
    if (x.length !== y.length) {
      throw new Error("The 'x' and 'y' arrays must have the same length.");
    }

    const points: [number, number][] = [];

    for (let i = 0; i < x.length; i++) {
      const point: [number, number] = [x[i], y[i]];
      points.push(point);
    }

    return points;
  }

  public applyDefaults(): void {
    Object.entries(this.form.formGroup.controls).forEach(control => {
      if (control[0] != 'id' && control[0] != 'name' && control[0] != 'co2withImpurities' && control[0] != 'type') {
        control[1].value = 0;
      }
    });
    this.co2Percentage = 100;
    this.form.formGroup.controls.co2withImpurities.setValue(false);
  }

  public typeChange() {
    this.showComposition = !this.showComposition;
  }

  private formatDecimal(value: any) {
    return value || value === 0
      ? (Math.trunc(value * 100) / 100).toLocaleString('en-US')
      : null;
  }

  ngAfterViewInit(): void {
    this.validatePercentage(null, null);
  }
  
}
