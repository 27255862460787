import { Component, Input, OnChanges } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { unitsLib } from 'src/app/core/services/unit-library';
import { AdvancedMud, Fluid, FluidType } from 'src/app/perical/models/fluid.model';
import { GetValueFromFahrenheit } from 'src/app/perivis/shared/helpers/units.helper';

export interface AdvancedMudsForm {
  name: string;
  type: FluidType.ADVANCEDMUD;
  waterFraction: number;
  oilFraction: number;
  baseOil: string;
  baseBrine: Partial<Fluid>;
  highDensitySolidsFraction: number;
  highDensityMudSolidsType: string;
  lowDensitySolidsFraction: number;
  lowDensityMudSolidsType: string;
  plasticViscosity: number;
  yieldPoint: number;
  rheologyTemperature: number;
  densityTemperature: number;
  nominalDensity: number;
}

@Component({
    selector: 'app-advanced-muds',
    templateUrl: './advanced-muds.component.html',
    styles: [``],
    providers: subformComponentProviders(AdvancedMudsComponent),
    standalone: false
})
export class AdvancedMudsComponent implements OnChanges {

  @Input() set fluids(fluids: Fluid[] | null | undefined) {
    this.brines = fluids.filter(f => f.state.type === 'brine');
  }

  @Input()
  public userUnits: UserUnitsModel;

  @Input()
  public isReadOnly: boolean;

  public brines: Fluid[];

  public totalPercent = 100;
  public tempUnit: string;
  public densityUnit: string;
  public temperatureValidation: { min: number, max: number };
  public showBaseOil = false;

  public a0: number;
  public a1: number;
  public a2: number;
  public b0: number;
  public b1: number;
  public b2: number;

  public emitInitialValueOnInit = false;

  public baseOilTypes = [
    { name: 'Diesel Oil', value: 'dieselOil', a0: -3.6058, a1: 0.464, a2: -1.6843, b0: 8.7071, b1: 3.6031, b2: -72.465 },
    { name: 'LVT 200', value: 'lvt200', a0: -3.8503, a1: 1.5695, a2: -4.3373, b0: 8.3847, b1: 2.4817, b2: 6.5076 },
    { name: 'LAO C16C18', value: 'laoC16C18', a0: -3.5547, a1: 1.2965, a2: -2.7166, b0: 8.1304, b1: 3.1227, b2: -28.894 },
    { name: 'Saraline 200', value: 'saraline200', a0: -3.7621, a1: 1.5814, a2: -4.3235, b0: 8.0019, b1: 2.3560, b2: 10.891 },
    { name: 'EMO-4000', value: 'emo4000', a0: -3.7799, a1: 1.3525, a2: -3.1847, b0: 8.4174, b1: 2.8808, b2: -17.697 },
    { name: 'Internal Olefin', value: 'olefin', a0: -4.0647, a1: 1.3429, a2: -2.3981, b0: 8.1964, b1: 2.6739, b2: -23.981 }
  ];

  public highDensityMudSolidsTypes = [
    { name: 'Barite', value: 'barite' },
    { name: 'Hematite', value: 'hematite' },
    { name: 'Ilmenite', value: 'ilmenite' },
    { name: 'Siderite', value: 'siderite' },
    { name: 'Galena', value: 'galena' }
  ];

  public lowDensityMudSolidsTypes = [
    { name: 'Bentonite', value: 'bentonite' },
    { name: 'Aragonite', value: 'aragonite' },
    { name: 'Calcite', value: 'calcite' },
    { name: 'Attapulgite', value: 'attapulgite' },
    { name: 'Limestone', value: 'limestone' },
    { name: 'Sand', value: 'sand' },
    { name: 'NaCl', value: 'naCl' },
    { name: 'CaCl2', value: 'caCl2' }
  ];

  public form = createForm<AdvancedMud, AdvancedMudsForm>(this, {
    formType: FormType.SUB,
    formControls: {
      name: new UntypedFormControl(null),
      type: new UntypedFormControl(null),
      nominalDensity: new UntypedFormControl(null),
      densityTemperature: new UntypedFormControl(70),
      waterFraction: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      oilFraction: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      baseOil: new UntypedFormControl(null, [Validators.required]),
      baseBrine: new UntypedFormControl(null, [Validators.required]),
      highDensitySolidsFraction: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      highDensityMudSolidsType: new UntypedFormControl(null, [Validators.required]),
      lowDensitySolidsFraction: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(100)]),
      lowDensityMudSolidsType: new UntypedFormControl(null, [Validators.required]),
      plasticViscosity: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(2000)]),
      yieldPoint: new UntypedFormControl(null, [Validators.required, Validators.min(0), Validators.max(2000)]),
      rheologyTemperature: new UntypedFormControl(null),
    },
    formGroupOptions: {
      validators: [
        formGroup => {
          let sum = 0;


          Object.entries(formGroup.controls).forEach(control => {
            if (control[0] === 'waterFraction' || control[0] === 'oilFraction' || control[0] === 'highDensitySolidsFraction' || control[0] === 'lowDensitySolidsFraction') {
              sum += +control[1].value;
            }
          });
          this.totalPercent = +sum.toFixed(5);
          if (this.totalPercent !== 100) {
            return { totalPercentMustBe100: true };
          }
          return null;
        }
      ]
    },
    toFormGroup: (obj: AdvancedMud): AdvancedMudsForm => {
      if (!obj) {
        return null;
      }

      this.a0 = this.baseOilTypes.find(f => f.value === obj.baseOil).a0;
      this.a1 = this.baseOilTypes.find(f => f.value === obj.baseOil).a1;
      this.a2 = this.baseOilTypes.find(f => f.value === obj.baseOil).a2;
      this.b0 = this.baseOilTypes.find(f => f.value === obj.baseOil).b0;
      this.b1 = this.baseOilTypes.find(f => f.value === obj.baseOil).b1;
      this.b2 = this.baseOilTypes.find(f => f.value === obj.baseOil).b2;

      const { baseBrine, ...commonValues } = obj;

      return {
        ...commonValues,
        baseBrine: this.brines.find(f => f.id === baseBrine.id) ?? null
      };
    },
    fromFormGroup: (formValue: AdvancedMudsForm): AdvancedMud => {
      const { baseBrine, ...commonValues } = formValue;

      return {
        baseBrine: { id: baseBrine.id },
        ...commonValues
      };
    }
  });

  showBaseOils() {
    this.showBaseOil = !this.showBaseOil;
  }

  ngOnChanges(): void {
    this.tempUnit = this.userUnits.temperature;
    this.densityUnit = unitsLib[this.userUnits.density].symbol;

    switch (this.tempUnit) {
      case '°F':
        this.temperatureValidation = { min: 32, max: 500 };
        break;
      case '°C':
        this.temperatureValidation = { min: 0, max: 260 };
        break;
      case 'K':
        this.temperatureValidation = { min: 273.15, max: 533.15 };
        break;
    }

    this.form.formGroup.controls.densityTemperature.setValidators([Validators.required, Validators.min(this.temperatureValidation.min), Validators.max(this.temperatureValidation.max)]);
    this.form.formGroup.controls.rheologyTemperature.setValidators([Validators.required, Validators.min(this.temperatureValidation.min), Validators.max(this.temperatureValidation.max)]);
  }

  public applyDefaults(): void {
    const tempDefault = GetValueFromFahrenheit(70, this.tempUnit);

    this.form.formGroup.controls.waterFraction.setValue(100);
    this.form.formGroup.controls.oilFraction.setValue(0);
    this.form.formGroup.controls.highDensitySolidsFraction.setValue(0);
    this.form.formGroup.controls.lowDensitySolidsFraction.setValue(0);
    this.form.formGroup.controls.plasticViscosity.setValue(1);
    this.form.formGroup.controls.yieldPoint.setValue(0);
    this.form.formGroup.controls.rheologyTemperature.setValue(tempDefault);
    this.form.formGroup.controls.densityTemperature.setValue(tempDefault);

    this.validatePercentage(null, null);
  }

  public validatePercentage(updatedInput, e) {
    let sum = 0;

    Object.entries(this.form.formGroup.controls).forEach(control => {
      if ((control[0] === 'waterFraction' || control[0] === 'oilFraction' || control[0] === 'highDensitySolidsFraction' || control[0] === 'lowDensitySolidsFraction') && control[0] !== updatedInput) {
        sum += +control[1].value;
      }
    });
    sum += +e.value;
    this.totalPercent = +sum.toFixed(5);
  }
}
