import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TemperatureDerationService } from '../shared/services/temperature-deration.service';
import { ConfirmationService } from 'primeng/api';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { TemperatureDerationCatalog } from '../models/temperature-deration-catalog.model';
import { EMPTY_GUID } from 'src/app/perical/models/thermal-operation.model';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { StorageKeys, StoreService } from 'src/app/core/services/store.service';
import { GetValueFromFahrenheit } from 'src/app/perivis/shared/helpers/units.helper';
import { valuesIncreaseValidator } from 'src/app/shared/components/ng-table-grid/shared-grid.validators';
import { AppNotificationService } from 'src/app/shared/services/app-notification.service';
import { GridItemResizedMessage } from 'src/app/shared/models/mediator-messages.model';
import { MediatorService } from 'src/app/shared/services/mediator.service';

@Component({
    selector: 'app-temperature-deration',
    templateUrl: './temperature-deration.component.html',
    styles: [],
    providers: [ConfirmationService],
    standalone: false
})
export class TemperatureDerationComponent implements OnInit, OnDestroy {
  @ViewChild("nameField") nameField;
  @ViewChild("tempDerationListbox") tempDerationListbox;

  public tempDerations: any = [];
  public selectedTempDeration: Partial<TemperatureDerationCatalog>;
  public temperatureDerations: TemperatureDerationCatalog[];
  public columnDefinitions: { header: string, field: string }[];
  
  private _subscriptions: Subscription = new Subscription();
  private _userUnits: UserUnitsModel;

  constructor(
    private _temperatureDerationService: TemperatureDerationService,
    private _confirmationService: ConfirmationService,
    private _storeService: StoreService,
    private _toaster: AppNotificationService,
    private _messenger: MediatorService
  ) {
    this._subscriptions.add(this._messenger.of(GridItemResizedMessage).subscribe((e) => {
      if (e.name == "Temperature Deration") {    
        this.tempDerationListbox.listStyle = { 'max-height': `${(e.itemHeight - 90) + 'px'}` };
      }
    }));
  }


  public newDataRow(): FormGroup {
    return new FormGroup({
      temperature: new FormControl(0, [Validators.required, valuesIncreaseValidator("temperature")]),
      derationFactor: new FormControl(null, [Validators.required])
    });
  }

  public inputFields = [{
    name: 'temperature',
    minFractions: 2,
    maxFractions: 6,
    formatDecimals: 4
  },
  {
    name: 'derationFactor',
    minFractions: 2,
    maxFractions: 6,
    formatDecimals: 4
  }];

  // State
  @Input()
  public componentId: string;

  async ngOnInit(): Promise<void> {
    this._userUnits = await this._storeService.get<UserUnitsModel>(StorageKeys.UNITS);

    this.columnDefinitions = [
      { header: `Temperature (${this._userUnits.temperature})`, field: 'temperature'  },  
      { header: 'Deration Factor', field: 'derationFactor' },
    ];

    this.getData();
  }

  getData() {
    this._temperatureDerationService.getTemperatureDerations().subscribe(tempDerations => {
      this.tempDerations = tempDerations;

      this.selectedTempDeration = this.tempDerations[0];
    });
  }

  addItem(): void {
    if (this.tempDerations.length > 0 && this.tempDerations[this.tempDerations.length - 1].id === EMPTY_GUID) {
      return;
    }
    this.nameField.nativeElement.focus();
    const defaultProfile = [
      {
        temperature: GetValueFromFahrenheit(75, this._userUnits.temperature),
        derationFactor: 1,
      }
    ];

    const tempDeration: any = {
      id: EMPTY_GUID,
      name: '',
      temperatureDerationProfile: defaultProfile
    }

    this.tempDerations.push(tempDeration);
    this.selectedTempDeration = this.tempDerations[this.tempDerations.length - 1];
  }

  onSelect(e) {
    if (e.value.id !== EMPTY_GUID) {
      this.selectedTempDeration = e.value;
      // this._temperatureDerationService.getTemperatureDerationsById(this.selectedTempDeration.id).subscribe(e => {
      //   this.selectedTempDeration = e;
      // });
    }
  }

  deleteProfile(tempDeration: TemperatureDerationCatalog): void {
    if (tempDeration.id === EMPTY_GUID) {
      this.selectTemperatureDerationAfterDelete(tempDeration);
      return;
    }
    this._confirmationService.confirm({
      message: 'Are you sure that you want to delete ' + tempDeration.name.bold() + '?',
      accept: () => {
        this._temperatureDerationService.deleteTempereatureDeration(tempDeration.id).subscribe(() => {
          this.selectTemperatureDerationAfterDelete(tempDeration);
        });
      }
    });
  }

  selectTemperatureDerationAfterDelete(item) {
    const index = this.tempDerations.findIndex(x => x.id == item.id);
    this.tempDerations.splice(index, 1);
    this.selectedTempDeration = this.tempDerations[this.tempDerations.length - 1];
  }

  onTemperatureDerationChange(v: { dataRows: any[], reload: boolean }) {
    if (v.dataRows && v.dataRows.length > 1) {
      const index = this.tempDerations.findIndex(x => x.id == this.selectedTempDeration?.id);
      this.tempDerations[index].temperatureDerationProfile = [...v.dataRows];

      this.saveTemperatureDerations(index);
    }
  }

  onNameChange(e) {
    const name = e['srcElement']['value'];
    if (name) {
      const index = this.tempDerations.findIndex(x => x.id == this.selectedTempDeration.id);
      this.tempDerations[index].name = name;

      if (this.tempDerations[index].temperatureDerationProfile.length < 2) {
        return;
      }
      this.saveTemperatureDerations(index);
    }
  }

  private saveTemperatureDerations(index) {
    const name = this.tempDerations[index].name;
    const tempDerations = this.tempDerations.filter(x => x.id != this.tempDerations[index].id);
    const isNameExists = tempDerations.some(x => x.name === name);
    if (isNameExists) {
      this._toaster.showError(`Profile with name ${name} already exists`);
      return;
    }
    if (this.tempDerations[index].id === EMPTY_GUID) {
      this._temperatureDerationService.createTemperatureDerations(this.tempDerations[index]).subscribe(res => {
        this.tempDerations[index].id = res;
      });
    } else {
      this._temperatureDerationService.updateTemperatureDeration(this.tempDerations[index]).subscribe();
    }
  }

  ngOnDestroy(): void {
    this._subscriptions?.unsubscribe();
  }

}
