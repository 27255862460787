<form [formGroup]="form.formGroup">

  <div class="field pb-6">
    <label>Packer Name</label>
    <p-inputgroup>
      <input id="packer-name" type="text" pInputText [formControlName]="form.formControlNames.name" />
    </p-inputgroup>
  </div>

    <p-tabs value="0">
      <p-tablist>
        <p-tab value="0">Packer Data </p-tab>
        <p-tab value="1">Expansion Joint / PBR</p-tab>
        <p-tab value="2">Packer Envelope</p-tab>
      </p-tablist>

      <p-tabpanels class="w-full h-full">
        <p-tabpanel value="0">
          <div class="flex flex-col space-y-2">
            <div class="flex flex-col">
              <label>Setting Type</label>
              <p-select class="w-full" (onChange)="typeChange()" [formControlName]="form.formControlNames.type"
                optionValue="value" optionLabel="label" [options]="packerTypes"></p-select>
            </div>

            <div class="field">
              <label>Packer Depth</label>
              <p-inputgroup>
                <p-inputnumber [minFractionDigits]="1" [formControlName]="form.formControlNames.measuredDepth" />
                <p-inputgroup-addon>{{ userUnits.longLengths }} MD</p-inputgroup-addon>
              </p-inputgroup>
              <p class="validation-error"
                *ngIf=" form.formGroup.controls.measuredDepth.invalid && form.formGroup.value.measuredDepth">
                Allowed range: {{ packerMinDepth }} - {{ packerDefaultDepth }} {{ userUnits.longLengths }}
              </p>
            </div>

            <div [style.display]="form.formGroup.controls.type.value === 'Hydraulic' ||
              form.formGroup.controls.type.value === 'Hydrostatic' ? 'block' : 'none'">
              <div class="flex flex-col">
                <label>Initial Set Pressure</label>
                <p-inputgroup>
                  <p-inputnumber [minFractionDigits]="1" [formControlName]="form.formControlNames.initialSetPressure" />
                  <p-inputgroup-addon>{{ pressureUnit }}</p-inputgroup-addon>
                </p-inputgroup>
              </div>
            </div>
            
            <div [style.display]="form.formGroup.controls.type.value === 'Hydraulic' ? 'block' : 'none'">
              <div class="flex flex-col">
                <label>Plug Depth</label>
                <p-inputgroup>
                  <p-inputnumber [minFractionDigits]="1" [formControlName]="form.formControlNames.plugDepth" />
                  <p-inputgroup-addon>{{ userUnits.longLengths }} MD</p-inputgroup-addon>
                </p-inputgroup>
                <p class="validation-error" *ngIf="
                    form.formGroup.controls.plugDepth.invalid &&
                    form.formGroup.controls.plugDepth.value &&
                    form.formGroup.controls.type.value === 'Hydraulic'
                  ">
                  Allowed range:
                  {{ form.formGroup.controls.measuredDepth.value }} -
                  {{ packerDefaultDepth }} {{ userUnits.longLengths }}
                </p>
              </div>
            </div>

            <div class="flex flex-col">
              <div class="flex-row">
                <label>Pickup/Slackoff </label>
                <i title="Pickup (+)/Slackoff (-)" class="pi pi-info-circle"></i>
              </div>
              <p-inputgroup>
                <p-inputnumber [minFractionDigits]="1" [formControlName]="form.formControlNames.pickupForce" />
                <p-inputgroup-addon>{{ forceUnit }}</p-inputgroup-addon>
              </p-inputgroup>
            </div>

            <div class="w-full">
              <label>Anchor/ASV:</label>

              <hr style="height: 2px; margin-bottom: 10px" />

              <div class="flex items-center space-x-2">
                <p-checkbox size="small" binary="true" [formControlName]="form.formControlNames.isPressureBarrier">
                </p-checkbox>
                <label class="pt-1">
                  Annulus pressure barrier
                  <i title="Uncheck if Anchor or Open ASV" class="pi pi-info-circle"></i>
                </label>
              </div>

            </div>
          </div>
        </p-tabpanel>

        <p-tabpanel value="1">
          <p-fieldset>
            <ng-template pTemplate="header">
              <div class="flex items-center space-x-2">
                <p-checkbox size="small" binary="true" [formControlName]="form.formControlNames.hasExpansionJoint">
                </p-checkbox>
                <label class="pt-1">Expansion Joint / PBR</label>
              </div>
            </ng-template>

            <app-expansion-joint [formControlName]="form.formControlNames.expansionJoint"
              [packerDepth]="form.formGroup.controls.measuredDepth.value"
              [hasExpansionJoint]="form.formGroup.controls.hasExpansionJoint.value" [userUnits]="userUnits">
            </app-expansion-joint>
          </p-fieldset>
        </p-tabpanel>

        <p-tabpanel value="2">
            <div class="flex items-center space-x-2">
              <p-toggleswitch (onChange)="tablePlotToggle($event)" binary="true"></p-toggleswitch>
              <label class="pb-1">Table/Plot</label>
            </div>

            <div class="w-full h-full">
              <app-xy-line-plot *ngIf="selectedResultDisplay" [plotData]="plot.data" [xAxisTitle]="xAxisTitle"
                [yAxisTitle]="yAxisTitle" [plotName]="plotName" [downloadPlotName]="downloadPlotName"
                [plotTitle]="'Packers'" [reverseAutoRange]="true" 
                [componentHeight]="componentHeight"
                [xyLinePlotStore]="packersStore"
              ></app-xy-line-plot>

              <app-ng-table-grid [scrollHeight]="tableHeight" [hidden]="selectedResultDisplay" [parentComponentName]="'Packers'" tableName="Packer Envelope"
                [tableData]="packerEnvelope" [columnDefinitions]="columnDefinitions" [newRowFormGroup]="newDataRow"
                [inputFields]="inputFields" (dataChange)="onPackerEnvelopeChange($event)" [isDynamicLoaded]="true"
                [enableVirtualScroll]="false" 
              />
            </div>
        </p-tabpanel>

      </p-tabpanels>

    </p-tabs>

</form>