/** 
 * Parses text string fromt the clipboard in the fastest most nieve way possible. 
 * Essentially does the same thing as stringToArray, but in a not crazy way.
 */
export function  clipboardStringParser(event: ClipboardEvent): any[] {
  const clipboardData = event.clipboardData;
  const pastedText = clipboardData.getData('text').replace('/\r', '');
  const row_data = pastedText.split('\n').filter(x => x != "");
  const data = row_data.map(r => r.replace('\r', '').split('\t'));
  return data.length > 0 ? data : null;
}
