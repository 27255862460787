<form [formGroup]="form.formGroup">

  <p-toolbar>
    <ng-template #start>
    </ng-template>
    <ng-template #end>
      <img
      class="w-[25px] h-[25px]" 
      (click)="applyDefaults()"
      title="Apply Defaults"
      src="../../assets/icons/default-icon-white.png"
    />
    </ng-template>
  </p-toolbar>

  <div class="flex flex-wrap mt-2">

    <div class="flex flex-col w-1/2 p-2 space-y-2">

      <div class="flex flex-col">
        <label for="baseFluid">Base Fluid</label>
        <p-select
          id="baseFluid"
          class="w-full"
          [options]="baseFluids"
          [formControlName]="form.formControlNames.baseFluid"
          appendTo="body"
          optionLabel="label"
          optionValue="value"
        ></p-select>
      </div>

      <div class="flex flex-col">
        <label for="nominalDensity">Nominal Density</label>
        <p-inputgroup>
          <p-inputNumber
            id="nominalDensity"
            [formControlName]="form.formControlNames.nominalDensity"
            required
            [minFractionDigits]="1"
            (onBlur)="setDefaultPvYp($event, false)"
          ></p-inputNumber>
          <p-inputgroup-addon>{{ densityUnits }}</p-inputgroup-addon>
        </p-inputgroup>
        <p *ngIf="form.formGroup.controls.nominalDensity.invalid" class="validation-error">
          Allowed range: {{ densityValidation.min }} - {{ densityValidation.max }} {{ densityUnits }}
        </p>
      </div>

      <div class="flex flex-col">
        <label for="densityTemperature">Density Temperature</label>
        <p-inputgroup>
          <p-inputNumber
            id="densityTemperature"
            [formControlName]="form.formControlNames.densityTemperature"
            required
            [minFractionDigits]="1"
          ></p-inputNumber>
          <p-inputgroup-addon>{{ tempUnit }}</p-inputgroup-addon>
        </p-inputgroup>
        <p
          *ngIf="form.formGroup.controls.densityTemperature.invalid"
          class="validation-error"
        >
          Allowed range: {{ temperatureValidation.min }} - {{ temperatureValidation.max }} {{ tempUnit }}
        </p>
      </div>
    </div>

    <div class="flex flex-col w-1/2 p-2 space-y-2">

      <div class="flex flex-col">
        <label for="plasticViscosity">Plastic Viscosity</label>
        <p-inputgroup>
          <p-inputNumber
            id="plasticViscosity"
            [formControlName]="form.formControlNames.plasticViscosity"
            required
            [minFractionDigits]="1"
          ></p-inputNumber>
          <p-inputgroup-addon>cP</p-inputgroup-addon>
        </p-inputgroup>
        <p
          *ngIf="form.formGroup.controls.plasticViscosity.invalid"
          class="validation-error"
        >
          Allowed range: 0 - 2000 cP
        </p>
      </div>

      <div class="flex flex-col">
        <label for="yieldPoint">Yield Point</label>
        <p-inputgroup>
          <p-inputNumber
            id="yieldPoint"
            [formControlName]="form.formControlNames.yieldPoint"
            required
            [minFractionDigits]="1"
          ></p-inputNumber>
          <p-inputgroup-addon>lbf/100ft²</p-inputgroup-addon>
        </p-inputgroup>
        <p *ngIf="form.formGroup.controls.yieldPoint.invalid" class="validation-error">
          Allowed range: 0 - 2000 lbf/100ft²
        </p>
      </div>

      <div class="flex flex-col">
        <label for="rheologyTemperature">Rheology Temperature</label>
        <p-inputgroup>
          <p-inputNumber
            id="rheologyTempDefault"
            [formControlName]="form.formControlNames.rheologyTemperature"
            required
            [minFractionDigits]="1"
          ></p-inputNumber>
          <p-inputgroup-addon>{{ tempUnit }}</p-inputgroup-addon>
        </p-inputgroup>
        <p
          *ngIf="form.formGroup.controls.rheologyTemperature.invalid"
          class="validation-error"
        >
          Allowed range: {{ temperatureValidation.min }} - {{ temperatureValidation.max }} {{ tempUnit }}
        </p>
      </div>

    </div>
  </div>

</form>
