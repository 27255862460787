import { UdtPlotComponent } from '../../../../wellbore-inputs/components/udt-plot/udt-plot.component';
import { DesignLimitsPlotComponent } from '../../../../perivis/components/design-limits-plot/design-limits-plot.component';
import { SingleLoadBurstComponent } from '../../../../perivis/components/single-load-burst/single-load-burst.component';
import { DesignFactorsComponent } from '../../../../perivis/components/design-factors/design-factors.component';
import { UndisturbedTemperatureComponent } from '../../../../wellbore-inputs/components/undisturbed-temperature/undisturbed-temperature.component';
import { TrajectoryGridComponent } from '../../../../wellbore-inputs/components/trajectory-grid/trajectory-grid.component';
import { PostInstallLoadsComponent } from '../../../../perivis/components/post-install-loads/post-install-loads.component';
import { CementingLandingComponent } from '../../../../wellbore-inputs/components/cementing-landing/cementing-landing.component';
import { SingleLoadSafetyFactorsComponent } from '../../../../perivis/components/single-load-safety-factors/single-load-safety-factors.component';
import { SingleLoadConnectionSafetyFactorsComponent } from '../../../../perivis/components/single-load-connection-safety-factors/single-load-connection-safety-factors.component';
import { SingleLoadCollapseComponent } from '../../../../perivis/components/single-load-collapse/single-load-collapse.component';
import { SingleLoadAxialComponent } from '../../../../perivis/components/single-load-axial/single-load-axial.component';
import { StressOptionsComponent } from '../../../../perivis/components/stress-options/stress-options.component';
import { WellTypeDatumsComponent } from '../../../../wellbore-inputs/components/well-type-datums/well-type-datums.component';
import { GradesCatalogComponent } from '../../../../catalogs/grades-catalog/grades-catalog.component';
import { PackersComponent } from '../../../../wellbore-inputs/components/packers/packers.component';
import { WellConfigurationComponent } from 'src/app/wellbore-inputs/components/well-configuration/well-configuration.component';
import { ConnectionsCatalogComponent } from 'src/app/catalogs/connections-catalog/connections-catalog.component';
import { PipesCatalogComponent } from 'src/app/catalogs/pipes-catalog/pipes-catalog/pipes-catalog.component';
import { ThermalOperationsComponent } from 'src/app/perical/components/thermal-operations/thermal-operations.component';
import { ThermalTemperatureResultsComponent } from 'src/app/perical/components/thermal-temperature-results/thermal-temperature-results.component';
import { ThermalPressureResultsComponent } from 'src/app/perical/components/thermal-pressure-results/thermal-pressure-results.component';
import { PerforationsComponent } from 'src/app/wellbore-inputs/components/perforations/perforations.component';
import { FluidsComponent } from 'src/app/perical/components/fluids/fluids.component';
import { FormationTopsComponent } from 'src/app/wellbore-inputs/components/formation-tops/formation-tops.component';
import { InterpolationToolComponent } from 'src/app/wellbore-inputs/components/interpolation-tool/interpolation-tool.component';
import { PpfgPlotComponent } from 'src/app/perivis/components/ppfg-plot/ppfg-plot.component';
import { UiComponentModel } from 'src/app/core/components/dashboard/models/dashboard.model';
import { PorePressureComponent } from 'src/app/perivis/components/pore-pressure/pore-pressure.component';
import { FracturePressureComponent } from 'src/app/perivis/components/fracture-pressure/fracture-pressure.component';
import { TrajectoryPlotContainerComponent } from 'src/app/wellbore-inputs/components/trajectory-plot/trajectory-plot-container.component';
import { ApbInputsComponent } from 'src/app/perinix/components/apb-inputs/apb-inputs.component';
import { ApbResultsComponent } from 'src/app/perinix/components/apb-results/apb-results.component';
import { WellSchematicComponent } from 'src/app/wellbore-inputs/components/well-schematic/well-schematic.component';
import { DrillstringComponent } from 'src/app/wellbore-inputs/components/drillstring/drillstring.component';
import { WellheadComponent } from '../../../../wellbore-inputs/components/wellhead/wellhead.component';
import { LiftoffResultsComponent } from 'src/app/perivis/components/liftoff-results/liftoff-results.component';
import { FormationsCatalogComponent } from 'src/app/catalogs/formations-cement-catalog/formations-catalog/formations-catalog.component';
import { CementCatalogComponent } from 'src/app/catalogs/formations-cement-catalog/cement-catalog/cement-catalog.component';
import { PressuresTemperaturesComponent } from 'src/app/perivis/components/pressures-temperatures/pressures-temperatures.component';
import { DetailedAnnularResultsComponent } from 'src/app/perical/components/detailed-annular-results/detailed-annular-results.component';
import { PreInstallLoadsComponent } from 'src/app/perivis/components/pre-install-loads/pre-install-loads.component';
import { DisplacementResultsComponent } from 'src/app/perivis/components/displacement-results/displacement-results.component';
import { TemperatureDerationComponent } from 'src/app/catalogs/temperature-deration/temperature-deration.component';
import { DlsOverridesComponent } from 'src/app/wellbore-inputs/components/dls-overrides/dls-overrides.component';
import { PackerEnvelopeResultsComponent } from 'src/app/perivis/components/packer-envelope-results/packer-envelope-results.component';
import { PackerTableResultsComponent } from 'src/app/perivis/components/packer-table-results/packer-table-results.component';
import { LauncherSharedService } from 'src/app/core/services/launcher-shared.service';
import { Injectable } from '@angular/core';
import { OperationEnvelopeComponent } from 'src/app/perical/components/operation-envelope/operation-envelope.component';
import { ChokesComponent } from 'src/app/perical/components/chokes/chokes.component';
import { ChokePerformanceUtilityComponent } from 'src/app/perical/components/chokes/choke-performance-utility/choke-performance-utility.component';
import { PressureResultsComponent } from 'src/app/perivis/components/pressure-results/pressure-results.component';
import { PericalOptionsComponent } from 'src/app/perical/components/perical-options/perical-options.component';
import { ApbResultsGridComponent } from 'src/app/perinix/components/apb-results-grid/apb-results-grid.component';
import { WellheadSlumpComponent } from 'src/app/perivis/components/wellhead-slump/wellhead-slump.component';
import { ReservoirPerformanceUtilityComponent } from 'src/app/perical/components/reservoir-performance-utility/reservoir-performance-utility.component';
import { AnnulusContentsComponent } from 'src/app/wellbore-inputs/components/annulus-contents/annulus-contents.component';
import { UnitsConvertComponent } from '../../units-convert/units-convert.component';
import { ReservoirCatalogComponent } from 'src/app/perical/components/reservoir-catalog/reservoir-catalog.component';
import { CasingWearResultsComponent } from 'src/app/perivis/components/casing-wear/casing-wear-results/casing-wear-results.component';

@Injectable()
export class ComponentLauncher {
  constructor(private _launcherSharedService: LauncherSharedService) {
    this._launcherSharedService.getData().subscribe(schematicExpanded => {
      this._schematicExpanded = schematicExpanded;
    });
    this._launcherSharedService.getSidebarExpanded().subscribe(sidebarExpanded => {
      this._sidebarExpanded = sidebarExpanded;
    });
  }

  protected componentRegistry;
  private _schematicExpanded = false;
  private _sidebarExpanded = false;

  public loadComponentInstances(uiComponents: UiComponentModel[]) {
    this.componentRegistry = this.getComponentRegistryWithProperties();
    uiComponents?.forEach(component => {
      const uiComponent = this.componentRegistry.find(c => c.name === component.name);
      if (uiComponent) {
        component['componentInstance'] = uiComponent.componentInstance;
      }
    });
    this._launcherSharedService.setData(null, this.componentRegistry);
  }

  public generateComponent(componentKey: string): UiComponentModel {
    this.componentRegistry = this.getComponentRegistry();
    const uiComponent = this.componentRegistry.find(c => c.key === componentKey);
    if (this._sidebarExpanded && this._schematicExpanded) {
      uiComponent.cols += 2;
      uiComponent.rows += 3;
    }
    return uiComponent;
  }

  public addComponentToUi(uiComponent: UiComponentModel, uiComponents: UiComponentModel[]){
    if (uiComponent) {
      uiComponents.push({ ...uiComponent });
    }
  }

  public removeComponents(componentsToRemove: UiComponentModel[], uiComponents: UiComponentModel[]): UiComponentModel[] {
    return uiComponents.filter(currentComponent => !componentsToRemove.includes(currentComponent));
  }

  public getComponentRegistry(): any {
    return [
      // Perifor Inputs
      { key: "wellType", name: 'Well Type & Datums', componentInstance: WellTypeDatumsComponent, cols: 5, rows: 9 },
      { key: "trajectoryGrid", name: 'Trajectory Input', componentInstance: TrajectoryGridComponent, cols: 17, rows: 7 },
      { key: "trajectoryPlot", name: 'Trajectory Plot', componentInstance: TrajectoryPlotContainerComponent, cols: 13, rows: 13 },
      { key: "interpolationTool", name: 'MD => TVD Converter', componentInstance: InterpolationToolComponent, cols: 7, rows: 3 },
      { key: "undisturbedTemperature", name: 'UDT', componentInstance: UndisturbedTemperatureComponent, cols: 7, rows: 12, },
      { key: "udtPlot", name: "UDT Plot", componentInstance: UdtPlotComponent, cols: 13, rows: 12 },
      { key: "formationTops", name: 'Formation Tops', componentInstance: FormationTopsComponent, cols: 9, rows: 8 },
      { key: "fluids", name: 'Fluids', componentInstance: FluidsComponent, cols: 12, rows: 14 },
      { key: "wellConfiguration", name: 'Well Configuration', componentInstance: WellConfigurationComponent, cols: 25, rows: 10 },
      { key: "wellSchematic", name: 'Well Schematic', componentInstance: WellSchematicComponent, cols: 8, rows: 18 },
      { key: "cementSelector", name: 'Cementing & Landing', componentInstance: CementingLandingComponent, cols: 9, rows: 11 },
      { key: "drillstring", name: 'Drill String', componentInstance: DrillstringComponent, cols: 13, rows: 10 },
      { key: "wellhead", name: 'Wellhead', componentInstance: WellheadComponent, cols: 5, rows: 4 },
      { key: "packers", name: 'Packers', componentInstance: PackersComponent, cols: 12, rows: 11 },
      { key: "annulusContents", name: 'Annulus Contents', componentInstance: AnnulusContentsComponent, cols: 12, rows: 9 },
      { key: "unitsConvert", name: 'Units Convert', componentInstance: UnitsConvertComponent, cols: 9, rows: 4 },

      // Perical
      { key: "perforations", name: 'Perforations', componentInstance: PerforationsComponent, cols: 9, rows: 5 },
      { key: "chokes", name: 'Chokes', componentInstance: ChokesComponent, cols: 12, rows: 11 },
      { key: "chokesPerformanceUtility", name: 'Choke Performance Utility', componentInstance: ChokePerformanceUtilityComponent, cols: 10, rows: 10 },
      { key: "reservoirPerformanceUtility", name: 'Reservoir Performance Utility', componentInstance: ReservoirPerformanceUtilityComponent, cols: 11, rows: 13 },
      { key: "reservoirCatalog", name: 'Reservoir Catalog', componentInstance: ReservoirCatalogComponent, cols: 9, rows: 10 },
      { key: "pericalOptions", name: 'Perical Options', componentInstance: PericalOptionsComponent, cols: 9, rows: 8 },
      { key: "thermalOperations", name: 'Thermal Operations', componentInstance: ThermalOperationsComponent, cols: 15, rows: 16 },
      { key: "thermalTemperatureResults", name: 'Thermal Results - Temperatures', componentInstance: ThermalTemperatureResultsComponent, cols: 23, rows: 17 },
      { key: "thermalPressureResults", name: 'Thermal Results - Pressures', componentInstance: ThermalPressureResultsComponent, cols: 23, rows: 17 },
      { key: "detailedAnnularResults", name: 'Annular Results', componentInstance: DetailedAnnularResultsComponent, cols: 30, rows: 17 },
      { key: "operationEnvelope", name: 'Operation Envelope', componentInstance: OperationEnvelopeComponent, cols: 17, rows: 13 },

      // Perinix
      { key: "apbInputs", name: 'APB Inputs', componentInstance: ApbInputsComponent, cols: 9, rows: 8 },
      { key: "apbResults", name: 'APB Results', componentInstance: ApbResultsComponent, cols: 9, rows: 8 },
      { key: "apbGridResults", name: 'APB Results Grid', componentInstance: ApbResultsGridComponent, cols: 24, rows: 18 },

      // Perivis
      { key: "porePressure", name: 'Pore Pressure', componentInstance: PorePressureComponent, cols: 7, rows: 10 },
      { key: "fracPressure", name: 'Fracture Gradient', componentInstance: FracturePressureComponent, cols: 7, rows: 10 },
      { key: "ppfgPlot", name: 'PPFG Plot', componentInstance: PpfgPlotComponent, cols: 10, rows: 10 },
      { key: "additionalDoglegs", name: 'Additional Doglegs', componentInstance: DlsOverridesComponent, cols: 10, rows: 6 },
      { key: "stressOptions", name: 'Perivis Options', componentInstance: StressOptionsComponent, cols: 7, rows: 13 },
      { key: "designFactors", name: 'Design Factors', componentInstance: DesignFactorsComponent, cols: 7, rows: 10 },
      { key: "wellboreDisplacement", name: 'Wellbore Displacement', componentInstance: WellheadSlumpComponent, cols: 7, rows: 3 },
      { key: "preInstallLoads", name: 'Pre-Install Load Cases', componentInstance: PreInstallLoadsComponent, cols: 12, rows: 13 },
      { key: "postInstallLoads", name: 'Post-Install Load Cases', componentInstance: PostInstallLoadsComponent, cols: 24, rows: 16 },
      { key: "pressuresTemperatures", name: 'Load P&T Profiles', componentInstance: PressuresTemperaturesComponent, cols: 24, rows: 18 },
      { key: "designLimitsPlot", name: 'Design Limits Plot', componentInstance: DesignLimitsPlotComponent, cols: 30, rows: 16 },
      { key: "packerOperatingEnvelope", name: 'Packer Envelope', componentInstance: PackerEnvelopeResultsComponent, cols: 13, rows: 13 },
      { key: "packerTableResults", name: 'Packer Results Table', componentInstance: PackerTableResultsComponent, cols: 22, rows: 9 },
      { key: "pressureResults", name: 'Pressures', componentInstance: PressureResultsComponent, cols: 24, rows: 18 },
      { key: "singleLoadBurst", name: 'Barlow Burst Results', componentInstance: SingleLoadBurstComponent, cols: 24, rows: 18 },
      { key: "singleLoadCollapse", name: 'API Collapse Results', componentInstance: SingleLoadCollapseComponent, cols: 24, rows: 18 },
      { key: "singleLoadAxial", name: 'Axial Forces Results', componentInstance: SingleLoadAxialComponent, cols: 24, rows: 18 },
      { key: "displacementResults", name: 'Forces/Displacements', componentInstance: DisplacementResultsComponent, cols: 27, rows: 18 },
      { key: "singleLoadSafetyFactors", name: 'Safety Factor Results', componentInstance: SingleLoadSafetyFactorsComponent, cols: 24, rows: 18 },
      { key: "singleConnectionLoadSafetyFactors", name: 'Connection Safety Factor Results', componentInstance: SingleLoadConnectionSafetyFactorsComponent, cols: 24, rows: 18 },
      { key: "liftoffResults", name: 'Lift-Off Results', componentInstance: LiftoffResultsComponent, cols: 24, rows: 18 },
      { key: "casingWearResults", name: 'Max Allowed Wear', componentInstance: CasingWearResultsComponent, cols: 24, rows: 18 },

      // Catalogs
      { key: "formationsCatalog", name: 'Formations Catalog', componentInstance: FormationsCatalogComponent, cols: 16, rows: 6 },
      { key: "hardCementCatalog", name: 'Hard Cement Catalog', componentInstance: CementCatalogComponent, cols: 16, rows: 6 },
      { key: "temperatureDeration", name: 'Temperature Deration', componentInstance: TemperatureDerationComponent, cols: 9, rows: 11 },
      { key: "gradesCatalog", name: 'Grades Catalog', componentInstance: GradesCatalogComponent, cols: 24, rows: 8 },
      { key: "pipesCatalog", name: 'Pipes Catalog', componentInstance: PipesCatalogComponent, cols: 13, rows: 8 },
      { key: "connectionsCatalog", name: 'Connections Catalog', componentInstance: ConnectionsCatalogComponent, cols: 25, rows: 9 }
    ];
  }

  private getComponentRegistryWithProperties(): any {
    return this.getComponentRegistry().map(component => {
      const properties = this.getComponentProperties(component.key);
      return { ...component, properties: properties };
    });
  }

  private getComponentProperties(key: any): string[] {
    const propertiesMap: Record<string, string[]> = {
      additionalDoglegs: ['DLS Override', 'Additional Doglegs'],
      detailedAnnularResults: [
        'Pressure',
        'Velocity',
        'Density',
        'Viscosity',
        'Yield Point',
        'Flow Regime',
        'Quality',
        'Holdup',
        'Vaporization Enthalpy',
        'Heat Generation',
        'Latent Heat Term',
        'Pressure Loss',
        'Reynolds Number',
        'Fanning FF',
        'Prandtl Number',
        'Convection Coeff',
        'Natural Convection Coeff',
        'Radiation Coeff',
        'Nusselt Number',
        'Grashof Number',
        'Specific Heat Cp',
        'Specific Heat Cv',
        'Fluid Effective Conductivity',
        'Fluid Expansivity',
        'Liquid Velocity',
        'Liquid Density',
        'Liquid Viscosity',
        'Liquid Specific Heat Cp',
        'Liquid Specific Heat Cv',
        'Liquid Expansion Coeff',
        'Liquid Dissolved GOR',
        'Liquid Water Holdup',
        'Liquid Water Fraction Density',
        'Gas Velocity',
        'Gas Density',
        'Gas Viscosity',
        'Gas Specific Heat Cp',
        'Gas Specific Heat Cv',
        'Gas Expansion Coeff'
      ],
      apbInputs: ['Vented Annulus', 'Final Conditions', 'Annular Pressure Build-Up'],
      apbResults: ['Annular Pressure Build-Up', 'Fluid Expansion Volume', 'Annulus'],
      apbGridResults: ['Annular Pressure Build-Up', 'APB Radial Displacement', 'Annulus'],
      singleLoadCollapse: ['Collapse Load', 'API Collapse Pressure'],
      singleLoadAxial: ['Axial Load', 'Axial Rating'],
      singleLoadBurst: ['Burst Load', 'Burst Pressure'],
      cementSelector: ['Slurry Density', 'Hard Cement', 'Lead Slurry', 'Slurry Density', 'Slurry Length', 'Tail Slurry', 'Displacement Fluid', 'Pickup After Cement Set', 'Slackoff Force on Bottom'],
      chokesPerformanceUtility: ['Opening Travel', 'Upstream Pressure', 'Upstream Temperature'],
      chokes: ['Choke', 'Opening Travel'],
      singleConnectionLoadSafetyFactors: ['Burst', 'Collapse', 'Axial'],
      connectionsCatalog: ['Burst Rating', 'Collapse Rating', 'Tension Rating', 'Compression Rating'],
      designFactors: ['API Collapse', 'Barlow Burst', 'Compression', 'Tension', 'VME Burst', 'VME Collapse', 'Burst Leak', 'DF'],
      designLimitsPlot: ['Effective Pressure', 'Axial Force', 'DLP', 'VME'],
      drillstring: ['Drill Pipe', 'Heavy Weight', 'Collar', 'HWDP'],
      displacementResults: ['Pressure', 'Temperature', 'Axial Force', 'Axial Stress', 'Total Displacement',
        'Thermal Displacement', 'Poisson Displacement', 'Buckling Displacement', 'Hooke Displacement', 'Strain', 'Effective Force',
        'Critical Sinusoidal Buckling Force', 'Dogleg Severity', 'Dogleg Due To Buckling', 'Torque Due To Buckling'
      ],
      formationsCatalog: ['Density', 'Specific Heat Capacity', 'Thermal Conductivity', 'Youngs Modulus', 'Poissons Ratio', 'Thermal Expansion Coefficient'],
      gradesCatalog: ['Minimum API Yield Strength', 'Youngs Modulus', 'Poissons Ratio', 'Density', 'Thermal Expansion Coefficient', 'UTS',
         'Ultimate Tensile Strength', 'Specific Heat Capacity', 'Thermal Conductivity', 'Temperature Deration', 'Strength Calculation Option'],
      hardCementCatalog: ['Density', 'Specific Heat Capacity', 'Thermal Conductivity', 'Youngs Modulus', 'Poissons Ratio', 'Thermal Expansion Coefficient'],
      liftoffResults: ['Lift-Off Force',],
      packers: ['Setting Type', 'Packer Depth', 'Upward Limit', 'Downward Limit', 'Anchor', 'ASV', 'Expansion Joint', 'Packer Envelope', 'Differential Pressure', 'Pickup', 'Slackoff', 'Annulus Pressure Barrier'],
      packerOperatingEnvelope: ['Axial Force', 'Differential Pressure'],
      packerTableResults: ['Differential Pressure', 'Tubing to Packer Force', 'Latch Shear Load', 'Packer to Casing Force', 'Total Displacement', 'Thermal Displacement',
        'Poisson Displacement', 'Hooke Displacement', 'Buckling Displacement'
      ],
      perforations: ['Perforation Depth', 'Perforation Temperature'],
      pericalOptions: ['Vertical Grid Spacing', 'Successive Relaxation Factor', 'Latent Heat Damping Factor', 'Include Latent Heat Phase Change', 'Use Advanced Natural Convection Correlation', 'Apply Pseudo Phase Envelope', 'Energy and Enthalpy Effects'],
      stressOptions: ['Vertical Grid Spacing', 'Helical Factor Type', 'Critical Ratio for Transition to Helical Buckling', 'He-Kyllingstad Buckling Dogleg Correlation', 'API Collapse Type'],
      pipesCatalog: ['Tubular Pipes', 'Drill Pipes', 'Heavy Weight Drill Pipes', 'Collars', 'HWDP'],
      postInstallLoads: ['Custom', 'Perical Link', 'Pressure Test', 'Displacement Fluid', 'SSSV', 'General Fluid', 'Full Evacuation', 'WCD',
        'Lost Returns Fluid Drop', 'Circulated Kick', 'CFOG', 'Casing Full of Gas', 'Fluid to Shoe', 'Mud and Wet Cement', 'Mud and Cement Mix Water',
        'Interpolation to Pore Pressure', 'Mud and Pore Pressure', 'Pore Pressure', 'IPP',  'EPP', 'Internal Pressure Profile', 'External Pressure Profile',
        'Initial Conditions', 'BHCT', 'Bottom Hole Circulating Temperature'
      ],
      preInstallLoads: ['Run in Hole', 'Overpull', 'Green Cement Test', 'RIH', 'GCT', 'OVP'],
      reservoirCatalog: ['Permeability', 'Height', 'Radial Extent', 'Skin Factor', 'Performance Index'],
      reservoirPerformanceUtility: ['Permeability', 'Height', 'Radial Extent', 'Skin Factor', 'Performance Index'],
      singleLoadSafetyFactors: ['Burst', 'Collapse', 'Axial', 'VME Triaxial'],
      thermalOperations: ['Injection', 'Production', 'Circulation', 'Trip Pipe', 'Run Casing', 'Drilling', 'Cementing', 'Shut-in', 'Shutin', 'Nodal', 'GERG', 'Mud Pits'],
      trajectoryGrid: ['Measured Depth', 'Inclination', 'Azimuth', 'TVD', 'MD (ft)', 'North', 'East', 'Dogleg', 'Dogleg Severity'],
      undisturbedTemperature: ['Gradient', 'Surface Temperature', 'Mudline Temperature'],
      wellConfiguration: ['Tubular', 'Non API Pipe', 'Wall Tolerance', 'Tension', 'Compression', 'Burst', 'Collapse', 'Connections', 'Grade', 'Wall', 'String Sections'],
      wellboreDisplacement: ['Wellhead Slump'],
      wellhead: ['Wellhead Inner Diameter', 'Hanger Lockdown Rating'],
      wellType: ['Drill Floor Elevation', 'Wellhead Depth', 'Water Depth', 'DFE', 'Mudline'],
      annulusContents: ['Hanger Pressure', 'Annular Fluid', 'Annulus'],
      fluids: ['Brines', 'Standard Muds', 'Black Oil & Gas', 'Cement Slurries', 'VLE', 'Advanced Muds', 'CO2', 'GERG Fluids'],
    };
  
    return propertiesMap[key] || [];
  }  
}