import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { PoreFrac } from '../models/poreFrac.model';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`;

@Injectable({
  providedIn: 'root'
})
export class PoreAndFracService {

  constructor(private http: HttpClient) { }
  private porePressuresCache$: Observable<PoreFrac[]> | null = null;
  private fractureGradientCache$: Observable<PoreFrac[]> | null = null;

  public getPorePressures(): Observable<PoreFrac[]> {
    if (!this.porePressuresCache$) {
      this.porePressuresCache$ = this.http
        .get<PoreFrac[]>(`${PeriforApiEndpoint}/porePressure`)
        .pipe(
          map(data => data.map(item => new PoreFrac(item))),
          catchError(() => of([])),
          shareReplay(1)
        );
    }
    setTimeout(() => this.porePressuresCache$ = null, 1000);
    return this.porePressuresCache$;
  }

  public getFractureGradient(): Observable<PoreFrac[]> {
    if (!this.fractureGradientCache$) {
      this.fractureGradientCache$ = this.http
        .get<PoreFrac[]>(`${PeriforApiEndpoint}/fracturePressure`)
        .pipe(
          map(data => data.map(item => new PoreFrac(item))),
          catchError(() => of([])),
          shareReplay(1)
        );
    }
    setTimeout(() => this.fractureGradientCache$ = null, 1000);
    return this.fractureGradientCache$;
  }

  setPorePressures(porePressure: PoreFrac[]) {
    this.porePressuresCache$ = null;
    return this.http.put<PoreFrac[]>(`${PeriforApiEndpoint}/porePressure`, porePressure);
  }

  setFractureGradient(fracPressure: PoreFrac[]) {
    this.fractureGradientCache$ = null;
    return this.http.put<PoreFrac[]>(`${PeriforApiEndpoint}/fracturePressure`, fracPressure);
  }
}
