<form [formGroup]="form.formGroup">
  <p-tabs value="0">
    <p-tablist>
      <p-tab value="0">Composition %</p-tab>
      <p-tab value="1">Options</p-tab>
    </p-tablist>
    <p-tabpanels>
      <p-tabpanel value="0">

        <p-toolbar>
          <ng-template #start>
          </ng-template>
          <ng-template #end>
            <img class="w-[25px] h-[25px]" (click)="applyDefaults()" title="Apply Defaults" 
              src="../../assets/icons/default-icon-white.png" />
          </ng-template>
        </p-toolbar>


        <div class="flex">
          <div class="flex flex-col w-1/2 space-y-2 p-2">
            <div class="flex flex-col">
              <label for="c1">
                C<span class="text-sm align-sub">1</span>
              </label>
              <p-inputNumber id="c1" [formControlName]="form.formControlNames.c1" required [minFractionDigits]="1"
                (onInput)="calculateTotalPercent('c1', $event)">
              </p-inputNumber>
              <p *ngIf="form.formGroup.controls.c1.invalid" class="validation-error">
                Allowed range: 0 - 100 %
              </p>
            </div>

            <div class="flex flex-col">
              <label for="c2">C<span class="text-sm align-sub">2</span></label>
              <p-inputNumber id="c2" [formControlName]="form.formControlNames.c2" required [minFractionDigits]="1"
                (onInput)="calculateTotalPercent('c2', $event)"></p-inputNumber>
              <p *ngIf="form.formGroup.controls.c2.invalid" class="validation-error">
                Allowed range: 0 - 100 %
              </p>
            </div>
            <div class="flex flex-col">
              <label for="c3">C<span class="text-sm align-sub">3</span></label>
              <p-inputNumber id="c3" [formControlName]="form.formControlNames.c3" required [minFractionDigits]="1"
                (onInput)="calculateTotalPercent('c3', $event)"></p-inputNumber>
              <p *ngIf="form.formGroup.controls.c3.invalid" class="validation-error">
                Allowed range: 0 - 100 %
              </p>
            </div>
            <div class="flex flex-col">
              <label for="nC4">nC<span class="text-sm align-sub">4</span></label>
              <p-inputNumber id="nC4" [formControlName]="form.formControlNames.nC4" required [minFractionDigits]="1"
                (onInput)="calculateTotalPercent('nC4', $event)"></p-inputNumber>
              <p *ngIf="form.formGroup.controls.nC4.invalid" class="validation-error">
                Allowed range: 0 - 100 %
              </p>
            </div>
            <div class="flex flex-col">
              <label for="iC4">iC<span class="text-sm align-sub">4</span></label>
              <p-inputNumber id="iC4" [formControlName]="form.formControlNames.iC4" required [minFractionDigits]="1"
                (onInput)="calculateTotalPercent('iC4', $event)"></p-inputNumber>
              <p *ngIf="form.formGroup.controls.iC4.invalid" class="validation-error">
                Allowed range: 0 - 100 %
              </p>
            </div>
            <div class="flex flex-col">
              <label for="nC5">nC<span class="text-sm align-sub">5</span></label>
              <p-inputNumber id="nC5" [formControlName]="form.formControlNames.nC5" required [minFractionDigits]="1"
                (onInput)="calculateTotalPercent('nC5', $event)"></p-inputNumber>
              <p *ngIf="form.formGroup.controls.nC5.invalid" class="validation-error">
                Allowed range: 0 - 100 %
              </p>
            </div>
          </div>

          <div class="flex flex-col w-1/2 space-y-2 p-2">
            <div class="flex flex-col">
              <label for="iC5">iC<span class="text-sm align-sub">5</span></label>
              <p-inputNumber id="iC5" [formControlName]="form.formControlNames.iC5" required [minFractionDigits]="1"
                (onInput)="calculateTotalPercent('iC5', $event)"></p-inputNumber>
              <p *ngIf="form.formGroup.controls.iC5.invalid" class="validation-error">
                Allowed range: 0 - 100 %
              </p>
            </div>
            <div class="flex flex-col">
              <label for="nC6">nC<span class="text-sm align-sub">6</span></label>
              <p-inputNumber id="nC6" [formControlName]="form.formControlNames.nC6" required [minFractionDigits]="1"
                (onInput)="calculateTotalPercent('nC6', $event)"></p-inputNumber>
              <p *ngIf="form.formGroup.controls.nC6.invalid" class="validation-error">
                Allowed range: 0 - 100 %
              </p>
            </div>
            <div class="flex flex-col">
              <label for="n2">N<span class="text-sm align-sub">2</span></label>
              <p-inputNumber id="n2" [formControlName]="form.formControlNames.n2" required [minFractionDigits]="1"
                (onInput)="calculateTotalPercent('n2', $event)"></p-inputNumber>
              <p *ngIf="form.formGroup.controls.n2.invalid" class="validation-error">
                Allowed range: 0 - 100 %
              </p>
            </div>
            <div class="flex flex-col">
              <label for="cO2">CO<span class="text-sm align-sub">2</span></label>
              <p-inputNumber id="cO2" [formControlName]="form.formControlNames.cO2" required [minFractionDigits]="1"
                (onInput)="calculateTotalPercent('cO2', $event)"></p-inputNumber>
              <p *ngIf="form.formGroup.controls.cO2.invalid" class="validation-error">
                Allowed range: 0 - 100 %
              </p>
            </div>
            <div class="flex flex-col">
              <label for="h2S">H<span class="text-sm align-sub">2</span>S</label>
              <p-inputNumber id="h2S" [formControlName]="form.formControlNames.h2S" required [minFractionDigits]="1"
                (onInput)="calculateTotalPercent('h2S', $event)"></p-inputNumber>
              <p *ngIf="form.formGroup.controls.h2S.invalid" class="validation-error">
                Allowed range: 0 - 100 %
              </p>
            </div>
            <div class="flex flex-col">
              <label for="h2O">H<span class="text-sm align-sub">2</span>O</label>
              <p-inputNumber id="h2O" [formControlName]="form.formControlNames.h2O" required [minFractionDigits]="1"
                (onInput)="calculateTotalPercent('h2O', $event)"></p-inputNumber>
              <p *ngIf="form.formGroup.controls.h2O.invalid" class="validation-error">
                Allowed range: 0 - 100 %
              </p>
            </div>

            <p class="{{ totalError ? 'validation-error' : '' }}">Total: {{ totalPercent }} % </p>
            <p *ngIf="totalError" class="validation-error">Total must be equal to 100%  ({{ percentLabel }}: {{ difference }} %)</p>
          </div>
        </div>

        <p-fieldset legend="VLE Heavy Weight Hydrocarbons (maximum of three)">
          <app-heavies
            [formControlName]="
            form.formControlNames.vleHeavyWeightHydrocarbonComponents
            "(ngModelChange)="calculateTotalPercent(null, null)">
          </app-heavies>
        </p-fieldset>

      </p-tabpanel>

      <p-tabpanel value="1">
        <app-vle-tuning-parameters [formControlName]="form.formControlNames.vleTuningParameters">
        </app-vle-tuning-parameters>
      </p-tabpanel>
    </p-tabpanels>

  </p-tabs>

</form>