export class ReservoirPerformance {
    reservoir: Reservoir;
    operationType: string;
    fluidId: string;
    gasFlowRate: number;
    bottomholeTemperature: number;
    bottomholeDiameter: number;
    reservoirTemperature: number;
    isCo2: boolean;
    pvtConditions: string;
}

export class ReservoirPerformanceResult {
    bottomholePressure: number;
    performanceIndex: number;
    effectiveMassFlowRate: number;
    reservoirPhase: string;
    reservoirVaporQuality: number;
}

export class Reservoirs {
    reservoirs: Reservoir[];
    id: string;
    designId: string;
}

export class Reservoir {
    name: string;
    pressure: number;
    permeability: number;
    height: number;
    radialExtent: number;
    skinFactor: number;
    applyCustomPerformanceIndex: boolean;
    performanceIndex: number;
}