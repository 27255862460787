<form [formGroup]="form.formGroup" bg-dark>
  <div class="flex space-x-2">
    <div class="w-1/2 space-y-2">
      <p-fieldset legend="Mud Pit Environment">
        <div class="space-y-2">
          <div class="flex flex-col">
            <label for="isNewMudTemperature">Starting Conditions</label>
            <p-inputgroup>
              <p-select
                [options]="startingConditions"
                [formControlName]="form.formControlNames.isNewMudTemperature"
                optionLabel="name"
                optionValue="value"
                appendTo="body"
                (onChange)="disableConfiguration($event)"
              ></p-select>
            </p-inputgroup>
          </div>

          <div class="flex flex-col pb-3">
            <label for="hardCement">New Mud Temperature</label>
            <p-inputgroup>
              <p-inputNumber [minFractionDigits]="1" id="newMudTemperature" formControlName="newMudTemperature"
                onfocus="this.select()"></p-inputNumber>
              <p-inputgroup-addon>{{ tempUnit }}</p-inputgroup-addon>
            </p-inputgroup>
          </div>

          <p-fieldset legend="Wind/Ventilation Conditions">
            <div class="space-y-2">
              <div class="flex flex-col">
                <label>Air Temperature</label>
                <p-inputgroup>
                  <p-inputNumber [minFractionDigits]="1" id="airTemperature" formControlName="airTemperature"
                    onfocus="this.select()"></p-inputNumber>
                  <p-inputgroup-addon>{{ tempUnit }}</p-inputgroup-addon>
                </p-inputgroup>
              </div>

              <div class="flex flex-col">
                <label>Air Velocity</label>
                <p-inputgroup>
                  <p-inputNumber [minFractionDigits]="1" id="airVelocity" formControlName="airVelocity"
                    onfocus="this.select()"></p-inputNumber>
                  <p-inputgroup-addon>mph</p-inputgroup-addon>
                </p-inputgroup>
              </div>
            </div>
          </p-fieldset>
        </div>
      </p-fieldset>
    </div>

    <div class="w-1/2 space-y-2">
      <p-fieldset legend="Mud Pit Configuration">

        <div class="flex flex-col pb-2">
          <label>Number Of Mud Tanks</label>
          <p-inputNumber id="numberOfTanks" formControlName="numberOfTanks"
            onfocus="this.select()" (focusout)="getTotalMudVolume('numberOfTanks', $event)"></p-inputNumber>
        </div>

        <p-fieldset legend="Per Tank Details">
          <div class="space-y-2">
            <div class="flex flex-col">
              <label>Nominal Volume</label>
              <p-inputgroup>
                <p-inputNumber [minFractionDigits]="1" id="nominalVolume" formControlName="nominalVolume"
                  onfocus="this.select()" (focusout)="getTotalMudVolume('nominalVolume', $event)"></p-inputNumber>
                <p-inputgroup-addon>{{ volumeUnit }}</p-inputgroup-addon>
              </p-inputgroup>
            </div>

            <div class="flex flex-col">
              <label>Percentage Volume Active</label>
              <p-inputgroup>
                <p-inputNumber
                  [minFractionDigits]="1"
                  formControlName="activeVolumeFraction"
                  (focusout)="getTotalMudVolume('activeVolumeFraction', $event)"
                ></p-inputNumber>
                <p-inputgroup-addon>%</p-inputgroup-addon>
              </p-inputgroup>
            </div>
          </div>
        </p-fieldset>

        <div class="pt-2 space-y-2">
          <b><u><label>Total Mud Volume: {{ totalMudVolume }} {{ volumeUnit }}</label></u></b>

          <div class="flex flex-col">
            <label>Exposed Surface Area</label>
            <p-inputgroup>
              <p-inputNumber [minFractionDigits]="1" formControlName="exposedSurfaceArea" 
                onfocus="this.select()"></p-inputNumber>
              <p-inputgroup-addon>ft²</p-inputgroup-addon>
            </p-inputgroup>
          </div>

          <div class="flex flex-col">
            <label>Agitator Power Rating</label>
            <p-inputgroup>
              <p-inputNumber [minFractionDigits]="1" formControlName="agitatorPower" onfocus="this.select()"></p-inputNumber>
              <p-inputgroup-addon>hp</p-inputgroup-addon>
            </p-inputgroup>
          </div>
        </div>

      </p-fieldset>
    </div>
  </div>
</form>
