<div class="w-full h-full">
  <app-tool-loader [isLoading]="isLoading" positionUnset="true">
    <p-toolbar>
      <ng-template #start>
        <div class="flex items-center gap-2 toolbar-menu">
          <p-selectButton [options]="modes" [(ngModel)]="axialResultsStore.state.selectedMode"
            (onChange)="onModeChange($event)" [allowEmpty]="false">
          </p-selectButton>

          <p-selectButton [options]="resultsDisplay" [(ngModel)]="axialResultsStore.state.selectedResultDisplay"
            (onChange)="onSelectedResultDisplayUpdated($event)" [allowEmpty]="false">
          </p-selectButton>

          <p-selectButton *ngIf="axialResultsStore.state?.selectedResultDisplay === 'plot'" [options]="depthView"
            [(ngModel)]="axialResultsStore.state.selectedDepthView" (onChange)="depthViewToggle($event)"
            [allowEmpty]="false">
          </p-selectButton>

          <app-single-load-results [isAxialForce]="true" [loadCases]="loadCases"
            (loadCaseSelected)="setLoadCase($event)" [stateStore]="axialResultsStore" [signalRChange]="signalRChange">
          </app-single-load-results>
        </div>
      </ng-template>
    </p-toolbar>

    <app-single-load-plot [hidden]="axialResultsStore.state?.selectedResultDisplay !== 'plot'"
      [yAxisTitle]="yAxisTitle" [xAxisTitle]="xAxisTitle" [columnDefs]="columnDefs" [data]="results"
      [selectedDepthView]="axialResultsStore.state?.selectedDepthView" [depthUnit]="longLengthUnit"
      [plotName]="plotName" [plotTitle]="'Axial Forces Results'"
      [singleLoadPlotStore]="axialResultsStore">
    </app-single-load-plot>

    <app-single-load-grid [hidden]="axialResultsStore.state?.selectedResultDisplay !== 'grid'" [rowData]="results"
      [columnDefinitions]="columnDefinitions" tableName="Single Load Axial" gridName="Axial Forces Results">
    </app-single-load-grid>

    <p-dialog [draggable]="false" [(visible)]="errorMsg" [closable]="false">
      <i class="pi pi-exclamation-circle pr-1"></i>
      <label>{{ errorMsg }}</label>
      <br> <br>
    </p-dialog>

  </app-tool-loader>
</div>