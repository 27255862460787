import { UntypedFormControl } from "@angular/forms";
import { WellComponent } from "../models";

export function shoeValidator(control: UntypedFormControl) {
   const shoeMd = control.value;
   const hangerMd = control?.parent?.controls['hangerMd']?.value || 0;
   const type = control?.parent?.controls['type']?.value;

   if (shoeMd <= hangerMd) {
      return {error: "Shoe depth must be below hanger"};
   }

  const tubularString = control?.parent.getRawValue() as WellComponent;
  const wellConfiguration = control?.parent?.parent?.getRawValue() as WellComponent[];
  const idx = wellConfiguration.findIndex(wc => wc.id == tubularString.id);
  if(idx == 0){
      return null;
  }
  let previousTubular = wellConfiguration[idx -1];

  for (let i = idx; i > 0; i--) {
    if (wellConfiguration[i - 1].type == "Tieback") {
      previousTubular = wellConfiguration[i - 2];
      break;
    } else {
      previousTubular = wellConfiguration[i - 1];
      break;
    }
  }

  const linerHangers = wellConfiguration.filter(wc => wc.type == "Liner").map(wc => wc.hangerMd);
  if (type == 'Tieback' && shoeMd != linerHangers.find(lh => lh == shoeMd)) {
    return {error: `Tieback shoe depth ${shoeMd} must correspond to a liner hanger depth`};
  }

  if (shoeMd > previousTubular?.shoeMd && (type == "Tubing" || type == "Tieback")) {
      return {error: `Tubing shoe depth ${shoeMd} must be < previous shoe depth of ${previousTubular?.shoeMd}`};
  }

  if (shoeMd <= previousTubular?.shoeMd && (type == "Casing" || type == "Liner")) {
    return {error: `Casing/Liner shoe depth ${shoeMd} must be > previous shoe depth of ${previousTubular?.shoeMd}`};
  }  

   return null;
 }
