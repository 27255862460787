import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DlsOverrideModel } from 'src/app/shared/models/dlsOverride.model';
import { environment } from 'src/environments/environment';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`;

@Injectable({
    providedIn: 'root'
  })
  export class DlsOverridesService {
  
    constructor(private http: HttpClient) { }
  
    getDlsOverrides(): Observable<DlsOverrideModel[]> {
        return this.http.get<DlsOverrideModel[]>(`${PeriforApiEndpoint}/dlsOverride`);
    }
  
    updateDlsOverrides(dlsOverrides: DlsOverrideModel[]): Observable<DlsOverrideModel[]> {
        return this.http.put<DlsOverrideModel[]>(`${PeriforApiEndpoint}/dlsOverride`, dlsOverrides);
      }
  }
  