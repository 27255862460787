import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { ConnectionCatalog } from '../../models/connection-catalog.model';

const PeriforApiEndpoint_CustomConnections = `${environment.PeriforEndpoint}/api/customConnections`;

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/json'
    })
};

@Injectable({
    providedIn: 'root'
})
export class ConnectionsService {
    private connectionsCache$: Observable<ConnectionCatalog[]> | null = null;
    private connectionsByPipeIdCache$: Record<string, Observable<ConnectionCatalog[]>> = {};

    constructor(private http: HttpClient) { }

    public getConnections(): Observable<ConnectionCatalog[]> {
        if (!this.connectionsCache$) {
          this.connectionsCache$ = this.http
            .get<ConnectionCatalog[]>(`${PeriforApiEndpoint_CustomConnections}`)
            .pipe(
              map(data => data.map(item => new ConnectionCatalog(item))),
              catchError(() => of([])),
              shareReplay(1)
            );
        }
        setTimeout(() => this.connectionsCache$ = null, 1000);
        return this.connectionsCache$;
      }
    
      public getConnectionsByPipeId(pipeId: string): Observable<ConnectionCatalog[]> {
        if (!this.connectionsByPipeIdCache$[pipeId]) {
          this.connectionsByPipeIdCache$[pipeId] = this.http
            .get<ConnectionCatalog[]>(`${PeriforApiEndpoint_CustomConnections}/pipe/${pipeId}`)
            .pipe(
              map(data => data.map(item => new ConnectionCatalog(item))),
              catchError(() => of([])),
              shareReplay(1)
            );
        }
        setTimeout(() => this.connectionsByPipeIdCache$[pipeId] = null, 1000);
        return this.connectionsByPipeIdCache$[pipeId];
      }

    addConnection(model: ConnectionCatalog): Observable<{id: string}> {
        return this.http.post<{id: string}>(`${PeriforApiEndpoint_CustomConnections}`, model);
    }

    updateConnection(model: ConnectionCatalog) {
        return this.http.put<boolean>(`${PeriforApiEndpoint_CustomConnections}/${model.id}`, model, httpOptions);
    }

    deleteConnection(id: string): Observable<boolean> {
      return this.http.delete<boolean>(`${PeriforApiEndpoint_CustomConnections}/${id}`);
    }

}
