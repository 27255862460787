import {cloneDeep} from "lodash-es";

export function setPlotImageColorsForExport(gd) {
  gd.layout.yaxis.linecolor = 'gray';
  gd.layout.yaxis.title.font.color = 'black';
  gd.layout.yaxis.tickfont.color = 'black';
  gd.layout.yaxis.gridcolor = 'gray';
  gd.layout.xaxis.linecolor = 'gray';
  gd.layout.xaxis.title.font.color = 'black';
  gd.layout.xaxis.tickfont.color = 'black';
  gd.layout.xaxis.gridcolor = 'gray';
  gd.layout.legend.font.color = 'black';
  gd.layout.plot_bgcolor = 'white';
  gd.layout.paper_bgcolor = 'white';

  if (gd.data[0].colorbar) {
    gd.data[0].colorbar.tickfont.color = 'black';
  }

  if (gd.id.startsWith('dlpPlot')) {
    gd.layout.legend.orientation = 'h';
    gd['dataBackup'] = cloneDeep(gd.data);
    gd.data.forEach(trace => {
      trace.legendgroup = '';
    });
  }
}

export function setPlotImageColorsForInterface(gd) {
  gd.layout.yaxis.linecolor = 'grey';
  gd.layout.yaxis.title.font.color = 'white';
  gd.layout.yaxis.tickfont.color = 'white';
  gd.layout.yaxis.gridcolor = '#454545';
  gd.layout.xaxis.linecolor = 'grey';
  gd.layout.xaxis.title.font.color = 'white';
  gd.layout.xaxis.tickfont.color = 'white';
  gd.layout.xaxis.gridcolor = '#454545';
  gd.layout.legend.font.color = 'white';
  gd.layout.plot_bgcolor = '#1C1C1C';
  gd.layout.paper_bgcolor = '#1C1C1C';
  if (gd.data[0].colorbar) {
    gd.data[0].colorbar.tickfont.color = 'white';
  }
  if (gd.id.startsWith('dlpPlot')) {
    gd.layout.legend.orientation = '';
    gd.data = gd['dataBackup'];
  }
}
