import { UntypedFormControl } from "@angular/forms";

export function outerGreaterThanInnerDiameter(control: UntypedFormControl) {
    const outerDiameter = control.value;
    const innerDiameter = control.parent && control.parent.controls['insideDiameter'].value;
    if(outerDiameter <= innerDiameter){
       return {error: "Outside Diameter must be greater than Inside Diameter."};
    } else if (outerDiameter <= 0) {
      return {error: "Value must be greater than 0"};
    }
    return null;
  }
