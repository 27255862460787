<form [formGroup]="form.formGroup" class="w-full space-y-2">

  <div class="w-full">
    <label>Type</label>
    <p-select
      class="w-full"
      [options]="loadTypes"
      [formControlName]="form.formControlNames.type"
      optionLabel="name"
      optionValue="value"
      placeholder="Select Load Type"
      [autoDisplayFirst]="false"
    ></p-select>
  </div>

  <div [ngSwitch]="form.formGroup.value.type" class="flex-col">
      <app-run-in-hole-details
        *ngSwitchCase="LoadType.RUNINHOLE"
        [formControlName]="form.formControlNames.runInHole"
        [userUnits]="userUnits"
        [shoeMd]="shoeMd.toFixed(2)"
        [hangerMd]="hangerMd"
        [annularFLuidDensity]="annularFLuidDensity.toFixed(2)"
      ></app-run-in-hole-details>
      <app-overpull-details
        *ngSwitchCase="LoadType.OVERPULL"
        [formControlName]="form.formControlNames.overpull"
        [annularFLuidDensity]="annularFLuidDensity.toFixed(2)"
        [userUnits]="userUnits"
        [shoeMd]="shoeMd.toFixed(2)"
        [hangerMd]="hangerMd"
      ></app-overpull-details>
      <app-green-cement-test-details
        *ngSwitchCase="LoadType.GREENCEMENTTEST"
        [formControlName]="form.formControlNames.greenCementTest"
        [userUnits]="userUnits"
      ></app-green-cement-test-details>
  </div>

</form>