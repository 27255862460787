import { Component, Input } from "@angular/core";

@Component({
    selector: 'app-allowed-range',
    styles: [],
    template: `
    <p *ngIf="invalid" class="validation-error">
        Allowed range: {{from}} - {{to}}
    </p>
    `,
    standalone: false
})
  export class AllowedRangeComponent {
    
    @Input()
    invalid : boolean;

    @Input()
    from: number;
    
    @Input()
    to: number;

  }