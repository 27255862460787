<form [formGroup]="form.formGroup" class="flex flex-col space-y-2">

  <app-uom-input
    label="Surface Pressure"
    [unitLabel]="userUnits.pressure"
    [formControlName]="form.formControlNames.surfacePressure"
    [control]="'surfacePressure'"
    [validators]="validators.surfacePressure"
    [default]="'0'"
    title="Allowed range: {{ pressureValidation.min }} - {{ pressureValidation.max }} {{ pressureLabel }}"
  />

  <app-uom-input
    label="Fluid Density"
    [unitLabel]="densityLabel"
    [formControlName]="form.formControlNames.fluidDensity"
    [control]="'fluidDensity'"
    [validators]="validators.fluidDensity"
    [default]="currentString.annularFluid?.state.nominalDensity"
    title="Allowed range: {{ densityValidation.min }} - {{ densityValidation.max }} {{ densityLabel }}"
  />

  <div class="w-full">
    <p-checkbox
      size="small"
      binary="true"
      [formControlName]="form.formControlNames.hasPlug"
      (change)="pressureTestPlugChange()"
    />
    <label class="pl-2">Has Plug</label>
  </div>

  <app-uom-input
    label="Plug Depth"
    [unitLabel]="userUnits.longLengths + ' MD'"
    [formControlName]="form.formControlNames.plugMd"
    [control]="'plugMd'"
    [validators]="validators.plugMd"
    [default]="null"
    [inputDisabled]="plugDepthDisabled"
  />
</form>
