<div class="ng-table-cmp" [formGroup]="dataRowsForm">
  <p-confirmDialog
    header="Confirmation"
    icon="pi pi-exclamation-triangle"
  ></p-confirmDialog>

  <p-contextMenu #cm [model]="contextMenuItems" appendTo="body"></p-contextMenu>
  <ng-container formArrayName="dataArray">
    <p-table
      #dataTable
      className="p-datatable-sm"
      [columns]="columnDefinitions"
      [value]="pTableData"
      [exportFilename]="tableName"
      [exportFunction]="exportFunction"
      [(contextMenuSelection)]="selectedRowIdx"
      [contextMenu]="cm"
      [scrollable]="true"
      [scrollHeight]="scrollHeight"
      [virtualScroll]="virtualScroll"
      [virtualScrollItemSize]="28"
      minBufferPx="800"
      maxBufferPx="1800"
      stateStorage="local"
      showGridlines
      stripedRows
      [stateKey]="componentId"
    >
      <ng-template pTemplate="header" let-columns>
        <!-- <tr title="Right click in cell and choose copy from browser context menu or use ctrl + v."></tr> -->
        <tr *ngIf="tableHeader">
          <th class="row-index"></th>
          <th [attr.colspan]='columnDefinitions.length + 1'>
            <span>{{ tableHeader }}</span>
          </th>
        </tr>

        <tr>
          <th class="row-index">
            <p-button
              type="button"
              size="small"
              title="Download CSV"
              icon="pi pi-download"
              (click)="dataTable.exportFunction()"
            ></p-button>
          </th>
          <th *ngFor="let col of columns" class="align-content-center">
            {{ col.header }}
          </th>
          <th class="td-btn-col">
            <p-button
              class="outlined-button"
              label="Reset"
              size="small"
              severity="danger"
              [outlined]="true"
              (click)="clearTable()"
              title="Clears all form data and resets table"
            ></p-button>
          </th>
        </tr>
      </ng-template>

      <ng-template
        pTemplate="body"
        let-rowData
        let-rIdx="rowIndex"
        let-columns="columns"
      >
        <ng-container [formGroupName]="rIdx">
          <tr style="height: 28px">
            <td class="row-index" *ngIf="rIdx == 0">
              {{ rIdx + 1 }}
            </td>
            <td class="row-index" *ngIf="rIdx > 0" [pContextMenuRow]="rIdx">
              {{ rIdx + 1 }}
            </td>

            <td
              [class.firstRow]="
                rIdx === 0 &&
                (tableName === 'Trajectory' || tableName === 'Pore Pressure' || tableName === 'Fracture Gradient')
              "
              *ngFor="let inputField of inputFields"
              pEditableColumn
              [pEditableColumnField]="inputField.name"
              [pEditableColumnRowIndex]="rIdx"
              [pEditableColumn]="rowData"
            >
              <p-cellEditor>
                <ng-template pTemplate="input">
                  <div class="flex items-center space-x-2">
                    <p-inputNumber
                      mode="decimal"
                      [formControlName]="inputField.name"
                      [minFractionDigits]="inputField.minFractions"
                      [maxFractionDigits]="inputField.maxFractions"
                      (paste)="onPaste($event, rIdx)"
                      (onFocus)="$event.target.select()"
                      (onBlur)="onCellEdit($event, rIdx, inputField.name)"
                      class="flex-shrink-0 w-full"
                    ></p-inputNumber>
                    <app-validation-error
                      class="validation-error"
                      [isValid]="isControlInvalid(rowData, inputField.name)"
                      [errorMsg]="getControlErrors(rowData, inputField.name)"
                    />
                  </div>
                </ng-template>
                <ng-template pTemplate="output">
                  <div class="text-left pr-1">
                    {{
                      rowData
                      .get(inputField.name)
                      ?.value?.toLocaleString("en-US", {
                        maximumFractionDigits: inputField.formatDecimals
                      })
                    }}
                  </div>
                  <app-validation-error
                    *ngIf="rowData"
                    [isValid]="isControlInvalid(rowData, inputField.name)"
                    [errorMsg]="getControlErrors(rowData, inputField.name)"
                  />
                </ng-template>
              </p-cellEditor>
            </td>

            <td
              *ngFor="let calcField of calculatedFields"
              class="calculated-row"
            >
              {{
                getDictValue(rowData)[calcField.name]?.toLocaleString("en-US", {
                  maximumFractionDigits: calcField.formatDecimals
                })
              }}
            </td>

            <td class="td-btn-col">
              <i
              title="Delete"
              class="pi pi-trash !text-red-400"
              [ngClass]="{ 'opacity-50': rIdx === 0 }"
              (click)="rIdx !== 0 && onDelete(rIdx)"
              tabindex="-1"
            ></i>
            
            </td>
          </tr>
        </ng-container>

        <ng-container [formGroup]="newDataRowForm">
          <tr
            style="height: 28px"
            *ngIf="rIdx == dataFormArray.length - 1"
            focusedRow
          >
            <td class="row-index">{{ rIdx + 2 }}</td>
            <ng-container *ngFor="let inf of inputFields; let i = index">
              <td>
                <p-inputNumber
                  #inputFocusFieldRef
                  class="w-full"
                  mode="decimal"
                  [minFractionDigits]="inf.minFractions"
                  [maxFractionDigits]="inf.maxFractions"
                  [formControlName]="inf.name"
                  (onBlur)="addRow()"
                  (paste)="onPaste($event, rIdx + 1)"
                  (keydown.enter)="$event.target.blur()"
                  (onFocus)="$event.target.select()"
                ></p-inputNumber>
              </td>
            </ng-container>
            <td class="calculated-row"
              *ngFor="let calcField of calculatedFields"
              [attr.colspan]="columnDefinitions?.length - inputFields.length + 1"
            ></td>
            <td class="td-btn-col"></td>
          </tr>
        </ng-container>
      </ng-template>

      <ng-template pTemplate="footer">
        <tr *ngIf="calculating">
          <td [attr.colspan]="columnDefinitions.length + 2" class="text-center">
            Calculating {{ tableName }}...
          </td>
        </tr>
      </ng-template>

    </p-table>
  </ng-container>
</div>
