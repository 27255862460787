  <div class="p-2">
    <form *ngIf="wellheadSlumpForm" [formGroup]="wellheadSlumpForm">
      
      <div class="flex items-center mb-2">
        <p-checkbox size="small" id="disp-post-inst-chk" formControlName="applyDisplacementToPostInstallLoads" binary="true" />
        <label for="disp-post-inst-chk" class="ml-2"> Apply displacement to Post-Install loads</label>
      </div>
      
      <div class="flex flex-col mb-2">
        <label for="surfaceAmbientTemp">Wellhead Slump
          <i title="Upward Movement (+)/Downward Movement (-)" class="pi pi-info-circle tooltip-icon pl-1"></i>
        </label>
      <p-inputgroup>
        <p-inputNumber [minFractionDigits]="1" formControlName="wellheadSlump" placeholder="0"></p-inputNumber>
        <p-inputgroup-addon>{{ units.short }}</p-inputgroup-addon>
      </p-inputgroup>
      <p *ngIf="wellheadSlumpForm.controls.wellheadSlump.invalid" class="validation-error">
        Allowed range: {{ this.slumpValidation.min }} - {{ this.slumpValidation.max }}
      </p>
    </div>
    
  </form>
</div>