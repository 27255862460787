import { Component, Input, OnChanges } from '@angular/core';
import { Store } from '@ngneat/elf';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { LinearInterpSingleVal } from 'src/app/perivis/shared/helpers/linear-interp-single.helper';
import { UndisturbedTemperaturePlot } from 'src/app/wellbore-inputs/models/undisturbed-temperature.model';

@Component({
    selector: 'app-post-install-loads-plot',
    template: `
    <app-xy-line-plot
      [plotData]="plot.data"
      [xAxisTitle]="xAxisTitle"
      [yAxisTitle]="yAxisTitle"
      [plotName]="plotName"
      [downloadPlotName]="downloadPlotName"
      [plotTitle]="'Post-Install Load Cases'"
      [componentHeight]="componentHeight"
      [xyLinePlotStore]="postInstallLoadsStore"
    ></app-xy-line-plot>
  `,
    standalone: false
})
export class PostInstallLoadsPlotComponent implements OnChanges {
  @Input() plotData: any;
  @Input() udt: UndisturbedTemperaturePlot;
  @Input() pressTempSelected: string;
  @Input() hangerTvd: number;
  @Input() shoeDepthTvd: number;
  @Input() userUnits: UserUnitsModel;
  @Input() stringName: string;
  @Input() componentHeight: number;
  @Input() postInstallLoadsStore: Store;
  @Input() plotName: string;

  public plot = {
    data: [],
    layout: {},
    config: {}
  };

  public xAxisTitle = '';
  public yAxisTitle = '';
  public downloadPlotName = 'load_case_plot';

  ngOnChanges() {
    if (this.plotData) { this.plotPressures(this.plotData, this.udt, this.pressTempSelected) };
  }

  async plotPressures(plotData: any, udt: any, pressTempSelected: string) {
    this.yAxisTitle = `TVD (${this.userUnits?.longLengths})`;
    this.xAxisTitle = pressTempSelected === 'temp' ? `Temperature (${this.userUnits?.temperature})` : `Pressure (${this.userUnits?.pressure})`;

    this.plot.data = [];

    if (!plotData || plotData === null) {
      // No data
      const defaultPlot = {
        x: 0,
        y: 0
      }

      this.plot.data.push(defaultPlot);
    } else {
      // IPP
      const intPress = {
        name: "Internal Pressure Profile",
        x: plotData.internalPressurePoints?.map(p => Math.trunc(p.pressure * 100) / 100),
        y: plotData.internalPressurePoints?.map(p => Math.trunc(p.trueVerticalDepth * 100) / 100)
      }

      // EPP
      const extPress = {
        name: "External Pressure Profile",
        x: plotData.externalPressurePoints?.map(p => Math.trunc(p.pressure * 100) / 100),
        y: plotData.externalPressurePoints?.map(p => Math.trunc(p.trueVerticalDepth * 100) / 100)
      }

      // Temp
      const temp = {
        name: 'Temperature',
        x: plotData.temperaturePoints?.map(p => Math.trunc(p.temperature * 100) / 100),
        y: plotData.temperaturePoints?.map(p => Math.trunc(p.trueVerticalDepth * 100) / 100),
        line: {
          color: '#FF0000'
        }
      }

      if (pressTempSelected === 'press') {
        this.plot.data.push(intPress);
        this.plot.data.push(extPress);
      } else {
        this.plot.data.push(temp);
      }

      // UDT
      if (udt && pressTempSelected === 'temp') {
        const deepestPoint = Math.max(...temp.y, this.shoeDepthTvd);

        const interpolatedX = LinearInterpSingleVal(deepestPoint, udt.y, udt.x);
        udt.x.push(interpolatedX);
        udt.y.push(deepestPoint);

        const deepestShoeIndex = udt.y.findIndex(y => y > deepestPoint);
        udt.y.splice(deepestShoeIndex, 1);
        udt.x.splice(deepestShoeIndex, 1);

        const udtPlot = {
          name: 'UDT',
          x: udt.x,
          y: udt.y,
          mode: 'lines+markers',
          line: {
            dash: 'dot',
            color: '#FF0000'
          }
        }

        this.plot.data.push(udtPlot);
      }

      // Casing
      const largestPointInt = Math.max(...extPress.x);
      const largestPointExt = Math.max(...intPress.x);
      const largestUdt = udt?.x ? Math.max(...udt.x) : 0;

      let largestValue = Math.max(largestPointExt, largestPointInt) + 300;

      if (pressTempSelected === 'temp') {
        largestValue = Math.max(...temp.x, largestUdt) + 50;
      }

      const casing = {
        name: this.stringName,
        x: [largestValue, largestValue],
        y: [this.hangerTvd, this.shoeDepthTvd],
        marker: {
          size: 10,
          symbol: ['line-ew', "triangle-up-dot", "line-ew", "line-ew", "triangle-up-dot", "line-ew"]
        },
        line: {
          color: '#999999'
        },
        hoverinfo: 'none'
      }

      this.plot.data.push(casing);
    }
  }
}
