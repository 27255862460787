<div class="sidebar-cmp">
  <div class="sidebar-menu">
    <div class="dock">
      <p-dock position="left" [model]="navbarItems">
        <ng-template pTemplate="item" let-item let-i="index">
          <a [pTooltip]="item.label" tooltipPosition="top" class="p-dock-item-link flex flex-col items-center">
            <i class="dock-ico" [ngClass]="item.iconClass"></i>
            <span class="text-[10px]">{{ item.label }}</span>
          </a>
        </ng-template>
      </p-dock>
    </div>
    <p-button *ngIf="menuSelected" size="small" text icon="pi pi-angle-double-left" (onClick)="handleClose()">
    </p-button>
  </div>

  <div class="{{ menuSelected === '' ? '' : menuSelected === 'wellExplorer' ? 'sidebar-sub-menu-well-explorer' : 'sidebar-sub-menu' }}">
    <app-well-explorer [hidden]="!(this.menuSelected === 'wellExplorer')" (closed)="handleClose()" [onScrollToNode]="scrollToNode" />

    <app-sidebar-menu-group [hidden]="!(menuSelected === 'wellboreInputs')" suffixLabel="FOR" labelColor="Red"
      [menuItems]="wellboreInputsSubMenu" (closed)="handleClose()" />

    <app-sidebar-menu-group [hidden]="!(menuSelected === 'thermal')" suffixLabel="CAL" [menuItems]="thermalSubMenu"
      labelColor="#FF5722" (closed)="handleClose()"
    />

    <app-sidebar-menu-group [hidden]="!(menuSelected === 'apb')" suffixLabel="NIX" labelColor="#4CAF50"
      [menuItems]="apbSubMenu" (closed)="handleClose()"
    />

    <app-sidebar-menu-group [hidden]="!(menuSelected === 'stress')" suffixLabel="VIS" [menuItems]="stressSubMenu"
      labelColor="#00e5ff" (closed)="handleClose()"
    />

    <app-sidebar-menu-group [hidden]="!(menuSelected === 'database')" solidColorLabel="Catalogs"
      [menuItems]="catalogSubMenu" (closed)="handleClose()"
    />
  </div>
</div>