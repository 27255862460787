import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { WellType } from 'src/app/shared/models/wellType.model';
import { catchError, map, shareReplay } from 'rxjs/operators';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`;

@Injectable({
    providedIn: 'root'
})
export class WellTypeService {
  private wellTypeCache$: Record<string, Observable<WellType>> = {};

  constructor(private _http: HttpClient) { }

  public getWellType(skipUnits = false): Observable<WellType> {
    const cacheKey = skipUnits.toString();
    if (!this.wellTypeCache$[cacheKey]) {
      this.wellTypeCache$[cacheKey] = this._http
        .get<WellType>(`${PeriforApiEndpoint}/wellType?skipUnits=${skipUnits}`)
        .pipe(
          map(data => new WellType(data)),
          catchError(() => of(null as WellType)),
          shareReplay(1)
        );
    }
    setTimeout(() => this.wellTypeCache$[cacheKey] = null, 1000);
    return this.wellTypeCache$[cacheKey];
  }

  updateWellType(wellType: WellType) {
    return this._http.put(`${PeriforApiEndpoint}/wellType`, wellType);
  }
}
