<app-tool-loader [isLoading]="isLoading" positionUnset="true">
  <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
    <p-dialog [draggable]="false" [(visible)]="configMissing" [closable]="false">
      <i style="font-size: 10px" class="pi pi-exclamation-circle"></i> Well
      Configuration Missing: Please specify at least one string <br />
      <br />
    </p-dialog>

    <form *ngIf="cementingLandingForm" [formGroup]="cementingLandingForm" [hidden]="isLoading">
      <div class="flex flex-wrap gap-2 m-2">

        <div class="flex-1">
          <p-fieldset legend="Cementing Inputs">
            <div class="mb-2">
              <p-fieldset legend="Lead Slurry">
                <label for="leadSlurryDensity">Slurry Density</label>
                <p-inputgroup>
                  <p-inputNumber [minFractionDigits]="1" id="leadSlurryDensity" formControlName="leadSlurryDensity" onfocus="this.select()"
                    [attr.disabled]="false" />
                  <p-inputgroup-addon>{{ densityUnits }}</p-inputgroup-addon>
                </p-inputgroup>
                <p *ngIf="cementingLandingForm.controls.leadSlurryDensity.invalid" class="validation-error mt-1">
                  Allowed: {{ slurryDensityValidation.min }} -
                  {{ slurryDensityValidation.max }} {{ densityUnits }}
                </p>
              </p-fieldset>
            </div>

            <p-fieldset>
              <ng-template pTemplate="header">
                <div class="flex align-items-center">
                  <p-toggleswitch (onChange)="onTailChange($event)" formControlName="hasTailCement" id="hasTailCement"
                    binary="true">
                  </p-toggleswitch>
                  <label class="ml-2"> Tail Slurry</label>
                </div>
              </ng-template>

              <div class="space-y-2">
                <div class="flex flex-col">
                  <label for="tailSlurryDensity">Slurry Density</label>
                  <p-inputgroup>
                    <p-inputNumber [minFractionDigits]="1" id="tailSlurryDensity" formControlName="tailSlurryDensity" onfocus="this.select()" />
                    <p-inputgroup-addon>{{ densityUnits }}</p-inputgroup-addon>
                  </p-inputgroup>
                  <p *ngIf="cementingLandingForm.controls.tailSlurryDensity.invalid" class="validation-error mt-1">
                    Allowed: {{ slurryDensityValidation.min }} - {{ slurryDensityValidation.max }} {{ densityUnits }}
                  </p>
                </div>

                <div class="flex flex-col">
                  <label for="tailSlurryLength">Slurry Length</label>
                  <p-inputgroup>
                    <p-inputNumber [minFractionDigits]="1" id="tailSlurryLength" formControlName="tailSlurryLength"
                      (change)="changeHasTailSlurry($event)" onfocus="this.select()" />
                    <p-inputgroup-addon>{{slurryLengthUnit}}</p-inputgroup-addon>
                  </p-inputgroup>
                  <p *ngIf="cementingLandingForm.controls.tailSlurryLength.invalid" class="validation-error mt-1">
                    Allowed: 0 - {{ maxTailSlurryLength }} {{ slurryLengthUnit }}
                  </p>
                </div>
              </div>
            </p-fieldset>

            <div class="pt-2 space-y-2">
              <div class="flex flex-col">
                <label for="displacementFluidDensity">Displacement Fluid</label>
                <p-inputgroup>
                  <p-inputNumber [minFractionDigits]="1" id="displacementFluidDensity" formControlName="displacementFluidDensity"
                    onfocus="this.select()" />
                  <p-inputgroup-addon>{{ densityUnits }}</p-inputgroup-addon>
                </p-inputgroup>
                <p *ngIf="cementingLandingForm.controls.displacementFluidDensity.invalid"
                  class="validation-error mt-1">
                  Allowed: {{ displacementDensityValidation.min }} - {{ displacementDensityValidation.max }} {{
                  densityUnits }}
                </p>
              </div>

              <div class="flex flex-col">
                <label for="hardCement">Hard Cement</label>
                <p-select class="w-full" [title]="hardCementJson" [options]="_hardCement" formControlName="cement"
                  [placeholder]="getPlaceholderText(cementingLandingForm.controls.cement.value)" appendTo="body">
                  <ng-template pTemplate="selectedItem">
                    {{ getPlaceholderText(cementingLandingForm.controls.cement) }}
                  </ng-template>
                  <ng-template let-cement pTemplate="item">
                    {{ cement.name }}
                  </ng-template>
                </p-select>
              </div>
            </div>
          </p-fieldset>
        </div>

        <div class="flex-1">
          <p-fieldset legend="Landing Details">

            <div class="space-y-2">
              <div class="flex flex-col">
                <label for="pickupAfterCementSet">Pickup/Slackoff On TOC
                  <i title="Pickup (+)/Slackoff (-)" class="pi pi-info-circle"></i>
                </label>
                <p-inputgroup>
                  <p-inputNumber [minFractionDigits]="1" id="pickupAfterCementSet" formControlName="pickupAfterCementSet"
                    onfocus="this.select()"></p-inputNumber>
                  <p-inputgroup-addon>{{ forceUnits }}</p-inputgroup-addon>
                </p-inputgroup>
              </div>

              <div class="flex flex-col">
                <label for="slackoffForceOnBottom">Slackoff Force On Bottom
                  <i title="Pickup (-)/Slackoff (+) &#13;This option can be used to approximate known friction loads encountered during tripping the pipe into the hole, &#13;or slackoff prior to cementing with the pipe fully landed out in the bottom of the as-drilled borehole."
                    class="pi pi-info-circle">
                  </i>
                </label>
                <p-inputgroup>
                  <p-inputNumber [minFractionDigits]="1" formControlName="slackoffForceOnBottom" id="slackoffForceOnBottom"
                    onfocus="this.select()" />
                  <p-inputgroup-addon>{{ forceUnits }}</p-inputgroup-addon>
                </p-inputgroup>
              </div>
            </div>

          </p-fieldset>
        </div>

      </div>

    </form>
  </p-scrollPanel>
</app-tool-loader>