import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";
import { PoreFrac } from "../../models/poreFrac.model";

export function porePressureMustBeToShoeValidator(porePressures: PoreFrac[], shoeDepth: number): ValidatorFn {

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return (control: AbstractControl): ValidationErrors | null => {
    const porePressureLastPoint: number = porePressures.length > 0 ? porePressures[porePressures.length - 1].trueVerticalDepth : 0;
    const porePressureDepth: boolean = porePressureLastPoint < shoeDepth;
    return porePressureDepth ? { porePressureMustBeToShoe: true } : null;
  }
}

export function porePressureNotSpecifiedValidator(porePressures: PoreFrac[]): ValidatorFn {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  return (control: AbstractControl) : ValidationErrors | null => {
    return porePressures.length < 2 ? { porePressureNotSpecified: true} : null;
  }
}
