<app-tool-loader [isLoading]="isLoading" positionUnset="true">
  <div class="p-1 h-full">
    <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
    <p-dialog modal="true" [(visible)]="calculating" [draggable]="false">
      <i class="pi pi-exclamation-circle"></i> Please wait while Reservoir Performance is being calculated <br />
      <br />
    </p-dialog>

    <form *ngIf="reservoirPerformanceForm && userUnits" [formGroup]="reservoirPerformanceForm" class="p-2">
      <div class="flex gap-4">

        <div class="flex-1">
        <p-fieldset legend="Input Configuration">
          <div class="flex flex-col space-y-2 mb-2">

            <div class="flex flex-col">
              <label for="type">Operation Type</label>
              <p-select 
                [options]="operationTypes" formControlName="operationType" optionLabel="name" appendTo="body"
                optionValue="value" (onChange)="selectedOperationChange($event)" [autoDisplayFirst]="false"
                placeholder="Select Operation Type">
              </p-select>
            </div>

            <div class="flex flex-col">
              <label>Fluid</label>
              <p-select [options]="fluids" optionLabel="state.name" appendTo="body"
                (onChange)="selectedFluidChange($event)"></p-select>
            </div>

            <div class="flex flex-col">
              <label>Gas Flow Rate</label>
              <p-inputgroup>
                <p-inputNumber [minFractionDigits]="1" formControlName="gasFlowRate"></p-inputNumber>
                <p-inputgroup-addon>{{ flowRateUnit }}</p-inputgroup-addon>
              </p-inputgroup>
              <p *ngIf="
                        reservoirPerformanceForm?.controls.gasFlowRate.value &&
                        reservoirPerformanceForm?.controls.gasFlowRate.invalid
                      " class="validation-error">
                Allowed range: {{ gasFlowRateValidation.min }} - {{ gasFlowRateValidation.max }}
              </p>
            </div>

            <div *ngIf="selectedOperationType === 'injection'" class="flex flex-col">
              <label>Bottomhole Temperature</label>
              <p-inputGroup>
                <p-inputNumber [minFractionDigits]="1" formControlName="bottomholeTemperature"></p-inputNumber>
                <p-inputgroup-addon>{{ tempUnit }}</p-inputgroup-addon>
              </p-inputGroup>
              <p *ngIf="
                        reservoirPerformanceForm?.controls.bottomholeTemperaturevalue &&
                        reservoirPerformanceForm?.controls.bottomholeTemperatureinvalid
                      " class="validation-error">
                Allowed range: {{ temperatureValidation.min }} - {{ temperatureValidation.max }}
              </p>
            </div>

            <div class="flex flex-col">
              <label>Bottomhole Diameter</label>
              <p-inputgroup>
                <p-inputNumber [minFractionDigits]="1" formControlName="bottomholeDiameter"></p-inputNumber>
                <p-inputgroup-addon>{{ userUnits?.shortLengths }}</p-inputgroup-addon>
              </p-inputgroup>
            </div>

          <p-fieldset legend="Reservoir Fluid PVT Conditions">
          
            <div class="flex flex-col">
              <div class="flex items-center space-x-2">
                <input
                  type="radio"
                  binary="true"
                  (click)="pvtConditionsChange('bottomHole')"
                  [checked]="pvtCondition === 'bottomHole'"
                >
                <label>Flowing Bottom-Hole</label>
              </div>
              <div class="flex items-center space-x-2">
                <input
                  type="radio"
                  binary="true"
                  (click)="pvtConditionsChange('farField')"
                  [checked]="pvtCondition === 'farField'"
                >
                <label>Far-field Reservoir</label>
              </div>
              <div class="flex items-center space-x-2">
                <input
                  type="radio"
                  binary="true"
                  (click)="pvtConditionsChange('average')"
                  [checked]="pvtCondition === 'average'"
                >
                <label>Average Reservoir Conditions</label>
              </div>
            </div>

          </p-fieldset>

          <div class="flex flex-col">
            <label>Perforation</label>
            <p-select formControlName="perf" [options]="perforations" optionLabel="name" appendTo="body"
            (onChange)="onPerforationsChange($event)" appendTo="body"></p-select>
          </div>        

          <div class="flex flex-col">
            <label>Reservoir Temperature</label>
            <p-inputgroup>
              <p-inputNumber [minFractionDigits]="1" formControlName="reservoirTemperature"></p-inputNumber>
              <p-inputgroup-addon>{{ tempUnit }}</p-inputgroup-addon>
            </p-inputgroup>
            <p *ngIf="
                      reservoirPerformanceForm?.controls.bottomholeTemperaturevalue &&
                      reservoirPerformanceForm?.controls.bottomholeTemperatureinvalid
                    " class="validation-error">
              Allowed range: {{ temperatureValidation.min }} - {{ temperatureValidation.max }}
            </p>
          </div>
          
          <div class="flex flex-col mt-2">
            <label for="type">Reservoir</label>
            <p-select
              formControlName="reservoir"
              [options]="reservoirs"
              optionLabel="name"
              appendTo="body"
              (onChange)="selectedReservoirChange($event)"
            ></p-select>
          </div>

        </div>
        </p-fieldset>
      </div>

      <div class="flex-1">
        <p-fieldset legend="Performance Output">
          <div class="flex flex-col space-y-2">

            <div class="flex flex-col">
              <label>Bottomhole Pressure</label>
              <p-inputgroup disabled="true">
                <p-inputNumber [minFractionDigits]="1" [disabled]="true"
                  placeholder="{{ reservoirPerformanceResult?.bottomholePressure }}"></p-inputNumber>
                <p-inputgroup-addon>{{ userUnits?.pressure }}</p-inputgroup-addon>
              </p-inputgroup>
            </div>

            <div class="flex flex-col">
              <label>Performance Index (J)</label>
              <p-inputgroup disabled="true">
                <p-inputNumber [minFractionDigits]="2" [disabled]="true"
                  placeholder="{{ reservoirPerformanceResult?.performanceIndex }}"></p-inputNumber>
                <p-inputgroup-addon>ft³/hr/psi</p-inputgroup-addon>
              </p-inputgroup>
            </div>

            <div class="flex flex-col">
              <label class="mr-2">Effective Mass Flow Rate
                <i title="Production (+) / Injection (-)" class="pi pi-info-circle tooltip-icon"></i>
              </label>
              <p-inputgroup>
                <p-inputNumber [minFractionDigits]="1" [disabled]="true"
                  placeholder="{{ reservoirPerformanceResult?.effectiveMassFlowRate }}"></p-inputNumber>
                <p-inputgroup-addon>{{ massFlowUnit }}</p-inputgroup-addon>
              </p-inputgroup>
            </div>

            <div class="flex flex-col">
              <label>Reservoir Phase</label>
              <p-inputNumber [minFractionDigits]="1" [disabled]="true"
                placeholder="{{ reservoirPerformanceResult?.reservoirPhase }}"></p-inputNumber>
            </div>

            <div class="flex flex-col">
              <label>Reservoir Vapor Quality</label>
              <p-inputNumber [minFractionDigits]="1" [disabled]="true"
                placeholder="{{ reservoirPerformanceResult?.reservoirVaporQuality }}"></p-inputNumber>
            </div>
          </div>
        </p-fieldset>

        <div class="flex justify-center mt-5">
          <p-button class="outlined-button" [disabled]="reservoirPerformanceForm?.invalid" size="small" severity="success"
            outlined="true" label="Calculate" (onClick)="calculateReservoirPerformance()">
          </p-button>
        </div>

      </div>

      </div>
    </form>
  </p-scrollPanel>
  </div>
</app-tool-loader>