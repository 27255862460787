<form [formGroup]="form.formGroup">
  <div class="flex gap-2 flex-row m-2">
    <p-fieldset legend="Lower Stage (Bottom Plug)" class="w-1/2">
      <div class="grid gap-1">
        <app-uom-input
          label="Surface Pressure"
          [unitLabel]="pressureLabel"
          [formControlName]="form.formControlNames.lowerStageSurfacePressure"
          [control]="'lowerStageSurfacePressure'"
          [default]="'0'"
          [validators]="validators.lowerStageSurfacePressure"
          title="Allowed range: {{ pressureValidation.min }} - {{ pressureValidation.max }} {{ pressureLabel }}"
        />

        <app-uom-input
          label="Fluid Density"
          [unitLabel]="densityLabel"
          [formControlName]="form.formControlNames.lowerStageFluidDensity"
          [control]="'lowerStageFluidDensity'"
          [default]="currentString.annularFluid.state?.nominalDensity"
          [validators]="validators.lowerStageFluidDensity"
          title="Allowed range: {{ densityValidation.min }} - {{ densityValidation.max }} {{ densityLabel }}"
        />

        <div>
          <p-checkbox
            size="small"
            binary="true"
            [formControlName]="form.formControlNames.lowerStageHasPlug"
            (change)="lowerStagePlugChange()"
          />
          <label class="ml-2">Has Plug</label>
        </div>

        <app-uom-input
          label="Plug Depth"
          [unitLabel]="userUnits.longLengths + ' MD'"
          [formControlName]="form.formControlNames.lowerStagePlugMd"
          [control]="'lowerStagePlugMd'"
          [default]="null"
          [validators]="validators.lowerStagePlugMd"
          [inputDisabled]="lowerStagePlugDepthDisabled"
        />
      </div>
    </p-fieldset>

    <p-fieldset legend="Upper Stage (Top Plug)" class="w-1/2">
      <div class="grid gap-1">
        <div>
          <p-checkbox
            binary="true"
            [formControlName]="form.formControlNames.upperStageEvacAbovePlug"
            (change)="upperStagePlugChange()"
          />
          <label class="ml-2">Evacuated above plug</label>
        </div>

        <app-uom-input
          label="Surface Pressure"
          [unitLabel]="userUnits.pressure"
          [formControlName]="form.formControlNames.upperStageSurfacePressure"
          [control]="'upperStageSurfacePressure'"
          [default]="'0'"
          [validators]="validators.upperStageSurfacePressure"
          [inputDisabled]="upperStageEvacAbovePlug"
          title="Allowed range: {{ pressureValidation.min }} - {{ pressureValidation.max }} {{ pressureLabel }}"
        />

        <app-uom-input
          label="Fluid Density"
          [unitLabel]="densityLabel"
          [formControlName]="form.formControlNames.upperStageFluidDensity"
          [control]="'upperStageFluidDensity'"
          [default]="currentString.annularFluid.state?.nominalDensity"
          [validators]="validators.upperStageFluidDensity"
          [inputDisabled]="upperStageEvacAbovePlug"
          title="Allowed range: {{ densityValidation.min }} - {{ densityValidation.max }} {{ densityLabel }}"
        />

        <app-uom-input
          label="Plug/Valve Depth"
          [unitLabel]="userUnits.longLengths + ' MD'"
          [formControlName]="form.formControlNames.upperStagePlugMd"
          [control]="'upperStagePlugMd'"
          [default]="currentString.hangerMd + 100"
          [validators]="validators.upperStagePlugMd"
        />
      </div>
    </p-fieldset>
  </div>

</form>
