<div class="p-1">
  <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

  <p class="text-center justify-content-center">
    <label class="stringLabel">{{ currentStringName }}</label>
  </p>
  <hr>

  <app-tool-loader [isLoading]="isLoading" positionUnset="true">
    <p-dialog [draggable]="false" [visible]="isDialogVisible" [closable]="false">
      <i class="pi pi-exclamation-circle"></i> {{ disabledMessage }} <br />
      <br />
    </p-dialog>

    <form [formGroup]="annulusContentsForm" novalidate>

      <div class="flex flex-col pt-2">
        <label>Hanger Pressure</label>
        <p-input-group>
          <p-inputNumber [minFractionDigits]="1" formControlName="hangerPressure"></p-inputNumber>
          <p-inputgroup-addon>{{ pressureUnit }}</p-inputgroup-addon>
        </p-input-group>
        <p *ngIf="annulusContentsForm?.controls.hangerPressure.value && annulusContentsForm?.controls.hangerPressure.invalid"
          class="validation-error">
          Allowed range: {{ pressureValidation.min }} - {{ pressureValidation.max }}
        </p>
      </div>

      <p class="text-sm mb-5 mt-5"> Multiple annular fluid layers may be selected for the post-completion state of
        the wellbore:
      </p>

      <div class="grid">
          <p-contextMenu #cm [model]="contextMenuItems" appendTo="body"></p-contextMenu>
          <ng-container formArrayName="annulusContents">
            <p-table [ariaDisabled]="stringFullyCemented" [scrollable]="true" [scrollHeight]="tableHeight"
              styleClass="p-datatable-gridlines" [columns]="columnDefs" [resizableColumns]="true"
              [value]="annulusContents?.controls" [(contextMenuSelection)]="selectedRowIdx" 
              dataKey="value.id"
              [contextMenu]="cm">
              <ng-template pTemplate="header" let-columns let-wIdx="rowIndex">
                <tr (mouseover)="getRowIndex(wIdx)">
                  <th *ngFor="let col of columns" pResizableColumn> {{ col.header }} </th>
                  <th class="place-items-center">
                    <p-button
                      class="outlined-button"
                      severity="success"
                      size="small"
                      [disabled]="annulusContents?.invalid || stringFullyCemented" 
                      label="Add"
                      outlined="true"
                      (click)="onAddAnnulusContent()">
                    </p-button>
                  </th>
                </tr>
              </ng-template>

              <ng-template pTemplate="body" let-annulusSectionFg let-rIdx="rowIndex">
                <ng-container [formGroupName]="rIdx">
                  <tr>
                    <td [pContextMenuRow]="rIdx" class="text-center" style="width: 15%;">
                        <p-inputNumber formControlName="annulusSectionBottom" [minFractionDigits]="1"
                          title="Section Bottom"></p-inputNumber>
                        <app-validation-error [isValid]="isControlInvalid(annulusSectionFg, 'annulusSectionBottom')"
                          [errorMsg]=" getControlErrors(annulusSectionFg, 'annulusSectionBottom')"/>
                    </td>
                    <td [pContextMenuRow]="rIdx" style="width: 40%;" class="text-center">
                      <p-select  class="w-full" [disabled]="userRoles.readOnly" placeholder="Select" [options]="fluids"
                        formControlName="annulusSectionContent" appendTo="body">
                      </p-select>
                    </td>
                    <td [pContextMenuRow]="rIdx" class="text-center" style="width: 15%;">
                      <p-checkbox size="small" title="Include Natural Convection" 
                        binary="true" formControlName="naturalConvection"/>
                    </td>
                    <td [pContextMenuRow]="rIdx" class="text-center" style="width: 15%;" >
                      <p-checkbox size="small" title="Include Radiation" binary="true"
                        formControlName="radiation"/>
                    </td>
                    <td [pContextMenuRow]="rIdx" class="text-center" style="width: 95px">
                      <p-button size="small" severity="danger" text
                        title="Remove Annulus Section" icon="pi pi-trash"
                        [disabled]="rIdx == annulusContents.length - 1" 
                        (click)="onDeleteSection(rIdx)">
                      </p-button>
                    </td>
                  </tr>
                </ng-container>
              </ng-template>
            </p-table>
          </ng-container>
        </div>
    </form>

    <p class="text-sm pt-2" *ngIf="tubularCemented">Hard Cement applies below TOC {{ tocLabel }} </p>

  </app-tool-loader>
</div>