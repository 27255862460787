import { AfterContentInit, Component, Input, OnChanges, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { TrajectoryService } from 'src/app/shared/services/trajectory.service';
import { CementingLanding } from 'src/app/wellbore-inputs/models/cementing-landing.model';
import { DisplacementFluidIPP, InternalPressureProfileType } from '../../models/internal-pressure-profile.model';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { unitsLib } from 'src/app/core/services/unit-library';
import { GetMudHydrostaticPressure } from 'src/app/perivis/shared/helpers/mud-hydrostatic.helper';
import { lastValueFrom } from 'rxjs';

@Component({
    selector: 'app-displacement-fluid-ipp',
    template: `
    <p class="text-sm mb-2">Displacement Fluid: &nbsp; &nbsp; &nbsp; {{ cement.displacementFluidDensity }} {{ densityLabel }}</p>
    <p class="text-sm">Hanger Pressure:  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; {{ hydrostaticHangerPressure }} {{ userUnits.pressure }}</p>
  `,
    providers: subformComponentProviders(DisplacementFluidIppComponent),
    standalone: false
})
export class DisplacementFluidIppComponent implements OnInit, OnChanges, AfterContentInit {

  @Input() cement: CementingLanding;
  @Input() currentString: any;
  @Input() userUnits: UserUnitsModel;

  public densityLabel: string;
  public pressureLabel: string;

  public form = createForm<DisplacementFluidIPP>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(InternalPressureProfileType.DISPLACEMENTFLUID)
    }
  });

  public hydrostaticHangerPressure: number;

  constructor(private _trajectoryService: TrajectoryService) { }

  ngOnInit(): void {
    this.densityLabel = unitsLib[this.userUnits.density].symbol;
    this.pressureLabel = unitsLib[this.userUnits.pressure].symbol;
  }

  async ngOnChanges(): Promise<void> {
    const tvds = await lastValueFrom(this._trajectoryService.getTvdsFromMds([this.currentString.hangerMd], true));
    this.hydrostaticHangerPressure = GetMudHydrostaticPressure(tvds[0], this.cement.displacementFluidDensity, this.userUnits)
  }

  ngAfterContentInit(): void {
    this.form.formGroup.controls.type.setValue(InternalPressureProfileType.DISPLACEMENTFLUID);
  }
}
