<form [formGroup]="form.formGroup">

    <!-- <div class="pb-5 field" >
      <div class="flex items-center">
        <p-checkbox
          [formControlName]="form.formControlNames.useMudPits"
          id="useMudPits"
          binary="true"
          (onChange)="this.useMudPits = $event.checked;"
        ></p-checkbox>
        <label for="useMudPits" class="ml-2">Circulate through Mud Pits</label>
      </div>
    </div> -->

    <p-tabs value="0">
      <p-tablist>
        <p-tab value="0">Operation Details</p-tab>
        <p-tab *ngIf="useMudPits" value="1">Mud Pits
          <i
          class="validation-error pi pi-exclamation-circle pl-1"
          *ngIf="form.formGroup.controls.mudPits.invalid"
          pTooltip="Operation not saved, double check Mud Pit inputs."
        ></i> 
        </p-tab>
      </p-tablist>

      <p-tabpanels>
      <p-tabpanel class="mt-2" value="0"> 
        <div class="flex flex-wrap gap-4">
          <!-- Left Column -->
          <div class="flex flex-col flex-1 min-w-[200px] space-y-2">
  
            <div class="flex flex-col">
              <label for="fluid">Fluid</label>
              <p-select
                [disabled]="isReadOnly"
                [options]="fluids"
                optionLabel="state.name"
                [formControlName]="form.formControlNames.fluid"
                dataKey="id"
                appendTo="body"
                tooltipPosition="top"
                (onChange)="fluidChangedEvent($event)"
              >
                <ng-template pTemplate="selectedItem">
                  {{ form.formGroup.value.fluid?.state.name }}
                </ng-template>
                <ng-template let-fluid pTemplate="item">
                  {{ fluid.state.name }}
                </ng-template>
              </p-select>
            </div>
            <div class="flex flex-col">
              <label for="pressure">Pressure</label>
              <p-inputgroup>
                <p-select
                  [disabled]="isReadOnly"
                  [options]="pressureLocations"
                  [formControlName]="form.formControlNames.pressureLocation"
                  optionLabel="name"
                  optionValue="value"
                  appendTo="body"
                ></p-select>
                <p-inputnumber
                  [minFractionDigits]="1"
                  [formControlName]="form.formControlNames.pressure"
                />
                <p-inputgroup-addon>{{ pressureUnit }}</p-inputgroup-addon>
                <p
                  *ngIf="
                    form.formGroup.controls.pressure.value &&
                    form.formGroup.controls.pressure.invalid
                  "
                  class="validation-error"
                >
                  Allowed range: {{ pressureValidation.min }} - {{ pressureValidation.max }} {{ pressureUnit }}
                </p>
              </p-inputgroup>
            </div>
            <div class="flex flex-col">
              <label for="inletTemperature">Inlet Temperature</label>
              <p-inputgroup>
                <p-inputnumber
                  [minFractionDigits]="1"
                  [formControlName]="form.formControlNames.inletTemperature"
                />
                <p-inputgroup-addon>{{ tempUnit }}</p-inputgroup-addon>
              </p-inputgroup>
              <p
                *ngIf="
                  form.formGroup.controls.inletTemperature.value &&
                  form.formGroup.controls.inletTemperature.invalid
                "
                class="validation-error"
              >
                Allowed range: {{ temperatureValidation.min }} - {{ temperatureValidation.max }} {{ tempUnit }}
              </p>
            </div>
          </div>
  
          <!-- Right Column -->
          <div class="flex flex-col flex-1 min-w-[150px] space-y-2">
            <div class="flex flex-col">
              <label for="circulationMd">Circulation Depth</label>
              <p-inputgroup>
                <p-inputnumber
                  [minFractionDigits]="1"
                  [formControlName]="form.formControlNames.circulationMd"
                />
                <p-inputgroup-addon>{{ depthUnit }}</p-inputgroup-addon>
              </p-inputgroup>
              <p
                *ngIf="form.formGroup.controls.circulationMd.invalid"
                class="validation-error"
              >
                Max value: {{ tubingShoe }}{{ depthUnit }}
              </p>
            </div>
            <div class="flex flex-col">
              <label for="flowDirection">Circulation Direction</label>
              <p-select
                  [disabled]="isReadOnly"
                  [options]="circulationDirections"
                  [formControlName]="form.formControlNames.circulationDirection"
                  optionLabel="name"
                  optionValue="value"
                  appendTo="body"
              ></p-select>
            </div>
  
            <div class="flex flex-col">
                <app-standard-fluid
                  [formControlName]="form.formControlNames.standardFluidFlow"
                  [userUnits]="userUnits"
                ></app-standard-fluid>
            </div>
  
          </div>
        </div>
      </p-tabpanel>

      <p-tabpanel value="1">
        <app-mud-pit-details
          *ngIf="useMudPits"
          [disableMudPits]="!useMudPits"
          [previousOperationMudPits]="previousOperationMudPits"
          [userUnits]="userUnits"
          [formControlName]="form.formControlNames.mudPits"
        ></app-mud-pit-details>
      </p-tabpanel>

    </p-tabpanels>
  </p-tabs>
  
</form>
