import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { createForm, FormType, subformComponentProviders } from 'ngx-sub-form';
import { BhctTempProfile, TemperatureProfileType } from '../../models/temperature-profile.model';
import { UomQuantityInput } from '../../uom-quantity-input/uom-quantity-input-component';
import { UserUnitsModel } from 'src/app/core/components/user-units/user-units.model';
import { WellConfigService } from 'src/app/shared/services/well-config.service';

interface BhctTempProfileForm {
  type: TemperatureProfileType.BHCT;
  bottomMd: UomQuantityInput;
}

@Component({
    selector: 'app-bhct-temp-profile',
    template: `
    <form [formGroup]="form.formGroup">
        <app-uom-input
          *ngIf="isLoaded"
          label="Bottom hole circulation depth"
          [unitLabel]="userUnits.longLengths + ' MD'"
          [formControlName]="form.formControlNames.bottomMd"
          [control]="'bottomMd'"
          [validators]="validators?.bottomMd"
          [default]="bottomMdDefault"
          [inputDisabled]="loadName === 'Initial Condition'"
          minFractionDigits="1"
          (onFocus)="onInputFocus($event)"
      />
    </form>
  `,
    providers: subformComponentProviders(BhctTempProfileComponent),
    standalone: false
})
export class BhctTempProfileComponent implements OnInit, OnChanges {

  @Input() currentString: any;
  @Input() loadName: string;
  @Input() userUnits: UserUnitsModel;

  public validators: any;
  public bottomMdDefault: number;
  public isLoaded = false;

  public form = createForm<BhctTempProfile, BhctTempProfileForm>(this, {
    formType: FormType.SUB,
    formControls: {
      type: new UntypedFormControl(TemperatureProfileType.BHCT),
      bottomMd: new UntypedFormControl(null)
    },
    toFormGroup: (obj: BhctTempProfile | null): BhctTempProfileForm | null => {
      if (!obj) {
        return null;
      }

      return {
        type: obj.type,
        bottomMd: { control: obj.bottomMd }
      }
    },
    fromFormGroup: (formValue: BhctTempProfileForm): BhctTempProfile | null => {
      const { bottomMd, ...commonValues } = formValue;
      return {
        bottomMd: +bottomMd?.control,
        ...commonValues
      }
    }
  });

  constructor(private _wellConfigService: WellConfigService) { }

  ngOnInit() {
    this._wellConfigService.getTubulars().subscribe(tubulars => {
      const selectedStringIndex = tubulars.findIndex(x => x.id === this.currentString.id);
      const nextString = tubulars[selectedStringIndex + 1];
      const nextShoeDepth = nextString?.shoeMd ?? this.currentString.shoeMd;
      this.bottomMdDefault = Math.max(this.currentString.shoeMd, nextShoeDepth);
      this.isLoaded = true;
    });
  }

  ngOnChanges() {
    this.validators = {
      bottomMd: [Validators.required, Validators.min(this.currentString.shoeMd)]
    }
  }

  onInputFocus($event){
    $event.target.select();
  }
}
