import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoaderComponent } from '../shared/components/loader/loader.component';
import { ToolLoaderComponent } from '../shared/components/tool-loader/tool-loader.component';
import { ValidationErrorComponent } from '../wellbore-inputs/components/well-configuration/components/validation-error.component';
import { NgTableGridComponent } from '../shared/components/ng-table-grid/ng-table-grid.component';
import { NgTableResultsComponent } from '../shared/components/ng-table-results/ng-table-results.component';
import { AllowedRangeComponent } from '../shared/components/allowed-range/allowed-range.component';
import { XyLinePlotComponent } from '../core/components/xy-line-plot/xy-line-plot.component';

import { RangeSelectionDirective } from '../shared/helpers/range-select.directive';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GridsterModule } from 'angular-gridster2';
import { DynamicModule } from 'ng-dynamic-component';
import { PlotlyViaWindowModule } from 'angular-plotly.js';
import { PrimeNGModule } from '../modules/primeng.module';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { devTools } from '@ngneat/elf-devtools';

if (process.env.NODE_ENV !== 'production') {
  devTools();
}

const sharedComponents = [
  LoaderComponent,
  ToolLoaderComponent,
  ValidationErrorComponent,
  NgTableGridComponent,
  NgTableResultsComponent,
  AllowedRangeComponent,
  XyLinePlotComponent,
  RangeSelectionDirective
];

@NgModule({
  declarations: [
    ...sharedComponents
  ],
  imports: [
    CommonModule,
    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    GridsterModule,
    PlotlyViaWindowModule,
    PrimeNGModule,
    DynamicModule
  ],
  exports: [
    ...sharedComponents,

    DragDropModule,
    FormsModule,
    ReactiveFormsModule,
    GridsterModule,
    PlotlyViaWindowModule,
    PrimeNGModule,
    DynamicModule
  ]
})
export class SharedModule { }
