<app-tool-loader [isLoading]="isLoading" [positionUnset]="true">
  <form [formGroup]="form.formGroup">
    
    <div class="flex flex-col mt-1">
      <label for="type">Type</label>
      <p-select
        [disabled]="isReadOnly"
        [options]="operationTypes"
        [formControlName]="form.formControlNames.type"
        optionLabel="name"
        appendTo="body"
        optionValue="value"
        [autoDisplayFirst]="false"
        (onChange)="onTypeChanged()"
      ></p-select>
    </div>

    <div class="pt-5" [ngSwitch]="form.formGroup.value.type" *ngIf="!isLoading">
      <app-production-operation-details
        [isReadOnly]="isReadOnly"
        *ngSwitchCase="OperationType.PRODUCTION"
        [formControlName]="form.formControlNames.productionOperation"
        [fluids]="fluids$"
        [perforation]="perforations"
        [userUnits]="units"
        [reservoirs]="reservoirs"
        [chokes]="chokes"
        [pericalOptions]="pericalOptions"
        (fluidChanged)="onFluidChange($event)"
      ></app-production-operation-details>
      <app-injection-operation-details
        [isReadOnly]="isReadOnly"
        *ngSwitchCase="OperationType.INJECTION"
        [formControlName]="form.formControlNames.injectionOperation"
        [fluids]="fluids$"
        [perforations]="perforations"
        [surfaceTemperature]="surfaceTemperature"
        [user]="user"
        [userUnits]="units"
        [reservoirs]="reservoirs"
        [chokes]="chokes"
        [pericalOptions]="pericalOptions"
        (fluidChanged)="onFluidChange($event)"
      ></app-injection-operation-details>
      <app-shut-in-operation-details
        [isReadOnly]="isReadOnly"
        *ngSwitchCase="OperationType.SHUTIN"
        [formControlName]="form.formControlNames.shutInOperation"
        [currentString]="currentString"
        [isAnnulus]="tabType === 'annulus'"
        [user]="user"
      ></app-shut-in-operation-details>
      <app-circulation-operation-details
        [isReadOnly]="isReadOnly"
        *ngSwitchCase="OperationType.CIRCULATION"
        [formControlName]="form.formControlNames.circulationOperation"
        [fluids]="fluids$"
        [userUnits]="units"
        [udt]="udtProfile"
        [tubingShoe]="tubingShoe"
        [previousOperationMudPits]="previousOperationMudPits"
      ></app-circulation-operation-details>
      <app-gas-lift-details
        *ngSwitchCase="OperationType.GASLIFT"
        [formControlName]="form.formControlNames.gasLiftOperation"
        [fluids]="fluids$"
      ></app-gas-lift-details>
      <app-drilling-operation-details
        [wellConfig]="tubulars"
        [isReadOnly]="isReadOnly"
        *ngSwitchCase="OperationType.DRILLING"
        [formControlName]="form.formControlNames.drillingOperation"
        [fluids]="fluids$"
        [annularFluidId]="annularFluidId"
        (drillingRangeChanged)="onDrillingDepthRangeChange($event)"
        [userUnits]="units"
        [currentStringId]="currentString.id"
        [udtProfile]="udtProfile"
        [currentTubularIndex]="currentTubularIndex"
      ></app-drilling-operation-details>
      <app-run-casing-and-circulate-details
        [isReadOnly]="isReadOnly"
        *ngSwitchCase="OperationType.RUNCASINGANDCIRCULATE"
        [formControlName]="form.formControlNames.runCasingAndCirculate"
        [fluids]="fluids$"
        [annularFluidId]="annularFluidId"
        [userUnits]="units"
        [currentString]="currentString.id"
        [udtProfile]="udtProfile"
        [currentTubularIndex]="currentTubularIndex"
      ></app-run-casing-and-circulate-details>
      <app-trip-pipe-and-circulate-details
        [isReadOnly]="isReadOnly"
        *ngSwitchCase="OperationType.TRIPPIPEANDCIRCULATE"
        [formControlName]="form.formControlNames.tripPipeAndCirculate"
        [fluids]="fluids$"
        [annularFluidId]="annularFluidId"
        [userUnits]="units"
        [currentString]="currentString"
        [udtProfile]="udtProfile"
        [currentTubularIndex]="currentTubularIndex"
      ></app-trip-pipe-and-circulate-details>
      <app-cementing-operation-details
        [isReadOnly]="isReadOnly"
        *ngSwitchCase="OperationType.CEMENTING"
        [formControlName]="form.formControlNames.cementingOperation"
        [fluids]="fluids$"
        [annularFluidId]="annularFluidId"
        [userUnits]="units"
        [currentString]="currentString"
        [udtProfile]="udtProfile"
        [currentTubularIndex]="currentTubularIndex"
        [surfaceTemperature]="surfaceTemperature"
      ></app-cementing-operation-details>
    </div>
  </form>
</app-tool-loader>

<!-- <pre>{{ form.formGroupErrors | json }}</pre> -->