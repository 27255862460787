import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { catchError, shareReplay } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { GradeReference, PipeReference, WellComponent } from 'src/app/wellbore-inputs/components/well-configuration/models';
import { CustomConnectionModel } from '../models/custom-connection.model';
import { TubularString } from 'src/app/wellbore-inputs/models/well-schematic.model';
import { Fluid } from 'src/app/perical/models/fluid.model';
import { AnnulusContent } from 'src/app/wellbore-inputs/components/well-configuration/models/annulus-contents';

const PeriforApiEndpoint = `${environment.PeriforEndpoint}/api`;

@Injectable({
  providedIn: 'root'
})
export class WellConfigService {
  private annulusContentsCache$: Observable<any> = null;
  private selectedTubularIdCache$: Observable<any> = null;
  private pipeCatalogCache$: Observable<PipeReference[]> = null;
  private gradeCatalogCache$: Observable<GradeReference[]> = null;
  private tubularCache$: Observable<TubularString> = null;
  private stringsListCache$: Observable<any> = null;
  private connectionsListCache$: Observable<CustomConnectionModel[]> = null;
  private pipeOdsCache$: Observable<number[]> = null;
  private fluidsListCache$: Observable<Fluid[]> | null = null;

  constructor(private http: HttpClient) { }

  public getTubulars(): Observable<WellComponent[]> {
    return this.http.get<WellComponent[]>(`${PeriforApiEndpoint}/WellConfiguration/tubulars`).pipe(catchError(e => of(e)));
  }

  public getPipeCatalog(): Observable<PipeReference[]> {
    if (!this.pipeCatalogCache$) {
      this.pipeCatalogCache$ = this.http
        .get<PipeReference[]>(`${PeriforApiEndpoint}/pipesCatalog`)
        .pipe(
          catchError(() => of([])),
          shareReplay(1)
        );
    }
    setTimeout(() => this.pipeCatalogCache$ = null, 1000);
    return this.pipeCatalogCache$;
  }

  public getPipeCatalogByOd(selectedOd: number): Observable<PipeReference[]> {
    return this.http.get<PipeReference[]>(`${PeriforApiEndpoint}/pipesCatalog?od=${selectedOd}`).pipe(catchError(e => of(e)));
  }

  public getPipeOds(): Observable<number[]> {
    if (!this.pipeOdsCache$) {
      this.pipeOdsCache$ = this.http
        .get<number[]>(`${PeriforApiEndpoint}/pipesCatalog/ods`)
        .pipe(
          catchError(() => of([])),
          shareReplay(1)
        );
    }
    setTimeout(() => this.pipeOdsCache$ = null, 1000);
    return this.pipeOdsCache$;
  }

  public getGradeCatalog(): Observable<GradeReference[]> {
    if (!this.gradeCatalogCache$) {
      this.gradeCatalogCache$ = this.http
        .get<GradeReference[]>(`${PeriforApiEndpoint}/gradesCatalog`)
        .pipe(
          catchError(() => of([])),
          shareReplay(1)
        );
    }
    setTimeout(() => this.gradeCatalogCache$ = null, 1000);
    return this.gradeCatalogCache$;
  }

  public createNewTubular(tubular: WellComponent): Observable<string> { //Returns guid
    return this.http.post<string>(`${PeriforApiEndpoint}/WellConfiguration/tubulars`, tubular);
  }

  public editTubular(tubular: WellComponent): Observable<boolean> {
    return this.http.put<boolean>(`${PeriforApiEndpoint}/WellConfiguration/tubulars/${tubular.id}`, tubular)
  }

  public deleteTubular(tubularId: string): Observable<boolean> {
    return this.http.delete<boolean>(`${PeriforApiEndpoint}/WellConfiguration/tubulars/${tubularId}`)
  }

  public getStringsList(): Observable<any> {
    if (!this.stringsListCache$) {
      this.stringsListCache$ = this.http
        .get(`${PeriforApiEndpoint}/WellConfiguration/tubulars/lookup`)
        .pipe(
          catchError(() => of([])),
          shareReplay(1)
        );
    }
    setTimeout(() => this.stringsListCache$ = null, 1000);
    return this.stringsListCache$;
  }

  public getConnectionsList(): Observable<CustomConnectionModel[]> {
    if (!this.connectionsListCache$) {
      this.connectionsListCache$ = this.http
        .get<CustomConnectionModel[]>(`${PeriforApiEndpoint}/customConnections`)
        .pipe(
          catchError(() => of([])),
          shareReplay(1)
        );
    }
    setTimeout(() => this.connectionsListCache$ = null, 1000);
    return this.connectionsListCache$;
  }

  public getTubular(): Observable<TubularString> {
    if (!this.tubularCache$) {
      this.tubularCache$ = this.http
        .get<TubularString>(`${PeriforApiEndpoint}/WellConfiguration/tubular`)
        .pipe(
          catchError(() => of(null as TubularString)),
          shareReplay(1)
        );
    }
    setTimeout(() => this.tubularCache$ = null, 1000);
    return this.tubularCache$;
  }

  public getSelectedTubularId(): Observable<any> {
    if (!this.selectedTubularIdCache$) {
      this.selectedTubularIdCache$ = this.http.get<any>(`${PeriforApiEndpoint}/WellConfiguration/tubular/id`)
        .pipe(
          catchError(() => {
            return of(null);
          }),
          shareReplay(1)
        );
    }
    setTimeout(() => this.selectedTubularIdCache$ = null, 1000);
    return this.selectedTubularIdCache$;
  }

  public getFluidsList(): Observable<Fluid[]> {
    if (!this.fluidsListCache$) {
      this.fluidsListCache$ = this.http
        .get<Fluid[]>(`${PeriforApiEndpoint}/fluids`)
        .pipe(
          catchError(() => of([])),
          shareReplay(1)
        );
    }
    setTimeout(() => this.fluidsListCache$ = null, 1000);
    return this.fluidsListCache$;
  }

  public getAnnulusContents(): Observable<any> {
    if (!this.annulusContentsCache$) {
      this.annulusContentsCache$ = this.http
        .get<any>(`${PeriforApiEndpoint}/WellConfiguration/annulus-contents`)
        .pipe(
          catchError(() => of(null as any)),
          shareReplay(1)
        );
    }
    setTimeout(() => this.annulusContentsCache$ = null, 1000);
    return this.annulusContentsCache$;
  }

  updateAnnulusContents(annulusContents: any) {
    this.annulusContentsCache$ = null;
    return this.http.put<AnnulusContent>(`${PeriforApiEndpoint}/WellConfiguration/annulus-contents`, annulusContents);
  }
}
