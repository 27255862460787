<p-fieldset legend="Gas Composition %">
  <form [formGroup]="form.formGroup">

    <p-toolbar>
      <ng-template #start>
      </ng-template>
      <ng-template #end>
        <img class="w-[25px] h-[25px] mb-2" (click)="applyDefaults()" title="Apply Defaults" src="../../assets/icons/default-icon-white.png" />
      </ng-template>
    </p-toolbar>

    <div class="flex flex-wrap">
      <!-- Left Column -->
      <div class="w-full md:w-1/3 p-2 space-y-2">

        <div class="flex flex-col">
          <label for="co2" class="mb-1">CO<span class="sub">2</span></label>
          <p-inputNumber id="co2" [formControlName]="form.formControlNames.co2" required
            (onInput)="validatePercentage('co2', $event)" [minFractionDigits]="1"></p-inputNumber>
          <p *ngIf="form.formGroup.controls.co2.invalid" class="validation-error">
            Allowed range: 0 - 100 %
          </p>
        </div>

        <div class="flex flex-col">
          <label for="c1">C<span class="text-sm align-sub">1</span></label>
          <p-inputNumber id="c1" [formControlName]="form.formControlNames.c1" required
            (onInput)="validatePercentage('c1', $event)" [minFractionDigits]="1"></p-inputNumber>
          <p *ngIf="form.formGroup.controls.c1.invalid" class="validation-error">
            Allowed range: 0 - 100 %
          </p>
        </div>

        <div class="flex flex-col">
          <label for="nc4">NC<span class="text-sm align-sub">4</span></label>
          <p-inputNumber id="nc4" [formControlName]="form.formControlNames.nC4" required
            (onInput)="validatePercentage('nC4', $event)" [minFractionDigits]="1"></p-inputNumber>
          <p *ngIf="form.formGroup.controls.nC4.invalid" class="validation-error">
            Allowed range: 0 - 100 %
          </p>
        </div>
      </div>

      <!-- Center Column -->
      <div class="w-full md:w-1/3 p-2 space-y-2">

        <div class="flex flex-col">
          <label for="h2S" class="mb-1">H<span class="sub">2</span>S</label>
          <p-inputNumber id="h2S" [formControlName]="form.formControlNames.h2S" required
            (onInput)="validatePercentage('h2S', $event)" [minFractionDigits]="1"></p-inputNumber>
          <p *ngIf="form.formGroup.controls.h2S.invalid" class="validation-error">
            Allowed range: 0 - 100 %
          </p>
        </div>

        <div class="flex flex-col">
          <label for="c2">C<span class="text-sm align-sub">2</span></label>
          <p-inputNumber id="c2" [formControlName]="form.formControlNames.c2" required
            (onInput)="validatePercentage('c2', $event)" [minFractionDigits]="1"></p-inputNumber>
          <p *ngIf="form.formGroup.controls.c2.invalid" class="validation-error">
            Allowed range: 0 - 100 %
          </p>
        </div>

        <div class="flex flex-col">
          <label for="iC4">i-C<span class="text-sm align-sub">4</span></label>
          <p-inputNumber id="iC4" [formControlName]="form.formControlNames.iC4" required
            (onInput)="validatePercentage('iC4', $event)" [minFractionDigits]="1"></p-inputNumber>
          <p *ngIf="form.formGroup.controls.iC4.invalid" class="validation-error">
            Allowed range: 0 - 100 %
          </p>
        </div>
      </div>

      <!-- Right Column -->
      <div class="w-full md:w-1/3 p-2 space-y-2">

        <div class="flex flex-col">
          <label for="n2" class="mb-1">N<span class="sub">2</span></label>
          <p-inputNumber id="n2" [formControlName]="form.formControlNames.n2" required
            (onInput)="validatePercentage('n2', $event)" [minFractionDigits]="1"></p-inputNumber>
          <p *ngIf="form.formGroup.controls.n2.invalid" class="validation-error">
            Allowed range: 0 - 100 %
          </p>
        </div>

        <div class="flex flex-col">
          <label for="c3">C<span class="text-sm align-sub">3</span></label>
          <p-inputNumber id="c3" [formControlName]="form.formControlNames.c3" required
            (onInput)="validatePercentage('c3', $event)" [minFractionDigits]="1"></p-inputNumber>
          <p *ngIf="form.formGroup.controls.c3.invalid" class="validation-error">
            Allowed range: 0 - 100 %
          </p>
        </div>

        <div class="flex flex-col">
          <label for="nC5">NC<span class="text-sm align-sub">5</span></label>
          <p-inputNumber id="nC5" [formControlName]="form.formControlNames.nC5" required
            (onInput)="validatePercentage('nC5', $event)" [minFractionDigits]="1"></p-inputNumber>
          <p *ngIf="form.formGroup.controls.nC5.invalid" class="validation-error">
            Allowed range: 0 - 100 %
          </p>
        </div>
      </div>
    </div>

    <p *ngIf="form.formGroup.controls.c7C10.invalid" class="validation-error">
      Total Gas Composition % must be equal to 100%
    </p>

    <div class="flex flex-col p-2 space-y-2">
      <!-- Field for Average Molecular Weight C7 to C10 -->
      <div class="flex flex-col">
        <label for="averageMolWeightC7ToC10">
          Avg. Mol Mass of C<span class="text-sm align-sub">7</span> to C<span class="text-sm align-sub">10</span>
        </label>
        <p-inputNumber id="averageMolWeightC7ToC10"
          [formControlName]="form.formControlNames.averageMolWeightC7ToC10" required [minFractionDigits]="2"
          (onInput)="calculateGasGravity('averageMolWeightC7ToC10', $event)" class="w-full"></p-inputNumber>
      </div>
      <!-- Field for Gas Gravity -->
      <div class="flex flex-col">
        <label for="gasGravity">Gas Gravity</label>
        <input id="gasGravity" pInputText [disabled]="true" value="{{ totalMolWeight }}" class="w-full" />
      </div>
    </div>

  </form>
</p-fieldset>