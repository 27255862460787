import { UntypedFormArray } from "@angular/forms";

export function checkForDuplicates(control: UntypedFormArray) {
    return checkForDupl(control);

    function checkForDupl(control: any) : { error: string } {
        if (!control?.parent?.controls || control.value.outsideDiameter == null || control.value.insideDiameter == null ||
            control.value.weightPerFoot == null || control.value.driftDiameter == null) {
            return;
        }

        for (let i = 0; i < control.parent.controls?.length; i++) {
            const element = control.parent.controls[i];
            if (element.value.outsideDiameter == control.value.outsideDiameter &&
                element.value.insideDiameter == control.value.insideDiameter &&
                element.value.weightPerFoot == control.value.weightPerFoot &&
                element.value.driftDiameter == control.value.driftDiameter &&
                element.value.id != control.value.id) {
                const error = `Pipe with same properties already exists`;
                return error ? { error: error } : null;
            }
        }
    }
}
