<form [formGroup]="form.formGroup">
  <div class="flex flex-col space-y-2 pb-4">
    <div class="flex flex-col">
      <label for="fluid">Injection Gas</label>
      <p-select [options]="fluids" [formControlName]="form.formControlNames.fluid" optionLabel="state.name"
      dataKey="id" appendTo="body" tooltipPosition="top">
      <ng-template pTemplate="selectedItem">
        {{ form.formGroup.value.fluid?.state.name }}
      </ng-template>
      <ng-template let-fluid pTemplate="item">
        {{ fluid.state.name }}
      </ng-template>
    </p-select>
    </div>

    <div class="flex flex-col">
      <label for="gasLiftValveDepth">GLV Depth</label>
      <p-inputgroup>
        <p-inputnumber [formControlName]="form.formControlNames.gasLiftValveDepth" />
        <p-inputgroup-addon>ft</p-inputgroup-addon>
      </p-inputgroup>
    </div>

    <div class="flex flex-col">
      <label for="wellheadPressure">Injection Pressure</label>
      <p-inputgroup>
        <p-inputnumber id="wellheadPressure" [formControlName]="form.formControlNames.injectionPressure" />
        <p-inputgroup-addon>psi</p-inputgroup-addon>
      </p-inputgroup>
    </div>
    <div class="flex flex-col">
      <label for="inletTemperature">Inlet Temperature</label>
      <p-inputgroup>
        <p-inputnumber [formControlName]="form.formControlNames.inletTemperature" />
        <p-inputgroup-addon>°F</p-inputgroup-addon>
      </p-inputgroup>
    </div>
    <div class="flex flex-col">
      <label for="gasRate">Gas Rate</label>
      <p-inputgroup>
        <p-inputnumber [formControlName]="form.formControlNames.gasRate" />
        <p-inputgroup-addon>MMscf/day</p-inputgroup-addon>
      </p-inputgroup>
    </div>
  </div>
</form>