<div class="p-2 h-full">
  <p-dialog [draggable]="false" [(visible)]="hideGerg" [closable]="false">
    <i class="pi pi-exclamation-circle"></i> Functionality only available for CO2 based fluids
    <br> <br>
  </p-dialog>

  <p-scrollPanel [style]="{ width: '100%', height: '100%' }">
    <div *ngIf="!isLoading">
      <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>
      <!-- Container with flex layout -->
      <div class="flex flex-1 gap-4 overflow-auto">
        <!-- Left Column -->
        <div class="flex-[0.3] min-w-[150px] flex-shrink">
          <p-listbox [options]="chokeData?.chokes" [(ngModel)]="selectedChoke" optionLabel="name"
            (onChange)="onChokeSelect($event)">
            <ng-template pTemplate="header">
              <p-button class="outlined-button" size="small" icon="pi pi-plus" severity="success" outlined="true" label="Add Choke"
                title="Add Choke" (click)="onAddChoke()" [disabled]="hideGerg"></p-button>
            </ng-template>

            <ng-template let-choke #item>
              <div class="flex items-center w-full">
                <!-- Text container -->
                <div class="flex-1 truncate">
                  <span>{{ choke.name }}</span>
                </div>
            
                <!-- Button container forced to the right -->
                <div>
                  <i
                    class="pi pi-trash !text-red-400" 
                    title="Delete Choke"
                    (click)="onDeleteChoke(choke)">
                  </i>
                </div>
              </div>
            </ng-template>

          </p-listbox>
        </div>

        <!-- Right Column -->
        <div class="flex-[0.7]">
          <app-choke-data [choke]="selectedChokeDetails$ | async" (chokeUpdate)="saveChoke($event)"
            [disabled]="chokeData?.chokes?.length < 1" [chokesStore]="chokesStore" [componentHeight]="componentHeight">
          </app-choke-data>
        </div>
      </div>
    </div>
  </p-scrollPanel>
</div>