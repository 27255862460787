export function GetValueFromPpg(density: number, unit: string): number {
  switch (unit) {
    case 'ppg':
      return density;
    case 'kg/m³':
    case 'g/L':
      return +(density * 119.8264).toFixed(2);
    case 'g/cm³':
    case 'kg/l':
    case 'sg':
      return +(density * 0.1198264).toFixed(2);
  }
}

export function GetPpgFromValue(density: number, unit: string): number {
  switch (unit) {
    case 'ppg':
      return density;
    case 'kg/m³':
    case 'g/L':
      return +(density / 119.8264).toFixed(2);
    case 'g/cm³':
    case 'kg/l':
    case 'sg':
      return +(density / 0.1198264).toFixed(2);
  }
}

export function GetValueFromPsi(pressure: number, unit: string): number {
  switch (unit) {
    case 'psi':
      return +pressure.toFixed(2);
  case 'bar':
      return +(pressure * 0.068947572932).toFixed(2);
  case 'KPa':
      return +(pressure * 6.8947572932).toFixed(2);
  case 'atm':
      return +(pressure / 14.696).toFixed(2);
  }
}

export function GetPsiFromValue(pressure: number, unit: string): number {
  switch (unit) {
    case 'psi':
      return pressure;
    case 'bar':
      return +(pressure / 0.068947572932).toFixed(2);
    case 'KPa':
      return +(pressure / 6.8947572932).toFixed(2);
    case 'atm':
      return +(pressure * 14.696).toFixed(2);
  }
}

export function GetSolidsDensityFromLbmFt(density: number, unit: string): number {
  switch (unit) {
    case 'lbm/ft³':
      return density;
    case 'kg/m³':
      return +(density * 16.0185).toFixed(2);
    case 'g/cm³':
      return +(density * 0.0160185).toFixed(2);
  }
}

export function GetValueFromFahrenheit(temperature: number, unit: string): number {
  switch (unit) {
    case '°F':
      return temperature;
    case '°C':
      return +((temperature - 32) / 1.8).toFixed(2);
    case 'K':
      return +((temperature - 32) / 1.8 + 273.15).toFixed(2);
  }
}

export function GetFahrenheitFromValue(temperature: number, unit: string): number {
  switch (unit) {
    case '°F':
      return temperature;
    case '°C':
      return +(temperature * 1.8 + 32);
    case 'K':
      return +((temperature - 273.15) * 1.8 + 32);
  }
}

export function GetGradientFahrenheitFeetFromValue(gradient: number, unit: string): number {
  switch (unit) {
    case '°F/100ft':
      return gradient;
    case '°C/100m':
      return +(gradient / 1.8226888304);
    case '°C/30m':
      return +(gradient * 1.8226888304)
  }
}

export function GetTempGradientFromValue(gradient: number, unit: string): number {
  switch (unit) {
    case '°F/100ft':
      return gradient;
    case '°C/100m':
      return +(gradient * 1.8226888304);
    case '°C/30m':
      return +(gradient / 1.8226888304);
  }
}

export function GetValueFromFeet(length: number, unit: string): number {
  switch (unit) {
    case 'ft':
      return length;
    case 'm':
      return +(length * 0.3048).toFixed(2);
  }
}

export function GetFeetFromValue(length: number, unit: string, skipRounding?: boolean): number {
  switch (unit) {
    case 'ft':
      return length;
    case 'm':
      return skipRounding ? +(length / 0.3048) : +(length / 0.3048).toFixed(2);
  }
}

export function GetShortLengthValueFromInches(length: number, unit: string): number {
  switch (unit) {
    case 'in':
      return length;
    case 'cm':
      return +(length * 2.54).toFixed(2);
    case 'mm':
      return +(length * 25.4).toFixed(2);
  }
}

export function GetForceValueFromLbf(force: number, unit: string): number {
  switch (unit) {
    case 'lbf':
      return force;
    case 'tonne':
      return +(force * 0.0005).toFixed(2);
    case 'kgf':
      return +(force * 0.453592).toFixed(2);
  }
}

export function getVolumeValueFromBbl(volume: number, unit: string): number {
  switch (unit) {
    case 'bbl':
      return volume;
    case 'm³':
      return +(volume * 0.1589873).toFixed(2);
    case 'l':
      return +(volume * 158.9873).toFixed(2);
  }
}