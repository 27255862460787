<form [formGroup]="form.formGroup">

  <div class="mt-2 mb-2">

    <div class="flex">
      <label class="mr-2" for="isOil">Oil</label>
      <p-toggleswitch
        id="isOil"
        [disabled]="isReadOnly"
        [formControlName]="form.formControlNames.isOil"
        (onChange)="isOilChange($event)"
      ></p-toggleswitch>
    </div>

    <div class="flex flex-col" *ngIf="form.formGroup.controls.isOil.value">
      <label for="oilApiGravity">API Gravity</label>
      <p-inputNumber
        id="oilApiGravity"
        [formControlName]="form.formControlNames.oilApiGravity"
        required
        [minFractionDigits]="1"
        class="w-full"
      ></p-inputNumber>
      <p *ngIf="form.formGroup.controls.oilApiGravity.invalid" class="validation-error">
        Allowed range: 0 - 200
      </p>
    </div>

  </div>

  <div class="flex mb-2">
    <label class="mr-2" for="isGas">Gas</label>
    <p-toggleswitch
      id="isGas"
      [disabled]="isReadOnly"
      [formControlName]="form.formControlNames.isGas"
      (onChange)="isGasChange($event)"
    ></p-toggleswitch>
  </div>

  <app-gas-composition
    [hidden]="!form.formGroup.controls.isGas.value"
    [formControlName]="form.formControlNames.gasComposition"
    required
  ></app-gas-composition>

</form>
