<form [formGroup]="form.formGroup">

  <div class="flex flex-col space-y-2">

    <div class="flex flex-col">
      <label for="pipeRoughness">Pipe Roughness</label>
      <p-inputgroup>
        <p-inputnumber [formControlName]="form.formControlNames.pipeRoughness" />
        <p-inputgroup-addon>{{ userUnits.shortLengths }}</p-inputgroup-addon>
      </p-inputgroup>
      <p class="validation-error" *ngIf="form.formGroup.controls.pipeRoughness.invalid">
        Allowed range: {{ pipeRoughnessValidation.min }} - {{ pipeRoughnessValidation.max }} {{ userUnits.shortLengths
        }}
      </p>
    </div>

    <div class="flex flex-col">
      <label for="multiphaseCorrelation">Multi Phase Correlation</label>
      <p-select [disabled]="isReadOnly" [options]="multiphaseCorrelations"
        [formControlName]="form.formControlNames.multiphaseCorrelation" optionLabel="name" optionValue="value"
        appendTo="body"></p-select>
    </div>

    <div class="flex flex-col" *ngIf="!(gasHidden || gasModelHidden)">
      <label for="gasModel">Gas Model</label>
      <p-select [disabled]="isReadOnly" [options]="gasModels" [formControlName]="form.formControlNames.gasModel"
        optionLabel="name" optionValue="value" appendTo="body"></p-select>
    </div>

    <div class="flex flex-col" *ngIf="co2FluidSelected">
      <label for="co2EosOption">CO2 EOS</label>
      <p-select [disabled]="isReadOnly" [options]="co2EosOptions" [formControlName]="form.formControlNames.co2EosOption"
        optionLabel="name" optionValue="value" appendTo="body"></p-select>
    </div>

    <div class="flex flex-col" *ngIf="!(oilHidden || gasHidden)">
      <label for="gorSelected">GOR</label>
      <p-inputgroup>
        <p-select [disabled]="isReadOnly || ((!oilGas[0] || !oilGas[1]) && !vleSelected)" id="gorSelected" [options]="gorOptions" optionLabel="label"
          optionValue="value" appendTo="body" (onChange)="onGorTypeChange($event)"
          class="gorDropdown" [formControlName]="form.formControlNames.gasToOilRatio"></p-select>
          <p-inputNumber 
          #gorInput
          [minFractionDigits]="1" 
          [placeholder]="gor" 
          id="gorValue" 
          (onBlur)="updateGor('gor')" 
          [disabled]="gorEnabled"/>
        <p-inputgroup-addon>{{ gorUnit }}</p-inputgroup-addon>
      </p-inputgroup>
    </div>

    <div class="flex flex-col" *ngIf="!(oilHidden || co2FluidSelected)">
      <label for="oilFlowRate">Oil Flow Rate</label>
      <p-inputgroup>
        <p-inputNumber [minFractionDigits]="1" (onBlur)="updateGor('oil')"
          [formControlName]="form.formControlNames.oilFlowRate" />
        <p-inputgroup-addon>{{ flowUnit }}</p-inputgroup-addon>
      </p-inputgroup>
      <p *ngIf="
        form.formGroup.controls.oilFlowRate.value &&
        form.formGroup.controls.oilFlowRate.invalid
      " class="validation-error">
        Allowed range: {{ oilFlowRateValidation.min }} - {{ oilFlowRateValidation.max }} {{ flowUnit }}
      </p>
    </div>

    <div class="flex flex-col" *ngIf="!gasHidden">
      <label for="gasFlowRate">Gas Flow Rate</label>
      <p-inputgroup>
        <p-inputNumber [minFractionDigits]="1" (onBlur)="updateGor('gas')"
          [formControlName]="form.formControlNames.gasFlowRate" />
        <p-inputgroup-addon>{{ gasFlowUnit }}</p-inputgroup-addon>
      </p-inputgroup>
      <p *ngIf="
        form.formGroup.controls.gasFlowRate.value &&
        form.formGroup.controls.gasFlowRate.invalid
      " class="validation-error">
        Allowed range: {{ gasFlowRateValidation.min }} - {{ gasFlowRateValidation.max }} {{ gasFlowUnit }}
      </p>
    </div>

    <div class="flex flex-col" *ngIf="!co2FluidSelected">
      <label for="waterFlowRate">Water Flow Rate</label>
      <p-inputgroup>
        <p-inputNumber [minFractionDigits]="1" [formControlName]="form.formControlNames.waterFlowRate" />
        <p-inputgroup-addon>{{ flowUnit }}</p-inputgroup-addon>
      </p-inputgroup>
      <p *ngIf="
        form.formGroup.controls.waterFlowRate.value &&
        form.formGroup.controls.waterFlowRate.invalid
      " class="validation-error">
        Allowed range: {{ waterFlowRateValidation.min }} - {{ waterFlowRateValidation.max }} {{ flowUnit }}
      </p>
    </div>

  </div>

</form>