<p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

<app-tool-loader [isLoading]="isLoading" positionUnset="true">
  <p-toolbar>
    <ng-template #start>
      <div class="flex space-x-2 toolbar-menu">
        <p-selectButton [options]="tablePlot" [(ngModel)]="formationTopsStore.state.tablePlotSelected"
          (onChange)="onTablePlotSelect($event)" [allowEmpty]="false">
        </p-selectButton>

        <p-selectButton *ngIf="formationTopsStore.state.tablePlotSelected === 'plot'" [options]="northingUdt"
          [(ngModel)]="formationTopsStore.state.northingUdtSelected" (onChange)="onNorthingUdtSelect($event)"
          [allowEmpty]="false">
        </p-selectButton>
      </div>
    </ng-template>
  </p-toolbar>

  <div *ngIf="formationTopsStore.state.tablePlotSelected === 'table'" class="w-full h-full">
    <form [formGroup]="formationTopsForm" novalidate>
      <div [style.display]="formationTopsStore.state.tablePlotSelected === 'table' ? 'block' : 'none'">
        <ng-container formArrayName="formationTops">
          <p-table [scrollable]="true" [scrollHeight]="tableHeight" [columns]="columnDefs" [resizableColumns]="true"
            [value]="formationTops.controls" showGridlines dataKey="value.id">
            <ng-template #header let-columns>
              <tr>
                <th class="text-center" *ngFor="let col of columns" pResizableColumn> {{ col.header }} </th>
                <th class="text-center">
                  <p-button outlined="true" size="small" severity="success" icon="pi pi-plus" [disabled]="formationTops.invalid"
                    title="Add" (click)="onAddFormation()"></p-button>
                </th>
              </tr>
            </ng-template>

            <ng-template #body let-formationTopFg let-rIdx="rowIndex">
              <ng-container [formGroupName]="rIdx">
                <tr>
                  <td>
                    <p-inputNumber class="text-right w-full" formControlName="formationTop" [minFractionDigits]="1"
                      title="Formation Top">
                    </p-inputNumber>
                    <app-validation-error [isValid]=" isControlInvalid(formationTopFg, 'formationTop')"
                      [errorMsg]=" getControlErrors(formationTopFg, 'formationTop')"/>
                  </td>
                  <td>
                    <p-select class="w-full" [title]="showJson(formationTopFg.get('formation').value)"
                      [options]="_formations" appendTo="body" formControlName="formation"
                      [placeholder]="getPlaceholderText(formationTopFg.get('formation').value)">
                      <ng-template let-formation pTemplate="selectedItem">
                        {{ getPlaceholderText(formationTopFg.get('formation').value) }}
                      </ng-template>
                      <ng-template let-formation pTemplate="item">
                        {{ formation.name }}
                      </ng-template>
                    </p-select>
                  </td>

                  <td class="text-center">
                    <i title="Remove Formation Top" class="pi pi-trash !text-red-400"
                    [ngClass]="{ 'opacity-50': rIdx === 0 }"
                    (click)="rIdx !== 0 && onDeleteFormation(rIdx)">
                 </i>
                 
                  </td>
                </tr>
              </ng-container>
            </ng-template>
          </p-table>
        </ng-container>
      </div>
    </form>
  </div>

  <app-xy-line-plot [componentHeight]="componentHeight" *ngIf="formationTopsStore.state.tablePlotSelected === 'plot'" [plotData]="plot.data"
    [xAxisTitle]="xAxisTitle" [yAxisTitle]="yAxisTitle" [plotName]="plotName" [downloadPlotName]="downloadPlotName"
    [plotTitle]="'Formation Tops'"
    [xyLinePlotStore]="formationTopsStore" 
  ></app-xy-line-plot>
</app-tool-loader>