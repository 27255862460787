<app-tool-loader [isLoading]="isLoading || isDelete" positionUnset="true">
  <p-confirmDialog header="Confirmation" icon="pi pi-exclamation-triangle"></p-confirmDialog>

  <p-toolbar styleClass="mb-2">
    <ng-template #start>
      <div class="toolbar-menu">
      <p-button class="outlined-button" size="small" label="Add Load Case" severity="success" outlined icon="pi pi-plus"
        (click)="!configMissing && showNewLoadCaseDialog()" [disabled]="configMissing"></p-button>
      </div>
    </ng-template>
    <ng-template #end>
      <p-button class="mr-2 outlined-button" size="small" label="Plot" severity="info" outlined icon="pi pi-fw pi-chart-line"
        (click)="drawerToggle()" (click)="pressTempToggle()" [disabled]="configMissing"></p-button>
      <p-selectButton class="mr-2" *ngIf="postInstallLoadsStore.state?.drawerOpen" [options]="pressTemp" visible="true"
        [(ngModel)]="postInstallLoadsStore.state.pressTempSelected" (onChange)="pressTempToggle($event)"
        [disabled]="configMissing" [allowEmpty]="false"></p-selectButton>
    </ng-template>
  </p-toolbar>

  <div class="flex">

    <p-table #loadsTable class="flex-1" scrollable [value]="loadCases" selectionMode="single"
       metaKeySelection="true" (onRowReorder)="onRowReorder($event)" dataKey="index"
      [expandedRowKeys]="expandedRows" rowExpandMode="single" [scrollHeight]="tableHeight"
      [(selection)]="postInstallLoadsStore.state.selectedLoad"
      >

      <ng-template pTemplate="header">
        <tr>
          <th class="w-5"></th>
          <th class="px-4 py-2 text-center font-extrabold uppercase">Name</th>
          <th class="px-4 py-2 text-center font-extrabold uppercase">Internal Pressure</th>
          <th class="px-4 py-2 text-center font-extrabold uppercase">External Pressure</th>
          <th class="px-4 py-2 text-center font-extrabold uppercase">Temperature</th>
      
          <th class="px-4 py-2 text-center" *ngIf="loadCases?.length > 0">
            <div class="flex justify-end items-center space-x-2">
              <p-button class="outlined-button" label="Delete" (click)="deleteLoadCases()" size="small" outlined severity="danger"
                [disabled]="selectedLoadCaseIndexes.length < 1" title="Delete selected load cases"></p-button>
              <p-checkbox size="small" binary="true" [ngModel]="selectAllChecked" (onChange)="toggleSelectAll()"
                title="Select All" [disabled]="loadCases?.length < 2">
              </p-checkbox>
            </div>
          </th>
        </tr>
      </ng-template>

      <ng-template #body let-load let-index="rowIndex" let-expanded="expanded">
        <tr class="h-[32px]" (dblclick)="editLoadCase(load)" (click)="selectLoad()" [pSelectableRow]="load" [pReorderableRow]="index">
          <td class="py-2 text-center">
            <i *ngIf="load.name !== 'Initial Condition'" class="pi pi-sort" pReorderableRowHandle></i>
          </td>
    
          <td class="py-2 text-center">
            {{ load.name }}
          </td>
          <td class="py-2 text-center">
            {{ load.ippDisplayName }}
          </td>
          <td class="py-2 text-center">
            {{ load.eppDisplayName }}
          </td>
          <td class="py-2 text-center">
            {{ load.tpDisplayName }}
          </td>
    
          <!-- Ensure button row has same height across all rows -->
          <td class="flex space-x-2 justify-end items-center h-[32px]">
            <i size="small" class="pi pi-pencil !text-green-400" title="Edit &quot;{{ load.name }}&quot;"
              [pRowToggler]="load" (click)="editLoadCase(load)" *ngIf="!expandedRows[load.index] === true">
            </i>
    
            <i size="small" class="pi pi-copy !text-cyan-400" title="Clone &quot;{{ load.name }}&quot;"
              (click)="cloneLoadCase(load)"></i>
        
            <i *ngIf="load.name !== 'Initial Condition'" class="pi pi-trash !text-red-400"
              title="Delete &quot;{{ load.name }}&quot;" (click)="deleteLoadCase(load)">
            </i>
    
            <i class="pr-[32px] pi pi-lock !text-red-400 flex items-center" *ngIf="load.name === 'Initial Condition'"
              text size="small" icon="pi pi-lock"></i>
    
            <p-checkbox class="pr-1" size="small" *ngIf="load.name !== 'Initial Condition'" binary="true"
              [ngModel]="selectedLoadCaseIndexes.includes(load.index)" (onChange)="selectLoadCases(load)"
              title="Select &quot;{{ load.name }}&quot; to delete multiple load cases">
            </p-checkbox>
          </td>
        </tr>
      
      </ng-template>
      <ng-template #expandedrow let-load>
        <tr>
          <td colspan="9">
            <app-post-install-load-details [isReadOnly]="userRoles.readOnly" [load]="loadCase"
              (loadUpdate)="saveLoadCase($event)" (cancelEdit$)="cancelEdit()" [shoeDepth]="currentString.shoeMd"
              [shoeDepthTvd]="shoeDepthTvd" [currentString]="currentString" [cement]="cement"
              [porePressures]="porePressures" [thermalOperations]="thermalOperations" [wellType]="wellType"
              [userUnits]="userUnits" [wellTd]="wellTd">
            </app-post-install-load-details>
          </td>
        </tr>
      </ng-template>
    </p-table>

    <div class="transition-all block w-1/3" *ngIf="postInstallLoadsStore.state?.drawerOpen">
      <app-post-install-loads-plot *ngIf="postInstallLoadsStore.state?.tablePlotSelected === 'plot'"
        [plotData]="plotData" [udt]="udtPlotData" [pressTempSelected]="postInstallLoadsStore.state?.pressTempSelected"
        [hangerTvd]="hangerTvd" [shoeDepthTvd]="shoeDepthTvd" [userUnits]="userUnits" [stringName]="stringName"
        [componentHeight]="componentHeight"
        [postInstallLoadsStore]="postInstallLoadsStore"
        [plotName]="postInstallLoadsStore.state?.selectedLoad.name + ' - ' + postInstallLoadsStore.state?.pressTempSelected"
        ></app-post-install-loads-plot>
    </div>
  </div>

  <p-dialog [(visible)]="configMissing" [closable]="false">
    <i class="pi pi-exclamation-circle"></i> Well Configuration Missing: Please specify at least one string 
    <br />
    <br />
  </p-dialog>

  <p-dialog [(visible)]="newLoadCaseDialogDisplay" [closable]="true" [draggable]="false" modal="true">
    <ng-template pTemplate="header">New Load Case</ng-template>
    <ng-template pTemplate="body">
      <app-new-post-install-load-dialog (saveLoad$)="newLoadCase($event)" (cancelNewLoad$)="cancelNewLoadCaseDialog()"
        appendTo="body"></app-new-post-install-load-dialog>
    </ng-template>
  </p-dialog>
  <br>
</app-tool-loader>