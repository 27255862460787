export class PoreFrac {
  public constructor(init?: Partial<PoreFrac>) {
    this.trueVerticalDepth = init && init.trueVerticalDepth || 0;
    this.equivalentMudWeight = init && init.equivalentMudWeight || 0;
    this.pressure = init && init.pressure || 0;
  }

  trueVerticalDepth: number;
  equivalentMudWeight: number;
  pressure: number;
}

export class PoreFracUi {
  public constructor(init?: Partial<PoreFracUi>) {
    this.tablePlotSelected = init && init.tablePlotSelected || 'table';
    this.pressureEmwSelected = init && init.pressureEmwSelected || 'emw';
  }
  tablePlotSelected: string;
  pressureEmwSelected: string;
}

export class PpfgPlotUi {
  public constructor(init?: Partial<PpfgPlotUi>) {
    this.pressureEmwSelected = init && init.pressureEmwSelected || 'emw';
    this.plotFormationTops = init && init.plotFormationTops || false;
  }
  pressureEmwSelected: string;
  plotFormationTops: boolean;
}
